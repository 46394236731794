import pencil from './icons/edit@2x.svg'
import trash from './icons/trash@2x.svg'
import close from './icons/close.svg'
import exportFile from './icons/export.svg'
import addButton from './icons/addButton.svg'
import closeFilled from './icons/close-filled.svg'

export const icons = {
  close,
  pencil,
  trash,
  exportFile,
  addButton,
  closeFilled
}
