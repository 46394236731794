import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Draggable from '../../../components/draggable-form-template'
import { VariableSizeList } from 'react-window'
import InputField from '../../../components/forms-helper/InputField'

const Row = memo(({ data, index, style }) => {
  const elementRef = useRef(null)

  const { elements, activeType, canDrag, setSize } = data
  const item = elements[index]

  useEffect(() => {
    if (elementRef.current) {
      const { height } = elementRef.current.getBoundingClientRect() || {}
      setSize(index, height)
    }
  }, [index, setSize, item])

  return (
    <div style={{ ...style }}>
      <ElementContainer
        ref={elementRef}
        data={item}
        labelKey={`${activeType}_name`}
        type={activeType}
        key={item[`${activeType}_id`]}
        canDrag={canDrag}
      />
    </div>
  )
})

const ElementContainer = forwardRef(
  ({ data, labelKey, type, canDrag }, ref) => {
    return (
      <div ref={ref}>
        <Draggable
          dragType={type}
          dragData={{ ...data }}
          previewClass={['element-container--preview']}
          canDrag={canDrag}>
          <div className="element-container">
            <span>{data[labelKey]}</span>
          </div>
        </Draggable>
      </div>
    )
  }
)

export const getTabs = setActiveTab => [
  {
    label: 'Spaces',
    id: 'spaces',
    onClick: value => setActiveTab(value),
    active: true,
    disabled: false
  },
  {
    label: 'Items',
    id: 'items',
    onClick: value => setActiveTab(value),
    active: false,
    disabled: false
  },
  {
    label: 'Observations',
    id: 'observations',
    onClick: value => setActiveTab(value),
    active: false,
    disabled: false
  },
  {
    label: 'Solutions',
    id: 'solutions',
    onClick: value => setActiveTab(value),
    active: false,
    disabled: false
  }
]

export const RenderTabsContent = ({ activeTab, elements, canDrag }) => {
  const activeType = activeTab.substring(0, activeTab.length - 1)

  const [searchValue, setSearchValue] = useState('')

  const sizeMap = useRef({})
  const listRef = useRef(null)

  const filteredElements = useMemo(() => {
    const searchKey = `${activeType}_name`

    return (elements[activeTab] || []).filter(element =>
      (element[searchKey] || '')
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    )
  }, [elements, activeTab, searchValue, activeType])

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, [])

  const getSize = index =>
    sizeMap.current[index] && sizeMap.current[index] >= 50
      ? sizeMap.current[index] + 11
      : 50

  const getElementWording = () => {
    switch (activeTab) {
      case 'spaces':
        return 'Spaces are any areas inside/outisde a unit, such as a bedroom or patio.'

      case 'items':
        return 'Items are any physical things inside a space that must be inspected, such as floors or cabinetry.'

      case 'observations':
        return 'Observations describe the condition of each item. Example: Carpet is stained.'

      case 'solutions':
        return 'Solutions answer the question, "What should be done?" based on the inspection.'

      default:
        return null
    }
  }

  const handleSearch = ({ currentTarget: input }) => {
    setSearchValue(input.value)
  }

  useEffect(() => {
    setSearchValue('')
  }, [activeTab])

  return (elements[activeTab] || []).length ? (
    <>
      <p className="m-b-md">{getElementWording()}</p>
      <div>
        <InputField
          wrapperClassName="search-element"
          type="text"
          name={activeTab}
          value={searchValue}
          placeholder="Search..."
          onChange={handleSearch}
        />
        <hr className="underline"></hr>
      </div>

      <div className="elements-container">
        <VariableSizeList
          ref={listRef}
          height={300}
          itemCount={(filteredElements || []).length}
          itemSize={getSize}
          itemData={{
            elements: filteredElements,
            activeType,
            canDrag,
            setSize
          }}
          width="100%">
          {Row}
        </VariableSizeList>
      </div>
    </>
  ) : (
    <>
      <p className="m-b-md">{getElementWording()}</p>
      <div className="elements-container">
        <p>
          <strong>No more available {activeTab}</strong>
        </p>
      </div>
    </>
  )
}
