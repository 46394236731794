import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import InspectionList from '../inspection-list'

const InspectionDashboardMain = (props) => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
      </div> 
      <div className="section">
        <InspectionList {...props}/>
      </div>
    </div>
  )
}

export default InspectionDashboardMain
