import React, { useState, useMemo, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { cloneDeep, isEmpty } from 'lodash'
import close from './icons/close.svg'
import { exportFile } from 'ui/icons'

import ErrorMessage from '../../components/forms-helper/ErrorMessage'
import { headerData } from './headers'
import InHouseTable from './in-house-table/in-house-table'
import './index.scss'
import { capitalize } from '../../utils/helperFunctions'
import moment from 'moment'
import { usePermissionGate } from '../../helpers/hooks'

const TurnboardInHouseInterfaceModal = ({
  services = [],
  rows = [],
  user,
  onConfirm,
  onCancel,
  pdbid,
  context,
  markInHouse,
  unMarkInHouse,
  markAndUnMarkInHouse
}) => {
  const { hasPermission } = usePermissionGate('turnboard-actions')
  const { markOrUnMarkInHouseLoader } = useSelector(state => state.turnboard)
  const [error, setError] = useState('')
  const [tableData, setTableData] = useState(() => cloneDeep(rows))
  const [skipReset, setSkipReset] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const tableRef = useRef(null)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).some(filter => filter?.value?.length)
  }, [isFilterApplied])

  const walkExcludedServices = useMemo(() => {
    return (services || []).filter(({ servicetype }) => servicetype !== 'Walk')
  }, [services])

  const columns = useMemo(
    () =>
      headerData({
        services: walkExcludedServices,
        hasPermission
      }),
    [walkExcludedServices, hasPermission]
  )

  const handleServiceStatusChange = (index, serviceName, status) => {
    setSkipReset(() => true)

    const newTableData = cloneDeep(tableData)
    newTableData[index][serviceName] = status

    setTableData(newTableData)
  }

  const handleResetFilter = () => tableRef.current?.clearFilter([])

  const handleExport = () => {
    if (!tableData.length) return

    const { user_metadata } = user || {}
    const { properties, projects } = user_metadata || {}
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }

    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const rows = tableRef.current?.getRows() || []
    let defaultData = selectedRows.length
      ? selectedRows.map(row => row?.original)
      : rows

    const newData = (defaultData || []).map(row => {
      const { unit, unit_space } = row || {}

      const serviceItems = (walkExcludedServices || []).map(
        ({ servicetype }) => {
          const serviceName = (servicetype || '')
            .toString()
            .replace(' ', '_')
            .toLowerCase()

          return {
            [serviceName]: row[`${serviceName}_inhouse_status`]
          }
        }
      )

      const items = Object.assign({}, ...serviceItems)

      return {
        Unit: unit,
        Space: unit_space,
        ...items
      }
    })

    const csvData = [Object.keys(newData[0] || []).map(key => capitalize(key))]
      .concat((newData || []).map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-in-house-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getServiceName = servicetype => {
    return (servicetype || '')
      .toString()
      .replace(' ', '_')
      .toLowerCase()
  }

  // This will check if there is any unit space having all the in house service statuses equals to N/A

  const getMarkedInHouseData = () => {
    const markedInHouseData = []

    // filter the rows which can be marked as in house if any service status of a unit space is not N/A
    const filteredMarkedRows = (tableData || []).filter(row => {
      return !(walkExcludedServices || []).every(({ servicetype }) => {
        const serviceName = getServiceName(servicetype)
        return row[`${serviceName}_inhouse_status`] === 'N/A'
      })
    })

    for (let row of filteredMarkedRows) {
      const mapped = (walkExcludedServices || [])
        .filter(({ servicetype }) => {
          const serviceName = getServiceName(servicetype)
          return (
            row[`${serviceName}_inhouse_status`] !== 'N/A' ||
            !isEmpty(row[`${serviceName}_inhouse_id`])
          )
        })
        .map(({ servicetype, servicetype_id }) => {
          const serviceName = getServiceName(servicetype)
          const status =
            row[`${serviceName}_inhouse_status`] === 'N/A'
              ? null
              : row[`${serviceName}_inhouse_status`]

          return {
            inhouse_id: row[`${serviceName}_inhouse_id`],
            servicetype_id,
            unit_space_id: row.unit_space_id,
            status
          }
        })

      markedInHouseData.push(...mapped)
    }

    return markedInHouseData
  }

  const getUnMarkedInHouseData = () => {
    const unMarkedInHouseData = []

    const filteredUnMarkedRows = (tableData || []).filter(row => {
      return (walkExcludedServices || []).every(({ servicetype }) => {
        const serviceName = getServiceName(servicetype)
        return row[`${serviceName}_inhouse_status`] === 'N/A'
      })
    })

    for (let row of filteredUnMarkedRows) {
      const mapped = (walkExcludedServices || [])
        .filter(({ servicetype }) => {
          const serviceName = getServiceName(servicetype)
          return !isEmpty(row[`${serviceName}_inhouse_id`])
        })
        .map(({ servicetype }) => {
          const serviceName = getServiceName(servicetype)
          return {
            inhouse_id: row[`${serviceName}_inhouse_id`]
          }
        })

      unMarkedInHouseData.push(...mapped)
    }

    return unMarkedInHouseData
  }

  const areAllInhouseStatusesIsNA = () => {
    return (tableData || []).some(row => {
      return (walkExcludedServices || []).every(({ servicetype }) => {
        const serviceName = getServiceName(servicetype)
        return row[`${serviceName}_inhouse_status`] === 'N/A'
      })
    })
  }

  const handleSuccess = () => onConfirm()

  const handleError = () =>
    setError('Something went wrong. Please try again later !!!')

  const handleSubmit = async () => {
    setError('')

    if (!tableData.length) return

    if (areAllInhouseStatusesIsNA()) {
      setShowAlert(true)

      const modal = document.getElementById('modal-card')
      modal.style.width = '500px'

      return
    }

    const markedInHouseData = getMarkedInHouseData()

    const data = {
      items: markedInHouseData
    }

    await markInHouse({
      data,
      pdbid,
      context,
      onSuccess: handleSuccess,
      onError: handleError
    })
  }

  const handleProceed = async () => {
    if (!tableData.length) return

    const markedInHouseData = getMarkedInHouseData()
    const unMarkInHouseData = getUnMarkedInHouseData()

    const markedData = {
      items: markedInHouseData
    }

    const unMarkedData = {
      items: unMarkInHouseData
    }

    if (markedInHouseData?.length) {
      await markAndUnMarkInHouse({
        data: { markedData, unMarkedData },
        pdbid,
        context,
        onSuccess: handleSuccess,
        onError: handleError
      })
    } else {
      await unMarkInHouse({
        data: unMarkedData,
        pdbid,
        context,
        onSuccess: handleSuccess,
        onError: handleError
      })
    }
  }

  const handleCancel = () => {
    setShowAlert(false)

    const modal = document.getElementById('modal-card')
    modal.style.width = '900px'
  }

  useEffect(() => {
    setSkipReset(() => false)
  }, [tableData.length])

  useEffect(() => {
    const modalCardBody = document.getElementById('modal-card-body')
    modalCardBody.style.maxHeight = 'unset'
  }, [])

  return (
    <div className="in-house-modal">
      <div className="modal-heading">
        <div className="heading-container">
          <h3>In-House Services</h3>
          {!showAlert && (
            <div className="action-button">
              <div className="in-house-action-buttons">
                {(checkIsFilterApplied || []).length !== 0 && (
                  <button
                    onClick={handleResetFilter}
                    className="button is-secondary">
                    Clear All Filter(s)
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        {!showAlert && (
          <div className="action-button">
            <div>
              <button
                className="export"
                disabled={!tableData.length}
                onClick={handleExport}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>
        )}
        <p className="close is-pointer has-text-grey-light" onClick={onCancel}>
          <img alt="Close Modal" src={close} />
        </p>
      </div>
      {error && (
        <ErrorMessage>
          <p>{error}</p>
        </ErrorMessage>
      )}
      {!showAlert ? (
        <div className="data-table-wrapper">
          <>
            <InHouseTable
              data={tableData}
              ref={tableRef}
              tableColumns={columns}
              skipReset={skipReset}
              setIsFilterApplied={setIsFilterApplied}
              onServiceStatusChange={handleServiceStatusChange}
              onRowSelectStateChange={setSelectedRows}
            />
            {hasPermission && (
              <div className="modal-actions center-container">
                <button
                  className="button main-button is-secondary m-r-md"
                  onClick={onCancel}>
                  Cancel
                </button>
                <button
                  disabled={!tableData.length || markOrUnMarkInHouseLoader}
                  className="button main-button is-primary"
                  onClick={handleSubmit}>
                  Save
                  {markOrUnMarkInHouseLoader && (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      color="#ffffff"
                      className="m-l-sm"
                    />
                  )}
                </button>
              </div>
            )}
          </>
        </div>
      ) : (
        <>
          <div className="modal-desceription">
            <p>
              It seems that there is a space with all services specified as N/A.
              Please update the status of at least one service per space.
              Otherwise, the space will be removed from the In-House feature if
              you wish to proceed ahead.
            </p>
          </div>
          <div className="modal-confirmation-text center-container">
            <p>Would you like to continue?</p>
          </div>
          {hasPermission && (
            <div className="modal-actions center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={handleCancel}>
                Cancel
              </button>
              <button
                disabled={!tableData.length || markOrUnMarkInHouseLoader}
                className="button main-button is-primary"
                onClick={handleProceed}>
                Proceed
                {markOrUnMarkInHouseLoader && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    color="#ffffff"
                    className="m-l-sm"
                  />
                )}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default TurnboardInHouseInterfaceModal
