import React, { useEffect, useState, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
// import { REASONS } from './reasons'
import { SERVICES } from '../services-main/constants'
import { icons } from './icons'
import './index.scss'

const ServicesModalManage = ({
  rows,
  services,
  closeModal,
  updateServices,
  user,
  action,
  context
}) => {
  const [unitSpaces, setUnitSpaces] = useState([])
  const [isConsolidated, setIsConsolidated] = useState(false)

  useEffect(() => {
    resetModal()
  }, [])

  useEffect(() => {
    const shouldConsolidate = getShouldConsolidate()
    const icChanged = isConsolidated !== shouldConsolidate
    if (icChanged) setIsConsolidated(shouldConsolidate)
  }, [unitSpaces, isConsolidated])

  const getShouldConsolidate = () => {
    let sc = true
    for (let i in unitSpaces) {
      if (unitSpaces[i] === false) sc = false
    }
    return sc
  }

  const resetModal = () => {
    const row = rows[0] //this modal doesn't take bulk actions
    let newUnitSpaces = {}
    for (let i in row) {
      switch (i) {
        case 'unit':
        case 'type':
        case 'team_member':
        case 'team_uri':
        case 'operation_uri':
          continue
        default:
          break
      }
      newUnitSpaces[i] = false
    }
    const newIsConsolidated = false
    setUnitSpaces(newUnitSpaces)
    setIsConsolidated(newIsConsolidated)
  }

  const getCurrentService = () => {
    const { currentService } = services
    if (currentService) {
      return SERVICES.find(s => s.key === currentService) || {}
    }
    return {}
  }

  const getPopupOverrides = () => {
    const currentService = getCurrentService()
    const { popupOverrides } = currentService
    return popupOverrides || {}
  }

  const allCurrentlyAccepted = () => {
    let all = true
    Object.keys(unitSpaces).forEach(key => {
      if (unitSpaces[key] === true) all = false
    })
    return all ? 'selected' : ''
  }

  const allCurrentlyRejected = () => {
    let all = true
    Object.keys(unitSpaces).forEach(key => {
      if (unitSpaces[key] === false) all = false
    })
    return all ? 'selected' : ''
  }

  const isValid = useMemo(
    () => Object.values(unitSpaces).some(value => value),
    [unitSpaces]
  )

  const headerText = () => {
    const overrides = getPopupOverrides()
    return (
      overrides.acceptedLabel ||
      'All spaces in the unit need Go-Back? Simply select Go-Back All.'
    )
  }

  const detailedText = () => {
    const overrides = getPopupOverrides()
    return (
      overrides.detailedText ||
      'Any spaces need another look? Select Go-Back for each space individually.'
    )
  }

  const acceptAllText = () => {
    const overrides = getPopupOverrides()
    return `${overrides.acceptedLabel || 'Approve'} All`
  }

  const rejectAllText = () => {
    const overrides = getPopupOverrides()
    return `${overrides.rejectedLabel || 'Go-Back'} All`
  }

  const acceptText = () => {
    const overrides = getPopupOverrides()
    return overrides.acceptedLabel || 'Approve'
  }

  const rejectText = () => {
    const overrides = getPopupOverrides()
    return overrides.rejectedLabel || 'Go-Back'
  }

  // const getReasonsOptions = () => {
  //   let template = []
  //   for (let i = 0; i < REASONS.length; i += 1) {
  //     let r = REASONS[i]
  //     template.push(
  //       <option key={r.key} value={r.key}>
  //         {r.display}
  //       </option>
  //     )
  //   }
  //   return template
  // }

  const getGoBackUnitSpaces = row => {
    const taskUri = []
    const goBackTaskUri = []
    let gbus = { goback_spaces: [], goback_notes: [] }
    let consolidatedSet = false

    for (let i in unitSpaces) {
      const us = unitSpaces[i]
      const r = row[i]

      if (!us) {
        taskUri.push(r['task_uri'])
      } else {
        goBackTaskUri.push(r['task_uri'])
      }

      // Due to dynamic columns we are commenting this code

      // switch (i) {
      //   case 'common':
      //   case 'a':
      //   case 'b':
      //   case 'c':
      //   case 'd':
      //   case 'e':
      //     if (!us) {
      //       taskUri.push(r['task_uri'])
      //     } else {
      //       goBackTaskUri.push(r['task_uri'])
      //     }
      //     break

      //   default:
      //     break
      // }

      if (!r.display || !us) continue
      let reasonId = 'consolidated-reason'
      let notesId = 'consolidated-notes'
      if (!isConsolidated) {
        reasonId = `reason-${i}`
        notesId = `notes-${i}`
      }
      const reason = document.getElementById(reasonId).value
      const notes = document.getElementById(notesId).value

      if (us) {
        gbus.goback_spaces.push(i.toUpperCase())
        if (!isConsolidated)
          gbus.goback_notes.push(`${i.toUpperCase()}:${reason}:${notes}`)
        else if (!consolidatedSet) {
          gbus.goback_notes.push(`all:${reason}:${notes}`)
          consolidatedSet = true
        }
      }
    }
    return {
      goback_spaces: gbus.goback_spaces.join(','),
      goback_notes: gbus.goback_notes.join(','),
      task_uri: taskUri.filter(item => item).join(','),
      goback_task_uri: goBackTaskUri.filter(item => item).join(',')
    }
  }

  const currentUnitAccepted = us => (unitSpaces[us] ? '' : 'selected')

  const currentUnitRejected = us => (unitSpaces[us] ? 'selected' : '')

  const getUnitsTemplate = () => {
    const service = getCurrentService()
    const row = rows[0]

    const keysWithoutCommon = (Object.keys(row || {}) || [])
      .filter(item => item !== 'Common')
      .sort()

    const keysWithCommon = ['Common', ...keysWithoutCommon]

    const template = []
    const keysToIgnore = [
      'unit',
      'unit_id',
      'unit_uri',
      'grouping',
      'has_note',
      'type',
      'team_member',
      'team_uri',
      'operation_uri',
      'is_transfer',
      'is_goback',
      'is_asis',
      'is_priority',
      'vendor_notes',
      'service_notes'
    ]

    for (let key of keysWithCommon) {
      if (
        keysToIgnore.includes(key) ||
        !row[key]?.display ||
        ['N/A', 'OCCUPIED', 'AS-IS'].includes(row[key]?.display.toUpperCase())
      )
        continue

      const isGoBack = unitSpaces[key]
      const room = key

      template.push(
        <Fragment key={`unit-space-${key}`}>
          {/* m-b-lg */}
          <div
            className="d-flex justify-content-end align-items-center"
            style={{ marginTop: '-8px' }}>
            <div className="column is-offset-1 is-3">
              <div className="columns">
                <div className="column p-t-xs p-b-xs">{room}</div>
              </div>
            </div>
            <div className="column is-8" style={{ paddingRight: 20 }}>
              <div className="d-flex justify-content-end align-items-center is-desktop is-mobile button-option">
                {/* <div
                  className={`button-container column is-half is-unselectable has-text-centered p-t-xs p-b-xs is-pointer is-accept ${currentUnitAccepted(
                    key
                  )}`}
                  style={{
                    width: '50%',
                    transition: 'background-color 300ms'
                  }}
                  onClick={() => {
                    setUnitSpaces({
                      ...unitSpaces,
                      [key]: false
                    })
                  }}>
                  {acceptText()}
                </div> */}
                <div
                  className={`button-container is-unselectable has-text-centered p-t-xs p-b-xs is-pointer is-reject ${currentUnitRejected(
                    key
                  )}`}
                  style={{
                    border: '1px solid #d3d3d3',
                    width: '50%',
                    transition: 'background-color 300ms'
                  }}
                  onClick={() =>
                    setUnitSpaces({
                      ...unitSpaces,
                      [key]: true
                    })
                  }>
                  {rejectText()}
                </div>
              </div>
            </div>
          </div>
          {service.detailBedrooms && (row.name || row.email || row.phone) && (
            <div className="columns">
              <div className="column is-full">
                {row.name && <p>{`Resident: ${row.name}`}</p>}
                {row.phone && <p>{`Phone number: ${row.phone}`}</p>}
                {row.email && <p>{`Email: ${row.email}`}</p>}
              </div>
            </div>
          )}
          {isGoBack && !isConsolidated && (
            <>
              {reasonsDropdown(`reason-${key}`)}
              <div
                className="columns"
                style={{ marginBottom: '-13px', marginTop: '-21px' }}>
                <div className="column is-offset-1 is-11">
                  <label htmlFor={`notes-${key}`}>Notes</label>
                  <textarea
                    rows={3}
                    className="column is-offset-1 is-11 m-l-none m-t-xs m-b-sm"
                    placeholder="Add notes here..."
                    id={`notes-${key}`}
                    style={{ width: '100%', resize: 'none' }}
                  />
                </div>
              </div>
            </>
          )}
        </Fragment>
      )
    }
    return template
  }

  const reasonsDropdown = id => {
    const overrides = getPopupOverrides()
    return !overrides.ignoreReason ? (
      <div className="columns" style={{ marginTop: 0 }}>
        <div className="column is-offset-1 is-11 p-t-none">
          <label htmlFor={id}>Reason for Go-Back</label>
          <div className="select is-fullwidth m-t-xs">
            <select className="input is-fullwidth" id={id}>
              <option value="not-completed"> Not Completed</option>
              <option value="inadequate-work"> Inadequate Work</option>
              <option value="broken-item"> Broken Item</option>
            </select>
          </div>
        </div>
      </div>
    ) : null
  }

  const getUnitSpaces = row => {
    const overrides = getPopupOverrides()

    if (overrides.unitsSpacesField) {
      return overrides.unitsSpacesField(row, unitSpaces, isConsolidated)
    }

    return getGoBackUnitSpaces(row)
  }

  const { pdbid } = user.user_metadata
  const { currentService } = services
  const row = rows[0] //this modal does not accept bulk action

  if (
    currentService === 'Walk' ||
    currentService === 'Punch' ||
    currentService === 'Sparkle' ||
    currentService === 'Holdover'
  ) {
    return (
      <div>
        <div className="columns is-desktop is-mobile">
          <div className="column">
            <p className="heading-3 is-pulled-left">Approve</p>
            <p
              className="close is-pointer has-text-grey-light"
              onClick={() => {
                closeModal()
                resetModal()
              }}>
              <img alt="close" src={icons.close} />
            </p>
          </div>
        </div>
        <div className="columns is-desktop is-mobile wrapping-container">
          <div className="column is-full">
            This approval cannot be changed, be sure to double-check before you
            click Submit
          </div>
        </div>
        <div className="columns">
          <div className="column has-text-right">
            <button
              className="button is-secondary m-r-sm"
              onClick={() => {
                closeModal()
                resetModal()
              }}>
              Cancel
            </button>
            <button
              className="button is-primary"
              onClick={() => {
                const body = {
                  service: currentService,
                  team_uri: row.team_uri,
                  operation_uri: row.operation_uri,
                  unit: row.unit,
                  unit_id: row.unit_id,
                  unit_uri: row.unit_uri,
                  workorder: row.workorder,
                  qc_team_uri: row.qc_team_uri
                }

                updateServices(
                  pdbid,
                  context,
                  currentService?.replace('/', '%20'),
                  action,
                  {
                    ...body,
                    ...getUnitSpaces(row)
                  }
                )
                closeModal(true)
                resetModal()
              }}>
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="go-back-container">
      <div className="columns is-desktop is-mobile">
        <div className="column p-b-sm">
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between'
            }}>
            <p className="heading-3 is-pulled-left">Manage</p>
            <button
              className="button is-secondary m-r-sm"
              onClick={() => {
                resetModal()
              }}>
              Reset
            </button>
          </div>

          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              closeModal()
              resetModal()
            }}>
            <img alt="close" src={icons.close} />
          </p>
        </div>
      </div>
      <div className="columns is-desktop is-mobile wrapping-container">
        <div className="column is-full">
          <div className="columns">
            <div className="column p-t-none">
              <p className="unit-text">Unit {row.unit}</p>
            </div>
          </div>
          <div
            className="columns is-desktop is-mobile"
            style={{ marginBottom: 0 }}>
            <div className="column is-full">{headerText()}</div>
          </div>
          <div className="d-flex justify-content-end is-desktop is-mobile button-bar">
            {/* <div className="button-option column is-half">
              <div
                className={`button p-sm is-accept is-fullwidth ${allCurrentlyAccepted()}`}
                onClick={() => {
                  let newUS = {}
                  for (let i in unitSpaces) {
                    newUS[i] = false
                  }
                  setUnitSpaces(newUS)
                }}>
                {acceptAllText()}
              </div>
            </div> */}
            <div className="button-option column is-full">
              <div
                className={`button p-sm is-reject is-fullwidth ${allCurrentlyRejected()}`}
                onClick={() => {
                  let newUS = {}
                  for (let i in unitSpaces) {
                    newUS[i] = true
                  }
                  setUnitSpaces(newUS)
                }}>
                {rejectAllText()}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`column is-offset-1 is-11 ${
            isConsolidated ? '' : 'is-hidden'
          }`}>
          {reasonsDropdown('consolidated-reason')}
        </div>
        <div
          className={`column is-offset-1 is-11 ${
            isConsolidated ? '' : 'is-hidden'
          }`}>
          <div
            className="columns"
            style={{ marginBottom: '-13px', marginTop: '-21px' }}>
            <div className="column is-offset-1 is-11">
              <label htmlFor="consolidated-notes">Notes</label>
              <textarea
                rows={3}
                className="column is-full m-t-xs"
                placeholder="Add notes here..."
                id="consolidated-notes"
                style={{ resize: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="column is-full m-b-xs" style={{ marginTop: '-10px' }}>
          {detailedText()}
        </div>
        <div
          className="column is-full"
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: '-16px',
            marginBottom: '-6px'
          }}>
          {getUnitsTemplate()}
        </div>
      </div>
      <div className="columns">
        <div className="column foot-note">
          Note: These selections cannot be changed, so be sure to double-check
          before you click Submit
        </div>
      </div>
      <div className="columns">
        <div className="column has-text-right">
          <button
            className="button is-secondary m-r-sm"
            onClick={() => {
              closeModal()
              resetModal()
            }}>
            Cancel
          </button>
          <button
            className={`button is-primary ${!isValid && 'isDisabled'} `}
            onClick={() => {
              if (isValid) {
                const body = {
                  service: currentService,
                  servicetype_id: row.servicetype_id,
                  team_uri: row.team_uri,
                  operation_uri: row.operation_uri,
                  unit: row.unit,
                  unit_id: row.unit_id,
                  unit_uri: row.unit_uri,
                  workorder: row.workorder,
                  qc_team_uri: row.qc_team_uri
                }

                updateServices(
                  pdbid,
                  context,
                  currentService?.replace('/', '%20'),
                  action,
                  {
                    ...body,
                    ...getUnitSpaces(row)
                  }
                )
                closeModal(true)
                resetModal()
              }
            }}>
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

ServicesModalManage.propTypes = {
  closeModal: PropTypes.func,
  updateServices: PropTypes.func,
  user: PropTypes.object,
  services: PropTypes.object,
  action: PropTypes.string,
  rows: PropTypes.array,
  modal: PropTypes.object,
  context: PropTypes.object
}

export default ServicesModalManage
