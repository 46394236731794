import { DateUtils } from 'react-day-picker'
import dateFnsParse from 'date-fns/parse'
import dateFnsFormat from 'date-fns/format'

export const calendarConfig = {
  firstDayOfWeek: 1,
  FORMAT: 'MM/dd/yyyy',
  weekdaysShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  modifiers: {
    past: { before: new Date() },
    weekS: { daysOfWeek: [1] },
    weekE: { daysOfWeek: [0] }
  }
}

export const parseDate = (str, format, locale) => {
  const parsed = dateFnsParse(str, format, new Date(), { locale })
  if (DateUtils.isDate(parsed)) {
    return parsed
  }
  return undefined
}

export const formatDate = (date, format, locale) =>
  dateFnsFormat(date, format, { locale })
