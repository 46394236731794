import React, { useMemo } from 'react'

const StatusColumnFilter = ({
  column,
  toggleAllRowsSelected,
  selectedFlatRows
}) => {
  const { filterValue, setFilter, preFilteredRows } = column || {}
  const id = column?.id

  const options = useMemo(() => {
    const distinct = new Set()
    const options = []

    ;(preFilteredRows || []).forEach(row => {
      const original = row?.original
      const val = original[id]
      if (val && !distinct.has(val)) {
        distinct.add(val)
        options.push({ [id]: val })
      }
    })

    return options.sort((a, b) => {
      const x = a[id].toLowerCase()
      const y = b[id].toLowerCase()
      if (x > y) return 1
      if (x < y) return -1
      return 0
    })
  }, [preFilteredRows])

  return (
    <select
      style={{ marginTop: '8px' }}
      name="shared"
      value={filterValue || 'all'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {(options || []).map(option => (
        <option key={option[id]}>{option[id]}</option>
      ))}
    </select>
  )
}

export default StatusColumnFilter
