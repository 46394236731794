import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import './index.scss'

/**
 * Component for showing a basic modal that can
 * toggle between open and close, and can alter
 * its contents through props
 *
 * @component
 * @example
 * const defaults = {
 *  address1: '123 Street',
 *  address2: 'Suite 300',
 *  city: 'Chicago',
 *  state: 'IL',
 *  zip: '60007',
 *  country: 'United States of America'
 * }
 * return (
 *   <AddressDisplay defaults={defaults} />
 * )
 */
const BasicModal = ({
  isActive = false,
  isDisabled = false,
  headerText = 'Header Text',
  modalContent = null,
  primaryAction = null,
  closeAction = null,
  hideHeader = true,
  hideFooter = true,
  width = '',
  maxWidth = '100%',
  backgroundColor = '#ffffff',
  overflow,
  additionalClasses,
  shouldBeFullWidth = false,
  additionalBodyClasses
}) => {
  useEffect(() => {
    const modalCard = document.getElementById('modal-card')
    const { height } = modalCard.getBoundingClientRect()
    modalCard.style.top = `-${height / 8}px`
  })

  const realWidth = shouldBeFullWidth ? width || '100%' : width
  const modalStyle = {
    width: realWidth,
    maxWidth,
    overflow: overflow || 'hidden'
  }

  return (
    <div
      className={cn(
        'basic-modal modal',
        { [additionalClasses]: additionalClasses },
        { 'is-active': isActive }
      )}>
      <div className="modal-background" />
      <div
        className="modal-card animated fadeIn"
        id="modal-card"
        style={modalStyle}>
        <header className={`modal-card-head ${hideHeader ? 'is-hidden' : ''}`}>
          <p className="is-size-4">{headerText}</p>
        </header>
        <section
          style={{ backgroundColor }}
          id="modal-card-body"
          className={cn('modal-card-body', {
            [additionalBodyClasses]: additionalBodyClasses
          })}>
          {typeof modalContent === 'function' ? modalContent() : ''}
        </section>
        <footer className={`modal-card-foot ${hideFooter ? 'is-hidden' : ''}`}>
          <button className="button close" onClick={closeAction}>
            Close
          </button>
          <button
            className={'button is-primary primary-action'}
            onClick={primaryAction}
            disabled={isDisabled}>
            Update
          </button>
        </footer>
      </div>
    </div>
  )
}

BasicModal.propTypes = {
  /**
   * Determine if modal is currently active
   */
  isActive: PropTypes.bool,
  /**
   * Determine if modal is currently disabled
   */
  isDisabled: PropTypes.bool,
  /**
   * Text to how in the header of the modal
   */
  headerText: PropTypes.string,
  /**
   * Function that returns the current content for the modal
   */
  modalContent: PropTypes.func,
  /**
   * Event handler for the close action
   */
  closeAction: PropTypes.func,
  /**
   * Event handler for the primary action
   */
  primaryAction: PropTypes.func,
  /**
   * Determine if the header should be hidden
   */
  hideHeader: PropTypes.bool,
  /**
   * Determine if the footer should be hidden
   */
  hideFooter: PropTypes.bool,
  /**
   * Modal width (in text, with units)
   */
  width: PropTypes.string,
  /**
   * Background color for the modal
   */
  backgroundColor: PropTypes.string,
  /**
   * Overflow attribute for the modal
   */
  overflow: PropTypes.string,
  /**
   * Determine if the modal should be shown full-width
   */
  shouldBeFullWidth: PropTypes.bool,
  /**
   * if you want to add additional css classes
   */
  additionalClasses: PropTypes.string
}

export default BasicModal
