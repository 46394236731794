import Service from '../service'
import LOGOUT from './auth'
import propertyFloorplansMock from '../mocks/property_floorplans.mock'

/**
 * Setup properties (floor plans) data module
 * @redux
 * @reduxActionScope setup-properties-floorplans
 * @module setup-properties-floorplans
 */

/**
 * Request floor plans list for the current property
 * @type {Redux.ActionType}
 */
export const GET_REQUESTED = 'setup-properties-floorplans/GET_REQUESTED'
/**
 * floor plans list request failed
 * @type {Redux.ActionType}
 */
export const GET_FAIL = 'setup-properties-floorplans/GET_FAIL'
/**
 * Update requested floor plans list with successful data from API
 * @type {Redux.ActionType}
 */
export const GET_SUCCESS = 'setup-properties-floorplans/GET_SUCCESS'

/**
 * Request property floor plan creation
 * @type {Redux.ActionType}
 */
export const CREATE_REQUESTED = 'setup-properties-floorplans/CREATE_REQUESTED'
/**
 * Property floor plan creation failed
 * @type {Redux.ActionType}
 */
export const CREATE_FAIL = 'setup-properties-floorplans/CREATE_FAIL'
/**
 * Refresh property details after a successful floor plan creation verified by the API
 * @type {Redux.ActionType}
 */
export const CREATE_SUCCESS = 'setup-properties-floorplans/CREATE_SUCCESS'

/**
 * Request property floor plan update
 * @type {Redux.ActionType}
 */
export const UPDATE_REQUESTED = 'setup-properties-floorplans/UPDATE_REQUESTED'
/**
 * Property floor plan update failed
 * @type {Redux.ActionType}
 */
export const UPDATE_FAIL = 'setup-properties-floorplans/UPDATE_FAIL'
/**
 * Refresh property details after a successful floor plan update verified by the API
 * @type {Redux.ActionType}
 */
export const UPDATE_SUCCESS = 'setup-properties-floorplans/UPDATE_SUCCESS'

/**
 * Request property floor plan delete
 * @type {Redux.ActionType}
 */
export const DELETE_REQUESTED = 'setup-properties-floorplans/DELETE_REQUESTED'
/**
 * Property floor plan delete failed
 * @type {Redux.ActionType}
 */
export const DELETE_FAIL = 'setup-properties-floorplans/DELETE_FAIL'
/**
 * Refresh property details after a successful floor plan delete verified by the API
 * @type {Redux.ActionType}
 */
export const DELETE_SUCCESS = 'setup-properties-floorplans/DELETE_SUCCESS'

const DF = ''

const floorplanInitialState = {
  uri: DF,
  id: DF,
  name: DF,
  square_feet: DF,
  bed_count: DF,
  bath_count: DF
}

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: floorplanInitialState
}

/**
 * Setup properties (floor plans) reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case DELETE_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case GET_FAIL:
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all floor plans for a property
 * @redux
 * @reduxActionCreator GET_REQUESTED, GET_SUCCESS, GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id for the requested property
 */
export const setupOnePropertyGetFloorplans = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: GET_REQUESTED
    })
    Service.setupOnePropertyGet(pdbid, context, id, 'floorplans')
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          const data = propertyFloorplansMock
          dispatch({
            type: GET_SUCCESS,
            data: _floorplansAdapter(data)
          })
        } else {
          _setupOnePropertyGetFloorplansFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyGetFloorplansFail(dispatch)
      })
  }
}

const _setupOnePropertyGetFloorplansFail = dispatch => {
  dispatch({
    type: GET_FAIL
  })
}

const _floorplansAdapter = floorplans => {
  let arr = []
  for (let i = 0; i < floorplans.length; i += 1) {
    const f = floorplans[i]
    arr.push({
      uri: typeof f.floorplan_uri === 'string' ? f.floorplan_uri : DF,
      id: typeof f.floorplan_id === 'string' ? f.floorplan_id : DF,
      name: typeof f.floorplan_name === 'string' ? f.floorplan_name : DF,
      square_feet:
        typeof f.square_feet === 'string' ? parseInt(f.square_feet) : 0,
      bed_count: typeof f.bed_count === 'string' ? parseInt(f.bed_count) : 0,
      bath_count: typeof f.bath_count === 'string' ? parseInt(f.bath_count) : 0
    })
  }
  return arr
}

/**
 * Creates a new floor plan for a property
 * @redux
 * @reduxActionCreator CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new floor plan (including the property id)
 */
export const setupOnePropertyFloorplanCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: CREATE_REQUESTED
    })
    Service.setupOnePropertyCreate(pdbid, context, '', 'floorplans', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CREATE_SUCCESS,
            data: _floorplansAdapter(data)
          })
        } else {
          _setupOnePropertyFloorplanCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyFloorplanCreateFail(dispatch)
      })
  }
}

const _setupOnePropertyFloorplanCreateFail = dispatch => {
  dispatch({
    type: CREATE_FAIL
  })
}

/**
 * Updates an existing floor plan
 * @redux
 * @reduxActionCreator UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing floor plan
 * @param {Object} body - the JSON object for the updated floor plan
 */
export const setupOnePropertyFloorplanUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyUpdate(pdbid, context, id, 'floorplans', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _floorplansAdapter(data)
          })
        } else {
          _setupOnePropertyFloorplanUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyFloorplanUpdateFail(dispatch)
      })
  }
}

const _setupOnePropertyFloorplanUpdateFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}

/**
 * Deletes an existing floor plan
 * @redux
 * @reduxActionCreator DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing floor plan
 */
export const setupOnePropertyFloorplanDelete = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyDelete(pdbid, context, id, 'floorplans')
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _floorplansAdapter(data)
          })
        } else {
          _setupOnePropertyFloorplanDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyFloorplanDeleteFail(dispatch)
      })
  }
}

const _setupOnePropertyFloorplanDeleteFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}
