import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import ApprovalsList from '../approvals-list'

const ApprovalsDashboardMain = props => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
      </div>
      <div className="section">
        <ApprovalsList {...props} />
      </div>
    </div>
  )
}

export default ApprovalsDashboardMain
