import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

const NavItem = ({ autoClose, currentPath, menuItem }) => {
  const { shouldHighlight } = menuItem
  const isActive = shouldHighlight
    ? shouldHighlight(currentPath)
    : currentPath === menuItem.path

  return (
    <li>
      <Link
        to={menuItem.path}
        onClick={autoClose || null}
        className={cn(
          'has-text-white',
          { 'is-active': isActive },
          { 'menu-item--isolated': menuItem.isolated }
        )}>
        <div className="columns is-desktop is-mobile is-vcentered is-gapless item-container">
          <div
            className="column is-narrow menu-icon"
            style={{ display: 'flex', padding: 0 }}>
            <img
              src={menuItem.iconSrc}
              className="is-inline"
              alt={menuItem.altText}
            />
          </div>
          <div className="column has-text-weight-bold">{menuItem.name}</div>
          {menuItem.render && menuItem.render()}
        </div>
      </Link>
    </li>
  )
}

export default NavItem
