import React,{ useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import {
  allRFPTitleGet,
} from '../../modules/request-rfp'
import DatePickerInput from '../../components/date-picker'
import { formatDateString } from '../../utils/index'
import _ from 'lodash'
import './index.scss'

const AddRFPModal = ({ onClose, allRFPTitleGet, requestRFPS, user, context, requestVendors, addRFPRequest, allRequestRFPGet, checkRFPVendorExists }) => {

  useEffect(() => {
    setUpdateRFPList(false)
    const {user_metadata} = user || {}
    const { pdbid } = user_metadata || {}
    allRFPTitleGet(pdbid, context)
  }, [])
  // All RFP Titles
  const { allRFPTitle } = requestRFPS || {}
  const { data: currentRFPTitles } = allRFPTitle || {}
  // All Vendors
  const { allVendor } = requestVendors || {}
  const { data: vendors } = allVendor || {}

  const currentVendors = _.orderBy(vendors, [vendor => vendor.name.toLowerCase()], 'asc')
  // States
  const [selectedVendors, setSelectedVendors] = useState([])
  const [rfpName, setRFPName] = useState('')
  const [dueDate, setDueDate] = useState(new Date())
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [message, setMessage] = useState('')
  const [vendorError, setVendorError] = useState([])
  const [updateRFPList, setUpdateRFPList] = useState(false)

  useEffect(() => {
    if(updateRFPList) {
      const {user_metadata} = user || {}
      const { pdbid } = user_metadata || {}
      allRequestRFPGet(pdbid, context)
      onClose()
    }

  }, [updateRFPList])

  const saveRFP = (event) => {
    event.preventDefault()
    checkVendorList()
  }

  const constructRfP = () => {

    const convertVendorsToAcceptableValue = ((selectedVendors || []).map(sVendors => sVendors.vender_id) || []).join(',')

    const vendorsData = (selectedVendors || []).map((vendor) => {
      const { name, email } = vendor || {}
      return {
        name,
        email
      }
    })

    const { user_metadata } = user || {}
    const { property } = context || {}
    const { pdbid, properties } = user_metadata || {}

    const selectedProperty = (properties || []).filter((p) => p.id === property)
    const { name } = selectedProperty[0] || {}

    if(rfpName === '' || rfpName === 'Select' || dueDate === '' || (selectedVendors || []).length === 0 || (vendorError || []).length !== 0) {
      setErrorMessage('You need to fill all the required fields')
      return
    }

    setSubmitting(true)

    const body = {
      'rfp_form_id': rfpName,
      'due_date': formatDateString(dueDate, 'YYYY-MM-DD'),
      'vendors':convertVendorsToAcceptableValue,
      vendorsData,
      'property_name': name
    }

    addRFPRequest({
      pdbid, 
      context, 
      body,
      onSuccess: () => {
        setMessage('RFP Request added successfully.')
        setUpdateRFPList(true)
        setSubmitting(false)
        setVendorError({})
      },
      onError: (error) => {
        setSubmitting(false)
        setErrorMessage(error)
      }
    })
  }

  const checkVendorList = async () => {
    setErrorMessage('')
    const requests =await selectedVendors.map(vendor =>  checkIfVendorExists({
      'rfp_vendor_id':vendor.vender_id,
      'rfp_form_id':rfpName,
    }))
    await Promise.all(requests)
      .then(responses => {
      // all responses are resolved successfully
        const vendorExists = []
        for(let response of responses) {
          const { exists, vendor_id } = response || {}
          if(exists === '1') {
            const filterVendor = (currentVendors || []).filter(cVendor => cVendor.vender_id === vendor_id)
            vendorExists.push(filterVendor[0] || {})
          }
        }
        setVendorError(vendorExists)
        if((vendorExists || []).length === 0) {
          constructRfP()
        }
        return responses
      }).catch(err => setErrorMessage('Something went wrong...'))
  }

  const checkIfVendorExists = async (body) => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const returnPromise = await checkRFPVendorExists({
      pdbid, 
      context, 
      body,
    })
    return  returnPromise
  }

  const onChangeSelectedVendor = (vendors) => {
    const checkVendorErrors = (vendorError || [])
    const difference = (selectedVendors || []).filter(vendor => !(vendors || []).includes(vendor))
    if(checkVendorErrors.length !== 0) {
      const currentVendor = difference[0] || {}
      const updateError = (checkVendorErrors || []).filter(err => err.vender_id !== currentVendor.vender_id)
      setVendorError(updateError)
    }
    setSelectedVendors(vendors)
  }
  


  const checkCanSubmit = (isSubmitting || rfpName === '' || (selectedVendors || []).length === 0 || (Object.keys(vendorError) || []).length > 0)

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            New RFP Request
          </p>
        </div>
      </div>
      <div className="is-full">
        <form>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="businessName">
                  RFP:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <select
                className="input is-inline"
                onChange={(e) => setRFPName(e.target.value)}
                value={rfpName}>
                <option value="">Select</option>
                {(currentRFPTitles || []).map(rfpTitle => <option key={rfpTitle.rfp_from_id} value={rfpTitle.rfp_from_id}>{rfpTitle.name}</option>)}
              </select>
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="businessName">
                  Vendor:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <MultiSelectDropdown 
                displayKey="name" 
                value={selectedVendors} 
                defaultValues={currentVendors || []} 
                onChange={(v) => onChangeSelectedVendor(v)} 
                customStyles = {{
                  valueContainer: provided => ({
                    ...provided,
                    minHeight: '45px',
                    maxHeight: '100px',
                    overflow: 'auto',
                    position: 'relative'
                  }),
                }} 
              />
              { (vendorError || []).length !== 0 && vendorError.map(err => <div className="help-block text-danger">
                {`${err.name} already exists`}
              </div>)
                
              }
            </div>
          </div>
          <div className="form-group columns">
            <div className="label column is-one-third">
              <label htmlFor="businessName">
                  Due Date:
              </label>
            </div>
            <div className="input-field column is-two-thirds">
              <DatePickerInput value={dueDate} minDate={ new Date() } onChange={(v) => setDueDate(v)} />
            </div>
          </div>  
          {
            message !== '' && <div className="alert alert-success">
              { message }
            </div>
          }
          {
            errorMessage !== '' && <div className="alert alert-danger">
              { errorMessage }
            </div>
          }
          <div className="columns is-vcentered m-b-sm m-t-sm">
            <div className="center-container">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => {
                  onClose()
                }}>
                  Cancel
              </button>

              <button disabled={checkCanSubmit} className="button main-button is-primary" onClick={ (event) => saveRFP(event)}>
                  Send Request
              </button>
            </div>
          </div>  
        </form>
      </div>  
    </div>  
  )
}

AddRFPModal.propTypes = {
  onClose: PropTypes.func,
  allRFPTitleGet: PropTypes.func,
  requestRFPS: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  requestVendors: PropTypes.object,
  addRFPRequest: PropTypes.func,
  allRequestRFPGet: PropTypes.func,
  checkRFPVendorExists: PropTypes.func,
}

const mapStateToProps = ({ requestRFPS, requestVendors }) => ({
  requestRFPS,
  requestVendors
})


const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      allRFPTitleGet
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRFPModal)
