import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import cn from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faMapMarkerAlt,
  faHome
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getMenu, menuItemTypes, isBlacklisted } from './sidebarUtilities'
import ParentItem from './parentItem'
import {DropdownItem} from './dropdownItem'
import NavItem from './navItem'
import './index.scss'
import { isMobile } from '../../utils/helperFunctions'
import { mainSidebarIcons } from 'ui/icons'
import { getIncompleteUnitSpaces, getServicesByProperty } from '../../modules/sidebar'

const OPEN = '275px'
const CLOSED = '0px'

const ctxStyle = {
  marginLeft: '1.8rem'
}
export default function Sidebar(props) {
  const {
    isOpen,
    context,
    requestContextChange,
    user,
    currentPath,
    stayOpen,
    closeSidebar
  } = props
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const { servicesRequested, services, incompletedUnitspaces,
    incompletedUnitspacesStatus, } = useSelector(state => state.sidebar)
  const { downloadState } = useSelector(state => state.downloadInspections)
  const { projects, properties, type } = user.user_metadata
  const currentProperty = _.find(properties, p => p.id === context.property)
  const [isInTransition, setIsInTransition] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [loadingServices, setLoadingServices] = useState(false)
  const [currentServices, setCurrentServices] = useState([])

  const { isQCLocked, isVendorLocked } = useMemo(() => {
    const { pathname } = location
    const { roles } = user || {}
    const role = (roles?.[0] || '').toLowerCase()

    // Accessing to QC routes as non-staff & non-all user is not allowed.
    const isQCLocked =
      pathname.startsWith('/qc') &&
      ((!isMobile() && role !== 'staff') || (isMobile() && role === 'vendor'))

    // Accessing to Vendor routes as non-vendor & non-all user is not allowed.
    const isVendorLocked = pathname.startsWith('/vendor') && role !== 'vendor'

    return { isQCLocked, isVendorLocked }
  }, [location, user, context])

  // if user type is vendor, only allow home page.
  const [, path] = window.location.pathname.split('/')
  const isMainPage =
    (path !== 'qc' || isQCLocked) && (path !== 'vendor' || isVendorLocked)
  const display = {
    project: (projects || []).find(p => p.id === context.project) || {
      name: ''
    },
    property: (properties || []).find(p => p.id === context.property) || {
      name: ''
    }
  }

  const property = _.get(context, 'property', '')
  const activeMenu = getMenu(
    user,
    context,
    property,
    currentServices,
    path,
    downloadState,
    incompletedUnitspaces,
    incompletedUnitspacesStatus
  )
    // .filter(menuItem => {
    //   return !isBlacklisted(user, menuItem, property)
    // })
    .map(menuItem => {
      if (menuItem.name === 'Services') {
        menuItem['loading'] = loadingServices
      }
      return menuItem
    })

  const onTransition = which => e => {
    if (e.propertyName !== 'width') return
    setIsInTransition(which === 'start')
  }

  useEffect(() => {
    const el = document.getElementById('sidebar')
    if (!initialized) {
      el.addEventListener('transitionstart', onTransition('start'), {}, true)
      el.addEventListener('transitionend', onTransition('end'), {}, true)
      setInitialized(initialized)
    }
    return () => {
      el.removeEventListener('transitionstart', onTransition)
      el.removeEventListener('transitionend', onTransition)
    }
  }, [initialized])

  useEffect(() => {
    setLoadingServices(true)
    const {
      user_metadata: { pdbid }
    } = user
    if (pdbid && context.property){
      dispatch(getServicesByProperty(pdbid, context))
      dispatch(getIncompleteUnitSpaces(pdbid,context))
    }
      
  }, [currentProperty])

  useEffect(() => {
    if (!servicesRequested && loadingServices) {
      setCurrentServices(services)
      setLoadingServices(false)
    }
  }, [servicesRequested, services])

  const autoClose = () => {
    if (isOpen && !stayOpen) {
      closeSidebar()
    }
  }

  const handleLogout = e => {
    e.preventDefault()
    autoClose()
    return history.push('/logout')
  }
  const propertyMapUrl = `/${path === 'vendor' ? 'vendor' : 'qc'}/property-map`
  const appHome = '/'

  return (
    <aside
      id="sidebar"
      className={cn(
        'menu is-fixed has-background-link is-fullheight nav-link',
        { 'p-t-md': stayOpen }
      )}
      style={{
        width: isOpen ? OPEN : CLOSED,
        //fontSize: '0.9rem',
        transition: 'width 500ms'
      }}>
      <ul
        className="menu-list"
        style={{
          opacity: !isOpen || isInTransition ? 0 : 1,
          transition: 'opacity 350ms'
        }}>
        <li
          className="has-background-success is-hidden-desktop"
          onClick={() => {
            requestContextChange()
            autoClose()
          }}>
          <p className="has-text-white" style={ctxStyle}>
            {display.project.name} {'>'} {display.property.name}
          </p>
        </li>

        {isMainPage ? (
          activeMenu.map((menuItem, i) => {
            if (menuItem.type === menuItemTypes.dropdown) {
              return (
                <DropdownItem
                  key={i}
                  menuItem={menuItem}
                  currentPath={currentPath}
                  onClick={() => {
                    autoClose()
                  }}
                  user={user}
                  property={property}
                  loading={menuItem.loading}
                  incompletedUnitspacesStatus = {incompletedUnitspacesStatus}
                  incompletedUnitspaces = {incompletedUnitspaces}
                />
              )
            } else if (menuItem.type === menuItemTypes.section) {
              return (
                <ParentItem
                  key={i}
                  menuItem={menuItem}
                  currentPath={currentPath}
                  user={user}
                  property={property}
                />
              )
            } else {
              return (
                <NavItem
                  key={i}
                  currentPath={currentPath}
                  autoClose={autoClose}
                  menuItem={menuItem}
                />
              )
            }
          })
        ) : (
          <>
            <li key="app-home">
              <Link to={appHome} onClick={autoClose} className="has-text-white">
                <div className="columns is-desktop is-mobile is-vcentered is-gapless">
                  <div className="column is-narrow menu-icon app-menu-home-icon">
                    <FontAwesomeIcon icon={faHome} size="lg" />
                  </div>
                  <div className="column">Home</div>
                </div>
              </Link>
            </li>
            {/* <li key="property-map">
              <Link
                to={propertyMapUrl}
                onClick={autoClose}
                className={cn('has-text-white', {
                  'is-active': currentPath.indexOf('/property-map') > -1
                })}>
                <div className="columns is-desktop is-mobile is-vcentered is-gapless">
                  <div className="column is-narrow menu-icon app-menu-map-icon">
                    <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
                  </div>
                  <div className="column">Map</div>
                </div>
              </Link>
            </li> */}
            <li key="logout">
              <a href="/" className="has-text-white" onClick={handleLogout}>
                <div className="columns is-desktop is-mobile is-vcentered is-gapless">
                  <div className="column is-narrow menu-icon app-menu-logout-icon">
                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                  </div>
                  <div className="column">Logout</div>
                </div>
              </a>
            </li>
          </>
        )}
      </ul>
    </aside>
  )
}

Sidebar.propTypes = {
  currentPath: PropTypes.string,
  context: PropTypes.object,
  isOpen: PropTypes.bool,
  stayOpen: PropTypes.bool,
  closeSidebar: PropTypes.func,
  sidebar: PropTypes.object,
  requestContextChange: PropTypes.func,
  user: PropTypes.object,
  logout: PropTypes.func
}
