import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const VendorTabOptions = ({ tabs, style, currentActiveTab, touchedChildTabs, type, serviceName, isEdit, childTabType }) => {
  const [tabOptionsCopy, setTabOptionsCopy] = useState([])

  // To update tabs State
  useEffect(() => {
    const currentTabsData= tabs || []
    let updatedTabsData = []
    if(type !== 'child') {
      updatedTabsData = (currentTabsData|| []).map(item => {
        item.label === currentActiveTab ? item.active = true : item.active = false
        return item
      })
    } else {
      updatedTabsData = (currentTabsData|| []).map(item => {
        item.label === childTabType ? item.active = true : item.active = false
        return item
      })
    }
    setTabOptionsCopy(updatedTabsData)
  },[tabs])

  const handleTabOptionsClick = useCallback(tab => {
    tab.onClick(tab)
    setTabOptionsCopy(currentTabs => (currentTabs || []).map(item => {
      item === tab ? item.active = true : item.active = false
      return item
    }))
  }, [tabs])

  useEffect(() => {
    if(currentActiveTab) {
      setTabOptionsCopy(currentTabs => (currentTabs || []).map(item => {
        item.label === currentActiveTab ? item.active = true : item.active = false
        return item
      }))
    }
  },[currentActiveTab])

  useEffect(() => {
    if(childTabType && type === 'child') {
      setTabOptionsCopy(currentTabs => (currentTabs || []).map(item => {
        item.label === childTabType ? item.active = true : item.active = false
        return item
      }))
    }
  },[childTabType])

  const tabOptionHoverHandler = (color, {target}) => target.style.background = color
  const tabOptionOutHandler = (isActiveTab, {target}) => !isActiveTab ? target.style.background = '' : null

  // Check touched child tab to determine which tab is touched by user
  const getLabel = (label) => {
    if(touchedChildTabs) {
      const data = touchedChildTabs[serviceName] || {}
      if(type === 'child' && isEdit) {
        if(!data[label]) {
          return (
            <>
            {label}
            <span></span>
            </>
          )
        }
        return label
      }
      return label
    } 
    return label
    
  }

  return (
    <div className="tab-options-container" style={{...style}}>
      <ul className="tab-options">
        {tabOptionsCopy.map(tab => (
          <li
            key={tab.label}
            className={`${tab.active ? 'active' : ''}`}
            onMouseOver={tab.overrideActiveBackground ? (event) => tabOptionHoverHandler(tab.overrideActiveBackground, event) : null}
            onMouseOut={tab.overrideActiveBackground ? (event) => tabOptionOutHandler(tab.active, event) : null}
            style={{ background: (tab.active && tab.overrideActiveBackground) || '' }}
            onClick={(e) =>{
              e.preventDefault()
              handleTabOptionsClick(tab)
            }}>
            {getLabel(tab.label)}
          </li>
        ))}
      </ul>
    </div>
  )
}

VendorTabOptions.propTypes = {
  /**
   * Array which contain all Tabs information
  */
  tabs: PropTypes.array,
  /**
   * Name of the current active tab
  */
  currentActiveTab: PropTypes.string,
  /**
   * List of all child tabs which are touched by end user
  */
  touchedChildTabs: PropTypes.object,
  /**
   * If current tab belong to child or parent tabs
  */
  type: PropTypes.string,
  /**
   * Name of the current active service
  */
  serviceName: PropTypes.string,
  /**
   * Name of the current child active tab
  */
  childTabType: PropTypes.string,
  /**
   * To determine the edit state
  */
  isEdit: PropTypes.bool,
}

export default VendorTabOptions

