import React, { useState, useRef, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { headerData } from './header'
import { Data } from './meta-data'
import VendorLoginTable from './vendor-login-table/vendor-table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { exportFile } from 'ui/icons'
import moment from 'moment'
import { useSelector } from 'react-redux'
import BigLoading from '../../components/big-loading'
import './index.scss'

const VendorLoginList = props => {
  const { user, allVendorAssociatedUsers, context, vendor_id } = props || {}
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [detailOpened, setDetailOpened] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const tableRef = useRef()
  const { associatedVendorUserList } = useSelector(state => state.setupUsers)
  const { isRequesting, data, hasMadeInitialRequest } =
    associatedVendorUserList || {}

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  // useEffect(() => {
  //   allVendorAssociatedUsers(pdbid, context, vendor_id)
  // }, [context, pdbid])

  const columns = useMemo(() => headerData(), [])

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = tableRef.current?.getRows() || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows

    const filterData = (defaultData || []).map(list => {
      const { name, email, status, login_access } = list || {}
      return {
        Name: name || '',
        Email: `"${email}"` || '',
        Status: status || '',
        Login: login_access || ''
      }
    })

    const data = filterData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `${datetime}-vendor-login.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    const checkSingleFilter = (isFilterApplied || []).filter(
      o => (o.value || []).length !== 0
    )
    return checkSingleFilter
  }, [isFilterApplied])

  const toggleList = () => {
    const { toggleAllRowsSelected } = tableRef?.current || {}
    setDetailOpened(!detailOpened)
    toggleAllRowsSelected(false)
    onResetFilter()
  }

  return (
    <div className="vendor-login-list-container">
      <div
        className="data-table-wrapper"
        style={{ border: detailOpened ? '1px solid #e5e9f2' : '0px' }}>
        <div className="data-table-header">
          <div className="header">
            <div className="sub-header">
              <h2 className="heading-5">Vendor Logins</h2>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={() => {
                    onResetFilter()
                  }}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>

            <div className="sub-header">
              {detailOpened ? (
                <button className="export" onClick={() => exportCSV()}>
                  <img src={exportFile} alt="Export Vendor Login User" />
                  Export
                </button>
              ) : null}
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => toggleList()}
                style={{ cursor: 'pointer' }}
                className={`down-arrow m-r-sm ${
                  detailOpened ? 'is-upsidedown' : ''
                }`}
              />
            </div>
          </div>
        </div>
        <div className={`detail ${detailOpened ? 'open' : ''}`}>
          {hasMadeInitialRequest && !isRequesting ? (
            <VendorLoginTable
              data={data}
              ref={tableRef}
              tableColumns={columns}
              setIsFilterApplied={setIsFilterApplied}
              onRowSelectStateChange={setSelectedRows}
            />
          ) : (
            <BigLoading />
          )}
        </div>
      </div>
    </div>
  )
}

VendorLoginList.propTypes = {
  user: PropTypes.object,
  setupStaff: PropTypes.object,
  setupAllStaffGet: PropTypes.func
}

export default VendorLoginList
