import exports from './icons/exports.svg'
import transfers from './icons/transfers.svg'
import asIs from './icons/asis.svg'
import fullAsIs from './icons/full-asis.svg'
import carpet from './icons/carpet.svg'
import question from './icons/question_circle.svg'
import legend from './icons/legend.png'
import note from './icons/note.svg'
import partiallyOccupied from './icons/partially-occupied.svg'
import fullyVacant from './icons/fully-vacant.svg'
import fullyRenewed from './icons/fully-renewed.svg'
import questionCircle from './icons/question_circle.svg'
import exportIcon from './icons/export-icon.svg'
import addButton from './icons/addButton.svg'
import collapseIcon from './icons/collapse.svg'
import showMore from './icons/show-more.svg'
import addTagButton from './icons/addTagButton.svg'
import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'
import dashIcon from './icons/dash.svg'
import circleFilledIcon from './icons/circle-filled.svg'
import circleFilledGreenIcon from './icons/circle-filled-green.svg'
import circleEmptyIcon from './icons/circle-empty.svg'
import questionMarkIcon from './icons/question-mark.svg'

export const icons = {
  exports,
  transfers,
  asIs,
  fullAsIs,
  question,
  legend,
  carpet,
  note,
  partiallyOccupied,
  fullyVacant,
  fullyRenewed,
  questionCircle,
  exportIcon,
  addButton,
  collapseIcon,
  showMore,
  addTagButton,
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff,
  dashIcon,
  circleFilledIcon,
  circleEmptyIcon,
  circleFilledGreenIcon,
  questionMarkIcon
}
