import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ProgressBar from '../progress-bar'
import './index.scss'

const ProgressCardNew = ({
  data,
  showLoading,
  topStat,
  caption,
  percentComplete,
  className
}) => {
  const showSpinner = () => <FontAwesomeIcon icon={faSpinner} spin />

  const displayData = () =>
    data.map((d, i) => (
      <p className="caption info-item" key={`progress-stat-${i}`}>
        {d.display}:
        <span className="is-pulled-right">
          {showLoading ? showSpinner() : d.stat}
        </span>
      </p>
    ))

  return (
    <div
      className={`progress-card box animated fadeInRight ${className || ''}`}>
      <div className="columns is-vcentered is-desktop is-mobile m-b-none">
        <div className="column has-text-centered p-l-md p-r-md p-b-none p-t-none">
          <p className="stat heading-3">
            {showLoading ? showSpinner() : topStat}
          </p>
          <p className="caption">{caption}</p>
        </div>
      </div>
      <div className="columns is-desktop is-mobile m-none">
        <div className="column p-l-none p-r-none p-t-sm p-b-sm">
          <hr
            className="m-none"
            style={{ height: '1px', background: '#E5E9F2' }}
          />
        </div>
      </div>
      <div className="columns is-desktop is-mobile m-t-none">
        <div className="column p-l-md p-r-md p-t-none">
          {displayData()}
          <div className="columns is-vcentered m-none has-text-right is-desktop is-mobile">
            <div className="column p-l-none p-r-none p-t-sm">
              <ProgressBar
                percentComplete={showLoading ? 0 : percentComplete}
              />
            </div>
            <div className="column is-narrow is-size-7 p-r-none p-t-sm">
              {percentComplete}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProgressCardNew.propTypes = {
  topStat: PropTypes.string,
  caption: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  showLoading: PropTypes.bool
}

export default ProgressCardNew
