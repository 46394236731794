export const selectStyles = {
  input: styles => ({
    ...styles,
    height: 23,
    margin: 0,
    marginTop: '-10px !important',
    padding: '1px 2px',
    fontSize: 10,
    lineHeight: 1,
    fontWeight: '400',
    cursor: 'text',
    marginLeft: 5
  }),
  placeholder: styles => ({
    ...styles,
    margin: 0,
    padding: '1px 7px',
    fontSize: 10,
    lineHeight: 1,
    fontWeight: '400',
    textAlign: 'left',
    marginLeft: 5
  }),
  control: (styles, state) => ({
    ...styles,
    height: 25,
    minHeight: 25,
    overflow: 'auto',
    marginTop: -5,
    border: '1px solid #e5e9f2',
    borderRadius: 4,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #e5e9f2'
    },
    backgroundColor: state.isDisabled ? 'ghostwhite' : styles.backgroundColor,
    '&::-webkit-scrollbar': {
      width: '4px'
    },
    ' &::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }),
  container: styles => ({
    ...styles,
    pointerEvents: 'all'
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  multiValue: styles => ({
    ...styles,
    height: 16
  }),
  multiValueLabel: styles => ({
    ...styles,
    padding: 3,
    paddingLeft: 3,
    fontSize: 10,
    lineHeight: 1
  }),
  multiValueRemove: styles => ({
    ...styles,
    padding: 0
  }),
  option: styles => ({
    ...styles,
    padding: 4,
    fontSize: 10
  }),
  indicatorsContainer: styles => ({
    ...styles,
    width: 2,
    height: 2
  }),
  clearIndicator: styles => ({
    ...styles,
    width: 18,
    padding: 0,
    paddingLeft: 4
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '6px',
    fontSize: '10px'
  })
}
