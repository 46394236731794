import React from 'react'
import UserCell from './components/UserCell'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import { Link } from 'react-router-dom'
import { capitalize } from '../../utils/helperFunctions'
import UserActionMenu from './components/UserActionMenu'
import { icons } from './icons'

const sorting = (sort, name) => {
  if (sort?.name === name) {
    if (sort.type === 'ASC') {
      return (
        <img
          style={{ display: 'block', marginTop: '-2px' }}
          src={icons.caretUpOn}
          alt="Sort in ascending order"
          title="Ascending"
        />
      )
    }
    return (
      <img
        style={{ display: 'block', marginTop: '-2px' }}
        src={icons.caretDownOn}
        alt="Sort in descending order"
        title="Descending"
      />
    )
  }
  return null
}

const displayHeader = (title, method) => {
  return (
    <div style={{ display: 'flex', gap: '5px' }}>
      <span className="m-r-5">{title}</span>
      {method}
    </div>
  )
}

export const headerData = ({
  SelectColumnFilter,
  user,
  onShowBlockModal,
  onshowDeleteModal,
  sortInfo
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: displayHeader('User ID', sorting(sortInfo, 'user_id')),
        accessor: 'user_id',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        disableFilters: true,
        disableSortBy: true,
        width: 90,
        Cell: row => {
          const { cell } = row || {}
          const { value } = cell || {}

          return (
            <div className="cell-item" title={value}>
              {value}
            </div>
          )
        }
      },
      {
        Header: displayHeader('Name', sorting(sortInfo, 'name')),
        accessor: 'name',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        disableFilters: true,
        disableSortBy: true,
        flexGrow: 1,
        Cell: row => {
          const { cell } = row || {}
          const { row: originalRow } = cell || {}
          const { original: currentRow } = originalRow || {}
          const { user_id } = currentRow || {}
          return (
            <div className="cell-item" title={currentRow?.name}>
              <Link
                to={{
                  pathname: `/setup/users/view/${user_id}`,
                  state: {
                    id: currentRow || {}
                  }
                }}
                className="link">
                {capitalize(currentRow?.name || '')}
              </Link>
            </div>
          )
        }
      },
      {
        Header: displayHeader('Email', sorting(sortInfo, 'email')),
        accessor: 'email',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        disableFilters: true,
        disableSortBy: true,
        textAlign: 'left',
        flexGrow: 1,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: displayHeader('Last Login', sorting(sortInfo, 'last_login')),
        accessor: 'last_login',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        disableFilters: true,
        disableSortBy: true,
        width: 180,
        Cell: row => {
          return <UserCell row={row} />
        }
      },
      {
        Header: displayHeader('Status', sorting(sortInfo, 'status')),
        accessor: 'status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        disableFilters: true,
        disableSortBy: true,
        width: 120,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { status } = original || {}
          const c_status = status === 'false' ? 'Blocked' : 'Active'
          return (
            <div className="cell-item" title={c_status}>
              {status}
            </div>
          )
        }
      },
      {
        Header: '',
        accessor: 'charge_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          return (
            <UserActionMenu
              userRow={original}
              user={user}
              onShowBlockModal={onShowBlockModal}
              onshowDeleteModal={onshowDeleteModal}
            />
          )
        }
      }
    ]
  }
]
