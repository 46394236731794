import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'

/**
 * Setup services data module
 * @redux
 * @reduxActionScope setup-services
 * @module setup-services
 */

/**
 * Request all services list
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_GET_REQUESTED =
  'setup-services/SETUP_ALL_SERVICES_GET_REQUESTED'
/**
 * All services list request failed
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_GET_FAIL =
  'setup-services/SETUP_ALL_SERVICES_GET_FAIL'
/**
 * Update requested services list with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_GET_SUCCESS =
  'setup-services/SETUP_ALL_SERVICES_GET_FAIL'

/**
 * Request a single service
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_GET_REQUESTED =
  'setup-services/SETUP_ONE_SERVICE_GET_REQUESTED'
/**
 * Single service request failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_GET_FAIL =
  'setup-services/SETUP_ONE_SERVICE_GET_FAIL'
/**
 * Update requested service details with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_GET_SUCCESS =
  'setup-services/SETUP_ONE_SERVICE_GET_SUCCESS'

/**
 * Request service creation
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_CREATE_REQUESTED =
  'setup-services/SETUP_ONE_SERVICE_CREATE_REQUESTED'
/**
 * Service creation failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_CREATE_FAIL =
  'setup-services/SETUP_ONE_SERVICE_CREATE_FAIL'
/**
 * Refresh service details after a successful API create call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_CREATE_SUCCESS =
  'setup-services/SETUP_ONE_SERVICE_CREATE_SUCCESS'

/**
 * Request service update
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_UPDATE_REQUESTED =
  'setup-services/SETUP_ONE_SERVICE_UPDATE_REQUESTED'
/**
 * Service update failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_UPDATE_FAIL =
  'setup-services/SETUP_ONE_SERVICE_UPDATE_FAIL'
/**
 * Refresh service details after a successful API update call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_UPDATE_SUCCESS =
  'setup-services/SETUP_ONE_SERVICE_UPDATE_SUCCESS'

/**
 * Request service delete
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_DELETE_REQUESTED =
  'setup-services/SETUP_ONE_SERVICE_DELETE_REQUESTED'
/**
 * Service delete failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_DELETE_FAIL =
  'setup-services/SETUP_ONE_SERVICE_DELETE_FAIL'
/**
 * Refresh service details after a successful API delete call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_SERVICE_DELETE_SUCCESS =
  'setup-services/SETUP_ONE_SERVICE_DELETE_SUCCESS'

const DS = ''

const initialService = {
  servicetype_uri: DS,
  servicetype_id: DS,
  servicetype_name: DS,
  items: []
}
const initialState = {
  allServices: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  oneService: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: initialService
  }
}

/**
 * Setup services reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ALL_SERVICES_GET_REQUESTED:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_SERVICES_GET_SUCCESS:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_SERVICES_GET_FAIL:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ONE_SERVICE_GET_REQUESTED:
    case SETUP_ONE_SERVICE_CREATE_REQUESTED:
    case SETUP_ONE_SERVICE_UPDATE_REQUESTED:
    case SETUP_ONE_SERVICE_DELETE_REQUESTED:
      return {
        ...state,
        oneService: {
          ...state.oneService,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ONE_SERVICE_GET_SUCCESS:
    case SETUP_ONE_SERVICE_CREATE_SUCCESS:
    case SETUP_ONE_SERVICE_UPDATE_SUCCESS:
    case SETUP_ONE_SERVICE_DELETE_SUCCESS:
      return {
        ...state,
        oneService: {
          ...state.oneService,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_SERVICE_GET_FAIL:
    case SETUP_ONE_SERVICE_CREATE_FAIL:
    case SETUP_ONE_SERVICE_UPDATE_FAIL:
    case SETUP_ONE_SERVICE_DELETE_FAIL:
      return {
        ...state,
        oneService: {
          ...state.oneService,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all services list
 * @redux
 * @reduxActionCreator SETUP_ALL_SERVICES_GET_REQUESTED, SETUP_ALL_SERVICES_GET_SUCCESS, SETUP_ALL_SERVICES_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupAllServicesGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_SERVICES_GET_REQUESTED
    })
    Service.setupServicesGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_SERVICES_GET_SUCCESS,
            data: _allServicesAdapter(data)
          })
        } else {
          _setupAllServicesGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllServicesGetFail(dispatch)
      })
  }
}

const _setupAllServicesGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_SERVICES_GET_FAIL
  })
}

const _allServicesAdapter = data => {
  return Array.isArray(data) ? data.map(s => _oneServiceAdapter(s)) : []
}

/**
 * Gets a single service details
 * @redux
 * @reduxActionCreator SETUP_ONE_SERVICE_GET_REQUESTED, SETUP_ONE_SERVICE_GET_SUCCESS, SETUP_ONE_SERVICE_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the requested service
 */
export const setupOneServiceGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_SERVICE_GET_REQUESTED
    })
    Service.setupOneServiceGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_SERVICE_GET_SUCCESS,
            data: _oneServiceAdapter(data[0])
          })
        } else {
          _setupOneServiceGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneServiceGetFail(dispatch)
      })
  }
}

const _setupOneServiceGetFail = dispatch => {
  dispatch({
    type: SETUP_ONE_SERVICE_GET_FAIL
  })
}

const _oneServiceAdapter = data => {
  return {
    uri: typeof data.servicetype_uri === 'string' ? data.servicetype_uri : '',
    id: typeof data.servicetype_id === 'string' ? data.servicetype_id : '',
    name: typeof data.servicetype === 'string' ? data.servicetype : '',
    items: Array.isArray(data.items) ? _itemsAdapter(data.items) : [],
    pivoted_options: _.flatten(
      _.map(_.get(data, 'items', []), item =>
        _.reduce(
          item.item_options,
          (acc, io) =>
            acc.concat([
              {
                item: item.item,
                item_id: item.item_id,
                item_uri: item.item_uri,
                ...io
              }
            ]),
          []
        )
      )
    )
  }
}

const _itemsAdapter = items => {
  return items.map(i => ({
    item: typeof i.item === 'string' ? i.item : '',
    item_uri: typeof i.item_uri === 'string' ? i.item_uri : '',
    item_id: typeof i.item_id === 'string' ? i.item_id : '',
    item_options: Array.isArray(i.item_options) ? i.item_options : []
  }))
}

/**
 * Create a new service
 * @redux
 * @reduxActionCreator SETUP_ONE_SERVICE_CREATE_REQUESTED, SETUP_ONE_SERVICE_CREATE_SUCCESS, SETUP_ONE_SERVICE_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new service
 */
export const setupOneServiceCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_SERVICE_CREATE_REQUESTED
    })
    Service.setupOneServiceCreate(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_SERVICE_CREATE_SUCCESS,
            data: _oneServiceAdapter(data[0])
          })
        } else {
          _setupOneServiceCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneServiceCreateFail(dispatch)
      })
  }
}

const _setupOneServiceCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_SERVICE_CREATE_FAIL
  })
}

/**
 * Updates an existing service
 * @redux
 * @reduxActionCreator SETUP_ONE_SERVICE_UPDATE_REQUESTED, SETUP_ONE_SERVICE_UPDATE_SUCCESS, SETUP_ONE_SERVICE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the updated service
 */
export const setupOneServiceUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_SERVICE_UPDATE_REQUESTED
    })
    Service.setupOneServiceUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_SERVICE_UPDATE_SUCCESS,
            data: _oneServiceAdapter(data[0])
          })
        } else {
          _setupOneServiceUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneServiceUpdateFail(dispatch)
      })
  }
}

const _setupOneServiceUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_SERVICE_UPDATE_FAIL
  })
}

/**
 * Deletes an existing service
 * @redux
 * @reduxActionCreator SETUP_ONE_SERVICE_UPDATE_REQUESTED, SETUP_ONE_SERVICE_UPDATE_SUCCESS, SETUP_ONE_SERVICE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing service
 */
export const setupOneServiceDelete = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_SERVICE_DELETE_REQUESTED
    })
    Service.setupOneServiceDelete(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_SERVICE_DELETE_SUCCESS,
            data: _oneServiceAdapter(data[0])
          })
        } else {
          _setupOneServiceDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneServiceDeleteFail(dispatch)
      })
  }
}

const _setupOneServiceDeleteFail = dispatch => {
  dispatch({
    type: SETUP_ONE_SERVICE_DELETE_FAIL
  })
}
