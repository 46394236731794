import React, { useEffect, useState } from 'react'
import VendorTabOptions from '../vendor-tab-options'
import SubPricingTab from './SubPricingTab'
import PropTypes from 'prop-types'

const PricingTabs = ({ childTabOptions, activeProgressItem, gmView, id, isEdit, updateActiveProgressItem, onChangeVendorQuotation, childServices, touchedChildTabs, serviceName, onChangeTouchedChildTab }) => {
  const [tabs, setTabs] = useState([]) 
  const [childTabContent, setChildTabContent] = useState([])
  const [childTabType, setChildTabType] = useState('') 

  // Get complete tabs list
  const { completed } = activeProgressItem || {}

  // Format Tab data for child tabs
  useEffect(() => {
    formatTabOptions()
  }, [childTabOptions])

  // Set default Tab 
  useEffect(() => {
    const getKeys = Object.keys(childServices || {}) || []
    if((getKeys || []).length !== 0) {
      if(childTabType) {
        setChildTabContent(childServices[childTabType] || [])
      } else {
        const firstTab = getKeys[0]
        setChildTabType(firstTab || '')
        setChildTabContent(childServices[firstTab] || [])
      }
    }
    
  }, [childTabType, childServices])

  const getChildTabContent = (value) => {
    const { label } = value || {}
    setChildTabType(label)
    onChangeTouchedChildTab(serviceName, label)
  }

  const formatTabOptions = () => {
    const tabOptions = (childTabOptions || []).map((service, index) => {    
      return {
        label: service,
        id: service.replace(' ', '_').toLowerCase(),
        overrideActiveBackground: '#3DB3E2',
        active: (index ===  0) ? true : false,
        touched: (index ===  0) ? true : false,
        onClick: data => getChildTabContent(data),
      }
    })
    setTabs(tabOptions)
  }

  const onHandleChange = ({ target }, index) => {
    const { name, value } = target || {}
    let filterValue = value
    // Remove service from completed progress item
    if((completed || []).includes(id)) {
      const newCompleted = (completed || []).filter(complete => complete !== id)
      updateActiveProgressItem((newCompleted || []), 'completed')
    }
    // Perform calculation only if current field is not associated to notes
    if(name !== 'notes') {
      // Only allow Numbers and .
      filterValue = (value || '').replace(/[^0-9\.]/g,'')
      // Check if . already exists
      if(filterValue.split('.').length>2) {
        filterValue =filterValue.replace(/\.+$/,'')
      }
    }
    // Update current data with updated data
    const list = [...childTabContent || []]
    list[index] = { ...list[index], inputType:{
      ...list[index]?.inputType || {},
      [name]:{
        ...list[index]?.inputType[name] || {},
        value: filterValue
      }
    } }
    setChildTabContent(list || [])

  }

  const onHandleUpdateQuote = ({s_id, costValue, noteValue, capacityValue, serviceName}) => {
    const childTabInfo = {
      childTabContent,
      childTabType
    }
    if(serviceName === 'Capacity By Date') {
      onChangeVendorQuotation({
        id: s_id,
        data: {
          'capacity': capacityValue || 0,
          'notes': noteValue || ''
        }
      }, childTabInfo)
    } else {
      onChangeVendorQuotation({
        id: s_id,
        data: {
          'cost': costValue || 0,
          'notes': noteValue || ''
        }
      },childTabInfo)
    }
  }

  return (
    <div className="pricing-tabs-wrapper">
      <div className="pricing-tabs-header">
        { (tabs || []).length > 0 && <VendorTabOptions key={tabs} isEdit={ isEdit } type= "child" serviceName={ serviceName } touchedChildTabs={ touchedChildTabs } tabs={ tabs } childTabType={ childTabType } /> }
      </div>
      <div className="pricing-tabs-content">
        <SubPricingTab gmView={ gmView } isEdit={ isEdit } serviceName={ serviceName } childTabType ={ childTabType }  key={ childTabType }  tabData={ childTabContent } onHandleUpdateQuote={ onHandleUpdateQuote } onHandleChange={ onHandleChange } />
      </div>
    </div>
  )
}

PricingTabs.propTypes = {
  childTabOptions: PropTypes.array,
  serviceName: PropTypes.string,
  gmView: PropTypes.bool,
  id: PropTypes.string,
  touchedChildTabs: PropTypes.object,
  onChangeVendorQuotation: PropTypes.func,
  updateActiveProgressItem: PropTypes.func,
  onChangeTouchedChildTab: PropTypes.func,
  activeProgressItem: PropTypes.object,
  childServices: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default PricingTabs

