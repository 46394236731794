import emptyPage from './icons/emptypage@2x.svg'
import edit from './icons/edit@2x.svg'
import trash from './icons/trash@2x.svg'
import setupAdd from './icons/setup-add.svg'
import addBlack from './icons/add-black.svg'

export const icons = {
  emptyPage,
  edit,
  trash,
  setupAdd,
  addBlack
}
