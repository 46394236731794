import React from 'react'
import PropTypes from 'prop-types'
import ComponentWithStatus from '../component-with-status'

/**
 * Turn details stats box
 *
 * @component
 * @example
 * return (
 *   <TurnDetails
 *     loading={false}
 *     error={false}
 *     beds={480}
 *     units={144}
 *     renewal={148}
 *     newLeases={298}
 *     unassigned={6}
 *     fullyRenewed={17}
 *     renewalTransferOut={14}
 *     renewalTransferIn={14}
 *     leapfrogs={4}
 *   />
 * )
 */
const TurnDetails = ({
  loading,
  error,
  beds,
  units,
  renewals,
  newLeases,
  unassigned,
  fullyRenewed,
  renewalTransferOut,
  renewalTransferIn,
  leapfrogs
}) => {
  return (
    <div className="dashboard-card card-semi">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="inner-content">
          <div className="columns">
            <div className="column is-full heading-5">Turn Details</div>
          </div>
          <div
            className="columns small-text"
            style={{ paddingLeft: '1.65rem', paddingRight: '1.65rem' }}>
            <div className="column is-third">
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Total Beds">
                  Total Beds:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {beds}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Total Units">
                  Total Units:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {units}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Total Renewals">
                  Total Renewals:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {renewals}
                </div>
              </div>
            </div>
            <div className="column is-third">
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Total New Leases">
                  Total New Leases:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {newLeases}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Unassigned / Vacant">
                  Unassigned / Vacant:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {unassigned}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Fully Renewed Units">
                  Fully Renewed Units:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {fullyRenewed}
                </div>
              </div>
            </div>
            <div className="column is-third">
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Renewal Transfer Out">
                  Renewal Transfer Out:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {renewalTransferOut}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Renewal Transfer In">
                  Renewal Transfer In:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {renewalTransferIn}
                </div>
              </div>
              <div className="columns">
                <div
                  className="column small-spacing with-ellipsis"
                  title="Leapfrogs">
                  Leapfrogs:
                </div>
                <div className="column small-spacing is-narrow has-text-right">
                  {leapfrogs}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ComponentWithStatus>
    </div>
  )
}

TurnDetails.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Total number of beds
   */
  beds: PropTypes.number,
  /**
   * Total number of units
   */
  units: PropTypes.number,
  /**
   * Total renewals
   */
  renewals: PropTypes.number,
  /**
   * Total new leases
   */
  newLeases: PropTypes.number,
  /**
   * Unassigned / Vacant
   */
  unassigned: PropTypes.number,
  /**
   * Total fully renewed units
   */
  fullyRenewed: PropTypes.number,
  /**
   * Renewal Transfer Outs
   */
  renewalTransferOut: PropTypes.number,
  /**
   * Renewal Transfer Ins
   */
  renewalTransferIn: PropTypes.number,
  /**
   * Total leapfrogs
   */
  leapfrogs: PropTypes.number
}

export default TurnDetails
