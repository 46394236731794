import React, { useState, useMemo, useEffect } from 'react'
import { invoiceAddAmountPaid } from 'modules/invoices'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'ui'
import { closeFilled } from 'ui/icons'
import { closeModal } from 'modules/modal'
import './index.scss'

const InvoicesPaymentModal = ({ invoice, ...restProps }) => {
  const dispatch = useDispatch()

  const { addAmountPaidIsRequesting, addAmountPaidSuccess } = useSelector(
    state => state.invoices
  )

  const {
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  const [paymentAmount, setPaymentAmount] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!addAmountPaidIsRequesting && addAmountPaidSuccess && submitted) {
      dispatch(closeModal())
    }
  }, [addAmountPaidIsRequesting, addAmountPaidSuccess])

  const { total_amount, amount_paid } = invoice || {}

  useEffect(() => {
    if (amount_paid) {
      setPaymentAmount(parseFloat(amount_paid || '0').toFixed(2))
    }
  }, [amount_paid])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  const getOutstandingAmount = useMemo(() => {
    return total_amount
  }, [total_amount])

  const calculateOutstandingAmount = useMemo(() => {
    const amount = total_amount
    return amount - paymentAmount
  }, [total_amount, paymentAmount])

  const onHandlePaymentAmountChange = ({ target }) => {
    setErrorMessage('')
    const { value } = target || {}
    let filterValue = value || ''
    // Only allow Numbers and .
    filterValue = (value || '').replace(/[^0-9\.]/g, '')
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Check if . already exists
    if ((filterValue || '').toString().split('.').length > 2) {
      filterValue = (filterValue || '').replace(/\.+$/, '')
    }
    // Dont allow more than 2 decimal points
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setPaymentAmount(filterValue)
  }

  return (
    <Modal
      autoClose={false}
      title="Apply Payment"
      okText="Add"
      onConfirm={() => {
        setLoading(true)
        setSubmitted(true)
        if (parseFloat(paymentAmount) > parseFloat(getOutstandingAmount)) {
          setLoading(false)
          setSubmitted(false)
          setErrorMessage(
            'Payment amount cannot be more than outstanding amount'
          )
          return
        } else {
          dispatch(
            invoiceAddAmountPaid(
              pdbid,
              context,
              invoice.po_id,
              paymentAmount,
              () => {
                setErrorMessage('')
              },
              () => {
                setLoading(false)
                setSubmitted(false)
                setErrorMessage(
                  'Something went wrong. Please try again later!!'
                )
              }
            )
          )
        }
      }}
      loading={loading}
      okDisabled={!paymentAmount}
      {...restProps}>
      <div className="columns" style={{ marginBottom: '-15px' }}>
        <div className="column ">
          <p className="is-size-6">Enter the total amount paid to date.</p>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="is-fullwidth box-padding-2y">
            <label htmlFor="payment" className="is-fullwidth">
              Payment Amount:
            </label>
            <input
              type="text"
              className="input is-fullwidth"
              id="pay-amount"
              placeholder="ex: $500"
              value={paymentAmount}
              onChange={e => onHandlePaymentAmountChange(e)}
            />
          </div>
          <p>
            <strong>{`$${calculateOutstandingAmount?.toFixed(
              2
            )} Outstanding`}</strong>
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default InvoicesPaymentModal
