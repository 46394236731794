import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ServicesSelector from '../../components/services-selector'
import AutoschedulingLoader from '../autoscheduling-loader'
import ScheduleServiceWizard from '../schedule-service-wizard'
import { close } from 'ui/icons'
import './index.scss'

const SCHEDULE_FLOW = {
  main: 'schedule-main-flow',
  wizard: 'schedule-service-flow',
}

const WAINT_INTERVAL_AUTOSCHEDULING_STATUS = 6000

const SchedulePlanningTool = ({ schedule, openModal, closeModal, setModalContent, getSchedulingStatus, resetSchedulingStatus, autoscheduleService, context, user }) => {
  const { mainData, schedulingStatus } = schedule
  const [activeFlow, setActiveFlow] = useState(SCHEDULE_FLOW.main)
  const [selectedServices, setSelectedServices] = useState(mainData.map(service => ({ ...service, active: service.scheduled })))
  const [servicesToSchedule, setServicesToSchedule] = useState([])
  const [autoScheduling, setAutoScheduling] = useState(false)

  useEffect(() => {
    return () => resetSchedulingStatus()
  }, [])

  useEffect(() => {
    if (schedulingStatus && schedulingStatus.running) {
      setAutoScheduling(true)
    } else {
      setAutoScheduling(false)
    }
  }, [schedulingStatus])
 
  useEffect(() => {
    let interval = null

    if (autoScheduling) {
      interval = setInterval(() => {
        const { pdbid } = user.user_metadata
        getSchedulingStatus(pdbid, context)
      }, WAINT_INTERVAL_AUTOSCHEDULING_STATUS)
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [autoScheduling])

  const startScheduleWizard = () => {
    setServicesToSchedule(selectedServices.filter(service => service.selected))
    setActiveFlow(SCHEDULE_FLOW.wizard)
  }

  const handleScheduleAllModal = serviceType => {
    
    const autoSchedule = () => {
      const { pdbid } = user.user_metadata
      autoscheduleService(pdbid, context, serviceType)
      setActiveFlow(SCHEDULE_FLOW.main)
      setAutoScheduling(true)
      closeModal()
    }

    setModalContent(() => (
      <div>
        <div className="columns is-desktop is-mobile">
          <div className="column">
            <p className="heading-3 is-pulled-left">
              Auto-Schedule {serviceType}
            </p>
            <p className="close is-pointer has-text-grey-light" onClick={() => {closeModal()}}>
              <img alt="Delete Note" src={close} />
            </p>
          </div>
        </div>
        <div className="div columns">
          <div className="column">
            { serviceType === 'All' ? (
              <p className="schedule-all-modal__text">
                This will schedule or reschedule all available units for<br/>
                each service, based on capacity. <span style={{ fontWeight: 'bold' }}>This could override<br/>
                any units that have not been locked - including<br/>
                units that have already been scheduled.</span><br/><br/>
                Before confirming, please check that all units you’d
                like to keep in place are locked.
              </p>
            ) : (
              <p className="schedule-all-modal__text">
                You’re about to auto-schedule the remaining capacity
                for {serviceType}. <span style={{ fontWeight: 'bold' }}>This could override any units
                that are not locked.</span> If you want to keep the
                assignments you’ve made, click “Cancel” and lock
                those units before continuing.
              </p>
            )}
            
            <p className="has-text-centered schedule-all-modal__text m-t-lg">
              { serviceType === 'All' ? 'Are you ready to auto-schedule all units?' : 'Are you ready to auto-schedule?' }
            </p>
          </div>
        </div>
        <div className="columns is-vcentered m-b-sm m-t-sm">
          <div className="center-container">
            <button
              className="button main-button is-secondary m-r-md"
              onClick={closeModal}>
              Cancel
            </button>
            
            <button className="button main-button is-primary" onClick={autoSchedule}>
              Confirm
            </button>

          </div>
        </div>
      </div>
    ))

    openModal({ width: '480px' })
  }
  
  const getMainFlow = () => {
    const selectedServicesNumber = selectedServices.filter(service => service.selected).length
    const completedServicesNumber = selectedServices.filter(service => service.scheduled).length

    return (
      <div className="column">
        {!autoScheduling ? (
          <>
            <ServicesSelector
              services={selectedServices}
              setSelectedServices={setSelectedServices}
              completedServicesNumber={completedServicesNumber}
              strictOrder={true}
              enableItemIndex={selectedServices.findIndex(service => !service.scheduled)}
            />
            {completedServicesNumber < selectedServices.length - 1 && (
              <div className="m-b-xl" style={{ margin: 'auto', maxWidth: 839, width: '100%' }}>
                <button
                  className="button is-primary is-pulled-right"
                  onClick={startScheduleWizard}
                  disabled={selectedServicesNumber === 0}>
                  Continue
                </button>
                <button
                  className="button is-success is-pulled-right m-r-md"
                  onClick={() => handleScheduleAllModal('All')}
                  disabled={selectedServicesNumber > 0}>
                  Auto-Schedule All
                </button>
              </div>
            )}
          </>
        ) : (
          <AutoschedulingLoader />
        )}
      </div>
    )
  }

  return activeFlow === SCHEDULE_FLOW.main ? (
    getMainFlow()
  ) : (
    <ScheduleServiceWizard
      servicesToSchedule={servicesToSchedule}
      goBackToMain={() => setActiveFlow(SCHEDULE_FLOW.main)}
      handleScheduleAllModal={handleScheduleAllModal}
    />
  )
}

SchedulePlanningTool.propTypes = {
  schedule: PropTypes.object
}

export default SchedulePlanningTool
