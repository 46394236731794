import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { close } from 'ui/icons'
import { reactivateOrInactivateUser } from '../../modules/setup-users'
import LoginAccessModal from './LoginAccessModal'
import ReactivateOrInactivateModal from './ReactivateOrInactivateModal'
import './index.scss'

const SetupUserReactivateOrInactivateStepperModal = ({
  onCancel,
  onConfirm,
  user,
  context,
  status,
  permission_rule_id
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [loginAccess, setLoginAccess] = useState(false)
  const [showLoginAccessModal, setShowLoginAccessModal] = useState(
    () => !status
  )

  const { pdbid } = user.user_metadata || {}

  const modalTitle = status ? 'Inactivate User' : 'Reactivate User'

  const handleLoginAccessChange = value => {
    setLoginAccess(value)
    setShowLoginAccessModal(false)
  }

  const handleReactivateOrInactivateUser = () => {
    const onSuccess = () => {
      setLoading(false)
      onConfirm()
    }

    const onError = () => {
      setLoading(false)
      setError('Something went wrong. Please try again later !!!')
    }

    setError('')
    setLoading(true)

    const body = {
      items: [
        {
          status: status ? 'false' : null,
          login_access: status ? 'false' : loginAccess ? null : 'false',
          permission_rule_id
        }
      ]
    }

    dispatch(
      reactivateOrInactivateUser(pdbid, context, body, onSuccess, onError)
    )
  }

  return (
    <div className="setup-user-reactivate-or-inactivate-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">{modalTitle}</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onCancel}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      {showLoginAccessModal ? (
        <LoginAccessModal
          onConfirm={handleLoginAccessChange}
          loginAccess={loginAccess}
        />
      ) : (
        <ReactivateOrInactivateModal
          status={status}
          loading={loading}
          error={error}
          onConfirm={handleReactivateOrInactivateUser}
          onCancel={onCancel}
        />
      )}
    </div>
  )
}

export default SetupUserReactivateOrInactivateStepperModal
