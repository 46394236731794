export const KEYS_SERIES_LABELS = {
  returned: 'Returned',
  rti: 'Renewal Transfer In',
  rto: 'Renewal Transfer Out',
  leapfrogs: 'Leapfrogs',
  potential_holdovers: 'Potential Holdovers',
  holdovers: 'Holdovers'
}

export const NON_VENDOR_SERVICES = ['Walk', 'Punch', 'Sparkle']

export const SUMMARY_CARDS = [
  {
    id: 'Walk',
    label: 'Walk',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=Walk&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=Walk&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=Walk&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=Walk&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=Walk&p=Approved'
      }
    ]
  },
  {
    id: 'Punch',
    label: 'Punch',
    short: 'Punch',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=Punch&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=Punch&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=Punch&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=Punch&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=Punch&p=Approved'
      }
    ]
  },
  {
    id: 'Paint',
    label: 'Paint',
    short: 'Paint',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=Paint&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=Paint&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=Paint&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=Paint&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=Paint&p=Approved'
      }
    ]
  },
  {
    id: 'Cleaning',
    label: 'Clean',
    short: 'Clean',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=Cleaning&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=Cleaning&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=Cleaning&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=Cleaning&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=Cleaning&p=Approved'
      }
    ]
  },
  {
    id: 'CarpetClean',
    label: 'Carpet Clean',
    short: 'CP Clean',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=CarpetClean&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=CarpetClean&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=CarpetClean&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=CarpetClean&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=CarpetClean&p=Approved'
      }
    ]
  },
  {
    id: 'CarpetReplace',
    label: 'Carpet Replace',
    short: 'CP Rep',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=CarpetReplace&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=CarpetReplace&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=CarpetReplace&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=CarpetReplace&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=CarpetReplace&p=Approved'
      }
    ]
  },
  {
    id: 'ConcreteVinylReplace',
    label: 'Concrete / Vinyl',
    short: 'CR/V Rep',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=ConcreteVinylReplace&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=ConcreteVinylReplace&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=ConcreteVinylReplace&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=ConcreteVinylReplace&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=ConcreteVinylReplace&p=Approved'
      }
    ]
  },
  {
    id: 'Sparkle',
    label: 'Sparkle',
    stats: [
      {
        id: 'Assign',
        label: 'Not Assigned',
        href: '/services?s=Sparkle&p=Assign'
      },
      {
        id: 'Start',
        label: 'Assigned',
        href: '/services?s=Sparkle&p=Start'
      },
      {
        id: 'InProgress',
        label: 'In Progress',
        href: '/services?s=Sparkle&p=InProgress'
      },
      {
        id: 'AssignQC',
        label: 'QC',
        href: '/services?s=Sparkle&p=AssignQC'
      },
      {
        id: 'Approved',
        label: 'Approved',
        href: '/services?s=Sparkle&p=Approved'
      }
    ]
  }
]
