import React, { useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { arrowDown } from 'icons'

const DropdownMenu = ({ children, title }) => {
  const [menuExpanded, setMenuExpanded] = useState(false)
  const ref = useDetectClickOutside({ onTriggered: () => setMenuExpanded(false) })

  return (
    <div ref={ref} className="dropdown is-active m-r-md toolbar ">
      <div className="dropdown-trigger">
        <button className="button tool-button"
          onClick={() => setMenuExpanded(!menuExpanded)}
        >{title}
          <img src={arrowDown} alt="carrot" className="p-l-md" />
        </button>
      </div>
      {menuExpanded && <div className="dropdown-menu">
        <div className="dropdown-content has-text-left">
          {children}
        </div>
      </div>}
    </div>

  )
}

export default DropdownMenu
