import { combineReducers } from 'redux'
import details from './setup-properties-details'
import buildings from './setup-properties-buildings'
import floorplans from './setup-properties-floorplans'
import units from './setup-properties-units'

/**
 * Setup properties (single property) data module
 * @redux
 * @reduxActionScope setup-properties-one
 * @module setup-properties-one
 */

export default combineReducers({
  details,
  buildings,
  floorplans,
  units
})
