import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import BasicLoading from '../../components/basic-loading'
import { allUserFiltersGet } from '../../modules/setup-users'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css'
import './index.scss'

const UserListFilterModal = props => {
  const {
    onClose,
    setupUsers,
    allUserFiltersGet,
    user,
    onHandleUserFilterQuery,
    AppliedFilteredValues
  } = props || {}

  const initializeState = value => (value?.length !== 0 ? value : [])
  const initializeDateStartState = value =>
    !value || value?.length === 0 ? new Date() : new Date(value[0]?.key)

  const initializeDateEndState = value =>
    !value || value?.length === 0 ? null : new Date(value[0]?.key)

  const initializeEnableDateSelectionState = value =>
    !value || value?.length === 0 ? false : true

  // Previous-Selected filter values
  const {
    f_user_id,
    f_name,
    f_email,
    f_role_name,
    f_property_name,
    f_project_name,
    f_status,
    f_last_login_from,
    f_last_login_to
  } = AppliedFilteredValues || {}

  // States
  const [isSubmitting, setSubmitting] = useState(false)
  const [UserIdValue, setUserIdValue] = useState(initializeState(f_user_id))
  const [nameValue, setNameValue] = useState(initializeState(f_name))
  const [emailValue, setEmailValue] = useState(initializeState(f_email))
  const [roleValue, setRoleValue] = useState(initializeState(f_role_name))
  const [propertiesValue, setPropertiesValue] = useState(
    initializeState(f_property_name)
  )
  const [projectValue, setProjectValue] = useState(
    initializeState(f_project_name)
  )
  const [statusValue, setStatusValue] = useState(initializeState(f_status))
  const [startDate, setStartDate] = useState(
    initializeDateStartState(f_last_login_from)
  )
  const [endDate, setEndDate] = useState(
    initializeDateEndState(f_last_login_to)
  )
  const [enableLastLogin, setEnableLastLogin] = useState(
    initializeEnableDateSelectionState(f_last_login_to)
  )

  useEffect(() => {
    getFieldsDefaultData()
  }, [])

  const getFieldsDefaultData = () => {
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    allUserFiltersGet({ pdbid })
  }

  const { allUserFilter } = setupUsers || {}
  const { hasMadeInitialRequest, isRequesting, isError, data: userFilterData } =
    allUserFilter || {}
  const {
    auth_ids,
    emails,
    names,
    projects,
    properties,
    roles,
    status,
    user_ids
  } = userFilterData || {}

  if (hasMadeInitialRequest && isRequesting) {
    return <BasicLoading />
  }

  const constructFilterString = filters =>
    Object.entries(filters)
      .filter(([_, value]) => value?.length)
      ?.map(
        ([key, value]) =>
          `&${key}=${value
            ?.map(val => encodeURIComponent(val?.value))
            ?.join(',')}`
      )
      .join('')

  const formatDate = date =>
    moment(date).isValid()
      ? moment(date).format('DD/MMM/YY')
      : moment(new Date()).format('DD/MMM/YY')

  const filters = {
    f_user_id: UserIdValue,
    f_name: nameValue,
    f_email: emailValue,
    f_role_name: roleValue,
    f_property_name: propertiesValue,
    f_project_name: projectValue,
    f_status: statusValue,
    ...(enableLastLogin && {
      f_last_login_from: [
        {
          key: formatDate(startDate),
          value: formatDate(startDate)
        }
      ],
      f_last_login_to: [
        {
          key: formatDate(endDate),
          value: formatDate(endDate)
        }
      ]
    })
  }

  const styles = {
    valueContainer: provided => ({
      ...provided,
      minHeight: '45px',
      maxHeight: '70px',
      overflow: 'auto',
      position: 'relative'
    })
  }

  const default_status_value = [
    { key: 'Active', value: 'Active' },
    { key: 'Blocked', value: 'Blocked' }
  ]

  const checkIsDisabled = () => {
    if (enableLastLogin && endDate === null) {
      return true
    }
    if (constructFilterString(filters) === '') {
      return true
    }
    return false
  }

  return (
    <div className="user-filter-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Filters</p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full" style={{ marginBottom: '30px' }}>
        <form>
          <div class="columns form-group">
            <div class="column is-half">
              <div className="label">
                <label>User ID</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={UserIdValue}
                  defaultValues={user_ids}
                  placeholder="Select"
                  onChange={value => setUserIdValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
            <div class="column is-half">
              <div className="label">
                <label>Name</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={nameValue}
                  defaultValues={names}
                  placeholder="Select"
                  onChange={value => setNameValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
          </div>
          <div class="columns form-group">
            <div class="column is-half">
              <div className="label ">
                <label>Email</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={emailValue}
                  defaultValues={emails}
                  placeholder="Select"
                  onChange={value => setEmailValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
            <div class="column is-half">
              <div className="label ">
                <label>Role</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={roleValue}
                  defaultValues={roles}
                  placeholder="Select"
                  onChange={value => setRoleValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
          </div>
          <div class="columns form-group">
            <div class="column is-half">
              <div className="label">
                <label>Properties</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={propertiesValue}
                  defaultValues={properties}
                  placeholder="Select"
                  onChange={value => setPropertiesValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
            <div class="column is-half">
              <div className="label">
                <label>Project</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={projectValue}
                  defaultValues={projects}
                  placeholder="Select"
                  onChange={value => setProjectValue(value)}
                  isMulti={true}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
          </div>
          <div class="columns form-group">
            <div class="column is-half">
              <div className="label">
                <label>Status</label>
              </div>
              <div
                className="input-field"
                style={{ cursor: isError ? 'not-allowed' : 'pointer' }}>
                <MultiSelectDropdown
                  displayKey="key"
                  isDisabled={isError}
                  value={statusValue}
                  defaultValues={!isError ? default_status_value : []}
                  placeholder="Select"
                  onChange={value => setStatusValue([value])}
                  isMulti={false}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
            <div class="column is-half">
              <label className={`checkbox ${enableLastLogin && 'is-active'}`}>
                <span
                  className={`checkbox-input ${enableLastLogin &&
                    'is-active'}`}>
                  <span className="checkbox-inner" />
                  <input
                    type="checkbox"
                    className="checkbox-original"
                    checked={enableLastLogin}
                    name="enablelogin"
                    onChange={e => {
                      setEnableLastLogin(e.target.checked)
                      setStartDate(new Date())
                      setEndDate(null)
                    }}
                  />
                </span>
                <span
                  className={`checkbox-label ${enableLastLogin &&
                    'is-active'}`}>
                  Enable Last Login
                </span>
              </label>
              <div class="columns form-group">
                <div class="column is-half">
                  <div className="label">
                    <label>From</label>
                  </div>
                  <div className="input-field">
                    <ReactDatePicker
                      selected={startDate}
                      disabled={!enableLastLogin}
                      onChange={date => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </div>
                </div>
                <div class="column is-half">
                  <div className="label">
                    <label>To</label>
                  </div>
                  <div className="input-field">
                    <ReactDatePicker
                      selected={endDate}
                      disabled={!enableLastLogin}
                      onChange={date => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {isError && (
        <div className="notification is-danger is-light is-flex">
          <p>Something went wrong. Please try again later !!!</p>
          <button>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            disabled={checkIsDisabled()}
            onClick={event => {
              event.preventDefault()
              setSubmitting(true)
              onHandleUserFilterQuery(constructFilterString(filters))
              onClose()
            }}>
            Filter
            {isSubmitting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

UserListFilterModal.propTypes = {
  onClose: PropTypes.func,
  allUserFiltersGet: PropTypes.func,
  user: PropTypes.object,
  setupUsers: PropTypes.object
}

const mapStateToProps = ({ setupUsers, user, context }) => ({
  setupUsers,
  user,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      allUserFiltersGet
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserListFilterModal)
