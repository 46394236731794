import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getInspectionMedia } from '../../modules/inspect'
import PropTypes from 'prop-types'

const InspectionMedia = props => {
  const {
    context,
    user,
    row,
    getInspectionMedia,
    onToggle,
    customClass,
    setIsPhotoLoading,
    isPhotoLoading,
    text = 'View'
  } = props || {}

  const handleMedia = () => {
    setIsPhotoLoading(true)
    const { inspection_id: inspectionId } = row || {}

    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    getInspectionMedia({
      data: {
        pdbid,
        context,
        inspectionId
      },
      onSuccess: () => {
        onToggle()
        setIsPhotoLoading(false)
      },
      onError: () => {
        console.log('error')
        setIsPhotoLoading(false)
      }
    })
  }
  return (
    <a
      title="View Photos"
      className={customClass}
      onClick={() => {
        handleMedia()
      }}>
      {text}
    </a>
  )
}

InspectionMedia.propTypes = {
  user: PropTypes.object,
  getInspectionMedia: PropTypes.func,
  onToggle: PropTypes.func,
  context: PropTypes.object,
  customClass: PropTypes.string,
  index: PropTypes.number,
  isPhotoLoading: PropTypes.bool,
  setIsPhotoLoading: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, inspect }) => ({
  user,
  context,
  modal,
  inspect
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getInspectionMedia
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionMedia)
