import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPurchaseDetails } from 'modules/purchase-orders'
import { useParams } from 'react-router'
import { finishEditingInvoice, updateInvoice } from '../../../modules/invoices'
import Button from '../../../ui/button'

const EditingActions = ({ invoice }) => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const context = useSelector(state => state.context)
  const { id, vendor_id } = useParams()

  const finishEditing = () => {
    dispatch(finishEditingInvoice())
  }

  const triggerUpdate = async () => {
    const { pdbid } = user.user_metadata

    const po_lines = invoice.po_lines
      .filter(line => !(line.isNew && line.deleted) && line.po_line_id)
      .map(line => {
        let task_id = line.task_id || null
        let note_id = line.note_id || null

        if (task_id) {
          const taskId = task_id.toLowerCase()
          if (taskId.startsWith('task')) {
            task_id = taskId
            note_id = null
          } else if (taskId.startsWith('note')) {
            note_id = taskId
            task_id = null
          } else {
            task_id = null
            note_id = null
          }
        }

        return {
          ...line,
          task_id,
          note_id,
          po_line_id: line.isNew ? '' : line.po_line_id
        }
      })

    const invoiceToUpdate = {
      ...invoice,
      vendor_po_number: invoice?.vendor_po_number?.trim(),
      po_lines
    }

    await dispatch(
      updateInvoice(pdbid, context, invoice.po_id, invoiceToUpdate)
    )
    dispatch(finishEditingInvoice())
    dispatch(getPurchaseDetails(pdbid, context, id, vendor_id))
  }

  return (
    <>
      <Button type="secondary" onClick={finishEditing}>
        Cancel
      </Button>
      <Button type="primary" onClick={triggerUpdate}>
        Save
      </Button>
    </>
  )
}

export default EditingActions
