/**
 * Company templates data module
 * @redux
 * @reduxActionScope Company templates
 * @module CompanyTemplates
 */

/**
 * create or duplicate or update company template request
 * @type {Redux.ActionType}
 */

export const CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED =
  'companyTemplates/CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED'

/**
 * create or duplicate or update company template success
 * @type {Redux.ActionType}
 */

export const CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS =
  'companyTemplates/CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS'

/**
 * create or duplicate or update company template fail
 * @type {Redux.ActionType}
 */

export const CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL =
  'companyTemplates/CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL'

/**
 * Get company templates list
 * @type {Redux.ActionType}
 */

export const COMPANY_TEMPLATES_LIST_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATES_LIST_REQUESTED'

/**
 * Get company templates list has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_LIST_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_LIST_FAIL'

/**
 * Get company templates list has succeeded
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_LIST_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATES_LIST_SUCCESS'

/**
 * Get company templates elements
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_REQUESTED'

/**
 * Get company templates elements has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_FAIL'

/**
 * Get company templates elements has succeeded
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_SUCCESS'

/**
 * update templates elements
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED'

/**
 * update templates elements has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL'

/**
 * update templates elements has succeeded
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS'

/**
 * delete templates elements
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED'

/**
 * delete templates elements has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL'

/**
 * delete templates elements has succeeded
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS'

/**
 * Reset company templates elements
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ELEMENTS_RESET =
  'companyTemplates/COMPANY_TEMPLATES_ELEMENTS_RESET'

/**
 * Get company template detail failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_DETAIL_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_DETAIL_FAIL'

/**
 * Get company templates details
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_DETAILS_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATES_DETAILS_REQUESTED'

/**
 * Get company templates details has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_DETAILS_FAIL =
  'companyTemplates/COMPANY_TEMPLATES_DETAILS_FAIL'

/**
 * Get company templates details has succeeded
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_DETAILS_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATES_DETAILS_SUCCESS'

/**
 * Reset company templates details
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_DETAILS_RESET =
  'companyTemplates/COMPANY_TEMPLATES_DETAILS_RESET'

/**
 * Delete a company template
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DELETE_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATE_DELETE_REQUESTED'

/**
 * Delete a company template has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DELETE_FAIL =
  'companyTemplates/COMPANY_TEMPLATE_DELETE_FAIL'

/**
 * Delete a company template was successful
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DELETE_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATE_DELETE_SUCCESS'

/**
 * Reset delete a company template state
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DELETE_RESET =
  'companyTemplates/COMPANY_TEMPLATE_DELETE_RESET'

/**
 * Reset company templates Errors
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATES_ERRORS_RESET =
  'companyTemplates/COMPANY_TEMPLATES_ERRORS_RESET'

/**
 * Duplicate a company template
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DUPLICATE_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATE_DUPLICATE_REQUESTED'

/**
 * Duplicate a company template has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DUPLICATE_FAIL =
  'companyTemplates/COMPANY_TEMPLATE_DUPLICATE_FAIL'

/**
 * Duplicate a company template was successful
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DUPLICATE_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATE_DUPLICATE_SUCCESS'

/**
 * Reset duplicate a company template state
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_DUPLICATE_RESET =
  'companyTemplates/COMPANY_TEMPLATE_DUPLICATE_RESET'

/**
 * Share a company template
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_SHARE_REQUESTED =
  'companyTemplates/COMPANY_TEMPLATE_SHARE_REQUESTED'

/**
 * Share a company template has failed
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_SHARE_FAIL =
  'companyTemplates/COMPANY_TEMPLATE_SHARE_FAIL'

/**
 * Share a company template was successful
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_SHARE_SUCCESS =
  'companyTemplates/COMPANY_TEMPLATE_SHARE_SUCCESS'

/**
 * Reset share a company template state
 * @type {Redux.ActionType}
 */
export const COMPANY_TEMPLATE_SHARE_RESET =
  'companyTemplates/COMPANY_TEMPLATE_SHARE_RESET'

/**
 * Get All Unit Space
 * @type {Redux.ActionType}
 */
export const ALL_UNIT_SPACES_REQUESTED =
  'companyTemplates/ALL_UNIT_SPACES_REQUESTED'

/**
 * Get All Unit Space has failed
 * @type {Redux.ActionType}
 */
export const ALL_UNIT_SPACES_FAIL = 'companyTemplates/ALL_UNIT_SPACES_FAIL'

/**
 * Get All Unit Space has succeeded
 * @type {Redux.ActionType}
 */
export const ALL_UNIT_SPACES_SUCCESS =
  'companyTemplates/ALL_UNIT_SPACES_SUCCESS'
