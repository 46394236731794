import React from 'react'
import './index.scss'

const InvoiceHeader = ({ invoice }) => {
  const { address, postal_code, state, city, country, property_name } =
    invoice || {}

  return (
    <div className="columns is-vcentered invoice-header">
      <div className="column">
        <span className="is-size-4 has-text-weight-bold">{property_name}</span>
      </div>
      <div className="column is-narrow has-text-right invoice-header__address">
        {`${address} | ${city}, ${state} ${postal_code} | ${country}`}
      </div>
    </div>
  )
}

export default InvoiceHeader
