import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Short from 'short-uuid'
import InvoiceData from 'components/invoice-data'
import InvoiceHeader from 'components/invoice-header'
import InvoiceItems from 'components/invoice-items'
import InvoiceSummary from 'components/invoice-summary'
import InvoiceTitle from 'components/invoice-title'
import { Page, StatusBar } from 'ui'
import { useAppContext } from '../../helpers/hooks'
import './index.scss'
import { Link } from 'react-router-dom'
import { getPurchaseDetails } from '../../modules/purchase-orders'
import { resetInvoiceDetails } from '../../modules/invoices'

const isEmpty = obj =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length

const InvoicesEdit = () => {
  const dispatch = useDispatch()
  const { id, vendor_id } = useParams()
  const {
    deleteIsRequesting,
    detailsIsRequesting: isLoading,
    details: invoice
  } = useSelector(state => state.invoices)

  const [current, setCurrent] = useState({ ...invoice })
  const [statusBar, setStatusBar] = useState()
  const {
    user: { pdbid },
    context
  } = useAppContext()

  useEffect(() => {
    if (isEmpty(invoice) && !isLoading && !deleteIsRequesting) {
      dispatch(getPurchaseDetails(pdbid, context, id, vendor_id))
    }

    setCurrent(prevCurrent => ({ ...prevCurrent, ...invoice }))
  }, [invoice, id, isLoading, pdbid, context, deleteIsRequesting])

  useEffect(() => {
    dispatch(resetInvoiceDetails())
  }, [])

  const addItem = () => {
    const emptyItem = {
      amount: 0,
      item: '',
      po_line_id: Short.uuid(),
      quantity: 1,
      rate: 0,
      task_id: Short.uuid(),
      unit: '',
      unit_space: '',
      isNew: true,
      newLine: true
    }

    setCurrent({
      ...current,
      po_lines: [...(current.po_lines || []), { ...emptyItem }]
    })
  }

  const updateItem = (itemTaskId, field, value) => {
    const itemIndex = (current.po_lines || []).findIndex(
      item => item.po_line_id === itemTaskId
    )
    const currentItem = (current.po_lines || [])[itemIndex]
    let amount = currentItem.amount || 0
    let newItem = currentItem.item || ''

    if (field === 'rate') {
      amount = parseFloat(currentItem?.quantity || 0) * parseFloat(value)
    } else if (field === 'quantity') {
      amount = parseFloat(currentItem?.rate || 0) * parseFloat(value)
    } else if (field === 'item') {
      newItem = value
    }

    setCurrent(prevCurrent => {
      let po_lines = prevCurrent.po_lines || []
      po_lines.splice(itemIndex, 1, {
        ...currentItem,
        [field]: parseFloat(value),
        amount,
        item: newItem
      })

      const subtotal = po_lines
        .filter(line => !line?.deleted)
        .reduce((_total, _current) => _total + _current.amount, 0)
      const total =
        po_lines
          .filter(line => !line?.deleted)
          .reduce((_total, _current) => _total + _current.amount, 0) +
        prevCurrent.tax_amount
      const amount_due = total - prevCurrent.amount_paid
      const total_amount = amount_due

      return {
        ...current,
        subtotal,
        amount_due,
        po_lines,
        total_amount
      }
    })
  }

  const deleteLine = itemTaskId => {
    const itemIndex = (current.po_lines || []).findIndex(
      item => item.po_line_id === itemTaskId
    )
    setCurrent(prevCurrent => {
      let po_lines = prevCurrent.po_lines || []
      po_lines[itemIndex]['deleted'] = true

      const subtotal = (po_lines || [])
        .filter(line => !line?.deleted)
        .reduce((_total, _current) => _total + _current.amount, 0)
      const total =
        (po_lines || [])
          .filter(line => !line?.deleted)
          .reduce((_total, _current) => _total + _current.amount, 0) +
        prevCurrent.tax_amount
      const amount_due = total - prevCurrent.amount_paid
      const total_amount = amount_due

      return {
        ...current,
        subtotal,
        amount_due,
        po_lines,
        total_amount
      }
    })
  }

  const states = {
    draft: () => (
      <>
        <strong>DRAFT. &nbsp;</strong>
      </>
    ),
    rejected: () => (
      <>
        <strong>
          <span
            style={{
              display: 'inline-block',
              width: '100%'
            }}>
            REJECTED. &nbsp; {invoice?.reason}
          </span>
        </strong>
      </>
    ),
    sent: () => (
      <>
        <strong>SENT.</strong>
      </>
    ),
    approved: () => (
      <>
        <strong>AP APPROVED.</strong>
      </>
    ),
    pending_ap_approval: () => (
      <>
        <strong>SITE APPROVED.</strong>
      </>
    )
  }

  useEffect(() => {
    if (invoice?.status) {
      const formattedInvoiceStatus = invoice?.status
        ?.toLowerCase()
        ?.replaceAll(' ', '_')
      setStatusBar(states[formattedInvoiceStatus])
    }
  }, [invoice?.status])

  const setInvoiceDate = po_date => setCurrent({ ...current, po_date })
  const setDueDate = due_date => setCurrent({ ...current, due_date })
  const setVendorInvoiceNumber = vendorInvoiceNumber => {
    setVendorInvoiceNumberCaveat(false)
    setCurrent({ ...current, vendor_po_number: vendorInvoiceNumber })
  }
  const setDoNotSync = value => {
    setCurrent({ ...current, do_not_sync: value ? 'true' : null })
  }
  const [vendorInvoiceNumberCaveat, setVendorInvoiceNumberCaveat] = useState(
    false
  )

  const { changeRequested } = context || {}

  if (changeRequested) {
    return <></>
  }

  return (
    <Page loading={isLoading}>
      <div className="columns">
        <Link
          to="/payables/purchase-orders"
          onClick={() => dispatch(resetInvoiceDetails())}>
          Back to Purchase Orders List
        </Link>
      </div>
      <InvoiceTitle
        invoice={current}
        setVendorInvoiceNumberCaveat={setVendorInvoiceNumberCaveat}
      />
      <div className="columns m-b-none m-t-sm">
        <div className="column p-none is-full">
          <StatusBar show={statusBar}>{statusBar}</StatusBar>
        </div>
      </div>
      <div className="columns animated fadeIn m-t-sm is-multiline invoice-edit__detail">
        <div className="column is-full invoice-edit__header">
          <InvoiceHeader invoice={invoice} />
          <InvoiceData
            invoice={invoice}
            setInvoiceDate={setInvoiceDate}
            setDueDate={setDueDate}
            setVendorInvoiceNumber={setVendorInvoiceNumber}
            setDoNotSync={setDoNotSync}
            currentData={current}
            vendorInvoiceNumberCaveat={vendorInvoiceNumberCaveat}
          />
        </div>
        <div className="column is-full p-none invoice-edit__body">
          {invoice?.po_lines && current?.po_lines && (
            <InvoiceItems
              invoice={invoice}
              addItem={addItem}
              deleteItem={deleteLine}
              updateItem={updateItem}
              current={current}
              setCurrent={setCurrent}
            />
          )}
        </div>
        <div className="column is-full invoice-edit__summary">
          <InvoiceSummary invoice={invoice} current={current} />
        </div>
      </div>
    </Page>
  )
}

export default InvoicesEdit
