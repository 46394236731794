import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import VendorQuotationMain from '../../parents/vendor-quotation-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'

const VendorQuotation = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <VendorQuotationMain {...props} />
  }

  return <BigLoading />
}

VendorQuotation.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
}

const mapStateToProps = ({ user, context, modal, requestVendors }) => ({
  user,
  context,
  modal,
  requestVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VendorQuotation)
)
