import React from 'react'
import PropTypes from 'prop-types'
import SchedulePlanningTool from '../schedule-planning-tool'
import Breadcrumbs from '../breadcrumbs'
import './index.scss'

const ScheduleMain = props => {
  const { location, schedule, openModal, closeModal, setModalContent, getSchedulingStatus, resetSchedulingStatus, autoscheduleService, context, user } = props

  return (
    <div className="container animated fadeIn">
      <div className="section">
        <div className="columns is-marginless">
          <div className="column is-one-half is-paddingless">
            <Breadcrumbs location={location} />
          </div>
        </div>
        <SchedulePlanningTool
          context={context}
          user={user}
          schedule={schedule}
          openModal={openModal}
          closeModal={closeModal}
          setModalContent={setModalContent}
          getSchedulingStatus={getSchedulingStatus}
          resetSchedulingStatus={resetSchedulingStatus}
          autoscheduleService={autoscheduleService}
        />
      </div>
    </div>
  )
}

ScheduleMain.propTypes = {
  location: PropTypes.object,
  getScheduleMainData: PropTypes.func,
}

export default ScheduleMain
