import React from 'react'
import PropTypes from 'prop-types'
import StatsCard from '../../components/stats-card'
import { statsCards } from './stats-cards'
import ProgressCardNew from '../../components/progress-card-new'

const PROGRESS_CARD = {
  total_beds: 'Total Beds',
  expected: 'Expected Keys',
  total_renewed: 'Renewals',
  total_vacant: 'Vacant'
}

const KeysStats = ({ keys, filter, cardClicked }) => {
  const getProgressCardData = () => {
    const { stats } = keys
    let data = []
    for (let key in PROGRESS_CARD) {
      const stat = stats[key]
      const display = PROGRESS_CARD[key]
      data.push({
        stat,
        display
      })
    }
    return data
  }

  const { stats, statsIsRequesting, statsHasRequested } = keys

  let template = []
  const showLoading = statsHasRequested === false || statsIsRequesting === true
  for (let i = 0; i < statsCards.length; i += 1) {
    const sc = statsCards[i]
    sc.stat = stats[sc.id]
    template.push(
      <div
        className="column is-full-mobile is-half-tablet is-one-third-desktop"
        key={`stats-card-${i}`}
        onClick={() => {
          cardClicked(sc.id)
        }}>
        <StatsCard
          icon={sc.icon}
          caption={sc.caption}
          stat={sc.stat}
          helpInfo={sc.helpInfo}
          showLoading={showLoading}
          isSelected={sc.id === filter}
          showUnits={false}
          spacesLabel={'Beds'}
        />
      </div>
    )
  }
  const cards = (
    <div className="column is-full is-three-quarters-widescreen" style={{ position: 'relative', zIndex: 1000 }}>
      <p className="m-b-sm" style={{ fontSize: 12 }}>Key Filters</p>
      <div className="columns is-mobile is-multiline">{template}</div>
    </div>
  )
  const summary = (
    <div className="column is-full is-one-quarter-widescreen">
      <p className="m-b-sm" style={{ fontSize: 12 }}>Summary</p>
      <ProgressCardNew
        topStat={stats.days_remaining}
        caption="Days to Move Out"
        percentComplete={stats.percent_complete}
        data={getProgressCardData()}
        showLoading={showLoading}
      />
    </div>
  )
  return (
    <div className="columns is-multiline">
      {document.body.clientWidth > 1024 ? (
        <>
          {cards}
          {summary}
        </>
      ) : (
        <>
          {summary}
          {cards}
        </>
      )}
    </div>
  )
}

KeysStats.propTypes = {
  keys: PropTypes.object,
  responsive: PropTypes.object,
  statsHasRequested: PropTypes.bool
}

export default KeysStats
