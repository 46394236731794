import _ from 'lodash'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import styled from 'styled-components'
import moment from 'moment'
import { exportFile, closeFilled, reopen, trash, note } from 'ui/icons'
import PayableTable from './components/PayableTable'
import { useModals } from 'hooks'
import ConfirmationModal from '../../components/confirmation-modal'
import Paginator from '../../components/react-table-pagination'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import InspectionMediaModal from '../../components/inspection-media-modal'
import DamageInspectionBulkStatusUpdate from '../damages-single-inspection-change-status'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import usePageReload from '../../hooks/usePageReload'
import { RouterPrompt } from '../../components/common/Prompt/RouterPrompt'
import { usePrevious, usePermissionGate } from '../../helpers/hooks'
import { capitalize } from '../../utils/helperFunctions'
import ToggleRequiredStatus from './components/ToggleRequiredStatus'
import AddCustomResidentDamageModal from '../add-custom-resident-damage-modal'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import './index.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;

  input {
    margin-top:8px !important;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E5E9F2;
    margin: 0px auto;
    outline: none;
    font-size: 10px
  }
  select {
    width: 100%
    margin-top:8px !important;
  }
  .approvals-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;

    .tr {
      position: relative;
     
      :first-child {
        .th {
          display:none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px
      :first-child {
        border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
    
  }
`

const DamageSingleInspectionTable = props => {
  const {
    user,
    context,
    getInspectionDownloadMediaURL,
    inspect,
    getSingleDamageInspection,
    damage,
    setModalContent,
    openModal,
    closeModal,
    saveDamageSingleInspection,
    changeDamageInspectionStatus,
    resetSingleDamageInspection,
    updateSingleInspectionData,
    getAdhocUnitSpaces,
    deleteAdhocDamage
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [showCustomModal, dismissCustomModal] = useModals()
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [singleInspectionData, setSingleInspectionData] = useState([])
  const [showModal, setModal] = useState(false)
  const [isPhotoLoading, setIsPhotoLoading] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [updateDamageInspectionList, setUpdateDamageInspectionList] = useState(
    false
  )
  const [isFormEdited, setIsFormEdited] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showRequiredStatus, setShowRequiredStatus] = useState(true)
  const [isSavingStatus, setIsSavingStatus] = useState(false)
  const [skipReset, setSkipReset] = useState(false)

  const tableRef = useRef()
  const history = useHistory()
  usePageReload(isFormEdited)
  const params = useParams()
  const { inspection_id } = params || {}

  const { hasPermission } = usePermissionGate('damage-action')

  // Redux
  const { singleDamageInspection, adhocUnitSpaces } = damage || {}
  const { data: inspectionList, isRequesting, hasMadeInitialRequest } =
    singleDamageInspection || {}
  const { inspectionDownloadMediaURL } = inspect || {}
  const { data: allResidentsData } = adhocUnitSpaces || {}

  const pdbid = user?.user_metadata?.pdbid

  const onSuccess = data => {
    if (data?.length) {
      getAdhocUnitSpaces(pdbid, context, data[0]?.unit_id)
    }
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later !!')
  }

  const prevContext = usePrevious(context)

  useEffect(() => {
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged && inspection_id) {
      getInspectionDownloadMediaURL(pdbid, context)
      resetSingleDamageInspection()
      getSingleDamageInspection(
        pdbid,
        context,
        inspection_id,
        onSuccess,
        onError
      )
    }
  }, [inspection_id])

  useEffect(() => {
    return () => resetSingleDamageInspection()
  }, [])

  useEffect(() => {
    if (inspectionList?.length) {
      if (showRequiredStatus) {
        const requiredStatus = (inspectionList || []).filter(
          data => data.damage_required
        )
        setSingleInspectionData(requiredStatus)
      } else {
        setSingleInspectionData(inspectionList)
      }
    }
  }, [inspectionList, singleDamageInspection, damage, showRequiredStatus])

  useEffect(() => {
    if (updateDamageInspectionList) {
      resetSingleDamageInspection()
      getSingleDamageInspection(
        pdbid,
        context,
        inspection_id,
        onSuccess,
        onError
      )
      setUpdateDamageInspectionList(false)
    }
  }, [updateDamageInspectionList])

  const caSplitsDecoded = val => {
    if (val == '1') {
      return 'All Residents'
    } else if (val == '2') {
      return 'Non-renewed Residents'
    } else if (val == '3') {
      return 'Custom'
    } else {
      return 'N/A'
    }
  }

  const getUniqueResident = () => {
    return [
      ...new Map(allResidentsData?.map(item => [item['value'], item]))?.values()
    ]
      ?.map(item => ({
        name: item.resident,
        value: item.resident,
        amount: 0,
        space_id: item.value,
        inspection_damage_split_id: null,
        is_non_renewed: item?.is_non_renewed,
        space: item?.name,
        unit_space_ext_id: item?.unit_space_ext_id,
        lease_ext_id: item?.lease_ext_id,
        tenant_ext_id: item?.tenant_ext_id
      }))
      ?.filter(
        res =>
          res?.value !== undefined &&
          res?.value !== null &&
          res?.value !== '' &&
          res?.value !== ' '
      )
  }

  const excelRowCommonSpaceTemplate = (damageData, residentData) => {
    const {
      building,
      unit,
      space,
      item,
      observation,
      damage_code,
      damages_generate,
      ca_split,
      building_ext_id,
      property_ext_id,
      unit_ext_id
    } = damageData || {}
    const { name, amount, unit_space_ext_id, lease_ext_id, tenant_ext_id } =
      residentData || {}
    return {
      Property: `"${_.get(context, 'property')}"` || '',
      Building: building || '',
      Unit: unit || `"${unit}"` || '',
      Space: space || `"${space}"` || '',
      Item: `"${item}"` || '',
      Observation: `"${observation}"` || '',
      'Generate Damage':
        damages_generate || damages_generate === null ? 'Yes' : 'No',
      Resident: name ? name : 'N/A',
      'CA Split Type': caSplitsDecoded(ca_split),
      'Date Cost': amount || 0,
      'Damage Code': damage_code || '',
      'Property Ext ID': `"${property_ext_id || ''}"` || '',
      'Building Ext ID': `"${building_ext_id || ''}"` || '',
      'Unit Ext ID': `"${unit_ext_id || ''}"` || '',
      'Unitspace Ext ID': amount > 0 ? `"${unit_space_ext_id || ''}"` : '',
      'Lease Ext ID': amount > 0 ? `"${lease_ext_id || ''}"` : '',
      'Tenant Ext ID': amount > 0 ? `"${tenant_ext_id || ''}"` : ''
    }
  }

  const excelRowRegularSpaceTemplate = (damageData, isCommon = false) => {
    const {
      building,
      unit,
      space,
      item,
      observation,
      damage_code,
      damage_cost,
      damages_generate,
      resident,
      ca_split,
      building_ext_id,
      property_ext_id,
      unit_ext_id,
      unit_space_ext_id,
      lease_ext_id,
      tenant_ext_id
    } = damageData || {}
    return {
      Property: `"${_.get(context, 'property')}"` || '',
      Building: building || '',
      Unit: unit || `"${unit}"` || '',
      Space: space || `"${space}"` || '',
      Item: `"${item}"` || '',
      Observation: `"${observation}"` || '',
      'Generate Damage':
        damages_generate || damages_generate === null ? 'Yes' : 'No',
      Resident: resident ? resident : 'N/A',
      'CA Split Type': caSplitsDecoded(ca_split),
      'Date Cost': damage_cost || 0,
      'Damage Code': damage_code || '',
      'Property Ext ID': `"${property_ext_id || ''}"` || '',
      'Building Ext ID': `"${building_ext_id || ''}"` || '',
      'Unit Ext ID': `"${unit_ext_id || ''}"` || '',
      'Unitspace Ext ID': `"${unit_space_ext_id || ''}"`,
      'Lease Ext ID': `"${lease_ext_id || ''}"`,
      'Tenant Ext ID': `"${tenant_ext_id || ''}"`
    }
  }

  const exportCSV = () => {
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const rows = singleInspectionData || []
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : rows
    defaultData = (defaultData || [])?.filter(item => item?.damages_generate)
    let customData = []
    let uniqueResidents = []
    const filterData = (defaultData || []).map(list => {
      const { damage_cost, ca_split, is_ca, splits } = list || {}
      if (is_ca) {
        if (ca_split == '1') {
          uniqueResidents = getUniqueResident()
          const totalAmount = damage_cost
          const isDecimal = totalAmount % 1 !== 0
          const amountPerResident = totalAmount / uniqueResidents?.length
          uniqueResidents.forEach(resident => {
            resident.amount = isDecimal
              ? amountPerResident.toFixed(2)
              : amountPerResident
          })
          uniqueResidents.map(res =>
            customData.push(excelRowCommonSpaceTemplate(list, res))
          )
          //customData.push({})
        }
        if (ca_split == '2') {
          uniqueResidents = getUniqueResident()?.filter(
            resident => resident.is_non_renewed
          )
          const totalAmount = damage_cost
          const isDecimal = totalAmount % 1 !== 0
          const amountPerResident = totalAmount / uniqueResidents?.length
          uniqueResidents.forEach(resident => {
            resident.amount = isDecimal
              ? amountPerResident.toFixed(2)
              : amountPerResident
          })
          uniqueResidents.map(res =>
            customData.push(excelRowCommonSpaceTemplate(list, res))
          )
          //customData.push({})
        }
        if (ca_split == '3') {
          if (splits?.length) {
            uniqueResidents = getUniqueResident()?.map(data => {
              splits.forEach(split => {
                if (data?.space_id === split?.space_id) {
                  data.amount = split.charge
                  data.inspection_damage_split_id =
                    split.inspection_damage_split_id
                }
              })
              return data
            })
          }
          uniqueResidents.map(res =>
            customData.push(excelRowCommonSpaceTemplate(list, res))
          )
          //customData.push({})
        }
        if (ca_split === null) {
          if (
            list?.resident !== undefined &&
            list?.resident !== null &&
            list?.resident !== '' &&
            list?.resident !== ' '
          ) {
            customData.push(excelRowRegularSpaceTemplate(list, true))
          }
          //customData.push({})
        }
      } else {
        if (
          list?.resident !== undefined &&
          list?.resident !== null &&
          list?.resident !== '' &&
          list?.resident !== ' '
        ) {
          customData.push(excelRowRegularSpaceTemplate(list, false))
        }
      }
      return
    })
    const data = customData || []
    const keys = Object.keys(data[0] || [])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${
        singleInspectionData?.length
          ? `${singleInspectionData[0]?.inspection_name?.replace(
              ' ',
              '-'
            )}-Inspection`
          : 'single-inspection'
      }-${datetime}.csv`
    )

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const toggleModal = row => {
    setModal(!showModal)
    setCurrentRow(row)
  }

  const onHandleApproveModal = ({
    msg,
    submitAction,
    dismissLabel,
    modalTitle,
    submitLabel,
    subMsg,
    additionalSubmitButtonClasses
  }) => {
    showCustomModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        useLoading={true}
        dismiss={dismissCustomModal}
      />
    )
  }

  const updateInspectionValue = (index, value, type) => {
    setSkipReset(() => true)
    setSingleInspectionData(prevData => {
      return prevData.map((data, i) => {
        if (i === index) {
          data[type] = value
          if (type === 'damage_cost') {
            if (data?.splits && data?.splits?.length) {
              data.splits = data.splits?.map(split => {
                return {
                  ...split,
                  charge: 0
                }
              })
            }
          }
        }
        return data
      })
    })
    setIsFormEdited(true)

    updateSingleInspectionData(singleInspectionData)
  }

  const updateCustomResidentValues = (damage_cost, data, row_id, splits) => {
    setSkipReset(() => true)
    const getUnitId = data?.filter(d => d.id == row_id)
    setModalContent(() => (
      <AddCustomResidentDamageModal
        {...props}
        damage_cost={damage_cost}
        row_id={row_id}
        unit_id={getUnitId?.length ? getUnitId[0]?.unit_id : ''}
        splits={splits}
        isView={false}
        getCustomResidentDataFromModal={getCustomResidentDataFromModal}
        ca_split_type={getUnitId?.length ? getUnitId[0]?.ca_split : ''}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '500px',
      maxWidth: '95%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  const onUpdateDamageInspectionStatus = row => {
    let selectedDamageInspectionRows = row || []
    const defaultProps = {
      ...props,
      setUpdateDamageInspectionList,
      selectedDamageInspectionRows,
      status: selectedDamageInspectionRows[0]
    }
    setModalContent(() => (
      <DamageInspectionBulkStatusUpdate
        {...defaultProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '590px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  const getCustomResidentDataFromModal = (customSplits, id, type) => {
    setSingleInspectionData(prevData => {
      return prevData.map(data => {
        if (data.id === id) {
          if (type == '3') {
            data.splits = customSplits
          }
          data.ca_split = type
        }
        return data
      })
    })
    setIsFormEdited(true)
    updateSingleInspectionData(singleInspectionData)
  }

  const onDeleteAdditionalDamageSuccess = () => {
    setUpdateDamageInspectionList(true)
  }
  const onDeleteAdditionalDamageError = () => {
    setErrorMessage(
      'Something went wrong while deleting additional damage(s). Please try again later !!!'
    )
  }

  const onHandleDeleteAdditionalDamage = async (
    selectedRow,
    isSingleDelete = false
  ) => {
    let deleteRows = []
    if (isSingleDelete) {
      deleteRows = (selectedRow || []).map(row => ({
        inspection_adhoc_damage_id: row?.inspection_adhoc_damage_id
      }))
    } else {
      deleteRows = (selectedRows || []).map(row => {
        const { original } = row || {}
        return {
          inspection_adhoc_damage_id: original?.inspection_adhoc_damage_id
        }
      })
    }
    const body = {
      items: deleteRows
    }
    await deleteAdhocDamage({
      pdbid,
      context,
      body,
      onSuccess: onDeleteAdditionalDamageSuccess,
      onError: onDeleteAdditionalDamageError
    })
  }

  const onHandleSingleAdhocChargeDelete = row => {
    return onHandleApproveModal({
      msg:
        'You are about to delete this additional damage. This action is irreversible. Are you sure you want to proceed?',
      dismissLabel: 'Cancel',
      modalTitle: 'Warning',
      submitLabel: 'Yes',
      submitAction: () => {
        dismissCustomModal()
        onHandleDeleteAdditionalDamage(row, true)
      }
    })
  }

  // Row option based on conditions
  const getRowOption = (row = {}) => {
    const {
      is_ca,
      damage_cost,
      id: row_id,
      unit_id,
      splits,
      ca_split,
      is_adhoc,
      inspection_status
    } = row || {}
    let options = []
    if (is_ca && ca_split !== null) {
      options.push({
        caption: 'Show Split Charges',
        icon: note,
        action: () => {
          if (hasPermission) {
            setModalContent(() => (
              <AddCustomResidentDamageModal
                {...props}
                damage_cost={damage_cost}
                row_id={row_id}
                unit_id={unit_id || ''}
                splits={splits}
                isView={true}
                ca_split_type={ca_split}
                getCustomResidentDataFromModal={getCustomResidentDataFromModal}
                onClose={() => {
                  closeModal()
                }}
              />
            ))
            openModal({
              width: '500px',
              maxWidth: '95%',
              additionalClasses: 'turnboard-all-notes-modal'
            })
          }
        }
      })
    }
    if (is_adhoc && inspection_status !== 'Damages Approved') {
      options.push({
        caption: 'Delete Damage',
        icon: trash,
        action: () => {
          if (hasPermission) {
            onHandleSingleAdhocChargeDelete([row])
          }
        }
      })
    }

    return options || []
  }

  const tableColumns = headerData({
    isPhotoLoading,
    setIsPhotoLoading,
    toggleModal,
    updateInspectionValue,
    hasPermission,
    getRowOption,
    updateCustomResidentValues
  })

  const columns = useMemo(() => tableColumns, [])

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  const onFormSaveSuccess = () => {
    setIsFormEdited(false)
    setIsSaving(false)
    setIsSavingStatus(false)
    setErrorMessage('')
    setUpdateDamageInspectionList(true)
  }

  const onFormStatusUpdate = () => {
    setIsSavingStatus(true)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    const data = [
      {
        inspection_id: singleInspectionData[0]?.inspection_id,
        status:
          singleInspectionData[0]?.inspection_status === 'Damages Approved'
            ? 'Approved'
            : 'Damages Approved'
      }
    ]
    changeDamageInspectionStatus({
      pdbid,
      context,
      body: {
        items: data || []
      },
      onSuccess: () => {
        setErrorMessage('')
        setIsFormEdited(false)
        setIsSaving(false)
        setIsSavingStatus(false)
        setUpdateDamageInspectionList(true)
      },
      onError: () => {
        setIsSavingStatus(false)
        setErrorMessage('Something went wrong. Please try again later !!!')
      }
    })
  }

  const onFormSaveError = () => {
    setIsSavingStatus(false)
    setIsSaving(false)
    setErrorMessage('Something went wrong. Please try again later !!!')
  }

  const showErrorModal = () => {
    setModalContent(() => (
      <ConfirmationModal
        msg="The custom Common Area split does not match the total damage amount.  
        Please update the custom split amount and save again"
        dismissLabel="Okay"
        modalTitle="Error"
        submit={() => {}}
        dismiss={dismissCustomModal}
        showSubmitButton={false}
      />
    ))
    openModal({
      width: '460px',
      maxWidth: '95%',
      additionalClasses: 'turnboard-all-notes-modal'
    })
  }

  const onSaveDamageData = async (statusChange = false) => {
    let isError = false
    const body = (singleInspectionData || [])?.map((data, i) => {
      let damage_cost = data?.damage_cost
      if (data?.ca_split == '3' && data?.splits?.length) {
        const totalResidentCharge = data?.splits?.reduce(
          (accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue.charge)
          },
          0.0
        )
        if (damage_cost != totalResidentCharge) {
          setIsSaving(false)
          setIsSavingStatus(false)
          showErrorModal()
          isError = true
          return
        }
      }
      if (data?.ca_split == '3' && data?.splits === null) {
        setIsSaving(false)
        setIsSavingStatus(false)
        showErrorModal()
        isError = true
        return
      }
      if (
        data?.ca_split == '3' &&
        (!data?.damage_cost ||
          data?.damage_cost === null ||
          data?.damage_cost == '0')
      ) {
        setIsSaving(false)
        setIsSavingStatus(false)
        showErrorModal()
        isError = true
        return
      }
      let body = {
        damage_code: data.damage_code || ' ',
        damage_cost: data.damage_cost || 0,
        damages_generate: data?.damages_generate === true ? true : false,
        line_observation_id: data.line_observation_id,
        inspection_adhoc_damage_id: data.inspection_adhoc_damage_id,
        splits: data.splits?.length ? data.splits : null,
        ca_split_type: data.ca_split !== 'Select' ? data.ca_split : null
      }
      if (data?.is_adhoc) {
        body.status = null
      } else {
        body.damage_required =
          data.simplified_damage_required === 'Not Required' ? 'false' : null
      }
      return body
    })
    if (isError) {
      setIsSaving(false)
      setIsSavingStatus(false)
    } else {
      setSelectedPageRowData(0)
      setRowsPerPage(30)
      await saveDamageSingleInspection({
        pdbid,
        context,
        body: {
          items: body
        },
        inspection_id: singleInspectionData[0]?.inspection_id,
        onSuccess: statusChange ? onFormStatusUpdate : onFormSaveSuccess,
        onError: onFormSaveError
      })
    }
  }

  const onInspectionStatusUpdate = () => {
    setIsSavingStatus(true)
    onSaveDamageData(true)
  }

  const userAction = useMemo(() => {
    if (
      singleInspectionData?.length &&
      (singleInspectionData[0]?.inspection_status !== null ||
        singleInspectionData[0]?.inspection_status !== '')
    ) {
      if (singleInspectionData[0]?.inspection_status === 'Damages Approved') {
        return (
          <button
            className="button is-danger"
            disabled={!hasPermission}
            style={{ cursor: !hasPermission ? 'not-allowed' : 'pointer' }}
            onClick={e => {
              e.preventDefault()
              if (hasPermission) {
                onHandleApproveModal({
                  msg:
                    'You are about to unapprove the damages of this inspection. You can manually revert this change via the approve button, if needed.',
                  dismissLabel: 'Cancel',
                  modalTitle: 'Confirm',
                  submitLabel: 'Yes',
                  submitAction: () => {
                    dismissCustomModal()
                    onInspectionStatusUpdate()
                  }
                })
              }
            }}>
            Unapprove
          </button>
        )
      }
      return (
        <button
          className="button is-primary"
          disabled={!hasPermission || isSavingStatus}
          style={{
            cursor: !hasPermission || isSavingStatus ? 'not-allowed' : 'pointer'
          }}
          onClick={e => {
            e.preventDefault()
            if (hasPermission) {
              onHandleApproveModal({
                msg:
                  'You are about to approve the damages of this inspection. You can manually revert this change via the unapprove button, if needed.',
                dismissLabel: 'Cancel',
                modalTitle: 'Confirm',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onInspectionStatusUpdate()
                }
              })
            }
          }}>
          {isSavingStatus && (
            <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
          )}
          Approve
        </button>
      )
    }
  }, [damage, inspectionList, singleInspectionData, hasPermission])

  const createAdHoc = useMemo(() => {
    if (
      singleInspectionData?.length &&
      (singleInspectionData[0]?.inspection_status !== null ||
        singleInspectionData[0]?.inspection_status !== '')
    ) {
      return (
        <button
          disabled={!hasPermission}
          style={{ cursor: !hasPermission ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            history.push(
              `/inspections/damages/createadhocdamage/${singleInspectionData[0]?.unit_id}/${singleInspectionData[0]?.inspection_id}`
            )
          }}
          className="button is-primary">
          Add Damage
        </button>
      )
    }
  }, [damage, inspectionList, singleInspectionData, hasPermission])

  const onSaveForm = async () => {
    setIsSaving(true)
    setIsSavingStatus(false)
    onSaveDamageData()
  }

  const handleDropdownChange = (value, itemIndex) => {
    setSingleInspectionData(prevData => {
      return prevData?.map((data, i) => {
        if (i === itemIndex) {
          return {
            ...data,
            ca_split: value
          }
        }
        return data
      })
    })
    setIsFormEdited(true)
  }

  const deleteAdditionalChargeButton = () => {
    if (
      singleInspectionData &&
      singleInspectionData?.length &&
      (selectedRows || []).length !== 0
    ) {
      let disabledStatusUpdate = true
      let showTooltip = false
      if ((selectedRows || []).length !== 0) {
        const simplifyRows = (selectedRows || []).map(row => row?.original)
        if ((simplifyRows || []).every(damage => damage?.is_adhoc)) {
          disabledStatusUpdate = false
        }
        if (hasPermission && disabledStatusUpdate) {
          showTooltip = true
        }
      }
      const bulkStatusUpdateButton = (
        <button
          disabled={
            !(selectedRows || []).length ||
            !hasPermission ||
            disabledStatusUpdate
          }
          style={{
            cursor:
              !(selectedRows || []).length ||
              disabledStatusUpdate ||
              !hasPermission
                ? 'not-allowed'
                : 'pointer'
          }}
          onClick={() => {
            if (hasPermission) {
              onHandleApproveModal({
                msg:
                  'You are about to delete these additional damages. This action is irreversible. Are you sure you want to proceed?',
                dismissLabel: 'Cancel',
                modalTitle: 'Warning',
                submitLabel: 'Yes',
                submitAction: () => {
                  dismissCustomModal()
                  onHandleDeleteAdditionalDamage()
                }
              })
            }
          }}
          className="button is-danger">
          Delete Additional Charge
        </button>
      )
      let bulkStatusbuttonToRender = bulkStatusUpdateButton
      if (showTooltip) {
        bulkStatusbuttonToRender = <span>{bulkStatusUpdateButton}</span>
      }
      return (
        <>
          <Tippy
            distance={20}
            content={
              (selectedRows || []).length &&
              hasPermission &&
              disabledStatusUpdate && (
                <div>
                  <p>
                    It seems you have selected damages belonging to
                    mixed/different statuses. Only Additional damage can be
                    deleted.
                  </p>
                </div>
              )
            }
            placement="bottom"
            allowHTML
            theme="light">
            {bulkStatusbuttonToRender}
          </Tippy>
        </>
      )
    }
  }

  return (
    <>
      <RouterPrompt
        title="You are about to exit without saving your changes. If you wish to proceed, all changes will be discarded."
        okText="Discard"
        cancelText="Cancel"
        when={isFormEdited}
      />
      <div className="approvals-list-container ">
        <div className="data-table-wrapper">
          <div className="data-table-header">
            <div
              className="header"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <h2 className="heading-5">
                {capitalize(
                  inspectionList?.length
                    ? `${inspectionList[0]?.inspection_name} Inspection`
                    : ''
                )}
              </h2>
              {singleInspectionData?.length &&
              singleInspectionData[0]?.inspection_status !==
                'Damages Approved' ? (
                <button
                  onClick={() => onSaveForm()}
                  style={{
                    height: '40px',
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '22px'
                  }}
                  disabled={!isFormEdited || isSaving || !hasPermission}
                  className="button is-success">
                  {isSaving && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
                  )}
                  Save Damage
                </button>
              ) : null}
            </div>
            <div className="action-button">
              <div className="inspection-action-buttons">
                {userAction}
                {singleInspectionData[0]?.inspection_status !==
                'Damages Approved'
                  ? createAdHoc
                  : null}
                {singleInspectionData?.length &&
                singleInspectionData[0]?.inspection_status !==
                  'Damages Approved'
                  ? deleteAdditionalChargeButton()
                  : null}
                {(checkIsFilterApplied || []).length !== 0 && (
                  <button
                    onClick={() => {
                      onResetFilter()
                    }}
                    className="button is-secondary">
                    Clear All Filter(s)
                  </button>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'end',
                  gap: '2.5rem'
                }}>
                <button className="export" onClick={() => exportCSV()}>
                  <img src={exportFile} alt="Export Inspections" />
                  Export
                </button>
              </div>
            </div>
            {errorMessage !== '' && (
              <div>
                <br />
                <div className="notification is-danger is-light is-flex">
                  <p>{errorMessage}</p>
                  <button onClick={() => setErrorMessage('')}>
                    <img src={closeFilled} alt="" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {hasMadeInitialRequest && !isRequesting ? (
            <Styles>
              <PayableTable
                ref={tableRef}
                columns={columns}
                data={singleInspectionData}
                onRowSelectStateChange={setSelectedRows}
                setIsFilterApplied={setIsFilterApplied}
                setSelectedPage={setSelectedPage}
                setSelectedPageRowData={setSelectedPageRowData}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                onDropdownItemChange={handleDropdownChange}
                skipReset={skipReset}
              />
            </Styles>
          ) : (
            <BigLoading />
          )}
          <div className="table-pagination">
            {hasMadeInitialRequest && !isRequesting ? (
              <Paginator
                previousPage={tableRef.current?.previousPage}
                nextPage={tableRef.current?.nextPage}
                rowsPerPage={rowsPerPage}
                rowCount={selectedPageRowData}
                currentPage={selectedPage}
                onChangePage={page => tableRef.current?.gotoPage(page - 1)}
                setRowsPerPage={pageSize => {
                  const { setPageSize } = tableRef.current
                  setPageSize(pageSize)
                }}
                limit={[30, 50, 70, 100]}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {showModal && (
          <InspectionMediaModal
            {...props}
            onToggle={toggleModal}
            onError={setErrorMessage}
            currentRow={currentRow}
            inspectionMediaList={currentRow?.line_media?.map(media => {
              media.unit = currentRow.unit
              return media
            })}
            inspectionDownloadMediaURL={inspectionDownloadMediaURL}
            type="damage"
          />
        )}
      </div>
    </>
  )
}

export default DamageSingleInspectionTable
