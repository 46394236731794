import React, { useState, forwardRef, createRef } from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import en from 'date-fns/locale/en-US'
import { calendar } from 'ui/icons'
import './styles.scss'
registerLocale('en', en)
/**
 * Component for showing a date picker input
 *
 * @component
 * @example
 * return (
 *   <DatePickerInput
 *     value={new Date()}
 *   />
 * )
 */
const DatePickerInput = forwardRef((props, ref) => {
  const { isDisabled } = props || {}
  return (
    <div className="field">
      <p className="control has-icons-right">
        <input
          className="input"
          type="text"
          placeholder="Select"
          value={props.value}
          readOnly
          onFocus={props.onClick}
          ref={ref}
          disabled={isDisabled}
        />
        <span className="icon is-small is-right has-text-primary" style={{ height: '2em' }}>
          <img src={calendar} alt="datepicker" />
        </span>
      </p>
    </div>
  )})

DatePickerInput.propTypes = {
  /**
   * Initial value
   */
  value: PropTypes.string,
  /**
   * Event handler for focus action
   */
  onClick: PropTypes.func
}

/**
 * Component for showing a date picker
 *
 * @component
 * @example
 * return (
 *   <DatePicker
 *     value={new Date()}
 *   />
 * )
 */
const DatePicker = ({ onChange, value, minDate, isDisabled=false }) => {
  const [currentDate, setCurrentDate] = useState(value || new Date())
  const inputRef = createRef()

  const handleChange = date => {
    setCurrentDate(date)
    onChange(date)
  }

  return (
    <ReactDatePicker
      locale="en"
      minDate = { minDate ? minDate : null }
      disabled={isDisabled}
      selected={value || currentDate}
      onChange={e => handleChange(e)}
      customInput={<DatePickerInput ref={inputRef} isDisabled={isDisabled} />}
    />
  )
}

DatePicker.propTypes = {
  /**
   * Initial value
   */
  value: PropTypes.object,
  /**
   * Event handler for change action
   */
  onChange: PropTypes.func,
  /**
   * Minimum value user can select
   */
  minDate: PropTypes.object,
  /**
   * Disabled the date picker
   */
  isDisabled: PropTypes.bool
}

export default DatePicker
