import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'

import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { closeFilled } from 'ui/icons'
import ErrorTemplate from '../../components/forms-helper/ErrorTemplate'
import PermissionRuleFieldTemplate from '../../components/forms-helper/PermissionRuleFieldTemplate'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import ToggleEnableLogin from './ToggleEnableLogin'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import GeneralField from '../../components/forms-helper/GeneralField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import InspectionFileUploadModal from '../inspection-fileupload-modal'
import { usePrevious } from '../../helpers/hooks'
import BigLoading from '../../components/big-loading'

import './index.scss'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

const AddAdHocDamage = props => {
  const {
    history,
    user,
    context,
    setModalContent,
    openModal,
    closeModal,
    inspect,
    getInspectionDownloadMediaURL,
    getInspectionUploadMediaURL,
    getAdhocUnitSpaces,
    damage,
    saveAdhocDamage,
    setAlert,
    getSingleDamageInspection
  } = props || {}
  const { pdbid } = user?.user_metadata

  const [isSubmitting, setSubmitting] = useState(false)
  const [spaceType, setSpaceType] = useState('')
  const [customResident, setCustomResident] = useState([])
  const [generateDamage, setGenerateDamage] = useState(true)
  const [damageCost, setDamageCost] = useState('')
  const [damageCode, setDamageCode] = useState('')
  const [selectedSplitType, setSelectedSplitType] = useState('')
  const [adhocNotes, setAdhocNotes] = useState('')
  const [uploadedMedia, setUploadedMedia] = useState([])

  const splitTypeOptions = [
    {
      name: 'All Residents',
      value: '1'
    },
    {
      name: 'Non-renewed Residents',
      value: '2'
    },
    {
      name: 'Custom',
      value: '3'
    }
  ]

  const unit_id = props?.match?.params?.id
  const inspection_id = props?.match?.params?.inspection_id

  const prevContext = usePrevious(context)

  useEffect(() => {
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged && inspection_id && unit_id) {
      getSingleDamageInspection(pdbid, context, inspection_id)
      getInspectionDownloadMediaURL(pdbid, context)
      getInspectionUploadMediaURL(pdbid, context)
      getAdhocUnitSpaces(pdbid, context, unit_id)
    }
  }, [inspection_id])

  const { inspectionUploadMediaURL, inspectionDownloadMediaURL } = inspect || {}
  const { adhocUnitSpaces, singleDamageInspection } = damage || {}
  const { data: allUnitSpaces, isRequesting: unitSpacesRequesting } =
    adhocUnitSpaces || {}
  const { allInspectionSpaces, isRequesting: damagesListRequesting } =
    singleDamageInspection || {}

  const constructAllSpaces = () => {
    let damageSpaces = []
    let unitSpaces = []
    if (allInspectionSpaces?.length) {
      damageSpaces = allInspectionSpaces?.map(damage => ({
        name: damage?.unit_space,
        value: damage?.unit_space_id,
        resident: damage?.resident,
        is_non_renewed: true
      }))
    }
    if (allUnitSpaces?.length) {
      unitSpaces = allUnitSpaces?.map(damage => ({
        name: damage?.name,
        value: damage?.value,
        resident: damage?.resident,
        is_non_renewed: true
      }))
    }
    const mergedValues = [...damageSpaces, ...unitSpaces]
    if (mergedValues?.length) {
      return (
        [...new Map(mergedValues?.map(item => [item['value'], item]))?.values()]
          ?.filter(res => {
            if (res?.name?.toLowerCase() !== 'common') {
              return (
                res?.resident !== undefined &&
                res?.resident !== null &&
                res?.resident !== '' &&
                res?.resident !== ' '
              )
            }
            return true
          })
          ?.filter(
            res =>
              res?.value !== undefined &&
              res?.value !== null &&
              res?.value !== ''
          ) || []
      )
    }
    return []
  }

  const allResidentsOptions = useMemo(() => {
    return allUnitSpaces
      ?.map(item => ({
        name: item?.resident,
        value: item?.resident,
        amount: 0,
        space_id: item?.value,
        inspection_damage_split_id: null,
        space: item?.name
      }))
      ?.sort((a, b) =>
        a?.space?.localeCompare(b?.space, undefined, {
          sensitivity: 'base'
        })
      )
      ?.filter(
        res =>
          res?.value !== undefined &&
          res?.value !== null &&
          res?.value !== '' &&
          res?.value !== ' '
      )
  }, [allUnitSpaces])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '')?.toString()?.indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '')?.length - x
  }

  const onHandleDamageCostChange = ({ target }) => {
    const { value } = target || {}
    let filterValue = value || ''
    if (selectedSplitType.name === 'Custom') {
      filterValue = (value || '')
        .replace(/^0+(\d)/, '$1')
        .replace(/[^\d.]/g, '')
    } else {
      filterValue = (value || '')?.replace(/[^0-9\.]/g, '')
    }
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    if ((filterValue || '')?.toString()?.split('.')?.length > 2) {
      filterValue = (filterValue || '')?.replace(/\.+$/, '')
    }
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setDamageCost(filterValue)
    if (customResident?.length) {
      setCustomResident(prevValue => {
        const updatedResident = prevValue?.map(resident => ({
          ...resident,
          amount: 0
        }))
        return updatedResident
      })
    }
  }

  const onHandleDamageCodeChange = ({ target }) => {
    const { value } = target || {}
    let filterValue = value || ''
    // Only allow Numbers and .
    filterValue = (value || '')?.replace(/[^0-9A-Za-z\-/]/g, '')
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Don't allow decimal points
    if (hasDecimalPlace(filterValue, 0)) {
      return
    }
    setDamageCode(filterValue)
  }

  const onHandleDamageChargeChange = ({ target }, i) => {
    const { value } = target || {}
    let filterValue = value || ''
    filterValue = (value || '')
      ?.replace(/^0+(\d)/, '$1')
      ?.replace(/[^\d.]/g, '')
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    if ((filterValue || '')?.toString()?.split('.')?.length > 2) {
      filterValue = (filterValue || '')?.replace(/\.+$/, '')
    }
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setCustomResident(prevState => {
      return prevState?.map((item, index) => {
        if (index === i) {
          return { ...item, amount: filterValue }
        } else {
          return item
        }
      })
    })
  }

  const onUploadFileHandler = () => {
    setModalContent(() => (
      <InspectionFileUploadModal
        {...props}
        onClose={() => {
          closeModal()
        }}
        onDisplayUploadMedia={uploadedFiles => {
          setUploadedMedia(prevState => [...prevState, ...uploadedFiles])
          closeModal()
        }}
        inspectionUploadMediaURL={inspectionUploadMediaURL}
        inspectionDownloadMediaURL={inspectionDownloadMediaURL}
      />
    ))
    openModal({
      width: '580px',
      maxWidth: '100%',
      additionalClasses: 'inspection-fileupload-modal'
    })
  }

  const checkIfCostExceed = useMemo(() => {
    const totalResidentAmount = customResident?.reduce(
      (accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount)
      },
      0.0
    )
    return totalResidentAmount > parseFloat(damageCost) ? true : false
  }, [customResident, damageCost])

  const checkIfCostIsLess = useMemo(() => {
    const totalResidentAmount = customResident?.reduce(
      (accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount)
      },
      0.0
    )
    return totalResidentAmount < parseFloat(damageCost) ? true : false
  }, [customResident, damageCost])

  const getFullMediaPath = file_name => {
    const { project, property } = context || {}
    return `${inspectionDownloadMediaURL || ''}Inspections/${project ||
      ''}_${property || ''}/${file_name || ''}`
  }

  const onHandleRemoveFile = index => {
    const updateSelectedMedia = (uploadedMedia || [])?.map((file, i) => {
      if (i === index) {
        return { ...file, deleted: true }
      }
      return { ...file, deleted: false }
    })
    const removeDeletedFiles = updateSelectedMedia?.filter(
      file => !file.deleted
    )
    setUploadedMedia(removeDeletedFiles)
  }

  const onSuccess = () => {
    setSubmitting(false)
    setAlert('Additional damage successfully created', 'success')
    history.goBack()
  }

  const onError = message => {
    setSubmitting(false)
    if (message) {
      setAlert(message, 'danger')
    } else {
      setAlert('Something went wrong. Please try again later!!!', 'danger')
    }
  }

  const onHandleCreateAdHoc = () => {
    setSubmitting(true)
    const payload = {
      identity: Math.floor(Math.random() * 10000000000) + 10000,
      inspection_adhoc_damage_id: null,
      header_id: inspection_id,
      unit_space_id: spaceType?.value,
      note: adhocNotes,
      status: null,
      damages_generate: generateDamage === true ? true : null,
      damage_cost: damageCost || 0,
      damage_code: damageCode || ' ',
      ca_split:
        spaceType?.name?.toLowerCase() === 'common'
          ? selectedSplitType?.value
          : null,
      splits:
        selectedSplitType?.name === 'Custom'
          ? customResident?.map(res => ({
              inspection_damage_split_id: null,
              line_observation_id: null,
              space_id: res.space_id,
              charge: res.amount
            }))
          : null,
      media: uploadedMedia?.length
        ? uploadedMedia?.map(media => ({
            URI: media?.fileName,
            type: media?.type
          }))
        : null
    }
    saveAdhocDamage(pdbid, context, payload, onSuccess, onError)
  }

  const isCreateButtonDisable = useMemo(() => {
    if (!(adhocNotes || '')?.trim() || !spaceType?.name) {
      return true
    }

    if (spaceType?.name?.toLowerCase() === 'common') {
      if (!selectedSplitType || !selectedSplitType.name) {
        return true
      }

      if (selectedSplitType.name === 'Custom') {
        if (!damageCost || damageCost <= 0 || checkIfCostExceed) {
          return true
        }

        const totalResidentAmount = customResident?.reduce(
          (accumulator, currentValue) => {
            return accumulator + parseFloat(currentValue.amount)
          },
          0.0
        )

        if (totalResidentAmount < parseFloat(damageCost)) {
          return true
        }
      }
    }

    return false
  }, [
    adhocNotes,
    spaceType,
    damageCost,
    checkIfCostExceed,
    selectedSplitType,
    customResident
  ])

  const displayErrorMessage = (message = 'Error') => {
    return (
      <ErrorTemplate>
        <div>
          <p>{message}</p>
        </div>
      </ErrorTemplate>
    )
  }

  const onHandleDisplayErrors = () => {
    if (selectedSplitType?.name === 'Custom' && customResident?.length === 0) {
      return displayErrorMessage(
        'No Resident found, to proceed with custom cost.'
      )
    } else if (
      selectedSplitType?.name === 'Custom' &&
      customResident?.length !== 0 &&
      (!damageCost || damageCost == 0 || damageCost === '')
    ) {
      return displayErrorMessage(
        'To proceed with custom cost type, please insert a damage cost first.'
      )
    } else if (
      selectedSplitType?.name === 'Custom' &&
      customResident?.length !== 0 &&
      damageCost &&
      damageCost > 0
    ) {
      if (checkIfCostExceed) {
        return displayErrorMessage(
          'The sum of all residents damage costs cannot exceed the total damage cost.'
        )
      } else if (checkIfCostIsLess) {
        return displayErrorMessage(
          'The sum of all residents damage costs cannot be less than the total damage cost.'
        )
      }
    }
  }

  return (
    <>
      {damagesListRequesting || unitSpacesRequesting ? (
        <BigLoading />
      ) : (
        <div className="columns is-multiline adhoc-damage">
          <div className="column is-half animated fadeIn">
            <div className="box is-shadowless has-transition-opacity">
              <FieldTemplate>
                <div className="label-heading column">
                  <strong className="is-size-5">Additional Damage</strong>
                </div>
              </FieldTemplate>
              <FieldTemplate>
                <GeneralField fieldLabel="Damage Cost" isRequired={false}>
                  <input
                    value={damageCost}
                    type="text"
                    onChange={onHandleDamageCostChange}
                    className="input form-control"
                    placeholder="Enter Cost"></input>
                </GeneralField>
              </FieldTemplate>
              <FieldTemplate>
                <GeneralField fieldLabel="Space">
                  <div style={{ marginTop: '2px' }}>
                    <MultiSelectDropdown
                      displayKey="name"
                      value={spaceType}
                      defaultValues={constructAllSpaces()}
                      onChange={v => {
                        setSpaceType(v)
                        setSelectedSplitType('')
                        setCustomResident([])
                      }}
                      dropdownIcon={true}
                      isMulti={false}
                      placeholder="Select Space"
                      noOptionsMessage="No Space Available"
                      customStyles={selectStyles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </GeneralField>
              </FieldTemplate>
              {spaceType?.name?.toLowerCase() === 'common' ? (
                <FieldTemplate>
                  <GeneralField fieldLabel="CA Split Type" isRequired={true}>
                    <div style={{ marginTop: '2px' }}>
                      <MultiSelectDropdown
                        displayKey="name"
                        value={selectedSplitType}
                        defaultValues={splitTypeOptions}
                        onChange={v => {
                          setCustomResident([])
                          if (v?.name === 'Custom') {
                            setCustomResident(allResidentsOptions)
                          }
                          setSelectedSplitType(v)
                        }}
                        dropdownIcon={true}
                        isMulti={false}
                        placeholder="Select CA Split Type"
                        noOptionsMessage="No Split Type Available"
                        customStyles={selectStyles}
                        menuPortalTarget={document.body}
                      />
                    </div>
                  </GeneralField>
                </FieldTemplate>
              ) : null}
              {onHandleDisplayErrors()}
              {damageCost && damageCost > 0 && customResident?.length
                ? (customResident || [])?.map((resident, i) => {
                    return (
                      <div className="columns is-vcentered mb-0" key={i}>
                        <div
                          className="column is-full is-10-fullhd is-offset-1-fullhd"
                          key={i}>
                          <div className="resident-container">
                            <div className="columns is-desktop is-mobile mb-0">
                              <div className="column permission-label">
                                <strong>{`Space ${resident?.space}:`}</strong>
                              </div>
                            </div>
                            <PermissionRuleFieldTemplate>
                              <GeneralField fieldLabel="Resident">
                                <input
                                  value={resident?.value}
                                  type="text"
                                  className="input form-control"
                                  disabled={true}
                                  placeholder="Enter Resident"></input>
                              </GeneralField>
                            </PermissionRuleFieldTemplate>
                            <PermissionRuleFieldTemplate>
                              <GeneralField fieldLabel="Amount">
                                <input
                                  id="amount"
                                  name="amount"
                                  value={resident?.amount}
                                  onBlur={() =>
                                    setCustomResident(prevState => {
                                      return prevState?.map((item, index) => {
                                        if (index === i) {
                                          return {
                                            ...item,
                                            amount:
                                              resident?.amount === ''
                                                ? 0
                                                : resident?.amount
                                          }
                                        } else {
                                          return item
                                        }
                                      })
                                    })
                                  }
                                  onChange={e =>
                                    onHandleDamageChargeChange(e, i)
                                  }
                                  type="text"
                                  className="input form-control"
                                  placeholder="Enter Amount"></input>
                              </GeneralField>
                            </PermissionRuleFieldTemplate>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null}
              <FieldTemplate>
                <GeneralField fieldLabel="Generate Damage?" isRequired={false}>
                  <div style={{ marginTop: '-14px' }}>
                    <ToggleEnableLogin
                      onChange={v => setGenerateDamage(v)}
                      isActive={generateDamage}
                    />
                  </div>
                </GeneralField>
              </FieldTemplate>

              <FieldTemplate>
                <GeneralField fieldLabel="Damage Code" isRequired={false}>
                  <input
                    value={damageCode}
                    onChange={onHandleDamageCodeChange}
                    type="text"
                    className="input form-control"
                    placeholder="Enter Code"></input>
                </GeneralField>
              </FieldTemplate>
              {/* <FieldTemplate>
                <GeneralField fieldLabel="Status">
                  <div style={{ marginTop: '2px' }}>
                    <MultiSelectDropdown
                      displayKey="name"
                      value={status}
                      defaultValues={statusOptions}
                      onChange={v => setStatus(v)}
                      dropdownIcon={true}
                      isMulti={false}
                      placeholder="Select Status"
                      noOptionsMessage="No Status Available"
                      customStyles={selectStyles}
                      menuPortalTarget={document.body}
                    />
                  </div>
                </GeneralField>
              </FieldTemplate> */}
              <FieldTemplate isDisabledVcentered>
                <GeneralField fieldLabel="Notes" isRequired={true}>
                  <textarea
                    className="textarea  input form-control"
                    rows={3}
                    style={{ resize: 'none', marginTop: '2px' }}
                    defaultValue={adhocNotes}
                    onChange={e => setAdhocNotes(e.target.value)}
                  />
                </GeneralField>
              </FieldTemplate>
              <FieldTemplate isDisabledVcentered>
                <GeneralField fieldLabel="Upload Photos" isRequired={false}>
                  <div className="state-description is-flex is-align-items-center is-flex-wrap-wrap">
                    {uploadedMedia?.map(({ fileName, preview, type }, i) => {
                      const toLowerType = (type || '')
                        ?.toString()
                        ?.toLowerCase()
                      return (
                        <div
                          key={i}
                          className="inspection-thumbnail-inner is-flex is-align-items-center">
                          {toLowerType === 'photo' && (
                            <img
                              src={preview || getFullMediaPath(fileName)}
                              alt=""
                            />
                          )}
                          {toLowerType === 'video' && (
                            <video
                              src={preview || getFullMediaPath(fileName)}
                            />
                          )}
                          <button onClick={() => onHandleRemoveFile(i)}>
                            <img src={closeFilled} alt="" />
                          </button>
                        </div>
                      )
                    })}
                    <button
                      className="is-link"
                      onClick={() => onUploadFileHandler()}>
                      {(uploadedMedia || [])?.length !== 0
                        ? 'Add another file'
                        : 'Choose File'}
                    </button>
                  </div>
                </GeneralField>
              </FieldTemplate>

              <div>
                <div className="button-wrapper">
                  <button
                    className="button main-button is-secondary m-r-md"
                    onClick={() => history.goBack()}>
                    Cancel
                  </button>
                  <span data-tip data-for="AddAdHocDamage">
                    <button
                      onClick={e => {
                        e.preventDefault()
                        if (!isCreateButtonDisable) {
                          onHandleCreateAdHoc()
                        }
                      }}
                      className={`button main-button is-primary ${
                        isCreateButtonDisable ? 'isDisabled' : ''
                      }`}>
                      Create
                      {isSubmitting && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          className="m-l-sm"
                        />
                      )}
                    </button>
                  </span>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="AddAdHocDamage"
                    place="top"
                    disable={!isCreateButtonDisable}
                    effect="solid">
                    <div>
                      <p>
                        Please fill all the required (
                        <span className="required-star">*</span>) fields
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default AddAdHocDamage
