import { isEmpty } from 'lodash'
import React from 'react'

const ServiceStatusColumn = ({ row, serviceName, hasPermission }) => {
  const { cell, onServiceStatusChange, column } = row || {}
  const {
    value,
    row: { index, original }
  } = cell || {}

  const serviceStatus = original[`${serviceName}_status`]
  const isInHouse = !isEmpty(original[`${serviceName}_inhouse_id`])
  const isServiceApproved = serviceStatus === 'Approved' && !isInHouse

  return (
    <>
      {hasPermission ? (
        <div class="select">
          <select
            value={value}
            disabled={isServiceApproved}
            onChange={e => {
              if (!isServiceApproved) {
                onServiceStatusChange(index, column.id, e.target.value)
              }
            }}>
            <option value="N/A">N/A</option>
            <option value="Not Started">Not Started</option>
            <option value="In Progress">In Progress</option>
            <option value="Approved">Approved</option>
          </select>
        </div>
      ) : (
        <div className="cell-item" title={value}>
          {value}
        </div>
      )}
    </>
  )
}

export default ServiceStatusColumn
