import React, { useState, useRef } from 'react'
import { BryntumCalendar } from '@bryntum/calendar-react'
import { hexToRGB } from '../../utils/index'
import './index.scss'

const ScheduleCalendar = ({ data }) => {
  const [activeData, setActiveData] = useState({ resources: 'servicesResources', events: 'servicesData', activeTab: 'services' })
  const calendar = useRef(null)
  
  const dataSelectorHandler = (resourcesToUse, eventsToUse, activeTab)  => {
    setActiveData({ resources: resourcesToUse, events: eventsToUse, activeTab })
  }

  return (
    <BryntumCalendar
      ref={calendar}
      sidebar={null}
      weekStartDay={1}
      date={new Date()}
      cls={'boards-calendar'}
      mode={'month'}
      tbar={{
        items: {
          todayButton: null,
          spacer: null,
          setsOfData: {
            type: 'ButtonGroup',
            toggleGroup : true,
            items : [
              { text: 'Services', pressed: true, onClick: () => dataSelectorHandler('servicesResources', 'servicesData', 'services'), toggleable : true, },
              { text: 'Teams', pressed: false, onClick: () => dataSelectorHandler('teamsResources', 'teamsData', 'teams'), toggleable : true, },
              // { text: 'Groups', pressed: false, onClick: () => dataSelectorHandler('groupsResources', 'groupsData', 'groups'), toggleable : true, },
            ],
            cls: 'data-selector',
            weight: 100,
          },
          prevButton: { weight: 150 },
          viewDescription: { weight: 200, marginLeft: 0 },
          nextButton: { weight: 250 },
          modeSelector: { cls: 'mode-selector' }
        }
      }}
      modes={{
        agenda: false,
        year: false,
        month: {
          cls: 'month-container',
          eventRenderer({ eventRecord, renderData }) {
            return `
              <div class="progress-container" style="background: ${hexToRGB(renderData.eventColor, .2)};">
                <div class="progress" style="width: ${eventRecord.data.percentage}%; background: ${renderData.eventColor}"></div>
                <span class="name">${eventRecord.data.name}: ${eventRecord.data.completed}</span>
                <span class="percentage">${eventRecord.data.percentage}%</span>
              </div>
            `
          },
        },
        week: {
          cls: 'week-container',
          dayStartTime: 0,
          dayEndTime: 13.5,
          visibleStartTime: 0,
          hourHeight: 35,
          eventRenderer({ eventRecord, renderData }) {
            return `
              <div class="week progress-container" style="background: ${hexToRGB(renderData.eventColor, .2)};">
                <div class="progress" style="width: ${eventRecord.data.percentage}%; background: ${renderData.eventColor}"></div>
                <span class="name">${eventRecord.data.name}: ${eventRecord.data.completed}</span>
                <span class="percentage">${eventRecord.data.percentage}%</span>
              </div>
            `
          },
        },
        day: {
          cls: 'day-container',
          dayStartTime: 0,
          dayEndTime: 13.5,
          visibleStartTime: 0,
          hourHeight: 35,
          eventRenderer({ eventRecord, renderData }) {
            return `
              <div class="day progress-container" style="background: ${hexToRGB(renderData.eventColor, .2)};">
                <div class="progress" style="width: ${eventRecord.data.percentage}%; background: ${renderData.eventColor}"></div>
                <span class="name">${eventRecord.data.name}: ${eventRecord.data.completed}</span>
                <span class="percentage">${eventRecord.data.percentage}%</span>
              </div>
            `
          },
        }
      }}
      eventEditFeature={false}
      eventTooltipFeature={{
        showOn : 'click',
        align : 'l-r',
        minWidth : null,
        titleRenderer : ({data}) => `${data.name}: ${data.completed}`,
        tools: {
          edit: null,
          delete: null
        },
        renderer : ({ eventRecord }) => {
          const { data } = eventRecord

          switch (data.type) {
            case 'service':
              const servicesItems = data.breakdown.map(item => `<li class="item">${item.service}: ${item.total}</li>`)
              return `
                <p class="title">Service Breakdown:</p>
                <ul>
                  ${servicesItems.join('\r\n')}
                </ul>
              `
            
            case 'team':
              const items = data.breakdown.map(item => `<li class="item">${item.service}: ${item.total}</li>`)
              return `
                <p class="title">Service: <span class="item" style="font-size: 14px;">${data.service}</span></p>
                <p class="title">Service Breakdown:</p>
                <ul>
                  ${items.join('\r\n')}
                </ul>
              `
          
            default:
              return
          }
        }
      }}
      resources={data[activeData.resources]}
      events={data[activeData.events]}
    />
  )
}

export default ScheduleCalendar
