// import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { getProjectId } from './dashboard-helpers'

/**
 * Dashboard turn data module
 * @redux
 * @reduxActionScope dashboard-turn
 * @module dashboard-turn
 */

/**
 * Request dashboard turn data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_TURN_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_TURN_SUMMARY_REQUESTED'
/**
 * Dashboard turn data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_TURN_SUMMARY_FAIL =
  'dashboard/DASHBOARD_TURN_SUMMARY_FAIL'
/**
 * Update request dashboard turn with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_TURN_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_TURN_SUMMARY_SUCCESS'

const initialPropertyState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: {
    totalSpaces: 0,
    totalUnits: 0,
    totalRenewals: 0,
    totalNewLeases: 0,
    vacant: 0,
    fullyRenewed: 0,
    renewalTransferOut: 0,
    renewalTransferIn: 0,
    leapfrogs: 0
  }
}

const initialState = {}

const DEFAULT = 0

/**
 * Dashboard turn reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_TURN_SUMMARY_REQUESTED:
      const prevData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...prevData,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case DASHBOARD_TURN_SUMMARY_SUCCESS:
      const prevDataSuccess = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...prevDataSuccess,
          isRequesting: false,
          isError: false,
          data: action.data,
        }
      }
    case DASHBOARD_TURN_SUMMARY_FAIL:
      const prevDataError = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...prevDataError,
          isRequesting: false,
          isError: true,
          data: {
            totalSpaces: 0,
            totalUnits: 0,
            totalRenewals: 0,
            totalNewLeases: 0,
            vacant: 0,
            fullyRenewed: 0,
            renewalTransferOut: 0,
            renewalTransferIn: 0,
            leapfrogs: 0
          },
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard turn data
 * @redux
 * @reduxActionCreator DASHBOARD_TURN_SUMMARY_REQUESTED, DASHBOARD_TURN_SUMMARY_SUCCESS, DASHBOARD_TURN_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getTurnSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_TURN_SUMMARY_REQUESTED,
      context
    })
    return Service.setupGoalStatsGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const result = await res.json()
            dispatch({
              type: DASHBOARD_TURN_SUMMARY_SUCCESS,
              data: turnAdapter(result['items'][0]),
              context
            })
          } catch {
            _turnSummaryFail(dispatch, context)
          }
        } else {
          _turnSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _turnSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _turnSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_TURN_SUMMARY_FAIL,
    context
  })
}

const turnAdapter = data => {
  return {
    totalSpaces: data.total_beds || DEFAULT,
    totalUnits: data.total_units || DEFAULT,
    totalRenewals: data.total_renewed || DEFAULT,
    totalNewLeases: data.total_new_leases || DEFAULT,
    vacant: data.total_vacant || DEFAULT,
    fullyRenewed: data.fully_renewed || DEFAULT,
    renewalTransferOut: data.renewal_transfer_out || DEFAULT,
    renewalTransferIn: data.renewal_transfer_in || DEFAULT,
    leapfrogs: data.total_leapfrog || DEFAULT
  }
}
