import add from './icons/setup-add.svg'
import addBlack from './icons/add-black.svg'
import trash from './icons/trash@2x.svg'
import projects from './icons/large@2x.svg'

export const icons = {
  add,
  addBlack,
  trash,
  projects
}
