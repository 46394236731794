import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import sortBy from 'lodash/sortBy'
// import { usePrevious } from '../../helpers/hooks'
import Breadcrumbs from '../breadcrumbs'
import CompanyTemplateList from '../company-template-list'
// import TemplateCard from '../template-card'
import CompanyTemplateManage from '../company-template-manage'

const CompanyTemplatesMain = props => {
  const {
    getCompanyTemplateList,
    user,
    context,
    // companyTemplates,
    location,
    history
  } = props
  const { pdbid } = user.user_metadata
  // const prevContext = usePrevious(context)

  const templateManageMode = location.pathname.includes('manage')
  const searchParams = new URLSearchParams(location.search)
  const templateId = searchParams.get('template')

  // Commented Temporarily
  // const inspectionSetupMode = location.pathname.includes('setup')

  // const recentlyCreated = sortBy(
  //   companyTemplates.list,
  //   ['created_date']
  // ).slice(0, 4)

  // const recentlyUpdated = sortBy(
  //   companyTemplates.list,
  //   ['updated_date']
  // ).slice(0, 4)

  useEffect(() => {
    const paramsLength = Array.from(searchParams).length

    if (!paramsLength) {
      getCompanyTemplateList(pdbid, context)
    }
  }, [location.search])

  // useEffect(() => {
  //   if (
  //     context?.project !== prevContext?.project ||
  //     context?.property !== prevContext?.property
  //   ) {
  //     getCompanyTemplateList(pdbid, context)
  //   }
  // }, [context])

  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        {/* <div className="columns">
          <div className="column">
            {!templateManageMode ? (
              <>
                Commented Temporarily
                <div className="columns is-multiline">
                  <div className="column is-full">
                    <p className="m-b-sm" style={{ fontSize: 12 }}>
                      Recently Created
                    </p>
                    <div className="columns is-mobile is-multiline">
                      <div
                        className="column is-full is-flex is-flex-wrap-wrap"
                        style={{ flexWrap: 'wrap' }}>
                        {recentlyCreated.map(item => (
                          <TemplateCard
                            key={item.template_id}
                            template={item}
                            editModeEnabled={inspectionSetupMode}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="column is-full">
                    <p className="m-b-sm" style={{ fontSize: 12 }}>
                      Recently Updated
                    </p>
                    <div className="columns is-mobile is-multiline">
                      <div
                        className="column is-flex is-flex-wrap-wrap"
                        style={{ flexWrap: 'wrap' }}>
                        {recentlyUpdated.map(item => (
                          <TemplateCard
                            key={item.template_id}
                            template={item}
                            editModeEnabled={inspectionSetupMode}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="columns m-b-lg">
                  <Link to="/inspections-setup/company-templates">
                    Back to List View
                  </Link>
                </div>
                <CompanyTemplateManage
                  templateId={templateId}
                  history={history}
                />
              </>
            )}
          </div>
        </div> */}
      </div>

      <div className="section">
        {!templateManageMode ? (
          <CompanyTemplateList {...props} />
        ) : (
          <>
            <div className="columns m-b-lg" style={{ marginLeft: '0' }}>
              <Link to="/inspections-setup/company-templates">
                Back to List View
              </Link>
            </div>
            <CompanyTemplateManage templateId={templateId} history={history} />
          </>
        )}
      </div>
    </div>
  )
}

CompanyTemplatesMain.propTypes = {
  context: PropTypes.object,
  getCompanyTemplateList: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object
}

export default CompanyTemplatesMain
