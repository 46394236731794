import React from 'react'
import { icons } from './icons'
import TurnboardHeader from './components/TurnboardHeader'
import TurnboardCellComponent from './components/TurnboardCell'
import PermissionGate from '../../components/permission-gate'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import { usePermissionGate } from '../../helpers/hooks'
import TagsColumnFilter from './components/TagsColumnFilter'
import TurnboardOverviewCell from './components/TurnboardOverviewCell'
import OverviewColumnFilter from './components/OverviewColumnFilter'
import FutureLeaseStatusCell from './components/FutureLeaseStatusCell'
import CurrentLeaseStatusCell from './components/CurrentLeaseStatusCell'

let hideColumns = []

let columnKeysToHide = ['scheduled_move_out', 'actual_move_out']

const getHiddenColumns = (state, column) => {
  columnKeysToHide.push(...column)

  if (state) {
    hideColumns = [...hideColumns, ...column]
  } else {
    const newColumns = (hideColumns || []).filter(item => {
      return column.indexOf(item) === -1
    })
    hideColumns = newColumns
  }

  return hideColumns
}

export const columnKeys = columnKeysToHide

const getTurnboardDetailServices = (
  services,
  isAllColumnCollapsed,
  isTurnboardOverview,
  onHideColumns,
  hiddenColumns
) => {
  return (services || []).map(service => {
    const { label, servicetype } = service || {}
    const serviceName = (servicetype || '')
      .toString()
      .replace(' ', '_')
      .toLowerCase()

    return label === 'Sparkle' ||
      label === 'Maintenance' ||
      label === 'Punch' ||
      label === 'Walk'
      ? {
          Header: options => {
            return (
              <TurnboardHeader
                options={options}
                title={label}
                backgroundColor="#122048"
                isAllColumnCollapsed={isAllColumnCollapsed}
                isTurnboardOverview={isTurnboardOverview}
                hiddenColumns={hiddenColumns}
                onToggle={state => {
                  const column = [
                    `${serviceName}_option`,
                    `${serviceName}_team`,
                    `${serviceName}_complete`,
                    `${serviceName}_status`
                  ]
                  onHideColumns(column, state)
                }}></TurnboardHeader>
            )
          },
          id: serviceName,
          columns:
            label === 'Walk'
              ? [
                  {
                    Header: 'Assigned',
                    disableSortBy: true,
                    accessor: `${serviceName}_assigned`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100
                  },
                  {
                    Header: 'Completed',
                    disableSortBy: true,
                    accessor: `${serviceName}_complete`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100
                  },
                  {
                    Header: 'Status',
                    disableSortBy: true,
                    accessor: `${serviceName}_status`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100
                  }
                ]
              : [
                  {
                    Header: 'Team',
                    disableSortBy: true,
                    accessor: `${serviceName}_team`,
                    Cell: row => {
                      return <TurnboardCellComponent row={row} />
                    },
                    width: 100
                  },
                  {
                    Header: 'Service Option',
                    disableSortBy: true,
                    width: 200,
                    accessor: `${serviceName}_option`,
                    Cell: row => {
                      return <TurnboardCellComponent row={row} />
                    }
                  },
                  {
                    Header: 'Status',
                    disableSortBy: true,
                    accessor: `${serviceName}_status`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100
                  },
                  {
                    Header: 'Assigned',
                    disableSortBy: true,
                    accessor: `${serviceName}_assigned`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100

                    // Filter: SelectColumnFilter,
                    // filter: 'equals',
                  },
                  {
                    Header: 'Completed',
                    disableSortBy: true,
                    accessor: `${serviceName}_complete`,
                    Cell: row => {
                      return (
                        <TurnboardCellComponent
                          row={row}
                          serviceName={serviceName}
                        />
                      )
                    },
                    width: 100
                  }
                ]
        }
      : {
          Header: options => {
            return (
              <TurnboardHeader
                options={options}
                title={label}
                backgroundColor="#122048"
                isAllColumnCollapsed={isAllColumnCollapsed}
                isTurnboardOverview={isTurnboardOverview}
                hiddenColumns={hiddenColumns}
                onToggle={state => {
                  const column = [
                    `${serviceName}_option`,
                    `${serviceName}_team`,
                    `${serviceName}_complete`,
                    `${serviceName}_final_cost`,
                    `${serviceName}_additional_cost`,
                    `${serviceName}_estimated_cost`,
                    `${serviceName}_status`
                  ]

                  onHideColumns(column, state)
                }}></TurnboardHeader>
            )
          },
          id: serviceName,
          columns: [
            {
              Header: 'Team',
              accessor: `${serviceName}_team`,
              disableSortBy: true,
              Cell: row => {
                return <TurnboardCellComponent row={row} />
              },
              width: 100
            },
            {
              Header: 'Service Option',
              accessor: `${serviceName}_option`,
              width: 200,
              disableSortBy: true,
              Cell: row => {
                return <TurnboardCellComponent row={row} />
              }
            },
            {
              Header: 'Status',
              disableSortBy: true,
              accessor: `${serviceName}_status`,
              Cell: row => {
                return (
                  <TurnboardCellComponent row={row} serviceName={serviceName} />
                )
              },
              width: 100
            },
            {
              Header: 'Assigned',
              accessor: `${serviceName}_assigned`,
              disableSortBy: true,
              Cell: row => {
                return (
                  <TurnboardCellComponent row={row} serviceName={serviceName} />
                )
              },
              width: 100

              // Filter: SelectColumnFilter,
              // filter: 'equals',
            },
            {
              Header: 'Completed',
              disableSortBy: true,
              accessor: `${serviceName}_complete`,
              Cell: row => {
                return (
                  <TurnboardCellComponent row={row} serviceName={serviceName} />
                )
              },
              width: 100
            },
            {
              Header: 'Est. Cost',
              disableSortBy: true,
              accessor: `${serviceName}_estimated_cost`,
              Cell: row => {
                return <TurnboardCellComponent row={row} />
              },
              width: 100
            },
            {
              Header: 'Add. Cost',
              disableSortBy: true,
              accessor: `${serviceName}_additional_cost`,
              Cell: row => {
                return <TurnboardCellComponent row={row} />
              },
              width: 100
            },
            {
              Header: 'Final. Cost',
              disableSortBy: true,
              accessor: `${serviceName}_final_cost`,
              Cell: row => {
                return <TurnboardCellComponent row={row} />
              },
              width: 100
            }
          ]
        }
  })
}

const getTurnboardOverviewServices = (
  services,
  isAllColumnCollapsed,
  isTurnboardOverview,
  onHideColumns,
  hiddenColumns
) => {
  return [
    {
      Header: options => {
        return (
          <TurnboardHeader
            options={options}
            title="Services"
            backgroundColor="#122048"
            isAllColumnCollapsed={isAllColumnCollapsed}
            isTurnboardOverview={isTurnboardOverview}
            hiddenColumns={hiddenColumns}
            onToggle={state => {
              const column = [
                'paint_overview_status',
                'punch_overview_status',
                'cleaning_overview_status',
                'carpet_clean_overview_status',
                'carpet_replace_overview_status',
                'concrete/vinyl_replace_overview_status',
                'sparkle_overview_status'
              ]

              onHideColumns(column, state)
            }}></TurnboardHeader>
        )
      },
      id: 'Services',
      columns: (services || []).map(service => {
        const serviceKey = (service?.servicetype || '')
          .toLowerCase()
          .replaceAll(' ', '_')

        return {
          Header: service?.servicetype,
          disableSortBy: true,
          accessor: `${serviceKey}_overview_status`,
          Filter: OverviewColumnFilter,
          filter: 'includesSome',
          Cell: row => {
            return <TurnboardOverviewCell row={row} serviceName={serviceKey} />
          },
          width: 100
        }
      })
    }
  ]
}

export const headerData = ({
  services = [],
  notes,
  addNote,
  isAllColumnCollapsed,
  manageTags,
  isTurnboardOverview,
  onHideColumns,
  hiddenColumns
}) => [
  {
    Header: () => {
      return (
        <TurnboardHeader
          title="Unit"
          backgroundColor="#8BD3ED"
          isAllColumnCollapsed={isAllColumnCollapsed}
          isTurnboardOverview={isTurnboardOverview}></TurnboardHeader>
      )
    },
    sticky: 'left',
    id: 'unit',
    columns: [
      {
        Header: 'Building',
        accessor: 'building',
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Floor',
        accessor: 'floor',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 150
      },
      {
        Header: 'Space',
        accessor: 'unit_space',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      }
    ]
  },
  {
    Header: options => {
      return (
        <TurnboardHeader
          options={options}
          title="Details"
          backgroundColor="#8BD3ED"
          isAllColumnCollapsed={isAllColumnCollapsed}
          isTurnboardOverview={isTurnboardOverview}
          hiddenColumns={hiddenColumns}
          onToggle={state => {
            const column = [
              'lease_status',
              'ca_needed',
              'floorplan',
              'has_note',
              'simplifiedTags'
            ]

            onHideColumns(column, state)
          }}></TurnboardHeader>
      )
    },
    id: 'details',
    columns: [
      {
        Header: 'Group',
        accessor: 'grouping',
        disableSortBy: true,
        // Filter: GroupColumnFilter,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Keys Status',
        accessor: 'key_status_text',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Current Lease Status',
        accessor: 'current_lease_status',
        disableSortBy: true,
        Cell: row => {
          return <CurrentLeaseStatusCell row={row} />
        },
        width: 150
      },
      {
        Header: 'Future Lease Status',
        accessor: 'lease_status',
        disableSortBy: true,
        Cell: row => {
          return <FutureLeaseStatusCell row={row} />
        },
        width: 150
      },
      {
        Header: 'CA Needed',
        accessor: 'ca_needed',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Floorplan',
        accessor: 'floorplan',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Notes',
        accessor: 'has_note',
        disableSortBy: true,
        disableFilters: true,
        Cell: row => {
          const { cell, value, state, row: selectedRow } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { isFirstSpaceRow } = original || {}
          const { selectedRowIds } = state || {}
          const { id } = selectedRow || {}

          return (
            <>
              {isFirstSpaceRow ? (
                <div
                  className="cell-item note-icon"
                  style={{
                    backgroundColor: Object.keys(selectedRowIds).includes(id)
                      ? '#55C2B8'
                      : null
                  }}>
                  {value ? (
                    <img
                      className="is-pointer is-icon"
                      onClick={() => notes(row)}
                      src={icons.note}
                      alt="View Notes"
                      title="View Notes"
                    />
                  ) : (
                    <PermissionGate name={'turnboard-actions'}>
                      <img
                        className="is-pointer is-icon"
                        onClick={() => addNote(row)}
                        src={icons.addButton}
                        alt="Add Note"
                        title="Add Note"
                      />
                    </PermissionGate>
                  )}
                </div>
              ) : (
                <div
                  className="cell-item"
                  style={{
                    backgroundColor: Object.keys(selectedRowIds).includes(id)
                      ? '#55C2B8'
                      : null
                  }}></div>
              )}
            </>
          )
        },
        width: 100
      },
      {
        Header: 'Tag',
        accessor: 'simplifiedTags',
        disableSortBy: true,
        // Filter: TagsColumnFilter,
        Cell: row => {
          const { cell, state, row: selectedRow } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { isFirstSpaceRow } = original || {}
          const { selectedRowIds } = state || {}
          const { id } = selectedRow || {}
          const { tags } = original || {}
          const { hasPermission } = usePermissionGate('turnboard-actions')
          const TooltipData = () => {
            return (
              <div>
                <p>Tags:</p>
                {(tags || []).map(tag => {
                  const { tag_id, text, color } = tag || {}
                  return (
                    <div
                      className="pill"
                      style={{ backgroundColor: color, color: '#ffffff' }}
                      key={tag_id}>
                      <span>{text}</span>
                    </div>
                  )
                })}
              </div>
            )
          }
          return (
            <>
              {isFirstSpaceRow ? (
                <div
                  className="cell-item note-icon tags-container"
                  style={{
                    backgroundColor: Object.keys(selectedRowIds).includes(id)
                      ? '#55C2B8'
                      : null
                  }}>
                  {(tags || []).length !== 0 ? (
                    (tags || []).slice(0, 3).map((tag, index) => {
                      const { tag_id, text, color } = tag || {}
                      return (
                        <div
                          key={index}
                          onClick={() => {
                            if (hasPermission) {
                              manageTags(original)
                            }
                          }}
                          className={`pill ${
                            (tags || []).length > 1 ? 'p-absolute' : ''
                          }`}
                          style={{ backgroundColor: color, color: '#ffffff' }}>
                          <Tippy
                            className="tooltip-container"
                            content={<TooltipData></TooltipData>}
                            placement="bottom"
                            theme="light">
                            <span key={tag_id}>{text}</span>
                          </Tippy>
                        </div>
                      )
                    })
                  ) : (
                    <PermissionGate name={'turnboard-actions'}>
                      <img
                        className="is-pointer is-icon"
                        onClick={() => manageTags(original)}
                        src={icons.addTagButton}
                        alt="Click to add a tag"
                        title="Click to add a tag"
                      />
                    </PermissionGate>
                  )}
                </div>
              ) : (
                <div
                  className="cell-item"
                  style={{
                    backgroundColor: Object.keys(selectedRowIds).includes(id)
                      ? '#55C2B8'
                      : null
                  }}></div>
              )}
            </>
          )
        },
        width: 100
      }
    ]
  },
  {
    Header: options => {
      return (
        <TurnboardHeader
          options={options}
          title="Move-In Dates"
          backgroundColor="#3DB3E2"
          isAllColumnCollapsed={isAllColumnCollapsed}
          isTurnboardOverview={isTurnboardOverview}
          hiddenColumns={hiddenColumns}
          onToggle={state => {
            const column = ['scheduled_move_out', 'actual_move_out']
            onHideColumns(column, state)
          }}></TurnboardHeader>
      )
    },
    id: 'move_in_dates',
    columns: [
      {
        Header: 'Early Move In',
        accessor: 'early_move_in',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Scheduled Move Out',
        accessor: 'scheduled_move_out',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      },
      {
        Header: 'Actual Move Out',
        accessor: 'actual_move_out',
        disableSortBy: true,
        Cell: row => {
          return <TurnboardCellComponent row={row} />
        },
        width: 100
      }
    ]
  },

  ...(isTurnboardOverview
    ? getTurnboardOverviewServices(
        services,
        isAllColumnCollapsed,
        isTurnboardOverview,
        onHideColumns,
        hiddenColumns
      )
    : getTurnboardDetailServices(
        services,
        isAllColumnCollapsed,
        isTurnboardOverview,
        onHideColumns,
        hiddenColumns
      ))
]

export const customFilters = [
  {
    key: 'renewal_transfer_in',
    icon: icons.transfers,
    caption: 'Renewal Transfer In'
  },
  {
    key: 'renewal_transfer_out',
    icon: icons.transfers,
    caption: 'Renewal Transfer Out'
  },
  {
    key: 'leapFrog',
    icon: icons.transfers,
    caption: 'Leap Frog'
  },
  {
    key: 'is_asis',
    icon: icons.asIs,
    caption: 'As-Is Space'
  },
  {
    key: 'is_full_asis',
    icon: icons.fullAsIs,
    caption: 'As-Is Unit'
  },
  // {
  //   key: 'is_carpet',
  //   icon: icons.carpet,
  //   caption: 'Carpet',
  //   match: (item, value) => (value ? item.is_carpet : true),
  //   reset: data =>
  //     _.filter(data, d => d.is_carpet).length === 0 ||
  //     _.filter(data, d => !d.is_carpet).length === 0
  // },
  {
    key: 'partially_occupied',
    icon: icons.partiallyOccupied,
    caption: 'Partially Occupied Unit'
  },
  {
    key: 'fully_vacant',
    icon: icons.fullyVacant,
    caption: 'Vacant Unit'
  },
  {
    key: 'fully_renewed',
    icon: icons.fullyRenewed,
    caption: 'Fully Renewed Unit',
    iconStyle: { width: 40, marginLeft: -2 }
  }
]
