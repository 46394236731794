import React from 'react'
import PropTypes from 'prop-types'
import {
  GoBackReasonSelectItems,
  NOT_COMPLETED,
} from '../../data'

export default function QcRejectionModal({
  unit,
  unitspace,
  onSubmit,
  onClose,
}) {
  const [reason, setReason] = React.useState(NOT_COMPLETED)
  const [notes, setNotes] = React.useState('')
  const [alert, setAlert] = React.useState(false)
  const handleReasonChange = (e) => setReason(e.target.value)
  
  const handleNotesChange = (e) => { 
    setAlert(false)
    setNotes(e.target.value)
  }

  const handleSaveClick = () => { 
    if (notes.length > 0) {
      onSubmit && onSubmit({ reason, notes })
    } else {
      setAlert(true)
    }
  }

  return (
    <div className="unitspace-reject-dialog">
      <button className="delete" aria-label="close" onClick={onClose} />
      <div className="title is-4 is-danger m-b-lg">Not Approved</div>
      <p className="subtitle">
        {`Unit ${unit} - ${unitspace}`}
      </p>
      <div className="reason-select-wrapper">
        <label className="m-b-sm">Reason</label>
        <div className="select m-b-md">
          <select value={reason} onChange={handleReasonChange}>
            {GoBackReasonSelectItems.map((item) => (
              <option key={item.value} value={item.value}>{ item.label }</option>
            ))}
          </select>
        </div>
        <label className="m-b-sm">Notes</label>
        <textarea className={`textarea ${alert && 'is-danger'}`} placeholder="Add notes here" value={notes} onChange={handleNotesChange} />
      </div>
      <button className="button is-primary is-fullwidth" onClick={handleSaveClick}>Save</button>
    </div>
  )
}

QcRejectionModal.propTypes = {
  unit: PropTypes.string,
  unitspace: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
}
