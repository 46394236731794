import React from 'react'
import './index.scss'

const VendorOrderUnitDetail = ({ unitspace, description, status, title = false}) => {
  return <div className={`vendor-order-unit-detail ${title && 'vendor-order-unit-detail-border'}`}>
    <div className={`vendor-order-unit-detail-unit ${title && 'vendor-order-unit-detail-bold'}`}>{unitspace}</div>
    <div className={`vendor-order-unit-detail-description ${title && 'vendor-order-unit-detail-bold'}`}>{description}</div>
    <div className={`vendor-order-unit-detail-status ${title && 'vendor-order-unit-detail-bold'}`}>{status}</div>
  </div>
}

export default VendorOrderUnitDetail
