import { servicesIcons } from '../services-main/services-icons'

export const statsCards = [{
  id: 'not_assigned',
  icon: servicesIcons.notAssigned,
  caption: 'Not Assigned',
  to: 'Assign',
  alsoSelectedWhen: ['notAssignedBlocked'],
},{
  id: 'assigned',
  icon: servicesIcons.assigned,
  caption: 'Assigned',
  to: 'Start',
  alsoSelectedWhen: ['Blocked'],
},{
  id: 'in_progress',
  icon: servicesIcons.inProgress,
  caption: 'In Progress',
  to: 'InProgress',
  alsoSelectedWhen: [],
},{
  id: 'ready_for_qc',
  icon: servicesIcons.readyForQC,
  caption: 'Ready for QC',
  to: 'AssignQC',
  alsoSelectedWhen: [],
},{
  id: 'pending_approval',
  icon: servicesIcons.pendingApproval,
  caption: 'Pending Approval',
  to: 'Approve',
  alsoSelectedWhen: [],
},{
  id: 'approved',
  icon: servicesIcons.approved,
  caption: 'Approved',
  to: 'Approved',
  alsoSelectedWhen: [],
}]
