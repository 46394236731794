import React from 'react'
import ToggleCheckbox from '../../../components/toggle-checkbox'

const ToggleColumns = ({ onChange: parentChangeHook }) => {
  return (
    <div className="toggle-column-input-wrapper">
      <ToggleCheckbox onChange={(state) => {
        parentChangeHook(state)
      }}/>
      <span>Collapse all columns</span>
    </div>
  )
}


export default ToggleColumns
