import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { invoiceAddTax } from 'modules/invoices'
import { closeModal } from 'modules/modal'
import { Modal } from 'ui'
import './index.scss'

const InvoicesAddTaxModal = ({ invoice, ...restProps }) => {
  const dispatch = useDispatch()
  const { addTaxesIsRequesting, addTaxesSuccess } = useSelector(
    state => state.invoices
  )
  const {
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  const [taxAmount, setTaxAmount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!addTaxesIsRequesting && addTaxesSuccess && submitted) {
      dispatch(closeModal())
    }
  }, [addTaxesIsRequesting, addTaxesSuccess])

  return (
    <Modal
      autoClose={false}
      title="Add Tax"
      okText="Add"
      onConfirm={() => {
        setLoading(true)
        setSubmitted(true)
        dispatch(invoiceAddTax(pdbid, context, invoice.po_id, taxAmount))
      }}
      loading={loading}
      {...restProps}>
      <div className="columns">
        <div className="column is-half">
          <div className="is-fullwidth box-padding-2y">
            <label htmlFor="payment" className="is-fullwidth">
              Tax Amount:
            </label>
            <input
              type="text"
              className="input is-fullwidth"
              id="tax-amount"
              placeholder="ex: 500"
              value={taxAmount}
              onChange={e => setTaxAmount(e.target.value)}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InvoicesAddTaxModal
