import Service from '../service'
import LOGOUT from './auth'
import propertyBuildingsMock from '../mocks/property_buildings.mock'

/**
 * Setup properties (buildings) data module
 * @redux
 * @reduxActionScope setup-properties-buildings
 * @module setup-goals
 */

/**
 * Request buildings list for the current property
 * @type {Redux.ActionType}
 */
export const GET_REQUESTED = 'setup-properties-buildings/GET_REQUESTED'
/**
 * Buildings list request failed
 * @type {Redux.ActionType}
 */
export const GET_FAIL = 'setup-properties-buildings/GET_FAIL'
/**
 * Update requested buildings list with successful data from API
 * @type {Redux.ActionType}
 */
export const GET_SUCCESS = 'setup-properties-buildings/GET_SUCCESS'

/**
 * Request property building creation
 * @type {Redux.ActionType}
 */
export const CREATE_REQUESTED = 'setup-properties-buildings/CREATE_REQUESTED'
/**
 * Property building creation failed
 * @type {Redux.ActionType}
 */
export const CREATE_FAIL = 'setup-properties-buildings/CREATE_FAIL'
/**
 * Refresh property details after a successful building creation verified by the API
 * @type {Redux.ActionType}
 */
export const CREATE_SUCCESS = 'setup-properties-buildings/CREATE_SUCCESS'

/**
 * Request property building update
 * @type {Redux.ActionType}
 */
export const UPDATE_REQUESTED = 'setup-properties-buildings/UPDATE_REQUESTED'
/**
 * Property building update failed
 * @type {Redux.ActionType}
 */
export const UPDATE_FAIL = 'setup-properties-buildings/UPDATE_FAIL'
/**
 * Refresh property details after a successful building update verified by the API
 * @type {Redux.ActionType}
 */
export const UPDATE_SUCCESS = 'setup-properties-buildings/UPDATE_SUCCESS'

/**
 * Request property building delete
 * @type {Redux.ActionType}
 */
export const DELETE_REQUESTED = 'setup-properties-buildings/DELETE_REQUESTED'
/**
 * Property building delete failed
 * @type {Redux.ActionType}
 */
export const DELETE_FAIL = 'setup-properties-buildings/DELETE_FAIL'
/**
 * Refresh property details after a successful building delete verified by the API
 * @type {Redux.ActionType}
 */
export const DELETE_SUCCESS = 'setup-properties-buildings/DELETE_SUCCESS'

const DB = ''

const buildingInitialState = {
  uri: DB,
  id: DB,
  num_beds: 0,
  name: DB,
  address: {
    address1: DB,
    address2: DB,
    city: DB,
    state: DB,
    zip: DB,
    country: DB
  },
  phone: DB
}

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: buildingInitialState
}

/**
 * Setup properties (buildings) reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case DELETE_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case GET_FAIL:
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all buildings for a property
 * @redux
 * @reduxActionCreator GET_REQUESTED, GET_SUCCESS, GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id for the requested property
 */
export const setupOnePropertyGetBuildings = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: GET_REQUESTED
    })
    Service.setupOnePropertyGet(pdbid, context, id, 'buildings')
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          const data = propertyBuildingsMock
          dispatch({
            type: GET_SUCCESS,
            data: _buildingsAdapter(data)
          })
        } else {
          _setupOnePropertyGetBuildingsFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyGetBuildingsFail(dispatch)
      })
  }
}

const _setupOnePropertyGetBuildingsFail = dispatch => {
  dispatch({
    type: GET_FAIL
  })
}

const _buildingsAdapter = buildings => {
  let arr = []
  for (let i = 0; i < buildings.length; i += 1) {
    const b = buildings[i]
    arr.push({
      uri: typeof b.building_uri === 'string' ? b.building_uri : DB,
      id: typeof b.building_id === 'string' ? b.building_id : DB,
      name: typeof b.building_name === 'string' ? b.building_name : DB,
      num_units: typeof b.num_units === 'string' ? b.num_units : DB,
      num_beds: typeof b.num_beds === 'string' ? b.num_beds : DB,
      address: {
        address1: typeof b.address1 === 'string' ? b.address1 : DB,
        address2: typeof b.address2 === 'string' ? b.address2 : DB,
        address3: typeof b.address3 === 'string' ? b.address3 : DB,
        city: typeof b.city === 'string' ? b.city : DB,
        state: typeof b.state === 'string' ? b.state : DB,
        zip: typeof b.zip === 'string' ? b.zip : DB,
        country: typeof b.country === 'string' ? b.country : DB
      },
      phone: typeof b.phone === 'string' ? b.phone : DB
    })
  }
  return arr
}

/**
 * Creates a new building for a property
 * @redux
 * @reduxActionCreator CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new building (including the property id)
 */
export const setupOnePropertyBuildingCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: CREATE_REQUESTED
    })
    Service.setupOnePropertyCreate(pdbid, context, '', 'buildings', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CREATE_SUCCESS,
            data: _buildingsAdapter(data)
          })
        } else {
          _setupOnePropertyBuildingCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyBuildingCreateFail(dispatch)
      })
  }
}

const _setupOnePropertyBuildingCreateFail = dispatch => {
  dispatch({
    type: CREATE_FAIL
  })
}

/**
 * Updates an existing building
 * @redux
 * @reduxActionCreator UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing building
 * @param {Object} body - the JSON object for the updated building
 */
export const setupOnePropertyBuildingUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyUpdate(pdbid, context, id, 'buildings', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _buildingsAdapter(data)
          })
        } else {
          _setupOnePropertyBuildingUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyBuildingUpdateFail(dispatch)
      })
  }
}

const _setupOnePropertyBuildingUpdateFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}

/**
 * Deletes an existing building
 * @redux
 * @reduxActionCreator DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing building
 */
export const setupOnePropertyBuildingDelete = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyDelete(pdbid, context, id, 'buildings')
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _buildingsAdapter(data)
          })
        } else {
          _setupOnePropertyBuildingDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyBuildingDeleteFail(dispatch)
      })
  }
}

const _setupOnePropertyBuildingDeleteFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}
