/**
 * Invoices data module
 * @redux
 * @reduxActionScope invoices
 * @module invoices
 */

/**
 * Reset invoices data
 * @type {Redux.ActionType}
 */
export const RESET_INVOICES = 'invoices/RESET_INVOICES'

/**
 * Request invoices list
 * @type {Redux.ActionType}
 */
export const INVOICE_LIST_REQUESTED = 'invoices/INVOICE_LIST_REQUESTED'
/**
 * Invoices list failed
 * @type {Redux.ActionType}
 */
export const INVOICE_LIST_FAIL = 'invoices/INVOICE_LIST_FAIL'
/**
 * Update requested invoices list with successful data from API
 * @type {Redux.ActionType}
 */
export const INVOICE_LIST_SUCCESS = 'invoices/INVOICE_LIST_SUCCESS'

/**
 * Request invoice details
 * @type {Redux.ActionType}
 */
export const INVOICE_DETAILS_REQUESTED = 'invoices/INVOICE_DETAILS_REQUESTED'
/**
 * Invoice details failed
 * @type {Redux.ActionType}
 */
export const INVOICE_DETAILS_FAIL = 'invoices/INVOICE_DETAILS_FAIL'
/**
 * Update requested invoice details with successful data from API
 * @type {Redux.ActionType}
 */
export const INVOICE_DETAILS_SUCCESS = 'invoices/INVOICE_DETAILS_SUCCESS'
/**
 * Resets invoice details
 * @type {Redux.ActionType}
 */
export const INVOICE_DETAILS_RESET = 'invoices/INVOICE_DETAILS_RESET'

/**
 * Request create invoice
 * @type {Redux.ActionType}
 */
export const INVOICE_CREATE_REQUESTED = 'invoices/INVOICE_CREATE_REQUESTED'
/**
 * Invoice creation failed
 * @type {Redux.ActionType}
 */
export const INVOICE_CREATE_FAIL = 'invoices/INVOICE_CREATE_FAIL'
/**
 * Update requested invoice status after successful creation in API
 * @type {Redux.ActionType}
 */
export const INVOICE_CREATE_SUCCESS = 'invoices/INVOICE_CREATE_SUCCESS'

/**
 * Request invoice update
 * @type {Redux.ActionType}
 */
export const INVOICE_UPDATE_REQUESTED = 'invoices/INVOICE_UPDATE_REQUESTED'
/**
 * Invoice update failed
 * @type {Redux.ActionType}
 */
export const INVOICE_UPDATE_FAIL = 'invoices/INVOICE_UPDATE_FAIL'
/**
 * Update requested invoice status after successful update in API
 * @type {Redux.ActionType}
 */
export const INVOICE_UPDATE_SUCCESS = 'invoices/INVOICE_UPDATE_SUCCESS'

/**
 * Request invoice delete
 * @type {Redux.ActionType}
 */
export const INVOICE_DELETE_REQUESTED = 'invoices/INVOICE_DELETE_REQUESTED'
/**
 * Invoice delete failed
 * @type {Redux.ActionType}
 */
export const INVOICE_DELETE_FAIL = 'invoices/INVOICE_DELETE_FAIL'
/**
 * Update requested invoice status after successful deletion in API
 * @type {Redux.ActionType}
 */
export const INVOICE_DELETE_SUCCESS = 'invoices/INVOICE_DELETE_SUCCESS'

/**
 * Request invoice lines generation
 * @type {Redux.ActionType}
 */
export const INVOICE_LINES_GENERATE_REQUESTED =
  'invoices/INVOICE_LINES_GENERATE_REQUESTED'
/**
 * Invoice lines generation failed
 * @type {Redux.ActionType}
 */
export const INVOICE_LINES_GENERATE_FAIL =
  'invoices/INVOICE_LINES_GENERATE_FAIL'
/**
 * Update requested invoice status after successful lines generation in API
 * @type {Redux.ActionType}
 */
export const INVOICE_LINES_GENERATE_SUCCESS =
  'invoices/INVOICE_LINES_GENERATE_SUCCESS'

/**
 * Request invoice line create
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_CREATE_REQUESTED =
  'invoices/INVOICE_LINE_CREATE_REQUESTED'
/**
 * Invoice line create failed
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_CREATE_FAIL = 'invoices/INVOICE_LINE_CREATE_FAIL'
/**
 * Update requested invoice status after successful line creation in API
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_CREATE_SUCCESS =
  'invoices/INVOICE_LINE_CREATE_SUCCESS'

/**
 * Request invoice line update
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_UPDATE_REQUESTED =
  'invoices/INVOICE_LINE_UPDATE_REQUESTED'
/**
 * Invoice line update failed
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_UPDATE_FAIL = 'invoices/INVOICE_LINE_UPDATE_FAIL'
/**
 * Update requested invoice status after successful line update in API
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_UPDATE_SUCCESS =
  'invoices/INVOICE_LINE_UPDATE_SUCCESS'

/**
 * Request invoice line delete
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_DELETE_REQUESTED =
  'invoices/INVOICE_LINE_DELETE_REQUESTED'
/**
 * Invoice line delete failed
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_DELETE_FAIL = 'invoices/INVOICE_LINE_DELETE_FAIL'
/**
 * Update requested invoice status after successful line deletion in API
 * @type {Redux.ActionType}
 */
export const INVOICE_LINE_DELETE_SUCCESS =
  'invoices/INVOICE_LINE_DELETE_SUCCESS'
/**
 * Update requested invoice status succcessful SENT to Email
 * @type {Redux.ActionType}
 */
export const INVOICE_SENT = 'invoices/INVOICE_SENT'

/**
 * Update requested invoice status APPROVED succcessful
 * @type {Redux.ActionType}
 */
export const INVOICE_APPROVE = 'invoices/INVOICE_APPROVE'

/**
 * Update tax value for the current invoice
 * @type {Redux.ActionType}
 */
export const INVOICE_ADD_TAX = 'invoices/INVOICE_ADD_TAX'
/**
 * Request an update for the current invoice tax value
 * @type {Redux.ActionType}
 */
export const INVOICE_ADD_TAX_REQUESTED = 'invoices/INVOICE_ADD_TAX_REQUESTED'
/**
 * Update for the current invoice tax value has been successful
 * @type {Redux.ActionType}
 */
export const INVOICE_ADD_TAX_SUCCESS = 'invoices/INVOICE_ADD_TAX_SUCCESS'

/**
 * Update requested invoice status PAYED succcessful
 * @type {Redux.ActionType}
 */
export const INVOICE_PAYED = 'invoices/INVOICE_PAYED'

/**
 * Update requested - invoice status
 * @type {Redux.ActionType}
 */
export const INVOICE_CHANGE_STATUS_REQUESTED =
  'invoices/INVOICE_CHANGE_STATUS_REQUESTED'
/**

* Update requested - invoice status 
* @type {Redux.ActionType}
*/
export const INVOICE_CHANGE_STATUS_SUCCESS =
  'invoices/INVOICE_CHANGE_STATUS_SUCCESS'

export const INVOICE_START_EDITING = 'invoices/INVOICE_START_EDITING'

export const INVOICE_FINISH_EDITING = 'invoices/INVOICE_FINISH_EDITING'

export const INVOICE_BACK_TO_DRAFT = 'invoices/INVOICE_BACK_TO_DRAFT'

export const INVOICE_AMOUNT_PAID_REQUESTED =
  'invoices/INVOICE_AMOUNT_PAID_REQUESTED'

export const INVOICE_AMOUNT_PAID_SUCCESS =
  'invoices/INVOICE_AMOUNT_PAID_SUCCESS'
