import React, { useState, useEffect, useRef, useMemo } from 'react'
import { headerData } from './header'
import BigLoading from '../../components/big-loading'
import { usePermissionGate } from '../..//helpers/hooks'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { exportFile, closeFilled } from 'ui/icons'
import UserTable from './components/UserTable'
import Paginator from '../../components/react-table-pagination'
import BlockUserModal from './components/BlockUserModal'
import DeleteUserModal from '../view-user/components/DeleteUserModal'
import UserListFilterModal from '../user-list-filter-modal'
import UserListSortModal from '../user-list-sort-modal'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'

// Table styles
const Styles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  // display: flex;

  input {
    margin-top: 8px !important;
    width: 100%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
  }
  .user-table {
    // display: inline-block;
    border-spacing: 0;
    position: relative;

    select {
      width: 100%;
      margin-top: 8px !important;
    }

    .tr {
      position: relative;

      :first-child {
        .th {
          display: none !important;
          border-top: 1px solid #e5e9f2;
        }
      }
    }
    .th {
      text-align: center;
      padding: 0px;
      :first-child {
        // border: 1px solid #e5e9f2;
      }
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
  }
`

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
  toggleAllRowsSelected,
  selectedFlatRows
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  let options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])
  // To remove empty values and - from filter
  options = (options || []).filter(e => e && e !== '-')

  return (
    <select
      value={filterValue || 'All'}
      onChange={e => {
        if (e.target.value === 'all') {
          setFilter(undefined)
        } else {
          setFilter(e.target.value || '')
        }
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="all">All</option>
      {(options || []).map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

const UserList = props => {
  const {
    user,
    isLoading,
    history,
    openModal,
    setModalContent,
    closeModal,
    openTooltip,
    setTooltipContent,
    closeTooltip,
    tooltip,
    setupUsers,
    setupOneUserDelete,
    setupOneUserBlock,
    context,
    allUsersGet,
    updateUserAccountStatus,
    userAccountDelete,
    allUsersFilteredResultGet,
    getUserExcelURL
  } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [selectedPageRowData, setSelectedPageRowData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(30)
  const [forceReload, setForceReload] = useState(false)
  const [filterAppliedQuery, setFilterAppliedQuery] = useState('')
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [sorting, setSorting] = useState([])
  const [sortAppliedQuery, setSortAppliedQuery] = useState('')
  const [sortInfo, setSortInfo] = useState({
    name: '',
    type: ''
  })

  const { pdbid } = user?.user_metadata

  const tableRef = useRef()
  const previousValues = useRef({
    filterAppliedQuery: '',
    sortAppliedQuery: ''
  })

  const { hasPermission } = usePermissionGate('add-member')

  const { allUsers } = setupUsers || {}
  const { isRequesting, data, hasMadeInitialRequest, totalUserCount } =
    allUsers || {}

  useEffect(() => {
    const {
      filterAppliedQuery: prevFilter,
      sortAppliedQuery: prevSort
    } = previousValues.current

    if (prevFilter !== filterAppliedQuery || prevSort !== sortAppliedQuery) {
      setSelectedPage(1)
    }

    // Update the previous values
    previousValues.current = { filterAppliedQuery, sortAppliedQuery }
  }, [filterAppliedQuery, sortAppliedQuery])

  function updateUserIds(url, newUserIds) {
    // Ensure newUserIds is a comma-separated string
    if (!Array.isArray(newUserIds)) {
      throw new Error('newUserIds should be an array of strings.')
    }
    const newIds = newUserIds.join(',')

    // Check if f_user_id exists in the URL
    const regex = /([&?])f_user_id=([^&]*)/
    if (regex.test(url)) {
      // Replace the existing f_user_id with the new list
      return url.replace(regex, `1f_user_id=${newIds}`)
    } else {
      // Add f_user_id to the URL
      const separator = url.includes('?') ? '?' : '&'
      return `${url}${separator}f_user_id=${newIds}`
    }
  }

  const exportCSV = () => {
    let defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original).map(row => row.user_id)
      : []
    setIsExportLoading(true)
    let query = ''
    if (filterAppliedQuery !== '' || sortAppliedQuery !== '') {
      if (filterAppliedQuery !== '') {
        query += filterAppliedQuery
      }
      if (sortAppliedQuery !== '') {
        query += sortAppliedQuery
      }
    }
    if (defaultData.length) {
      query = updateUserIds(query, defaultData)
    }

    getUserExcelURL({
      pdbid,
      query: query !== '' ? `?${query}` : '',
      onSuccess: () => {
        setIsExportLoading(false)
      },
      onError: () => {
        setIsExportLoading(false)
        setErrorMessage('Something went wrong. Please try again later!!!')
      }
    })
  }

  const onHandleForceReload = status => {
    setForceReload(status)
  }

  const onSuccess = () => {
    setErrorMessage('')
  }
  const onError = () => {
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  useEffect(() => {
    if (forceReload) {
      allUsersGet(pdbid, selectedPage, rowsPerPage, onSuccess, onError)
      setForceReload(false)
      setFilterAppliedQuery('')
      setSortAppliedQuery('')
      setSortInfo('')
      setSelectedPage(1)
    }
  }, [forceReload, onHandleForceReload])

  const handleShowBlockUserModal = (user, isMultiple = false) => {
    setModalContent(() => (
      <BlockUserModal
        closeModal={closeModal}
        updateUserAccountStatus={updateUserAccountStatus}
        user={user}
        isMultiple={isMultiple}
        onHandleForceReload={onHandleForceReload}
      />
    ))
    openModal({ width: '480px' })
  }

  const handleDeleteUserModal = currentUser => {
    const { user_id } = currentUser || {}
    setModalContent(() => (
      <DeleteUserModal
        closeModal={closeModal}
        user={user}
        history={history}
        userId={user_id}
        onHandleForceReload={onHandleForceReload}
        userAccountDelete={userAccountDelete}
      />
    ))
    openModal({ width: '480px' })
  }

  const tableColumns = headerData({
    SelectColumnFilter,
    user,
    onShowBlockModal: handleShowBlockUserModal,
    onshowDeleteModal: handleDeleteUserModal,
    sortInfo
  })

  const columns = useMemo(() => tableColumns, [sortInfo])

  const onResetFilter = () => tableRef.current?.clearFilter([])

  const checkIsFilterApplied = useMemo(() => {
    const checkSingleFilter = (isFilterApplied || []).filter(
      o => (o.value || []).length !== 0
    )
    return checkSingleFilter
  }, [isFilterApplied])

  useEffect(() => {
    if (filterAppliedQuery !== '' || sortAppliedQuery !== '') {
      let query = ''
      if (filterAppliedQuery !== '') {
        query += filterAppliedQuery
      }
      if (sortAppliedQuery !== '') {
        query += sortAppliedQuery
      }
      allUsersFilteredResultGet(
        pdbid,
        selectedPage,
        rowsPerPage,
        query,
        onSuccess,
        onError
      )
      return
    }
    allUsersGet(pdbid, selectedPage, rowsPerPage, onSuccess, onError)
  }, [rowsPerPage, selectedPage, filterAppliedQuery, sortAppliedQuery])

  const isAllBlockUser = useMemo(() => {
    const defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : []
    return (defaultData || []).every(data => data.blocked === 'Active')
  }, [selectedRows])

  const isAllActiveUser = useMemo(() => {
    const defaultData = (selectedRows || []).length
      ? (selectedRows || []).map(row => row?.original)
      : []
    return (defaultData || []).every(data => data.blocked === 'Blocked')
  }, [selectedRows])

  const processQueryString = queryString => {
    if (!queryString) return {}

    const sanitizedQuery = queryString.startsWith('&')
      ? queryString?.slice(1)
      : queryString

    return sanitizedQuery.split('&')?.reduce((acc, param) => {
      const [key, value] = param?.split('=')
      acc[decodeURIComponent(key)] = value?.split(',').map(val => ({
        key: decodeURIComponent(val),
        value: decodeURIComponent(val)
      }))
      return acc
    }, {})
  }

  const onHandleUserFilterQuery = query => {
    if (query !== '') {
      setFilterAppliedQuery(query)
    }
  }

  const onHandleUserSortQuery = query => {
    if (query !== '') {
      setSortAppliedQuery(query)
    }
  }

  const onHandleUserSortInfo = info => {
    if (info?.type !== '') {
      setSortInfo(info)
    }
  }

  const onUserFilterHandler = () => {
    const AppliedFilteredValues = processQueryString(filterAppliedQuery)
    console.log(AppliedFilteredValues)
    setModalContent(() => (
      <UserListFilterModal
        onHandleUserFilterQuery={onHandleUserFilterQuery}
        AppliedFilteredValues={AppliedFilteredValues}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '700px',
      maxWidth: '94%',
      additionalClasses: 'user-filter-modal'
    })
  }

  const onUserSortHandler = () => {
    let AppliedSortingValues = []
    if (sortInfo.name !== '') {
      AppliedSortingValues = processQueryString(
        `&name=${sortInfo.name}&type=${sortInfo.type}`
      )
    }

    setModalContent(() => (
      <UserListSortModal
        onHandleUserSortQuery={onHandleUserSortQuery}
        onHandleUserSortInfo={onHandleUserSortInfo}
        AppliedSortingValues={AppliedSortingValues}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '700px',
      maxWidth: '94%',
      additionalClasses: 'user-filter-modal'
    })
  }

  const defaultFilters = {
    f_user_id: 'User ID',
    f_name: 'Name',
    f_email: 'Email',
    f_role_name: 'Role',
    f_property_name: 'Property',
    f_project_name: 'Project',
    f_status: 'Status',
    f_last_login_from: 'Last Login'
  }

  const getMatchingValues = (filterObject, filters) => {
    const filterKeys = Object.keys(filterObject)

    const result = filterKeys
      .map(key => filters[key])
      .filter(value => value !== undefined)

    return result
  }

  const filteredValue = () => {
    const AppliedFilteredValues = processQueryString(filterAppliedQuery)
    return getMatchingValues(AppliedFilteredValues, defaultFilters)
  }

  return (
    <div className="users-list-container ">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">Users</h2>
          </div>
          <div className="action-button">
            <div className="users-action-buttons">
              <button
                className="button  is-success"
                disabled={!hasPermission}
                onClick={e => {
                  e.preventDefault()
                  history.push('/setup/users/new')
                }}>
                Add User
              </button>
              {(selectedRows || []).length && hasPermission ? (
                <>
                  <button
                    data-tip
                    data-for="blockuser"
                    className={`button is-secondary ${
                      !isAllBlockUser ? 'button-disabled' : ''
                    }`}
                    onClick={e => {
                      if (isAllBlockUser) {
                        const defaultData = (selectedRows || []).length
                          ? (selectedRows || []).map(row => row?.original)
                          : []
                        const arrayUniqueByKey = [
                          ...new Map(
                            (defaultData || []).map(item => [
                              item['user_id'],
                              item
                            ])
                          ).values()
                        ]
                        handleShowBlockUserModal(arrayUniqueByKey, true)
                      }
                    }}>
                    Block
                  </button>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="blockuser"
                    place="top"
                    disable={isAllBlockUser}
                    effect="solid">
                    <div>
                      <p>
                        {!isAllActiveUser && !isAllBlockUser
                          ? 'Please select users belonging to the same status to use the block/unblock feature.'
                          : 'Please select users belonging to the active status'}
                      </p>
                    </div>
                  </ReactTooltip>
                  <button
                    data-tip
                    data-for="activeuser"
                    className={`button is-secondary ${
                      !isAllActiveUser ? 'button-disabled' : ''
                    }`}
                    onClick={e => {
                      if (isAllActiveUser) {
                        const defaultData = (selectedRows || []).length
                          ? (selectedRows || []).map(row => row?.original)
                          : []
                        const arrayUniqueByKey = [
                          ...new Map(
                            (defaultData || []).map(item => [
                              item['user_id'],
                              item
                            ])
                          ).values()
                        ]
                        handleShowBlockUserModal(arrayUniqueByKey, true)
                      }
                    }}>
                    Unblock
                  </button>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="activeuser"
                    place="top"
                    disable={isAllActiveUser}
                    effect="solid">
                    <div>
                      <p>
                        {!isAllActiveUser && !isAllBlockUser
                          ? 'Please select users belonging to the same status to use the block/unblock feature.'
                          : 'Please select users belonging to the blocked status'}
                      </p>
                    </div>
                  </ReactTooltip>
                </>
              ) : null}

              {filterAppliedQuery !== '' && (
                <button
                  onClick={() => {
                    setFilterAppliedQuery('')
                    setSelectedPage(1)
                  }}
                  className="button is-secondary">
                  Clear All Filters
                </button>
              )}
              {sortAppliedQuery !== '' && (
                <button
                  onClick={() => {
                    setSortAppliedQuery('')
                    setSortInfo('')
                    setSelectedPage(1)
                  }}
                  className="button is-secondary">
                  Clear All Sorting
                </button>
              )}
            </div>
            <div className="button-container">
              <button
                className="export"
                onClick={e => {
                  e.preventDefault()
                  onUserFilterHandler()
                }}>
                <span className="m-r-xs">+</span>
                Filter
              </button>
              <button
                className="export"
                onClick={e => {
                  e.preventDefault()
                  onUserSortHandler()
                }}>
                <span className="m-r-xs">+</span>
                Sort
              </button>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
                {isExportLoading && (
                  <FontAwesomeIcon
                    color="#3DB3E2"
                    icon={faSpinner}
                    spin
                    className="m-l-sm"
                  />
                )}
              </button>
            </div>
          </div>
          {filterAppliedQuery !== '' && (
            <>
              <br />
              <div
                style={{ display: 'flex', gap: '2px', justifyContent: 'end' }}>
                <p>Applied Filters:</p>
                {filteredValue()?.map((item, index) => (
                  <div
                    className={`column is-narrow p-l-xs p-t-none ${
                      index === filteredValue()?.length - 1
                        ? 'p-r-none'
                        : 'p-r-none'
                    }`}
                    key={`basic-${index}`}>
                    <div className="tags has-addons">
                      <div className="tag">{item}</div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {errorMessage !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
                <button onClick={() => setErrorMessage('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        {hasMadeInitialRequest && !isRequesting ? (
          <Styles>
            <UserTable
              ref={tableRef}
              columns={columns}
              data={data}
              onRowSelectStateChange={setSelectedRows}
              setIsFilterApplied={setIsFilterApplied}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
              setSelectedPageRowData={setSelectedPageRowData}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              sorting={sorting}
              setSorting={setSorting}
            />
          </Styles>
        ) : (
          <BigLoading />
        )}
        <div className="table-pagination">
          {hasMadeInitialRequest && !isRequesting ? (
            <Paginator
              previousPage={() => {
                setSelectedPage(selectedPage - 1)
                if (tableRef?.current?.previousPage) {
                  tableRef.current.previousPage()
                }
              }}
              nextPage={() => {
                setSelectedPage(selectedPage + 1)
                if (tableRef?.current?.nextPage) {
                  tableRef.current.nextPage()
                }
              }}
              rowsPerPage={rowsPerPage}
              rowCount={totalUserCount}
              currentPage={selectedPage}
              onChangePage={page => {
                setSelectedPage(page)
                if (tableRef?.current?.gotoPage) {
                  tableRef.current.gotoPage(page - 1)
                }
              }}
              setRowsPerPage={pageSize => {
                setSelectedPage(1)
                const { setPageSize } = tableRef.current
                setPageSize(pageSize)
              }}
              limit={[30, 60, 90]}
              addAllLimit={false}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default UserList
