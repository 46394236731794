import styled from 'styled-components'

export const EditionTableStyles = styled.div`
  overflow: auto;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  input {
    margin-top: 8px !important;
    width: 98.4%;
    background: #ffffff;
    border: 1px solid #e5e9f2;
    margin: 0px auto;
    outline: none;
    font-size: 10px;
    padding-left: 7px;
  }
  .search-filter {
    padding-left: 7px;
  }
  .action-input {
    margin-top: 9px !important;
    width: 97%;
    margin-left: 0px;
  }
  select {
    width: 100%;
  }
  .status-select {
    select {
      &:active,
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .edition-table {
    display: inline-block;
    border-spacing: 0;
    position: relative;
    .tr {
      position: relative;
    }
    .th {
      text-align: center;
      padding: 0px;
    }
    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
      // :last-child {
      //   border-right: 1px solid #e5e9f2;
      // }
    }
  }
`
