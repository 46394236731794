import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

/**
 * Base component with loading and error status
 *
 * @component
 * @example
 * const content = (
 *    <div className="test">
 *      Component loaded
 *    </div>
 * )
 * return (
 *   <ComponentWithStatus loading={false} error={false}>
 *      {content}
 *   </ComponentWithStatus>
 * )
 */
const ComponentWithStatus = ({ loading = false, error = false, children }) => {
  if (loading)
    return (
      <div className="p-sm">
        <FontAwesomeIcon icon={faSpinner} size="lg" spin />
      </div>
    )

  if (error)
    return (
      <div className="p-sm" style={{ color: '#e94337' }}>
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
        <span className="p-l-sm">
          An error ocurred while loading data from the server
        </span>
      </div>
    )

  return children
}

ComponentWithStatus.propTypes = {
  /**
   * Determines if the content is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if the loading process triggered an error
   */
  error: PropTypes.bool
}

export default ComponentWithStatus
