import React, { useState, useEffect, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'

const TurnboardHeader = ({
  title,
  backgroundColor,
  onToggle,
  isAllColumnCollapsed,
  isTurnboardOverview,
  hiddenColumns,
  options
}) => {
  const [isCollapsed, setCollapsed] = useState(() => {
    if (hiddenColumns && hiddenColumns.length) {
      const { column } = options || {}
      const { columns } = column || {}

      const children = (columns || []).map(({ id }) => id)

      return hiddenColumns.some(column => (children || []).includes(column))
    }

    return isAllColumnCollapsed
  })

  const handleCollapse = e => {
    e.preventDefault()
    setCollapsed(!isCollapsed)
    onToggle(!isCollapsed)
  }

  return (
    <div
      className={`header-item ${isCollapsed ? 'collapsed' : ''}`}
      style={{ backgroundColor }}>
      <>{title === 'Unit' ? title : <>{!isCollapsed && title}</>}</>
      {title !== 'Unit' && (
        <span title={title} onClick={handleCollapse}>
          {isCollapsed ? (
            <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
          )}
        </span>
      )}
    </div>
  )
}

export default TurnboardHeader
