/**
 * Shared modal module
 * @redux
 * @reduxActionScope modal
 * @module modal
 */

/**
 * Sets modal status to open
 * @type {Redux.ActionType}
 */
export const MODAL_OPEN = 'modal/MODAL_OPEN'
/**
 * Sets modal status to closed
 * @type {Redux.ActionType}
 */
export const MODAL_CLOSE = 'modal/MODAL_CLOSE'
/**
 * Changes the current content for the modal
 * @type {Redux.ActionType}
 */
export const MODAL_SET_CONTENT = 'modal/SET_CONTENT'

const initialState = {
  isActive: false,
  content: null,
  width: '',
  maxWidth: undefined,
  overflow: undefined,
  backgroundColor: undefined,
  additionalClasses: ''
}

/**
 * General shared modal reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_OPEN:
      return {
        ...state,
        isActive: true,
        width: action.width,
        maxWidth: action.maxWidth,
        overflow: action.overflow,
        backgroundColor: action.backgroundColor,
        additionalClasses: action.additionalClasses,
        additionalBodyClasses: action.additionalBodyClasses
      }
    case MODAL_CLOSE:
      return {
        ...state,
        isActive: false
      }
    case MODAL_SET_CONTENT:
      return {
        ...state,
        content: action.content
      }
    default:
      return state
  }
}

/**
 * Opens the shared modal
 * @redux
 * @reduxActionCreator MODAL_OPEN
 * @param {string} width - the desired width for the modal
 * @param {string} maxWidth - the maximum width value for the modal
 * @param {string} overflow - the overflow value for the modal
 * @param {string} backgroundColor - the CSS background color for the modal
 */
// Passing 'visible' as overflow will fix an issue where DatePicker gets cutted off
export const openModal = ({
  width = '',
  maxWidth,
  overflow,
  backgroundColor,
  additionalClasses,
  additionalBodyClasses
} = {}) => {
  return dispatch => {
    dispatch({
      type: MODAL_OPEN,
      width,
      maxWidth,
      overflow,
      backgroundColor,
      additionalClasses,
      additionalBodyClasses
    })
  }
}

/**
 * Closes the shared modal
 * @redux
 * @reduxActionCreator MODAL_CLOSE
 */
export const closeModal = () => {
  return dispatch => {
    dispatch({
      type: MODAL_CLOSE
    })
  }
}

/**
 * Changes the content for the shared modal
 * @redux
 * @reduxActionCreator MODAL_OPEN
 * @param {Element} content - the new content for the modal
 */
export const setModalContent = content => {
  return dispatch => {
    dispatch({
      type: MODAL_SET_CONTENT,
      content
    })
  }
}
