import _ from 'lodash'
import { servicesIcons } from './services-icons'

export const customFilters = [
  {
    key: 'by_transfer_status',
    icon: servicesIcons.transfers,
    caption: 'Transfers',
    match: (item, value) => (value ? item.is_transfer : true),
    reset: data =>
      _.filter(data, d => d.is_transfer).length === 0 ||
      _.filter(data, d => !d.is_transfer).length === 0
  },
  {
    key: 'by_goback',
    icon: servicesIcons.goBack,
    caption: 'Go-Back',
    match: (item, value) => (value ? item.is_goback : true),
    reset: data =>
      _.filter(data, d => d.is_goback).length === 0 ||
      _.filter(data, d => !d.is_goback).length === 0
  },
  {
    key: 'by_asis',
    icon: servicesIcons.asIs,
    caption: 'As-Is',
    match: (item, value) => (value ? item.is_asis : true),
    reset: data =>
      _.filter(data, d => d.is_asis).length === 0 ||
      _.filter(data, d => !d.is_asis).length === 0
  },
  {
    key: 'by_priority',
    icon: servicesIcons.priority,
    caption: 'Priority',
    match: (item, value) => (value ? item.is_priority : true),
    reset: data =>
      _.filter(data, d => d.is_priority).length === 0 ||
      _.filter(data, d => !d.is_priority).length === 0
  },
  {
    key: 'by_carpet',
    icon: servicesIcons.carpet,
    caption: 'Carpet',
    match: (item, value) => (value ? item.is_carpet : true),
    reset: data =>
      _.filter(data, d => d.is_carpet).length === 0 ||
      _.filter(data, d => !d.is_carpet).length === 0
  },
  {
    key: 'by_occupied',
    icon: servicesIcons.partiallyOccupied,
    caption: 'Partially Occupied',
    match: (item, value) => {
      const unitOccupied = _.filter(Object.keys(item), currentKey => {
        return currentKey && item[currentKey] && (item[currentKey].service?.toLowerCase() === 'renewed' || item[currentKey].service?.toLowerCase() === 'occupied')
      })

      return unitOccupied.length
    },
  },
  {
    key: 'by_vacant',
    icon: servicesIcons.fullyVacant,
    caption: 'Vacant',
    match: (item, value) => {
      const unitOccupied = _.filter(Object.keys(item), currentKey => {
        return currentKey && item[currentKey] && (item[currentKey].service?.toLowerCase() === 'renewed' || item[currentKey].service?.toLowerCase() === 'occupied')
      })

      return unitOccupied.length === 0
    },
  },
]
