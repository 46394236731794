import React, { useMemo } from 'react'
import MultiSelectDropdown from '../../../components/multi-select-dropdown'

const SearchDropdownFilterMultiple = props => {
  const id = props?.column?.id

  const options = useMemo(() => {
    const deduped = new Set()
    const options = []
    const commaSeperatedColumns = id === 'simplifiedServices'

    props.data.forEach(row => {
      if (commaSeperatedColumns) {
        const vals = row[id].split(',').map(x => x.trim())
        vals.forEach(val => {
          if (val && !deduped.has(val)) {
            deduped.add(val)
            options.push({ [id]: val })
          }
        })
      } else {
        const val = row[id]
        if (val && !deduped.has(val)) {
          deduped.add(val)
          options.push({ [id]: val })
        }
      }
    })

    return options.sort((a, b) => {
      const x = a[id].toLowerCase()
      const y = b[id].toLowerCase()
      if (x > y) return 1
      if (x < y) return -1
      return 0
    })
  }, [props.rows])

  return (
    <MultiSelectDropdown
      displayKey={id}
      value={props.column.filterValue || ''}
      defaultValues={options}
      onChange={newValue => props.column.setFilter(newValue)}
      isMulti
      isClearable
      placeholder="Search"
      noOptionsMessage="No Options Available"
      menuPortalTarget={document.body}
      customStyles={{
        input: styles => ({
          ...styles,
          height: 23,
          margin: 0,
          marginTop: '-10px !important',
          padding: '1px 2px',
          fontSize: 10,
          lineHeight: 1,
          fontWeight: '400',
          cursor: 'text'
        }),
        placeholder: styles => ({
          ...styles,
          margin: 0,
          padding: '1px 2px',
          fontSize: 10,
          lineHeight: 1,
          fontWeight: '400',
          textAlign: 'left'
        }),
        control: (styles, state) => ({
          ...styles,
          minHeight: 23,
          maxHeight: 69,
          overflow: 'auto',
          marginTop: 8,
          border: '1px solid #e5e9f2',
          borderRadius: 0,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #e5e9f2'
          },
          backgroundColor: state.isDisabled
            ? 'ghostwhite'
            : styles.backgroundColor,
          '&::-webkit-scrollbar': {
            width: '4px'
          },
          ' &::-webkit-scrollbar-track': {
            background: 'transparent'
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '5px'
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555'
          }
        }),
        container: styles => ({
          ...styles,
          pointerEvents: 'all'
        }),
        valueContainer: styles => ({
          ...styles,
          minHeight: 20,
          width: '100%',
          padding: 0,
          position: 'relative'
        }),
        multiValue: styles => ({
          ...styles,
          height: 16
        }),
        multiValueLabel: styles => ({
          ...styles,
          padding: 3,
          paddingLeft: 3,
          fontSize: 10,
          lineHeight: 1
        }),
        multiValueRemove: styles => ({
          ...styles,
          padding: 0
        }),
        option: styles => ({
          ...styles,
          padding: 4,
          fontSize: 10
        }),
        indicatorsContainer: styles => ({
          ...styles,
          width: 20,
          height: 20
        }),
        clearIndicator: styles => ({
          ...styles,
          width: 18,
          padding: 0,
          paddingLeft: 4
        })
      }}
    />
  )
}

export default SearchDropdownFilterMultiple
