import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VendorHeader from '../vendor-header'
import BigLoading from '../../components/big-loading'
import VendorMessageHeader from '../../components/vendor-message-header'
import MobileEmptyState from '../../components/mobile-empty-state'
import { getApproved } from '../../modules/vendor'
import { toggleSidebar } from '../../modules/sidebar'
import { noPendingWork } from '../../ui/icons'
import { ServiceColors } from '../../data'
import './index.scss'

const VendorApproved = ({ vendor, user, context, getApproved, toggleSidebar }) => {

  const { approvedJobs, isRequesting } = vendor

  const { project, property } = context
  const { pdbid, vendorid } = user.user_metadata

  useEffect(() => {
    if (project && property && pdbid && vendorid && !isRequesting) {
      getApproved(pdbid, { project, property }, vendorid)
    }
  }, [context, user])

  const jobsByServiceType = approvedJobs.reduce((acc, job) => {
    if (job) {
      const service = job.service || 'Unknown'
      return {
        ...acc,
        [service]: acc[service] ? [...acc[service], job] : [job]
      }
    }
    return acc
  }, {})

  return (
    <div className="vendor-approved is-fullheight">
      <VendorHeader
        title="Approved"
        toggleSidebar={toggleSidebar}
        refreshAction={() => getApproved(pdbid, { project, property }, vendorid)} />
      
      <div className="vendor-approved-content">
        {isRequesting ? (
          <BigLoading style={{ marginTop: 0 }} />
        ) : (
          <div>
            {approvedJobs.length ? (
              <>
                <VendorMessageHeader
                  title="These units are completed."
                  subtitle="Way to go! And thanks for your hard work!"
                />

                {Object.keys(jobsByServiceType).map(serviceType => (
                  <table className="table is-fullwidth vendor-approved-table" key={serviceType}>
                    <thead>
                      <tr>
                        <th className="p-b-md">
                          <span
                            className="tag is-medium is-uppercase is-dark has-text-weight-medium"
                            style={{backgroundColor: ServiceColors[serviceType] || 'grey'}}
                          >{serviceType}</span>
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobsByServiceType[serviceType].length ? jobsByServiceType[serviceType].map(job => (
                        <tr key={job.workorder}>
                          <td>{`Unit ${job.unit}`}</td>
                          <td align="right">{job.approvedAt}</td>
                        </tr>
                      )) : (
                        <tr>
                          <td>No {serviceType}(s) have been approved yet.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                ))}
              </>
            ) : (
              <div className="vendor-approved-empty">
                <MobileEmptyState 
                  image={noPendingWork}
                  title="No approvals…yet!"
                  subtitle="All approved work orders will be listed here." />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

VendorApproved.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  vendor: PropTypes.object,
  getPendings: PropTypes.func,
  toggleSidebar: PropTypes.func,
}

const mapStateToProps = ({ vendor, user, context }) => ({
  vendor,
  user,
  context
})

const mapDispatchToProps = {
  getApproved,
  toggleSidebar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorApproved)

