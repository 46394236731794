export const filteredProjectList = (selectedProperties, allProperties) => {
  const propertyIds = (selectedProperties || []).map(
    ({ property_id }) => property_id
  )
  const selectedProjects = (allProperties || []).filter(({ property_id }) =>
    propertyIds.includes(property_id)
  )

  return selectedProjects || []
}

export const filteredPropertiesList = (selectedProjects, allProperties) => {
  const projectIds = (selectedProjects || []).map(
    ({ property_id }) => property_id
  )
  const selectedProperties = (allProperties || []).filter(({ property_id }) =>
    projectIds.includes(property_id)
  )
  return selectedProperties || []
}

const destructurePermissionObjectForCreation = (
  property_id,
  permission_rule_id,
  role_id,
  internal_permission_rule_id,
  login_access,
  status,
  action
) => {
  return {
    property_id,
    permission_rule_id,
    role_id,
    internal_permission_rule_id,
    login_access: login_access ? null : 'false',
    action,
    status
  }
}

export const destructDataForUserCreationPayload = (
  permissionData,
  allProperties
) => {
  let data = []
  if (permissionData?.length) {
    ;(permissionData || []).map(selectedPermissionData => {
      const {
        properties,
        role,
        login_access,
        internal_permission_rule_id,
        status,
        action
      } = selectedPermissionData || {}
      if (properties?.length > 1) {
        ;(properties || []).map(property => {
          console.log({ property })
          data.push(
            destructurePermissionObjectForCreation(
              // [multiple_property][0]?.property_id,
              property?.property_id,
              selectedPermissionData[(property?.property_id)],
              role[0]?.role_id,
              internal_permission_rule_id,
              login_access,
              status,
              action
            )
          )
          return property
        })
      } else {
        data.push(
          destructurePermissionObjectForCreation(
            properties[0]?.property_id,
            selectedPermissionData[(properties[0]?.property_id)],
            role[0]?.role_id,
            internal_permission_rule_id,
            login_access,
            status,
            action
          )
        )
      }
      return selectedPermissionData
    })
  }
  return data || []
}

export const findAndGetPermission = (selectedPermissions, id) => {
  return (selectedPermissions || []).find(
    perm => perm?.internal_permission_rule_id === id
  )
}

export const getDistinctPropertiesOrProjects = (
  propertiesOrProjects = [],
  key
) => {
  const history = {}
  const distinctPropertiesOrProjects = []

  for (let propertyOrProject of propertiesOrProjects) {
    if (!history[propertyOrProject[key]]) {
      distinctPropertiesOrProjects.push(propertyOrProject)
      history[propertyOrProject[key]] = true
    }
  }

  return distinctPropertiesOrProjects
}
