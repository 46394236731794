import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import KeyValueDisplay from 'components/key-value-display'
import PricingUnitModal from 'parents/setup-vendor-manager-modal-pricing-unit'
import { Button } from 'ui'
import cn from 'classnames'
import { SERVICE_ICONS } from './constants'
import { addButton, edit as editIcon, addButtonBlack } from 'icons'
import RestrictedElement from 'containers/restricted-element'
import { useModals } from 'hooks'
import { useSelector } from 'react-redux'
import { currencyFormatter } from '../../utils/index'
import PermissionGate from '../permission-gate'
import { close } from 'ui/icons'
import ConfirmationModal from 'components/confirmation-modal'
import { usePermissionGate } from '../../helpers/hooks'

const SetupVendorService = ({
  service,
  floorplan,
  falttenFloorplans,
  openServicesModal,
  showTooltip,
  showDeleteVendorModal,
  setupOneVendorGet,
  vendorId,
  history,
  vendorName,
  deleteVendorServices,
  setAlert,
  user,
  context,
  onHandleForceUpdateVendor
}) => {
  const [showModal, dismissModal] = useModals()
  const { hasPermission } = usePermissionGate('add-vendor')

  const allServices = useSelector(
    state => state.setupVendors?.allServices?.data ?? []
  )

  const [vendorService, setVendorService] = useState(null)
  const [allUsed, setAllUsed] = useState(false)

  useEffect(() => {
    if (allServices.length > 0) {
      setVendorService(allServices.find(s => s.servicetype_uri === service.uri))
    }
  }, [allServices, service])

  useEffect(() => {
    if (vendorService) {
      setAllUsed(() => {
        const allOptions =
          vendorService &&
          vendorService.items.reduce(
            (acc, item) => [...acc, ...item.item_options],
            []
          )
        return service.options.length === allOptions.length
      })
    }
  }, [vendorService, service])

  const onSuccess = () => {
    onHandleForceUpdateVendor()
    dismissModal()
  }

  const onError = () => {
    dismissModal()
    setAlert(
      'Something went wrong in deleting service. Please try again later!!!',
      'danger'
    )
  }

  const onHandleDeleteService = () => {
    const { pdbid } = user.user_metadata
    showModal(
      { width: '480px' },
      <ConfirmationModal
        msg={
          <p>
            You are about to remove the service from this vendor. You will not
            be able to undo it and you will have to manually add it again later.
          </p>
        }
        dismissLabel="Cancel"
        submitLabel="Yes, Delete"
        additionalSubmitButtonClasses="is-danger"
        subMsg="Are you sure you want to proceed?"
        submit={() => {
          const body = { vendorid: vendorId, serviceid: service.uri }
          deleteVendorServices(pdbid, context, body, onSuccess, onError)
        }}
        dismiss={dismissModal}
      />
    )
  }

  const getServiceLabel = label =>
    label.toLowerCase() === 'cleaning' ? 'Clean' : label
  return (
    <div className="box is-shadowless">
      <PermissionGate name={'add-vendor'}>
        <RestrictedElement>
          {/* {service?.status !== 'T' && (
            <p
              style={{ marginTop: '-13px', marginRight: '-7px' }}
              title="Delete Service"
              onClick={() => onHandleDeleteService()}
              className="close is-pointer has-text-grey-light is-pulled-right">
              <img alt="Delete Service" src={close} />
            </p>
          )} */}
          <p
            style={{ marginTop: '-13px', marginRight: '-7px' }}
            title={
              service?.status !== 'T'
                ? 'Delete Service.'
                : 'You cannot remove this service from the vendor because this service is being used in a team of this vendor.'
            }
            onClick={e => {
              e.preventDefault()
              if (hasPermission && service?.status !== 'T') {
                onHandleDeleteService()
              }
            }}
            className="close is-pointer has-text-grey-light is-pulled-right">
            <img
              alt="Delete Service"
              src={close}
              style={{
                cursor: service?.status !== 'T' ? 'pointer' : 'not-allowed'
              }}
            />
          </p>
        </RestrictedElement>
      </PermissionGate>
      <FontAwesomeIcon
        icon={faEllipsisH}
        className="is-pulled-right is-pointer is-hidden"
        onMouseOver={e => showTooltip('services', e)}
      />
      <div
        style={{
          marginTop: hasPermission ? '28px' : 0
        }}>
        <div className="columns is-desktop is-mobile">
          <div className="column is-narrow">
            <img src={SERVICE_ICONS[service?.name || '']} alt="Vendor Icon" />
          </div>
          <div className="column">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <p className="is-size-4">
                {getServiceLabel(service?.name || '')}
              </p>
              <button
                className="button is-normal is-responsive is-primary style"
                type="button"
                onClick={() =>
                  history.push(
                    `/setup/vendor/pricing/${vendorId}/${service.uri}?service=${service.name}&vendor=${vendorName}`
                  )
                }>
                Pricing Workbook
              </button>
            </div>

            {/* <p className="is-size-4 m-b-md">{getServiceLabel(service.name)}</p> */}
            {/* <p>
            <strong>Service Options</strong>
          </p>
          <KeyValueDisplay
            data={service.options_kv.data}
            display={service.options_kv.display}
            icons={hook => (
              <RestrictedElement>
                <div className="column is-narrow">
                Previously commited out
                   TODO uncomment this line of code when endpoints has been fixed 
                   <img
                    src={trash}
                    className="is-inline is-pulled-right m-l-sm is-pointer"
                    style={{ marginTop: '-1px' }}
                    alt="Delete Service Option"
                    onClick={() => {
                      showDeleteVendorModal(hook.key)
                    }}
                  /> 
                  Previously commited out
                  <PermissionGate name={'edit-vendor-service'}>
                    <img
                      src={editIcon}
                      className="is-inline is-pulled-right is-pointer"
                      style={{ marginTop: '-1px' }}
                      alt="Edit Service Option"
                      onClick={() => {
                        openServicesModal(hook)
                      }}
                    />
                  </PermissionGate>
                </div>
              </RestrictedElement>
            )}
          />
          <RestrictedElement>
            <Button
              type="white"
              size="content"
              className={cn('is-size-7', { 'is-hidden': allUsed })}
              onClick={() => openServicesModal({ serviceKey: service.uri })}
              style={{ marginLeft: '-11px' }}>
              <img src={addButton} width="17px" alt="Add Option" /> Add Option
            </Button>
          </RestrictedElement>
          <div className="m-t-sm">
            <p className="m-t-sm m-b-sm">
              <strong>Floorplan Pricing (Unit)</strong>
                Previously commited out
                 TODO develop delete functionallity when enpoint is ready
                 <img
                  src={trash}
                  className="is-inline is-pulled-right m-l-sm is-pointer"
                  style={{ marginTop: '-1px' }}
                  alt="Delete Floorprice"
                  onClick={() => {
                    console.log('a')
                  }}
                />
                 Previously commited out
              <PermissionGate name={'edit-service-floor-pricing'}>
                <img
                  src={editIcon}
                  className="is-inline is-pulled-right is-pointer"
                  style={{ marginTop: '-1px' }}
                  alt="Edit Floorprice"
                  onClick={() => {
                    showModal(
                      { width: '480px' },
                      <PricingUnitModal
                        floorplan={floorplan}
                        service={service}
                        setupOneVendorGet={setupOneVendorGet}
                      />
                    )
                  }}
                />
              </PermissionGate>
            </p>

            {floorplan.map(floorplanItem =>
              floorplanItem.services
                .filter(
                  item => item.cost > 0 && item.servicetype_id === service.uri
                )
                .map(item => (
                  <p className="m-t-sm" key={item.floorplan_cost_id}>
                    {floorplanItem.floorplan_name}{' '}
                    <span className="m-l-lg">
                      {currencyFormatter(item.cost, { disableRounding: true })}
                    </span>
                  </p>
                ))
            )}

            {falttenFloorplans.filter(
              item => item.cost > 0 && item.servicetype_id === service.uri
            ).length === 0 && (
              <Button
                type="white"
                size="content"
                className={cn('is-size-7', { 'is-hidden': allUsed })}
                onClick={() =>
                  showModal(
                    { width: '480px' },
                    <PricingUnitModal
                      floorplan={floorplan}
                      service={service}
                      setupOneVendorGet={setupOneVendorGet}
                    />
                  )
                }
                style={{ marginLeft: '-11px' }}>
                <img src={addButtonBlack} alt="Add Unit Pricing" /> Add Unit
                Pricing
              </Button>
            )}
          </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupVendorService
