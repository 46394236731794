import LOGOUT from '../auth'
import {
  PROPERTY_FORMS_LIST_REQUESTED,
  PROPERTY_FORMS_LIST_SUCCESS,
  PROPERTY_FORMS_LIST_FAIL,
  PROPERTY_FORMS_ELEMENTS_REQUESTED,
  PROPERTY_FORMS_ELEMENTS_SUCCESS,
  PROPERTY_FORMS_ELEMENTS_FAIL,
  PROPERTY_FORMS_ELEMENTS_RESET,
  PROPERTY_FORMS_DETAILS_REQUESTED,
  PROPERTY_FORMS_DETAILS_SUCCESS,
  PROPERTY_FORMS_DETAILS_FAIL,
  PROPERTY_FORMS_DETAILS_RESET,
  PROPERTY_FORM_DELETE_REQUESTED,
  PROPERTY_FORM_DELETE_SUCCESS,
  PROPERTY_FORM_DELETE_FAIL,
  PROPERTY_FORM_DELETE_RESET,
  PROPERTY_FORM_DUPLICATE_REQUESTED,
  PROPERTY_FORM_DUPLICATE_SUCCESS,
  PROPERTY_FORM_DUPLICATE_FAIL,
  PROPERTY_FORM_DUPLICATE_RESET,
  CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED,
  CREATE_OR_UPDATE_PROPERTY_FORM_FAIL,
  CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS,
  PROPERTY_FORM_CREATE_RESET,
  PROPERTY_FORMS_LIST_RESET,
  PROPERTY_FORM_ERROR_RESET
} from './types'

const initialState = {
  listHasRequested: false,
  listIsRequesting: false,
  listIsError: false,
  list: [],
  sharedTemplates: [],
  detailsHasRequested: false,
  detailsIsRequesting: false,
  detailsIsError: false,
  details: {
    form_id: null,
    template_id: null,
    template_name: null,
    inspection_id: null,
    name: '',
    type: '',
    spaces: [],
    created_by: null,
    created_at: null,
    status: null
  },
  elementsHasRequested: false,
  elementsIsRequesting: false,
  elementsIsError: false,
  elementsUpdateIsRequesting: false,
  elementsUpdateIsError: false,
  elements: {
    spaces: [],
    items: [],
    observations: [],
    solutions: []
  },
  deleteIsRequesting: false,
  deleteHasRequested: false,
  deleteIsError: false,
  deleteSuccess: false,
  duplicateIsRequesting: false,
  duplicateHasRequested: false,
  duplicateIsError: false,
  duplicateSuccess: false,
  createOrUpdateFormRequested: false,
  createOrUpdateFormError: false
}

/**
 * Property forms data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case PROPERTY_FORMS_LIST_REQUESTED:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: true,
        listIsError: false
      }
    case PROPERTY_FORMS_LIST_SUCCESS:
      return {
        ...state,
        listIsRequesting: false,
        list: action.list,
        sharedTemplates: action.sharedTemplates
      }
    case PROPERTY_FORMS_LIST_FAIL:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: true,
        list: [],
        sharedTemplates: []
      }
    case PROPERTY_FORMS_LIST_RESET:
      return {
        ...state,
        listIsError: false
      }
    case PROPERTY_FORMS_ELEMENTS_REQUESTED:
      return {
        ...state,
        elementsHasRequested: true,
        elementsIsRequesting: true,
        elementsIsError: false
      }
    case PROPERTY_FORMS_ELEMENTS_SUCCESS:
      return {
        ...state,
        elementsIsRequesting: false,
        elements: action.elements
      }
    case PROPERTY_FORMS_ELEMENTS_FAIL:
      return {
        ...state,
        elementsIsRequesting: false,
        elementsIsError: true,
        elements: {
          spaces: [],
          items: [],
          observations: [],
          solutions: []
        }
      }
    case PROPERTY_FORMS_ELEMENTS_RESET:
      return {
        ...state,
        elementsHasRequested: false,
        elementsIsRequesting: false,
        elementsIsError: false,
        elements: {
          spaces: [],
          items: [],
          observations: [],
          solutions: []
        }
      }
    case PROPERTY_FORMS_DETAILS_REQUESTED:
      return {
        ...state,
        detailsHasRequested: true,
        detailsIsRequesting: true,
        detailsIsError: false
      }
    case PROPERTY_FORMS_DETAILS_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        details: action.details,
        elements: action.elements,
        list: action.list
      }
    case PROPERTY_FORMS_DETAILS_FAIL:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: true
      }
    case PROPERTY_FORMS_DETAILS_RESET:
      return {
        ...state,
        detailsHasRequested: false,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: {
          form_id: null,
          template_id: null,
          template_name: null,
          inspection_id: null,
          name: '',
          type: '',
          spaces: [],
          created_by: null,
          created_at: null,
          status: null
        }
      }
    case CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED:
      return {
        ...state,
        createOrUpdateFormRequested: true,
        createOrUpdateFormError: false
      }
    case CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS:
      return {
        ...state,
        createOrUpdateFormRequested: false
      }
    case CREATE_OR_UPDATE_PROPERTY_FORM_FAIL:
      return {
        ...state,
        createOrUpdateFormRequested: false,
        createOrUpdateFormError: true
      }
    case PROPERTY_FORM_CREATE_RESET:
      return {
        ...state,
        createHasRequested: false,
        createIsRequesting: false,
        createIsError: false,
        createSuccess: false
      }
    case PROPERTY_FORM_DELETE_REQUESTED:
      return {
        ...state,
        deleteHasRequested: true,
        deleteIsRequesting: true,
        deleteIsError: false,
        deleteSuccess: false
      }
    case PROPERTY_FORM_DELETE_SUCCESS:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: false,
        deleteSuccess: true,
        list: state.list.filter(form => form.form_id !== action.removedFormId)
      }
    case PROPERTY_FORM_DELETE_FAIL:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: true,
        deleteSuccess: false
      }
    case PROPERTY_FORM_DELETE_RESET:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: false,
        deleteSuccess: false
      }
    case PROPERTY_FORM_ERROR_RESET:
      return {
        ...state,
        detailsIsError: false,
        elementsIsError: false,
        deleteIsError: false,
        duplicateIsError: false,
        createOrUpdateFormError: false
      }
    case PROPERTY_FORM_DUPLICATE_REQUESTED:
      return {
        ...state,
        duplicateHasRequested: true,
        duplicateIsRequesting: true,
        duplicateIsError: false,
        duplicateSuccess: false
      }
    case PROPERTY_FORM_DUPLICATE_SUCCESS:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: false,
        duplicateSuccess: true
      }
    case PROPERTY_FORM_DUPLICATE_FAIL:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: true,
        duplicateSuccess: false
      }
    case PROPERTY_FORM_DUPLICATE_RESET:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: false,
        duplicateSuccess: false
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
