import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Pricing from '../../parents/pricing'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  allVendorServicePricing,
  updateBulkVendorServicePricing,
  allVendorPricingItemOption,
  updateSingleVendorServicePricing,
  deleteSingleVendorServiceUnitSpacePricing,
  getAllPropertyUnitSpace
} from '../../modules/setup-vendors'
import { allUnitsGet } from '../../modules/charge'
import { getAllUnitSpace } from '../../modules/company-templates'

const PricingManage = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <Pricing {...props} />
  }

  return <BigLoading />
}

PricingManage.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = ({ user, context, modal, setupVendors }) => ({
  user,
  context,
  modal,
  setupVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      allVendorServicePricing,
      updateBulkVendorServicePricing,
      allVendorPricingItemOption,
      allUnitsGet,
      getAllUnitSpace,
      updateSingleVendorServicePricing,
      deleteSingleVendorServiceUnitSpacePricing,
      getAllPropertyUnitSpace
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PricingManage)
)
