import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { question } from 'ui/icons'
import './index.scss'

/**
 * Component for showing a stats card with an optional icon
 *
 * @component
 * @example
 *   <StatsCard
 *      icon="http://example.com/img/test.svg"
 *      caption="Test"
 *      stat={10}
 *      showLoading={false}
 *      showUnits={true}
 *      helpInfo={() => <p>stats help info</p>}
 *      isSelected
 *   />
 * )
 */
const StatsCard = ({
  icon,
  caption,
  stat,
  totalBeds,
  className,
  showLoading,
  isSelected,
  showUnits,
  spacesLabel = 'Spaces',
  helpInfo
}) => {
  const showSpinner = () => <FontAwesomeIcon icon={faSpinner} spin />

  return (
    <div
      className={`stats-card box is-pointer animated fadeInDown ${className ||
        ''} ${isSelected ? 'is-selected' : ''}`}>
      <div className="columns is-desktop is-mobile">
        <div className="column has-text-centered is-narrow p-t-none p-b-none">
          <img src={icon} alt={caption} />
        </div>
        <div className="column p-t-none p-b-none extra-info-container" style={{ ...(showLoading ? {alignSelf: 'flex-start', fontSize: 16 } : {}) }}>
          {showLoading ? (
            <p className="is-unselectable">
              {showSpinner()}
            </p>
          ) : (
            <div className="info-section">
              <p className="caption is-unselectable">
                {caption}
              </p>
              <p className={`extra-info ${!showUnits ? 'no-border' : ''}`}>
                { showUnits && (
                  <span>Units: {stat || 0}</span>
                )}
                <span>{spacesLabel}: {totalBeds || stat || 0}</span>
              </p>
            </div>
          )}
        </div>
      </div>
      { helpInfo && (
        <div className="help-container">
          <img src={question} alt="help"/>
          <div className="help-body">
            {helpInfo()}
          </div>
        </div>
      )}
    </div>
  )
}

StatsCard.propTypes = {
  /**
   * An absolute or relative URL with the icon image
   */
  icon: PropTypes.string,
  /**
   * The main caption to show
   */
  caption: PropTypes.string,
  /**
   * The value to show in the stats
   */
  stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * The value to show in the beds label
   */
  totalBeds: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * An additional class to be applied to the main component DIV
   */
  className: PropTypes.string,
  /**
   * Determines if the data is currently being loaded
   */
  showLoading: PropTypes.bool,
  /**
   * Determines if the stat card is currently selected
   */
  isSelected: PropTypes.bool,
  /**
   * Determines whether to show or not the units info
   */
  showUnits: PropTypes.bool,
  /**
   * Determines whether to show or not the units info
   */
  spacesLabel: PropTypes.string,
  /**
   * Determines whether to show or not the help icon and it's content
   */
  helpInfo: PropTypes.func,
}

export default StatsCard
