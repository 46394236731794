import React, { useEffect, useState } from 'react'

import '../index.scss'

const ToggleIsDamageRequired = ({ onChange, isActive }) => {
  const [isChecked, setChecked] = useState(isActive || false)

  const handleChange = () => {
    setChecked(!isChecked)
  }

  useEffect(() => {
    onChange(isChecked)
  }, [isChecked])
  return (
    <div className="toggle-container">
      <label className="toggle-control">
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="control"></span>
      </label>
      <span className="active-toggle-text">
        {isChecked ? (
          <>
            Damage <span>required</span> for approval
          </>
        ) : (
          <>
            Damage <span>not required</span> for approval
          </>
        )}
      </span>
    </div>
  )
}

export default ToggleIsDamageRequired
