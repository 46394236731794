import React from 'react'
import PropTypes from 'prop-types'
import { hamburger } from '../../ui/icons'
import './index.scss'

function QcHeader({ title, toggleSidebar, renderActions }) {
  return (
    <div className="qc-header navbar is-primary">
      <div className="qc-header-back" onClick={toggleSidebar}>
        <img src={hamburger} alt="menu" />
      </div>

      <div className="qc-header-title">
        {title}
      </div>

      <div className={`qc-header-actions ${!renderActions ? 'qc-header-actions-hidden' : ''}`}>
        {renderActions && renderActions()}
      </div>
    </div>
  )
}

QcHeader.propTypes = {
  title: PropTypes.string,
  toggleSidebar: PropTypes.func,
  renderActions: PropTypes.func,
}

export default QcHeader
