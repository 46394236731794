import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from 'hooks'
import Breadcrumbs from '../breadcrumbs'
import RouterPrompt from '../../components/router-prompt'
import UnsavedChangesModal from '../../components/unsaved-changes-modal'
import TabOptions from '../../components/tab-options'
import EditionList from '../../components/edition-list'
import { getTabs, stateOptions } from './elements-main-config'
import { closeFilled } from 'ui/icons'
import BigLoading from '../../components/big-loading'
import './index.scss'

const ElementsMain = props => {
  const {
    user: { pdbid },
    context
  } = useAppContext()
  const {
    location,
    getElements,
    updateTemplateElements,
    deleteTemplateElement,
    companyTemplates,
    setModalContent,
    openModal,
    closeModal,
    getAllUnitSpace
  } = props
  const {
    elements,
    elementsIsRequesting,
    elementsIsError,
    elementsHasRequested,
    elementsUpdateIsRequesting,
    elementsDeleteIsRequesting
  } = companyTemplates
  const [customElements, setCustomElements] = useState({})
  const [modifiedData, setModifiedData] = useState(false)
  const [error, setError] = useState(false)
  const [refreshElements, setRefreshElements] = useState(false)

  // TODO USE THE FOLLOWING VAR TO DYNAMICALLY CHANGE THE OPTIONS IN THE VIEW
  // const inspectionSetupMode = location.pathname.includes('setup')

  const [activeTab, setActiveTab] = useState({})
  const tabs = getTabs(setActiveTab, {
    setModalContent,
    openModal,
    closeModal,
    UnsavedChangesModal,
    setModifiedData
  })

  const isLoading =
    (elementsIsRequesting && elementsHasRequested) || elementsDeleteIsRequesting

  useEffect(() => {
    getElements(pdbid, context)
    getAllUnitSpace(pdbid, context)
    setActiveTab(tabs[0])
  }, [])

  useEffect(() => {
    if (refreshElements) {
      getElements(pdbid, context)
      getAllUnitSpace(pdbid, context)
      setActiveTab(tabs[0])
      setRefreshElements(false)
    }
  }, [refreshElements])

  useEffect(() => {
    setCustomElements(elements)
  }, [elements])

  const saveItemHandler = async (type, elementsToUpdate) => {
    const dataToUpdate = {
      items: elementsToUpdate
    }

    updateTemplateElements(pdbid, context, type, dataToUpdate, () => {
      getElements(pdbid, context)
    })

    setModifiedData(false)
  }

  useEffect(() => {
    if (elementsIsError) {
      setError(true)
    }
  }, [elementsIsError])

  if (isLoading) {
    return <BigLoading />
  }

  return (
    <>
      <RouterPrompt activePrompt={modifiedData} />
      <div className="container setup-vendors-main animated fadeIn">
        <div className="section">
          <Breadcrumbs location={location} />
        </div>
        <div className="section">
          <div className="elements__container">
            <TabOptions
              tabs={tabs}
              tabExtraInfo={{ modifiedData }}
              style={{ top: -35 }}
              activeTab={activeTab}
            />
            {error && (
              <div className="notification is-danger is-light is-flex elements__error">
                <p>{'Something went wrong. Please try again later !!! '}</p>
                <button onClick={() => setError(false)}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            )}
            <div className="elements__body">
              {activeTab.id && (
                <EditionList
                  saveAction={saveItemHandler}
                  setError={setError}
                  loading={elementsUpdateIsRequesting}
                  type={activeTab.id}
                  singularTabName={activeTab.singularTabName}
                  data={customElements[activeTab.id]}
                  setModifiedData={setModifiedData}
                  isModified={modifiedData}
                  labelKey={`${activeTab.id.substring(
                    0,
                    activeTab.id.length - 1
                  )}_name`}
                  idKey={`${activeTab.id.substring(
                    0,
                    activeTab.id.length - 1
                  )}_id`}
                  dropdownKey="status"
                  removeKey="onTemplate"
                  stateOptions={stateOptions}
                  deleteTemplateElement={deleteTemplateElement}
                  itemStructure={{
                    [`${activeTab.id.substring(
                      0,
                      activeTab.id.length - 1
                    )}_name`]: '',
                    [`${activeTab.id.substring(
                      0,
                      activeTab.id.length - 1
                    )}_id`]: null,
                    status: 'active',
                    onTemplate: 'F'
                  }}
                  {...props}
                  pdbid={pdbid}
                  context={context}
                  onRefreshElements={setRefreshElements}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ElementsMain.propTypes = {
  location: PropTypes.object,
  getElements: PropTypes.func,
  companyTemplates: PropTypes.object,
  updateTemplateElements: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

export default ElementsMain
