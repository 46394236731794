import addBlack from './icons/add-black.svg'
import setupAdd from './icons/setup-add.svg'
import edit from './icons/edit@2x.svg'
import services from './icons/services@2x.svg'
import trash from './icons/trash@2x.svg'

export const icons = {
  addBlack,
  setupAdd,
  edit,
  services,
  trash,
} 
