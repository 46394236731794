import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import DataTableMenu from '../../../components/data-table-menu'
import { usePermissionGate } from '../../../helpers/hooks'
import { checkPermission } from '../../../utils/helperFunctions'
import { trash } from 'ui/icons'
import { icons } from '../icons'

const UserActionMenu = ({
  userRow,
  user,
  onShowBlockModal,
  onshowDeleteModal
}) => {
  const history = useHistory()
  const { hasPermission } = usePermissionGate('manage-user')

  const isPropertyManager = useMemo(() => {
    const { roles } = user || {}

    return (roles || []).some(role => role === 'Property Manager')
  }, [user])

  const showActionDropdown = useMemo(() => {
    const { userRole } = userRow || {}

    return !(isPropertyManager && userRole?.name === 'Administrator')
  }, [userRow, isPropertyManager])

  const isCurrentUserIsLoggedInUser = useMemo(() => {
    return user?.email === userRow?.email
  }, [user, userRow])

  const { roles, used } = userRow || {}

  // Row option based on conditions
  const menuOption = useMemo(() => {
    const options = []

    if (roles !== 'Vendor') {
      options.push({
        caption: 'Edit User',
        icon: icons.edit,
        action: () => {
          history.push(`/setup/users/edit/${userRow?.user_id}`)
        }
      })
    }

    if (!isCurrentUserIsLoggedInUser) {
      options.push({
        icon: icons.lock,
        name: 'manage-user',
        caption:
          userRow?.blocked === 'Blocked' ? 'Reactivate User' : 'Block User',
        action: () => onShowBlockModal(userRow)
      })
    }

    if (!isCurrentUserIsLoggedInUser && !used) {
      options.push({
        icon: trash,
        caption: 'Delete User',
        action: () => onshowDeleteModal(userRow)
      })
    }

    return options
  }, [userRow, isCurrentUserIsLoggedInUser, roles])

  return (
    <div className="menu-item">
      {hasPermission && showActionDropdown && (
        <DataTableMenu options={menuOption} row={userRow} />
      )}
    </div>
  )
}

export default UserActionMenu
