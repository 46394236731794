import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './styles.scss'

const MobileTabs = ({ pathname, tabs }) => {
  return (
    <div className="mobile-tabs-container">
      <div className="tabs is-fullwidth">
        <ul>
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={pathname === tab.pathname ? 'is-active' : ''}>
              <Link to={tab.pathname}>
                <div className="has-text-centered">
                  <div>
                    <img
                      src={pathname === tab.pathname ? tab.clicked : tab.icon}
                      className="tab-icon"
                      alt="link"
                    />
                  </div>
                  <div className="has-text-weight-normal">{tab.title}</div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

MobileTabs.propTypes = {
  pathname: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string,
      icon: PropTypes.any,
      clicked: PropTypes.any,
      title: PropTypes.string
    })
  )
}

export default MobileTabs
