import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import get from 'lodash/get'
import find from 'lodash/find'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'

import { close, closeFilled } from 'ui/icons'

const ServicesModalAssign = ({
  rows,
  services,
  closeModal,
  updateServices,
  context,
  user,
  action,
  onAccepted,
  blockedUnitBody,
  unblockService,
  setChecked,
  setIsBlockUnitEnabled
}) => {
  const [teamSelected, setTeamSelected] = useState('no-team')
  const [errorMessage, setErrorMessage] = useState('')
  const [date, setDate] = useState(null)
  const property = find(
    get(user.user_metadata, 'properties', []),
    p => p.id === get(context, 'property')
  )

  const handleDayClick = day => {
    setErrorMessage('')
    setDate(day)
  }

  const resetModal = () => setTeamSelected('no-team')

  const getTeamsOptions = () => {
    const { teams } = services
    const filteredTeams = teams.filter(t => t.member !== rows.team)
    const template = []
    for (let i = 0; i < filteredTeams.length; i += 1) {
      const t = filteredTeams[i]
      template.push(
        <option key={`option-${t.member}`} value={t.uri}>
          {t.member}
        </option>
      )
    }
    return template
  }

  const { pdbid } = user?.user_metadata || {}
  const { teams } = services || {}
  const currentService = (services || {})?.currentService?.replace('/', '%20')

  let team = teams.find(t => t.uri === teamSelected)
  const operation_uris = rows.map(r => r.operation_uri).join(',')
  const buttonIsDisabled = teamSelected === 'no-team'
  const displayUnits = rows.map(r => r.unit)

  console.log(errorMessage)

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Assign / Schedule</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              closeModal()
              resetModal()
              setIsBlockUnitEnabled(false)
            }}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full m-t-none p-t-none">
          <p className="modal-sync-subtitle">Unit {displayUnits.join(' | ')}</p>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}

      <div className="columns">
        <div className="column is-full m-b-none">
          <p className="is-size-6">Team</p>
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              value={teamSelected}
              onChange={e => {
                setErrorMessage('')
                setTeamSelected(e.target.value)
              }}>
              <option value="no-team">Select Team</option>
              {getTeamsOptions()}
            </select>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">Schedule Date</p>
          <DayPickerInput
            onDayChange={handleDayClick}
            dayPickerProps={{
              modifiers: calendarConfig.modifiers,
              firstDayOfWeek: calendarConfig.firstDayOfWeek,
              weekdaysShort: calendarConfig.weekdaysShort
            }}
            formatDate={formatDate}
            format={calendarConfig.FORMAT}
            parseDate={parseDate}
            placeholder={`${dateFnsFormat(new Date(), calendarConfig.FORMAT)}`}
          />
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              closeModal()
              resetModal()
              //setChecked([])
              setIsBlockUnitEnabled(false)
            }}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={() => {
              setErrorMessage('')
              const body = {
                operation_uri: operation_uris,
                team_uri: team?.uri,
                scheduled_date: date ? date.toISOString() : ''
              }
              if (action === 'Assign') {
                if (blockedUnitBody?.operation_uri !== '') {
                  unblockService(
                    pdbid,
                    context,
                    currentService,
                    blockedUnitBody,
                    status => {
                      if (status) {
                        setErrorMessage('')
                        updateServices(
                          pdbid,
                          context,
                          currentService,
                          action,
                          body
                        )
                        if (onAccepted) {
                          onAccepted(team.uri)
                        }
                        closeModal(true)
                      }
                    },
                    status => {
                      setErrorMessage(
                        'Something went wrong. Please try again later !!!'
                      )
                    }
                  )
                } else {
                  updateServices(pdbid, context, currentService, action, body)
                  if (onAccepted) {
                    onAccepted(team.uri)
                  }
                  closeModal(true)
                }
              } else {
                updateServices(pdbid, context, currentService, action, body)
                if (onAccepted) {
                  onAccepted(team.uri)
                }
                closeModal(true)
              }
            }}
            disabled={buttonIsDisabled ? 'disabled' : ''}>
            Assign
          </button>
        </div>
      </div>
    </div>
  )
}

ServicesModalAssign.propTypes = {
  closeModal: PropTypes.func,
  updateServices: PropTypes.func,
  user: PropTypes.object,
  services: PropTypes.object,
  action: PropTypes.string,
  rows: PropTypes.array,
  modal: PropTypes.object
}

export default ServicesModalAssign
