import _ from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { getCostSummary } from '../../modules/dashboard-cost'
import { headerData } from './header-data'
import DataTable from '../../components/data-table'

const getContextData = (context, project, property) =>
  project
    ? `${project.id || project}_${property || 'All'}`
    : `${context.project}_${context.property || 'All'}`

const getCostData = (dashboard, context, property, project) =>
  _.get(dashboard, `${getContextData(context, project, property)}.rows`)

const getLoadingData = (dashboard, context, property, project) =>
  _.get(dashboard, `${getContextData(context, project, property)}.isLoading`)

const CostTable = ({
  user,
  context,
  property,
  project,
  cost,
  getCostSummary
}) => {
  useEffect(() => {
    const { pdbid } = user.user_metadata
    const requestedContext =
      project || property ? { property, project } : context
    getCostSummary(pdbid, requestedContext)
  }, [user, context, property, project, getCostSummary])

  const data = getCostData(cost, context, property, project)

  return data && data.length ? (
    <DataTable
      header={headerData}
      data={data}
      isLoading={getLoadingData(cost, context, property, project)}
      iconClicked={() => {}}
      tableTop={<div style={{ borderBottom: '2px solid #e5e9f2' }}></div>}
      resetPage={false}
      filterKey={null}
      filterValue={''}
      hasCheckboxes={false}
      checkboxKey={''}
      checkboxClicked={() => {}}
    />
  ) : null
}

CostTable.propTypes = {
  user: PropTypes.object,
  cost: PropTypes.object,
  getCostSummary: PropTypes.func.isRequired
}

const mapStateToProps = ({ user, context, dashboard }) => ({
  user,
  context,
  cost: dashboard.cost
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCostSummary
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostTable)
