import _ from 'lodash'
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import { close } from 'ui/icons'

const SetupVendorManageModalServices = ({
  defaultService = 'none',
  defaultOption = 'none',
  defaultCost = 0,
  defaultUnit = 'none',
  location,
  user,
  setupOneVendorUpdate,
  closeModal,
  setupVendors,
  context,
  disableServices,
  disableOptions
}) => {
  const modal = useSelector(state => state.modal)
  const [selectedService, setSelectedService] = useState(defaultService)
  const [selectedOption, setSelectedOption] = useState(defaultOption)
  const [cost, setCost] = useState(parseFloat(defaultCost))
  const [selectedUnit, setSelectedUnit] = useState(defaultUnit)

  const prevModal = usePrevious(modal)

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)

    return {
      v: newParams.get('v')
    }
  }, [location])

  const { v } = params
  const { pdbid } = user.user_metadata

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    const { isActive } = modal
    const wasActive = _.get(prevModal, 'isActive')
    const activeChanged = isActive !== wasActive
    if (activeChanged) reset()
  }, [modal])

  const reset = () => {
    setSelectedService(defaultService)
    setSelectedOption(defaultOption)
    setCost(parseFloat(defaultCost))
  }

  const submitForm = () => {
    let data = {}
    data.cost = '1'
    data.item_option_uri = null
    data.servicetype_id = selectedService
    const { id } = setupVendors.oneVendor.data
    const vendorId = v === 'new-vendor' ? id : v

    //data.cost = document.getElementById('vendor-services-modal-cost').value
    //data.item_option_uri = document.getElementById(
    //  'vendor-services-modal-option'
    //).value
    //data.unit = document.getElementById('vendor-services-modal-unit').value
    setupOneVendorUpdate(pdbid, context, vendorId, data)
    closeModal()
  }

  const serviceSelected = e => {
    const { value } = e.target
    setSelectedService(value)
  }

  const optionSelected = e => {
    const { value } = e.target
    setSelectedOption(value)
  }

  const unitSelected = e => {
    const { value } = e.target
    setSelectedUnit(value)
  }

  const getUsedServices = () => {
    const { data } = setupVendors.oneVendor
    return _.map(data.services, service => service.uri)
  }

  const getUsedOptions = () => {
    let usedOptions = []
    const { data } = setupVendors.oneVendor
    for (let i = 0; i < data.services.length; i += 1) {
      const service = data.services[i]
      for (let ii = 0; ii < service.options.length; ii += 1) {
        const option = service.options[ii]
        usedOptions.push(option.uri)
      }
    }
    return usedOptions
  }

  const getOptions = () => {
    const { data } = setupVendors.allServices
    const usedOptions = getUsedOptions()
    const filtered = data.filter(d => d.servicetype_id === selectedService)
    let template = []
    for (let i = 0; i < filtered.length; i += 1) {
      const f = filtered[i]
      for (let ii = 0; ii < f.items.length; ii += 1) {
        const item = f.items[ii]
        for (let iii = 0; iii < item.item_options.length; iii += 1) {
          const option = item.item_options[iii]
          const isNew = defaultOption === ''
          if (isNew && usedOptions.indexOf(option.item_option_uri) !== -1)
            continue
          template.push(
            <option
              value={option.item_option_uri}
              key={`service-option-${option.item_option_id}-${ii}`}>
              {option.item_option}
            </option>
          )
        }
      }
    }
    return template
  }

  const getServiceLabel = label =>
    label.toLowerCase() === 'cleaning' ? 'Clean' : label

  const getServices = filterItems => {
    const { data } = setupVendors.allServices
    const usedServices = getUsedServices()
    let filtered = data

    if (filterItems) {
      filtered = data.filter(d => usedServices.indexOf(d.servicetype_uri) < 0)
    }

    let template = []
    for (let i = 0; i < filtered.length; i += 1) {
      const d = filtered[i]
      template.push(
        <option
          value={d.servicetype_id}
          key={`service-option-${d.servicetype_id}-${i}`}>
          {getServiceLabel(d.servicetype)}
        </option>
      )
    }
    return template
  }

  const disableSubmit = !selectedService || selectedService === 'none'
  const onSubmit = e => e.preventDefault()

  return (
    <form onSubmit={e => onSubmit(e)} id="setup-vendor-modal-services">
      <div className="columns is-multiline">
        <div className="columns is-desktop is-mobile">
          <div className="column">
            <p className="heading-3 is-pulled-left">Add Service</p>
            <p
              className="close is-pointer has-text-grey-light"
              onClick={closeModal}>
              <img alt="Delete Note" src={close} />
            </p>
          </div>
        </div>
        <div className="column is-full" style={{ padding: '0 0 8px 0' }}>
          <div className="form-control">
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                name="service"
                id="vendor-services-modal-service"
                onChange={e => serviceSelected(e)}
                disabled={disableServices ? 'disabled' : ''}
                value={selectedService}>
                <option value="none">Select Service</option>
                {getServices(!disableServices)}
              </select>
            </div>
          </div>
        </div>
        {/* <div className="column is-full">
          <div className="form-control">
            <label forhtml="option">Option</label>
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                name="item_option_uri"
                onChange={e => optionSelected(e)}
                disabled={
                  selectedService === 'none' || disableOptions ? 'disabled' : ''
                }
                value={selectedOption}
                id="vendor-services-modal-option">
                <option value="none">Select Option</option>
                {getOptions()}
              </select>
            </div>
          </div>
        </div>
        <div className="column is-full">
          <label forhtml="cost">Cost</label>
        </div>
        <div className="column is-half">
          <div className="form-control">
            <input
              className="input"
              min="0"
              step="0.01"
              disabled={
                selectedOption === 'none' || selectedService === 'none'
                  ? 'disabled'
                  : ''
              }
              name="cost"
              id="vendor-services-modal-cost"
              value={cost}
              onChange={e => {
                const value = e.target.value
                if (
                  value === '' ||
                  /^([0-9]+(\.?[0-9]?[0-9]?)?)$/.test(value)
                ) {
                  setCost(value)
                }
              }}
            />
          </div>
        </div>
        <div className="column is-half">
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              name="item_option_uri"
              onChange={e => unitSelected(e)}
              value={selectedUnit}
              id="vendor-services-modal-unit">
              <option value="space">Space</option>
              <option value="foot">Sq. Foot</option>
            </select>
          </div>
        </div> */}
      </div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary"
              onClick={() => closeModal()}>
              Cancel
            </button>
          </div>
        </div>
        <div className="column is-half has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary"
              onClick={() => submitForm()}
              disabled={disableSubmit ? 'disabled' : ''}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupVendorManageModalServices.propTypes = {
  setupVendors: PropTypes.object,
  submit: PropTypes.func,
  defaultService: PropTypes.string,
  defaultOption: PropTypes.string,
  defaultCost: PropTypes.number
}

export default SetupVendorManageModalServices
