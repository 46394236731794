import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const TabOptions = ({ tabs, style, tabExtraInfo = {}, activeTab }) => {
  const [tabOptionsCopy, setTabOptionsCopy] = useState([...tabs])

  const handleTabOptionsClick = useCallback((tab, tabExtraInfo) => {
    tab.onClick({...tab, ...tabExtraInfo})
  }, [tabs])

  useEffect(() => {
    setTabOptionsCopy(currentTabs => currentTabs.map(item => {
      item.id === activeTab.id ? item.active = true : item.active = false
      return item
    }))
  }, [activeTab])

  const tabOptionHoverHandler = (color, {target}) => target.style.background = color
  const tabOptionOutHandler = (isActiveTab, {target}) => !isActiveTab ? target.style.background = '' : null

  return (
    <div className="tab-options-container" style={{...style}}>
      <ul className="tab-options">
        {tabOptionsCopy.map(tab => (
          <li
            key={tab.label}
            className={`${tab.active ? 'active' : ''}`}
            onMouseOver={tab.overrideActiveBackground ? (event) => tabOptionHoverHandler(tab.overrideActiveBackground, event) : null}
            onMouseOut={tab.overrideActiveBackground ? (event) => tabOptionOutHandler(tab.active, event) : null}
            style={{ background: (tab.active && tab.overrideActiveBackground) || '' }}
            onClick={() => handleTabOptionsClick(tab, tabExtraInfo)}>
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  )
}

TabOptions.propTypes = {
  tabs: PropTypes.array,
  style: PropTypes.object,
  tabExtraInfo: PropTypes.object,
  activeTab: PropTypes.object,
}

export default TabOptions
