import React from 'react'

const InspectionCellComponent = ({ row }) => {
  const { cell } = row || {}
  const { row: currentRow, value } = cell || {}
  const { original } = currentRow || {}
  const { status } = original || {}

  return <div className="cell-item" title={value}>
    <span className={status === 'Will Not Be Added' ? 'disabled-row' : ''}>
      {value}
    </span>
  </div>
}

export default InspectionCellComponent
