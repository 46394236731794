import { datadogLogs } from '@datadog/browser-logs'
import { DEV_HOSTNAME } from './index'

export const initLogger = () => {
  datadogLogs.init({
    // TODO: Invalidate token later after testing. Should not remain checked into git
    clientToken: 'pubc59471e01ad91d5873b8572b46e885e1',
    datacenter: 'us',
    forwardErrorsToLogs: true,
    sampleRate: 100
  })
}

export const loggerMiddleware = store => next => action => {
  const previousState = store && store.getState()
  const nextState = store && store.getState()
  if (window.location.hostname === DEV_HOSTNAME) {
    return next(action)
  }

  const project =
    (nextState && nextState.context && nextState.context.project) || 'UNDEFINED'
  const property =
    (nextState && nextState.context && nextState.context.property) ||
    'UNDEFINED'
  const user =
    (nextState && nextState.user && nextState.user.email) || 'UNDEFINED'

  // If the action type has the word FAIL in it, send all information available
  const errorInfo =
    action && typeof action.type === 'string' && action.type.includes('FAIL')
      ? {
        action,
        previousState,
        nextState
      }
      : undefined

  if (errorInfo) {
    datadogLogs.logger.error({
      user,
      project,
      property,
      action: action.type,
      errorInfo
    })
  }
  datadogLogs.logger.info({
    user,
    project,
    property,
    action: action.type
  })
  return next(action)
}

export default datadogLogs.logger
