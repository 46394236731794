import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router'

import BigLoading from '../../components/big-loading'
import { useAuth0 } from '../../components/lock/auth0-spa'
import { updateContext } from '../../modules/context'
import { getDownloadState } from '../../modules/download-inspections'
import { getUser } from '../../modules/user'
import Service from '../../service'
import { logout } from '../../modules/auth'

const AuthInit = ({ onStopLoading, children }) => {
  const dispatch = useDispatch()
  const {
    isAuthenticated,
    loginWithRedirect,
    loading,
    logout: auth0Logout
  } = useAuth0()
  const {
    context,
    auth,
    user: {
      isRequesting,
      hasMadeInitialRequest,
      user_metadata,
      isError,
      blocked
    }
  } = useSelector(state => state)

  const contextIsSelected =
    context.project !== null && context.property !== null
  const contextCookie = Service.getCtxCookie()
  const isNullContextValue =
    typeof contextCookie === 'string' && contextCookie.search('null') !== -1
  const { pdbid } = user_metadata || {}
  const { property, project } = context || {}

  const isLoading = isRequesting || !hasMadeInitialRequest

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (contextCookie && contextCookie !== '') {
        const ctx = contextCookie.split('|')
        dispatch(getUser(ctx))
      } else {
        dispatch(getUser())
      }
    }
  }, [auth.isAuthenticated, dispatch])

  // useEffect(() => {
  //   if (pdbid && !isEmpty(property) && !isEmpty(project)) {
  //     dispatch(getDownloadState(pdbid, context))
  //   }
  // }, [pdbid, property, project, dispatch])

  useEffect(() => {
    if (loading) return
    if (isAuthenticated) {
      if (context.changeRequested || !contextIsSelected) {
        if (
          contextCookie !== null &&
          !context.changeRequested &&
          !isNullContextValue
        ) {
          const ctx = contextCookie.split('|')
          dispatch(updateContext(ctx[0], ctx[1]))
        }
      }
      return
    }

    ;(async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      })
    })()
  }, [isAuthenticated, loading, loginWithRedirect, dispatch])

  useEffect(() => {
    if (!isLoading) onStopLoading && onStopLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (isAuthenticated && blocked) {
      dispatch(logout())
      auth0Logout({ returnTo: window.location.origin })
    }
  }, [blocked, dispatch, isAuthenticated])

  if (isLoading) return <BigLoading />
  else if (isError) return <Redirect to="/" />
  return <>{children}</>
}

export default AuthInit
