import React, { useState, Fragment, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useDrop } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { closeModal } from '../../../modules/modal'
import { StepperContext } from '../../../components/wizard'
import FilterModal from '../../../components/data-table/filter-modal'
import FilterButton from '../../../components/data-table/filter-button'
import Draggable from './draggable'
import Droppable from './droppable'
import ClearAllModal from './clear-all-modal'
import SaveBeforeAutoscheduleModal from './save-before-autoschedule-modal'
import UnsavedChangesModal from '../../unsaved-changes-modal'
import { useModals } from 'hooks'
import { formatDateString } from '../../../utils'
import { getUnits, mapActiveUnits } from './helpers'
import { filters, getFieldFilters } from './filters'
import { lock, transfers, unlock, warning } from 'ui/icons'
import './index.scss'

const ScheduleDnD = ({ goBackAction, scheduleHandler }) => {
  const { schedule } = useSelector(state => state)
  const { scheduleManualData: { dates, hasTeamCapacity, teamsCapacity, units }, scheduleServiceIsRequesting, scheduleServiceIsError } = schedule
  const [showModal] = useModals()
  const dispatch = useDispatch()

  const { handleScheduleAllModal, stepData, goBackToMain } = useContext(StepperContext)
  const serviceType = stepData['servicetype'].replace(/[^a-zA-Z ]/g, '').split(' ').join('')

  const [clearCalled, setClearCalled] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [textFilter, setTextFilter] = useState('')
  const [activeFilters, setActiveFilters] = useState({})
  const [pendingSaving, setPendingSaving] = useState(false)
  const [unitsComparitionDate, setUnitsComparitionDate] = useState(new Date(dates[0]))

  // Scheduled units
  const [datesList, setDatesList] = useState(() => {
    const scheduledItems = units.filter(unit => unit.scheduled_date && unit.team_id )

    return [...teamsCapacity].map((item, index) => {
      return {
        ...getUnits(scheduledItems, {...item}),
        collapsed: index === 0 ? false : true,
      }
    })
  })

  // Unscheduled units
  const [unscheduledUnits, setUnscheduledUnits] = useState(() =>
    _.sortBy(
      mapActiveUnits(
        units
          .filter(unit => !unit.scheduled_date)
          .map(unit => ({ ...unit, show: true })),
        unitsComparitionDate
      ),
      ['unit']
    )
  )
  const [unscheduledUnitsMirror, setUnscheduledUnitsMirror] = useState(unscheduledUnits)

  // Filtering unscheduled items
  useEffect(() => {
    const { activeUnits, building, floorplan, group, istransfer } = activeFilters

    if (!clearCalled) {
      if (istransfer || activeUnits || textFilter || building || floorplan || group) {
        setUnscheduledUnits(unscheduledUnits => {
          let copy = [...unscheduledUnitsMirror]
  
          if (activeUnits) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && unit.active && !unit.scheduled_date
            })), unitsComparitionDate)
          }
  
          if (building) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && building.includes(unit.building)
            })), unitsComparitionDate)
          }
          
          if (floorplan) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && floorplan.includes(unit.floorplan)
            })), unitsComparitionDate)
          }
          
          if (group) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && group.includes(unit.group)
            })), unitsComparitionDate)
          }
  
          if (istransfer) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && unit.istransfer
            })), unitsComparitionDate)
          }
  
          if (textFilter) {
            copy = mapActiveUnits(copy.map(unit => ({
              ...unit,
              show: unit.show && unit.unit.includes(textFilter)
            })), unitsComparitionDate)
          }
  
          return copy
        })
  
      } else {
        setUnscheduledUnits(
          _.sortBy(
            mapActiveUnits([...unscheduledUnitsMirror].filter(unit => !unit.scheduled_date),unitsComparitionDate)
              .map(unit => ({ ...unit, show: true })),
            ['unit'])
        )
      }
    }
    
    setClearCalled(false)
  }, [textFilter, unitsComparitionDate, activeFilters])

  // Clear all units
  const clearAllUnits = () => {
    
    setDatesList(dateList => {
      let unitsToUnschedule = []
      const copy = [...dateList]

      copy.forEach(item => {
        item.team_capacity.forEach(team => {
          unitsToUnschedule = [...unitsToUnschedule, ...team.units.filter(unit => !unit.schedule_locked).map(unit => ({ ...unit, scheduled_date: null, team_id: null }))]
          team.units = team.units.filter(unit => unit.schedule_locked)
          const valueToSumToSpaces = team.units.length > 0 ? team.units.reduce((total, current) => total + current.total_spaces, 0) : 0
          team.availableSpaces = team.capacity - valueToSumToSpaces 
        })
      })

      setUnscheduledUnits(
        _.sortBy(
          mapActiveUnits([...unscheduledUnits, ...unitsToUnschedule].filter(unit => !unit.scheduled_date),unitsComparitionDate)
            .map(unit => ({ ...unit, show: true })),
          ['unit'])
      )
      
      setUnscheduledUnitsMirror(
        _.sortBy(
          mapActiveUnits([...unscheduledUnits, ...unitsToUnschedule].filter(unit => !unit.scheduled_date),unitsComparitionDate)
            .map(unit => ({ ...unit, show: true })),
          ['unit'])
      )

      setClearCalled(true)
      setActiveFilters({})
      dispatch(closeModal())

      return copy
    })

  }

  const handleClearAllModal = () => showModal({ width: '480px' }, <ClearAllModal submitAction={clearAllUnits} />)

  // Updating scheduled units
  const updateScheduleUnits = (dropData, dragData, dateIndex, teamIndex) => {
    // Preventing the duplication of a unit
    let unitAlreadyAdded = false

    datesList[dropData.dateIndex]['team_capacity'][dropData.teamIndex]['units'].forEach(unitItem => {
      if (unitItem.unit === dragData.unit) {
        unitAlreadyAdded = true
      } 
    })

    if (unitAlreadyAdded) {
      return
    }

    // Dragging from one team to another - removing the item inside the old team
    if (dragData.scheduled) {
      setDatesList(datesList => {
        const copy = [...datesList]
        copy[dragData.dateIndex]['team_capacity'][dragData.teamIndex]['units'].splice(dragData.scheduledUnitIndex, 1)
        copy[dragData.dateIndex]['team_capacity'][dragData.teamIndex]['availableSpaces'] = parseInt(copy[dragData.dateIndex]['team_capacity'][dragData.teamIndex]['availableSpaces']) + parseInt(dragData.total_spaces)
        return copy
      })

      setPendingSaving(true)
    }

    // Dragging unit item
    if (dropData.availableSpaces - dragData.total_spaces >= 0) {
      setDatesList(datesList => {
        const copy = [...datesList]
        copy[dateIndex]['team_capacity'][teamIndex]['units'].push({
          ...dragData,
          scheduled: true,
          unscheduled: false,
          scheduled_date: dropData.date.date,
          team_id: dropData.team_id,
          schedule_locked: null
        })
        copy[dateIndex]['team_capacity'][teamIndex]['availableSpaces'] = parseInt(copy[dateIndex]['team_capacity'][teamIndex]['availableSpaces']) - parseInt(dragData.total_spaces)
        return copy
      })

      // Removing the dragged item from the unscheduled section
      if (!dragData.scheduled) {
        setUnscheduledUnits(unscheduledUnits => {
          const copy = [...unscheduledUnits]
          copy.splice(dragData['unitIndex'], 1)
          return copy
        })
  
        setUnscheduledUnitsMirror(unscheduledUnits => {
          const copy = [...unscheduledUnits]
          copy.splice(dragData['unitIndex'], 1)
          return copy
        })
      }

      setPendingSaving(true)
    }
  }
  
  // toggle collapsible cards
  const toggleCollapsible = vendorIndex => {
    setUnitsComparitionDate(new Date(datesList[vendorIndex]['date']))
    setDatesList(list => {
      const listCopy = list.map(listItem => ({ ...listItem, collapsed: true }))
      listCopy[vendorIndex]['collapsed'] = !listCopy[vendorIndex]['collapsed']
      return listCopy
    })
  }

  // schedule lock functionality
  const handleScheduleLock = (dateIndex, teamIndex, unitIndex) => {
    setPendingSaving(true)
    
    if (typeof unitIndex !== 'number') {
      setDatesList(dateList => {
        const copy = [...dateList]

        if (!copy[dateIndex]['team_capacity'][teamIndex]['schedule_locked']) {
          copy[dateIndex]['team_capacity'][teamIndex]['schedule_locked'] = true
          copy[dateIndex]['team_capacity'][teamIndex]['units'] = copy[dateIndex]['team_capacity'][teamIndex]['units'].map(unit => ({
            ...unit,
            schedule_locked: 'T'
          }))
        } else {
          copy[dateIndex]['team_capacity'][teamIndex]['schedule_locked'] = false
          copy[dateIndex]['team_capacity'][teamIndex]['units'] = copy[dateIndex]['team_capacity'][teamIndex]['units'].map(unit => ({
            ...unit,
            schedule_locked: null
          }))
        }

        return copy
      })
    } else {
      setDatesList(dateList => {
        const copy = [...dateList]

        if (!copy[dateIndex]['team_capacity'][teamIndex]['units'][unitIndex]['schedule_locked']) {
          copy[dateIndex]['team_capacity'][teamIndex]['units'][unitIndex] = {
            ...copy[dateIndex]['team_capacity'][teamIndex]['units'][unitIndex],
            schedule_locked: 'T'
          }
        } else {
          copy[dateIndex]['team_capacity'][teamIndex]['units'][unitIndex] = {
            ...copy[dateIndex]['team_capacity'][teamIndex]['units'][unitIndex],
            schedule_locked: null
          }
        }

        return copy
      })
    }
  }

  const autoScheduleHandler = serviceType => {
    if (pendingSaving) {
      showModal({ width: '480px' }, <SaveBeforeAutoscheduleModal />)
    } else {
      handleScheduleAllModal(serviceType)
    }
  }

  const goBackHandler = () => {
    if (pendingSaving) {
      showModal({ width: '480px' }, <UnsavedChangesModal backAction={goBackToMain} />)
    } else {
      goBackToMain()
    }
  }

  const saveReviewHandler = () => {
    let editedUnits = []
    
    datesList.forEach(dateItem => {
      dateItem['team_capacity'].forEach(capacityItem => {
        editedUnits = [...editedUnits, ...capacityItem.units] 
      })
    })

    const allUnits = [...unscheduledUnits, ...editedUnits].map(unit => {
      return {
        scheduled_date: unit.scheduled_date ? new Date(unit.scheduled_date).toISOString() : null,
        schedule_locked: unit.schedule_locked,
        team_id: unit.team_id,
        unit: unit.unit,
        unit_id: unit.unit_id,
        workorder_id: unit.workorder_id,
      }
    })
    
    setPendingSaving(false)
    scheduleHandler(allUnits)
  }

  // Droppable unschedule container
  const [{ isOver }, unscheduleDrop] = useDrop(() => ({
    accept: 'unit',
    drop: (item) => {
      if (unscheduledUnits.filter( unit => unit.unit === item.unit).length >= 1) return

      setUnscheduledUnits(units => {
        const unitsCopy = [...units]
        unitsCopy.push({...item, scheduled: false, unscheduled: true, scheduled_date: null, team_id: null })
        return mapActiveUnits(_.sortBy(unitsCopy, ['unit']), unitsComparitionDate)
      })

      setUnscheduledUnitsMirror(units => {
        const unitsCopy = [...units]
        unitsCopy.push({...item, scheduled: false, unscheduled: true, scheduled_date: null, team_id: null })
        return mapActiveUnits(_.sortBy(unitsCopy, ['unit']), unitsComparitionDate)
      })

      setDatesList(dateList => {
        const copy = [...dateList]
        copy[item.dateIndex]['team_capacity'][item.teamIndex]['units'].splice(item.scheduledUnitIndex, 1)
        copy[item.dateIndex]['team_capacity'][item.teamIndex]['availableSpaces'] = parseInt(copy[item.dateIndex]['team_capacity'][item.teamIndex]['availableSpaces']) + parseInt(item.total_spaces)
        setPendingSaving(true)
        return copy
      })
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    }),
  }), [unscheduledUnits])
 
  return (
    <>
      <div className="schedule-dnd">
        <div className={`schedule-dnd-body ${scheduleServiceIsError ? 'error' : ''}`}>
          <button
            className="clear-all-button button is-text"
            onClick={handleClearAllModal}
            style={{ color: '#91989E' }}>
            Clear All
          </button>
          <div className="navigation-container">
            <button onClick={goBackHandler} className="text-button">
              Back To Services
            </button>
            {hasTeamCapacity && (
              <div>
                <button
                  className="button m-r-md"
                  style={{
                    background: 'white',
                    color: '#8dc85c',
                    border: '1px solid #8dc85c'
                  }}
                  onClick={() => autoScheduleHandler(serviceType)}>
                  Auto-Schedule
                </button>

                <button
                  className="button is-success"
                  onClick={saveReviewHandler}>
                  {scheduleServiceIsRequesting && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
                  )}
                  Save
                </button>
              </div>
            )}
          </div>
          {scheduleServiceIsError && (
            <div className="error-container">
              <img src={warning} alt="warning" className="m-r-sm" />
              <span className="error-description">Error.</span> Something went wrong when attempting to save your most recent changes. Please try again.
            </div>
          )}
          {!hasTeamCapacity ? (
            <>
              <p className="large-text m-b-md">
                There is no team capacity setup for this service.
              </p>
              <Link to="/setup/teams">
                Click here if you need to update a team’s capacity
              </Link>
            </>
          ) : (
            <>
              <p className="title m-b-md">
                Now it’s time to{' '}
                <span className="highlighted">schedule {serviceType}</span>
              </p>
              <p className="large-text">
                Drag and drop into a team’s list then click “Save.”
              </p>
              <p className="caveat">
                To auto-schedule the remaining capacity, lock your selections then click “Auto-Schedule.”
              </p>

              <SimpleBar style={{ maxHeight: 550 }}>
                {datesList.map((date, dateIndex) => (
                  <Fragment key={date.date}>
                    <div
                      className={`team-container ${
                        !date.collapsed ? 'active' : ''
                      }`}>
                      {date.collapsed && (
                        <FontAwesomeIcon
                          icon={!date.collapsed ? faChevronUp : faChevronDown}
                          className="collapsible-toggler"
                          onClick={() => toggleCollapsible(dateIndex)}
                        />
                      )}
                      <span
                        className={`team-name ${
                          !date.collapsed ? 'active' : ''
                        }`}>
                        {formatDateString(date.date, 'ddd, MM/DD')}
                      </span>
                      <SimpleBar style={{ width: '100%' }}>
                        <div className="teams-container">
                          {date['team_capacity'].map((team, teamIndex) => (
                            <Droppable
                              dropType="unit"
                              dropClass={['team-item']}
                              dropAction={(dropData, dragData) =>
                                updateScheduleUnits(
                                  dropData,
                                  dragData,
                                  dateIndex,
                                  teamIndex
                                )
                              }
                              canDrop={() =>
                                new Date(date.date).getTime() ===
                                unitsComparitionDate.getTime()
                              }
                              dropData={{
                                ...team,
                                date,
                                dateIndex,
                                teamIndex
                              }}
                              key={team.team_name}>
                              <div className="team-item__header">
                                <div className="title">
                                  <span style={{ fontWeight: 'bold' }}>
                                    {team.team_name}
                                  </span>
                                  <span>({team.capacity}) Max</span>
                                </div>
                                <div
                                  className="subtitle"
                                  style={{
                                    marginBottom: !date.collapsed ? 'inherit' : 0
                                  }}>
                                  <span>
                                    {team.availableSpaces} Spaces Available
                                  </span>
                                  {team.schedule_locked ? (
                                    <img
                                      src={lock}
                                      alt="locked"
                                      onClick={() =>
                                        handleScheduleLock(dateIndex, teamIndex)
                                      }
                                      className="locked-icon"
                                    />
                                  ) : (
                                    <img
                                      src={unlock}
                                      alt="unlock"
                                      onClick={() =>
                                        handleScheduleLock(dateIndex, teamIndex)
                                      }
                                      className="locked-icon"
                                    />
                                  )}
                                </div>
                              </div>
                              {!team['units'].length && !date.collapsed && (
                                <p className="team-item__message">
                                  No units scheduled
                                </p>
                              )}
                              {!date.collapsed &&
                                !!team['units'].length &&
                                team['units'].map((unit, unitIndex) => (
                                  <Draggable
                                    dragClass={['schedule-draggable']}
                                    previewClass={[
                                      'schedule-draggable__preview scheduled',
                                      unit.active ? '' : 'disabled'
                                    ]}
                                    siblingSection={
                                      <div className="schedule-lock-container">
                                        {unit.schedule_locked ? (
                                          <img
                                            src={lock}
                                            alt="locked"
                                            onClick={() =>
                                              handleScheduleLock(
                                                dateIndex,
                                                teamIndex,
                                                unitIndex
                                              )
                                            }
                                            className="locked-icon"
                                          />
                                        ) : (
                                          <img
                                            src={unlock}
                                            alt="unlock"
                                            onClick={() =>
                                              handleScheduleLock(
                                                dateIndex,
                                                teamIndex,
                                                unitIndex
                                              )
                                            }
                                            className="locked-icon"
                                          />
                                        )}
                                      </div>
                                    }
                                    dragType="unit"
                                    dragData={{
                                      ...unit,
                                      dateIndex,
                                      teamIndex,
                                      scheduled: true,
                                      unscheduled: false,
                                      scheduledUnitIndex: unitIndex
                                    }}
                                    key={unit.unit_id}>
                                    <div className="schedule-draggable__icons">
                                      {unit.istransfer && (
                                        <img src={transfers} alt="is transfer" />
                                      )}
                                    </div>
                                    <span className="schedule-draggable__title">
                                      {unit.unit}
                                    </span>
                                    <div className="schedule-draggable__divider"></div>
                                    <div className="schedule-draggable__additional-info">
                                      <p>Floorplan: {unit.floorplan}</p>
                                      <p>
                                        <strong>
                                          Need Work: {unit.total_spaces}
                                        </strong>
                                      </p>
                                      {unit.earliest_date && (
                                        <p className="earliest-date">
                                          Earliest: {formatDateString(new Date(unit.earliest_date), 'MM/DD/YYYY')}
                                        </p>
                                      )}
                                    </div>
                                  </Draggable>
                                ))}
                            </Droppable>
                          ))}
                        </div>
                      </SimpleBar>
                    </div>
                  </Fragment>
                ))}
              </SimpleBar>
            </>
          )}
        </div>

        {/* Unscheduled units section */}
        <div
          className="draggables-container"
          ref={unscheduleDrop}
          style={{
            background: isOver
              ? 'rgba(139, 211, 237, 0.8)'
              : 'rgba(255, 255, 255, 1)'
          }}>
          <p className="title">Unscheduled units</p>
          <div className="actions-container">
            <input
              type="text"
              placeholder="Search By Unit"
              className="input is-inline text-filter"
              value={textFilter}
              onChange={e => setTextFilter(e.target.value)}
            />
            <FilterButton
              header={[]}
              activeFilters={activeFilters}
              customFilters={filters}
              oneFilterAtTime={false}
              onSetFilters={setActiveFilters}
              onToggleModal={setFilterModalOpen}
              showCustomFilterOption={true}
              filterLabel={''}
              filterButtonStyle={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 8,
                paddingRight: 8
              }}
            />
          </div>
          <SimpleBar style={{ width: '100%', maxHeight: 347, overflowY: 'visible' }}>
            <div className="drag-list">
              {unscheduledUnits.map(
                (unit, unitIndex) =>
                  unit.show && (
                    <Draggable
                      dragClass={['schedule-draggable']}
                      previewClass={[
                        'schedule-draggable__preview',
                        unit.active ? '' : 'disabled'
                      ]}
                      dragType="unit"
                      dragData={{
                        ...unit,
                        unitIndex,
                        scheduled: false,
                        unscheduled: true
                      }}
                      canDrag={unit.active}
                      key={unit.unit_id}>
                      <div className="schedule-draggable__icons">
                        {unit.istransfer && (
                          <img src={transfers} alt="is transfer" />
                        )}
                      </div>
                      <span className="schedule-draggable__title">
                        {unit.unit}
                      </span>
                      <div className="schedule-draggable__divider"></div>
                      <div className="schedule-draggable__additional-info">
                        <p>Floorplan: {unit.floorplan}</p>
                        <p>
                          <strong>Need Work: {unit.total_spaces}</strong>
                        </p>
                        {unit.earliest_date && (
                          <p className="earliest-date">
                            Earliest: {formatDateString(new Date(unit.earliest_date), 'MM/DD/YYYY')}
                          </p>
                        )}
                      </div>
                    </Draggable>
                  )
              )}
            </div>
          </SimpleBar>
        </div>
      </div>
      {filterModalOpen && (
        <FilterModal
          open={filterModalOpen}
          startFilter={activeFilters}
          customFilters={filters}
          fieldFilters={getFieldFilters(units)}
          onFilter={setActiveFilters}
          onClose={() => setFilterModalOpen(false)}
        />
      )}
    </>
  )
}

ScheduleDnD.propTypes = {
  goBackAction: PropTypes.func,
  scheduleHandler: PropTypes.func,
}

export default ScheduleDnD
