import React, { useEffect, useState } from 'react'
import VendorTabOptions from '../vendor-tab-options'
import PricingTabs from './pricing-tabs'
import BusinessInformation from './BusinessInformation'
import ConfirmationModal from '../confirmation-modal'
import { useModals } from 'hooks'
import PropTypes from 'prop-types'
import './index.scss'
import PermissionGate from '../permission-gate'

const  VendorServiceComponent = ({ services, isEdit, onChangeQuotationStatusByGM, quoteStatus, gmView, updateManualEntry, isManualEntry, submitted_at, businessInformation, updateVendorQuoteStatus, touchedChildTabs, onChangeTouchedChildTab, currentActiveTab, reviewAndSubmit, onChangeReviewAndSubmit, onChangeVendorQuotation, updateActiveProgressItem, vendorQuote, onHandleSelectedTab, selectedTab , activeProgressItem }) =>  {
  const [tabs, setTabs] = useState([])
  const [childTabOptions, setChildTabOptions] = useState([]) 
  const [showModal, dismissModal] = useModals()
  const [businessTabData, setBusinessTabData]  = useState(businessInformation || {})

  // Get current Selected tab info
  const { serviceName,  description, heading } = selectedTab || {}
  // Get current selected tab child services
  const currentservice = vendorQuote || {}
  const { services: childServices, id } = currentservice[serviceName] || {}

  // Get Business Info Data
  const { status } = businessInformation || {}

  // Get complete tabs list
  const { completed } = activeProgressItem || {}

  // Update Business Data
  const onChangeBusinessTabData = (data) => [
    setBusinessTabData(data)
  ]

  // Validate Business Data 
  const ValidateBusinessTabData = () => {
    // Desctructure Business Info
    const { address_line_1, phone, tax_id, term, country, city, state, zip } = businessTabData || {}
    // Check Business data to disable or enable save button 
    return (!address_line_1  || !phone || !tax_id || !term || !country || !city || !state || !zip  )   
  }

  // Format Tab data for parent tabs
  useEffect(() => {
    if((services || []).length !== 0) {
      formatTabOptions()
    }
  }, [services])

  // Update Tab position on Review and Submit Edit option
  useEffect(() => {
    const { prevTab } = reviewAndSubmit || {}
    let data = getCurrentTabData(prevTab)
    onHandleSelectedTab(data)
  },[reviewAndSubmit])

  // Create child tabs
  useEffect(() => {
    const tabOptions = Object.keys( childServices || [])
    setChildTabOptions(tabOptions)
  }, [childServices])

  // Pass Current Tab data to parent component
  const getParentTabContent = (data) => {
    onHandleSelectedTab(data)
  }

  // Get current parent data from quotation
  const getCurrentTabData = (key) => {
    let data = {}
    if ([key] || '' in vendorQuote || []) {
      data = vendorQuote[key] || {}
    }
    return data || {}
  }

  // Format Tabs
  const formatTabOptions = () => {
    const tabOptions = (services || []).map((service, index) => { 
      let data = getCurrentTabData(service)
      return {
        label: service,
        id: service.replace(' ', '_').toLowerCase(),
        overrideActiveBackground: '#3DB3E2',
        active: (index ===  0) ? true : false,
        onClick: value => getParentTabContent(data),
      }
    })
    setTabs(tabOptions)
  }

  // For New Section
  const goToNextSection = () => {
    let keys = Object.keys(vendorQuote || {})
    let nextIndex = keys.indexOf(serviceName || '') +1
    let nextItem = keys[nextIndex]
    let data = getCurrentTabData(nextItem)
    onHandleSelectedTab(data)
  }


  const getAllTabsId = (tabs || []).map(tab => tab.id)
  const checkCompletedTabs = () => {
    let allExists = false
    for(var i = 0; i < getAllTabsId.length; i++){
      if( (completed || []).includes( getAllTabsId[i] )){
        allExists = true
      } else {
        allExists = false
        return
      }
    }
    return allExists
  }

  // Get input values (cost, capacity) from child tab
  const  getInputValues = (arr) => {
    // Flat sub array's in array
    const flatData =  [].concat.apply([], (arr || []))
    return (flatData || []).flatMap(
      (data) => {
        const { inputType } = data || {}
        const { cost, capacity } = inputType || {}
        const { value: costValue } = cost || {}
        const { value: capacityValue } = capacity || {}
        let newData = [] 
        if(costValue !== null) {
          newData.push(costValue)
        } if(capacityValue !== null) {
          newData.push(capacityValue)
        }
        return newData
      }
    )
  }

  // Get child services tab data
  const checkSubServicesValues = () => {
    const cServices = Object.keys(childServices || {}) || []
    if((cServices || []).length !== 0) {
      let data = [];
      (cServices || []).forEach((key,i) => {
        data.push( childServices[key])
      })
      return getInputValues(data || [])
    }
  }

  // Check input field data on child tab to enable or disable service
  const checkSaveButtonDisabledState = () => {
    if(heading === 'Business Information') {
      return  ValidateBusinessTabData()
    } else {
      const getInputValues = checkSubServicesValues() || []
      const unqualifiedValues = ['',0,'0','0.00']
      const checkInputValues = (getInputValues || []).filter((val) => {
        if((unqualifiedValues || []).includes(val)) {
          return true
        }
        // To check if value starts from 0
        if(val && val.toString().startsWith('00')) {
          return true
        }
      })
      return (checkInputValues || []).length !== 0
    }
  }

  // Confirmation Modal for action buttons
  const onHandleConfirmationModal = ({msg, submitAction, dismissLabel, modalTitle, submitLabel, subMsg, additionalSubmitButtonClasses}) => {
    showModal(
      { width: '480px' },
      <ConfirmationModal
        msg={msg}
        submit={submitAction}
        dismissLabel={dismissLabel}
        modalTitle={modalTitle}
        submitLabel={submitLabel}
        subMsg={subMsg}
        additionalSubmitButtonClasses={additionalSubmitButtonClasses}
        dismiss={dismissModal}
      />
    )
  }

  // Get Save and Next Button For all Tabs
  const actionButtons = () => {
    return <div className="action-items">
      <button className="button is-outlined is-primary"
        disabled={ checkSaveButtonDisabledState() }
        onClick={(e) => {
          e.preventDefault()
          // Update Progress Item list with current service as completed
          updateActiveProgressItem([...completed, id], 'completed')
          if(heading !== 'Business Information') {
            goToNextSection()
          }
          // Change status to started
          if(status === null || status === 'not-started') {
            updateVendorQuoteStatus('started')
          }
        }}>
      Save
      </button>
      <button
        className="button is-success"
        type="button"
        disabled={ heading === 'Business Information' && !checkCompletedTabs()}
        onClick={(e) => {
          e.preventDefault()
          if(heading === 'Business Information') {
          // Change Review and submit to active
            onChangeReviewAndSubmit(true, serviceName)
            // Change Business Info to completed in progress item list
            updateActiveProgressItem([...completed, id], 'completed')
            // Set review and submit tab to active in progress item list
            updateActiveProgressItem( 'review_submit', 'active')
          } else {
          // Next Section
            goToNextSection()
          }
        }}
      >
        {heading !== 'Business Information' ? 'Next Section' : 'Review'}
      </button>
    </div>
  }

  // Conditional Rendering Action Buttons
  const getActionItemsButton = (gmView, submitted_at) => {
    if(gmView) {
      if(quoteStatus === 'approved' || quoteStatus === 'rejected' || quoteStatus === 'closed') {
        return
      }
      if(submitted_at) {
        return (
          <PermissionGate name={'rfp-vendor-actions'}>
            <div className="action-items">
              <button className="button is-outlined is-primary" onClick={() => onHandleConfirmationModal({
                msg : 'You’re about to reject this proposal. Once you confirm, the vendor will be notified and this proposal will be moved to the “Rejected” status.',
                dismissLabel:'Cancel',
                modalTitle:'Confirm Rejection',
                submitLabel:'Yes, Reject',
                subMsg:'Would you like to reject this proposal?',
                additionalSubmitButtonClasses:'is-danger',
                submitAction:() => {
                  onChangeQuotationStatusByGM('rejected_at')
                  dismissModal()
                }
              })}>
                Reject
              </button>
              <button className="button is-primary" onClick={() => onHandleConfirmationModal({
                msg : 'You’re one click away from approving this proposal! Once you confirm, the vendor will be notified.',
                dismissLabel:'Cancel',
                modalTitle:'Confirm Approval',
                submitLabel:'Yes, Approve',
                subMsg:'Would you like to approve this proposal?',
                submitAction:() => {
                  onChangeQuotationStatusByGM('approved_at')
                  dismissModal()
                }
              })}>
                Approve
              </button>
            </div>
          </PermissionGate>
        )
      }
      if(isManualEntry) {
        return actionButtons()
      }
      return (
        <PermissionGate name={'rfp-vendor-actions'}>
          <div>
            <button className="button main-button is-primary manual-button" onClick={() => onHandleConfirmationModal({
              msg : 'Manual entry mode allows you to enter information for this RFP on behalf of the specified vendor. This should only be done if the vendor cannot access their online portal to complete the forms themself.',
              dismissLabel:'Cancel',
              modalTitle:'Confirm',
              submitLabel:'Yes',
              subMsg:'Would you like to continue?',
              submitAction:() => {
                updateManualEntry(true)
                dismissModal()
              }
            })}>
            Manual Entry
            </button>
          </div>
        </PermissionGate>
      )
    } else {
      if(!submitted_at) {
        return actionButtons()
      }
    }
  }

  return (
    <div className="service-tabs-wrapper">
      <div className="service-tabs-header">
        { (tabs || []).length > 0 && <VendorTabOptions isEdit={ isEdit } type= "parent" currentActiveTab={ currentActiveTab } tabs={ tabs }/> }
      </div>
      { (tabs || []).length > 0 && <div className="service-tabs-body">
        <div className="service-detail-header">
          <h2>
            { heading || ''}
          </h2>
          { getActionItemsButton(gmView, submitted_at) }
        </div>
        {isEdit && <p>
          { description || ''}
        </p>}
        { heading !== 'Business Information' ? <div className="service-detail-body">
          <PricingTabs currentActiveTab={ currentActiveTab } selectedTab={selectedTab} gmView={ gmView } isEdit={ isEdit } id={ id } touchedChildTabs={ touchedChildTabs } activeProgressItem= { activeProgressItem } updateActiveProgressItem={ updateActiveProgressItem } serviceName={ serviceName } onChangeTouchedChildTab={ onChangeTouchedChildTab } key={ id } childServices={ childServices } onChangeVendorQuotation= { onChangeVendorQuotation } childTabOptions={ childTabOptions }  />
        </div> : <BusinessInformation businessTabData={ businessTabData }  onChangeBusinessTabData={ onChangeBusinessTabData } isEdit={ isEdit } id={ id } activeProgressItem= { activeProgressItem } updateActiveProgressItem={ updateActiveProgressItem } />}
      </div>}
    </div>
  )
}


VendorServiceComponent.propTypes = {
  services: PropTypes.array,
  businessInformation: PropTypes.object,
  touchedChildTabs: PropTypes.object,
  onChangeTouchedChildTab: PropTypes.func,
  updateVendorQuoteStatus: PropTypes.func,
  onChangeReviewAndSubmit: PropTypes.func,
  onChangeVendorQuotation: PropTypes.func,
  updateActiveProgressItem: PropTypes.func,
  onChangeQuotationStatusByGM: PropTypes.func,
  currentActiveTab: PropTypes.string,
  reviewAndSubmit: PropTypes.object,
  vendorQuote: PropTypes.object,
  onHandleSelectedTab: PropTypes.func,
  updateManualEntry: PropTypes.func,
  selectedTab: PropTypes.object,
  activeProgressItem: PropTypes.object,
  isEdit: PropTypes.bool,
  gmView: PropTypes.bool,
  submitted_at: PropTypes.string,
  isManualEntry: PropTypes.bool,
  quoteStatus: PropTypes.string,
}

export default VendorServiceComponent

