import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from 'hooks'
import { getInspectServiceOptions, resetInspectServiceOptions, setInspectServiceOptions } from '../../modules/inspect'
import { closeModal } from '../../modules/modal'
import ChangeOption from './change-option'
import TurnableSpinner from '../../components/turnable-spinner'
import { close, addButton } from 'ui/icons'
import { Button } from 'ui'

const getServiceKey = key => {
  return key.replace(/[^a-zA-Z ]/g, '').split(' ').join('')
}

const ServiceOptionModal = ({ unitInfo, service }) => {
  const dispatch = useDispatch()
  const { user: { pdbid }, context } = useAppContext()
  const { inspect: { serviceOptionsIsRequesting, serviceOptions } } = useSelector(state => state)

  const [serviceOption, setServiceOption] = useState('')
  const [, setChangeOption] = useState(false)
  const [openedOption, setOpenedOption] = useState(-1)
  const [addOption, setAddOption] = useState(false)
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [tasksList, setTasksList] = useState([])

  useEffect(() => {
    dispatch(getInspectServiceOptions(pdbid, context, unitInfo.unit_space_id, getServiceKey(service.key)))
  }, [])

  useEffect(() => {

    if (!serviceOptionsIsRequesting && serviceOptions) {
      setLoading(false)

      const { turnboard_task: { services, tasks }} = serviceOptions
      setTasksList(() => {
        return tasks.map(task => ({
          ...task,
          label: services[0] ? services[0]['items'].find(item => item.item_option_id === task.item_option_id).item_option : ''
        }))
      })

      const currentTasksIds = tasks.map(task => task.item_option_id)

      setOptions(() => {
        return services[0] ? services[0]['items'].filter(task => !currentTasksIds.includes(task.item_option_id)) : []
      })

    }
  }, [serviceOptionsIsRequesting, serviceOptions])

  const addOptionHandler = () => {
    setServiceOption('')
    setAddOption(true)
    setChangeOption(false)
  }
  
  const changeOptionHandler = () => {
    setChangeOption(true)
    setAddOption(false)
  }
  
  const closeModalHandler = () => {
    dispatch(resetInspectServiceOptions())
    dispatch(closeModal())
  }
  
  const submitHandler = () => {
    const [optionId, taskId] = serviceOption

    const body = {
      task_id: addOption ? null : taskId,
      unit_id: unitInfo.unit_id,
      servicetype_id: service.servicetype_id,
      item_option_id: addOption ? serviceOption : optionId,
    }

    dispatch(setInspectServiceOptions(pdbid, context, unitInfo.unit_space_id, getServiceKey(service.key), body))
    dispatch(closeModal())
  }
  
  return loading ? (
    <div className="columns">
      <div className="column fadeIn has-text-centered p-t-md">
        <TurnableSpinner size="large" className="loading-spinner" />
      </div>
    </div>
  ) : (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="heading-3 is-pulled-left">Service Option</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModalHandler}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>
      <div className="columns m-b-none">
        <div className="column">
          <p className="has-text-weight-bold">{service.display}</p>
          <p>
            Unit {unitInfo.unit}{' '}
            {unitInfo.unit_space.toLowerCase() === 'common'
              ? 'Common Area'
              : `Bedroom ${unitInfo.unit_space}`}
          </p>
          {tasksList.map((task, index) => (
            <ChangeOption
              task={task}
              changeOptionHandler={changeOptionHandler}
              options={options}
              setOpenedOption={setOpenedOption}
              setParentServiceOption={setServiceOption}
              closed={addOption || openedOption !== index}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button
            type="white"
            size="content"
            className="p-l-none"
            onClick={() => addOptionHandler()}
            style={{ fontSize: 12 }}
            disabled={addOption}>
            <img
              src={addButton}
              className="m-r-sm"
              width="17px"
              alt="Add Option"
            />{' '}
            Add Option
          </Button>

          {addOption && (
            <>
              <p style={{ fontSize: 14 }}>Add Option to:</p>
              <select
                className="input is-fullwidth"
                value={serviceOption}
                onChange={e => {
                  setServiceOption(e.target.value)
                }}>
                <option value="">Select</option>
                {options.map(option => (
                  <option
                    key={option.item_option_id}
                    value={option.item_option_id}>
                    {option.item_option}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={closeModalHandler}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={submitHandler}>
            {addOption ? <span>Add</span> : <span>Change</span>}
          </button>
        </div>
      </div>
    </div>
  )
}

ServiceOptionModal.propTypes = {
  unitInfo: PropTypes.object,
  service: PropTypes.object
}

export default ServiceOptionModal
