import React from 'react'
import AlcarteTab from './AlcarteTab'
import FullUnit from './FullUnit'
import FullUnitType from './FullUnitType'
import PerSpace from './PerSpace'
import PerUnitAndSpace from './PerUnitAndSpace//index'

const TabContentContainer = props => {
  const { tabData } = props || {}
  if (tabData?.id === 1) {
    return <AlcarteTab {...props} />
  }
  if (tabData?.id === 2) {
    return <FullUnit {...props} />
  }
  if (tabData?.id === 3) {
    return <FullUnitType {...props} />
  }
  if (tabData?.id === 4) {
    return <PerSpace {...props} />
  }
  if (tabData?.id === 5) {
    return <PerUnitAndSpace {...props} />
  }
  return null
}

export default TabContentContainer
