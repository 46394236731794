import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { closeModal } from '../../modules/modal'
import { close } from 'ui/icons'

const UnsavedChangesModal = ({ backAction }) => {
  const dispatch = useDispatch()

  const closeModalHandler = () => dispatch(closeModal())

  const backActionHandler = () => {
    backAction()
    closeModalHandler()
  }
  
  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column">
          <p className="heading-3 is-pulled-left">Unsaved Changes</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModalHandler}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>
      <div className="div columns">
        <div className="column">
          <p className="schedule-all-modal__text">
            It looks like you made some changes that haven’t been saved yet and will be lost.
          </p>

          <p className="has-text-centered schedule-all-modal__text m-t-lg">
            Are you sure you want to leave this page?
          </p>
        </div>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={backActionHandler}>
            Leave Page
          </button>

          <button
            className="button main-button is-primary"
            onClick={closeModalHandler}>
            Stay on Page
          </button>
        </div>
      </div>
    </div>
  )
}

UnsavedChangesModal.propTypes = {
  backAction: PropTypes.func
}

export default UnsavedChangesModal
