import _ from 'lodash'
import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SetupTeamManageModalVendor from '../setup-team-manage-modal-vendor'
import SetupTeamManageModalInternal from '../setup-team-manage-modal-internal'
import { usePrevious } from '../../helpers/hooks'
import SetupBox from '../../components/setup-box'
import SetupListFields from '../../components/setup-list-fields'
import ConfirmationModal from '../../components/confirmation-modal'
import { icons } from '../setup-teams-main/icons'
import { animate } from '../../helpers'
import { getInnerDates } from '../../utils'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../../components/permission-gate'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import TurnToggle from '../../components/turn-toggle'
import CapacitySchedule from './CapacitySchedule'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { question, questionFilled } from 'ui/icons'
import SetupTeamManageModalDelete from '../setup-team-manage-modal-delete'
import { useModals } from 'hooks'
import './index.scss'

const DEFAULT_TEAM = 'new-team'
const SetupTeamManage = props => {
  const {
    setupTeams,
    context,
    location,
    user,
    history,
    openModal,
    setModalContent,
    closeModal,
    openTooltip,
    setTooltipContent,
    closeTooltip,
    setupOneTeamCreate,
    setupOneTeamDelete,
    setupOneTeamUpdate,
    setupOneTeamCapacityCreate,
    setupOneTeamCapacityUpdate
  } = props

  const [isEditingMain, setIsEditingMain] = useState(false)
  const [type, setType] = useState('none')
  const [teamService, setTeamService] = useState([])
  const [selectedEditIndex, setSelectedEditIndex] = useState('')
  const [staffNameError, setStaffNameError] = useState({ title: null })
  const [isTeamActive, setIsTeamActive] = useState(true)
  const [showModal, dismissModal] = useModals()

  const prevSetupTeams = usePrevious(setupTeams)
  const prevContext = usePrevious(context)

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)

    return {
      t: newParams.get('t') || DEFAULT_TEAM,
      edit: newParams.get('edit') === 'true' || false,
      modal: newParams.get('modal') === 'true' || false
    }
  }, [location])

  const { t, edit, modal: tModal } = params

  const getTeamById = id => {
    const { allTeams } = setupTeams || {}
    const { data } = allTeams || {}
    const getTeam = (data || []).filter(s => s.team_id === id)
    if ((getTeam || []).length !== 0) {
      return getTeam[0]
    }
    return null
  }

  const updateState = () => {
    if (t !== 'new-team') {
      const data = getTeamById(t) || {}
      const { services, team_type } = data || {}
      const updateServiceMeta = (services || []).map(s => ({
        servicetype: s?.service_name,
        servicetype_id: s?.service_id,
        servicetype_uri: s?.service_id,
        isPrevAdded: true
      }))
      setTeamService(updateServiceMeta || [])
      setType(team_type || '')
    } else {
      setIsEditingMain(true)
      setType('none')
      setTeamService([])
    }
  }

  useEffect(() => {
    if (t === 'new-team' || edit) {
      setIsEditingMain(true)
    } else {
      updateState()
    }

    if (t !== 'new-team' && tModal) {
      showMembersModal()
    }
  }, [])

  useEffect(() => {
    updateState()
  }, [t])

  useEffect(() => {
    const newId = setupTeams.oneTeam.data.id
    const oldId = _.get(prevSetupTeams, 'oneTeam.data.id', null)
    const idChanged =
      newId && oldId && newId !== oldId && newId !== 'new-staff' && newId !== ''
    const staffChanged = _.get(context, 'staff') !== _.get(prevContext, 'staff')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = staffChanged || projectChanged
    const { changeRequested } = context || {}
    if (changeRequested) {
      history.push('/setup/teams')
    }
    if (contextChanged) {
      updateState()
    }
    if (idChanged) {
      history.push(`/setup/teams-manage?t=${newId}`)
    }
  }, [setupTeams, context])

  const getMembers = () => {
    if (t === 'new-team') return null
    const { members, team_type } = getTeamById(t) || {}
    const opacity = isEditingMain ? 0.5 : 1
    const noun = team_type === 'Vendor' ? 'Vendor' : 'Team Members'

    const emptyTemplate = (
      <PermissionGate name={'manage-team-member'}>
        <RestrictedElement>
          <div
            className="box is-shadowless has-transition-opacity is-pointer"
            style={{ opacity, pointerEvents: opacity > 0.5 ? 'auto' : 'none' }}
            onClick={() => showMembersModal()}>
            <img
              src={icons.setupAdd}
              className="is-inline"
              style={{ marginTop: '-1px' }}
              alt="Add New Teams"
            />
            <p className="is-inline m-l-sm has-text-black">Add {noun}</p>
          </div>
        </RestrictedElement>
      </PermissionGate>
    )

    if ((members || []).length < 1) return emptyTemplate
    const membersTemplate = (members || []).map((m, i) => {
      const { vendor_name = '', staff_name = '', vendor_uri = '' } = m || {}
      const name = team_type === 'Vendor' ? vendor_name : staff_name
      const hidePencil = team_type !== 'Vendor' ? 'is-hidden' : ''
      return (
        <div
          className="columns is-desktop is-mobile m-none"
          key={`member-${i}`}>
          <div className="column">
            <p style={{ marginLeft: '60px' }}>{name || ''}</p>
          </div>
          <PermissionGate name={'manage-team-member'}>
            <RestrictedElement>
              <div className="column is-narrow">
                <img
                  src={icons.pencil}
                  className={`is-pointer ${hidePencil}`}
                  onClick={() => showMembersModal(vendor_uri)}
                  alt="Edit Team Member"
                />
                <img
                  src={icons.trash}
                  className="is-pointer m-l-sm"
                  onClick={() => {
                    showDeleteModal(m)
                  }}
                  alt="Edit Team Member"
                />
              </div>
            </RestrictedElement>
          </PermissionGate>
        </div>
      )
    })
    const hideAddTeam = team_type === 'Vendor' ? 'is-hidden' : ''
    return (
      <div
        className="box is-shadowless has-transition-opacity"
        style={{ opacity }}>
        <div className="columns is-desktop is-mobile m-none">
          <div className="column is-narrow p-b-none">
            <img alt="Service Icon" src={icons.setupTeams} />
          </div>
          <div className="column p-b-none">
            <p
              className="is-size-4 m-b-sm"
              style={{
                overflow: 'hidden',
                height: '36px'
              }}>
              Team Members
            </p>
          </div>
        </div>
        {membersTemplate}
        <PermissionGate name={'add-team-member'}>
          <RestrictedElement>
            <div className={`columns ${hideAddTeam}`}>
              <div className="column" style={{ paddingLeft: '80px' }}>
                <p
                  className="is-size-7 is-pointer is-unselectable"
                  onClick={() => showMembersModal()}>
                  <img
                    alt="Add Team Members"
                    src={icons.addBlack}
                    className="m-r-xs"
                  />
                  Add Team Members
                </p>
              </div>
            </div>
          </RestrictedElement>
        </PermissionGate>
      </div>
    )
  }

  const showDeleteTeamModal = () => {
    setModalContent(() => <SetupTeamManageModalDelete {...props} teamId={t} />)
    openModal({ width: '500px' })
  }

  const showDeleteModal = m => {
    const { pdbid } = user.user_metadata
    const { vendor_uri = '', staff_uri = '' } = m || {}
    let body = null
    if (vendor_uri) {
      body = { vendor_uri }
    } else if (staff_uri) {
      body = { staff_uri }
    } else {
      return
    }
    setModalContent(() => (
      <ConfirmationModal
        submit={() => {
          closeModal()
          setupOneTeamDelete(pdbid, context, t, body)
        }}
        dismiss={closeModal}
      />
    ))
    openModal({ width: '480px' })
  }

  const showMembersModal = vendor => {
    if (typeof vendor !== 'string') vendor = 'none'
    const { team_type, services } = getTeamById(t) || {}
    const Component =
      team_type === 'Vendor'
        ? SetupTeamManageModalVendor
        : SetupTeamManageModalInternal
    const defaults = {
      vendor,
      service: services || [],
      teamId: t || ''
    }
    setModalContent(() => <Component defaults={defaults} {...props} />)
    openModal({ width: '480px' })
  }

  const showTooltip = (e, showRemoveOption, parentIndex, editTeam) => {
    let { target } = e || {}
    const { assigned_services } = getTeamById(t) || {}
    const { pdbid } = user.user_metadata

    if (e.target.tagName === 'path') target = e.target.parentNode

    let template = (
      <PermissionGate name={'manage-team-capacity'}>
        <div>
          <div>
            <button
              className="options-button"
              onClick={() => {
                if (Object.keys(allCapacityData || {}).length !== 0) {
                  const data = { ...allCapacityData }
                  setTempData(data)
                }
                setSelectedEditIndex(parentIndex)
                showRemoveOption
                  ? setIsEditingMain(true)
                  : setIsEditingCapacity(true)
                closeTooltip()
              }}>
              <img
                src={icons.pencil}
                alt="edit information"
                className="p-r-sm"
              />
              Edit Information
            </button>
            {showRemoveOption && !d?.used && (
              <button
                className="options-button"
                onClick={e => {
                  e.preventDefault()
                  closeTooltip()
                  showDeleteTeamModal()
                  // onHandleDeleteModal({
                  //   msg:
                  //     'Once you delete the team, you will not be able to reverse the action. Are you sure you want to proceed?',
                  //   dismissLabel: 'Cancel',
                  //   modalTitle: 'Confirm',
                  //   submitLabel: 'Yes',
                  //   submitAction: () => {
                  //     closeTooltip()
                  //     dismissModal()
                  //   }
                  // })
                }}>
                <img src={icons.trash} alt="Delete Team" className="p-r-sm" />
                Delete Team
              </button>
            )}
          </div>
          {showRemoveOption && !assigned_services && !editTeam && (
            <div>
              <button
                className="options-button"
                onClick={() => {
                  closeTooltip()
                  setModalContent(() => (
                    <ConfirmationModal
                      submit={() => {
                        closeModal()
                        setupOneTeamDelete(pdbid, context, t)
                        history.push('/setup/teams')
                      }}
                      dismiss={closeModal}
                    />
                  ))
                  openModal({ width: '480px' })
                }}>
                <img src={icons.trash} alt="remove team" className="p-r-sm" />
                Remove Team
              </button>
            </div>
          )}
        </div>
      </PermissionGate>
    )

    setTooltipContent(template)
    const pos = target.getBoundingClientRect()
    const width = 160
    const height = 'auto'
    openTooltip(
      `${width}px`,
      height,
      `${pos.y + pos.height / 2 + pos.height}px`,
      `${pos.x + pos.width / 2 - width / 2}px`,
      e.target
    )
  }

  const { allServices } = setupTeams || {}
  const { data: allServicesData } = allServices || {}
  const data = t === 'new-team' ? {} : getTeamById(t) || {}
  const isNew = t === 'new-team'
  const { pdbid } = user.user_metadata
  const { simplifiedServices, team_type: currentTeamType, status, used } =
    data || {}
  const d = {
    servicetype_uri: simplifiedServices || 'No Services Found',
    team_type: currentTeamType || '',
    used,
    status
  }

  useEffect(() => {
    if (t === 'new-team') {
      setIsTeamActive(true)
    } else {
      const isTeamActive = d?.status === 'Active' ? true : false
      setIsTeamActive(isTeamActive)
    }
  }, [data])

  const defaultCapacities = {
    start_date: new Date().setHours(0, 0, 0, 0),
    end_date: new Date().setHours(0, 0, 0, 0),
    capacity: '0',
    service_id: ''
  }

  const { newcapacities = {}, services = [], team_type = '', team_name = '' } =
    data || {}

  // const [capacityData, setCapacityData] = useState(() => newcapacities && data['newcapacities'].length > 0 ? data['newcapacities'] : defaultCapacities)
  const [allCapacityData, setAllCapacityData] = useState(() =>
    newcapacities && Object.keys(newcapacities || {}).length > 0
      ? newcapacities
      : {}
  )
  const [isEditingCapacity, setIsEditingCapacity] = useState(
    newcapacities && !Object.keys(newcapacities || {}).length
  )
  const [capacityError, setCapacityError] = useState('')
  const [tempData, setTempData] = useState(() =>
    newcapacities && Object.keys(newcapacities || {}).length > 0
      ? newcapacities
      : {}
  )

  const handleCapacityChange = (parentIndex, itemIndex, itemInfo) => {
    const { currentKey, service_id } = itemInfo || {}
    allCapacityData[currentKey] = allCapacityData[currentKey].map((data, i) => {
      if (i === itemIndex) {
        data = {
          ...data,
          ...itemInfo
        }
      }
      data.servicetype_id = service_id
      return data
    })
    setCapacityError('')
  }

  const isValidCapacityData = capacities => {
    let valid = true
    capacities.forEach((capacity, index) => {
      const { end_date, start_date } = capacity || {}
      for (let i = index + 1; i < capacities.length; i++) {
        const innerDates = getInnerDates(
          new Date(capacities[i]['start_date']),
          new Date(capacities[i]['end_date'])
        ).map(date => date.getTime())
        // eslint-disable-next-line
        innerDates.forEach(innerDate => {
          if (innerDate >= start_date && innerDate <= end_date) {
            valid = false
            setCapacityError('Some dates are overlapped, please verify them')
            return
          }
        })
      }
    })
    return valid
  }

  const isValidServiceData = capacities => {
    let valid = true
    capacities.forEach((capacity, index) => {
      const { service_id, servicetype_id, capacity: currentCapacity } =
        capacity || {}
      for (let i = index; i < capacities.length; i++) {
        if (
          (service_id === undefined ||
            service_id === '' ||
            service_id === 'Select') &&
          (servicetype_id === undefined ||
            servicetype_id === '' ||
            servicetype_id === 'Select')
        ) {
          valid = false
          setCapacityError('Please select service for capacity schedule')
          return
        }
        if (
          currentCapacity === 0 ||
          currentCapacity === '' ||
          currentCapacity === '0' ||
          (currentCapacity || '').toString().startsWith('0')
        ) {
          valid = false
          setCapacityError('Please enter Max spaces for capacity schedule')
          return
        }
      }
    })
    return valid
  }

  console.log(allServicesData)

  return (
    <>
      <div className="columns animated fadeIn">
        <div className="column is-half">
          <SetupBox
            isValid={{ title: staffNameError.title ? false : true }}
            errorMessages={staffNameError}
            hasEllipsis={true}
            title={team_name || ''}
            titleCanEdit={true}
            name="edit-team"
            data={d}
            display={{
              servicetype_uri: 'Service(s)',
              team_type: 'Team Type',
              status: (
                <div className="info-container">
                  <span className="m-r-5">Status</span>
                  <Tippy
                    appendTo={() => document.body}
                    content={
                      <div className="content">
                        <p>
                          If this team is being used someplace, you will not be
                          able to delete them, You may set the team status to
                          Inactive which will prevent the team being used in
                          future. You can revert this change at any time by
                          changing the status back to Active.
                        </p>
                      </div>
                    }
                    placement="bottom-start"
                    allowHTML
                    theme="light">
                    <img
                      src={question}
                      alt="help"
                      onMouseOver={e => (e.currentTarget.src = questionFilled)}
                      onMouseLeave={e => (e.currentTarget.src = question)}
                    />
                  </Tippy>
                </div>
              )
            }}
            customFields={{
              servicetype_uri: () => {
                return (
                  <div
                    className="services-select is-fullwidth"
                    id="service-select">
                    <MultiSelectDropdown
                      displayKey="servicetype"
                      //isDisabled={!isNew}
                      value={teamService}
                      defaultValues={allServicesData || []}
                      placeholder="Select"
                      id="service-select"
                      onChange={v => {
                        setTeamService(v)
                      }}
                      customStyles={{
                        valueContainer: provided => ({
                          ...provided,
                          minHeight: '35px',
                          maxHeight: '200px',
                          overflow: 'auto',
                          position: 'relative'
                        }),
                        multiValueRemove: (style, state) => {
                          return {
                            ...style,
                            ...(state.data?.isPrevAdded
                              ? {
                                  visibility: 'hidden',
                                  width: '4px'
                                }
                              : {})
                          }
                        }
                      }}
                    />
                    <input
                      type="hidden"
                      name="services"
                      value={(teamService || [])
                        .filter(service => service?.isPrevAdded === undefined)
                        .map(s => s.servicetype_uri)
                        .join(',')}
                    />
                  </div>
                )
              },
              team_type: () => {
                return (
                  <div className="is-fullwidth">
                    <select
                      className="input"
                      value={type}
                      onChange={e => {
                        const { value } = e.target
                        setType(value)
                      }}
                      disabled={!isNew}
                      id="type-select">
                      <option value="none">Select</option>
                      <option>Internal QC</option>
                      <option>Internal Staff</option>
                      <option>Vendor</option>
                    </select>
                    <input type="hidden" name="team_type" value={type} />
                  </div>
                )
              },
              status: () => {
                return (
                  <div className="is-fullwidth team-manage-toggle">
                    <TurnToggle
                      isActive={isTeamActive}
                      disabled={t === 'new-team'}
                      onChange={state => setIsTeamActive(state)}
                    />
                  </div>
                )
              }
            }}
            isEditing={isEditingMain}
            hasTransitionOpacity={true}
            cancel={() => {
              setIsEditingMain(false)
              setStaffNameError({})
              const isTeamActive = d?.status === 'Active' ? true : false
              setIsTeamActive(isTeamActive)
              if (t === 'new-team') {
                history.push('/setup/teams')
              }
            }}
            titleChange={() => setStaffNameError({})}
            submit={(body, id) => {
              const { allTeams } = setupTeams
              body.status = isTeamActive === true ? null : 'false'
              body.services =
                (teamService || [])
                  .filter(service => service?.isPrevAdded === undefined)
                  .map(s => s.servicetype_uri)
                  .join(',') || null

              if (team_name !== body.name || t === 'new-staff') {
                const { data } = allTeams || {}
                if (
                  (data || []).length > 0 &&
                  (data || []).filter(
                    existingStaff => body?.name === existingStaff?.team_name
                  ).length > 0
                ) {
                  setStaffNameError({
                    title: 'This Team name is already taken'
                  })
                  return
                }
              }

              if (!body.name) {
                const el = document.getElementById(`setup-box-title-${id}`)
                el.focus()
                return animate(el, 'shake')
              } else if ((teamService || []).length === 0) {
                const el = document.getElementById('service-select')
                el.focus()
                return animate(el, 'shake')
              } else if (type === 'none') {
                const el = document.getElementById('type-select')
                el.focus()
                return animate(el, 'shake')
              } else {
                body.team_name = body.name
                delete body.name
              }
              if (t === 'new-team') {
                setupOneTeamCreate(pdbid, context, body)
              } else {
                setupOneTeamUpdate(pdbid, context, t, body)
              }

              history.push(`/setup/team-manage?t=${t}`)
              setIsEditingMain(false)
            }}
            ellipsisClick={e => showTooltip(e, true, '', true)}
            closeTooltip={closeTooltip}
          />
        </div>
        <div className="column is-half">{getMembers()}</div>
      </div>

      {t !== 'new-team' && (
        <div className="columns">
          <div className="column is-half">
            {type === 'Vendor' && (
              <>
                <CapacitySchedule
                  isEditing={isEditingCapacity}
                  hasEllipsis={true}
                  title="Capacity Schedule"
                  description="Use this section to specify how many spaces this team can work each day."
                  mainError={capacityError || ''}
                  closeTooltip={closeTooltip}
                  ellipsisClick={(e, parentIndex) =>
                    showTooltip(e, false, parentIndex, false)
                  }
                  services={services || []}
                  cancel={setSelectedService => {
                    delete tempData['New']
                    setSelectedService('')
                    setAllCapacityData(tempData || {})
                    setIsEditingCapacity(false)
                    setSelectedEditIndex('')
                  }}
                  submit={(key = '', newKeyId = '') => {
                    setCapacityError('')
                    if (
                      isValidCapacityData(allCapacityData[key]) &&
                      isValidServiceData(allCapacityData[key])
                    ) {
                      if (key === 'New') {
                        // Delete New service and copy its data to selected services
                        const newKey = (services || []).filter(
                          s => s.service_id === newKeyId
                        )
                        if ((newKey || []).length !== 0) {
                          allCapacityData[newKey[0].service_name || ''] =
                            allCapacityData['New']
                          delete allCapacityData['New']
                        }
                      }
                      setAllCapacityData(allCapacityData)
                      // Restructure data according to API payload
                      let reStructureData = []
                      for (key in allCapacityData || []) {
                        if (allCapacityData.hasOwnProperty(key)) {
                          const value = allCapacityData[key]
                          if ((value || []).length !== 0) {
                            ;(value || []).map(v => reStructureData.push(v))
                          }
                        }
                      }
                      // Change date hours and update payload attributes
                      const updateDataObject = (reStructureData || []).map(
                        item => {
                          const { servicetype_id, start_date, end_date } =
                            item || {}
                          if (
                            servicetype_id &&
                            servicetype_id !== undefined &&
                            servicetype_id !== ''
                          ) {
                            return {
                              ...item,
                              service_id: servicetype_id,
                              start_date: start_date,
                              end_date: end_date
                            }
                          }
                          return {
                            ...item,
                            start_date: start_date,
                            end_date: end_date
                          }
                        }
                      )
                      if (Object.keys(newcapacities || {}).length) {
                        setupOneTeamCapacityUpdate(pdbid, context, t, {
                          team: t,
                          capacities: updateDataObject || []
                        })
                      } else {
                        setupOneTeamCapacityCreate(pdbid, context, t, {
                          team: t,
                          capacities: updateDataObject || []
                        })
                      }
                      history.push(`/setup/team-manage?t=${t}`)
                      setIsEditingCapacity(false)
                      setSelectedEditIndex('')
                    }
                  }}
                  handleCapacityChange={handleCapacityChange}
                  allCapacityData={allCapacityData}
                  setAllCapacityData={setAllCapacityData}
                  selectedEditIndex={selectedEditIndex}
                />
                {(services || []).length >
                  Object.keys(allCapacityData || {}).length && (
                  <PermissionGate name={'add-team-capacity'}>
                    <RestrictedElement>
                      <div className="box is-shadowless has-transition-opacity setup-list-fields">
                        <div className="columns">
                          <div
                            className="column is-pointer"
                            onClick={() => {
                              allCapacityData['New'] = [defaultCapacities]
                              setAllCapacityData(allCapacityData)
                              setSelectedEditIndex(
                                Object.keys(allCapacityData || {}).length - 1
                              )
                            }}>
                            <img
                              alt="Add Capacity Schedule"
                              src={icons.setupAdd}
                              className="m-r-xs"
                            />
                            <p className="is-inline m-l-sm">
                              Add Capacity Schedule
                            </p>
                          </div>
                        </div>
                      </div>
                    </RestrictedElement>
                  </PermissionGate>
                )}
              </>
            )}
            <PermissionGate name={'add-team'}>
              <RestrictedElement>
                <Link
                  to="/setup/team-manage?t=new-team"
                  className={`${t === 'new-team' ? 'is-hidden' : ''}`}>
                  <img
                    src={icons.setupAdd}
                    className="is-inline"
                    style={{ marginTop: '-1px' }}
                    alt="Add New Teams"
                  />
                  <p className="is-inline m-l-sm has-text-black">Add Team</p>
                </Link>
              </RestrictedElement>
            </PermissionGate>
          </div>
        </div>
      )}
    </>
  )
}

SetupTeamManage.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupTeams: PropTypes.object,
  setupOneTeamGet: PropTypes.func,
  setTooltipContent: PropTypes.func,
  openTooltip: PropTypes.func,
  closeTooltip: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

export default SetupTeamManage
