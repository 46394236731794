import _ from 'lodash'
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import DataTable from '../../components/data-table'
import { getHeaderData, pivotConfig } from './header'
import BigLoading from '../../components/big-loading'
import { icons } from '../setup-teams-main/icons'
import RestrictedElement from '../../containers/restricted-element'
import { sectionManageable } from '../../security'
import PermissionGate from '../../components/permission-gate'
import { checkPermission } from '../../security'
import SetupTeamManageModalDelete from '../setup-team-manage-modal-delete'
import { capitalize } from '../../utils/helperFunctions'
import './index.scss'

const SetupTeamsList = props => {
  const {
    history,
    setupTeams,
    user,
    context,
    openModal,
    setModalContent
  } = props
  const [filterStr, setFilterStr] = useState('')
  const tableRef = useRef()

  const dropDownOptions = [
    {
      available: () => sectionManageable('/setup/team-manage', user),
      icon: icons.pencil,
      caption: 'Edit Team',
      action: row =>
        history.push(`/setup/team-manage?t=${row.team_id}&edit=true`)
    },
    {
      available: row =>
        sectionManageable('/setup/team-manage', user) && !row.used,
      icon: icons.trash,
      caption: 'Delete Team',
      action: row => {
        setModalContent(() => (
          <SetupTeamManageModalDelete {...props} teamId={row.team_id} />
        ))
        openModal({ width: '500px' })
      }
    }
  ]

  const showUserDropdownOptions = () => {
    if (checkPermission('manage-team', user)) {
      return dropDownOptions
    }

    return []
  }

  const getLoadingState = () => <BigLoading />

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={icons.emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">
          Looks like you're the first one here!
        </p>
        <PermissionGate name={'add-team'}>
          <RestrictedElement>
            <p className="has-text-grey is-size-7 m-t-sm">
              Let's get started by adding one of your teams.
            </p>
            <Link to="/setup/team-manage?t=new-team">
              <button className="button is-primary m-t-md">Add Team</button>
            </Link>
          </RestrictedElement>
        </PermissionGate>
      </div>
    </div>
  )

  const getFilteredTeams = () => {
    const { data } = setupTeams.allTeams
    let filtered =
      filterStr !== ''
        ? data.filter(function(d) {
            return (
              d.team_name.toUpperCase().indexOf(filterStr.toUpperCase()) !== -1
            )
          })
        : data
    return filtered
  }

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    const filterData = (tableRef?.current?.getRows() || []).map(list => {
      const {
        team_name,
        simplifiedServices,
        simplifiedMembers,
        team_type,
        status
      } = list || {}
      return {
        'Team Name': capitalize(team_name || ''),
        'Team Member(s)': `"${simplifiedMembers}"` || '',
        Service: `"${simplifiedServices}"` || '',
        Type: capitalize(team_type || ''),
        Status: status || ''
      }
    })

    const data = filterData
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-teams-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const { allTeams } = setupTeams

  if (!allTeams.hasMadeInitialRequest || allTeams.isRequesting) {
    return getLoadingState()
  }
  if (allTeams.data.length < 1) {
    return getEmptyState()
  }

  const filteredTeams = getFilteredTeams()

  return (
    <DataTable
      title="Teams"
      ref={tableRef}
      header={getHeaderData(_.get(setupTeams, 'allServices.data', []))}
      data={filteredTeams}
      textFilter={filterStr}
      onSetTextFilter={setFilterStr}
      onExport={exportCSV}
      pivotConfig={pivotConfig}
      iconClicked={() => {}}
      resetPage={false}
      hasCheckboxes={false}
      singleSelection
      buttonsSection={
        <PermissionGate name={'add-team'}>
          <div className="columns is-vcentered">
            <div className="column is-narrow">
              <RestrictedElement>
                <button
                  className="button is-success"
                  type="button"
                  onClick={() => history.push('/setup/team-manage?t=new-team')}>
                  Add Team
                </button>
              </RestrictedElement>
            </div>
          </div>
        </PermissionGate>
      }
      rowKey="team_id"
      rowOptions={showUserDropdownOptions()}
    />
  )
}

SetupTeamsList.propTypes = {
  user: PropTypes.object,
  setupTeams: PropTypes.object,
  setupAllTeamsGet: PropTypes.func
}

export default SetupTeamsList
