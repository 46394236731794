import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import VendorHeader from '../vendor-header'
import BigLoading from '../../components/big-loading'
import VendorUserHeader from '../../components/vendor-user-header'
import MobileWorkCard from '../../components/mobile-work-card'
import MobileEmptyState from '../../components/mobile-empty-state'
import MobileWorkTypeTitle from '../../components/mobile-work-type-title'
import { getMyWork } from '../../modules/vendor'
import { toggleSidebar } from '../../modules/sidebar'
import { noWork } from '../../ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import ColumnSorting from '../../components/app-sorting'
import './index.scss'

const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: 23,
    maxHeight: '2.5em',
    cursor: 'pointer',
    height: '2.5em',
    border: '1px solid #e5e9f2',
    borderRadius: 2,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #e5e9f2'
    },
    backgroundColor: state.isDisabled ? 'ghostwhite' : styles.backgroundColor
  }),
  container: styles => ({
    ...styles,
    marginTop: '-3px'
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '10px',
    marginBottom: '3px'
  }),
  indicatorContainer: styles => ({
    ...styles,
    paddingTop: '4px'
  })
}

const VendorWork = ({ vendor, user, context, getMyWork, toggleSidebar }) => {
  const history = useHistory()
  const [selectedUnit, setSelectedUnit] = useState('')
  const [selectedService, setSelectedService] = useState('')
  const [newOrders, setNewOrders] = useState([])
  const [sorting, setSorting] = useState({ key: '', ascending: true })

  const { jobs, isRequesting, property: propertyMap } = vendor

  const { pdbid, vendorid } = user.user_metadata
  const { project, property } = context

  const name = user.nickname || user.name || user.email

  useEffect(() => {
    if (project && property && pdbid && vendorid && !isRequesting) {
      getMyWork(pdbid, { project, property }, vendorid)
    }
  }, [context, user])

  // TODO: when a property that means back orders is decided, implement the backOrders filter
  const backOrders = []

  useEffect(() => {
    setNewOrders(jobs)
  }, [jobs])

  const handleClick = job => {
    history.push(`/vendor/job/${job.workorder}`, { job, property: propertyMap })
  }

  useEffect(() => {
    let defaultNewOrders = jobs || []
    if (selectedUnit?.unit && selectedService?.service) {
      defaultNewOrders = (defaultNewOrders || []).filter(
        o =>
          o.unit === selectedUnit?.unit &&
          o.service === selectedService?.service
      )
    } else if (selectedUnit?.unit || selectedService?.service) {
      defaultNewOrders = (defaultNewOrders || []).filter(
        o =>
          o.unit === selectedUnit?.unit ||
          o.service === selectedService?.service
      )
    } else {
      defaultNewOrders = defaultNewOrders || []
    }
    setNewOrders(defaultNewOrders)
  }, [selectedUnit, selectedService])

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending })
  }

  useEffect(() => {
    if (sorting?.key) {
      if (sorting?.ascending) {
        const myData = []
          .concat(newOrders || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() <
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewOrders(myData)
      } else {
        const myData = []
          .concat(newOrders || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() >
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewOrders(myData)
      }
    }
  }, [sorting])

  return (
    <div className="vendor-work is-fullheight">
      <VendorHeader
        title="My Work"
        toggleSidebar={toggleSidebar}
        refreshAction={() => {
          getMyWork(pdbid, { project, property }, vendorid)
          setSelectedUnit('')
          setSelectedService('')
          setSorting({ key: '', ascending: false })
          setNewOrders(jobs)
        }}
      />

      <div className="vendor-work-content">
        {isRequesting ? (
          <BigLoading style={{ marginTop: 0 }} />
        ) : (
          <div>
            {backOrders.length > 0 || newOrders.length > 0 ? (
              <div className="vendor-work-header">
                <VendorUserHeader
                  title={`Hey, ${name}!`}
                  message="Here are your current work orders."
                />
              </div>
            ) : null}

            {newOrders.length ? (
              <div className="columns is-mobile p-t-sm">
                <div className="column is-half">
                  <div className="columns">
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Unit
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="unit"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="unit"
                        value={selectedUnit}
                        defaultValues={newOrders}
                        onChange={v => setSelectedUnit(v)}
                        isClearable={true}
                        isMulti={false}
                        placeholder="Select Unit"
                        noOptionsMessage="No Unit Available"
                        customStyles={selectStyles}
                      />
                    </div>
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Service
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="service"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="service"
                        value={selectedService}
                        isClearable={true}
                        defaultValues={_.uniqBy(
                          newOrders || [],
                          obj => obj?.service
                        )}
                        onChange={v => setSelectedService(v)}
                        isMulti={false}
                        placeholder="Select Service"
                        noOptionsMessage="No Service Available"
                        customStyles={selectStyles}
                      />
                    </div>
                  </div>
                </div>
                {(selectedService?.service ||
                  selectedUnit?.unit ||
                  sorting?.key) && (
                  <div className="column is-half">
                    <label
                      htmlFor="clear"
                      style={{ color: 'white' }}
                      className="is-size-5">
                      Clear
                    </label>
                    <div>
                      <button
                        style={{ marginTop: '-3px' }}
                        onClick={() => {
                          setSelectedUnit('')
                          setSelectedService('')
                          setSorting({ key: '', ascending: false })
                          setNewOrders(jobs)
                        }}
                        className="button is-primary">
                        Clear All Filter(s)
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {backOrders.length ? (
              <>
                <MobileWorkTypeTitle title="GO-BACKS" />
                <div className="columns is-mobile is-multiline">
                  {backOrders.map(order => (
                    <MobileWorkCard
                      order={order}
                      handleClick={handleClick}
                      key={order.workorder}
                    />
                  ))}
                </div>
              </>
            ) : null}

            {newOrders.length ? (
              <>
                <MobileWorkTypeTitle title="NEW" />
                <div className="columns is-mobile is-multiline">
                  {newOrders.map(order => (
                    <MobileWorkCard
                      order={order}
                      handleClick={handleClick}
                      key={order.workorder}
                    />
                  ))}
                </div>
              </>
            ) : null}

            {backOrders.length === 0 && newOrders.length === 0 ? (
              <div className="vendor-work-empty">
                <MobileEmptyState
                  image={noWork}
                  title="You’re all caught up."
                  subtitle="All work orders assigned to you will be listed here."
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

VendorWork.propTypes = {
  vendor: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  getMyWork: PropTypes.object,
  toggleSidebar: PropTypes.func
}

const mapStateToProps = ({ user, context, vendor }) => ({
  vendor,
  user,
  context
})

const mapDispatchToProps = {
  getMyWork,
  toggleSidebar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorWork)
