import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import propertiesListMock from '../mocks/properties_list.mock'

/**
 * Setup properties (all) data module
 * @redux
 * @reduxActionScope setup-properties-all
 * @module setup-properties-all
 */

/**
 * Request all properties list
 * @type {Redux.ActionType}
 */
export const GET_REQUESTED = 'setup-properties-all/GET_REQUESTED'
/**
 * All properties list failed
 * @type {Redux.ActionType}
 */
export const GET_FAIL = 'setup-properties-all/GET_FAIL'
/**
 * Update requested properties list with successful data from API
 * @type {Redux.ActionType}
 */
export const GET_SUCCESS = 'setup-properties-all/GET_SUCCESS'

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: []
}

/**
 * Setup properties (all) reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case GET_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case GET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all available properties in the project from the context
 * @redux
 * @reduxActionCreator GET_REQUESTED, GET_SUCCESS, GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupAllPropertiesGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: GET_REQUESTED
    })
    Service.setupAllPropertiesGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          const data = propertiesListMock
          dispatch({
            type: GET_SUCCESS,
            data: _allPropertiesAdapter(data.properties)
          })
        } else {
          _setupAllPropertiesGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllPropertiesGetFail(dispatch)
      })
  }
}

const _allPropertiesAdapter = data =>
  _.map(data, d => _onePropertySummaryAdapter(d))

const DEFAULT_VALUE = ''

const _onePropertySummaryAdapter = property => ({
  id: typeof property.id === 'string' ? property.id : DEFAULT_VALUE,
  name: typeof property.name === 'string' ? property.name : DEFAULT_VALUE,
  buildings:
    property.buildings && Array.isArray(property.buildings)
      ? _.map(property.buildings, building => ({
        building_id:
            typeof building.building_id === 'string'
              ? building.building_id
              : DEFAULT_VALUE,
        building_name:
            typeof building.building_name === 'string'
              ? building.building_name
              : DEFAULT_VALUE,
        num_beds:
            typeof building.num_beds === 'string'
              ? parseInt(building.num_beds)
              : 0
      }))
      : [],
  units:
    property.units && Array.isArray(property.units)
      ? _.map(property.units, unit => ({
        unit_id:
            typeof unit.unit_id === 'string' ? unit.unit_id : DEFAULT_VALUE,
        building_id:
            typeof unit.building_id === 'string'
              ? unit.building_id
              : DEFAULT_VALUE
      }))
      : []
})

const _setupAllPropertiesGetFail = dispatch => {
  dispatch({
    type: GET_FAIL
  })
}
