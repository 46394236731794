import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { currencyFormatter } from 'utils'
import { useDetectClickOutside } from 'react-detect-click-outside'
import cn from 'classnames'
import EditField from '../../edit-field'
import { ConfirmDeleteLine } from 'components/invoice'
import RestrictedElement from '../../../containers/restricted-element'
import { useModals } from 'hooks'
import { trash } from 'icons'
import { Button } from 'ui'
import './index.scss'
import { isEmpty } from 'lodash'

const InvoiceItem = ({
  isNew,
  item,
  onDescriptionChanged,
  onRateChanged,
  onQuantityChanged,
  onDelete,
  editing,
  currentInvoice,
  setCurrentInvoice
}) => {
  const [showModal] = useModals()
  const [, setOptionsExpanded] = useState(false)
  const [additionalChargesExpanded, setAdditionalChargesExpanded] = useState(
    false
  )

  const confirmDelete = () =>
    showModal({ widht: '480px' }, <ConfirmDeleteLine deleteAction={onDelete} />)

  const closeDropdown = () => setOptionsExpanded(false)
  const firstColumnRef = useDetectClickOutside({ onTriggered: closeDropdown })

  const descriptionObj = !!item.item

  const editingAditionalFields = useMemo(
    () => descriptionObj === null && editing,
    [descriptionObj, editing]
  )

  const columnClass = useCallback(
    ({ first = false, last = false, centered = true } = {}) =>
      cn(
        'invoice-item__td',
        { 'has-text-centered': centered },
        { 'invoice-item__td--first': first },
        { 'invoice-item__td--last': last },
        { 'invoice-item__td--first-input': editingAditionalFields && first },
        { 'invoice-item__td--with-input': editingAditionalFields }
      ),
    [editingAditionalFields]
  )

  return (
    <tr className="invoice-item">
      <td
        ref={firstColumnRef}
        className={columnClass({ first: true, centered: false })}>
        {additionalChargesExpanded && <div className="asterisk">*</div>}
        <div className="invoice-item__margin-title">
          {!editing ? (
            item.item
          ) : (
            <EditField
              id="description"
              label="Description"
              locked={false}
              active={false}
              value={item.item}
              placeholder={'Enter Item Name'}
              className="invoice-item__edit"
              onChange={value => {
                if (item.item !== value) {
                  onDescriptionChanged(value)
                }
              }}
              onFocus={() => {
                if (
                  isNew &&
                  (item.item === '' || item.item === 'Enter Item Name')
                ) {
                  setOptionsExpanded(true)
                }
              }}
              onKeyStroke={value => {
                const isAditionalCharges = value
                  .toLowerCase()
                  .includes('additional charges')
                setOptionsExpanded(isNew && value === '')
                setAdditionalChargesExpanded(isAditionalCharges)
              }}
            />
          )}
        </div>
      </td>
      <td className={columnClass()}>
        {!editing ? (
          currencyFormatter(item.rate, { disableRounding: false })
        ) : (
          <EditField
            id="rate"
            label="Rate"
            locked={isNew && item.description === ''}
            active={false}
            filter="^([0-9]+(\.?[0-9]?[0-9]?)?)$"
            value={item.rate || '0'}
            defaultValue="0"
            className="has-text-centered"
            onChange={value => {
              onRateChanged(value || 0)
            }}
          />
        )}
      </td>
      <td className={columnClass()}>
        {!editing ? (
          item.quantity
        ) : (
          <EditField
            id="quantity"
            label="Quantity"
            locked={isNew && item.description === ''}
            active={false}
            filter="^[0-9]+$"
            value={item.quantity || '1'}
            defaultValue="1"
            className="has-text-centered"
            onChange={value => {
              // console.log({ value, he: item.quantity !== value })
              if (item.quantity !== value) {
                onQuantityChanged(value || 1)
              }
            }}
          />
        )}
      </td>
      <td className={columnClass()}>
        {currencyFormatter(item.amount || 0, { disableRounding: false })}
      </td>
      <td className={columnClass({ last: true })}>
        <span
          className={cn('invoice-item__actions', {
            'invoice-item__actions--show': editing
          })}>
          <RestrictedElement>
            <Button type="white" size="content" onClick={confirmDelete}>
              <img src={trash} alt="Remove Item" />
            </Button>
          </RestrictedElement>
        </span>
      </td>
    </tr>
  )
}

export default InvoiceItem
