import React from 'react'

const AccessDenied = () => (
  <div className="has-text-centered p-l-md p-r-md animated fadeInDown faster">
    <h1 className="m-t-xl">Access Denied!</h1>
    <p className="subtitle is-4 m-t-md has-text-centered">
      You don't have the proper permissions for this experience. <br />
      Please contact support if you think this is a mistake.
    </p>
  </div>
)
export default AccessDenied
