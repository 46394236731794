import Service from '../service'
import LOGOUT from './auth'

/**
 * Request RFP data module
 * @redux
 * @reduxActionScope request-rfp
 * @module request-rfp
 */

/**
 * Request all RFP list
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_RFP_GET_REQUESTED =
  'request-rfp/REQUEST_ALL_RFP_GET_REQUESTED'
/**
 * All RFP list request failed
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_RFP_GET_FAIL = 'request-rfp/REQUEST_ALL_RFP_GET_FAIL'
/**
 * Update requested RFP list with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_RFP_GET_SUCCESS =
  'request-rfp/REQUEST_ALL_RFP_GET_SUCCESS'

/**
 * Request RFP title
 * @type {Redux.ActionType}
 */
export const REQUEST_RFP_TITLE_GET_REQUESTED =
  'request-rfp/REQUEST_RFP_TITLE_GET_REQUESTED'
/**
 * RFP title request failed
 * @type {Redux.ActionType}
 */
export const REQUEST_RFP_TITLE_GET_FAIL = 'request-rfp/REQUEST_RFP_TITLE_GET_FAIL'
/**
 * Update requested RFP title with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_RFP_TITLE_GET_SUCCESS =
  'request-rfp/REQUEST_RFP_TITLE_GET_SUCCESS'

export const UPDATE_RFP_LIST = 'UPDATE_RFP_LIST'

export const RFP_LIST_STATUS_UPDATE = 'RFP_LIST_STATUS_UPDATE'




const initialState = {
  allRFP: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  allRFPTitle: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },

}

/**
 * Request RFP reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_RFP_GET_REQUESTED:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_RFP_TITLE_GET_REQUESTED:
      return {
        ...state,
        allRFPTitle: {
          ...state.allRFPTitle,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_ALL_RFP_GET_SUCCESS:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case REQUEST_RFP_TITLE_GET_SUCCESS:
      return {
        ...state,
        allRFPTitle: {
          ...state.allRFPTitle,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case UPDATE_RFP_LIST: {
      const { data: newRFPRequest } = action || {}
      const { allRFP } = state || {}
      const { data: allRFPs } = allRFP || {}
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          data: [newRFPRequest, ...allRFPs ],
        }
      }
    } 
    case RFP_LIST_STATUS_UPDATE: {
      const { data: rfpRequest } = action || {}
      const { rfp_request_id } = rfpRequest || {}
      const { allRFP } = state || {}
      const { data: list } = allRFP || {}

      const newList = (list || []).map((item) => {
        const { rfp_request_id: id } = item || {}
        if(id === rfp_request_id) {
          return {
            ...item,
            ...rfpRequest
          }
        }
        return item
      })

      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          data: newList,
        }
      }
    
      // return state
    }
    case REQUEST_ALL_RFP_GET_FAIL:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          isRequesting: false,
          isError: true
        }
      }
    case REQUEST_RFP_TITLE_GET_FAIL:
      return {
        ...state,
        allRFPTitle: {
          ...state.allRFPTitle,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all RFP members
 * @redux
 * @reduxActionCreator REQUEST_ALL_RFP_GET_REQUESTED, REQUEST_ALL_RFP_GET_SUCCESS, REQUEST_ALL_RFP_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const allRequestRFPGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: REQUEST_ALL_RFP_GET_REQUESTED
    })
    Service.allRequestRFPGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_ALL_RFP_GET_SUCCESS,
            data: _allRequestRFPAdapter(data)
          })
        } else {
          _allRequestRFPFail(dispatch)
        }
      })
      .catch(() => {
        _allRequestRFPFail(dispatch)
      })
  }
}

export const allRFPTitleGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: REQUEST_RFP_TITLE_GET_REQUESTED
    })
    Service.allRFPTitleGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const uniqueData = [...new Map((data || []).map(item =>
            [item['property_id'], item])).values()]
          dispatch({
            type: REQUEST_RFP_TITLE_GET_SUCCESS,
            data: uniqueData
          })
        } else {
          dispatch({
            type: REQUEST_RFP_TITLE_GET_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: REQUEST_RFP_TITLE_GET_FAIL
        })
      })
  }
}

export const addRFPRequest = ({ pdbid, context, body, onSuccess, onError }) => {
  return dispatch => {
    Service.addRFPRequest(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if(onSuccess) {
            onSuccess()
          }
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }
}

export const checkRFPVendorExists = ({pdbid, context, body}) => (dispatch) => {
  return new Promise(function(resolve, reject) {
    Service.checkRFPVendorExists(pdbid, context, body)
      .then(async res => {
        const data =  res.json()
        await resolve(data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const declineRFPRequest = ({ pdbid, context, body, onSuccess, onError, requestId}) => {
  console.log({
    pdbid,
    context
  })
  return dispatch => {
    Service.declineVendorRequest(pdbid, context, body, requestId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: RFP_LIST_STATUS_UPDATE,
            data: _requestRFPAdapter(data)
          })
          if(onSuccess) {
            onSuccess(data)
          }
          return
        } 

        if(onError) {
          onError('Something went wrong.')
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }

}

const _allRequestRFPFail = dispatch => {
  dispatch({
    type: REQUEST_ALL_RFP_GET_FAIL
  })
}

const upatedStatusText = (status) => {
  if(status !== '') {
    if(status === 'approved') {
      return 'Approved'
    } if(status === 'rejected') {
      return 'Closed'
    } if(status === 'closed') {
      return 'Closed'
    } if(status === 'rescinded') {
      return 'Rescinded'
    }
    return 'Not Started'
  }
  return 'Not Started'
}

const getSimplifiedServices = (values) => {
  const simplifiedValue = (values || []).flatMap((service,i) => {
    let newValue=[]
    const { name } = service
    newValue.push(name)
    return newValue
  })
  return (simplifiedValue || []).join(', ')
}

const getSimplifiedFormName = (formName, VendorName) => {
  
  return `${formName || ''} - ${VendorName || ''}`
}

const NS = ''
const _requestRFPAdapter = data => {
  return {
    rfp_request_id: typeof data.rfp_request_id === 'string' ? data.rfp_request_id : NS,
    vendor_name: typeof data.vendor_name === 'string' ? data.vendor_name : NS,
    form_name: typeof data.form_name === 'string' ? data.form_name : NS,
    simplifiedRFPFormName: typeof data.form_name === 'string' ? getSimplifiedFormName(data.form_name, data.vendor_name) : NS,
    due_date: typeof data.due_date === 'string' ? data.due_date : NS,
    response: typeof data.response === 'string' ? data.response : NS,
    approval: typeof data.approval === 'string' ? data.approval : NS,
    status: typeof data.status === 'string' ? upatedStatusText(data.status.toLowerCase()) : 'Not Started',
    responseRecievedStatus: typeof data.status === 'string' ? data.status.toLowerCase() : 'Not Started',
    submitted_at: typeof data.submitted_at === 'string' ? data.submitted_at : NS,
    services: typeof data.services === 'object' ? data.services : [],
    simplifiedServices: typeof data.services === 'object' ? getSimplifiedServices(data.services) : [],
    created_at: data.created_at,
    vendor_email: data.vendor_email || ''
  }
}

const _allRequestRFPAdapter = data => {
  const arrayUniqueByKey = [...new Map((data || []).map(item =>
    [item['rfp_request_id'], item])).values()]
  return arrayUniqueByKey.map(d => _requestRFPAdapter(d))
}
