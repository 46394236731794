import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { icons } from './icons'
// For delete to be done in next release
import DeleteTagMenu from './deleteMenu'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTurnboardUnitTags, addTurnboardUnitTag, addTurnboardAllTags, getTurnboardAllTags, deleteTurnboardAllTags, deleteTurnboardUnitTag, updateTurnboardDataByState } from '../../modules/turnboard'
import BasicLoading from '../../components/basic-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import './index.scss'

const ALLNEWTAGCOLORS = ['#122048', '#0081C3', '#55C2B8', '#806DC7', '#FF9D52', '#F1576D']

const TurnboardAllTagsModal = (props) => {
  const { onClose, unit, turnboard, getTurnboardUnitTags, user, context, unit_id, addTurnboardUnitTag, deleteTurnboardAllTags, getTurnboardAllTags, addTurnboardAllTags, deleteTurnboardUnitTag, updateTurnboardDataByState } = props || {}
  const { allTags, unitTags, data: turnboardData, activePage } = turnboard || {}
  const { units } = turnboardData || {}
  // Icons
  const { close, closeFilled, tagClose } = icons || {}

  // State
  const [errorMessage, setErrorMessage] = useState('')
  const [newTag, setNewTag] = useState('')
  const [newTagColor, setNewTagColor] = useState('')
  const [availableTag, setAvailableTag] = useState([])
  const [selectedTag, setSelectedTag] = useState([])
  const [isHoverAvailableTag, setIsHoverAvailableTag] = useState('')
  const [isHoverSelectedTag, setIsHoverSelectedTag] = useState('')
  const [isUnitTagsLoading, setIsUnitTagsLoading] = useState(false)
  const [isAvailableTagsLoading, setIsAvailableTagsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const [userUpdatedData, setUserUpdatedData] = useState([])

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  useEffect(() => {
    getTurnboardUnitTagsData()
  }, [unit_id])

  const resetAvailableTag = (unitTag) => {
    const getSelectedTagID = (unitTag || []).map(tag => tag.tag_id)
    const filterTags = (allTags || []).filter(tag => !(getSelectedTagID || []).includes(tag.tag_id))
    setAvailableTag(filterTags)
  }

  // Set all unit level tags
  useEffect(() => {
    setSelectedTag(unitTags)
    resetAvailableTag(unitTags)
  }, [unitTags])

  // Set all turnboard tags
  useEffect(() => {
    setAvailableTag(allTags)
  }, [allTags])

  // Get unit level tags data
  const getTurnboardUnitTagsData = async () => {
    setIsUnitTagsLoading(true)
    await getTurnboardUnitTags({
      pdbid,
      context,
      id: unit_id,
      onSuccess: () => {
        setIsUnitTagsLoading(false)
      },
      onError: () => {
        setIsUnitTagsLoading(false)
      }
    })
  }

  const stringToLower = (text = '') => {
    return (text || '').toString().trim().toLowerCase()
  }

  // Get all turnboard tags
  const getAllTurnboardTags = async () => {
    setIsAvailableTagsLoading(true)
    await getTurnboardAllTags({
      pdbid,
      context,
      onSuccess: () => {
        setErrorMessage('')
        setIsAvailableTagsLoading(false)
        setNewTagColor('')
        setNewTag('')
      },
      onError: () => {
        setErrorMessage('Something went wrong, Please try again !!!')
        setIsAvailableTagsLoading(false)
      }
    })
  }

  const addAvailableTag = () => {
    setErrorMessage('')
    if ((availableTag || []).filter(avaTag => stringToLower(avaTag?.text) === stringToLower(newTag)).length) {
      setErrorMessage('A tag by this name already exists.')
    } else {
      setIsAvailableTagsLoading(true)
      const body = {
        text: newTag || '',
        color: newTagColor || ''
      }
      // Add turnboard level tag
      addTurnboardAllTags({
        pdbid,
        context,
        body,
        onSuccess: () => {
          getAllTurnboardTags()
        },
        onError: () => {
          setErrorMessage('Something went wrong, Please try again !!!')
          setIsAvailableTagsLoading(false)
        }
      })
    }
  }

  const addUnitSelectedTag = async (currentTag) => {
    const { text, color, tag_id } = currentTag || {}
    setErrorMessage('')
    if ((selectedTag || []).filter(selTag => selTag.text === text).length) {
      setErrorMessage('This tag is already assigned to the unit.')
    } else {
      let tags = []
      const body = {
        text: text || '',
        color: color || '',
        tag_id,
        new: true,
        unit_id,
        unit,
        deleted: null
      }
      if ((selectedTag || []).length !== 0) {
        tags = [...selectedTag, body]
      } else {
        tags = [body]
      }
      setSelectedTag(tags)
      resetAvailableTag(tags)
      const checkIfTagAlreadyExists = (userUpdatedData || []).findIndex(tag => tag.tag_id === tag_id)
      if(checkIfTagAlreadyExists !== -1) {
        let data = userUpdatedData || []
        data[checkIfTagAlreadyExists] = {
          ...currentTag,
          deleted: null,
          prevExists : true
        }
        setUserUpdatedData(data)
      } else {
        setUserUpdatedData(prevTags => [...prevTags, body])
      }
      
    }
  }

  const resetDashboardTagsData = () => {
    const updateUnits = (units || []).map(unitData => {
      const { unit_id : unitId } = unitData || {}
      if (unitId === unit_id) {
        return {
          ...unitData,
          tags: selectedTag || [],
          simplifiedTags: ((selectedTag || []).map(d => d.text) || []).join(', '),
        }
      }
      return {
        ...unitData
      }
    })
    updateTurnboardDataByState({
      data: {
        page: activePage,
        units: updateUnits || []
      }
    })
  }

  const onSave = async () => {
    const filterTag = (userUpdatedData || []).filter(tag => !tag?.prevExists)
    if((filterTag || []).length !== 0) {
      setIsSaving(true)
      const body = {
        items: filterTag || []
      }
      await addTurnboardUnitTag({
        pdbid,
        context,
        id: filterTag[0]?.tag_id,
        body,
        onSuccess: () => {
          setIsSaving(false)
          setErrorMessage('')
          resetDashboardTagsData()
          onClose()
        },
        onError: () => {
          setIsSaving(false)
          setErrorMessage('Something went wrong, Please try again !!!')
        }
      })
    }
  }

  // For delete to be done in next release
  // const updateAllTurnboardState = (tagId) => {
  //   const filterTag = (allTags || []).filter(tag => tag.tag_id !== tagId)
  //   setAvailableTag(filterTag)
  // }

  const updateSelectedTagState = (tag, i) => {
    const getFilteredTags = (selectedTag || []).filter(selTag => selTag.text !== tag.text)
    setIsTouched(true)
    setSelectedTag(getFilteredTags)
    resetAvailableTag(getFilteredTags)
    setUserUpdatedData(prevTags => [...prevTags, {
      ...tag,
      deleted: 'true'
    }])
  }

  // For delete to be done in next release
  // const deleteAvailableTagsProps = {
  //   setErrorMessage,
  //   deleteTurnboardAllTags,
  //   pdbid,
  //   context,
  //   updateAllTurnboardState
  // }

  // For delete to be done in next release
  // const deleteSelectedTagsProps = {
  //   setErrorMessage,
  //   deleteTurnboardUnitTag,
  //   pdbid,
  //   context,
  //   updateSelectedTagState
  // }

  // For border color of the pill
  const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16)

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')'
    }
  }

  const onCloseTagModal = () => {
    // if(isTouched) {
    //   resetDashboardTagsData()
    // }
    onClose()
  }

  return <div className="turnboard-all-tags-modal">
    <div className="is-desktop is-mobile">
      <div className="is-full">
        <h2 className="modal-sync-title">Tags</h2>
        <p
          className={`close is-pointer has-text-grey-light ${isAvailableTagsLoading && 'is-disabled'}`}
          onClick={() => onCloseTagModal()}>
          <img
            alt="Close Modal"
            src={close}
          />
        </p>
      </div>
    </div>
    <div className="is-full">
      <h4>{`Unit ${unit || ''}`}</h4>
      {
        errorMessage !== '' && <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}><img src={closeFilled} alt="" /></button>
        </div>
      }
      <div className="selected-tags-container">
        <h5>Selected Tags</h5>
        {isUnitTagsLoading ? <BasicLoading /> : <div className="pill-container">
          {(selectedTag || []).length !== 0 ? (selectedTag || []).map((selTag, i) => {
            const { color, text } = selTag || {}
            let defaultColor = {
              backgroundColor: color || '#000000',
              boxShadow: isHoverSelectedTag === i && '0px 2px 4px 0 #C1C5C9'
            }
            return <p onMouseEnter={() => setIsHoverSelectedTag(i)}
              onMouseLeave={() => setIsHoverSelectedTag('')} className="pill" style={defaultColor} key={i}><span>{text || 'NULL'}</span>
              {isHoverSelectedTag === i && <img src={tagClose} alt="close-icon" onClick={() => updateSelectedTagState(selTag, i)} />}
              {/* // For delete to be done in next release */}
              {/* {isHoverSelectedTag === i && <DeleteTagMenu type="Selected" unitId={unit_id} tagId={tag_id} {...deleteSelectedTagsProps} />} */}
            </p>
          }) : <p className="no-tags">There are no tags assigned to this unit.</p>}
        </div>}
      </div>
      <div className="available-tags-container">
        <h5>Available Tags</h5>
        <div className="pill-container">
          {(availableTag || []).length !== 0 ? (availableTag || []).map((avaTag, i) => {
            const { color, text } = avaTag || {}
            let defaultColor = {
              backgroundColor: color || '#000000',
              boxShadow: isHoverAvailableTag === i && '0px 2px 4px 0 #C1C5C9'
            }
            // To show delete icon on hover in selected tag
            return <p onMouseEnter={() => setIsHoverAvailableTag(i)}
              onMouseLeave={() => setIsHoverAvailableTag('')}
              className="pill" style={defaultColor} key={i}><span onClick={() => addUnitSelectedTag(avaTag)}>{text || 'NULL'}</span>
              {/* // For delete to be done in next release */}
              {/* {isHoverAvailableTag === i && <DeleteTagMenu type="Available" tagId={tag_id} {...deleteAvailableTagsProps} />} */}
            </p>
          }) : <p className="no-tags">There are no available tags. Please create them from below.</p>}
        </div>
      </div>
      <div className="new-tags-container">
        <h5>Create New Tag</h5>
        <div className="main-container">
          <div className="input-container">
            <input className="new-tag-input" value={newTag} onChange={(e) => {
              const { target } = e || {}
              const { value } = target || {}
              setNewTag(value)
              setNewTagColor('')
              setErrorMessage('')
            }} placeholder="Type to create..." type="text" disabled={isAvailableTagsLoading} />
            <p>Color:</p>
            {(ALLNEWTAGCOLORS || []).map(tagColor => {
              let defaultColor = {
                backgroundColor: tagColor,
                opacity: newTag ? 1 : 0.2,
                cursor: newTag && 'pointer',
                border: (newTagColor === tagColor) && `2px solid ${hexToRGB('#eeeeee', 0.5)}`
              }
              return <span className={`${isAvailableTagsLoading ? 'is-disabled' : ''} ${(newTagColor === tagColor) ? 'is-selected-color' : ''}`} onClick={() => (newTag && !isAvailableTagsLoading) && setNewTagColor(tagColor)} key={tagColor} style={defaultColor}></span>
            })}
          </div>
          {(newTag && newTagColor) && <div className="action-container is-disabled">
            <p className={isAvailableTagsLoading ? 'is-disabled' : ''} onClick={() => {
              setNewTag('')
              setErrorMessage('')
              setNewTagColor('')
            }}>Cancel</p>
            <button
              disabled={isAvailableTagsLoading}
              className="button is-primary"
              onClick={() => addAvailableTag()}>
              {isAvailableTagsLoading ? 'Creating' : 'Create'}
              {isAvailableTagsLoading && <FontAwesomeIcon icon={faSpinner} spin color="##ffffff" className="m-l-sm" />}
            </button>
          </div>}
        </div>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            disabled={isAvailableTagsLoading || isSaving}
            className="button main-button is-secondary m-r-md"
            onClick={() => onCloseTagModal()}>
            Cancel
          </button>
          <button
            disabled={isAvailableTagsLoading || (userUpdatedData || []).filter(tag => !tag?.prevExists).length === 0 || isSaving}
            className="button main-button is-primary"
            onClick={() => onSave()}>
            {isSaving ? 'Saving' : 'Save'}
            {isSaving && <FontAwesomeIcon icon={faSpinner} spin color="#ffffff" className="m-l-sm" />}
          </button>
        </div>
      </div>
    </div>
  </div>
}

TurnboardAllTagsModal.propTypes = {
  onClose: PropTypes.func,
}

const mapStateToProps = ({ turnboard, user, context }) => ({
  turnboard,
  user,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTurnboardUnitTags,
      addTurnboardUnitTag,
      addTurnboardAllTags,
      getTurnboardAllTags,
      deleteTurnboardAllTags,
      deleteTurnboardUnitTag,
      updateTurnboardDataByState
    },
    dispatch
  )

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TurnboardAllTagsModal)


