import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import get from 'lodash/get'
import find from 'lodash/find'
import { REASONS } from './reasons'
import { close, closeFilled } from 'ui/icons'
import {
  calendarConfig,
  parseDate,
  formatDate
} from '../../utils/calendarInputHelper'

const emptyReason = 'no-reason'
const emptyTeam = 'no-team'

const ServicesModalReassign = ({
  closeModal,
  updateServices,
  context,
  user,
  services,
  rows,
  action,
  blockedUnitBody,
  unblockService,
  setChecked,
  setIsBlockUnitEnabled
}) => {
  const [teamSelected, setTeamSelected] = useState(emptyTeam)
  const [reassignmentReason, setReassignmentReason] = useState(emptyReason)
  const [reschedulingReason, setReschedulingReason] = useState(emptyReason)
  const [date, setDate] = useState(null)
  const [note, setNote] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const displayUnits = rows.map(r => r.unit)
  const property = find(
    get(user.user_metadata, 'properties', []),
    p => p.id === get(context, 'property')
  )

  const handleDayClick = day => {
    setErrorMessage('')
    setDate(day)
  }

  const resetModal = () => {
    setTeamSelected(emptyTeam)
    //setReassignmentReason(emptyReason)
    //setReschedulingReason(emptyReason)
  }

  const getReasonsOptions = () => {
    let template = []
    for (let i = 0; i < REASONS.length; i += 1) {
      let r = REASONS[i]
      template.push(
        <option key={r.key} value={r.key}>
          {r.display}
        </option>
      )
    }
    return template
  }

  const getTeamsOptions = () => {
    const filteredTeams = teams.filter(t => t.member !== rows.team)
    let template = []
    for (let i = 0; i < filteredTeams.length; i += 1) {
      const t = filteredTeams[i]
      template.push(
        <option key={`option-${t.member}`} value={t.uri}>
          {t.member}
        </option>
      )
    }
    return template
  }

  const { pdbid } = user?.user_metadata || {}
  const { teams } = services || {}
  const currentService = (services || {})?.currentService?.replace('/', '%20')
  let team = teams.find(t => t.uri === teamSelected)
  const operation_uris = rows.map(r => r.operation_uri).join(',')
  const submitDisabled = teamSelected === emptyTeam && !date

  //   &&
  //   reassignmentReason === emptyReason &&
  //   !date &&
  //   reschedulingReason === emptyReason) ||
  // (teamSelected !== emptyTeam && reassignmentReason === emptyReason) ||
  // (date && reschedulingReason === emptyReason) ||
  // (!date && teamSelected === emptyTeam)

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Reassign / Reschedule
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              closeModal()
              resetModal()
              //setChecked([])
              setIsBlockUnitEnabled(false)
            }}>
            <img alt="close modal" src={close} />
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-full m-t-none p-t-none">
          <p className="modal-sync-subtitle">Unit {displayUnits.join(' | ')}</p>
        </div>
      </div>
      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <p>{errorMessage}</p>
          <button onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns">
        <div className="column is-three-quarters">
          <p className="is-size-6">New team</p>
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              value={teamSelected}
              onChange={e => {
                setErrorMessage('')
                setTeamSelected(e.target.value)
              }}>
              <option value="no-team">Select Team</option>
              {getTeamsOptions()}
            </select>
          </div>
        </div>
      </div>
      {/* <div className="columns">
        <div className="column is-three-quarters">
          <p className="is-size-6">Reason for Reassignment</p>
          <div className="select is-fullwidth">
            <select
              className="input is-fullwidth"
              value={reassignmentReason}
              onChange={e => {
                setErrorMessage('')
                setReassignmentReason(e.target.value)
              }}
              disabled={teamSelected === emptyTeam}>
              <option value="no-reason">Select reason</option>
              {getReasonsOptions()}
            </select>
          </div>
        </div>
      </div> */}
      <>
        <div className="columns">
          <div className="column is-full">
            <p className="is-size-6">Schedule Date</p>
            <DayPickerInput
              onDayChange={handleDayClick}
              dayPickerProps={{
                modifiers: calendarConfig.modifiers,
                firstDayOfWeek: calendarConfig.firstDayOfWeek,
                weekdaysShort: calendarConfig.weekdaysShort
              }}
              formatDate={formatDate}
              format={calendarConfig.FORMAT}
              parseDate={parseDate}
              placeholder={`${dateFnsFormat(
                new Date(),
                calendarConfig.FORMAT
              )}`}
            />
          </div>
        </div>
        {/* <div className="columns">
          <div className="column is-three-quarters">
            <p className="is-size-6">Reason for Rescheduling</p>
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                value={reschedulingReason}
                onChange={e => {
                  setErrorMessage('')
                  setReschedulingReason(e.target.value)
                }}
                disabled={!date}>
                <option value="no-reason">Select reason</option>
                {getReasonsOptions()}
              </select>
            </div>
          </div>
        </div> */}
        <div className="columns">
          <div className="column is-three-quarters">
            <p className="is-size-6">Note</p>
            <div className="is-fullwidth">
              <textarea
                className="textarea m-t-sm m-b-40"
                rows={4}
                style={{ resize: 'none' }}
                onChange={e => setNote(e.target.value)}
              />
            </div>
          </div>
        </div>
      </>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              closeModal()
              resetModal()
              setIsBlockUnitEnabled(false)
            }}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={() => {
              const body = {
                operation_uri: operation_uris,
                team_uri: rows[0].team_uri,
                new_team_uri: teamSelected !== emptyTeam ? team?.uri : null,
                // reassignment_reason:
                //   reassignmentReason !== emptyReason
                //     ? reassignmentReason
                //     : null,
                reschedule_date: date ? date.toISOString() : null,
                // rescheduling_reason:
                //   reschedulingReason !== emptyReason ? reschedulingReason : null
                reason: note?.trim()
              }

              updateServices(
                pdbid,
                context,
                currentService,
                action,
                body,
                () => {},
                () => {},
                false
              )
              closeModal(true)

              // if (action === 'Reassign') {
              //   if (blockedUnitBody?.operation_uri !== '') {
              //     unblockService(
              //       pdbid,
              //       context,
              //       currentService,
              //       blockedUnitBody,
              //       status => {
              //         if (status) {
              //           setErrorMessage('')
              //           updateServices(
              //             pdbid,
              //             context,
              //             currentService,
              //             action,
              //             body
              //           )
              //           closeModal(true)
              //         }
              //       },
              //       status => {
              //         setErrorMessage(
              //           'Something went wrong. Please try again later !!!'
              //         )
              //       }
              //     )
              //   } else {
              //     updateServices(pdbid, context, currentService, action, body)
              //     closeModal(true)
              //   }
              // } else {
              //   updateServices(pdbid, context, currentService, action, body)
              //   closeModal(true)
              // }
            }}
            disabled={submitDisabled ? 'disabled' : ''}>
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

ServicesModalReassign.propTypes = {
  closeModal: PropTypes.func,
  updateServices: PropTypes.func,
  user: PropTypes.object,
  services: PropTypes.object,
  action: PropTypes.string,
  rows: PropTypes.array,
  modal: PropTypes.object
}

export default ServicesModalReassign
