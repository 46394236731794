import _ from 'lodash'
import Short from 'short-uuid'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

/**
 * Component for showing the paginator for a DataTable component
 *
 * @component
 * @example
 * return (
 *   <Paginator
 *      count={300}
 *      limit={25}
 *      page={1}
 *      action={page => console.log(page)}
 *   />
 * )
 */

const Paginator = ({
  page,
  action,
  count,
  limit,
  className,
  limitOptions,
  onLimitChange,
  elementName
}) => {
  useEffect(() => {
    action(page)
  }, [page, action])

  const getTotalPages = () => Math.ceil(count / limit)

  const getPaginationList = () => {
    let template = []
    let totalPages = getTotalPages()

    if (totalPages > 6) {
      let start = page - 1
      if (page === 1 || page === 2) {
        start = 2
      } else if (page === totalPages) {
        start = page - 3
      } else if (page === totalPages - 1) {
        start = page - 2
      }

      template.push(
        <ul key={0} className="pagination-list">
          <li>
            <button
              className={`pagination-link is-pointer ${
                page === 1 ? 'is-current' : ''
              }`}
              aria-label="Goto page 1"
              onClick={() => {
                action(1)
              }}>
              1
            </button>
          </li>
          {page > 3 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
          {page < totalPages - 1 && (
            <li>
              <button
                className={`pagination-link is-pointer ${
                  page === start ? 'is-current' : ''
                }`}
                aria-label={`Goto page ${start}`}
                onClick={() => {
                  action(start)
                }}>
                {start}
              </button>
            </li>
          )}
          <li>
            <button
              className={`pagination-link is-pointer ${
                page === start + 1 ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${start + 1}`}
              onClick={() => {
                action(start + 1)
              }}>
              {start + 1}
            </button>
          </li>
          {page > 2 && (
            <li>
              <button
                className={`pagination-link is-pointer ${
                  page === start + 2 ? 'is-current' : ''
                }`}
                aria-label={`Goto page ${start + 2}`}
                onClick={() => {
                  action(start + 2)
                }}>
                {start + 2}
              </button>
            </li>
          )}
          {page < totalPages - 2 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}

          <li>
            <button
              className={`pagination-link is-pointer ${
                page === totalPages ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${totalPages}`}
              onClick={() => {
                action(totalPages)
              }}>
              {totalPages}
            </button>
          </li>
        </ul>
      )
    } else {
      for (let i = 1; i <= totalPages; i += 1) {
        template.push(
          <li key={i}>
            <button
              className={`pagination-link is-pointer ${
                page === i ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${i}`}
              onClick={() => {
                action(i)
              }}>
              {i}
            </button>
          </li>
        )
      }
    }

    return template
  }

  const previousClicked = () => {
    if (page > 1) action(page - 1)
  }

  const nextClicked = () => {
    let totalPages = getTotalPages()
    if (page < totalPages) action(page + 1)
  }

  let pageLimit = getTotalPages() - 1
  // let display =
  //   pageLimit < 1 && !limitOptions.length
  //     ? { display: 'none' }
  //     : { display: '' }
  let cn = className || ''
  const start = 1 + (page - 1) * limit
  const end = Math.min(page * limit, count)
  const options = _.orderBy(limitOptions)
  return (
    <div className={`paginator-container ${cn}`}>
      {options.length > 0 && options[0] < count && (
        <div className="column counter-section">
          <label style={{ marginLeft: '3px', marginRight: '6px' }}>
            {elementName} per page
          </label>
          <div className="select" style={{ marginTop: '-6px' }}>
            <select
              className="input is-inline"
              value={limit}
              onChange={e =>
                onLimitChange(
                  parseInt(e.target.value),
                  Math.ceil(start / parseInt(e.target.value))
                )
              }>
              {_.map(_.filter(options, option => option < count), option => (
                <option key={Short.uuid()} value={option}>
                  {option}
                </option>
              ))}
              {/* {count < options[options.length - 1] && ( */}
              <option key={Short.uuid()} value={count}>
                All
              </option>
              {/* )} */}
            </select>
          </div>
        </div>
      )}
      <div className="columns is-vcentered">
        <div className="column">
          <p className="p-l-md counter-section">
            Showing {start} to {end} of {count} entries
          </p>
        </div>
        {pageLimit >= 1 && (
          <div className="column is-narrow">
            <nav
              className="pagination is-centered"
              role="navigation"
              aria-label="pagination">
              <button
                className="pagination-previous is-pointer"
                onClick={() => previousClicked()}
                disabled={page < 2}>
                Previous
              </button>
              <ul className="pagination-list">{getPaginationList()}</ul>
              <button
                className="pagination-next is-pointer"
                onClick={() => nextClicked()}
                disabled={page > pageLimit}>
                Next page
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  )
}

Paginator.propTypes = {
  /**
   * Total number of rows to paginate
   */
  count: PropTypes.number.isRequired,
  /**
   * Page size
   */
  limit: PropTypes.number.isRequired,
  /**
   * Current page number, starting from 1
   */
  page: PropTypes.number.isRequired,
  /**
   * Event handler for page change action
   */
  action: PropTypes.func.isRequired,
  /**
   * Additional class name for paginator main DIV
   */
  className: PropTypes.string,
  /**
   * Event handler for page size change action
   */
  onLimitChange: PropTypes.func,
  /**
   * Array with all possible page size values
   */
  limitOptions: PropTypes.array,
  /**
   * Name of the element paged, to include in page size options dropdown
   */
  elementName: PropTypes.string
}

Paginator.defaultProps = {
  className: '',
  limitOptions: [],
  onLimitChange: () => {},
  elementName: 'Rows'
}

export default Paginator
