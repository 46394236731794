import React from 'react'

const TurnboardCellComponent = ({ row, serviceName }) => {
  const { cell, state, row: selectedRow } = row || {}
  const { id } = selectedRow || {}
  const { selectedRowIds } = state || {}
  const { value } = cell || {}

  return (
    <>
      <div
        className="cell-item"
        title={value}
        style={{
          backgroundColor: Object.keys(selectedRowIds).includes(id)
            ? '#55C2B8'
            : null
        }}>
        <span>{value}</span>
      </div>
    </>
  )
}

export default TurnboardCellComponent
