import React from 'react'
import Short from 'short-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FIRST_STYLE } from './constants'
import { getColumnStyle } from './helpers'
import { icons } from './icons'
import { getColumnWrapper } from './helpers'

const TableHead = ({
  isLoading,
  columns,
  hasCheckboxes,
  singleSelection,
  pivotConfig,
  isChild,
  checkboxDisabled,
  allChecked,
  setAllChecked,
  headerRefs,
  setHeaderRef,
  getColWidth,
  activeSort,
  onSort,
  hasRowOptions,
  asyncActionOptionOnGoing
}) => {
  const getColumnSorted = col => {
    if (activeSort.indexOf(col) >= 0) return 1
    if (activeSort.indexOf(`-${col}`) >= 0) return -1
    return 0
  }

  const setSingleSort = column => {
    if (!column.notSortable) {
      if (getColumnSorted(column.key) === 1) {
        onSort([`-${column.key}`])
      } else {
        onSort([column.key])
      }
    }
  }

  let template = []

  if (isLoading) {
    return null
  }

  if (pivotConfig) {
    template.push(
      <td key={Short.uuid()} className="is-narrow no-wrap is-borderless" />
    )
  }

  if (hasCheckboxes)
    template.push(
      <td key={Short.uuid()} className="is-narrow no-wrap is-borderless">
        {!singleSelection && (
          <input
            type="checkbox"
            onChange={e => {
              setAllChecked(e)
            }}
            disabled={checkboxDisabled ? 'disabled' : ''}
            checked={allChecked}
            style={FIRST_STYLE}
          />
        )}
      </td>
    )

  const activeColumns = columns.filter(columnItem => !columnItem.hidden)

  for (let i = 0; i < activeColumns.length; i += 1) {
    const { textAlign } = activeColumns[i]
    const textDirection = textAlign || 'left'
    if (activeColumns[i].placeholder) {
      template.push(
        !isChild ? (
          <td
            className="is-narrow placeholder-column"
            key={Short.uuid()}
            style={getColumnStyle(activeColumns, i, isChild, {}, headerRefs)}
            ref={ref => setHeaderRef(i, ref)}
          />
        ) : (
          <div
            className="placeholder-column"
            style={getColWidth(activeColumns[i])}></div>
        )
      )
    } else {
      template.push(
        !isChild ? (
          <td
            className={`is-narrow no-wrap is-borderless p-r-md has-text-${textDirection} has-text-weight-semibold ${
              activeColumns[i].wide ? 'is-wide' : ''
            }`}
            key={`header-${activeColumns[i].key}`}
            ref={ref => setHeaderRef(i, ref)}
            style={getColumnStyle(activeColumns, i, isChild, {}, headerRefs)}
            onClick={() => setSingleSort(activeColumns[i])}>
            {getColumnWrapper(
              i,
              activeColumns[i].display,
              hasCheckboxes || pivotConfig
            )}
            {!activeColumns[i].notSortable && (
              <div
                style={{
                  verticalAlign: 'middle',
                  display: 'inline-block',
                  marginLeft: 5,
                  marginTop: -4
                }}>
                <img
                  style={{ display: 'block', marginTop: '3px' }}
                  src={
                    getColumnSorted(activeColumns[i].key) === 1
                      ? icons.caretUpOn
                      : icons.caretUpOff
                  }
                  alt="Sort in ascending order"
                />
                <img
                  style={{ display: 'block', marginTop: '-3px' }}
                  src={
                    getColumnSorted(activeColumns[i].key) === -1
                      ? icons.caretDownOn
                      : icons.caretDownOff
                  }
                  alt="Sort in descending order"
                />
              </div>
            )}
          </td>
        ) : (
          <div
            key={`header-${activeColumns[i].key}`}
            className="div-column"
            style={getColumnStyle(activeColumns, i, isChild, {}, headerRefs)}>
            {activeColumns[i].display}
          </div>
        )
      )
    }
  }
  if (hasRowOptions && !isChild) {
    template.push(
      <td
        className="is-narrow is-borderless"
        key={Short.uuid()}
        style={{ minWidth: '40px' }}
        ref={ref => setHeaderRef(columns.length, ref)}>
        {asyncActionOptionOnGoing && (
          <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
        )}
      </td>
    )
  }

  return !isChild ? (
    <tr
      style={{
        border: '1px solid #e5e9f2',
        borderBottom: 'none'
      }}>
      {template}
    </tr>
  ) : (
    <div className="row div-header">{template}</div>
  )
}

export default TableHead
