import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const VendorMessageHeader = ({ title, subtitle }) => {
  return (
    <>
      <div className="vendor-message-header-title">{title}</div>
      <div className="vendor-message-header-subtitle">{subtitle}</div>
    </>
  )
}

VendorMessageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default VendorMessageHeader
