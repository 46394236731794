import React from 'react'

const SetupUsersModalDelete = ({
  user,
  history,
  closeModal,
  setupOneUserDelete,
  setupOneUserBlock,
  userId
}) => {
  const { blocked } = user || {}
  const block=blocked ? 'reactivate' : 'block'
  
  return (
    <div>
      <p className="is-size-4 m-b-sm">You're about to {block} this user.</p>
      {/* <p className="is-size-6 m-b-md">
        This action cannot be undone and the user will need to be manually
        re-added if needed in the future.
      </p> */}
      <p className="is-size-4 has-text-link has-text-centered m-b-lg">
        Are you sure you want to {block} this user?
      </p>
      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <button
            type="button"
            className="button is-primary"
            style={{ width: 130 }}
            onClick={() => {
              closeModal()
            }}>
            No, Cancel
          </button>
        </div>
        <div className="column is-half">
          <button
            type="button"
            className="button is-danger"
            style={{ width: 130 }}
            onClick={() => {
              setupOneUserBlock(user)
              closeModal()
              if (history) {
                history.push('/setup/users')
              }
            }}>
            Yes, {block.charAt(0).toUpperCase() + block.slice(1)}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SetupUsersModalDelete
