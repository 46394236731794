import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import { getHoldoversList, resetHoldoversList } from '../../modules/holdovers'
import HoldoversMain from '../../parents/holdovers-main'
import BigLoading from '../../components/big-loading'

const Holdovers = props => {
  useEffect(() => {
    return () => {
      const { resetHoldoversList } = props
      resetHoldoversList()
    }
  }, [])

  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <HoldoversMain {...props} />
  }

  return <BigLoading />
}

Holdovers.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  holdovers: PropTypes.object,
  tooltip: PropTypes.object
}

const mapStateToProps = ({ user, context, holdovers, tooltip }) => ({
  user,
  context,
  holdovers,
  tooltip
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openTooltip,
      closeTooltip,
      setTooltipContent,
      setModalContent,
      openModal,
      closeModal,
      getHoldoversList,
      //getUnoccupiedList,
      resetHoldoversList
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Holdovers)
)
