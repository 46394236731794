import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import { omit, cloneDeep } from 'lodash'
import moment from 'moment'
import { useAppContext, useAppUser } from '../../../helpers/hooks'
import { duplicateCompanyTemplate } from '../../../modules/company-templates'
import { newTemplate } from '../templageConfig'
import { useHistory } from 'react-router'

const DuplicateTemplateModal = ({
  template = newTemplate,
  onSubmit,
  onConfirm = () => {},
  onDismiss = () => {}
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useAppUser()
  const {
    user: { pdbid },
    context
  } = useAppContext()

  const { duplicateIsRequesting, list } = useSelector(
    state => state.companyTemplates
  )

  const isNameExist = useCallback(
    (templateList, name) =>
      (templateList || []).some(template => template.name === name),
    []
  )

  const generatedTemplateName = useMemo(() => {
    let templateName = `${template.name} - Copy`.replace(/  +/g, ' ').trim()

    if (isNameExist(list, templateName)) {
      let randomNumber = 1

      while (isNameExist(list, `${templateName} (${randomNumber})`)) {
        randomNumber++
      }

      templateName = `${templateName} (${randomNumber})`
    }

    return templateName
  }, [template, isNameExist])

  const finalTemplateData = useMemo(() => {
    const templateData = omit(cloneDeep(template), [
      'template_name',
      'template_type',
      'shared',
      'usedSpaces'
    ])

    templateData.template_id = null
    templateData.name = generatedTemplateName
    templateData.type = templateData.type.trim()
    templateData.created_at = moment(new Date()).format('DD-MMM-YY')

    templateData.updated_at = null
    templateData.created_by = !templateData.created_by
      ? user.user_id
      : templateData.created_by

    templateData.status = null

    templateData.spaces = templateData?.spaces?.length
      ? templateData.spaces.map((space, spaceIndex) => ({
          space_id: space.space_id,
          sorting_order: `${spaceIndex + 1}`,
          template_space_id: null,
          do_delete_space: space.do_delete_space,
          required_on_property_forms: space.required_on_property_forms,
          items: space?.items?.length
            ? space.items.map((item, itemIndex) => ({
                item_id: item.item_id,
                sorting_order_item: `${itemIndex + 1}`,
                template_item_id: null,
                do_delete_item: item.do_delete_item,
                observations: item?.observations?.length
                  ? item.observations.map((observation, observationIndex) => ({
                      observation_id: observation.observation_id,
                      sorting_order_observation: `${observationIndex + 1}`,
                      template_observation_id: null,
                      do_delete_observation: observation.do_delete_observation,
                      solution_type: observation.solution_type,
                      media_required: observation.media_required,
                      media_minimum: observation.media_minimum,
                      solutions: observation?.solutions?.length
                        ? observation.solutions.map(
                            (solution, solutionIndex) => ({
                              solution_id: solution.solution_id,
                              sorting_order_solution: `${solutionIndex + 1}`,
                              template_solution_id: null,
                              do_delete_solution: solution.do_delete_solution
                            })
                          )
                        : [{ solution_id: null }]
                    }))
                  : [{ observation_id: null }]
              }))
            : [{ item_id: null }]
        }))
      : [{ space_id: null }]

    return templateData
  }, [template, generatedTemplateName])

  const handleSubmit = () => {
    const templateData = finalTemplateData
    onSubmit(true)

    const template_id = 0

    dispatch(
      duplicateCompanyTemplate(
        pdbid,
        context,
        templateData,
        template_id,
        template => {
          history.replace(
            `/inspections-setup/company-templates/manage?template=${template.template_id}`
          )
          onSubmit(false)
          onConfirm()
        },
        () => {
          onSubmit(false)
          onDismiss()
        }
      )
    )
  }

  return (
    <div className="duplicate-template-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Are you sure?</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onDismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">
            You're about to duplicate this template. Are you sure you want to
            duplicate?
          </p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={onDismiss}>
            No
          </button>
          <button
            className="button main-button is-primary"
            onClick={handleSubmit}>
            {duplicateIsRequesting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default DuplicateTemplateModal
