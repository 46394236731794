import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'
import sort from './icons/sort.svg'
import filter from './icons/filter.svg'
import exportCsv from './icons/export.svg'
import emptyPage from './icons/emptypage@2x.svg'
import setupAdd from './icons/setup-add.svg'
import trash from './icons/trash@2x.svg'
import dark from './icons/dark@2x.svg'
import close from './icons/close.svg'
import rescind from './icons/rescind.svg'

export const icons = {
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff,
  sort,
  filter,
  exportCsv,
  emptyPage,
  setupAdd,
  trash,
  dark,
  close,
  rescind
}
