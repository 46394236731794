import _ from 'lodash'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import GaugeChart from '../gauge-chart'
import { NON_VENDOR_SERVICES, SUMMARY_CARDS } from './constants'
import ComponentWithStatus from '../../components/component-with-status'

const COLUMNS = 3

const getStatsObject = (service, stat) =>
  _.find(
    _.get(
      _.find(SUMMARY_CARDS, c => c.short === service || c.id === service),
      'stats',
      []
    ),
    s => s.id === stat
  )

const getRowTitle = row =>
  _.get(
    _.find(SUMMARY_CARDS, c => c.id === _.get(row, 'label')),
    'label',
    _.get(row, 'label')
  )

const getRowData = row =>
  _.map(_.get(row, 'stats'), d => {
    const service = _.get(row, 'label')
    const statsObject = getStatsObject(service, d.id)
    return {
      category: d.id,
      value: d.value,
      label: _.get(statsObject, 'label', service),
      href: _.get(statsObject, 'href', null)
    }
  })

const generateRows = (data, loading, error) => {
  const template = []
  const maxRows =
    Math.floor(data.length / COLUMNS) + (data.length % COLUMNS === 0 ? 0 : 1)
  for (let currentRow = 0; currentRow < maxRows; currentRow++) {
    const columns = []
    let currentColumn = 0
    while (
      currentColumn < COLUMNS &&
      data[currentRow * COLUMNS + currentColumn]
    ) {
      columns.push(
        <div className="column is-one-third" key={Short.uuid()}>
          <GaugeChart
            loading={loading}
            error={error}
            title={getRowTitle(data[currentRow * COLUMNS + currentColumn])}
            data={getRowData(data[currentRow * COLUMNS + currentColumn])}
          />
        </div>
      )
      currentColumn++
    }
    template.push(
      <div className="columns is-vcentered" key={Short.uuid()}>
        {columns}
      </div>
    )
  }
  return template
}

/**
 * Services summary gauge charts boxes
 *
 * @component
 * @example
 * const summaryCards = [
 *   {
 *     "id": "Walk",
 *     "label": "Walk",
 *     "stats": [
 *       {
 *         "id": "Assign",
 *         "label": "Not Assigned",
 *         "value": 18,
 *         "href": "/services?s=Walk&p=Assign"
 *       },
 *       {
 *         "id": "Start",
 *         "label": "Assigned",
 *         "value": 5,
 *         "href": "/services?s=Walk&p=Start"
 *       },
 *       {
 *         "id": "InProgress",
 *         "label": "In Progress",
 *         "value": 1,
 *         "href": "/services?s=Walk&p=InProgress"
 *       },
 *       {
 *         "id": "AssignQC",
 *         "label": "Complete",
 *         "value": 0,
 *         "href": "/services?s=Walk&p=AssignQC"
 *       },
 *       {
 *         "id": "Approved",
 *         "label": "Approved",
 *         "value": 1,
 *         "href": "/services?s=Walk&p=Approved"
 *       }
 *     ]
 *   },
 *   {
 *     "id": "Paint",
 *     "label": "Paint",
 *     "stats": [
 *       {
 *         "id": "Assign",
 *         "label": "Not Assigned",
 *         "value": 30,
 *         "href": "/services?s=Paint&p=Assign"
 *       },
 *       {
 *         "id": "Start",
 *         "label": "Assigned",
 *         "value": 19,
 *         "href": "/services?s=Paint&p=Start"
 *       },
 *       {
 *         "id": "InProgress",
 *         "label": "In Progress",
 *         "value": 17,
 *         "href": "/services?s=Paint&p=InProgress"
 *       },
 *       {
 *         "id": "AssignQC",
 *         "label": "Complete",
 *         "value": 14,
 *         "href": "/services?s=Paint&p=AssignQC"
 *       },
 *       {
 *         "id": "Approved",
 *         "label": "Approved",
 *         "value": 55,
 *         "href": "/services?s=Paint&p=Approved"
 *       }
 *     ]
 *   }
 * ]
 * return (
 *   <ServicesCharts
 *     loading={false}
 *     error={false}
 *     summaryCards={summaryCards}
 *   />
 * )
 */
const ServicesCharts = ({ loading, error, summaryCards }) => {
  const nonVendorServices = useMemo(() => {
    return generateRows(
      _.filter(summaryCards, c => NON_VENDOR_SERVICES.indexOf(c.label) >= 0),
      loading,
      error
    )
  }, [summaryCards])

  const vendorServices = useMemo(
    () =>
      generateRows(
        _.filter(summaryCards, c => NON_VENDOR_SERVICES.indexOf(c.label) < 0),
        loading,
        error
      ),
    [summaryCards]
  )

  return (
    <div style={{ minHeight: '2rem' }}>
      <ComponentWithStatus loading={loading} error={error}>
        <>
          <p className="p-b-sm">Non-Vendor Services</p>
          {nonVendorServices}
          <p className="p-b-sm">Vendor Services</p>
          {vendorServices}
        </>
      </ComponentWithStatus>
    </div>
  )
}

ServicesCharts.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Array with the services data to be rendered
   */
  summaryCharts: PropTypes.array
}

export default ServicesCharts
