import React from 'react'
import { Link } from 'react-router-dom'
import InvoiceCell from './components/InvoiceCell'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'

export const headerData = ({ SelectColumnFilter, editInvoice }) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Vendor',
        accessor: 'vendor_name',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        flexGrow: 1,
        Cell: row => {
          const item = row?.cell?.row?.original
          return (
            <Link
              to={location =>
                `${location.pathname}/${item?.po_id}/${item?.vendor_id}`
              }
              onClick={() => editInvoice(item?.po_id, item?.vendor_id)}>
              <InvoiceCell row={row} />
            </Link>
          )
        }
      },
      {
        Header: 'Invoice Number',
        accessor: 'vendor_po_number',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        flexGrow: 1,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'Invoice Date',
        accessor: 'po_date',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'Due Date',
        accessor: 'due_date',
        textAlign: 'left',
        width: 110,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'P.O. Number',
        accessor: 'po_number',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 120,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'Total Amount ($)',
        accessor: 'total_amount',
        textAlign: 'left',
        disableFilters: true,
        width: 150,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'Amount Paid ($)',
        accessor: 'amount_paid',
        textAlign: 'left',
        disableFilters: true,
        width: 150,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        width: 110,
        Cell: row => {
          return <InvoiceCell row={row} />
        }
      }
    ]
  }
]
