import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'

const SetupVendorManageModalDelete = ({
  user,
  context,
  closeModal,
  itemOptionUri = null,
  setupOneVendorDelete,
  vendorId,
  history,
  allVendorListGet
}) => {
  const {
    oneVendor: { isRequesting, isError }
  } = useSelector(state => state.setupVendors)
  const [removedCalled, setRemovedCalled] = useState(false)
  const prevState = usePrevious(isRequesting)

  useEffect(() => {
    if (isRequesting && removedCalled) {
      document.querySelector('.basic-modal').style.display = 'none'
    } else if (prevState && !isRequesting && removedCalled && !isError) {
      const { pdbid } = user.user_metadata
      allVendorListGet(pdbid, context)
      closeModal()
      setRemovedCalled(false)
      document.querySelector('.basic-modal').removeAttribute('style')
      history.push('/setup/vendors')
    }
  }, [isRequesting, removedCalled])

  const getIOTemplate = () => {
    const { pdbid } = user.user_metadata
    return (
      <div>
        <div className="columns">
          <div className="column">
            <p className="is-size-4 m-b-sm">
              Are you sure you want to delete this?
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column is-half has-text-right">
            <button
              type="button"
              className="button is-primary"
              onClick={() => {
                closeModal()
              }}>
              No, Cancel
            </button>
          </div>
          <div className="column is-half">
            <button
              type="button"
              className="button is-danger"
              onClick={() => {
                setupOneVendorDelete(pdbid, context, vendorId, itemOptionUri)
              }}>
              Yes, Delete
            </button>
          </div>
        </div>
      </div>
    )
  }

  const { pdbid } = user.user_metadata
  if (typeof itemOptionUri === 'string') return getIOTemplate()

  return (
    <div>
      <p className="is-size-4 m-b-sm">You're about to remove this vendor.</p>
      <p className="is-size-6 m-b-md">
        This action cannot be undone and the vendor will need to be manually
        re-added if needed in the future.
      </p>
      <p className="is-size-4 has-text-link has-text-centered m-b-lg">
        Are you sure you want to remove this vendor?
      </p>
      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <button
            type="button"
            className="button is-primary"
            style={{ width: 130 }}
            onClick={() => {
              closeModal()
            }}>
            No, Cancel
          </button>
        </div>
        <div className="column is-half">
          <button
            type="button"
            className="button is-danger"
            style={{ width: 130 }}
            onClick={() => {
              setRemovedCalled(true)
              setupOneVendorDelete(pdbid, context, vendorId)
            }}>
            Yes, Remove
          </button>
        </div>
      </div>
    </div>
  )
}

SetupVendorManageModalDelete.propTypes = {
  closeModal: PropTypes.func,
  setupOneVendorDelete: PropTypes.func,
  itemOptionUri: PropTypes.string,
  vendorId: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object
}

export default SetupVendorManageModalDelete
