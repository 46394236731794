import React from 'react'
import * as XLSX from 'xlsx'
import { exportFile } from 'ui/icons'

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileExtension = '.xlsx'

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(
      apiData[0]?.content?.map(d => ({
        name: d.name,
        price: d.price
      }))
    )
    XLSX.utils.sheet_add_aoa(ws, [['Service Option', 'Price']], {
      origin: 'A1'
    })
    const ws2 = XLSX.utils.json_to_sheet(
      apiData[1]?.content?.map(d => ({
        name: d.name,
        floorplan: d.floor_plan,
        price: d.price
      }))
    )
    XLSX.utils.sheet_add_aoa(ws2, [['Service Option', 'Floorplan', 'Price']], {
      origin: 'A1'
    })
    const ws3 = XLSX.utils.json_to_sheet(
      apiData[2]?.content?.map(d => ({
        name: d.name,
        floorplan: d.floor_plan,
        'Unit Type': d.unit_type,
        price: d.price
      }))
    )
    XLSX.utils.sheet_add_aoa(
      ws3,
      [['Service Option', 'Floorplan', 'Unit Type', 'Price']],
      {
        origin: 'A1'
      }
    )
    const ws4 = XLSX.utils.json_to_sheet(
      apiData[3]?.content?.map(d => ({
        name: d.name,
        floorplan: d.floor_plan,
        'Unit Type': d.unit_type,
        space: d.space,
        price: d.price
      }))
    )
    XLSX.utils.sheet_add_aoa(
      ws4,
      [['Service Option', 'Floorplan', 'Unit Type', 'Space', 'Price']],
      {
        origin: 'A1'
      }
    )
    const ws5 = XLSX.utils.json_to_sheet(
      apiData[4]?.content?.map(d => ({
        name: d.name,
        unit: d.unit,
        space: d.space,
        price: d.price
      }))
    )
    XLSX.utils.sheet_add_aoa(
      ws5,
      [['Service Option', 'Unit', 'Space', 'Price']],
      {
        origin: 'A1'
      }
    )
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'A La Carte')
    XLSX.utils.book_append_sheet(wb, ws2, 'Full Unit')
    XLSX.utils.book_append_sheet(wb, ws3, 'Full Unit Type')
    XLSX.utils.book_append_sheet(wb, ws4, 'Per Space')
    XLSX.utils.book_append_sheet(wb, ws5, 'Per Unit & Space')
    XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    XLSX.writeFile(wb, fileName + fileExtension)
  }

  return (
    <button
      className="button main-button export"
      onClick={() => {
        exportToCSV(apiData, fileName)
      }}>
      <img src={exportFile} alt="Export Pricing" />
      Export
    </button>
  )
}
