export const SERVICES = [
  {
    key: 'Paint',
    display: 'Paint'
  },
  {
    key: 'Punch',
    display: 'Punch'
  },
  {
    key: 'Cleaning',
    display: 'Clean'
  },
  {
    key: 'CarpetClean',
    display: 'Carpet Clean'
  },
  {
    key: 'CarpetReplace',
    display: 'Carpet Replace'
  },
  {
    key: 'ConcreteVinylReplace',
    display: 'Concrete/Vinyl Replace'
  },
  {
    key: 'Sparkle',
    display: 'Sparkle'
  },
  {
    key: 'Walk',
    display: 'Walk'
  },
  {
    key: 'Holdover',
    display: 'Potential Holdovers'
    /*popupOverrides: {
      largeText: true,
      acceptedLabel: 'No Holdover',
      rejectedLabel: 'Holdover',
      ignoreReason: true,
      detailedText:
        'Any bedrooms still occupied? Select No Holdover or Holdover for each room individually.',
      unitsSpacesField: (row, unitSpaces, isConsolidated) => {
        {
          let gbus = []
          for (let i in unitSpaces) {
            const us = unitSpaces[i]
            const r = row[i]
            if (!r.display) continue
            let notesId = 'consolidated-notes'
            if (!isConsolidated) {
              notesId = `notes-${i}`
            }
            const notes = document.getElementById(notesId).value
            if (us)
              gbus.push({
                unitspace: i.toUpperCase(),
                Notes: notes,
                unitspace_name: r.unitspace_name
              })
          }
          return { UnitSpaces_holdovers: gbus }
        }
      }
    }*/
  }
]

export const PHASES = [
  {
    key: 'Assign',
    display: {
      table: [
        {
          display: 'Ready to Assign',
          goTo: 'Assign',
          selected: true
        }
        // {
        //   display: 'Blocked',
        //   goTo: 'notAssignedBlocked',
        //   selected: false
        // }
      ],
      button: 'Assign'
    },
    actions: [
      {
        display: 'Assign',
        key: 'Assign'
      }
    ]
  },
  {
    key: 'notAssignedBlocked',
    display: {
      table: [
        {
          display: 'Ready to Assign',
          goTo: 'Assign',
          selected: false
        },
        {
          display: 'Blocked',
          goTo: 'notAssignedBlocked',
          selected: true
        }
      ],
      button: ''
    },
    actions: []
  },
  {
    key: 'Start',
    display: {
      table: [
        {
          display: 'Ready to Start',
          goTo: 'Start',
          selected: true
        }
        // {
        //   display: 'Blocked',
        //   goTo: 'Blocked',
        //   selected: false
        // }
      ],
      button: 'Start'
    },
    actions: [
      {
        display: 'Start',
        key: 'Start'
      },
      {
        display: 'Reassign',
        key: 'Reassign'
      }
    ]
  },
  {
    key: 'Blocked',
    display: {
      table: [
        {
          display: 'Ready to Start',
          goTo: 'Start',
          selected: false
        },
        {
          display: 'Blocked',
          goTo: 'Blocked',
          selected: true
        }
      ],
      button: ''
    },
    actions: []
  },
  {
    key: 'InProgress',
    display: {
      table: 'In Progress',
      button: 'Complete'
    },
    actions: [
      {
        display: 'Complete',
        key: 'InProgress'
      },
      {
        display: 'Return',
        key: 'Return'
      }
    ]
  },
  {
    key: 'AssignQC',
    display: {
      table: 'QC Ready to Assign',
      button: 'Assign'
    },
    actions: [
      {
        display: 'Assign',
        key: 'AssignQC'
      }
    ]
  },
  {
    key: 'Approve',
    single: false,
    display: {
      table: 'QC In Progress',
      button: 'Go-Back'
    },
    actions: [
      {
        display: 'Manage',
        key: 'Approve'
      }
    ]
  },
  {
    key: 'Approved',
    display: {
      table: 'QC Approved',
      button: ''
    },
    actions: []
  }
]
