import React, { useMemo } from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { icons } from '../icons'
import moment from 'moment'
import { isEmpty } from 'lodash'

const InProgressToolTip = ({ unitItem, serviceName }) => {
  const startedDate = unitItem[`${serviceName}_assigned`]

  const days = useMemo(() => {
    return startedDate && moment(startedDate).isValid()
      ? moment(new Date()).diff(startedDate, 'days')
      : startedDate
  }, [startedDate])

  const daysText = useMemo(() => {
    return days === 1 ? 'day' : 'days'
  }, [days])

  return (
    <div>
      <p>{unitItem[`${serviceName}_option`]}</p>
      <p>{unitItem[`${serviceName}_team`]}</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <p>
          <b>Started: </b>
          {startedDate}
        </p>
        {days && days !== 'N/A' && (
          <p style={{ color: '#3cb3e2' }}>
            {days} {daysText}
          </p>
        )}
      </div>
    </div>
  )
}

const NotStartedToolTip = ({ serviceName }) => {
  const originalServiceNam = (serviceName || '').replace('_', ' ')

  return (
    <div style={{ textTransform: 'capitalize' }}>
      <p style={{ textAlign: 'center' }}>{originalServiceNam}</p>
    </div>
  )
}

const CompletedToolTip = ({ unitItem, serviceName }) => {
  return (
    <div>
      <p>{unitItem[`${serviceName}_option`]}</p>
      <p>{unitItem[`${serviceName}_team`]}</p>
      <p>
        <b>Completed: </b> {unitItem[`${serviceName}_complete`]}
      </p>
    </div>
  )
}

const TurnboardOverviewCell = ({ row, serviceName }) => {
  const { cell, state, row: selectedRow } = row || {}
  const { id } = selectedRow || {}
  const { selectedRowIds } = state || {}
  const { value } = cell || {}
  const { original: unitItem } = selectedRow || {}
  const { isWalkComplete } = unitItem || {}
  const taskId = unitItem[`${serviceName}_task_id`]
  const isInHouseService = unitItem[`${serviceName}_inhouse_id`]

  const cellValue = useMemo(() => {
    if (!isWalkComplete && isEmpty(isInHouseService))
      return <img src={icons.dashIcon} alt="Pending Walk" />

    switch (value) {
      case 'In Progress':
        return (
          <Tippy
            className="tooltip-container"
            content={
              <InProgressToolTip
                unitItem={unitItem}
                serviceName={serviceName}
              />
            }
            placement="bottom"
            theme="light">
            <img src={icons.circleFilledIcon} alt="In Progress" />
          </Tippy>
        )
      case 'Not Started':
        return (
          <Tippy
            className="tooltip-container"
            content={
              <NotStartedToolTip
                unitItem={unitItem}
                serviceName={serviceName}
              />
            }
            placement="bottom"
            theme="light">
            <img src={icons.circleEmptyIcon} alt="Not Started" />
          </Tippy>
        )
      case 'Completed':
        return (
          <Tippy
            className="tooltip-container"
            content={
              <CompletedToolTip unitItem={unitItem} serviceName={serviceName} />
            }
            placement="bottom"
            theme="light">
            <img src={icons.circleFilledGreenIcon} alt="Completed" />
          </Tippy>
        )

      case 'Not Needed':
      case 'Pending Walk':
        return <img src={icons.dashIcon} alt="Not Needed" />
      case 'Renewal':
      case 'As Is':
        return value
      default:
        return value
    }
  }, [value, isWalkComplete, isInHouseService])

  const styles = useMemo(() => {
    const style = {}

    if (value === 'Completed' || value === 'Not Needed') {
      style.backgroundColor = 'rgb(141, 200, 92)'
    }

    if (!isWalkComplete) {
      delete style.backgroundColor
    }

    if (Object.keys(selectedRowIds || {}).includes(id)) {
      style.backgroundColor = '#55C2B8'
    }

    return style
  }, [taskId, selectedRowIds, id, isWalkComplete, value])

  return (
    <>
      <div className="cell-item" style={styles}>
        <span>{cellValue}</span>
      </div>
    </>
  )
}

export default TurnboardOverviewCell
