import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import flattenDepth from 'lodash/flattenDepth'
import GeneralDetails from './general-details'
import SectionDetails from './section-details'
import { getTabs } from './tabs'
import { useAppContext, useModals } from 'hooks'
import { deleteCompanyTemplate } from '../../../modules/company-templates'
import InlineTabs from '../../../components/inline-tabs'
import ConfirmationModal from '../../../components/confirmation-modal'
import ShareTemplateModal from '../../../parents/share-template-modal'
import { formatDateString } from 'utils'
import DuplicateTemplateModal from './duplicate-template-modal'
import { capitalize } from '../../../utils/helperFunctions'

const templateDetails = ({
  activeSpace,
  history,
  originalTemplate,
  templateData,
  templateToExport,
  setTemplateData,
  onRemoveTemplateElement,
  canEditTemplate,
  hasPermission,
  isTemplateComplete,
  onSubmit,
  onOpenSection
}) => {
  const dispatch = useDispatch()
  const {
    deleteIsRequesting,
    deleteSuccess,
    deleteIsError,
    shareTemplateIsRequesting,
    shareTemplateSuccess,
    shareTemplateIsError
  } = useSelector(state => state.companyTemplates)
  const {
    user: { pdbid },
    context
  } = useAppContext()
  const [activeTab, setActiveTab] = useState({})
  const [tabs] = useState(getTabs(setActiveTab))
  const [showModal, dismissModal] = useModals()
  const { shared, template_id, spaces, properties } = templateData

  useEffect(() => {
    setActiveTab(tabs[0])
  }, [])

  useEffect(() => {
    if (activeTab.id === 'section' && activeSpace !== null) {
      onOpenSection(true)
    } else {
      onOpenSection(false)
    }

    return () => {
      onOpenSection(false)
    }
  }, [activeTab, activeSpace])

  useEffect(() => {
    if (
      (!deleteIsRequesting && deleteSuccess) ||
      (!shareTemplateIsRequesting && shareTemplateSuccess)
    ) {
      dismissModal()
      return history.push('/inspections-setup/company-templates')
    } else if (
      (!deleteIsRequesting && deleteIsError) ||
      (!shareTemplateIsRequesting && shareTemplateIsError)
    ) {
      dismissModal()
    }
  }, [
    deleteIsRequesting,
    deleteSuccess,
    shareTemplateIsRequesting,
    shareTemplateSuccess
  ])

  const deleteTemplate = useCallback(() => {
    if (canEditTemplate) {
      showModal(
        { width: '480px' },
        <ConfirmationModal
          msg="You’re about to delete this template. This action cannot be undone. Are you sure you want to delete?"
          submit={() => {
            onSubmit(true)
            dispatch(deleteCompanyTemplate(pdbid, context, template_id))
          }}
          dismiss={dismissModal}
          useLoading
        />
      )
    }
  }, [templateData])

  const downloadTemplate = useCallback(() => {
    const mappedSpaces = (templateToExport.spaces || []).map(space => ({
      rows: flattenDepth(
        space.items.length
          ? space.items.map(item => {
              return item.observations.length
                ? item.observations.map(observation => {
                    return observation.solutions.length
                      ? observation.solutions.map(solution => [
                          space.space_name,
                          item.item_name,
                          observation.observation_name,
                          solution.solution_name,
                          observation.media_required === null
                            ? 'Required'
                            : 'Not Required',
                          (observation.media_minimum =
                            observation.media_required === null
                              ? observation.media_minimum
                              : 0),
                          (observation.solution_type = capitalize(
                            observation.solution_type || ''
                          ))
                        ])
                      : [
                          [
                            space.space_name,
                            item.item_name,
                            observation.observation_name
                          ]
                        ]
                  })
                : [[[space.space_name, item.item_name]]]
            })
          : [[[[space.space_name]]]],
        2
      )
    }))

    let templateCsvData = []
    mappedSpaces.forEach(space => {
      templateCsvData = [...templateCsvData, ...space.rows]
    })

    const rows = [
      [
        'Spaces',
        'Items',
        'Observations',
        'Solutions',
        'Photo Required',
        'Minimum # of Photo',
        'Solutions Possible'
      ],
      ...templateCsvData
    ]

    const csvContent = [
      'data:text/csv;charset=utf-8,',
      rows.map(e => e.join(',')).join('\n')
    ].join('')

    const encodedUri = encodeURI(csvContent).replace(/#/g, '%23')
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    const filename = [
      templateToExport.name,
      formatDateString(new Date(), 'MM_DD_YYYY')
    ].join('-')
    link.setAttribute('download', `${filename}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [templateToExport])

  const duplicateTemplate = useCallback(() => {
    if (hasPermission) {
      showModal(
        { width: '480px' },
        <DuplicateTemplateModal
          onConfirm={dismissModal}
          onDismiss={dismissModal}
          onSubmit={onSubmit}
          template={originalTemplate}
        />
      )
    }
  }, [onSubmit, originalTemplate])

  const shareTemplate = useCallback(() => {
    if (hasPermission && templateData.status !== 'inactive') {
      showModal(
        { width: '480px' },
        <ShareTemplateModal
          dismiss={dismissModal}
          shared={shared}
          properties={properties}
          templateId={template_id}
          onSubmit={onSubmit}
        />
      )
    }
  }, [templateData])

  const removeActiveSpace = () => {
    if (canEditTemplate) {
      showModal(
        { width: '480px' },
        <ConfirmationModal
          msg="You’re about to delete this section. This action cannot be undone. Are you sure you want to delete?"
          submit={() => {
            onRemoveTemplateElement(templateData.spaces[activeSpace])
            dismissModal()
          }}
          dismiss={dismissModal}
        />
      )
    }
  }

  const getContent = () => {
    switch (activeTab['id']) {
      case tabs[0]['id']:
        return (
          <GeneralDetails
            templateStatus={templateData.status}
            setTemplateData={setTemplateData}
            sharedElements={shared}
            deleteTemplate={{
              disabled: !template_id || shared.length || !canEditTemplate,
              action: deleteTemplate
            }}
            downloadTemplate={{
              disabled: spaces.length === 0,
              action: downloadTemplate
            }}
            duplicateTemplate={{
              disabled: !template_id || !hasPermission,
              action: duplicateTemplate
            }}
            shareTemplate={{
              title: !isTemplateComplete
                ? 'Please complete the template before sharing with any property.'
                : '',
              disabled:
                !template_id ||
                !hasPermission ||
                templateData.status === 'inactive' ||
                !isTemplateComplete,
              action: shareTemplate
            }}
          />
        )

      case tabs[1]['id']:
        return (
          <SectionDetails
            canEditTemplate={canEditTemplate}
            activeSpace={activeSpace}
            removeActiveSpace={removeActiveSpace}
            templateData={templateData}
            setTemplateData={setTemplateData}
          />
        )

      default:
        return null
    }
  }

  return (
    <>
      <InlineTabs tabs={tabs} style={{ top: -34 }} />
      {getContent()}
    </>
  )
}

templateDetails.propTypes = {
  activeSpace: PropTypes.number,
  history: PropTypes.object,
  templateData: PropTypes.object,
  setTemplateData: PropTypes.func,
  canEditTemplate: PropTypes.bool,
  hasPermission: PropTypes.bool,
  isTemplateComplete: PropTypes.bool,
  onSubmit: PropTypes.func,
  onOpenSection: PropTypes.func
}

export default templateDetails
