import Service from '../service'

/**
 * Vendor module
 * @redux
 * @reduxActionScope vendor
 * @module vendor
 */

export const VENDOR_GET_REQUESTED = 'VENDOR_GET_REQUESTED'
export const VENDOR_GET_FAIL = 'VENDOR_GET_FAIL'

export const VENDOR_TODOS_SUCCESS = 'VENDOR_TODOS_SUCCESS'
export const VENDOR_PENDINGS_SUCCESS = 'VENDOR_PENDINGS_SUCCESS'
export const VENDOR_APPROVED_SUCCESS = 'VENDOR_APPROVED_SUCCESS'

export const VENDOR_TASK_UPDATE_SUCCESS = 'VENDOR_TASK_UPDATE_SUCCESS'
export const VENDOR_TASK_UPDATE_FAIL = 'VENDOR_TASK_UPDATE_FAIL'

const initialState = {
  isRequesting: false,
  isError: false,
  property: null,
  jobs: [],
  pendingJobs: [],
  approvedJobs: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_GET_REQUESTED:
      return {
        ...state,
        isRequesting: true,
        isError: false
      }
    case VENDOR_GET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case VENDOR_TODOS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        jobs: Object.values(action.payload.jobs),
        property: action.payload.property || state.property,
      }

    case VENDOR_PENDINGS_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        pendingJobs: action.payload.pendingJobs,
        property: action.payload.property || state.property,
      }

    case VENDOR_APPROVED_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        approvedJobs: action.payload.approvedJobs,
        property: action.payload.property || state.property,
      }

    case VENDOR_TASK_UPDATE_SUCCESS:
      return {
        ...state,
        isError: false
      }

    case VENDOR_TASK_UPDATE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true,
      }

    default:
      return state
  }
}

export const getMyWork = (pdbid, context, vendorId) => {
  return dispatch => {
    dispatch({ type: VENDOR_GET_REQUESTED })

    Service.vendorMyWorkGet(pdbid, context, vendorId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: VENDOR_TODOS_SUCCESS,
            payload: {
              jobs: (data.mobile.jobs || []).slice(),
              property: data.mobile.property,
            }
          })
        } else {
          dispatch({ type: VENDOR_GET_FAIL })
        }
      })
      .catch(() => {
        dispatch({ type: VENDOR_GET_FAIL })
      })
  }
}

export const getPendings = (pdbid, context, vendorId) => {
  return dispatch => {
    dispatch({ type: VENDOR_GET_REQUESTED })

    Service.vendorPendingGet(pdbid, context, vendorId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: VENDOR_PENDINGS_SUCCESS,
            payload: {
              pendingJobs: data.mobile.jobs || [],
              property: data.mobile.property,
            }
          })
        } else {
          dispatch({ type: VENDOR_GET_FAIL })
        }
      })
      .catch(() => {
        dispatch({ type: VENDOR_GET_FAIL })
      })
  }
}

export const getApproved = (pdbid, context, vendorId) => {
  return dispatch => {
    dispatch({ type: VENDOR_GET_REQUESTED })

    Service.vendorApprovedGet(pdbid, context, vendorId).then(async res => {
      if (res.ok) {
        const data = await res.json()
        dispatch({
          type: VENDOR_APPROVED_SUCCESS,
          payload: {
            approvedJobs: data.mobile.jobs || [],
            property: data.mobile.property,
          }
        })
      } else {
        dispatch({ type: VENDOR_GET_FAIL })
      }
    })
  }
}

export const requestInspection = (pdbid, context, vendorid, order) => {
  return dispatch => {

    const json = { operation_uri: order.operation_uri }

    Service.vendorInspectionPut(pdbid, context, order.service, json)
      .then(async res => {
        if (res.ok) {
          dispatch({ type: VENDOR_TASK_UPDATE_SUCCESS })
          getMyWork(pdbid, context, vendorid)(dispatch)
        } else {
          dispatch({ type: VENDOR_TASK_UPDATE_FAIL })
        }
      })
      .catch(() => {
        dispatch({ type: VENDOR_TASK_UPDATE_FAIL })
      })
  }
}
