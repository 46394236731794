import React from 'react'

const HeadingTemplate = ({ children }) => {
  return (
    <div className="columns is-vcentered mb-0">
      <div className="column is-full is-11-fullhd is-offset-1-fullhd">
        <div className="columns is-desktop is-mobile content-spacing">
          {children}
        </div>
      </div>
    </div>
  )
}

export default HeadingTemplate
