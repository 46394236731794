/**
 * Responsive viewport module
 * @redux
 * @reduxActionScope responsive
 * @module responsive
 */

/**
 * Update the current responsive data
 * @type {Redux.ActionType}
 */
const UPDATE = 'responsive/UPDATE'

const initialState = {
  isMobile: false,
  isTouch: false,
  isDesktop: false,
  isWideScreen: false,
  isFullHD: false
}

/**
 * Responsive config reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        isMobile: action.isMobile,
        isTouch: action.isTouch,
        isDesktop: action.isDesktop,
        isWideScreen: action.isWideScreen,
        isFullHD: action.isFullHD
      }
    default:
      return state
  }
}

/**
 * Updates the current responsive config
 * @redux
 * @reduxActionCreator INSPECT_LIST_REQUESTED, INSPECT_LIST_SUCCESS, INSPECT_LIST_FAIL
 * @param {boolean} isMobile - determines if the current viewport supports at least mobile sizes
 * @param {boolean} isTouch - determines if the current viewport supports at least tablet sizes (769px width or higher)
 * @param {boolean} isDesktop - determines if the current viewport supports at least desktop sizes (1024px width or higher)
 * @param {boolean} isWideScreen - determines if the current viewport supports at least wide screen sizes (1216px width or higher)
 * @param {boolean} isFullHD - determines if the current viewport supports full HD sizes (1408px width or higher)
 */
export const updateResponsive = responsive => {
  const { isMobile, isTouch, isDesktop, isWideScreen, isFullHD } = responsive
  return dispatch => {
    dispatch({
      type: UPDATE,
      isMobile,
      isTouch,
      isDesktop,
      isWideScreen,
      isFullHD
    })
  }
}
