import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import { usePrevious } from '../../helpers/hooks'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../breadcrumbs'
import SetupGoalsServices from '../setup-goals-services'
// import SetupGoalsTurnDetails from '../setup-goals-turn-details'
// import TurnTimeline from '../../components/turn-timeline'
// import { icons } from './icons'
import './index.scss'

// const formatDate = date =>
//   moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : 'N/A'

const SetupGoalsMain = ({
  user,
  context,
  location,
  openModal,
  setModalContent,
  closeModal,
  setupGoals,
  setupAllGoalsGet,
  setupServicesGet,
  setupGoalLineCreate,
  setupGoalLineUpdate
}) => {
  const [isEditingMain] = useState(false)

  const prevContext = usePrevious(context)

  useEffect(() => {
    const { pdbid } = user.user_metadata
    setupAllGoalsGet(pdbid, context)
    setupServicesGet(pdbid, context)
  }, [])

  useEffect(() => {
    const { pdbid } = user.user_metadata
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      setupServicesGet(pdbid, context)
      setupAllGoalsGet(pdbid, context)
    }
  }, [context])

  const getLoadingState = () => <BigLoading />

  // const getEmptyState = () => (
  //   <div className="columns">
  //     <div className="column has-text-centered">
  //       <img src={icons.emptyPage} alt="Empty Page Icon" />
  //       <p className="is-size-5 m-t-md">You've got BIG goals...</p>
  //       <p className="has-text-grey is-size-7 m-t-sm">
  //         it's time to <strong>make them official!</strong>
  //       </p>
  //       <button
  //         className="button is-primary m-t-md"
  //         onClick={() => setIsEditingMain(true)}>
  //         Add Your Goals
  //       </button>
  //     </div>
  //   </div>
  // )

  // const getManageState = () => {
  //   const data = setupGoals.goal.data
  //   const stats = data.stats || {}
  //   const goalLines = (data.goal_lines || []).map(gl => ({
  //     service: gl.servicetype,
  //     start: gl.start_date,
  //     end: gl.end_date,
  //     duration: moment(gl.end_date, 'MM-DD-YYYY').diff(
  //       moment(gl.start_date, 'MM-DD-YYYY'),
  //       'days'
  //     )
  //   }))

  //   return (
  //     <div>
  //       <SetupGoalsTurnDetails
  //         data={[
  //           {
  //             title: 'Turn Details',
  //             rows: [
  //               {
  //                 move_out_date: formatDate(stats.move_out_date),
  //                 move_in_date: formatDate(stats.move_in_date)
  //               },
  //               {
  //                 transfer_date: formatDate(stats.transfer_date),
  //                 total_turn_days: stats.total_turn_days
  //               }
  //             ]
  //           },
  //           {
  //             titles: ['Unit Level Detail', 'Bed Level Detail'],
  //             rows: [
  //               {
  //                 total_units: stats.total_units,
  //                 total_beds: stats.total_beds
  //               },
  //               {
  //                 fully_renewed_units: stats.fully_renewed,
  //                 total_renewals: stats.total_renewed
  //               },
  //               {
  //                 partially_renewed_units: stats.partially_renewed, // Check if it's the partially occupied units they need
  //                 empty: true
  //               },
  //               {
  //                 as_is_units: stats.as_is_units || 0,
  //                 as_is_beds: stats.as_is_beds || 0
  //               },
  //               {
  //                 total_common: stats.total_common,
  //                 total_turn_beds: stats.total_turn_beds
  //               },
  //               {
  //                 total_turn_spaces: stats.total_turn_spaces || 0
  //               }
  //             ]
  //           },
  //           {
  //             title: 'Transfer Detail',
  //             rows: [
  //               {
  //                 renewal_transfer_out: stats.renewal_transfer_out,
  //                 renewal_transfer_in: stats.renewal_transfer_in
  //               },
  //               {
  //                 leapfrogs: stats.total_leapfrog
  //               }
  //             ]
  //           },
  //           {
  //             rows: [
  //               {
  //                 fully_vacant_units: stats.fully_vacant,
  //                 unassigned_vacant: stats.total_unassigned
  //               }
  //             ]
  //           }
  //         ]}
  //         display={{
  //           move_out_date: 'Move Out Date',
  //           move_in_date: 'Move In Date',
  //           transfer_date: 'Transfer Date',
  //           total_turn_days: 'Total Turn Days',
  //           total_beds: 'Total Beds',
  //           total_units: 'Total Units',
  //           total_common: 'Total Turn Common Areas',
  //           total_turn_beds: 'Total Turn Beds',
  //           total_renewals: 'Total Renewals',
  //           total_new_leases: 'Total New Leases', // Where?
  //           unassigned_vacant: 'Unassigned/Vacant',
  //           fully_renewed_units: 'Fully Renewed Units',
  //           partially_renewed_units: 'Partially Renewed Units',
  //           fully_vacant_units: 'Fully Vacant Units',
  //           renewal_transfer_out: 'Renewal Transfer Out',
  //           renewal_transfer_in: 'Renewal Transfer In',
  //           leapfrogs: 'Leapfrogs',
  //           as_is_units: 'As-Is Units',
  //           as_is_beds: 'As-Is Beds',
  //           total_turn_spaces: 'Total Turn SPACES',
  //           prelease: 'Pre-Lease %' // Where?
  //         }}
  //         classNames={{
  //           total_common: 'is-sub-1',
  //           total_turn_beds: 'is-sub-1',
  //           total_turn_spaces: 'half-width is-sub-1 has-text-weight-mid',
  //           leapfrogs: 'half-width is-sub-2 has-text-weight-mid'
  //         }}
  //         flexContainers={['total_turn_spaces', 'leapfrogs']}
  //         isFaded={isEditingMain}
  //         hasTransitionOpacity={true}
  //       />

  //       <TurnTimeline
  //         isFaded={isEditingMain}
  //         data={goalLines}
  //         hasTransitionOpacity={true}
  //       />
  //     </div>
  //   )
  // }

  const { goal } = setupGoals
  const { hasMadeInitialRequest, isRequesting } = goal
  if (!hasMadeInitialRequest || isRequesting) {
    return getLoadingState()
  }
  // let leftColumn = getEmptyState()
  // let format = Object.keys(data).length > 0 || isEditingMain ? 'two' : 'one'
  // if (format === 'two') leftColumn = getManageState()
  return (
    <div className="container setup-goals-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="setup-goal-main">
          {/* <div className={`column ${format === 'two' ? 'is-half' : 'is-full'}`}>
            {leftColumn}
          </div> */}
          <SetupGoalsServices
            context={context}
            user={user}
            openModal={openModal}
            setModalContent={setModalContent}
            closeModal={closeModal}
            setupGoals={setupGoals}
            setupGoalLineCreate={setupGoalLineCreate}
            setupGoalLineUpdate={setupGoalLineUpdate}
            setupAllGoalsGet={setupAllGoalsGet}
            isFaded={isEditingMain}
          />
        </div>
      </div>
    </div>
  )
}

SetupGoalsMain.propTypes = {
  location: PropTypes.object,
  setupGoals: PropTypes.object
}

export default SetupGoalsMain
