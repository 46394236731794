import _ from 'lodash'
import Short from 'short-uuid'
import React, { forwardRef } from 'react'
import Stars from '../../components/stars'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const getTotalProperties = healthMap =>
  _.reduce(healthMap, (sum, item) => sum + item.count, 0)

const getSortedMap = healthMap => _.orderBy(healthMap, 'stars', 'desc')

const TOOLTIP_WIDTH = 394
const TOOLTIP_MOVE_DELTA = 10

const ProjectsList = ({ data, stars, onPropertySelected, closeTooltip }) => (
  <section className="modal-card-body">
    <div className="columns">
      <div className="column is-full is-size-5">
        Select a property you want to detail further
      </div>
    </div>
    <ul className="is-size-6 m-b-sm is-borderless">
      {_.map(data[stars], property => (
        <li key={Short.uuid()}>
          <a
            href="#!"
            onClick={() => {
              onPropertySelected(property.id)
              closeTooltip()
            }}>
            {property.name}
          </a>
        </li>
      ))}
    </ul>
  </section>
)

const OverallHealth = (
  {
    healthMap,
    data,
    minHeight,
    openTooltip,
    closeTooltip,
    setTooltipContent,
    onPropertySelected
  },
  ref
) => {
  const totalProperties = getTotalProperties(healthMap)
  return (
    <div className="box animated flipInX" style={{ minHeight }} ref={ref}>
      <p className="is-size-5">Overall Health</p>
      {_.map(getSortedMap(healthMap), item => (
        <div className="columns m-b-none" key={Short.uuid()}>
          <div className="column p-l-md">
            <Stars numActive={parseInt(item.stars)} />
          </div>
          <div className="column is-size-7">
            <table>
              <tbody>
                <tr>
                  <td>{`${item.count} of ${totalProperties} Properties`}</td>
                  <td className="p-l-sm">
                    <a
                      href="#!"
                      onClick={e => {
                        setTooltipContent(
                          <ProjectsList
                            data={data}
                            stars={item.stars}
                            onPropertySelected={onPropertySelected}
                            closeTooltip={closeTooltip}
                          />
                        )
                        openTooltip(
                          TOOLTIP_WIDTH,
                          'auto',
                          e.clientY,
                          e.clientX - TOOLTIP_WIDTH,
                          e.target,
                          TOOLTIP_MOVE_DELTA
                        )
                      }}>
                      {item.count > 0 && (
                        <FontAwesomeIcon icon={faQuestionCircle} />
                      )}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )
}

export default forwardRef(OverallHealth)
