import React, { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import InputField from '../../components/forms-helper/InputField'
import { emailPolicy } from '../../components/forms-helper/ValidationPolicies'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'

const EmailVerificationModal = ({
  loading,
  onNext,
  onDismiss,
  existsInCurrentProperty,
  isVendor,
  serverError
}) => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const validateEmail = email => {
    return emailPolicy.test(email)
  }

  const isValid = useMemo(() => {
    return validateEmail(email)
  }, [error, email])

  const handleChange = ({ currentTarget: input }) => {
    input.value = input.value?.toLowerCase()?.trim()

    setEmail(input.value)

    if (!validateEmail(input.value)) {
      setError(
        'The email address you entered is invalid. Please enter your email address using standard format.'
      )
    } else {
      setError('')
    }
  }

  const handleNext = () => {
    setError('')
    onNext(email)
  }

  useEffect(() => {
    if (existsInCurrentProperty)
      setError(` Ops! It seems that a user with this email address already exists
      on this property. You cannot create duplicate users with the
      same email address. Kindly provide a different email address.`)
  }, [existsInCurrentProperty])

  useEffect(() => {
    if (isVendor)
      setError(
        `  Ops! It seems that a user with this email address already
        exists as a <strong>vendor</strong> user. Kindly provide a
        different email address.
        `
      )
  }, [isVendor])

  useEffect(() => {
    if (serverError) {
      setError('Something went wrong. Please try again later !!!')
    }
  }, [serverError])

  return (
    <>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Enter Email</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onDismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="form">
        <InputField
          name="email"
          value={email}
          onChange={handleChange}
          label="Email Address"
          placeholder="Enter Email Address"
          labelClassName="input-label"
          error={error}
        />
        <div className="columns is-vcentered m-b-sm m-t-md">
          <div className="center-container">
            <button
              className="button main-button is-primary"
              onClick={handleNext}
              disabled={!isValid}>
              Next
              {loading && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmailVerificationModal
