import _ from 'lodash'
import { icons } from './icons'

export const getHeaderData = (services, options) => [
  {
    key: 'services',
    display: 'Service',
    filterable: true,
    getValues: () => services,
    notSortable: true
  },
  {
    key: 'options',
    display: 'Options',
    filterable: true,
    getValues: () => options,
    notSortable: true
  },
  {
    key: 'unit',
    display: 'Unit',
    filterable: true,
  },
  // {
  //   key: 'lease_status',
  //   display: 'Lease Status',
  //   filterable: true,
  //   getValues: data => {
  //     let leaseStatus = []
  //     const spaces = data.map(item => item.unit_spaces.map(space => space.lease_status).filter(lease => lease))
  //     spaces.forEach(item => leaseStatus = [...leaseStatus, ...item])
  //     return _.uniq(leaseStatus)
  //   },
  // },
  {
    key: 'unit_cost',
    display: 'Cost'
  }
]

export const customFilters = [
  {
    key: 'is_transfer',
    icon: icons.transfers,
    caption: 'Transfer',
    match: (item, value) => (value ? item.is_transfer : true),
    reset: data =>
      _.filter(data, d => d.is_transfer).length === 0 ||
      _.filter(data, d => !d.is_transfer).length === 0
  },
  {
    key: 'is_asis',
    icon: icons.asIs,
    caption: 'As-Is Space',
    match: (item, value) => (value ? item.is_asis : true),
    reset: data =>
      _.filter(data, d => d.is_asis).length === 0 ||
      _.filter(data, d => !d.is_asis).length === 0
  },
  {
    key: 'is_full_asis',
    icon: icons.fullAsIs,
    caption: 'As-Is Unit',
    match: (item, value) => (value ? item.is_full_asis : true),
    reset: data =>
      _.filter(data, d => d.is_full_asis).length === 0 ||
      _.filter(data, d => !d.is_full_asis).length === 0
  },
  {
    key: 'is_carpet',
    icon: icons.carpet,
    caption: 'Carpet',
    match: (item, value) => (value ? item.is_carpet : true),
    reset: data =>
      _.filter(data, d => d.is_carpet).length === 0 ||
      _.filter(data, d => !d.is_carpet).length === 0
  },
  {
    key: 'partially_occupied',
    icon: icons.partiallyOccupied,
    caption: 'Partially Occupied Unit',
    match: (item, value) => (value ? item.partially_occupied : true),
    reset: data =>
      _.filter(data, d => d.partially_occupied).length === 0 ||
      _.filter(data, d => !d.partially_occupied).length === 0
  },
  {
    key: 'fully_vacant',
    icon: icons.fullyVacant,
    caption: 'Vacant Unit',
    match: (item, value) => (value ? item.fully_vacant : true),
    reset: data =>
      _.filter(data, d => d.fully_vacant).length === 0 ||
      _.filter(data, d => !d.fully_vacant).length === 0
  },
]
