import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

/**
 * Component for showing a 5-stars strip with some of them toggled
 *
 * @component
 * @example
 *   <Stars
 *      numActive={4}
 *   />
 * )
 */
const Stars = ({ numActive = 0, className = '' }) => {
  const getStars = () => {
    let template = []
    for (let i = 0; i < 5; i += 1) {
      const isActive = i < numActive
      template.push(
        <li
          className={`is-inline ${isActive ? 'is-active' : ''}`}
          key={`star-${i}`}>
          <FontAwesomeIcon icon={faStar} />
        </li>
      )
    }
    return template
  }

  return (
    <div className={`stars ${className}`}>
      <ul>{getStars()}</ul>
    </div>
  )
}

Stars.propTypes = {
  numActive: PropTypes.number
}

export default Stars
