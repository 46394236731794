import _ from 'lodash'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { usePrevious } from '../../helpers/hooks'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../breadcrumbs'
import SetupTeamsList from '../setup-teams-list'
import SetupTeamManage from '../setup-team-manage'
import './index.scss'

const SetupTeamsMain = props => {
  const {
    context,
    setupTeams,
    user,
    allTeamListGet,
    setupAllServicesGet,
    setupAllVendorsGet,
    setupAllStaffGet,
    resetTeamCreation,
    history,
    location,
    setupVendors,
    setupStaff
  } = props

  const prevContext = usePrevious(context)
  const prevSetupTeams = usePrevious(setupTeams)
  const { allTeams, allServices, oneTeam } = setupTeams

  useEffect(() => {
    const { pdbid } = user.user_metadata
    allTeamListGet(pdbid, context)
    setupAllServicesGet(pdbid, context)
    setupAllVendorsGet(pdbid, context)
    setupAllStaffGet(pdbid, context)
  }, [])

  useEffect(() => {
    if (oneTeam.successfullCreation) {
      history.push(`/setup/team-manage?t=${oneTeam.data.team_id}`)
      resetTeamCreation()
    }
  }, [oneTeam])

  useEffect(() => {
    const { isRequesting } = setupTeams.oneTeam
    const newId = _.get(setupTeams, 'oneTeam.data.team_id')
    const oldId = _.get(prevSetupTeams, 'oneTeam.data.team_id')
    const idChanged =
      newId && oldId && newId !== oldId && newId !== 'new-team' && newId !== ''
    const wasRequesting = _.get(prevSetupTeams, 'oneTeam.isRequesting')
    const updateComplete = !isRequesting && isRequesting !== wasRequesting
    const { pdbid } = user.user_metadata
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged || updateComplete) {
      allTeamListGet(pdbid, context)
      setupAllServicesGet(pdbid, context)
      setupAllVendorsGet(pdbid, context)
      setupAllStaffGet(pdbid, context)
    }
    if (idChanged && newId) {
      history.push(`/setup/team-manage?t=${newId}`)
    }
  }, [context, setupTeams])

  const getLoadingState = () => <BigLoading />

  const { allStaff } = setupStaff
  const { allVendors } = setupVendors
  if (
    !allTeams.hasMadeInitialRequest ||
    allTeams.isRequesting ||
    oneTeam.isRequesting ||
    !allStaff.hasMadeInitialRequest ||
    allStaff.isRequesting ||
    !allVendors.hasMadeInitialRequest ||
    allVendors.isRequesting ||
    !allServices.hasMadeInitialRequest ||
    allServices.isRequesting
  ) {
    return getLoadingState()
  }

  const isListView = location.pathname === '/setup/teams'
  const Component = isListView ? SetupTeamsList : SetupTeamManage
  return (
    <div className="container setup-teams-main animated fadeIn">
      <div className={`section ${!isListView ? 'vendor-section' : ''}`}>
        <Breadcrumbs location={location} />
        {!isListView && (
          <div className="back-text">
            <Link to="/setup/teams" className="p-none">
              Back to List View
            </Link>
          </div>
        )}
        <div className="columns setup-teams-main">
          <div className="column">
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

SetupTeamsMain.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  setupTeams: PropTypes.object,
  setupVendors: PropTypes.object,
  setupStaff: PropTypes.object,
  allTeamListGet: PropTypes.func,
  setupAllServicesGet: PropTypes.func,
  setupAllVendorsGet: PropTypes.func,
  setupAllStaffGet: PropTypes.func,
  resetTeamCreation: PropTypes.func
}

export default SetupTeamsMain
