import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const getIcon = iconLabel => {
  const iconToUse = iconLabel.replace(/[^a-zA-Z0-9 ]/g, '').split(' ').join('').toLowerCase()
  return `turnable-icon-${iconToUse}`
}

const ServicesSelector = ({ services, setSelectedServices, completedServicesNumber }) => {
  const [serviceSelected, setServiceSelected] = useState(() => services.filter(service => service.selected).length > 0)

  const selectedHandler = itemIndex => {
    const newServices = [...services]
    newServices[itemIndex]['selected'] = !newServices[itemIndex]['selected']
    newServices[itemIndex]['selected'] ? setServiceSelected(true) : setServiceSelected(false)
    setSelectedServices(newServices)
  }

  return (
    <div className="services-selector">
      <p className="m-b-lg title">
        <span>
          <span className="highlighted">Select the service</span> you need to schedule
        </span>
      </p>
      <p className="subtitle">
        Or click “Auto-Schedule All” to immediately schedule all available services
      </p>
      <div className="services-list">
        {services.map((service, index) => (
          <div
            className={`service-item ${service.selected ? 'selected' : ''} ${serviceSelected && !service.selected ? 'disabled' : ''}`}
            key={index}
            onClick={() => (!service.active ? selectedHandler(index) : null)}>
            <span className={getIcon(service.label)}></span>
            <p className="label">{service.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

ServicesSelector.propTypes = {
  completedServicesNumber: PropTypes.number,
  services: PropTypes.array,
  setSelectedServices: PropTypes.func,
}

export default ServicesSelector
