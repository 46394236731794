import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useAppContext } from 'hooks'
import { close } from 'ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { cloneDeep, isEmpty } from 'lodash'
import Service from '../../service'
import { warning } from 'ui/icons'
import moment from 'moment'
import './index.scss'
import { useSelector } from 'react-redux'

/**
 * Component for showing a new property form modal
 *
 * @component
 * @example
 * return (
 *   <NewPropertyFormModal
 *     dismiss = () => {}
 *     sharedTemplates = [{}]
 *     history= {}
 *   />
 * )
 */
const NewPropertyFormModal = ({
  dismiss = () => {},
  sharedTemplates,
  history
}) => {
  const {
    user: { pdbid },
    context
  } = useAppContext()

  const { list } = useSelector(state => state.propertyForms)

  const [loading, setLoading] = useState(false)
  const [useTemplate, setUseTemplate] = useState(true)
  const [parentSharedTemplate, setParentSharedTemplate] = useState(null)
  const [error, setError] = useState(false)

  const sortedSharedTemplates = useMemo(() => {
    const sortedTemplates = cloneDeep(sharedTemplates)

    return (sortedTemplates || []).sort((current, next) => {
      if (current?.template?.toLowerCase() < next?.template?.toLowerCase())
        return -1
      if (current?.template?.toLowerCase() > next?.template?.toLowerCase())
        return 1
      return 0
    })
  }, [sharedTemplates])

  const isNameExist = useCallback(
    (formList, name) => (formList || []).some(form => form.name === name),
    []
  )

  const getFormName = useCallback(
    template => {
      if (!isNameExist(list, template.name)) return template.name

      let formName = `${template.name} - Copy`.replace(/  +/g, ' ').trim()

      if (isNameExist(list, formName)) {
        let randomNumber = 1

        while (isNameExist(list, `${formName} (${randomNumber})`)) {
          randomNumber++
        }

        formName = `${formName} (${randomNumber})`
      }

      return formName
    },
    [isNameExist, list]
  )

  const isTemplateComplete = (template = {}) => {
    const { spaces } = template

    if (!spaces.length) return false

    for (let space of spaces || []) {
      if (!space?.items?.length) return false

      for (let item of space.items || []) {
        if (!item?.observations?.length) return false

        for (let observation of item.observations || []) {
          if (!observation?.solutions?.length) return false
        }
      }
    }

    return true
  }

  const makePropertyFormData = (template = {}) => {
    const propertyForm = {}

    propertyForm.form_id = null
    propertyForm.template_id = template.template_id
    propertyForm.name = getFormName(template)
    propertyForm.type = template.type
    propertyForm.created_by = template.created_by
    propertyForm.created_at = moment(new Date()).format('DD-MMM-YY')
    propertyForm.status = isTemplateComplete(template) ? null : 'false'

    propertyForm.spaces = template?.spaces?.length
      ? template.spaces.map((space, spaceIndex) => ({
          space_id: space.space_id,
          form_space_id: null,
          do_delete_space: 'false',
          required_on_property_forms: space.required_on_property_forms || null,
          sorting_order: space.sorting_order || `${spaceIndex + 1}`,
          template_space:
            space.required_on_property_forms === 'false' ? 'false' : null,
          items: space?.items?.length
            ? space.items.map((item, itemIndex) => ({
                item_id: item.item_id,
                form_item_id: null,
                do_delete_item: 'false',
                sorting_order_item:
                  item.sorting_order_item || `${itemIndex + 1}`,
                template_item:
                  space.required_on_property_forms === 'false' ? 'false' : null,
                observations: item?.observations?.length
                  ? item.observations.map((observation, observationIndex) => ({
                      observation_id: observation.observation_id,
                      form_observation_id: null,
                      do_delete_observation: 'false',
                      solution_type: observation.solution_type,
                      media_required: observation.media_required || null,
                      media_minimum: observation.media_minimum,
                      sorting_order_observation:
                        observation.sorting_order_observation ||
                        `${observationIndex + 1}`,
                      template_observation:
                        space.required_on_property_forms === 'false'
                          ? 'false'
                          : null,
                      solutions: observation?.solutions?.length
                        ? observation.solutions.map(
                            (solution, solutionIndex) => ({
                              solution_id: solution.solution_id,
                              form_solution_id: null,
                              do_delete_solution: 'false',
                              sorting_order_solution:
                                solution.sorting_order_solution ||
                                `${solutionIndex + 1}`,
                              template_solution:
                                space.required_on_property_forms === 'false'
                                  ? 'false'
                                  : null
                            })
                          )
                        : [{ solution_id: null }]
                    }))
                  : [{ observation_id: null }]
              }))
            : [{ item_id: null }]
        }))
      : [{ space_id: null }]

    return propertyForm
  }

  const submitHandler = async () => {
    if (useTemplate && !isEmpty(parentSharedTemplate)) {
      setLoading(true)
      setError(false)
      try {
        const templateDetailsRes = await Service.getCompanyTemplateDetails(
          pdbid,
          context,
          parentSharedTemplate.template_id
        )

        if (templateDetailsRes.ok) {
          const templateDetails = await templateDetailsRes.json()
          const propertyFormData = makePropertyFormData(templateDetails[0])
          const propertyFormRes = await Service.createOrUpdateOrDuplicatePropertyForm(
            pdbid,
            context,
            propertyFormData,
            0
          )

          if (propertyFormRes.ok) {
            const propertyForm = await propertyFormRes.json()
            dismiss()
            history.push(
              `/inspections-setup/property-forms/manage?form=${propertyForm[0].form_id}`
            )
          } else {
            setLoading(false)
            setError(true)
          }
        } else {
          setLoading(false)
          setError(true)
        }
      } catch (ex) {
        setError(true)
      } finally {
        setLoading(false)
      }
    } else {
      dismiss()
      history.push('/inspections-setup/property-forms/manage?action=new-form')
    }
  }

  return (
    <div className="propertyFormModal">
      {error && (
        <div className="columns">
          <div className="column is-full">
            <div className={`${error ? 'error' : ''}`}>
              <div className="error-container">
                <img src={warning} alt="warning" className="m-r-sm" />
                <span className="error-description">Error.</span> Something went
                wrong. Please try again.
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Select Starting Point
          </p>
          <p className="close is-pointer has-text-grey-light" onClick={dismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <label className={`checkbox ${!useTemplate || 'is-active'}`}>
            <span className={`checkbox-input ${!useTemplate || 'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={useTemplate}
                onChange={() => setUseTemplate(prevValue => !prevValue)}
              />
            </span>
            <span className={`checkbox-label ${!useTemplate || 'is-active'}`}>
              Create using template
            </span>
          </label>
        </div>
      </div>

      <div className="propertyFormModal__dropdown-container">
        <div className="m-l-lg m-r-md select-label propertyFormModal__dropdown-container__label">
          Template:
        </div>
        <div className="propertyFormModal__dropdown-container__dropdown">
          <MultiSelectDropdown
            isDisabled={!useTemplate}
            displayKey="template"
            value={parentSharedTemplate}
            defaultValues={sortedSharedTemplates}
            onChange={newValue => {
              setParentSharedTemplate(newValue)
            }}
            isMulti={false}
            isClearable
            placeholder="Search..."
            noOptionsMessage="No options available"
            menuPortalTarget={document.body}
            customStyles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              valueContainer: provided => ({
                ...provided,
                minHeight: '45px',
                maxHeight: '100px',
                overflow: 'auto',
                position: 'relative'
              })
            }}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <label className={`checkbox ${useTemplate || 'is-active'}`}>
            <span className={`checkbox-input ${useTemplate || 'is-active'}`}>
              <span className="checkbox-inner" />
              <input
                type="checkbox"
                className="checkbox-original"
                checked={!useTemplate}
                onChange={() => setUseTemplate(prevValue => !prevValue)}
              />
            </span>
            <span className={`checkbox-label ${useTemplate || 'is-active'}`}>
              Create without template
            </span>
          </label>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={dismiss}>
            Cancel
          </button>

          <button
            className="button main-button is-primary"
            onClick={submitHandler}
            disabled={useTemplate && isEmpty(parentSharedTemplate)}>
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Create Form
          </button>
        </div>
      </div>
    </div>
  )
}

NewPropertyFormModal.propTypes = {
  dismiss: PropTypes.func,
  history: PropTypes.object,
  sharedTemplates: PropTypes.array
}

export default NewPropertyFormModal
