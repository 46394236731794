import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getTabs, RenderTabsContent } from './tabs'
import InlineTabs from '../../../components/inline-tabs'

const PropertyFormsElements = ({ elements, isLoading, canDrag }) => {
  const [activeTab, setActiveTab] = useState({})
  const [tabs] = useState(getTabs(setActiveTab))

  useEffect(() => {
    setActiveTab(tabs[0])
  }, [])

  if (isLoading) {
    return (
      <div className="columns">
        <div className="column is-full">
          <div
            className="is-size-4 no-wrap"
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '120px',
              paddingBottom: '120px'
            }}>
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <InlineTabs tabs={tabs} style={{ top: -34 }} />
      {activeTab['id'] && (
        <RenderTabsContent
          activeTab={activeTab['id']}
          elements={elements}
          canDrag={canDrag}
        />
      )}
    </>
  )
}

export default PropertyFormsElements
