import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getPropertyFormsList } from '../../modules/property-forms'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import PropertyFormsMain from '../../parents/property-forms-main'
import BigLoading from '../../components/big-loading'

const PropertyForms = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <PropertyFormsMain {...props} />
  }

  return <BigLoading />
}

PropertyForms.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  propertyForms: PropTypes.object
}

const mapStateToProps = ({ user, context, propertyForms, modal }) => ({
  user,
  context,
  propertyForms,
  modal,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPropertyFormsList,
      openModal,
      closeModal,
      setModalContent,
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertyForms)
)
