import React from 'react'
import ActionColumn from './property-form-table/property-form-table-columns/action-column'
import DefaultColumn from './property-form-table/property-form-table-columns/default-column'
import NameColumn from './property-form-table/property-form-table-columns/name-column'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'

export const headerData = () => {
  return [
    {
      Header: 'Form Name',
      accessor: 'name',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Created By',
      accessor: 'created_by_name',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Created Date',
      accessor: 'created_at',
      width: 130,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Template Used',
      accessor: 'template',
      width: 150,
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },

    {
      Header: 'Type',
      accessor: 'type',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'State',
      accessor: 'state',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      width: 150,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: 'template_id',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return <ActionColumn row={row} />
      }
    }
  ]
}
