import React from 'react'
import EditField from '../edit-field'
import DatePicker from '../../components/date-picker'
import RestrictedElement from '../../containers/restricted-element'
import './index.scss'
import { currencyFormatter, formatISODateString } from '../../utils'
import SwitchButton from '../forms-helper/SwitchButton'

const InvoiceData = ({
  invoice,
  setInvoiceDate,
  setDueDate,
  setVendorInvoiceNumber,
  setDoNotSync,
  currentData,
  vendorInvoiceNumberCaveat
}) => {
  const invoiceDate =
    invoice?.po_date && invoice?.po_date !== ''
      ? new Date(invoice?.po_date)
      : new Date()

  const dueDate =
    invoice?.due_date && invoice?.due_date !== ''
      ? new Date(invoice?.due_date)
      : new Date()

  const invoicePoDate = invoice?.po_date
    ? formatISODateString(invoice.po_date)
    : 'N/A'

  const currentPoDate = currentData?.po_date
    ? new Date(currentData.po_date)
    : invoiceDate

  const invoiceDueDate = invoice?.due_date
    ? formatISODateString(invoice.due_date)
    : 'N/A'

  const currentDueDate = currentData?.due_date
    ? new Date(currentData.due_date)
    : dueDate

  const vendorPoNumber = invoice?.vendor_po_number
    ? invoice?.vendor_po_number
    : 'N/A'

  return (
    <div className="invoice-data">
      <div className="invoice-data__extra-info">
        <div className="invoice-data__item">
          <p className="invoice-data__title m-b-sm">Purchase Order For:</p>
          <p>
            <span className="is-size-6">{invoice?.vendor_name}</span>
          </p>
        </div>
        <div className="invoice-data__item">
          <p className="invoice-data__title m-b-sm">Created Date:</p>
          <RestrictedElement replacement={<span>{invoicePoDate}</span>}>
            {!invoice?.editing ? (
              <p className="date-string">{invoicePoDate}</p>
            ) : (
              <DatePicker value={currentPoDate} onChange={setInvoiceDate} />
            )}
          </RestrictedElement>
        </div>
        <div className="invoice-data__item">
          <p className="invoice-data__title m-b-sm">Due Date:</p>
          <RestrictedElement replacement={<span>{invoiceDueDate}</span>}>
            {!invoice?.editing ? (
              <p className="date-string">{invoiceDueDate}</p>
            ) : (
              <DatePicker value={currentDueDate} onChange={setDueDate} />
            )}
          </RestrictedElement>
        </div>
        <div className="invoice-data__item">
          <p className="invoice-data__title m-b-sm">P.O. Number:</p>
          <p>{invoice?.po_number}</p>
        </div>
        <div className="invoice-data__item">
          <p className="invoice-data__title m-b-sm">Vendor Invoice Number:</p>
          <RestrictedElement
            replacement={<span>{invoice?.vendor_po_number}</span>}>
            {!invoice?.editing ? (
              vendorPoNumber
            ) : (
              <>
                <EditField
                  id="vendor_po_number"
                  label="Add Number..."
                  locked={false}
                  active={false}
                  value={invoice?.vendor_po_number || ''}
                  onChange={value => {
                    setVendorInvoiceNumber(value)
                  }}
                  customInputContainerStyle={{ position: 'relative', top: -5 }}
                />
                <SwitchButton
                  removeMargin
                  isActive={invoice?.do_not_sync === 'true'}
                  rightLabel="Do Not Sync"
                  onChange={value => {
                    setDoNotSync(value)
                  }}
                />
              </>
            )}
          </RestrictedElement>
          {vendorInvoiceNumberCaveat && (
            <p
              class="animated bounce"
              style={{ color: '#ea3f32', fontSize: 12 }}>
              This field is needed to approve the purchase order
            </p>
          )}
        </div>
      </div>
      <div className="invoice-data__main-info m-t-sm">
        <p className="invoice-data__title m-b-sm">Amount Due (USD):</p>
        <p>
          <span className="invoice-data__amount-due">
            {currencyFormatter(invoice?.amount_due, { disableRounding: false })}
          </span>
        </p>
      </div>
    </div>
  )
}

export default InvoiceData
