import _, { isEmpty } from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { formatDateString } from '../utils'
import { UNIT_NOTES_CREATE_SUCCESS, UNIT_NOTES_DELETE_SUCCESS } from './notes'

import { MODAL_CLOSE } from './modal'

export const TURNBOARD_REQUESTED = 'turnboard/TURNBOARD_REQUESTED'
export const TURNBOARD_FAIL = 'turnboard/TURNBOARD_FAIL'
export const TURNBOARD_SUCCESS = 'turnboard/TURNBOARD_SUCCESS'

export const AS_IS_STATUS_REQUESTED = 'turnboard/AS_IS_STATUS_REQUESTED'
export const AS_IS_STATUS_SUCCESS = 'turnboard/AS_IS_STATUS_SUCCESS'
export const AS_IS_STATUS_ERROR = 'turnboard/AS_IS_STATUS_ERROR'

export const GROUP_OR_UNGROUP_UNITS_REQUESTED =
  'turnboard/GROUP_OR_UNGROUP_UNITS_REQUESTED'
export const GROUP_OR_UNGROUP_UNITS_SUCCESS =
  'turnboard/GROUP_OR_UNGROUP_UNITS_SUCCESS'
export const GROUP_OR_UNGROUP_UNITS_ERROR =
  'turnboard/GROUP_OR_UNGROUP_UNITS_ERROR'

export const ENABLE_SERVICES_REQUESTED = 'turnboard/ENABLE_SERVICES_REQUESTED'
export const ENABLE_SERVICES_SUCCESS = 'turnboard/ENABLE_SERVICES_SUCCESS'
export const ENABLE_SERVICES_ERROR = 'turnboard/ENABLE_SERVICES_ERROR'

export const MARK_OR_UNMARK_IN_HOUSE_REQUESTED =
  'turnboard/MARK_OR_UNMARK_IN_HOUSE_REQUESTED'
export const MARK_OR_UNMARK_IN_HOUSE_SUCCESS =
  'turnboard/MARK_OR_UNMARK_IN_HOUSE_SUCCESS'
export const MARK_OR_UNMARK_IN_HOUSE_ERROR =
  'turnboard/MARK_OR_UNMARK_IN_HOUSE_ERROR'

export const GET_ALL_GROUPS_REQUESTED = 'turnboard/GET_ALL_GROUPS_REQUESTED'
export const GET_ALL_GROUPS_SUCCESS = 'turnboard/GET_ALL_GROUPS_SUCCESS'
export const GET_ALL_GROUPS_ERROR = 'turnboard/GET_ALL_GROUPS_ERROR'

export const LEASE_OVERRIDE_REQUESTED = 'turnboard/LEASE_OVERRIDE_REQUESTED'
export const LEASE_OVERRIDE_SUCCESS = 'turnboard/LEASE_OVERRIDE_SUCCESS'
export const LEASE_OVERRIDE_ERROR = 'turnboard/LEASE_OVERRIDE_ERROR'

export const TURNBOARD_DATA_BY_PAGE = 'turnboard/TURNBOARD_DATA_BY_PAGE'
export const TURNBOARD_DATA_BY_FILTER = 'turnboard/TURNBOARD_DATA_BY_FILTER'
export const UPDATE_TURNBOARD_DATA_BY_STATE =
  'turnboard/UPDATE_TURNBOARD_DATA_BY_STATE'
export const UPDATE_TURNBOARD_DATA_BY_SORT =
  'turnboard/UPDATE_TURNBOARD_DATA_BY_SORT'
export const UPDATE_TURNBOARD_DATA = 'UPDATE_TURNBOARD_DATA'
export const GET_SCHEDULE_BY_SERVICE = 'GET_SCHEDULE_BY_SERVICE'
export const GET_TURNBOARD_ALL_TAGS = 'GET_TURNBOARD_ALL_TAGS'
export const GET_TURNBOARD_UNIT_TAGS = 'GET_TURNBOARD_UNIT_TAGS'
export const ADD_TURNBOARD_UNIT_TAG = 'ADD_TURNBOARD_UNIT_TAG'

export const SET_CURRENT_POPUP_UNIT = 'turnboard/SET_CURRENT_POPUP_UNIT'
export const CURRENT_SERVICES_AND_TASKS_GET_REQUESTED =
  'turnboard/CURRENT_SERVICES_AND_TASKS_GET_REQUESTED'
export const CURRENT_SERVICES_AND_TASKS_GET_FAIL =
  'turnboard/CURRENT_SERVICES_AND_TASKS_GET_FAIL'
export const CURRENT_SERVICES_AND_TASKS_GET_SUCCESS =
  'turnboard/CURRENT_SERVICES_AND_TASKS_GET_SUCCESS'

export const UPDATE_TURNBOARD_DATA_BY_FILTER =
  'turnboard/UPDATE_TURNBOARD_DATA_BY_FILTER'

export const OVERRIDE_AS_IS_PUT_REQUESTED =
  'turnboard/OVERRIDE_AS_IS_PUT_REQUESTED'
export const OVERRIDE_AS_IS_PUT_FAIL = 'turnboard/OVERRIDE_AS_IS_PUT_FAIL'
export const OVERRIDE_AS_IS_PUT_SUCCESS = 'turnboard/OVERRIDE_AS_IS_PUT_SUCCESS'

export const OVERRIDE_CURRENT_RESIDENT_PUT_REQUESTED =
  'turnboard/OVERRIDE_CURRENT_RESIDENT_PUT_REQUESTED'
export const OVERRIDE_CURRENT_RESIDENT_PUT_FAIL =
  'turnboard/OVERRIDE_CURRENT_RESIDENT_PUT_FAIL'
export const OVERRIDE_CURRENT_RESIDENT_PUT_SUCCESS =
  'turnboard/OVERRIDE_CURRENT_RESIDENT_PUT_SUCCESS'

export const OVERRIDE_SERVICE_PUT_REQUESTED =
  'turnboard/OVERRIDE_SERVICE_PUT_REQUESTED'
export const OVERRIDE_SERVICE_PUT_FAIL = 'turnboard/OVERRIDE_SERVICE_PUT_FAIL'
export const OVERRIDE_SERVICE_PUT_SUCCESS =
  'turnboard/OVERRIDE_SERVICE_PUT_SUCCESS'

export const UPDATE_SERVICE_STATUS_SUCCESS =
  'turnboard/UPDATE_SERVICE_STATUS_SUCCESS'

const leasedStatus = {
  Occupied: 'Leased',
  Vacant: 'Not Leased',
  Renewal: 'Renewal',
  'Renewal Transfer In': 'Renewal Transfer In',
  'Renewal Transfer Out': 'Renewal Transfer Out',
  Leapfrog: 'Leapfrog'
}

export const SAVE_SELECTED_ROWS = 'turnboard/SAVE_SELECTED_ROWS'

const initialState = {
  allGroups: null,
  getAllGroupsLoader: false,
  asIsStatusLoader: false,
  enableServicesLoader: false,
  markOrUnMarkInHouseLoader: false,
  groupOrUngroupUnitsLoader: false,
  leaseOverrideLoader: false,
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  turnboardLoader: false,
  data: {
    services: [],
    units: []
  },
  defaultData: {},
  detailData: {
    services: [],
    tasks: []
  },
  isRequestingDetail: false,
  isErrorDetail: false,
  detailUnit: null,
  isRequestingUpdate: false,
  isErrorUpdate: false,
  scheduleData: {
    schedule: []
  },
  allTags: [],
  unitTags: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case MODAL_CLOSE:
      return {
        ...state,
        detailUnit: null
      }
    case SET_CURRENT_POPUP_UNIT:
      return {
        ...state,
        detailUnit: action.unitid,
        isRequestingDetail: false,
        isErrorDetail: false,
        isRequestingUpdate: false,
        isErrorUpdate: false
      }
    case GET_SCHEDULE_BY_SERVICE: {
      // const { data } = action || {}
      // return {
      //   ...state,
      //   scheduleData: {
      //     schedule: []
      //   }
      // }
    }
    case GET_TURNBOARD_ALL_TAGS: {
      const { data } = action || {}
      return {
        ...state,
        allTags: data || []
      }
    }
    case GET_TURNBOARD_UNIT_TAGS: {
      const { data } = action || {}
      return {
        ...state,
        unitTags: data || []
      }
    }
    case TURNBOARD_REQUESTED:
      return {
        ...state,
        turnboardLoader: true,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case TURNBOARD_SUCCESS:
      const { data, page } = action || {}

      const { units } = data || {}

      const totalPages = Math.ceil((units || []).length / 100)

      return {
        ...state,
        isRequesting: false,
        isError: false,
        turnboardLoader: false,
        data: data || [],
        defaultData: data || [],
        activePage: 1,
        totalPages,
        activeItems: units || []
      }
    case TURNBOARD_DATA_BY_PAGE: {
      const { page } = action || {}

      const { data } = state || {}
      const { units } = data || {}

      return {
        ...state,
        isRequesting: false,
        isError: false,
        activePage: page,
        activeItems: (units || []).slice(0, 100 * page || 100)
      }
    }
    case UPDATE_TURNBOARD_DATA_BY_STATE: {
      const { data: actionData } = action || {}
      const { page, units } = actionData || {}
      const { data } = state || {}
      const { services } = data || {}
      return {
        ...state,
        isRequesting: false,
        isError: false,
        activePage: page,
        activeItems: (units || []).slice(0, 100 * page || 100),
        data: {
          units,
          services
        }
      }
    }
    case TURNBOARD_DATA_BY_FILTER: {
      const { filter } = action || {}
      const { data: turnboardData } = state || {}
      const { units } = turnboardData || {}
      const keys = Object.keys(filter)

      let filterData = []

      if ((keys || []).length === 0) {
        return {
          ...state,
          isRequesting: false,
          isError: false,
          activePage: 1,
          activeItems: (units || []).slice(0, 100)
        }
      }

      if ((keys || []).length === 1) {
        const { value, column } = filter[keys]
        const { key } = column || {}
        filterData = _.filter(units, function(o) {
          return (
            o[key]?.match(value) ||
            o[key]?.toLowerCase().match(value) ||
            o[key] === value
          )
        })
      }

      if ((keys || []).length > 1) {
        let condition = []
        ;(keys || []).forEach(item => {
          const { value, column } = filter[item]
          const { key } = column || {}
          condition[key] = value
        })

        filterData = _.filter(units, Object.assign({}, condition))
      }

      return {
        ...state,
        isRequesting: false,
        isError: false,
        activePage: page || 1,
        activeItems: filterData || []
      }
    }

    case UPDATE_TURNBOARD_DATA_BY_SORT: {
      const { activeItems } = state || {}
      const { data } = action || {}
      const { column, direction } = data || {}
      const { selector } = column || {}
      const newData = _.orderBy(activeItems, selector, direction)

      return {
        ...state,
        activeItems: newData || []
      }
    }

    case UPDATE_TURNBOARD_DATA_BY_FILTER: {
      const { data: actionData } = action || {}
      const { filters } = actionData || {}

      const {
        partially_occupied,
        is_full_asis,
        is_asis,
        fully_vacant,
        renewal_transfer_in,
        renewal_transfer_out,
        leapFrog,
        fully_renewed
      } = filters || {}

      const { data } = state || {}
      const { units } = data || {}

      let newData = []

      if ((Object.keys(filters) || []).length === 0) {
        const { defaultData } = state || {}
        return {
          ...state,
          data: defaultData || []
        }
      }

      const groupedUnits = _.chain(units || [])
        .groupBy('unit_id')
        .value()

      if (partially_occupied) {
        _.forEach(groupedUnits, item => {
          const hasOneRenewal = (item || []).some(
            space => space.lease_status === 'Renewal'
          )

          const hasOneNonRenewal = (item || []).some(
            space =>
              space.lease_status !== 'Renewal' && space.unit_space !== 'CA'
          )

          if (hasOneRenewal && hasOneNonRenewal) {
            newData = [...newData, ...item]
          }
        })
      }

      if (fully_vacant) {
        _.forEach(groupedUnits, (item, key) => {
          const is_fully_vacant =
            _.filter(item, o => o['lease_status'] === 'Renewal').length === 0
          if (is_fully_vacant) {
            newData = [...newData, ...item]
          }
        })
      }

      if (renewal_transfer_in) {
        _.forEach(groupedUnits, (item, key) => {
          const is_fully_vacant =
            _.filter(
              item,
              o =>
                o['lease_status'] === 'Transfer In' ||
                o['lease_status'] === 'Leapfrog'
            ).length > 0

          if (is_fully_vacant) {
            newData = [...newData, ...item]
          }
        })
      }

      if (renewal_transfer_out) {
        _.forEach(groupedUnits, (item, key) => {
          const is_fully_vacant =
            _.filter(
              item,
              o =>
                o['previous_lease_status'] === 'Transfer Out' ||
                o['previous_lease_status'] === 'Leapfrog'
            ).length > 0

          if (is_fully_vacant) {
            newData = [...newData, ...item]
          }
        })
      }

      if (leapFrog) {
        _.forEach(groupedUnits, (item, key) => {
          const is_fully_vacant =
            _.filter(item, o => o['lease_status'] === 'Leapfrog').length > 0
          if (is_fully_vacant) {
            newData = [...newData, ...item]
          }
        })
      }

      if (is_asis) {
        const filtered = (units || []).filter(unit => unit.is_asis)
        newData = [...newData, ...filtered]
      }

      if (is_full_asis) {
        _.forEach(groupedUnits, (item, key) => {
          const is_asis = (item || []).every(space => space.is_asis)
          if (is_asis) {
            newData = [...newData, ...item]
          }
        })
      }

      if (fully_renewed) {
        _.forEach(groupedUnits, (item, key) => {
          const isFullyRenewed = (item || []).every(
            space =>
              space.lease_status === 'Renewal' || space.unit_space === 'CA'
          )
          if (isFullyRenewed) {
            newData = [...newData, ...item]
          }
        })
      }

      return {
        ...state,
        data: {
          ...data,
          units: newData || []
        }
      }
    }

    case UPDATE_SERVICE_STATUS_SUCCESS:
      const { data: stateData } = state || {}
      const { data: actionData } = action || {}

      return {
        ...state,
        data: {
          ...stateData,
          units: actionData || []
        }
      }
    case TURNBOARD_FAIL:
      return {
        ...state,
        turnboardLoader: false,
        isRequesting: false,
        isError: true
      }
    case CURRENT_SERVICES_AND_TASKS_GET_REQUESTED:
      return {
        ...state,
        detailData: {
          services: [],
          tasks: []
        },
        isRequestingDetail: true,
        isErrorDetail: false,
        detailUnit: action.unitid
      }
    case CURRENT_SERVICES_AND_TASKS_GET_SUCCESS:
      return {
        ...state,
        detailData: action.data,
        isRequestingDetail: false,
        isErrorDetail: false
      }
    case CURRENT_SERVICES_AND_TASKS_GET_FAIL:
      return {
        ...state,
        isRequestingDetail: false,
        isErrorDetail: true
      }
    case OVERRIDE_AS_IS_PUT_REQUESTED:
    case OVERRIDE_CURRENT_RESIDENT_PUT_REQUESTED:
    case OVERRIDE_SERVICE_PUT_REQUESTED:
      return {
        ...state,
        isRequestingUpdate: true,
        isErrorUpdate: false
      }
    case OVERRIDE_AS_IS_PUT_SUCCESS:
    case OVERRIDE_CURRENT_RESIDENT_PUT_SUCCESS:
    case OVERRIDE_SERVICE_PUT_SUCCESS: {
      const unitIndex = state.data.units.findIndex(
        unit => unit.unit_id === action.data[0].unit_id
      )
      const newUnits =
        unitIndex >= 0
          ? state.data.units
              .slice(0, unitIndex)
              .concat([action.data[0]])
              .concat(state.data.units.slice(unitIndex + 1))
          : state.data.units
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: false,
        data: {
          ...state.data,
          units: newUnits
        }
      }
    }
    case OVERRIDE_AS_IS_PUT_FAIL:
    case OVERRIDE_CURRENT_RESIDENT_PUT_FAIL:
    case OVERRIDE_SERVICE_PUT_FAIL:
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: true
      }
    case UNIT_NOTES_CREATE_SUCCESS: {
      const index = _.findIndex(
        state.data.units,
        u => u.unit_id === action.unitId
      )
      return {
        ...state,
        data: {
          ...state.data,
          units: state.data.units
            .slice(0, index)
            .concat([
              { ...state.data.units[index], has_note: action.data.length > 0 }
            ])
            .concat(state.data.units.slice(index + 1))
        }
      }
    }
    case UNIT_NOTES_DELETE_SUCCESS: {
      const index = _.findIndex(
        state.data.units,
        u => u.unit_id === action.unitId
      )
      return {
        ...state,
        data: {
          ...state.data,
          units: state.data.units
            .slice(0, index)
            .concat([
              { ...state.data.units[index], has_note: action.data.length > 0 }
            ])
            .concat(state.data.units.slice(index + 1))
        }
      }
    }
    case AS_IS_STATUS_REQUESTED:
      return {
        ...state,
        asIsStatusLoader: true
      }
    case AS_IS_STATUS_SUCCESS:
    case AS_IS_STATUS_ERROR:
      return {
        ...state,
        asIsStatusLoader: false
      }
    case GROUP_OR_UNGROUP_UNITS_REQUESTED:
      return {
        ...state,
        groupOrUngroupUnitsLoader: true
      }
    case GROUP_OR_UNGROUP_UNITS_SUCCESS:
    case GROUP_OR_UNGROUP_UNITS_ERROR:
      return {
        ...state,
        groupOrUngroupUnitsLoader: false
      }
    case ENABLE_SERVICES_REQUESTED:
      return {
        ...state,
        enableServicesLoader: true
      }
    case ENABLE_SERVICES_SUCCESS:
    case ENABLE_SERVICES_ERROR:
      return {
        ...state,
        enableServicesLoader: false
      }

    case MARK_OR_UNMARK_IN_HOUSE_REQUESTED:
      return {
        ...state,
        markOrUnMarkInHouseLoader: true
      }
    case MARK_OR_UNMARK_IN_HOUSE_SUCCESS:
    case MARK_OR_UNMARK_IN_HOUSE_ERROR:
      return {
        ...state,
        markOrUnMarkInHouseLoader: false
      }

    case GET_ALL_GROUPS_REQUESTED:
      return {
        ...state,
        getAllGroupsLoader: true
      }
    case GET_ALL_GROUPS_SUCCESS:
      const { payload } = action

      return {
        ...state,
        allGroups: payload,
        getAllGroupsLoader: false
      }
    case GET_ALL_GROUPS_ERROR:
      return {
        ...state,
        getAllGroupsLoader: false
      }
    case LEASE_OVERRIDE_REQUESTED:
      return {
        ...state,
        leaseOverrideLoader: true
      }
    case LEASE_OVERRIDE_SUCCESS:
    case LEASE_OVERRIDE_ERROR:
      return {
        ...state,
        leaseOverrideLoader: false
      }
    case LOGOUT:
      return initialState

    default:
      return state
  }
}

export const getTurnboard = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: TURNBOARD_REQUESTED
    })
    Service.getTurnboard(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: TURNBOARD_SUCCESS,
            data: _turnboardAdapter(data.turnboard)
          })
        } else {
          _turnboardFail(dispatch)
        }
      })
      .catch(() => {
        _turnboardFail(dispatch)
      })
  }
}

export const getTurnboard2 = ({ pdbid, context, onSuccess, onError }) => {
  return async dispatch => {
    dispatch({
      type: TURNBOARD_REQUESTED
    })

    try {
      const [
        turnboardResponse,
        servicesStatusResponse,
        servicesCostResponse,
        inHouseServicesResponse
      ] = await Promise.all([
        Service.getTurnboardDetails(pdbid, context),
        Service.getTurnboardServiceStatus(pdbid, context),
        Service.getTurnboardServiceCost(pdbid, context),
        Service.getTurnboardInHouseServices(pdbid, context)
      ])

      if (
        turnboardResponse.ok &&
        servicesStatusResponse.ok &&
        servicesCostResponse.ok &&
        inHouseServicesResponse.ok
      ) {
        const data = await turnboardResponse.json()
        const servicesStatusData = await servicesStatusResponse.json()
        const servicesCostData = await servicesCostResponse.json()
        const inHouseServicesData = await inHouseServicesResponse.json()

        const { turnboard } = data || {}

        if ((ALL_UNITS || []).length > 0) {
          ALL_UNITS = []
        }

        const turnboardData = _turnboardAdapter2(turnboard)

        turnboardData.units = _servicesStatusAdapter(
          turnboardData,
          servicesStatusData
        )

        turnboardData.units = _servicesCostAdapter(
          turnboardData,
          servicesCostData
        )

        turnboardData.units = _inHouseServicesAdapter(
          turnboardData,
          inHouseServicesData
        )

        dispatch({
          type: TURNBOARD_SUCCESS,
          data: turnboardData
        })
        if (onSuccess) {
          onSuccess()
        }
      } else {
        _turnboardFail(dispatch)
        if (onError) {
          onError()
        }
      }
    } catch (err) {
      _turnboardFail(dispatch)
      if (onError) {
        onError()
      }
    }

    // Service.getTurnboardDetails(pdbid, context)
    //   .then(async res => {
    //     const { ok } = res || {}
    //     if (ok) {
    //       const data = await res.json()
    //       const { turnboard } = data || {}
    //       if ((ALL_UNITS || []).length > 0) {
    //         ALL_UNITS = []
    //       }
    //       dispatch({
    //         type: TURNBOARD_SUCCESS,
    //         data: _turnboardAdapter2(turnboard)
    //       })
    //       if (onSuccess) {
    //         onSuccess()
    //       }
    //     } else {
    //       _turnboardFail(dispatch)
    //       if (onError) {
    //         onError()
    //       }
    //     }
    //   })
    //   .catch(() => {
    //     _turnboardFail(dispatch)
    //     if (onError) {
    //       onError()
    //     }
    //   })
  }
}

export const getTurnboardServiceStatus = ({
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.getTurnboardServiceStatus(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SERVICE_STATUS_SUCCESS,
            data
          })

          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const updateAsIsStatus = ({
  data,
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return dispatch => {
    dispatch({
      type: AS_IS_STATUS_REQUESTED
    })
    Service.updateAsIsStatus(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: AS_IS_STATUS_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: AS_IS_STATUS_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: AS_IS_STATUS_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const groupOrUngroupUnits = ({
  data,
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return dispatch => {
    dispatch({
      type: GROUP_OR_UNGROUP_UNITS_REQUESTED
    })
    Service.groupOrUngroupUnits(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: GROUP_OR_UNGROUP_UNITS_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: GROUP_OR_UNGROUP_UNITS_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GROUP_OR_UNGROUP_UNITS_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const updateEnableServices = ({
  data,
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return dispatch => {
    dispatch({
      type: ENABLE_SERVICES_REQUESTED
    })
    Service.updateEnableServices(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: ENABLE_SERVICES_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: ENABLE_SERVICES_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: ENABLE_SERVICES_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const markInHouse = ({ data, pdbid, context, onSuccess, onError }) => {
  return dispatch => {
    dispatch({
      type: MARK_OR_UNMARK_IN_HOUSE_REQUESTED
    })
    Service.markInHouse(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: MARK_OR_UNMARK_IN_HOUSE_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const unMarkInHouse = ({ data, pdbid, context, onSuccess, onError }) => {
  return dispatch => {
    dispatch({
      type: MARK_OR_UNMARK_IN_HOUSE_REQUESTED
    })
    Service.unMarkInHouse(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: MARK_OR_UNMARK_IN_HOUSE_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const markAndUnMarkInHouse = ({
  data: { markedData, unMarkedData },
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return async dispatch => {
    dispatch({
      type: MARK_OR_UNMARK_IN_HOUSE_REQUESTED
    })

    try {
      const [markInHouseResponse, unMarkInHouseResponse] = await Promise.all([
        Service.markInHouse(markedData, pdbid, context),
        Service.unMarkInHouse(unMarkedData, pdbid, context)
      ])

      if (markInHouseResponse.ok && unMarkInHouseResponse.ok) {
        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_SUCCESS
        })

        if (onSuccess) {
          onSuccess()
        }
      } else {
        dispatch({
          type: MARK_OR_UNMARK_IN_HOUSE_ERROR
        })

        if (onError) {
          onError()
        }
      }
    } catch (err) {
      dispatch({
        type: MARK_OR_UNMARK_IN_HOUSE_ERROR
      })

      if (onError) {
        onError()
      }
    }
  }
}

export const getAllGroups = ({ pdbid, context, onSuccess, onError }) => {
  return dispatch => {
    dispatch({
      type: GET_ALL_GROUPS_REQUESTED
    })
    Service.getAllGroups(pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          const allGroups = await res.json()

          dispatch({
            type: GET_ALL_GROUPS_SUCCESS,
            payload: allGroups
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: GET_ALL_GROUPS_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: GET_ALL_GROUPS_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const updateLeaseOverride = ({
  data,
  pdbid,
  context,
  onSuccess,
  onError
}) => {
  return dispatch => {
    dispatch({
      type: LEASE_OVERRIDE_REQUESTED
    })
    Service.updateLeaseOverride(data, pdbid, context)
      .then(async res => {
        const { ok } = res || {}

        if (ok) {
          dispatch({
            type: LEASE_OVERRIDE_SUCCESS
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: LEASE_OVERRIDE_ERROR
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        dispatch({
          type: LEASE_OVERRIDE_ERROR
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const getTurnboardDataByPage = ({ data, onSuccess }) => {
  const { page } = data || {}
  return dispatch => {
    dispatch({
      type: TURNBOARD_DATA_BY_PAGE,
      page
    })
    onSuccess()
  }
}

export const getTurnboardDataByFilter = ({ filter, onSuccess }) => {
  return dispatch => {
    dispatch({
      type: TURNBOARD_DATA_BY_FILTER,
      filter
    })
    onSuccess()
  }
}

export const getTurnboardDataBySort = ({ column, direction }) => {
  return dispatch => {
    return dispatch({
      type: UPDATE_TURNBOARD_DATA_BY_SORT,
      data: {
        column,
        direction
      }
    })
  }
}

export const getTurnboardDataFilter = ({ filters }) => {
  return dispatch => {
    return dispatch({
      type: UPDATE_TURNBOARD_DATA_BY_FILTER,
      data: {
        filters
      }
    })
  }
}

export const updateTurnboardDataByState = ({ data }) => {
  return dispatch => {
    dispatch({
      type: UPDATE_TURNBOARD_DATA_BY_STATE,
      data
    })
  }
}

export const setDetailUnit = unitid => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_POPUP_UNIT,
      unitid
    })
  }
}

export const unitDetailsGet = (pdbid, context, unitid, usid) => {
  return dispatch => {
    dispatch({
      type: CURRENT_SERVICES_AND_TASKS_GET_REQUESTED,
      unitid
    })
    Service.unitDetailsGet(pdbid, context, usid)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CURRENT_SERVICES_AND_TASKS_GET_SUCCESS,
            data: _detailsAdapter(data.turnboard_task)
          })
        } else {
          _detailsFail(dispatch)
        }
      })
      .catch(() => {
        _detailsFail(dispatch)
      })
  }
}

export const getScheduleByService = ({
  pdbid,
  context,
  id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.getScheduleByService(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          // dispatch({
          //   type: GET_SCHEDULE_BY_SERVICE,
          //   data
          // })
          if (onSuccess) {
            onSuccess(data)
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const getTurnboardAllTags = ({ pdbid, context, onSuccess, onError }) => {
  return dispatch => {
    Service.getTurnboardAllTags(pdbid, context)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_TURNBOARD_ALL_TAGS,
            data
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const addTurnboardAllTags = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.addTurnboardAllTags(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const deleteTurnboardAllTags = ({
  pdbid,
  context,
  id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.deleteTurnboardAllTags(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const getTurnboardUnitTags = ({
  pdbid,
  context,
  id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.getTurnboardUnitTags(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: GET_TURNBOARD_UNIT_TAGS,
            data
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const addTurnboardUnitTag = ({
  pdbid,
  context,
  id,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.addTurnboardUnitTag(pdbid, context, id, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const deleteTurnboardUnitTag = ({
  pdbid,
  context,
  id,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.deleteTurnboardUnitTag(pdbid, context, id, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(err => {
        if (onError) {
          onError()
        }
      })
  }
}

export const overrideAsIs = (pdbid, context, unitid, body = {}) => {
  return dispatch => {
    dispatch({
      type: OVERRIDE_AS_IS_PUT_REQUESTED
    })
    Service.overrideAsIs(pdbid, context, unitid, body)
      .then(async res => {
        if (res.ok) {
          try {
            const json = await res.json()
            dispatch({
              type: OVERRIDE_AS_IS_PUT_SUCCESS,
              data: _unitsAdapter(json.turnboard.units, json.turnboard.services)
            })
          } catch {
            dispatch({
              type: OVERRIDE_AS_IS_PUT_SUCCESS,
              data: {}
            })
          }
        } else {
          dispatch({
            type: OVERRIDE_AS_IS_PUT_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: OVERRIDE_AS_IS_PUT_FAIL
        })
      })
  }
}

export const switchResident = (pdbid, context, unitid, body = {}) => {
  return dispatch => {
    dispatch({
      type: OVERRIDE_CURRENT_RESIDENT_PUT_REQUESTED
    })
    Service.switchResident(pdbid, context, unitid, body)
      .then(async res => {
        if (res.ok) {
          try {
            const json = await res.json()
            dispatch({
              type: OVERRIDE_CURRENT_RESIDENT_PUT_SUCCESS,
              data: _unitsAdapter(json.turnboard.units, json.turnboard.services)
            })
          } catch {
            dispatch({
              type: OVERRIDE_CURRENT_RESIDENT_PUT_SUCCESS,
              data: {}
            })
          }
        } else {
          dispatch({
            type: OVERRIDE_CURRENT_RESIDENT_PUT_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: OVERRIDE_CURRENT_RESIDENT_PUT_FAIL
        })
      })
  }
}

export const overrideTask = (pdbid, context, unitid, body = {}) => {
  return dispatch => {
    dispatch({
      type: OVERRIDE_SERVICE_PUT_REQUESTED
    })
    Service.overrideTask(pdbid, context, unitid, body)
      .then(async res => {
        if (res.ok) {
          try {
            const json = await res.json()
            dispatch({
              type: OVERRIDE_SERVICE_PUT_SUCCESS,
              data: _unitsAdapter(json.turnboard.units, json.turnboard.services)
            })
          } catch {
            dispatch({
              type: OVERRIDE_SERVICE_PUT_SUCCESS,
              data: {}
            })
          }
        } else {
          dispatch({
            type: OVERRIDE_SERVICE_PUT_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: OVERRIDE_SERVICE_PUT_FAIL
        })
      })
  }
}

const _turnboardAdapter2 = data => {
  const units = _unitsAdapter2(data.units, data.services)
  return {
    services: Array.isArray(data.services)
      ? _servicesAdapter(data.services)
      : [],
    units
  }
}

const _servicesStatusAdapter = (data, servicesStatus) => {
  const { units: currentUnits, services: allServices } = data || {}
  if ((servicesStatus || []).length === 0) {
    return currentUnits
  }

  const newUnitsData = (currentUnits || []).map(item => {
    const { unit_id, unit_space, is_asis, lease_status, ca_needed } = item || {}

    const serviceByStatus = (servicesStatus || []).find(
      item =>
        item.unit_id === unit_id &&
        (item.unitspace === unit_space || item.unitspace === 'Common')
    )
    const isCaNeedNA = ca_needed === 'N/A' && unit_space === 'CA'

    if (serviceByStatus) {
      const { services } = serviceByStatus || {}
      const serviceNames = (services || []).map(({ service }) => service)
      const restServices = (allServices || [])
        .filter(
          ({ servicetype }) => !(serviceNames || []).includes(servicetype)
        )
        .map(({ servicetype }) => ({ service: servicetype, status: 'N/A' }))

      const totalServices = [...services, ...(restServices || [])]
      const formatServices = []

      const d = (totalServices || []).forEach(serviceItem => {
        const { service, status } = serviceItem || {}
        const serviceName = (service || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        if (serviceName === 'walk' && lease_status !== 'Renewal') {
          formatServices[`${serviceName}_status`] = status
        } else if (!is_asis && lease_status !== 'Renewal' && !isCaNeedNA) {
          formatServices[`${serviceName}_status`] = status
        }

        if (
          (serviceName === 'walk' && lease_status !== 'Renewal') ||
          (!is_asis && lease_status !== 'Renewal')
        ) {
          if (
            status === 'Unassigned' ||
            status === 'Assigned' ||
            status === 'Not Assigned'
          ) {
            formatServices[`${serviceName}_overview_status`] = 'Not Started'
          } else if (status === 'In Progress' || status === 'QC') {
            formatServices[`${serviceName}_overview_status`] = 'In Progress'
          } else if (status === 'Approved') {
            formatServices[`${serviceName}_overview_status`] = 'Completed'
          } else {
            formatServices[`${serviceName}_overview_status`] =
              item[`${serviceName}_overview_status`]
          }
        }
      })

      return {
        ...item,
        ...formatServices
      }
    }

    return {
      ...item
    }
  })

  let isWalkComplete = false
  const scannedUnits = []

  const updatedUnitsData = (newUnitsData || []).map(item => {
    if (!scannedUnits.includes(item?.unit_id)) {
      const singleUnitSpaces = (newUnitsData || []).filter(
        unitItem => unitItem?.unit_id === item?.unit_id
      )

      if (
        (singleUnitSpaces || []).some(
          unitSpace =>
            (unitSpace['walk_overview_status'] || '').toLowerCase() ===
            'completed'
        )
      ) {
        isWalkComplete = true
      } else {
        isWalkComplete = false
      }

      scannedUnits.push(item?.unit_id)
    }

    if (!isWalkComplete) {
      ;(allServices || []).forEach(({ servicetype }) => {
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        if (item[`${serviceName}_overview_status`] === 'Not Needed') {
          item[`${serviceName}_overview_status`] = 'Pending Walk'
        }
      })
    }

    const inCompleteOverviewStatuses = [
      'In Progress',
      'Not Started',
      'Pending Walk',
      'N/A'
    ]

    const areAllOverviewStatusesGreen = (allServices || []).every(
      ({ servicetype }) => {
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        return !inCompleteOverviewStatuses.includes(
          item[`${serviceName}_overview_status`]
        )
      }
    )

    const isUnitSpaceCompleted = (allServices || []).every(
      ({ servicetype }) => {
        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        return item[`${serviceName}_status`] === 'Approved'
      }
    )

    return {
      ...item,
      isWalkComplete,
      isUnitSpaceCompleted,
      areAllOverviewStatusesGreen
    }
  })

  return updatedUnitsData
}

const _servicesCostAdapter = (data, servicesCost) => {
  const { units: currentUnits, services: allServices } = data || {}

  if ((servicesCost || []).length === 0) {
    return currentUnits
  }

  const newUnitsData = (currentUnits || []).map(item => {
    const { unit_space, unit_space_id, is_asis, lease_status, ca_needed } =
      item || {}

    const foundUnitSpace = (servicesCost || []).find(
      item => item.unitspace_id === unit_space_id
    )

    const isCaNeedNA = ca_needed === 'N/A' && unit_space === 'CA'

    if (foundUnitSpace) {
      const { services } = foundUnitSpace || {}
      const serviceNames = (services || []).map(({ service }) => service)
      const restServices = (allServices || [])
        .filter(
          ({ servicetype }) =>
            !(serviceNames || []).includes(servicetype) &&
            servicetype !== 'Walk'
        )
        .map(({ servicetype }) => ({
          service: servicetype,
          estimated_cost: 0,
          additional_cost: 0,
          final_cost: 0
        }))

      const totalServices = [...services, ...(restServices || [])]
      const formatServices = []

      const d = (totalServices || []).forEach(serviceItem => {
        const { service, additional_cost, estimated_cost, final_cost } =
          serviceItem || {}

        const serviceName = (service || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        if (lease_status !== 'Renewal' && !is_asis && !isCaNeedNA) {
          formatServices[`${serviceName}_estimated_cost`] = estimated_cost
        }

        if (lease_status !== 'Renewal' && !is_asis) {
          formatServices[`${serviceName}_additional_cost`] = additional_cost
          formatServices[`${serviceName}_final_cost`] = final_cost
        }
      })

      return {
        ...item,
        ...formatServices
      }
    }

    return {
      ...item
    }
  })

  return newUnitsData
}

const _inHouseServicesAdapter = (data, inHouseServices) => {
  const { units: currentUnits, services: allServices } = data || {}
  const walkExcludedServices = (allServices || []).filter(
    ({ servicetype }) => servicetype !== 'Walk'
  )

  if ((inHouseServices || []).length === 0) {
    return currentUnits
  }

  const newUnitsData = (currentUnits || []).map(item => {
    const {
      unit_space,
      unit_space_id,
      unit_id,
      unit,
      is_asis,
      ca_needed,
      areAllOverviewStatusesGreen
    } = item || {}

    const foundServices = (inHouseServices || []).filter(
      item => item.unit_space_id === unit_space_id
    )

    if (foundServices && foundServices.length) {
      const serviceNames = (foundServices || []).map(
        ({ servicetype }) => servicetype
      )
      const restServices = (walkExcludedServices || [])
        .filter(
          ({ servicetype }) => !(serviceNames || []).includes(servicetype)
        )
        .map(({ servicetype, servicetype_id }) => ({
          inhouse_id: null,
          servicetype_id,
          unit_space_id: unit_space_id,
          status: 'N/A',
          model: '',
          servicetype,
          unit_space: unit_space,
          unit_id: unit_id,
          unit: unit
        }))

      const totalServices = [...foundServices, ...(restServices || [])]
      const formatServices = []

      const d = (totalServices || []).forEach(serviceItem => {
        const { status, inhouse_id, servicetype } = serviceItem || {}

        const serviceName = (servicetype || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()

        formatServices[`${serviceName}_inhouse_status`] = status
        formatServices[`${serviceName}_inhouse_id`] = inhouse_id

        if (inhouse_id) {
          formatServices[`${serviceName}_team`] = 'In House'
          formatServices[`${serviceName}_option`] = 'In House'
          formatServices[`${serviceName}_assigned`] = 'In House'
          formatServices[`${serviceName}_complete`] = 'In House'
          formatServices[`${serviceName}_overview_status`] = 'In House'
          formatServices[`${serviceName}_status`] = status
          formatServices[`${serviceName}_estimated_cost`] = 0
          formatServices[`${serviceName}_additional_cost`] = 0
          formatServices[`${serviceName}_final_cost`] = 0
        }
      })

      const isCaNeededIsNA = unit_space === 'CA' && ca_needed === 'N/A'

      const filteredServices = (totalServices || []).filter(
        ({ status }) => status !== 'N/A'
      )

      const isAsIsOrCaNeededNaAndSomeApproved =
        (is_asis || isCaNeededIsNA) &&
        (filteredServices || []).every(
          ({ inhouse_id, status }) =>
            !isEmpty(inhouse_id) && status === 'Approved'
        )

      const overviewStatusesGreenAndSomeApproved =
        areAllOverviewStatusesGreen &&
        (filteredServices || []).every(
          ({ inhouse_id, status }) =>
            !isEmpty(inhouse_id) && status === 'Approved'
        )

      const isInHouseCompleted = (totalServices || []).every(
        ({ inhouse_id, status }) =>
          !isEmpty(inhouse_id) && status === 'Approved'
      )

      const updatedUnitSpace = {
        ...item,
        ...formatServices,
        isInHouseCompleted:
          isInHouseCompleted ||
          isAsIsOrCaNeededNaAndSomeApproved ||
          overviewStatusesGreenAndSomeApproved,
        // isWalkComplete: true,
        is_inhouse: true
      }

      const isUnitSpaceCompleted = (totalServices || []).every(
        ({ servicetype }) => {
          const serviceName = (servicetype || '')
            .toString()
            .replace(' ', '_')
            .toLowerCase()
          return updatedUnitSpace[`${serviceName}_status`] === 'Approved'
        }
      )

      return {
        ...updatedUnitSpace,
        isUnitSpaceCompleted:
          isUnitSpaceCompleted || updatedUnitSpace?.isUnitSpaceCompleted
      }
    }

    return {
      ...item
    }
  })

  return newUnitsData
}

const checkFirstRow = (units, unit) => {
  if (!units.includes(unit)) {
    return true
  }
  return false
}

const getKeysIconText = status => {
  if (status === 'Yes') {
    return 'Returned'
  } else if (status === 'R') return 'Renewal'
  else if (status === 'V') {
    return 'Vacant'
  } else if (status === 'holdover') return 'Holdover'
  else if (status === 'No') {
    return 'Expected'
  } else return 'Expected'
}

const EMPTY2 = ''
let ALL_UNITS = []
const _unitsAdapter2 = (data, services) => {
  let unitIndex = 0
  return (data || []).flatMap((d, i) => {
    const { unit_spaces, unit_id, unit } = d || {}
    let updatedData = []
    ;(unit_spaces || []).map(uSpace => {
      const {
        unit_space,
        services: unit_services,
        is_asis,
        enable_service,
        orig_key_status,
        tanent_status
      } = uSpace || {}

      let caNeeded = typeof d.ca_needed === 'string' ? d.ca_needed : EMPTY2
      const unitSpace = (unit_space || '') === 'Common' ? 'CA' : unit_space

      uSpace.lease_status =
        enable_service && uSpace.lease_status === 'Renewal'
          ? 'Renewal - Override'
          : uSpace.lease_status
      caNeeded =
        enable_service && (caNeeded === 'N/A' && unitSpace === 'CA')
          ? 'Override'
          : caNeeded

      // Get services and convert it into object to get value from key
      const getAllServiceType = (
        (services || []).map(service => service.servicetype) || []
      ).reduce((ac, a) => ({ ...ac, [a]: a }), {})
      // Convert object of service data based on services key

      const unitServiceNames = (unit_services || []).map(
        ({ service }) => service
      )
      const restServices = (services || []).filter(
        ({ servicetype }) => !(unitServiceNames || []).includes(servicetype)
      )

      const updatedRestServices = (restServices || []).map(
        ({ servicetype }) => ({
          service: servicetype,
          additional_cost: 0,
          assigned: '',
          complete: null,
          estimated_cost: 0,
          final_cost: 0,
          option: '',
          task_id: '',
          team: '',
          workorder_id: ''
        })
      )

      const updatedUnitServices = (unit_services || []).map(unitService => ({
        ...unitService,
        additional_cost: 0,
        estimated_cost: 0,
        final_cost: 0
      }))

      const allUnitServices = (updatedRestServices || []).concat(
        updatedUnitServices || []
      )

      const allServicesData = (allUnitServices || []).map(uService => {
        const {
          team,
          option,
          assigned,
          complete,
          estimated_cost,
          service,
          additional_cost,
          final_cost,
          task_id,
          workorder_id
        } = uService || {}
        let serviceName = getAllServiceType[service]
        const originalServiceName = getAllServiceType[service]
        serviceName = (serviceName || '')
          .toString()
          .replace(' ', '_')
          .toLowerCase()
        const allUnitService = (allUnitServices || [])
          .filter(d => d.service === originalServiceName)
          .map(d => d.option)
        const convertToString = (allUnitService || [])
          .reverse()
          .toString()
          .replace(/,/g, ', ')

        let serviceOption = convertToString
        let newTeam = team
        let newAssigned = assigned
          ? formatDateString(assigned, 'MM/DD/YYYY')
          : serviceName === 'walk'
          ? ''
          : 'N/A'

        let newComplete = complete
          ? formatDateString(complete, 'MM/DD/YYYY')
          : serviceName === 'walk'
          ? ''
          : 'N/A'

        let newEstimatedCost = estimated_cost
        let newAdditionalCost = additional_cost
        let newFinalCost = final_cost
        let newStatus = 'N/A'
        let newOverviewStatus = 'N/A'

        if (uSpace?.lease_status === 'Renewal') {
          serviceOption = 'Renewal'
          newTeam = 'Renewal'
          newAssigned = 'Renewal'
          newComplete = 'Renewal'
          newStatus = 'Renewal'
          newOverviewStatus = 'Renewal'
        }

        if (serviceName !== 'walk') {
          if (is_asis) {
            serviceOption = 'As Is'
            newTeam = 'As Is'
            newAssigned = 'As Is'
            newComplete = 'As Is'
            newStatus = 'As Is'
            newOverviewStatus = 'As Is'
          } else if (caNeeded === 'N/A' && unitSpace === 'CA') {
            serviceOption = 'N/A'
            newTeam = 'N/A'
            newAssigned = 'N/A'
            newComplete = 'N/A'
            newOverviewStatus = 'N/A'
          } else if (!serviceOption) {
            serviceOption = 'N/A'
            newTeam = 'N/A'
            newAssigned = 'N/A'
            newComplete = 'N/A'
            newOverviewStatus = 'Not Needed'
          }
        }

        return {
          [`${serviceName}_team`]: newTeam,
          [`${serviceName}_option`]: serviceOption,
          [`${serviceName}_assigned`]: newAssigned,
          [`${serviceName}_complete`]: newComplete,
          [`${serviceName}_estimated_cost`]: newEstimatedCost,
          [`${serviceName}_additional_cost`]: newAdditionalCost,
          [`${serviceName}_final_cost`]: newFinalCost,
          [`${serviceName}_task_id`]: task_id,
          [`${serviceName}_all_assigned_services`]: allUnitService || [],
          [`${serviceName}_workorder_id`]: workorder_id,
          [`${serviceName}_status`]: newStatus,
          [`${serviceName}_overview_status`]: newOverviewStatus,
          [`${serviceName}_inhouse_status`]: 'N/A'
        }
      })
      // Convert objects into single object
      const allServicesDataToObject = Object.assign({}, ...allServicesData)
      // Set isFirstRow to true of every unit
      const isFirstRow = checkFirstRow(ALL_UNITS, unit_id)
      if (isFirstRow) {
        unitIndex = unitIndex + 1
      }

      ALL_UNITS.push(unit_id)
      // Create Object
      updatedData.push({
        ...uSpace,
        unit_space: unitSpace,
        isFirstSpaceRow: isFirstRow,
        ...allServicesDataToObject,
        unitIndex,
        key_status_text:
          unitSpace !== 'CA' ? getKeysIconText(orig_key_status) : null,
        unit: typeof d.unit === 'string' ? d.unit : EMPTY2,
        tags: typeof d.tags === 'object' ? d.tags : [],
        simplifiedTags:
          typeof d.tags === 'object'
            ? ((d.tags || []).map(d => d.text) || []).join(', ')
            : [],
        unit_id: typeof d.unit_id === 'string' ? d.unit_id : EMPTY2,
        unit_progress:
          typeof d.unit_progress === 'string' ? d.unit_progress : EMPTY2,
        building: typeof d.building === 'string' ? d.building : EMPTY2,
        floorplan: typeof d.floorplan === 'string' ? d.floorplan : EMPTY2,
        floor: typeof d.floor === 'string' ? d.floor : EMPTY2,
        ca_needed: caNeeded,
        unit_type: typeof d.unit_type === 'string' ? d.unit_type : EMPTY2,
        furnished: typeof d.furnished === 'string' ? d.furnished : EMPTY2,
        overall_progress: _getOverallProgress(d, services),
        has_note: typeof d.has_note === 'string',
        is_transfer:
          d.unit_spaces.filter(us => typeof us.is_transfer === 'string')
            .length > 0,
        is_asis: is_asis ? true : false,
        lease_status: is_asis ? 'As Is' : uSpace.lease_status,
        previous_lease_status: is_asis ? 'As Is' : uSpace.previous_lease_status,
        current_lease_status: is_asis ? 'As Is' : uSpace.current_lease_status,
        enable_service: enable_service ? true : false,
        is_inhouse: false,
        isUnitSpaceCompleted: false,
        areAllOverviewStatusesGreen: false,
        is_full_asis: _.every(
          d.unit_spaces,
          us => typeof us.is_asis === 'string'
        ),
        fully_vacant: (() => {
          const renewals = _.get(d, 'unit_spaces', []).filter(
            us => !us.lease_status || us.lease_status.toLowerCase() === 'vacant'
          ).length
          const commonArea = 1
          return (
            renewals - commonArea ===
            _.get(d, 'unit_spaces', []).length - commonArea
          )
        })(),
        partially_occupied: (() => {
          const renewals = _.get(d, 'unit_spaces', []).filter(
            us => us.lease_status === 'Renewal'
          ).length
          const commonArea = 1
          return (
            renewals > 0 &&
            renewals !== _.get(d, 'unit_spaces', []).length - commonArea
          )
        })()
      })
    })
    return updatedData
  })
}

const _turnboardAdapter = data => {
  const units = _unitsAdapter(data.units, data.services)
  return {
    services: Array.isArray(data.services)
      ? _servicesAdapter(data.services)
      : [],
    units
  }
}

const _servicesAdapter = data => {
  return data.map(d => ({
    servicetype_uri:
      typeof d.servicetype_uri === 'string' ? d.servicetype_uri : EMPTY,
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : EMPTY,
    servicetype: typeof d.servicetype === 'string' ? d.servicetype : EMPTY,
    label: typeof d.label === 'string' ? d.label : EMPTY,
    services_options: d.services_options ? d.services_options : []
  }))
}

const _getOverallProgress = (unit, services) => {
  const services_fields = _.map(services, s => s.servicetype)
  const unitspace_progress = _.map(services_fields, field =>
    _.every(
      _.get(unit, 'unit_spaces'),
      us =>
        _.get(us, field, '0').split(':')[0] === '-99' ||
        _.get(us, field, '0').split(':')[0] === '100'
    )
  )
  return Math.round(
    (_.filter(unitspace_progress, usp => usp).length / services_fields.length) *
      100
  )
}

const EMPTY = ''
const _unitsAdapter = (data, services) => {
  return data.map((d, i) => {
    return {
      unit: typeof d.unit === 'string' ? d.unit : EMPTY,
      unit_id: typeof d.unit_id === 'string' ? d.unit_id : EMPTY,
      building: typeof d.building === 'string' ? d.building : EMPTY,
      floorplan: typeof d.floorplan === 'string' ? d.floorplan : EMPTY,
      unit_spaces: Array.isArray(d.unit_spaces)
        ? _unitSpacesAdapter(d.unit_spaces)
        : [],
      overall_progress: _getOverallProgress(d, services),
      has_note: typeof d.has_note === 'string',
      is_transfer:
        d.unit_spaces.filter(us => typeof us.is_transfer === 'string').length >
        0,
      is_asis:
        d.unit_spaces.filter(us => typeof us.is_asis === 'string').length > 0,
      is_full_asis: _.every(
        d.unit_spaces,
        us => typeof us.is_asis === 'string'
      ),
      fully_vacant: (() => {
        const renewals = _.get(d, 'unit_spaces', []).filter(
          us => !us.lease_status || us.lease_status.toLowerCase() === 'vacant'
        ).length
        const commonArea = 1
        return (
          renewals - commonArea ===
          _.get(d, 'unit_spaces', []).length - commonArea
        )
      })(),
      partially_occupied: (() => {
        const renewals = _.get(d, 'unit_spaces', []).filter(
          us => us.lease_status === 'Renewal'
        ).length
        const commonArea = 1
        return (
          renewals > 0 &&
          renewals !== _.get(d, 'unit_spaces', []).length - commonArea
        )
      })()
    }
  })
}

const _validateString = str => typeof str === 'string' && str !== ' '

const _unitSpacesAdapter = data => {
  const common = _.find(data, d => d.unit_space === 'Common') || {}
  return data.map(d => _unitSpaceAdapter(d, common))
}

const UNIT_SPACE_STRING_FIELDS = [
  'unit_space_id',
  'unit_space',
  'current_resident',
  'current_email',
  'future_resident',
  'future_email',
  'key_status',
  'lease_status'
]

const OVERRIDE_KEYS = ['Punch', 'Sparkle']

const _unitSpaceAdapter = (unit_space, defaults) => {
  const transformed = {}
  const reOpt = new RegExp(/Opt$/)
  _.forEach(Object.keys(unit_space), key => {
    if (UNIT_SPACE_STRING_FIELDS.indexOf(key) >= 0) {
      transformed[key] = _validateString(unit_space[key])
        ? unit_space[key]
        : EMPTY
    } else if (reOpt.test(key)) {
      const baseName = key.replace(reOpt, '')
      if (
        OVERRIDE_KEYS.indexOf(baseName) >= 0 &&
        unit_space[baseName] !== -99
      ) {
        transformed[key] = _validateString(defaults[key])
          ? defaults[key]
          : EMPTY
      } else {
        transformed[key] = _validateString(unit_space[key])
          ? unit_space[key]
          : EMPTY
      }
    } else if (key === 'allow_as_is') {
      transformed.allow_as_is = typeof unit_space.allow_as_is === 'string'
    } else if (key === 'is_transfer') {
      transformed.is_transfer = typeof unit_space.is_transfer === 'string'
    } else if (key === 'is_asis') {
      transformed.is_asis = typeof unit_space.is_asis === 'string'
    } else if (
      unit_space.unit_space !== 'Common' &&
      OVERRIDE_KEYS.indexOf(key) >= 0 &&
      _.get(unit_space, 'lease_status', '') !== 'Renewal' &&
      _.get(unit_space, 'lease_status', '') !== 'As Is'
    ) {
      // transformed[key] = typeof defaults[key] === 'string' ? parseFloat(defaults[key]) : 0
      const [value, label] = unit_space[key].split(':')
      transformed[key] = {
        value: typeof value === 'string' ? parseFloat(value) : 0,
        label: label
      }
    } else {
      // transformed[key] = typeof unit_space[key] === 'string' ? parseFloat(unit_space[key]) : 0
      const [value, label] = unit_space[key].split(':')
      transformed[key] = {
        value: typeof value === 'string' ? parseFloat(value) : 0,
        label: label
      }
    }

    if (key === 'lease_status') {
      transformed[key] =
        unit_space[key] === 'Vacant'
          ? 'Not Leased'
          : unit_space[key] === 'Occupied'
          ? 'Leased'
          : unit_space[key]
    }
  })

  return transformed
}

const _turnboardFail = dispatch => {
  dispatch({
    type: TURNBOARD_FAIL
  })
}

const _detailsAdapter = data => {
  return {
    services: Array.isArray(data.services)
      ? _detailServicesAdapter(data.services)
      : [],
    tasks: Array.isArray(data.tasks) ? _detailTasksAdapter(data.tasks) : []
  }
}

const _detailServicesAdapter = data => {
  return data.map(d => ({
    servicetype_uri:
      typeof d.servicetype_uri === 'string' ? d.servicetype_uri : EMPTY,
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : EMPTY,
    servicetype: typeof d.servicetype === 'string' ? d.servicetype : EMPTY,
    items: Array.isArray(d.items) ? d.items : []
  }))
}

const _detailTasksAdapter = data => {
  return data.map(d => ({
    task_uri: typeof d.task_uri === 'string' ? d.task_uri : EMPTY,
    task_id: typeof d.task_id === 'string' ? d.task_id : EMPTY,
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : EMPTY,
    item_option_id:
      typeof d.item_option_id === 'string' ? d.item_option_id : EMPTY
  }))
}

const _detailsFail = dispatch => {
  dispatch({
    type: CURRENT_SERVICES_AND_TASKS_GET_FAIL
  })
}

export const updateServiceOptions = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError,
  service_type
}) => {
  return async dispatch => {
    Service.updateBulkServiceOptions(pdbid, context, body, service_type)
      .then(async res => {
        const { ok } = res || {}
        const json = await res.json()
        if (ok) {
          if (onSuccess) {
            onSuccess(json)
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}

export const updateServiceSchedule = ({
  pdbid,
  context,
  body,
  serviceType,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.updateServiceSchedule(pdbid, context, body, serviceType)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}
