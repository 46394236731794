import React from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

export const GetToolTip = ({ children, purchaseOrder, type }) => {
  if (purchaseOrder) {
    return (
      <Tippy
        placement="top"
        theme="light"
        content={
          purchaseOrder &&
          `You cannot ${
            type === 'edit' ? 'edit' : 'delete'
          } this note because its charge is associated with a Purchase Order. In order to ${
            type === 'edit' ? 'edit' : 'delete'
          } it, please first remove the charge from the Purchase Order.`
        }>
        {children}
      </Tippy>
    )
  }
  return children
}
