import LOGOUT from '../auth'
import { elementsDeleteAdapter } from './adapters'
import {
  COMPANY_TEMPLATES_LIST_REQUESTED,
  COMPANY_TEMPLATES_LIST_SUCCESS,
  COMPANY_TEMPLATES_LIST_FAIL,
  COMPANY_TEMPLATES_ELEMENTS_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_FAIL,
  COMPANY_TEMPLATES_ELEMENTS_RESET,
  COMPANY_TEMPLATES_DETAILS_REQUESTED,
  COMPANY_TEMPLATES_DETAILS_SUCCESS,
  COMPANY_TEMPLATES_DETAILS_FAIL,
  COMPANY_TEMPLATES_DETAILS_RESET,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL,
  COMPANY_TEMPLATE_DELETE_REQUESTED,
  COMPANY_TEMPLATE_DELETE_SUCCESS,
  COMPANY_TEMPLATE_DELETE_FAIL,
  COMPANY_TEMPLATE_DELETE_RESET,
  COMPANY_TEMPLATE_DUPLICATE_REQUESTED,
  COMPANY_TEMPLATE_DUPLICATE_SUCCESS,
  COMPANY_TEMPLATE_DUPLICATE_FAIL,
  COMPANY_TEMPLATE_DUPLICATE_RESET,
  COMPANY_TEMPLATE_SHARE_REQUESTED,
  COMPANY_TEMPLATE_SHARE_SUCCESS,
  COMPANY_TEMPLATE_SHARE_FAIL,
  COMPANY_TEMPLATE_SHARE_RESET,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL,
  COMPANY_TEMPLATES_ERRORS_RESET,
  ALL_UNIT_SPACES_REQUESTED,
  ALL_UNIT_SPACES_FAIL,
  ALL_UNIT_SPACES_SUCCESS
} from './types'

const initialState = {
  createOrUpdateTemplateRequested: false,
  createOrUpdateTemplateError: false,
  listHasRequested: false,
  listIsRequesting: false,
  listIsError: false,
  list: [],
  detailsHasRequested: false,
  detailsIsRequesting: false,
  detailsIsError: false,
  details: {
    name: '',
    type: '',
    status: null,
    template_id: null,
    created_by: null,
    created_at: null,
    updated_at: null,
    spaces: [],
    shared: []
  },
  elementsHasRequested: false,
  elementsIsRequesting: false,
  elementsIsError: false,
  elementsUpdateIsRequesting: false,
  elementsDeleteIsRequesting: false,
  elementsUpdateIsError: false,
  elements: {
    spaces: [],
    items: [],
    observations: [],
    solutions: []
  },
  deleteIsRequesting: false,
  deleteHasRequested: true,
  deleteIsError: false,
  deleteSuccess: false,
  duplicateIsRequesting: false,
  duplicateHasRequested: true,
  duplicateIsError: false,
  duplicateSuccess: false,
  shareTemplateIsRequesting: false,
  shareTemplateHasRequested: true,
  shareTemplateIsError: false,
  shareTemplateSuccess: false,
  unitSpaceList: []
}

/**
 * Company templates data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED:
      return {
        ...state,
        createOrUpdateTemplateError: false,
        createOrUpdateTemplateRequested: true
      }
    case CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS:
      return {
        ...state,
        createOrUpdateTemplateRequested: false
      }
    case CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL:
      return {
        ...state,
        createOrUpdateTemplateError: true,
        createOrUpdateTemplateRequested: false
      }
    case COMPANY_TEMPLATES_LIST_REQUESTED:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: true,
        listIsError: false
      }
    case COMPANY_TEMPLATES_LIST_SUCCESS:
      return {
        ...state,
        listIsRequesting: false,
        list: action.list
      }
    case COMPANY_TEMPLATES_LIST_FAIL:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: true,
        list: []
      }
    case COMPANY_TEMPLATES_ELEMENTS_REQUESTED:
      return {
        ...state,
        elementsHasRequested: true,
        elementsIsRequesting: true,
        elementsIsError: false
      }
    case COMPANY_TEMPLATES_ELEMENTS_SUCCESS:
      return {
        ...state,
        elementsIsRequesting: false,
        elements: action.elements
      }
    case COMPANY_TEMPLATES_ELEMENTS_FAIL:
      return {
        ...state,
        elementsIsRequesting: false,
        elementsIsError: true,
        elements: {
          spaces: [],
          items: [],
          observations: [],
          solutions: []
        }
      }
    case COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED:
      return {
        ...state,
        elementsUpdateIsRequesting: true,
        elementsUpdateIsError: false,
        elementsIsError: false
      }
    case COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        elementsUpdateIsRequesting: false,
        elementsIsError: false
      }
    case COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL:
      return {
        ...state,
        elementsUpdateIsRequesting: false,
        elementsIsError: true
      }
    case COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED:
      return {
        ...state,
        elementsDeleteIsRequesting: true,
        elementsIsError: false
      }
    case COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS:
      return {
        ...state,
        elementsDeleteIsRequesting: false,
        elements: elementsDeleteAdapter(state.elements, action.payload),
        elementsIsError: false
      }
    case COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL:
      return {
        ...state,
        elementsDeleteIsRequesting: false,
        elementsIsError: true
      }
    case COMPANY_TEMPLATES_ELEMENTS_RESET:
      return {
        ...state,
        elementsHasRequested: false,
        elementsIsRequesting: false,
        elementsIsError: false,
        elements: {
          spaces: [],
          items: [],
          observations: [],
          solutions: []
        }
      }
    case COMPANY_TEMPLATES_DETAILS_REQUESTED:
      return {
        ...state,
        detailsHasRequested: true,
        detailsIsRequesting: true,
        detailsIsError: false
      }
    case COMPANY_TEMPLATES_DETAILS_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        details: action.details,
        elements: action.elements
      }
    case COMPANY_TEMPLATES_DETAILS_FAIL:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: true
      }
    case COMPANY_TEMPLATES_DETAILS_RESET:
      return {
        ...state,
        detailsHasRequested: false,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: {
          name: '',
          type: '',
          status: null,
          template_id: null,
          created_by: null,
          created_at: null,
          updated_at: null,
          spaces: [],
          shared: []
        }
      }
    case COMPANY_TEMPLATES_ERRORS_RESET:
      return {
        ...state,
        detailsIsError: false,
        elementsIsError: false,
        deleteIsError: false,
        createOrUpdateTemplateError: false,
        duplicateIsError: false
      }
    case COMPANY_TEMPLATE_DELETE_REQUESTED:
      return {
        ...state,
        deleteHasRequested: true,
        deleteIsRequesting: true,
        deleteIsError: false,
        deleteSuccess: false
      }
    case COMPANY_TEMPLATE_DELETE_SUCCESS:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: false,
        deleteSuccess: true,
        list: state.list.filter(
          template => template.template_id !== action.removedTemplateId
        )
      }
    case COMPANY_TEMPLATE_DELETE_FAIL:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: true,
        deleteSuccess: false
      }
    case COMPANY_TEMPLATE_DELETE_RESET:
      return {
        ...state,
        deleteHasRequested: false,
        deleteIsRequesting: false,
        deleteIsError: false,
        deleteSuccess: false
      }
    case COMPANY_TEMPLATE_DUPLICATE_REQUESTED:
      return {
        ...state,
        duplicateHasRequested: true,
        duplicateIsRequesting: true,
        duplicateIsError: false,
        duplicateSuccess: false
      }
    case COMPANY_TEMPLATE_DUPLICATE_SUCCESS:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: false,
        duplicateSuccess: true
      }
    case COMPANY_TEMPLATE_DUPLICATE_FAIL:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: true,
        duplicateSuccess: false
      }
    case COMPANY_TEMPLATE_DUPLICATE_RESET:
      return {
        ...state,
        duplicateHasRequested: false,
        duplicateIsRequesting: false,
        duplicateIsError: false,
        duplicateSuccess: false
      }
    case COMPANY_TEMPLATE_SHARE_REQUESTED:
      return {
        ...state,
        shareTemplateHasRequested: true,
        shareTemplateIsRequesting: true,
        shareTemplateIsError: false,
        shareTemplateSuccess: false
      }
    case COMPANY_TEMPLATE_SHARE_SUCCESS:
      return {
        ...state,
        shareTemplateHasRequested: false,
        shareTemplateIsRequesting: false,
        shareTemplateIsError: false,
        shareTemplateSuccess: true
      }
    case COMPANY_TEMPLATE_SHARE_FAIL:
      return {
        ...state,
        shareTemplateHasRequested: false,
        shareTemplateIsRequesting: false,
        shareTemplateIsError: true,
        shareTemplateSuccess: false
      }
    case COMPANY_TEMPLATE_SHARE_RESET:
      return {
        ...state,
        shareTemplateHasRequested: false,
        shareTemplateIsRequesting: false,
        shareTemplateIsError: false,
        shareTemplateSuccess: false
      }
    case ALL_UNIT_SPACES_REQUESTED:
      return {
        ...state,
        unitSpaceList: []
      }
    case ALL_UNIT_SPACES_SUCCESS:
      return {
        ...state,
        unitSpaceList: action.allUnitSpaces
      }
    case ALL_UNIT_SPACES_FAIL:
      return {
        ...state,
        unitSpaceList: []
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
