import React from 'react'
import PropTypes from 'prop-types'

const SubPricingTab = ({ tabData, onHandleUpdateQuote, onHandleChange, childTabType, isEdit, gmView }) => {

  // Show input field based on props
  const getInputData = (name, value, placeholder, index, id, updateQuoteData, type) => {
    if(isEdit) {
      return <input onBlur={(e) => onHandleUpdateQuote(updateQuoteData)} onChange={(e) => onHandleChange(e, index, id)} name={name} type={ type } placeholder={ placeholder} value={ value || '' } />
    }
    return <p>{value}</p>
  }
  // For capacity by date tab
  let startingDate=''
  let endingDate=''
  if(childTabType === 'Capacity By Date') {
    if((tabData || []).length !== 0) {
      startingDate = tabData[0]?.id || ''
      endingDate = tabData[(tabData || []).length - 1]?.id || ''
    }
  }

  return (
    <div className="input-fields-wrapper sub-pricing-section">
      { childTabType === 'Capacity By Date' && <div className="capacity-date-info">
        <div className="is-flex">
          <h3>Start Date: <span>{startingDate}</span></h3>
          <h3>End Date: <span>{endingDate}</span></h3>
        </div>
        <p>Enter the number of spaces that your team can complete for each date listed below.</p>
      </div>}
      <div className="input-field">
        <table className="table is-fullwidth">
          <thead>
            {(tabData || []).length !== 0 && <tr className="columns">
              <th className="column is-one-third">{`${childTabType === 'Capacity By Date' ? 'Dates' : 'Option'}`}</th>
              <th className="column is-one-fifth">{`${childTabType === 'Capacity By Date' ? 'Capacity' : 'Cost'}`}</th>
              <th className="column h-gap-30 is-two-fifths">Notes</th>
            </tr>}
          </thead>
          <tbody>
            {
              (tabData || []).map((content,i) => {
                const { s_id, type: name, inputType, serviceName  } = content || {}
                const { cost, notes, capacity } = inputType || {}
                const { inputType: capacityInputType, value: capacityValue, placeholder: capacityPlaceholder } = capacity || {}
                const { inputType: costInputType, value: costValue, placeholder: costPlacehoder } = cost || {}
                const { inputType: noteInputType, value: noteValue, placeholder: notePlacehoder } = notes || {}
                const valuesToUpdateData = {
                  s_id,
                  costValue,
                  noteValue, 
                  capacityValue, 
                  serviceName
                }
                return (
                  <tr className="columns is-vcentered" key={s_id}>
                    <td className="column is-one-third">
                      <h2>
                        { name || '' }
                      </h2>
                    </td>
                    <td className="column is-one-fifth">
                      { serviceName === 'Capacity By Date' 
                        ? getInputData('capacity',capacityValue,capacityPlaceholder,i,s_id,valuesToUpdateData,capacityInputType)
                        : getInputData('cost',costValue,costPlacehoder,i,s_id,valuesToUpdateData,costInputType)
                      }
                    </td>
                    <td className="column h-gap-30 is-two-fifths">
                      {
                        getInputData('notes',noteValue,notePlacehoder,i,s_id,valuesToUpdateData,noteInputType)
                      }
                    </td>
                  </tr> 
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

SubPricingTab.propTypes = {
  tabData: PropTypes.array,
  childTabType: PropTypes.string,
  gmView: PropTypes.bool,
  onHandleChange: PropTypes.func,
  onHandleUpdateQuote: PropTypes.func,
  isEdit: PropTypes.bool,
}

export default SubPricingTab

