import React from 'react'
import SharedColumnFilter from './company-template-table/company-template-table-column-filters/shared-column-filter'
import ActionColumn from './company-template-table/company-template-table-columns/action-column'
import DefaultColumn from './company-template-table/company-template-table-columns/default-column'
import NameColumn from './company-template-table/company-template-table-columns/name-column'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'

export const headerData = () => {
  return [
    {
      Header: 'Template Name',
      accessor: 'name',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      // width: 181,
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <NameColumn row={row} />
      }
    },
    {
      Header: 'Template Type',
      accessor: 'type',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      // width: 181,
      flexGrow: 1,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Created By',
      accessor: 'created_by_name',
      // width: 181,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Created Date',
      accessor: 'created_at',
      width: 130,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'State',
      accessor: 'state',
      Filter: SearchDropdownFilter,
      filter: 'customEquals',
      width: 130,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: 'Shared',
      accessor: 'shared',
      Filter: SharedColumnFilter,
      width: 100,
      textAlign: 'left',
      Cell: row => {
        return <DefaultColumn row={row} />
      }
    },
    {
      Header: '',
      accessor: 'template_id',
      textAlign: 'center',
      disableFilters: true,
      disableSortBy: true,
      width: 40,
      Cell: row => {
        return <ActionColumn row={row} />
      }
    }
  ]
}
