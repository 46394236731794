import Service from '../service'
import { LOGOUT } from './auth'

/**
 * Setup goals data module
 * @redux
 * @reduxActionScope setup-goals
 * @module setup-goals
 */

/**
 * Request all goals list
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_GOALS_GET_REQUESTED =
  'setup-goals/SETUP_ALL_GOALS_GET_REQUESTED'
/**
 * All goals list failed
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_GOALS_GET_FAIL = 'setup-goals/SETUP_ALL_GOALS_GET_FAIL'
/**
 * Update requested goals list with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_GOALS_GET_SUCCESS =
  'setup-goals/SETUP_ALL_GOALS_GET_SUCCESS'

/**
 * Request goal line creation
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_CREATE_REQUESTED =
  'setup-goal-line/SETUP_GOAL_LINE_CREATE_REQUESTED'
/**
 * Goal line creation failed
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_CREATE_FAIL =
  'setup-goal-line/SETUP_GOAL_LINE_CREATE_FAIL'
/**
 * Refresh property details after a successful goal line creation verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_CREATE_SUCCESS =
  'setup-goal-line/SETUP_GOAL_LINE_CREATE_SUCCESS'

/**
 * Request goal line update
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_UPDATE_REQUESTED =
  'setup-goal-line/SETUP_GOAL_LINE_UPDATE_REQUESTED'
/**
 * Goal line update failed
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_UPDATE_FAIL =
  'setup-goal-line/SETUP_GOAL_LINE_UPDATE_FAIL'
/**
 * Refresh goals details after a successful goal line update verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_GOAL_LINE_UPDATE_SUCCESS =
  'setup-goal-line/SETUP_GOAL_LINE_UPDATE_SUCCESS'

/**
 * Request all available services list
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_REQUESTED =
  'setup-goals/SETUP_ALL_SERVICES_REQUESTED'
/**
 * All available services list failed
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_FAIL = 'setup-goals/SETUP_ALL_SERVICES_FAIL'
/**
 * Update requested available services list with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_SERVICES_SUCCESS =
  'setup-goals/SETUP_ALL_SERVICES_SUCCESS'

const initialState = {
  goal: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  },
  allServices: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  }
}

/**
 * Setup goals reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_GOAL_LINE_CREATE_REQUESTED:
    case SETUP_GOAL_LINE_UPDATE_REQUESTED:
    case SETUP_ALL_GOALS_GET_REQUESTED:
      return {
        ...state,
        goal: {
          ...state.goal,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_GOAL_LINE_CREATE_SUCCESS:
    case SETUP_GOAL_LINE_UPDATE_SUCCESS:
    case SETUP_ALL_GOALS_GET_SUCCESS:
      return {
        ...state,
        goal: {
          ...state.goal,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_GOAL_LINE_CREATE_FAIL:
    case SETUP_GOAL_LINE_UPDATE_FAIL:
    case SETUP_ALL_GOALS_GET_FAIL:
      return {
        ...state,
        goal: {
          ...state.goal,
          isRequesting: false,
          isError: true
        }
      }
    case SETUP_ALL_SERVICES_REQUESTED:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_SERVICES_FAIL:
      return {
        ...state,
        allServices: {
          ...state.allServices,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all available goals
 * @redux
 * @reduxActionCreator SETUP_ALL_GOALS_REQUESTED, SETUP_ALL_GOALS_SUCCESS, SETUP_ALL_GOALS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupAllGoalsGet = (pdbid, context) => {
  return dispatch => {
    dispatch({ type: SETUP_ALL_GOALS_GET_REQUESTED })

    return Promise.all([
      Service.setupGoalStatsGet(pdbid, context).then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Failed in fetching the goal stats')
        }
      }),
      Service.setupAllGoalsGet(pdbid, context).then(res => {
        if (res.ok) {
          return res.json()
        } else {
          throw new Error('Failed in fetching all goals')
        }
      })
    ])
      .then(([stats, goals]) => {
        dispatch({
          type: SETUP_ALL_GOALS_GET_SUCCESS,
          data: {
            ..._allGoalsAdapter(goals),
            stats: _goalStatsAdapter(stats)
          }
        })
      })
      .catch(err => {
        _setupAllGoalsGetFail(dispatch)
        throw err
      })
  }
}

const _allGoalsAdapter = goals => {
  return Array.isArray(goals) && goals.length > 0
    ? _oneGoalAdapter(goals[0])
    : {}
}

const _goalStatsAdapter = stats => {
  return Array.isArray(stats.items) && stats.items ? stats.items[0] : {}
}

const _setupAllGoalsGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_GOALS_GET_FAIL
  })
}

const EMPTY = ''
const DEFAULT_NUMBER_VALUE = 0
const _oneGoalAdapter = b => {
  return {
    goal_uri: typeof b.goal_uri === 'string' ? b.goal_uri : EMPTY,
    goal_id: typeof b.goal_id === 'string' ? b.goal_id : EMPTY,
    goal_name: typeof b.goal_name === 'string' ? b.goal_name : EMPTY,
    goal_lines: Array.isArray(b.goal_lines)
      ? _goalLinesAdapter(b.goal_lines)
      : []
  }
}
const _allEmpty = obj => {
  let allEmpty = true
  for (let key in obj) {
    const value = obj[key]
    if (value !== null) allEmpty = false
  }
  return allEmpty
}
const _goalLinesAdapter = arr => {
  let lines = []
  for (let i = 0; i < arr.length; i += 1) {
    const a = arr[i]
    if (_allEmpty(a)) continue
    lines.push({
      end_date: typeof a.end_date === 'string' ? a.end_date : EMPTY,
      goal_line_uri:
        typeof a.goal_line_uri === 'string' ? a.goal_line_uri : EMPTY,
      goal_rooms: typeof a.goal_rooms === 'string' ? a.goal_rooms : EMPTY,
      rooms_per_day:
        typeof a.rooms_per_day === 'number'
          ? a.rooms_per_day
          : DEFAULT_NUMBER_VALUE,
      servicetype: typeof a.servicetype === 'string' ? a.servicetype : EMPTY,
      servicetype_id:
        typeof a.servicetype_id === 'string' ? a.servicetype_id : EMPTY,
      servicetype_uri:
        typeof a.servicetype_uri === 'string' ? a.servicetype_uri : EMPTY,
      start_date: typeof a.start_date === 'string' ? a.start_date : EMPTY
    })
  }
  return lines
}

/**
 * Creates a new goal line
 * @redux
 * @reduxActionCreator SETUP_GOAL_LINE_CREATE_REQUESTED, SETUP_GOAL_LINE_CREATE_SUCCESS, SETUP_GOAL_LINE_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing goal
 * @param {Object} body - the JSON object for the new goal line
 */
export const setupGoalLineCreate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_GOAL_LINE_CREATE_REQUESTED
    })
    return Service.setupGoalLineCreate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_GOAL_LINE_CREATE_SUCCESS,
            data: _allGoalsAdapter(data)
          })
        } else {
          _setupGoalLineCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupGoalLineCreateFail(dispatch)
      })
  }
}

const _setupGoalLineCreateFail = dispatch => {
  dispatch({
    type: SETUP_GOAL_LINE_CREATE_FAIL
  })
}

/**
 * Updates an existing goal line
 * @redux
 * @reduxActionCreator SETUP_GOAL_LINE_UPDATE_REQUESTED, SETUP_GOAL_LINE_UPDATE_SUCCESS, SETUP_GOAL_LINE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing goal line
 * @param {Object} body - the JSON object for the updated goal line
 */
export const setupGoalLineUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_GOAL_LINE_UPDATE_REQUESTED
    })
    return Service.setupGoalLineUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_GOAL_LINE_UPDATE_SUCCESS,
            data: _allGoalsAdapter(data)
          })
        } else {
          _setupGoalLineUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupGoalLineUpdateFail(dispatch)
      })
  }
}

const _setupGoalLineUpdateFail = dispatch => {
  dispatch({
    type: SETUP_GOAL_LINE_UPDATE_FAIL
  })
}

/**
 * Gets all available services
 * @redux
 * @reduxActionCreator SETUP_ALL_SERVICES_REQUESTED, SETUP_ALL_SERVICES_SUCCESS, SETUP_ALL_SERVICES_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupServicesGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_SERVICES_REQUESTED
    })
    Service.setupAllServicesGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_SERVICES_SUCCESS,
            data: _allServicesAdapter(data)
          })
        } else {
          _setupServicesFail(dispatch)
        }
      })
      .catch(() => {
        _setupServicesFail(dispatch)
      })
  }
}

const _itemsAdapter = items => {
  return items.map(i => ({
    item: typeof i.item === 'string' ? i.item : '',
    item_uri: typeof i.item_uri === 'string' ? i.item_uri : '',
    item_id: typeof i.item_id === 'string' ? i.item_id : '',
    item_options: Array.isArray(i.item_options) ? i.item_options : []
  }))
}
const _allServicesAdapter = data => {
  return data.map(d => ({
    servicetype_uri:
      typeof d.servicetype_uri === 'string' ? d.servicetype_uri : '',
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : '',
    servicetype: typeof d.servicetype === 'string' ? d.servicetype : '',
    items: Array.isArray(d.items) ? _itemsAdapter(d.items) : []
  }))
}

const _setupServicesFail = dispatch => {
  dispatch({
    type: SETUP_ALL_SERVICES_FAIL
  })
}
