import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react'
import {
  useBlockLayout,
  useFilters,
  useSortBy,
  useTable,
  useRowSelect,
  usePagination
} from 'react-table'
import { VariableSizeList } from 'react-window'
import './edition-table.scss'

import SortIcon from '../../common/SortIcon/SortIcon'
import Paginator from '../../react-table-pagination'
import { EditionTableStyles } from './edition-table-styles'
import EditionTableCheckbox from './edition-table-checkbox'
import DefaultColumnFilter from '../../react-table-column-filters/DefaultColumnFilter'

const RenderRow = ({ data, index, style }) => {
  const { page, prepareRow, setSize } = data || {}
  const row = page[index]

  const rowRef = useRef()

  prepareRow(row)

  useEffect(() => {
    if (rowRef.current) {
      const { height } =
        rowRef.current?.children[1]?.children[0]?.getBoundingClientRect() || {}

      setSize(index, height)
    }
  }, [index, setSize, row])

  return (
    <div
      {...row.getRowProps({
        style
      })}
      ref={rowRef}
      className="tr">
      {row.cells.map(cell => {
        let styleBody = {}
        if (cell.column.minWidth !== 0) {
          styleBody.minWidth = cell.column.minWidth
        }
        if (cell.column.flexGrow !== 0) {
          styleBody.flexGrow = cell.column.flexGrow
        }
        return (
          <div
            {...cell.getCellProps({
              style: {
                textAlign: cell.column.textAlign,
                width: cell.column.width,
                ...styleBody
              }
            })}
            className="td">
            {cell.render('Cell')}
          </div>
        )
      })}
    </div>
  )
}

const EditionTable = (
  {
    data = [],
    tableColumns,
    skipReset,
    onItemChange,
    onDropdownItemChange,
    onItemDelete,
    type,
    setIsFilterApplied,
    onRowSelectStateChange
  },
  ref
) => {
  const sizeMap = useRef({})
  const listRef = useRef(null)

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      width: 100
    }),
    []
  )

  const filterTypes = React.useMemo(
    () => ({
      customEquals: (rows, id, filterValue) => {
        if (!filterValue) return rows
        id = id[0]
        return rows.filter(row => filterValue?.[id] === row.values?.[id])
      },
      customIncludes: (rows, id, filterValue) => {
        if (!filterValue) return rows
        id = id[0]
        return rows.filter(
          row => filterValue?.[id] === row.values?.[id]?.split('-')[0]
        )
      }
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    previousPage,
    page,
    rows,
    gotoPage,
    setPageSize,
    prepareRow,
    state: { pageSize, pageIndex, filters, selectedRowIds },
    selectedFlatRows,
    setAllFilters,
    toggleAllRowsSelected
  } = useTable(
    {
      columns: tableColumns,
      data,
      defaultColumn,
      autoResetPage: !skipReset,
      autoResetFilters: !skipReset,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        hiddenColumns: tableColumns
          .filter(col => col.show === false)
          .map(col => col.accessor)
      },
      onItemChange,
      onDropdownItemChange,
      onItemDelete,
      filterTypes,
      sortTypes: {
        alphanumeric: (row1 = {}, row2 = {}, columnName = '') => {
          const rowOneColumn = row1?.values[columnName]
          const rowTwoColumn = row2?.values[columnName]
          return (rowOneColumn || '').toString().toLowerCase() >
            (rowTwoColumn || '').toString().toLowerCase()
            ? 1
            : -1
        }
      }
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 45,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div style={{ textAlign: 'end', marginLeft: '8px' }}>
              <EditionTableCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div style={{ paddingTop: '3px' }}>
              <EditionTableCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ])
    }
  )

  const scrollToTop = () => listRef?.current?.scrollTo(0, 0)

  useEffect(() => {
    setPageSize(30)
    gotoPage(1)
    scrollToTop()
  }, [type])

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, [])

  const getSize = index =>
    sizeMap.current[index] && sizeMap.current[index] >= 45
      ? sizeMap.current[index] + 10
      : 45

  useImperativeHandle(ref, () => ({
    getRows: () => {
      return (rows || []).map(row => row.original)
    },

    getSelectedRows: () => {
      return (selectedFlatRows || []).map(row => row.original)
    },
    clearFilter: setAllFilters,
    toggleAllRowsSelected: toggleAllRowsSelected
  }))

  useEffect(() => {
    setIsFilterApplied(filters)
  }, [filters])

  useEffect(() => {
    onRowSelectStateChange(selectedFlatRows)
  }, [selectedRowIds])

  useEffect(() => {
    if ((data[0] || {}).new) {
      if (listRef.current) {
        listRef.current.scrollToItem(0)
      }
    }
  }, [data.length])

  return (
    <div>
      <EditionTableStyles>
        <div {...getTableProps()} className="edition-table">
          <div>
            {headerGroups.map(headerGroup => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr full-header">
                {headerGroup.headers.map(column => (
                  <div
                    {...column.getHeaderProps({
                      style: {
                        textAlign: column.textAlign,
                        width: column.width,
                        minWidth: column.minWidth,
                        flexGrow: column.flexGrow
                      }
                    })}
                    className="th">
                    <div>
                      {column.render('Header')}
                      <span {...column.getSortByToggleProps()}>
                        <SortIcon column={column} />
                      </span>
                    </div>
                    <div title="Filter">
                      {column.canFilter ? column.render('Filter') : null}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            {(rows || []).length ? (
              <VariableSizeList
                ref={listRef}
                className="full-body list-container"
                height={250}
                itemCount={(page || []).length}
                itemSize={getSize}
                itemData={{ page, prepareRow, setSize }}>
                {RenderRow}
              </VariableSizeList>
            ) : (
              <></>
            )}
          </div>
        </div>
      </EditionTableStyles>
      <Paginator
        previousPage={() => {
          scrollToTop()
          previousPage()
        }}
        nextPage={() => {
          scrollToTop()
          nextPage()
        }}
        rowsPerPage={pageSize}
        rowCount={(rows || []).length}
        currentPage={pageIndex + 1}
        onChangePage={page => {
          gotoPage(page - 1)
          scrollToTop()
        }}
        setRowsPerPage={pageSize => {
          setPageSize(pageSize)
        }}
        limit={[30, 50, 70, 100]}
      />
    </div>
  )
}

export default forwardRef(EditionTable)
