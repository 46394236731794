import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import DamageSetupTable from '../damage-setup-list'
import { Link } from 'react-router-dom'

const DamageSetupMain = props => {
  const { location } = props
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <Link to="/inspections/damagesetup" className="p-none">
          Back to Select Form
        </Link>
      </div>
      <div className="section">
        <DamageSetupTable {...props} />
      </div>
    </div>
  )
}

export default DamageSetupMain
