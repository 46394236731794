import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getInspectionList,
  getInspectionMedia,
  getInspectionFormList,
  getInspectionUnitList,
  getInspectionDownloadMediaURL,
  addNewInspection,
  getInspectionAssignToList,
  getInspectionPDFURL,
  editNewInspection,
  bulkUpdateInspectionsProfile,
  getFilteredInspectionList,
  downloadInspectionMedia,
  addInspectionMedia,
  getInspectionUploadMediaURL
} from '../../modules/inspect'
import DamageInspectionsMain from '../../parents/damage-inspections-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { getDownloadState } from '../../modules/download-inspections'
import {
  getApprovedInspectionList,
  changeDamageInspectionStatus,
  getAdhocUnitSpaces,
  saveAdhocDamage,
  getSingleDamageInspection
} from '../../modules/damages'
import { setAlert } from 'modules/alerts'

const DamageInspections = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <DamageInspectionsMain {...props} />
  }

  return <BigLoading />
}

DamageInspections.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, inspect, damage }) => ({
  user,
  context,
  modal,
  inspect,
  damage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getInspectionList,
      getInspectionMedia,
      getInspectionFormList,
      getInspectionUnitList,
      getInspectionDownloadMediaURL,
      addNewInspection,
      getInspectionAssignToList,
      getInspectionPDFURL,
      editNewInspection,
      bulkUpdateInspectionsProfile,
      getFilteredInspectionList,
      downloadInspectionMedia,
      getDownloadState,
      getApprovedInspectionList,
      changeDamageInspectionStatus,
      addInspectionMedia,
      getInspectionUploadMediaURL,
      getAdhocUnitSpaces,
      saveAdhocDamage,
      setAlert,
      getSingleDamageInspection
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DamageInspections)
)
