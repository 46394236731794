import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import {
  getTurnboard2,
  getTurnboardDataByPage,
  getTurnboardDataByFilter,
  updateTurnboardDataByState,
  getTurnboardDataBySort,
  getTurnboardDataFilter,
  updateServiceOptions,
  getScheduleByService,
  updateServiceSchedule,
  updateAsIsStatus,
  getTurnboardAllTags,
  getAllGroups,
  groupOrUngroupUnits,
  getTurnboardServiceStatus,
  updateLeaseOverride,
  updateEnableServices,
  markInHouse,
  unMarkInHouse,
  markAndUnMarkInHouse
} from '../../modules/turnboard'
import Turnboard2Component from '../../parents/turnboard-2'
import { bindActionCreators } from 'redux'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import {
  allNotesGet,
  unitNotesCreate,
  unitNotesUpdate
} from '../../modules/notes'

const Turnboard2Container = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}

  if (hasMadeInitialRequest && !isRequesting) {
    return <Turnboard2Component {...props} />
  }

  return <BigLoading />
}

const mapStateToProps = ({
  user,
  turnboard,
  context,
  modal,
  notes,
  setupVendors
}) => ({
  user,
  turnboard,
  context,
  modal,
  notes,
  setupVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getTurnboard2,
      openModal,
      closeModal,
      setModalContent,
      getTurnboardDataByPage,
      getTurnboardDataByFilter,
      allNotesGet,
      unitNotesCreate,
      unitNotesUpdate,
      updateTurnboardDataByState,
      getTurnboardDataBySort,
      getTurnboardDataFilter,
      updateServiceOptions,
      getScheduleByService,
      updateServiceSchedule,
      updateAsIsStatus,
      getTurnboardAllTags,
      getAllGroups,
      groupOrUngroupUnits,
      getTurnboardServiceStatus,
      updateLeaseOverride,
      setupAllVendorsGet,
      updateEnableServices,
      markInHouse,
      unMarkInHouse,
      markAndUnMarkInHouse
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Turnboard2Container)
)
