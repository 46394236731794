export const getTabs = setActiveTab => ([
  {
    label: 'Form',
    id: 'form',
    onClick: value => setActiveTab(value),
    active: true,
    disabled: false,
  },
  {
    label: 'Section',
    id: 'section',
    onClick: value => setActiveTab(value),
    active: false,
    disabled: false,
  },
])
