import React from 'react'
import PropTypes from 'prop-types'

const SetupTeamManageModalDelete = ({
  user,
  context,
  teamId,
  closeModal,
  setupOneTeamDelete,
  history
}) => {
  const { pdbid } = user.user_metadata
  return (
    <div>
      <p className="is-size-4 m-b-sm">You are about to remove this team.</p>
      <p className="is-size-6 m-b-md">
        This action cannot be undone and the team will need to be manually
        re-added if needed in the future.
      </p>
      <p className="is-size-4 has-text-link has-text-centered m-b-lg">
        Are you sure you want to remove this team?
      </p>
      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <button
            type="button"
            className="button is-primary"
            style={{ width: 130 }}
            onClick={() => {
              closeModal()
            }}>
            No, Cancel
          </button>
        </div>
        <div className="column is-half">
          <button
            type="button"
            className="button is-danger"
            style={{ width: 130 }}
            onClick={() => {
              setupOneTeamDelete(pdbid, context, teamId)
              closeModal()
              history.push('/setup/teams')
            }}>
            Yes, Remove
          </button>
        </div>
      </div>
    </div>
  )
}

SetupTeamManageModalDelete.propTypes = {
  closeModal: PropTypes.func,
  setupOneTeamDelete: PropTypes.func,
  teamId: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object
}

export default SetupTeamManageModalDelete
