import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { icons } from './icons'
import './index.scss'

const ServicesUnblock = ({ onSave, onClose, unit, services, context, user, unblockService }) => {
  const [updating, setUpdating] = useState(false)
  const { updateIsRequesting } = useSelector(state => state.services)

  useEffect(() => {
    if (updating && !updateIsRequesting) {
      onSave()
    }
  }, [updateIsRequesting])

  const onComplete = () => {
    const { pdbid } = user.user_metadata
    const { currentService } = services
    
    const body = {
      operation_uri: unit['operation_uri'],
    }

    unblockService(pdbid, context, currentService, body)
    setUpdating(true)
  }

  return (
    <div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            You're about to override a workflow
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={() => {
              onClose()
            }}>
            <img alt="Delete Note" src={icons.close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full p-t-none">
          <p className="modal-sync-subtitle">Unit {unit.unit}</p>
        </div>
      </div>
      <div className="columns">
        <div className="column is-full" style={{ wordSpacing: -.2 }}>
          This action will allow more than one vendor to start work in this unit.
        </div>
      </div>
      <div className="columns">
        <div className="column is-full has-text-centered m-t-sm">
          Are you sure you want to unblock this unit?
        </div>
      </div>


      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            No, Cancel
          </button>

          <button className="button main-button is-danger" onClick={onComplete}>
            { updating ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <span>Yes, Unblock</span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

ServicesUnblock.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  services: PropTypes.object,
  unit: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  unblockService: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}

export default ServicesUnblock
