import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DataTable from '../../components/data-table'
import { getHeaderData } from './header'
import { capitalize } from '../../utils/helperFunctions'
import { formatDateString } from '../../utils/index'
import './index.scss'

const RFPList = props => {
  // Destructure Props
  const {
    user,
    context,
    vendorDetailsByEmailGet,
    requestsForProposal,
    tenantCookieValue
  } = props
  // State
  const [textFilter, setTextFilter] = useState('')
  const tableRef = useRef()
  useEffect(() => {
    const { user_metadata, email } = user || {}
    const { pdbid, pdbids } = user_metadata || {}
    if (tenantCookieValue) {
      vendorDetailsByEmailGet(tenantCookieValue, context, email)
    } else {
      vendorDetailsByEmailGet(pdbid || pdbids[0], context, email)
    }
  }, [context])

  const defaultStatusList = {
    'not-started': 'Not Started',
    started: 'Started',
    'in-review': 'In Review',
    closed: 'Closed',
    approved: 'Approved',
    rejected: 'Rejected'
  }

  const getReadAbleStatus = status => {
    if (status !== null && status !== '') {
      return defaultStatusList[(status || 'not-started').toLowerCase()]
    }
    return 'Not Started'
  }

  // Methods
  const exportCSV = () => {
    const { user_metadata, name } = user
    const vendor_name = name.replace(/ /g, '_')

    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const filterData = (tableRef?.current?.getRows() || []).map(list => {
      const { form_name, vendor_name, property, due_date, status } = list || {}
      return {
        'Request Title': `${form_name || ''} - ${capitalize(
          vendor_name || ''
        )}`,
        'Property Name': `"${property}"` || '',
        'Due Date': due_date ? formatDateString(due_date, 'MM/DD/YYYY') : 'N/A',
        Status: getReadAbleStatus(status) || ''
      }
    })

    const data = filterData

    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${vendor_name}-all-requests-for-proposals-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  const getFilteredRequestsForProposal = () => {
    const { allRFP } = requestsForProposal || {}
    const { data } = allRFP || {}
    return (data || []).filter(d => {
      const { vendor_name, form_name } = d || {}
      const constructValue = `${vendor_name || ''} - ${form_name || ''}`
      return (
        (constructValue || '')
          .toUpperCase()
          .indexOf(textFilter.toUpperCase()) !== -1
      )
    })
  }
  const filteredRequestForProposal = getFilteredRequestsForProposal()
  return (
    <DataTable
      title="Requests For Proposal"
      ref={tableRef}
      cssClasses={'request-rfp-table'}
      header={getHeaderData()}
      data={filteredRequestForProposal}
      onExport={exportCSV}
      textFilter={textFilter}
      onSetTextFilter={setTextFilter}
      rowKey={'rfp_id'}
    />
  )
}

RFPList.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object
}

export default RFPList
