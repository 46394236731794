import React from 'react'

const DefaultColumn = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}

  return (
    <div className="cell-item" title={value}>
      {value}
    </div>
  )
}

export default DefaultColumn
