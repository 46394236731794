import React from 'react'
import PropTypes from 'prop-types'
import Stars from '../stars'
import ComponentWithStatus from '../component-with-status'

/**
 * Property health indicator box
 *
 * @component
 * @example
 * return (
 *   <HealthRating
 *     loading={false}
 *     error={false}
 *     stars={4}
 *   />
 * )
 */
const HealthRating = ({ loading, error, stars }) => {
  return (
    <div className="dashboard-card card-sm dark-background">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="vertical-center has-text-centered">
          <p className="m-b-sm large-text">Turn Health Rating</p>
          <Stars numActive={stars} className="dashboard-stars" />
        </div>
      </ComponentWithStatus>
    </div>
  )
}

HealthRating.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Star rating of the property
   */
  stars: PropTypes.number
}

export default HealthRating
