import React, { useMemo, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import KeyValueDisplay from '../../components/key-value-display'
import KeyValueForm from '../../components/key-value-form'
import { icons } from './icons'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../permission-gate'

/**
 * Component for rendering a setup box with all
 * needed controls: unless otherwise specified, all
 * controls are rendered as simple input boxes
 *
 * @component
 * @example
 * const data = {
 *   "email": "paintvendor@test.com",
 *   "address": "Some address",
 *   "phone": "512-555-5555",
 * }
 * return (
 *   <SetupBox
 *      hasCircle={true}
 *      title="Test Entity"
 *      titleCanEdit
 *      data={data}
 *      display={{
 *        email: 'Email',
 *        address: 'Address',
 *        phone: 'Phone'
 *      }}
 *      isEditing={false}
 *   />
 * )
 */
const SetupBox = ({
  canEdit = true,
  isEditing = false,
  titleCanEdit = false,
  data = {},
  display = {},
  customFields = {},
  customDisplays = {},
  isEmptyPointer = true,
  submitButtonLabel = 'Submit',
  cancelButtonLabel = 'Cancel',
  cancel = () => {},
  submit = () => {},
  toggleEditing = () => {},
  isFaded = false,
  change = () => {},
  isValid = {},
  errorMessages = {},
  hasTransitionOpacity = false,
  titleField = 'name',
  title,
  hasCircle = false,
  hasEllipsis = true,
  ellipsisClick = () => {},
  closeTooltip,
  titleChange = () => {},
  titleLabel = 'Name',
  emptyTemplate = null,
  ignoreInDisplay = [],
  additionalTitle = null,
  name,
  isSubmitting
}) => {
  const id = useMemo(() => Short.uuid(), [])
  const ref = useRef()
  let [tooltipOpened, setTooltipOpened] = useState(false)

  useEffect(() => {
    if (isEditing && titleCanEdit) {
      ref.current.focus()
    }
  }, [isEditing, titleCanEdit])

  useEffect(() => () => closeTooltip(), [])

  const optionsHandler = e => {
    e.stopPropagation()
    if (tooltipOpened) {
      closeTooltip()
    } else {
      ellipsisClick(e)
    }

    setTooltipOpened(state => !state)
  }

  const getEmptyState = () =>
    emptyTemplate || <div className="default-empty-state"></div>

  const getTopTemplate = () => {
    const showCircle = hasCircle ? '' : 'is-hidden'
    const titleInput = (
      <div className="columns">
        <div className="column is-full is-10-fullhd is-offset-1-fullhd">
          <div className="columns is-desktop is-mobile">
            <div className="column is-one-third has-text-left">
              <label
                htmlFor={`setup-box-title-${id}`}
                style={{ lineHeight: '40px' }}>
                {titleLabel}:
              </label>
            </div>
            <div className="column is-two-thirds">
              <>
                <input
                  type="text"
                  className={`input ${
                    isValid.title === false ? 'invalid' : ''
                  }`}
                  defaultValue={title}
                  onChange={e => titleChange(e.target.value)}
                  ref={ref}
                  id={`setup-box-title-${id}`}
                />
                {isValid.title === false && (
                  <>
                    <br />
                    <span className="invalid-text">{errorMessages.title}</span>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    )
    const titleDisplay = (
      <>
        {additionalTitle}
        <p className="is-size-4 has-text-centered m-b-md user-title">{title}</p>
      </>
    )
    const circle = hasCircle && title.length > 0 ? 'blue-circle' : 'grey-circle'
    const match = title.match(/\b(\w)/g)
    const abbr = Array.isArray(match) ? match.join('') : ''
    return (
      <div>
        {!isEditing && hasEllipsis && canEdit && (
          <RestrictedElement>
            <PermissionGate name={name}>
              <div style={{ position: 'relative' }}>
                <button
                  className="button p-l-none p-r-none"
                  type="button"
                  onClick={e => optionsHandler(e)}
                  style={{
                    border: 0,
                    position: 'absolute',
                    top: '0px',
                    right: '10px'
                  }}>
                  <img src={icons.ellipsis} alt="Options" />
                </button>
              </div>
            </PermissionGate>
          </RestrictedElement>
        )}
        <div
          className={`${circle} m-b-md ${showCircle} has-text-centered has-text-white is-size-3`}>
          {abbr}
        </div>
        <div className="columns is-vcentered">
          <div className="column">
            {titleCanEdit && isEditing ? titleInput : titleDisplay}
          </div>
        </div>
      </div>
    )
  }

  const opacity = isFaded ? '0.5' : 1
  const transition = hasTransitionOpacity ? 'has-transition-opacity' : ''
  if (Object.keys(data).length < 1 && !isEditing)
    return (
      <div
        className={`box is-shadowless ${transition} ${
          isEmptyPointer ? 'is-pointer' : ''
        }`}
        onClick={() => {
          toggleEditing(true)
        }}
        style={{ opacity }}>
        {getEmptyState()}
      </div>
    )
  const component = isEditing ? (
    <KeyValueForm
      data={data}
      submitButtonLabel={submitButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      display={display}
      customFields={customFields}
      cancel={() => {
        cancel()
      }}
      isValid={isValid}
      errorMessages={errorMessages}
      change={change}
      isSubmitting={isSubmitting}
      submit={data => {
        let title = document.querySelector(`#setup-box-title-${id}`)
        if (title) data[titleField] = title.value
        submit(data, id)
      }}
    />
  ) : (
    <KeyValueDisplay
      data={data}
      display={display}
      customDisplays={customDisplays}
      ignore={ignoreInDisplay}
    />
  )

  return (
    <div className={`box is-shadowless ${transition}`} style={{ opacity }}>
      {getTopTemplate()}
      <div className="columns">
        <div className="column is-full is-10-fullhd is-offset-1-fullhd">
          {component}
        </div>
      </div>
    </div>
  )
}

SetupBox.propTypes = {
  /**
   * Box title; usually the entity name
   */
  title: PropTypes.string,
  /**
   * Determines if a blue circle should be shown surrounding the title
   */
  hasCircle: PropTypes.bool,
  /**
   * Determines if the title is editable
   */
  titleCanEdit: PropTypes.bool,
  /**
   * Specifies an empty template, to show if no data has been entered yet
   */
  emptyTemplate: PropTypes.element,
  /**
   * The entity data, other than its title
   */
  data: PropTypes.object,
  /**
   * Hashmap with the captions for each of the entity's fields
   */
  display: PropTypes.object,
  /**
   * Determines if the entity is currently being edited
   */
  isEditing: PropTypes.bool,
  /**
   * Event handler for toggle edit action
   */
  toggleEditing: PropTypes.func,
  /**
   * Event handler for toggle title change action
   */
  titleChange: PropTypes.func,
  /**
   * Event handler for other fields change action
   */
  change: PropTypes.func,
  /**
   * Event handler for submit changes action
   */
  submit: PropTypes.func,
  /**
   * Event handler for cancel changes action
   */
  cancel: PropTypes.func,
  /**
   * Hashmap for valid/invalid fields
   */
  isValid: PropTypes.object,
  /**
   * Hashmap for field error messages
   */
  errorMessages: PropTypes.object,
  /**
   * Event handler for ellipsis mouse click action
   */
  ellipsisClick: PropTypes.func,
  /**
   * Function to close the tooltip
   */
  closeTooltip: PropTypes.func,
  /**
   * Determines if the setup box is currently faded
   */
  isFaded: PropTypes.bool,
  /**
   * Determines if the setup box should have a transition opacity effect applied
   */
  hasTransitionOpacity: PropTypes.bool,
  /**
   * Determines if the setup box should override the pointer with an empty pointer
   */
  isEmptyPointer: PropTypes.bool,
  /**
   * The label for the title field
   */
  titleLabel: PropTypes.string,
  additionalTitle: PropTypes.element,
  ignoreInDisplay: PropTypes.array,
  /**
   * The name of the title field
   */
  titleField: PropTypes.string,
  /**
   * Component name which you add as Permission key to check permissions against
   */
  name: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string
}

export default SetupBox
