import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'

export const getDistinctProperties = (properties = []) => {
  const history = {}
  const distinctProperties = []

  for (let property of properties) {
    if (!history[property['property_id']]) {
      distinctProperties.push(property)
      history[property['property_id']] = true
    }
  }

  return distinctProperties
}

const EmailAlreadyExistsModal = ({
  currentProperty,
  properties,
  onDismiss,
  onAddUser
}) => {
  const displayPropertiesAsText = useMemo(() => {
    const distinctProperties = getDistinctProperties(properties)

    const propertyNames = (distinctProperties || []).map(property => {
      const projects = (properties || [])
        .filter(project => project.property_id === property.property_id)
        .map(project => project.project_name)

      return `${property.property_name} (${projects?.join(', ')?.trim(' ')})`
    })

    const text = propertyNames
      ?.slice(0, properties?.length - 1)
      ?.join(', ')
      ?.trim(' ')

    return propertyNames?.length === 1
      ? propertyNames[0]
      : `${text} and ${propertyNames?.[propertyNames?.length - 1]}`
  }, [properties])

  return (
    <>
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">
            Email already exists
          </p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onDismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">
            This user already exists in{' '}
            <strong>{displayPropertiesAsText}</strong>
          </p>
          <p className="is-size-6 sub-message">
            Do you want to add this user to <strong>{currentProperty}</strong>{' '}
            as well ?{' '}
          </p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={onDismiss}>
            No
          </button>
          <button className="button main-button is-primary" onClick={onAddUser}>
            {/* {useLoading && submitFire && (
            <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
          )} */}
            Yes
          </button>
        </div>
      </div>
    </>
  )
}

export default EmailAlreadyExistsModal
