import React from 'react'
import DataTable from 'react-data-table-component-with-filter'
import DataTablePagination from '../data-table-pagination'
import PropTypes from 'prop-types'

import './index.scss'
import BasicLoading from '../basic-loading'


const defaultStyles = {
  table: {
    style: {
      borderWidth: '1px',
      borderColor: '#e5e9f2',
      borderStyle: 'solid',
      borderTopWidth: '0px',
      borderBottomWidth: '0px'
       
    }
  },
  headCells: {
    style: {
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '14px',
      fontWeight: '700',
      color: '#122048'
    },
  },
  headRow: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid',
      paddingLeft: '12px',
      paddingRight: '12px'
    }
  },
  cells: {
    style: {
      paddingTop: '15px',
      paddingBottom: '15px',
      fontSize: '14px',
      fontWeight: '400',
      fontFamily: 'Avenir',
      color: '#122048'
    },
  },
  rows: {
    style: {
      borderBottomWidth: '1px',
      borderBottomColor: '#e5e9f2',
      borderBottomStyle: 'solid',
      paddingLeft: '12px',
      paddingRight: '12px',
      '&:first-child': {
        borderBottomWidth: '1px',
        borderBottomColor: '#e5e9f2',
        borderBottomStyle: 'solid',
      },
      '&:last-child': {
        borderBottomWidth: '0px',
      }
    }
  },
  pagination: {
    style: {
      borderWidth: '1px',
      borderColor: '#e5e9f2',
      borderStyle: 'solid',
      borderTopWidth: '0px',
      color: '#122048',
      fontSize: '14px',
      fontWeight: '300',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }
}

const DataTableComponent = props => {
  const { title, showSearchBar, columns, columnStyles, data, rowsPerPage, fixedHeader = false, selectableRows, onSelectedRowsChange, showProgressBar,  filterServer = false, onFilter, showPagination, hideHeaderSection, isDense=false, sortServer=false, onSort, customRowStyles, clearSelectedRows } = props || {}

  return (
    <>
      <div className="data-table-wrapper">
        {
          !hideHeaderSection &&  <div className="data-table-header">
            <div className="header">
              <h2 className="heading-5">
                { title || ''}
              </h2>
              { showSearchBar && <div className="column has-text-right is-narrow">
                <input type="text" placeholder="Search..." className="input is-inline text-filter" value=""/>
              </div> }
            </div>
            <div className="action-button">
          
            </div>
          </div>
        }
        
        <div className="data-table-body">
          <DataTable
            columns={columns}
            customStyles={{...defaultStyles, ...columnStyles}} 
            data = { data || []}
            paginationPerPage = { rowsPerPage || 15 }
            pagination = { showPagination } 
            paginationComponentOptions = {{
              noRowsPerPage: true,
            }}
            paginationComponent={DataTablePagination}
            dense={isDense}
            sortServer = { sortServer }
            conditionalRowStyles={customRowStyles}
            onSort={onSort}
            filterServer = { filterServer }
            onFilter = { onFilter }
            fixedHeader = { fixedHeader }
            persistTableHead={ true }
            selectableRows = { selectableRows }
            onSelectedRowsChange = { onSelectedRowsChange }
            noDataComponent={null}
            progressPending={ showProgressBar }
            progressComponent = { <BasicLoading/> }
            clearSelectedRows={clearSelectedRows}
          />
        </div>
      </div>
    </>
   
  )
}


DataTableComponent.propTypes = {
  columns: PropTypes.array,
  title: PropTypes.string,
  columnStyles: PropTypes.object,
  showPagination: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  rowsPerPage: PropTypes.number,
  data: PropTypes.array,
  isDense: PropTypes.bool,
  onSort: PropTypes.func,
  sortServer: PropTypes.bool  
}



export default DataTableComponent
