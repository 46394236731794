import React from 'react'

const UsedColumn = ({ row }) => {
  const { cell } = row || {}
  const { value } = cell || {}

  return (
    <div style={{ marginTop: '7px' }}>
      <div className="cell-item" title={value}>
        {value}
      </div>
    </div>
  )
}

export default UsedColumn
