import React from 'react'
import Breadcrumbs from '../breadcrumbs'
import DamageInspectionsList from '../damage-inspections-list'
import AddNewAdhocDamage from '../add-new-adhoc-damage'

const DamageInspectionsMain = props => {
  const { location, history } = props

  let inner = <DamageInspectionsList {...props} />
  if (location.pathname.includes('/inspections/damages/createadhocdamage')) {
    inner = <AddNewAdhocDamage {...props} />
  }
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        {location.pathname.includes(
          '/inspections/damages/createadhocdamage'
        ) && (
          <div class="back-text">
            <a onClick={() => history.goBack()} className="p-none">
              Back to List View
            </a>
          </div>
        )}
      </div>

      <div className="section">{inner}</div>
    </div>
  )
}

export default DamageInspectionsMain
