import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { headerData } from './header'
import { emptyPage, closeFilled, exportFile } from 'ui/icons'
import { resetDownloadInspectionsList } from '../../modules/download-inspections'

import BigLoading from '../../components/big-loading'
import { formatDateString } from '../../utils'
import DownloadInspectionsTable from './download-inspections-table/download-inspections-table'
import './index.scss'

const DownloadInspectionsList = ({
  history,
  user,
  context,
  downloadInspections,
  inspect
}) => {
  const dispatch = useDispatch()
  const [error, setError] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  const tableRef = useRef(null)

  const { list: data, listError, listRequested } = downloadInspections || {}
  const { inspectionDownloadMediaURL } = inspect || {}

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const rows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []

    const dataToExport = (selectedRows.length ? selectedRows : rows || []).map(
      data => ({
        name: `"${data.name}"` || '',
        status: `"${data.status}"` || ''
      })
    )

    const keys = ['Name', 'Status']

    const csvData = [keys]
      .concat(dataToExport.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')

    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-downloads-${formatDateString(
        new Date(),
        'MM_DD_YYYY'
      )}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const columns = useMemo(() => headerData(inspectionDownloadMediaURL), [
    inspectionDownloadMediaURL
  ])
  const memoizedData = useMemo(() => data || [], [data])

  useEffect(() => {
    return () => {
      dispatch(resetDownloadInspectionsList())
    }
  }, [dispatch])

  useEffect(() => {
    if (listError) setError('Something went wrong, please try again later.')
  }, [listError])

  const getLoadingState = () => <BigLoading />

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">
          Looks like there are no downloads yet!
        </p>
      </div>
    </div>
  )

  const handleResetFilter = () => tableRef.current?.clearFilter([])

  const onResetSelectedRows = () =>
    tableRef.current?.toggleAllRowsSelected(false)

  const checkIsFilterApplied = useMemo(() => {
    return (isFilterApplied || []).filter(o => o.value)
  }, [isFilterApplied])

  if (listRequested) {
    return getLoadingState()
  }

  if (Array.isArray(data) && !data.length) {
    return getEmptyState()
  }

  return (
    <div className="download-inspections-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">All Downloads</h2>
          </div>
          <div className="action-button">
            <div className="download-inspections-action-buttons">
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={handleResetFilter}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <div>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>

          <p className="description">
            <div className="caveat-container">
              <span className="caveat-description">
                <strong>Note:</strong>&nbsp; Below is the list of all your
                downloads. Please note that the download link will expire after
                72 hours since the time it is ready for download.
              </span>
            </div>
          </p>

          {error !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{error}</p>
                <button onClick={() => setError('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        <DownloadInspectionsTable
          ref={tableRef}
          data={memoizedData}
          tableColumns={columns}
          onRowSelectStateChange={setSelectedRows}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
    </div>
  )
}

DownloadInspectionsList.propTypes = {
  propertyForms: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object
}

export default DownloadInspectionsList
