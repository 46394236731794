import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useModals } from '../../../helpers/hooks'
import ConfirmationModal from '../../confirmation-modal'

export function RouterPrompt({
  when,
  title = 'Your unsaved changes will be lost. Are you sure you want to leave?',
  okText = 'Yes',
  cancelText = 'No'
}) {
  const history = useHistory()
  const unblock = useRef()
  const [showModal, dismissModal] = useModals()

  useEffect(() => {
    if (when) {
      unblock.current = history.block(prompt => {
        showModal(
          { width: '480px' },
          <ConfirmationModal
            msg={title}
            submitLabel={okText}
            dismissLabel={cancelText}
            submit={() => {
              dismissModal()
              unblock.current()
              history.push(prompt.pathname)
            }}
            dismiss={dismissModal}
          />
        )
        return false
      })
    }

    return () => {
      if (unblock.current) {
        unblock.current()
      }
    }
  }, [when])

  return null
}
