import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquare,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons'
import { currencyFormatter } from '../../utils'

const getDifference = difference => {
  if (difference > 0) {
    return (
      <div
        className="columns is-vcentered green-color m-none"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '0.1rem'
        }}>
        <div className="column is-size-2 is-narrow p-none">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <div className="column xl-text is-narrow p-l-xs p-t-none p-b-none p-r-none">
          {currencyFormatter(difference, { disableRounding: false })}
        </div>
      </div>
    )
  } else if (difference < 0) {
    return (
      <div
        className="columns is-vcentered red-color"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '0.1rem'
        }}>
        <div className="column is-size-2 is-narrow p-r-none">
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div className="column xl-text is-narrow p-l-xs">
          {currencyFormatter(Math.abs(difference))}
        </div>
      </div>
    )
  }
  return (
    <div
      className="columns is-vcentered faded-color"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.1rem'
      }}>
      <div className="column is-size-4 is-narrow p-r-none">
        <FontAwesomeIcon icon={faSquare} />
      </div>
      <div className="column xl-text is-narrow p-l-xs">
        {currencyFormatter(difference)}
      </div>
    </div>
  )
}

/**
 * Component for showing the summarized cost vs. budget box
 *
 * @component
 * @example
 * return (
 *   <CostBudget
 *     costs={[
 *       {
 *         service: 'Paint',
 *         budget: 75000,
 *         difference: 25000,
 *         actual: 50000
 *       },
 *       {
 *         service: 'Clean',
 *         budget: 50000,
 *         difference: -10000,
 *         actual: 60000
 *       },
 *       {
 *         service: 'CP Rep',
 *         budget: 20000,
 *         difference: 20000,
 *         actual: 0
 *       }
 *     ]}
 *   />
 * )
 */
const CostBudget = ({ costs }) => {
  const totalCost = _.reduce(costs, (acc, cost) => acc + cost.budget, 0)
  const actualCost = _.reduce(costs, (acc, cost) => acc + cost.actual, 0)
  return (
    <div className="dashboard-card card-semi has-text-centered">
      <div className="vertical-center">
        <div className="faded-color">
          {getDifference(totalCost - actualCost)}
        </div>
        <p>of {currencyFormatter(totalCost)} Budget</p>
      </div>
    </div>
  )
}

CostBudget.propTypes = {
  /**
   * Array with the costs on the current property
   */
  costs: PropTypes.array
}

export default CostBudget
