import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import close from './icons/close.svg'
import './index.scss'

const leaseStatusOptions = [
  { value: 'Vacant', text: 'Vacant' },
  { value: 'Leased', text: 'Leased' },
  { value: 'Renewed', text: 'Renewal' },
  { value: 'Transfer In', text: 'Transfer In' },
  { value: 'Transfer Out', text: 'Transfer Out' },
  { value: 'Leapfrog', text: 'Leapfrog' }
]

const TurnboardLeaseOverrideModal = ({
  onConfirm,
  onCancel,
  pdbid,
  context,
  shouldOverrideLease,
  shouldUndoOverrideLease,
  selectedUnit,
  updateLeaseOverride
}) => {
  const { leaseOverrideLoader } = useSelector(state => state.turnboard)

  const [selectedLeaseStatus, setSelectedLeaseStatus] = useState('')
  const [error, setError] = useState('')

  const handleSubmit = () => {
    const data = {
      items: [
        {
          unit_space_id: selectedUnit?.unit_space_id,
          status: shouldOverrideLease ? selectedLeaseStatus : null
        }
      ]
    }

    updateLeaseOverride({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      },
      onError: () => {
        onCancel()
      }
    })
  }

  const handleChange = ({ currentTarget: input }) => {
    setSelectedLeaseStatus(input.value)

    if (!input.value) {
      setError('Please select lease override status.')
    } else {
      setError('')
    }
  }

  useEffect(() => {
    if (selectedUnit && shouldOverrideLease) {
      const statusFound = leaseStatusOptions.some(
        option => option.text === selectedUnit.lease_status
      )

      if (statusFound) {
        const status =
          selectedUnit.lease_status === 'Renewal'
            ? 'Renewed'
            : selectedUnit.lease_status
        setSelectedLeaseStatus(status)
      }
    }
  }, [selectedUnit, shouldOverrideLease])

  return (
    <div className="lease-override-modal">
      <div className="modal-heading">
        <h3>
          {shouldOverrideLease
            ? 'Override Lease Status'
            : 'Undo Lease Override'}
        </h3>
        <p className="close is-pointer has-text-grey-light" onClick={onCancel}>
          <img alt="Close Modal" src={close} />
        </p>
      </div>
      <div className="modal-desceription">
        {shouldUndoOverrideLease && (
          <div>
            <p>By undoing the lease status override for the selected</p>
            <p>space, you will be moving back to the previously</p>
            <p>reported lease status</p>
          </div>
        )}
        <div className="show-status">
          {shouldUndoOverrideLease ? (
            <div>
              {selectedUnit?.unit} {selectedUnit?.unit_space} &mdash;{' '}
              {selectedUnit?.override_status}
            </div>
          ) : (
            <div>
              {selectedUnit?.unit} {selectedUnit?.unit_space} &mdash;{' '}
              {selectedUnit?.lease_status}
            </div>
          )}
          {shouldUndoOverrideLease && (
            <div>
              <span className="prev-lease-status">Previous Status</span> &mdash;{' '}
              {selectedUnit?.previous_lease_status}
            </div>
          )}
        </div>
      </div>
      {shouldOverrideLease && (
        <div className="form">
          <div className="columns is-mobile">
            <div className="column is-half">
              <h3>
                <b>New Lease Status</b>
              </h3>
              <div className={`select is-fullwidth ${error && 'is-danger'}`}>
                <select
                  className="input"
                  name="leaseStatus"
                  value={selectedLeaseStatus}
                  onChange={handleChange}>
                  <option value="">Choose Option</option>
                  {leaseStatusOptions.map(({ value, text }) => (
                    <option value={value}>{text}</option>
                  ))}
                </select>
                {error && <p className="help is-danger">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      )}

      {shouldUndoOverrideLease && (
        <div className="modal-confirmation-text center-container">
          <p>Would you like to undo the lease override ?</p>
        </div>
      )}

      <div className="modal-actions center-container">
        <button
          className="button main-button is-secondary m-r-md"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          className="button main-button is-primary"
          disabled={shouldOverrideLease && !selectedLeaseStatus}
          onClick={handleSubmit}>
          {shouldOverrideLease ? 'Save' : 'Yes'}
          {leaseOverrideLoader && (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#ffffff"
              className="m-l-sm"
            />
          )}
        </button>
      </div>
    </div>
  )
}

export default TurnboardLeaseOverrideModal
