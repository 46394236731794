import React, { useMemo } from 'react'
import { useHistory } from 'react-router'
import { edit } from 'ui/icons'

import DataTableMenu from '../../../../components/data-table-menu'

const ActionColumn = ({ row }) => {
  const history = useHistory()

  const { cell } = row || {}
  const { row: currentRow } = cell || {}
  const { original } = currentRow || {}
  const { template_id } = original || {}

  const menuOptions = useMemo(() => {
    return [
      {
        caption: 'Edit Template',
        icon: edit,
        disabled: original.shared === 'Yes',
        action: () => {
          history.push(
            `/inspections-setup/company-templates/manage?&template=${template_id}`
          )
        }
      }
    ]
  }, [original, template_id])

  return (
    <div className="menu-item">
      <DataTableMenu options={menuOptions} row={row} />
    </div>
  )
}

export default ActionColumn
