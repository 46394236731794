export const headerData = (serviceColumns) => {
  return [
    {
      key: 'unit',
      display: 'Unit'
    },
    {
      key: 'returns',
      display: 'Returned',
      notSortable: true
    },
    {
      key: 'expected',
      display: 'Expected',
      notSortable: true
    },
    ...(serviceColumns || []).map((item) => {
      const { unitspace } = item || {}

      return {
        key: `unitSpaces.${unitspace?.toLowerCase()}.icon`,
        display: unitspace,
        root: `unitSpaces.${unitspace?.toLowerCase()}`,
        tooltip: `unitSpaces.${unitspace?.toLowerCase()}.tooltip`,
        sortField: `unitSpaces.${unitspace?.toLowerCase()}.status`,
        notSortable: true
      }
    })
  ]
}

