import Service from '../service'
import LOGOUT from './auth'
import _ from 'lodash'

/**
 * Vendor Quotation data module
 * @redux
 * @reduxActionScope vendor-quotation
 * @module vendor-quotation
 */

/**
 * Request Vendor Quotation
 * @type {Redux.ActionType}
 */
export const REQUEST_VENDOR_QUOTATION =
  'vendor-quotation/REQUEST_VENDOR_QUOTATION'
/**
 * Vendor Quotation request failed
 * @type {Redux.ActionType}
 */
export const REQUEST_VENDOR_QUOTATION_FAIL = 'vendor-quotation/REQUEST_VENDOR_QUOTATION_FAIL'
/**
 * Update requested vendor quotation with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_VENDOR_QUOTATION_SUCCESS =
  'vendor-quotation/REQUEST_VENDOR_QUOTATION_SUCCESS'

/**
 * Request Update Vendor Quotation
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_QUOTATION =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_QUOTATION'
/**
 * Request Update Vendor Quotation Fail
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_QUOTATION_FAIL =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_QUOTATION_FAIL'

/**
 * Update requested vendor quotation with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_QUOTATION_SUCCESS =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_QUOTATION_SUCCESS'

/**
 * Request Vendor Business Information
 * @type {Redux.ActionType}
 */
export const REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION =
 'vendor-quotation/REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION'
/**
* Vendor Business Information request failed
* @type {Redux.ActionType}
*/
export const REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_FAIL = 'vendor-quotation/REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_FAIL'
/**
* Update requested vendor Business Information with successful data from API
* @type {Redux.ActionType}
*/
export const REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_SUCCESS =
 'vendor-quotation/REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_SUCCESS'

/**
 * Request Update Vendor Business Information
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION'
/**
 * Request Update Vendor Business Information Fail
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_FAIL =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_FAIL'

/**
 * Update requested Vendor Business Information with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_SUCCESS =
 'vendor-quotation/REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_SUCCESS'

/**
 * Reset State
 * @type {Redux.ActionType}
 */
export const REQUEST_VENDOR_RESET_STATE =
 'vendor-quotation/REQUEST_VENDOR_RESET_STATE'



const initialState = {
  vendorQuotation: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    constructed: false,
    data: []
  },
  vendorQuotationBusinessInfo: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  }
}

/**
 * Vendor Quotation Reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_VENDOR_QUOTATION:
      return {
        ...state,
        vendorQuotation: {
          ...state.vendorQuotation,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION:
      return {
        ...state,
        vendorQuotationBusinessInfo: {
          ...state.vendorQuotationBusinessInfo,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_VENDOR_QUOTATION_SUCCESS:
      return {
        ...state,
        vendorQuotation: {
          ...state.vendorQuotation,
          isRequesting: false,
          isError: false,
          data: action.data,
          constructed: false
        }
      }
    case REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_SUCCESS:
    case REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_SUCCESS:
      return {
        ...state,
        vendorQuotationBusinessInfo: {
          ...state.vendorQuotationBusinessInfo,
          isRequesting: false,
          isError: false,
          data: action.data,
        }
      }
    case REQUEST_UPDATE_VENDOR_QUOTATION_SUCCESS:
    case REQUEST_UPDATE_VENDOR_QUOTATION_FAIL:
      return {
        ...state,
        vendorQuotation: {
          ...state.vendorQuotation,
          isRequesting: false,
          isError: false,
          data: action.data,
          constructed: true
        }
      }
    case REQUEST_VENDOR_QUOTATION_FAIL:
      return {
        ...state,
        vendorQuotation: {
          ...state.vendorQuotation,
          isRequesting: false,
          isError: true
        }
      }
    case REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_FAIL:
    case REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_FAIL:
      return {
        ...state,
        vendorQuotationBusinessInfo: {
          ...state.vendorQuotationBusinessInfo,
          isRequesting: false,
          isError: true,
          data: action.data,
        }
      }
    case REQUEST_VENDOR_RESET_STATE:
      return {
        ...state,
        vendorQuotation: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          constructed: false,
          data: []
        },
        vendorQuotationBusinessInfo: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          data: {}
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}


export const vendorQuotationGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: REQUEST_VENDOR_QUOTATION
    })
    Service.vendorQuotationGet(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const vendorQuoteData = await res.json()
          Service.vendorQuotationCapacityByDateGet(pdbid, context, id)
            .then(async res => {
              const { ok } = res || {}
              if (ok) {
                const vendorQuoteCapacityData = await res.json()
                let combineBothData = []
                if((vendorQuoteData || []).length !==0) {
                  combineBothData = [...vendorQuoteData]
                } if((vendorQuoteCapacityData || []).length !==0) {
                  if((combineBothData || []).length !==0) {
                    combineBothData = [...combineBothData, ...vendorQuoteCapacityData]
                  } else {
                    combineBothData = [...vendorQuoteCapacityData]
                  }
                }
                const groupByServices = _(combineBothData || [])
                  .groupBy(x => x.service_type).value()
                dispatch({
                  type: REQUEST_VENDOR_QUOTATION_SUCCESS,
                  data: groupByServices || {}
                })
              }
              else {
                dispatch({
                  type: REQUEST_VENDOR_QUOTATION_FAIL,
                  data: {}
                })
              }
            })
            .catch(() => {
              dispatch({
                type: REQUEST_VENDOR_QUOTATION_FAIL,
                data: {}
              })
            })
        } else {
          dispatch({
            type: REQUEST_VENDOR_QUOTATION_FAIL,
            data: {}
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_VENDOR_QUOTATION_FAIL,
          data: {}
        })
      })
  }
}

export const vendorQuotationBusinessInformationGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION
    })
    Service.vendorQuotationBusinessInformationGet(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_SUCCESS,
            data: data || {}
          })
        } else {
          dispatch({
            type: REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_FAIL,
            data: {}
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_VENDOR_QUOTATION_BUSINESS_INFORMATION_FAIL,
          data: {}
        })
      })
  }
}

export const resetVendorQuotationState = () => {
  return dispatch => {
    dispatch({
      type: REQUEST_VENDOR_RESET_STATE
    })
  }
}

export const updateVendorQuotation = ({ pdbid, context, body, newData, oldData, onSuccess }) => {
  const { id: quoteid, data } = body || {}
  return dispatch => {
    dispatch({
      type: REQUEST_UPDATE_VENDOR_QUOTATION
    })
    Service.updateVendorQuotation(pdbid, context, data, quoteid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          dispatch({
            type: REQUEST_UPDATE_VENDOR_QUOTATION_SUCCESS,
            data: newData || {}
          })
          if(onSuccess) {
            onSuccess('success')
          }
        } else {
          dispatch({
            type: REQUEST_UPDATE_VENDOR_QUOTATION_FAIL,
            data: oldData || {}
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_UPDATE_VENDOR_QUOTATION_FAIL,
          data: oldData || {}
        })
      })
  }
}

export const updateVendorQuotationCapacityByDate = ({ pdbid, context, body, newData, oldData, onSuccess }) => {
  const { id: quoteid, data } = body || {}
  return dispatch => {
    dispatch({
      type: REQUEST_UPDATE_VENDOR_QUOTATION
    })
    Service.updateVendorQuotationCapacityByDate(pdbid, context, data, quoteid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          dispatch({
            type: REQUEST_UPDATE_VENDOR_QUOTATION_SUCCESS,
            data: newData || {}
          })
          if(onSuccess) {
            onSuccess('success')
          }
        } else {
          dispatch({
            type: REQUEST_UPDATE_VENDOR_QUOTATION_FAIL,
            data: oldData || {}
          })
        }
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_UPDATE_VENDOR_QUOTATION_FAIL,
          data: oldData || {}
        })
      })
  }
}

export const updateVendorBusinessInformation = ({ pdbid, context, body, oldData, onSuccess }) => {
  const { id: quoteid, data } = body || {}
  return dispatch => {
    dispatch({
      type: REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION
    })
    Service.updateVendorBusinessInformation(pdbid, context, data, quoteid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_SUCCESS,
            data: data || {}
          })
          if(onSuccess) {
            onSuccess('success')
          }
        } else {
          dispatch({
            type: REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_FAIL,
            data: oldData || {}
          })
        }
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: REQUEST_UPDATE_VENDOR_BUSINESS_INFORMATION_FAIL,
          data: oldData
        })
      })
  }
}




