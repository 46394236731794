import React from 'react'

const PermissionRuleFieldTemplateViewOnly = ({ children }) => {
  return (
    <div className="columns is-vcentered mb-0">
      <div className="column">
        <div className="columns is-desktop is-mobile content-spacing">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PermissionRuleFieldTemplateViewOnly
