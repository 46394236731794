import { share, trash, duplicate } from 'icons'

export const dropdownDefaultColorIndicator = '#E0E66B'

export const statusOptions = [
  {
    label: 'Active',
    value: 'active',
    color: '#3DB3E2'
  },
  {
    label: 'Inactive',
    value: 'inactive',
    color: '#E0E66B'
  }
]

export const getAdditionalActions = (
  duplicateTemplate,
  downloadTemplate,
  deleteTemplate
) => {
  return [
    {
      action: duplicateTemplate.action,
      disabled: duplicateTemplate.disabled,
      icon: duplicate,
      iconAlt: 'duplicate form',
      label: 'Duplicate form'
    },
    {
      action: downloadTemplate.action,
      disabled: downloadTemplate.disabled,
      icon: share,
      iconAlt: 'export',
      label: 'Export'
    },
    {
      action: deleteTemplate.action,
      disabled: deleteTemplate.disabled,
      title: deleteTemplate.title || '',
      icon: trash,
      iconAlt: 'delete',
      label: 'Delete'
    }
  ]
}
