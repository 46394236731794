import React, { useState, useMemo, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import close from './icons/close.svg'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'
import { headerData } from './headers'

import InHouseTable from './in-house-table/in-house-table'
import './index.scss'
import { cloneDeep, isEmpty } from 'lodash'
import { usePermissionGate } from '../../helpers/hooks'

const TurnboardInHouseModal = ({
  services = [],
  rows = [],
  onConfirm,
  onCancel,
  pdbid,
  context,
  markInHouse,
  unMarkInHouse,
  shouldMarkInHouse
}) => {
  const { hasPermission } = usePermissionGate('turnboard-actions')
  const { markOrUnMarkInHouseLoader } = useSelector(state => state.turnboard)
  const [error, setError] = useState('')
  const [tableData, setTableData] = useState(() => cloneDeep(rows))
  const [skipReset, setSkipReset] = useState(false)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const tableRef = useRef(null)

  const heading = shouldMarkInHouse ? 'Mark In-House' : 'Unmark In-House'

  const walkExcludedServices = useMemo(() => {
    return (services || []).filter(({ servicetype }) => servicetype !== 'Walk')
  }, [services])

  const columns = useMemo(
    () =>
      headerData({
        hasPermission,
        services: walkExcludedServices
      }),
    [walkExcludedServices]
  )

  const handleServiceStatusChange = (index, serviceName, status) => {
    setSkipReset(() => true)

    const newTableData = cloneDeep(tableData)
    newTableData[index][serviceName] = status

    setTableData(newTableData)
  }

  const getServiceName = servicetype => {
    return (servicetype || '')
      .toString()
      .replace(' ', '_')
      .toLowerCase()
  }

  const getMarkedInHouseData = () => {
    const markedInHouseData = []

    // filter the rows which can be marked as in house if any service status of a unit space is not N/A
    const filteredRows = (tableData || []).filter(row => {
      return !(walkExcludedServices || []).every(({ servicetype }) => {
        const serviceName = getServiceName(servicetype)
        return row[`${serviceName}_inhouse_status`] === 'N/A'
      })
    })

    for (let row of filteredRows) {
      const mapped = (walkExcludedServices || [])
        .filter(({ servicetype }) => {
          const serviceName = getServiceName(servicetype)
          return row[`${serviceName}_inhouse_status`] !== 'N/A'
        })
        .map(({ servicetype, servicetype_id }) => {
          const serviceName = getServiceName(servicetype)
          return {
            inhouse_id: null,
            servicetype_id,
            unit_space_id: row.unit_space_id,
            status: row[`${serviceName}_inhouse_status`]
          }
        })

      markedInHouseData.push(...mapped)
    }

    return markedInHouseData
  }

  const getUnMarkedInHouseData = () => {
    const unMarkedInHouseData = []

    for (let row of tableData) {
      const mapped = (walkExcludedServices || [])
        .filter(({ servicetype }) => {
          const serviceName = getServiceName(servicetype)
          return !isEmpty(row[`${serviceName}_inhouse_id`])
        })
        .map(({ servicetype, servicetype_id }) => {
          const serviceName = getServiceName(servicetype)
          return {
            inhouse_id: row[`${serviceName}_inhouse_id`]
          }
        })

      unMarkedInHouseData.push(...mapped)
    }

    return unMarkedInHouseData
  }

  const areAllInhouseStatusesIsNA = () => {
    return (tableData || []).some(row => {
      return (walkExcludedServices || []).every(({ servicetype }) => {
        const serviceName = getServiceName(servicetype)
        return row[`${serviceName}_inhouse_status`] === 'N/A'
      })
    })
  }

  const doMarkInHouse = async () => {
    const markedInHouseData = getMarkedInHouseData()

    const data = {
      items: markedInHouseData
    }

    await markInHouse({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      },
      onError: () => {
        setError('Something went wrong. Please try again later !!!')
      }
    })
  }

  const doUnMarkInhouse = async () => {
    const unMarkedInHouseData = getUnMarkedInHouseData()

    const data = {
      items: unMarkedInHouseData
    }

    await unMarkInHouse({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      },
      onError: () => {
        setError('Something went wrong. Please try again later !!!')
      }
    })
  }

  const handleSubmit = async () => {
    setError('')

    if (shouldMarkInHouse) {
      if (areAllInhouseStatusesIsNA()) {
        setShowAlert(true)

        const modal = document.getElementById('modal-card')
        modal.style.width = '500px'

        return
      }

      doMarkInHouse()
    } else {
      doUnMarkInhouse()
    }
  }

  const handleUpdate = async () => {
    setShowAlert(false)

    const modal = document.getElementById('modal-card')
    modal.style.width = '900px'
  }

  const handleCancel = () => {
    onCancel()
  }

  useEffect(() => {
    setSkipReset(() => false)
  }, [tableData.length])

  return (
    <div className="in-house-modal">
      <div className="modal-heading">
        <h3>{heading}</h3>
        <p className="close is-pointer has-text-grey-light" onClick={onCancel}>
          <img alt="Close Modal" src={close} />
        </p>
      </div>
      {error && (
        <ErrorMessage>
          <p>{error}</p>
        </ErrorMessage>
      )}
      {shouldMarkInHouse ? (
        <>
          {!showAlert ? (
            <div className="data-table-wrapper">
              <InHouseTable
                data={tableData}
                ref={tableRef}
                tableColumns={columns}
                skipReset={skipReset}
                setIsFilterApplied={setIsFilterApplied}
                onServiceStatusChange={handleServiceStatusChange}
              />
            </div>
          ) : (
            <>
              <div className="modal-desceription">
                <p>
                  At least one service per space needs to have a status. All
                  services of a space cannot be N/A.
                </p>
              </div>
              <div className="modal-confirmation-text center-container">
                <p>Would you like to continue?</p>
              </div>
              <div className="modal-actions center-container">
                <button
                  className="button main-button is-secondary m-r-md"
                  onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  disabled={!tableData.length}
                  className="button main-button is-primary"
                  onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="modal-desceription">
          <p>
            You are about to Unmark In-House these spaces. Please note that
            In-House will be cleared from all the services of these spaces.
          </p>
        </div>
      )}
      {!shouldMarkInHouse && (
        <div className="modal-confirmation-text center-container">
          <p>Would you like to continue?</p>
        </div>
      )}
      {!showAlert && (
        <div className="modal-actions center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={onCancel}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            disabled={markOrUnMarkInHouseLoader}
            onClick={handleSubmit}>
            Save
            {markOrUnMarkInHouseLoader && (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                color="#ffffff"
                className="m-l-sm"
              />
            )}
          </button>
        </div>
      )}
    </div>
  )
}

export default TurnboardInHouseModal
