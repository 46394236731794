import React, { useMemo } from 'react'
import MultiSelectDropdown from '../multi-select-dropdown'

const UnitFilter = props => {
  const id = props?.column?.id
  const rows = props?.filteredRows || []

  const options = useMemo(() => {
    const deduped = new Set()
    const options = []

    ;(rows || []).forEach(row => {
      const getOriginal = row?.original
      const val = getOriginal[id]
      const result = val?.split('-')[0]
      if (result && !deduped.has(result)) {
        deduped.add(result)
        options.push({ [id]: result })
      }
    })

    return options.sort((a, b) => {
      const x = a[id].toLowerCase()
      const y = b[id].toLowerCase()
      if (x > y) return 1
      if (x < y) return -1
      return 0
    })
  }, [props.rows])

  return (
    <MultiSelectDropdown
      displayKey={id}
      value={props.column.filterValue || ''}
      defaultValues={options}
      onChange={newValue => props.column.setFilter(newValue)}
      isMulti={false}
      isClearable
      placeholder="Search"
      noOptionsMessage={<p style={{ fontSize: '10px' }}>No Data Found</p>}
      menuPortalTarget={document.body}
      customStyles={{
        input: styles => ({
          ...styles,
          height: 23,
          margin: 0,
          marginTop: '-10px !important',
          padding: '1px 2px',
          fontSize: 10,
          lineHeight: 1,
          fontWeight: '400',
          cursor: 'text'
        }),
        placeholder: styles => ({
          ...styles,
          margin: 0,
          padding: '1px 2px',
          fontSize: 10,
          lineHeight: 1,
          fontWeight: '400',
          textAlign: 'left'
        }),
        control: (styles, state) => ({
          ...styles,
          minHeight: 23,
          maxHeight: 23,
          border: '1px solid #e5e9f2',
          borderRadius: 0,
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid #e5e9f2'
          },
          backgroundColor: state.isDisabled
            ? 'ghostwhite'
            : styles.backgroundColor
        }),
        container: styles => ({
          ...styles,
          marginTop: 8,
          pointerEvents: 'all'
        }),
        valueContainer: styles => ({
          ...styles,
          minHeight: 20,
          width: '100%',
          padding: 0,
          position: 'relative',
          marginTop: '-1px !important'
        }),
        singleValue: styles => ({
          ...styles,
          fontSize: 10,
          fontWeight: 400
        }),
        option: styles => ({
          ...styles,
          padding: 4,
          fontSize: 10
        }),
        indicatorsContainer: styles => ({
          ...styles,
          width: 20,
          height: 23,
          marginTop: '-2px !important'
        }),
        clearIndicator: styles => ({
          ...styles,
          width: 18,
          padding: 0,
          paddingLeft: 4
        })
      }}
    />
  )
}

export default UnitFilter
