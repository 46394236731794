import Service from '../service'
import LOGOUT from './auth'

/**
 * Setup staff data module
 * @redux
 * @reduxActionScope setup-staff
 * @module setup-staff
 */

/**
 * Request all staff list
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_STAFF_GET_REQUESTED =
  'setup-staff/SETUP_ALL_STAFF_GET_REQUESTED'
/**
 * All staff list request failed
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_STAFF_GET_FAIL = 'setup-staff/SETUP_ALL_STAFF_GET_FAIL'
/**
 * Update requested staff list with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_STAFF_GET_SUCCESS =
  'setup-staff/SETUP_ALL_STAFF_GET_SUCCESS'

/**
 * Request a single staff member
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_GET_REQUESTED =
  'setup-staff/SETUP_ONE_STAFF_GET_REQUESTED'
/**
 * Single staff member request failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_GET_FAIL = 'setup-staff/SETUP_ONE_STAFF_GET_FAIL'
/**
 * Update requested staff member details with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_GET_SUCCESS =
  'setup-staff/SETUP_ONE_STAFF_GET_SUCCESS'

/**
 * Request staff member creation
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_CREATE_REQUESTED =
  'setup-staff/SETUP_ONE_STAFF_CREATE_REQUESTED'
/**
 * Staff member creation failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_CREATE_FAIL =
  'setup-staff/SETUP_ONE_STAFF_CREATE_FAIL'
/**
 * Refresh staff details after a successful API create call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_CREATE_SUCCESS =
  'setup-staff/SETUP_ONE_STAFF_CREATE_SUCCESS'

/**
 * Request staff member update
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_UPDATE_REQUESTED =
  'setup-staff/SETUP_ONE_STAFF_UPDATE_REQUESTED'
/**
 * Staff member update failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_UPDATE_FAIL =
  'setup-staff/SETUP_ONE_STAFF_UPDATE_FAIL'
/**
 * Refresh staff details after a successful API update call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_UPDATE_SUCCESS =
  'setup-staff/SETUP_ONE_STAFF_UPDATE_SUCCESS'

/**
 * Request staff member delete
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_DELETE_REQUESTED =
  'setup-staff/SETUP_ONE_STAFF_DELETE_REQUESTED'
/**
 * Staff member delete failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_DELETE_FAIL =
  'setup-staff/SETUP_ONE_STAFF_DELETE_FAIL'
/**
 * Refresh staff details after a successful API delete call
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_STAFF_DELETE_SUCCESS =
  'setup-staff/SETUP_ONE_STAFF_DELETE_SUCCESS'

export const ALL_STAFF_LIST_REQUESTED = 'setup-staff/ALL_STAFF_LIST_REQUESTED'

export const ALL_STAFF_LIST_SUCCESS = 'setup-staff/ALL_STAFF_LIST_SUCCESS'

export const ALL_STAFF_LIST_FAIL = 'setup-staff/ALL_STAFF_LIST_FAIL'

export const RESET_ALL_STAFF_LIST_GET = 'setup-staff/RESET_ALL_STAFF_LIST_GET'

export const ADD_STAFF_USER_TO_EXISTING_PROPERTY_REQUESTED =
  'setup-staff/ADD_STAFF_USER_TO_EXISTING_PROPERTY_REQUESTED'

export const ADD_STAFF_USER_TO_EXISTING_PROPERTY_SUCCESS =
  'setup-staff/ADD_STAFF_USER_TO_EXISTING_PROPERTY_SUCCESS'

export const ADD_STAFF_USER_TO_EXISTING_PROPERTY_FAIL =
  'setup-staff/ADD_STAFF_USER_TO_EXISTING_PROPERTY_FAIL'

export const STAFF_EMAIL_VERIFICATION_REQUESTED =
  'setup-staff/STAFF_EMAIL_VERIFICATION_REQUESTED'

export const STAFF_EMAIL_VERIFICATION_SUCCESS =
  'setup-staff/STAFF_EMAIL_VERIFICATION_SUCCESS'

export const STAFF_EMAIL_VERIFICATION_FAIL =
  'setup-staff/STAFF_EMAIL_VERIFICATION_FAIL'

export const RESET_STAFF_EMAIL_VERIFICATION =
  'setup-staff/RESET_STAFF_EMAIL_VERIFICATION'

const initialState = {
  addStaffUserToProperty: {
    loading: false,
    error: false
  },
  verifiedStaff: {
    emailExists: false,
    emailVerified: false,
    email: '',
    properties: null,
    loading: false,
    error: false
  },
  allStaff: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  oneStaff: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  }
}

/**
 * Setup staff reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_ALL_STAFF_GET_REQUESTED:
    case ALL_STAFF_LIST_REQUESTED:
      return {
        ...state,
        allStaff: {
          ...state.allStaff,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ALL_STAFF_GET_SUCCESS:
    case ALL_STAFF_LIST_SUCCESS:
      return {
        ...state,
        allStaff: {
          ...state.allStaff,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ALL_STAFF_GET_FAIL:
    case ALL_STAFF_LIST_FAIL:
      return {
        ...state,
        allStaff: {
          ...state.allStaff,
          isRequesting: false,
          isError: true
        }
      }
    case RESET_ALL_STAFF_LIST_GET:
      return {
        ...state,
        allStaff: {
          ...state.allStaff,
          isRequesting: false,
          isError: false
        }
      }
    case SETUP_ONE_STAFF_GET_REQUESTED:
    case SETUP_ONE_STAFF_CREATE_REQUESTED:
    case SETUP_ONE_STAFF_UPDATE_REQUESTED:
    case SETUP_ONE_STAFF_DELETE_REQUESTED:
      return {
        ...state,
        oneStaff: {
          ...state.oneStaff,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_ONE_STAFF_GET_SUCCESS:
    case SETUP_ONE_STAFF_CREATE_SUCCESS:
    case SETUP_ONE_STAFF_UPDATE_SUCCESS:
    case SETUP_ONE_STAFF_DELETE_SUCCESS:
      return {
        ...state,
        oneStaff: {
          ...state.oneStaff,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_ONE_STAFF_GET_FAIL:
    case SETUP_ONE_STAFF_CREATE_FAIL:
    case SETUP_ONE_STAFF_UPDATE_FAIL:
    case SETUP_ONE_STAFF_DELETE_FAIL:
      return {
        ...state,
        oneStaff: {
          ...state.oneStaff,
          isRequesting: false,
          isError: true
        }
      }
    case ADD_STAFF_USER_TO_EXISTING_PROPERTY_REQUESTED:
      return {
        ...state,
        addStaffUserToProperty: {
          ...state.addStaffUserToProperty,
          loading: true,
          error: false
        }
      }
    case ADD_STAFF_USER_TO_EXISTING_PROPERTY_SUCCESS:
      return {
        ...state,
        addStaffUserToProperty: {
          ...state.addStaffUserToProperty,
          loading: false,
          error: false
        }
      }
    case ADD_STAFF_USER_TO_EXISTING_PROPERTY_FAIL:
      return {
        ...state,
        addStaffUserToProperty: {
          ...state.addStaffUserToProperty,
          loading: false,
          error: true
        }
      }
    case STAFF_EMAIL_VERIFICATION_REQUESTED:
      return {
        ...state,
        verifiedStaff: {
          ...state.verifiedStaff,
          loading: true,
          error: false
        }
      }
    case STAFF_EMAIL_VERIFICATION_SUCCESS:
      const { payload } = action || {}
      const staffVerificationResponse = payload.emailExists
        ? {
            emailExists: true,
            emailVerified: true,
            email: payload.email,
            properties: payload.properties
          }
        : {
            emailExists: false,
            emailVerified: true,
            email: payload.email,
            properties: null
          }

      return {
        ...state,
        verifiedStaff: {
          ...state.verifiedStaff,
          ...staffVerificationResponse,
          loading: false,
          error: false
        }
      }
    case STAFF_EMAIL_VERIFICATION_FAIL:
      return {
        ...state,
        verifiedStaff: {
          ...state.verifiedStaff,
          loading: false,
          error: true
        }
      }
    case RESET_STAFF_EMAIL_VERIFICATION:
      return {
        ...state,
        verifiedStaff: {
          ...state.verifiedStaff,
          emailVerified: false,
          emailExists: false,
          email: '',
          properties: null,
          loading: false,
          error: false
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all staff members
 * @redux
 * @reduxActionCreator SETUP_ALL_STAFF_GET_REQUESTED, SETUP_ALL_STAFF_GET_SUCCESS, SETUP_ALL_STAFF_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupAllStaffGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_STAFF_GET_REQUESTED
    })
    Service.setupAllStaffGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_STAFF_GET_SUCCESS,
            data: _allStaffAdapter(data)
          })
        } else {
          _setupAllStaffGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllStaffGetFail(dispatch)
      })
  }
}

export const allStaffListGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: ALL_STAFF_LIST_REQUESTED
    })
    Service.allStaffListGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()

          dispatch({
            type: ALL_STAFF_LIST_SUCCESS,
            data: staffUserListAdapter(data)
          })
        } else {
          _AllStaffListFail(dispatch)
        }
      })
      .catch(() => {
        _AllStaffListFail(dispatch)
      })
  }
}

export const resetAllStaffListGet = () => {
  return {
    type: RESET_ALL_STAFF_LIST_GET
  }
}

const _AllStaffListFail = dispatch => {
  dispatch({
    type: ALL_STAFF_LIST_FAIL
  })
}

const _setupAllStaffGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_STAFF_GET_FAIL
  })
}

/**
 * Gets a single staff member
 * @redux
 * @reduxActionCreator SETUP_ONE_STAFF_GET_REQUESTED, SETUP_ONE_STAFF_GET_SUCCESS, SETUP_ONE_STAFF_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the requested staff member
 */
export const setupOneStaffGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_STAFF_GET_REQUESTED
    })
    Service.setupOneStaffGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_STAFF_GET_SUCCESS,
            data: _oneStaffAdapter(data)
          })
        } else {
          _setupOneStaffGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneStaffGetFail(dispatch)
      })
  }
}

const _setupOneStaffGetFail = dispatch => {
  dispatch({
    type: SETUP_ONE_STAFF_GET_FAIL
  })
}

const NS = ''
const _oneStaffAdapter = data => {
  return {
    used: data?.used === 'T' ? true : false,
    status: data?.isinactive === null ? 'Active' : 'Inactive',
    staff_uri: typeof data.staff_uri === 'string' ? data.staff_uri : NS,
    staff_id: typeof data.staff_id === 'string' ? data.staff_id : NS,
    staff_name: typeof data.staff_name === 'string' ? data.staff_name : NS,
    title: typeof data.title === 'string' ? data.title : NS,
    type: typeof data.type === 'string' ? data.type : NS,
    email: typeof data.email === 'string' ? data.email : NS,
    phone: typeof data.phone === 'string' ? data.phone : NS,
    altphone: typeof data.altphone === 'string' ? data.altphone : NS,
    notes: typeof data.notes === 'string' ? data.notes : NS,
    hiredate: typeof data.hiredate === 'string' ? data.hiredate : NS,
    termdate: typeof data.termdate === 'string' ? data.termdate : NS
  }
}

const _allStaffAdapter = data => {
  return data.map(d => _oneStaffAdapter(d))
}

/**
 * Create a new staff member
 * @redux
 * @reduxActionCreator SETUP_ONE_STAFF_CREATE_REQUESTED, SETUP_ONE_STAFF_CREATE_SUCCESS, SETUP_ONE_STAFF_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new staff member
 */
export const setupOneStaffCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_STAFF_CREATE_REQUESTED
    })
    Service.setupOneStaffCreate(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_STAFF_CREATE_SUCCESS,
            data: _oneStaffAdapter(data)
          })
        } else {
          _setupOneStaffCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneStaffCreateFail(dispatch)
      })
  }
}

const _setupOneStaffCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_STAFF_CREATE_FAIL
  })
}

/**
 * Updates an existing staff member
 * @redux
 * @reduxActionCreator SETUP_ONE_STAFF_UPDATE_REQUESTED, SETUP_ONE_STAFF_UPDATE_SUCCESS, SETUP_ONE_STAFF_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the updated staff member
 */
export const setupOneStaffUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_STAFF_UPDATE_REQUESTED
    })
    Service.setupOneStaffUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_STAFF_UPDATE_SUCCESS,
            data: _oneStaffAdapter(data)
          })
        } else {
          _setupOneStaffUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneStaffUpdateFail(dispatch)
      })
  }
}

const _setupOneStaffUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_STAFF_UPDATE_FAIL
  })
}

/**
 * Deletes an existing staff member
 * @redux
 * @reduxActionCreator SETUP_ONE_STAFF_DELETE_REQUESTED, SETUP_ONE_STAFF_DELETE_SUCCESS, SETUP_ONE_STAFF_DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing staff member
 */
export const setupOneStaffDelete = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_STAFF_DELETE_REQUESTED
    })
    Service.setupOneStaffDelete(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_STAFF_DELETE_SUCCESS,
            data: _oneStaffAdapter(data)
          })
        } else {
          _setupOneStaffDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneStaffDeleteFail(dispatch)
      })
  }
}

/**
 * Verify Staff User with email
 * @redux
 * @reduxActionCreator STAFF_EMAIL_VERIFICATION_REQUESTED, STAFF_EMAIL_VERIFICATION_SUCCESS, STAFF_EMAIL_VERIFICATION_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} body - body will contain the email address of staff user
 */
export const verifyStaffUserWithEmail = (
  pdbid,
  context,
  body,
  onSuccess,
  onFail
) => {
  return dispatch => {
    const emails = ['ali@gmail.com', 'akif@gmail.com']

    dispatch({
      type: STAFF_EMAIL_VERIFICATION_REQUESTED
    })

    setTimeout(() => {
      const payload = {
        email: body.email,
        emailExists: false,
        properties: null
      }

      if (emails.some(email => body.email === email)) {
        payload.emailExists = true
        payload.properties = [
          'Development Property 2',
          'Development Property 3'
        ]
      }

      dispatch({
        type: STAFF_EMAIL_VERIFICATION_SUCCESS,
        payload
      })

      onSuccess && onSuccess()
    }, 3000)
    // Service.verifyStaffUserWithEmail(pdbid, context, body)
    //   .then(async res => {
    //     if (res.ok) {
    //       const data = await res.json()
    //       onSuccess && onSuccess(data)

    //       return
    //     }
    //     onFail && onFail()
    //   })
    //   .catch(() => {
    //     onFail && onFail()
    //   })
  }
}

export const resetVerifyStaffUserWithEmail = () => {
  return {
    type: RESET_STAFF_EMAIL_VERIFICATION
  }
}

export const deleteStaffUser = (
  pdbid,
  context,
  user_id,
  body,
  onSuccess,
  onError
) => {
  return dispatch => {
    Service.deleteStaff(pdbid, context, user_id, body)
      .then(async res => {
        if (res.ok) {
          if (onSuccess) {
            onSuccess()
          }

          return
        }

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const _setupOneStaffDeleteFail = dispatch => {
  dispatch({
    type: SETUP_ONE_STAFF_DELETE_FAIL
  })
}

const staffUserListAdapter = staffUserList => {
  return (staffUserList || []).map(staffUser => ({
    ...staffUser,
    account_status: staffUser.status === 'false' ? 'Active' : 'Blocked',
    status: staffUser.perm_status === null ? 'Active' : 'Inactive',
    login_access: staffUser.login_access === null ? 'Enabled' : 'Disabled'
  }))
}
