import React from 'react'
import PropTypes from 'prop-types'

const AdditionalActions = ({ actions }) => {
  return (
    <div className="m-t-md">
      <p className="has-text-weight-bold">Additional Actions</p>
      <ul>
        {actions.map(action => (
          <li key={action.label} title={action.title || action.label}>
            <button
              className="text-button m-t-md"
              onClick={action.action}
              disabled={action.disabled}>
              <img src={action.icon} alt={action.iconAlt} />
              <span className="text-button__label">{action.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

AdditionalActions.propTypes = {
  actions: PropTypes.array
}

export default AdditionalActions
