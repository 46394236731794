import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import React from 'react'

const defaultStatusList = {
  blocked: 'Blocked',
  active: 'Active'
}

const getReadAbleStatus = status => {
  if (status !== null && status !== '') {
    return defaultStatusList[(status || 'active').toLowerCase()]
  }
  return 'Active'
}

export const headerData = [
  {
    key: 'name',
    display: 'Name',
    filterable: true,
    textAlign: 'left',
    template: row => (
      <div>
        <Link
          to={{
            pathname: '/setup/users/detail',
            state: {
              id: row || {}
            }
          }}
          style={{
            textDecoration: 'underline',
            textTransform: 'capitalize'
          }}>
          {row.name}
        </Link>
      </div>
    )
  },
  {
    key: 'email',
    filterable: true,
    display: 'Email Address',
    textAlign: 'left'
  },
  {
    key: 'last_login',
    filterable: true,
    display: 'Last Login',
    textAlign: 'left',
    notSortable: true,
    format: value =>
      moment(value).isValid()
        ? moment(value).format('MM/DD/YYYY hh:mm a')
        : 'N/A'
  },
  {
    key: 'roles',
    filterable: true,
    display: 'Role',
    notSortable: true,
    textAlign: 'left',
    template: row => {
      const { roles } = row || {}
      return <span>{roles}</span>
    }
  },
  {
    key: 'status',
    display: 'Status',
    textAlign: 'left',
    template: row => {
      const { status } = row || {}
      return <span>{getReadAbleStatus(status)}</span>
    },
    getValues: data =>
      _.uniq(_.map(data || [], appr => getReadAbleStatus(appr.status))),
    evaluateMatch: (filter, element = {}) =>
      (filter || []).indexOf(getReadAbleStatus(element.status)) >= 0
  }
]

export const pivotConfig = {
  keyField: 'id',
  idField: 'user_id',
  collection: 'projects',
  columns: [
    {
      key: 'name',
      display: 'Project',
      notSortable: true,
      textAlign: true
    },
    {
      key: 'properties',
      display: 'Properties',
      notSortable: true,
      textAlign: true,
      allowWrap: true,
      wide: true,
      template: (parent, elementId) => (
        <div>
          {_.map(
            _.get(
              _.find(_.get(parent, 'projects', []), p => p.id === elementId),
              'properties'
            ),
            p =>
              _.get(
                _.find(
                  _.get(parent, 'properties', []),
                  property => property.id === p
                ),
                'name'
              )
          ).join(', ')}
        </div>
      )
    }
  ]
}
