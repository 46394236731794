import { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sectionManageable } from '../../security'

const RestrictedElement = ({
  auth,
  user,
  location,
  children,
  replacement = null
}) => {
  const [access, setAccess] = useState(false)

  useEffect(() => {
    const { isAuthenticated, hasMgmtToken } = auth
    if (isAuthenticated && hasMgmtToken) {
      setAccess(sectionManageable(location.pathname, user))
    }
  }, [auth, user, location])

  return access ? children : replacement
}

const mapStateToProps = ({ auth, user }) => ({
  auth,
  user
})

export default withRouter(memo(connect(mapStateToProps)(RestrictedElement)))
