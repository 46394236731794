import React, { useEffect, useState } from 'react'

const ToggleUserLogin = ({
  onChange,
  isActive,
  prevIsActive,
  isDisabled = false
}) => {
  const [isChecked, setChecked] = useState(isActive || false)

  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  useEffect(() => {
    if (prevIsActive !== undefined && prevIsActive !== isChecked) {
      setChecked(isActive)
    }
  }, [isActive])

  return (
    <div className="toggle-container">
      <span className="block-text is-size-5">Disable Login</span>
      <label
        className="toggle-control"
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}>
        <input
          type="checkbox"
          checked={isChecked}
          disabled={isDisabled}
          onChange={handleChange}
        />
        <span className="control"></span>
      </label>
      <span className="active-text is-size-5">Enable Login</span>
    </div>
  )
}

export default ToggleUserLogin
