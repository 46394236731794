import React, { useState } from 'react'
import { close } from 'ui/icons'
import LoginAccessModal from './LoginAccessModal'
import ReactivateOrInactivateModal from './ReactivateOrInactivateModal'
import './index.scss'

const SetupVendorReactivateOrInactivateStepperModal = ({
  onCancel,
  onConfirm,
  user,
  context,
  status,
  vendorUsers,
  vendorCompanyId,
  vendorCompanyData,
  toggleVendorAvailablitity,
  setupOneVendorUpdate
}) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const [loginAccess, setLoginAccess] = useState(false)
  const [showLoginAccessModal, setShowLoginAccessModal] = useState(
    () => !status
  )

  const { pdbid } = user.user_metadata || {}
  const modalTitle = status ? 'Inactivate Vendor' : 'Reactivate Vendor'

  const handleLoginAccessChange = value => {
    setLoginAccess(value)
    setShowLoginAccessModal(false)
  }

  const onSuccessToggleAvailablitity = () => {
    setLoading(false)
    onConfirm()
  }

  const onErrorToggleAvailablitity = () => {
    setLoading(false)
    setError('Something went wrong. Please try again later !!!')
  }

  const onStatusUpdateSuccess = () => {
    if (!vendorUsers || !vendorUsers.length) {
      setLoading(false)
      onConfirm()

      return
    }

    const constructData = (vendorUsers || []).map(d => ({
      permission_rule_id: d.permission_rule_id,
      status: status ? 'false' : null,
      login_access: status ? 'false' : loginAccess ? null : 'false',
      perm_status: status ? 'false' : null
    }))

    const body = {
      items: constructData || []
    }

    toggleVendorAvailablitity(
      pdbid,
      context,
      vendorCompanyId,
      body,
      onSuccessToggleAvailablitity,
      onErrorToggleAvailablitity
    )
  }

  const onStatusUpdateError = () => {
    setLoading(false)
    setError('Something went wrong. Please try again later !!!')
  }

  const handleReactivateOrInactivateUser = () => {
    setError('')
    setLoading(true)

    vendorCompanyData.status = status ? 'false' : null

    setupOneVendorUpdate(
      pdbid,
      context,
      vendorCompanyId,
      vendorCompanyData,
      onStatusUpdateSuccess,
      onStatusUpdateError
    )
  }

  return (
    <div className="setup-user-reactivate-or-inactivate-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">{modalTitle}</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onCancel}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      {showLoginAccessModal && vendorUsers && vendorUsers?.length ? (
        <LoginAccessModal
          onConfirm={handleLoginAccessChange}
          loginAccess={loginAccess}
        />
      ) : (
        <ReactivateOrInactivateModal
          status={status}
          loading={loading}
          error={error}
          onConfirm={handleReactivateOrInactivateUser}
          onCancel={onCancel}
        />
      )}
    </div>
  )
}

export default SetupVendorReactivateOrInactivateStepperModal
