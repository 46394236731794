import React, { useEffect, useState } from 'react'

const InputField = ({ row, updateInspectionValue, type, hasPermission }) => {
  const { cell } = row || {}
  const {
    value: initialValue,
    row: { index, original }
  } = cell || {}
  const { inspection_status } = original || {}

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  return (
    <div title={value} className="field-input">
      <input
        type="text"
        placeholder={type === 'damage_cost' ? 'Enter Charge' : 'Enter Code'}
        value={value || ''}
        disabled={!hasPermission || inspection_status === 'Damages Approved'}
        onChange={e => {
          const { target } = e || {}
          const { value } = target || {}
          let filterValue = value || ''

          if (type === 'damage_cost') {
            // Only allow Numbers and .
            filterValue = (value || '').replace(/[^0-9\.]/g, '')
            // Don't allow . as first character
            if ((filterValue || '')[0] === '.') {
              filterValue = ''
            }
            // Check if . already exists
            if ((filterValue || '').toString().split('.').length > 2) {
              filterValue = (filterValue || '').replace(/\.+$/, '')
            }
            // Dont allow more than 2 decimal points
            if (hasDecimalPlace(filterValue, 3)) {
              return
            }
          }

          if (type === 'damage_code') {
            // Only allow Numbers and - /
            filterValue = (value || '').replace(/[^0-9A-Za-z\-/]/g, '')
            // Don't allow . as first character
            if ((filterValue || '')[0] === '.') {
              filterValue = ''
            }
            // Don't allow decimal points
            if (hasDecimalPlace(filterValue, 0)) {
              return
            }
          }

          updateInspectionValue(index, filterValue, type)
          setValue(filterValue)
        }}
      />
    </div>
  )
}

export default InputField
