import React, { useEffect, useState } from 'react'

const ToggleEnableLogin = ({ onChange, isActive }) => {
  const [isChecked, setChecked] = useState(isActive || false)

  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  const handleChange = () => {
    setChecked(!isChecked)
  }

  useEffect(() => {
    onChange(isChecked)
  }, [isChecked])
  return (
    <div className="toggle-container">
      <label className="toggle-control">
        <input type="checkbox" checked={isChecked} onChange={handleChange} />
        <span className="control"></span>
      </label>
    </div>
  )
}

export default ToggleEnableLogin
