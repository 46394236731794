import React, { useEffect, useState } from 'react'
import { closeOutline } from 'ui/icons'
import PropTypes from 'prop-types'
import './index.scss'

const InspectionPDFModal = props => {
  const { onToggle, inspect, unit, id } = props || {}
  // Redux
  const { inspectionPDFURL } = inspect || {}

  // Close Modal
  const handleClose = () => {
    onToggle()
  }

  return (
    <div className="inspection-modal-wrapper">
      <div className="inspection-modal-header">
        <div className="columns">
          <div className="column">
            <h4>{unit || ''}</h4>
          </div>
          <div className="column">
            <span onClick={handleClose}>
              <img
                className="action-button action-button-close"
                src={closeOutline}
                alt="close"
              />
            </span>
          </div>
        </div>
      </div>
      <div className="pdf-wrapper">
        <iframe src={inspectionPDFURL} width="100%"></iframe>
      </div>
    </div>
  )
}

InspectionPDFModal.propTypes = {
  inspect: PropTypes.object,
  unit: PropTypes.string,
  onToggle: PropTypes.func,
  id: PropTypes.string
}

export default InspectionPDFModal
