import _ from 'lodash'
import React from 'react'

const AppliedFilters = ({
  ignoreLabel = false,
  alignLeft = false,
  activeFilters,
  customFilters,
  definitions,
  onFilterRemove,
  getTagCaption,
  isInner = false,
  className
}) => {
  const customExpanded = Object.keys(activeFilters).filter(
    key => !_.find(customFilters, f => f.key === key)
  )
  const basicExpanded = Object.keys(activeFilters).filter(key =>
    _.find(customFilters, f => f.key === key)
  )
  if (customExpanded.length || basicExpanded.length) {
    const getCustomTags = (keys, definitions) =>
      keys
        .map(key => {
          const field = _.find(definitions, d => d.key === key)
          let values = activeFilters[key]
          const column = _.find(definitions, def => def.key === key)
          const transform = _.get(column, 'renderMatch', v => v)
          if (values) {
            if (Array.isArray(values)) {
              const captions = _.map(
                values,
                v => `${_.get(field, 'display', key)}: ${transform(v)}`
              )
              values = captions.map((value, index) => ({ key, value, rawValue: values[index] }))
            } else {
              values = [{ key, value: transform(values) }]
            }
            return values
          }
          return []
        })
        .flat()
    const customTags = getCustomTags(customExpanded, definitions)
    const basicTags = _.map(basicExpanded, tag => ({
      key: tag,
      caption: `Exception: ${_.get(
        _.find(customFilters, filter => filter.key === tag),
        'caption'
      )}`
    }))

    return (
      <div
        className={`columns ${
          !alignLeft ? 'has-text-right' : ''
        } is-vcentered ${isInner ? 'p-b-md m-none p-t-none' : ''} ${className}`}>
        {!ignoreLabel && (
          <div
            className={`column small-text p-r-md p-t-none ${
              alignLeft ? 'is-narrow' : ''
            }`}>
            Applied Filters:
          </div>
        )}
        {basicExpanded.length > 0 &&
          basicTags.map((item, index) => (
            <div
              className={`column is-narrow p-l-xs p-t-none ${
                index === basicTags.length - 1 && !customTags.length
                  ? 'p-r-none'
                  : 'p-r-none'
              }`}
              key={`basic-${index}`}>
              <div className="tags has-addons">
                <div className="tag">{item.caption}</div>
                {onFilterRemove && (
                  <div
                    className="tag is-delete"
                    onClick={() => onFilterRemove(item)}></div>
                )}
              </div>
            </div>
          ))}
        {customExpanded.length > 0 &&
          customTags.map((item, index) => (
            <div
              className={`column is-narrow p-l-xs p-t-none ${
                index === customExpanded.length - 1 ? 'p-r-xs' : 'p-r-none'
              }`}
              key={`tag-${index}`}>
              <div className="tags has-addons">
                <div className="tag">
                  {getTagCaption ? getTagCaption(item.value) : item.value}
                </div>
                <div
                  className="tag is-delete"
                  onClick={e => onFilterRemove(item)}></div>
              </div>
            </div>
          ))}
      </div>
    )
  }
  return null
}

export default AppliedFilters
