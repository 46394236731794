import _ from 'lodash'
import Short from 'short-uuid'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const TYPES = [
  'General',
  'Vendor General',
  'Vendor Service',
  'Furniture'
  //'Additional Charge'
]

const NotesModalEdit = ({
  note = '',
  type = '',
  additionalInfo = {},
  adding = true,
  onNoteChanged,
  onTypeChanged,
  onAdditionalInfoChanged,
  allowCancel = false,
  onCancel = () => {}
}) => {
  const { charge } = additionalInfo

  return (
    <>
      {/* <div className="columns m-b-none">
        <div className="column p-b-none">
          <label htmlFor="note-edit">
            {adding ? 'Add a Note' : 'Edit Note'}
          </label>
        </div>
        {allowCancel && (
          <div className="column is-narrow is-pulled-right p-b-none">
            <a href="#!" onClick={() => onCancel()}>
              <FontAwesomeIcon icon={faTimes} size="xs" />
            </a>
          </div>
        )}
      </div> */}
      <div style={{ display: 'flex' }}>
        <div className="m-b-md" style={{ width: '50%' }}>
          <label htmlFor="note-category" className="input-label">
            Note Category
          </label>
          <div className="select">
            <select
              value={type}
              id="note-category"
              onChange={e => onTypeChanged(e.target.value)}>
              <option value="">Select Note Category</option>
              {_.map(TYPES, t => (
                <option key={Short.uuid()}>{t}</option>
              ))}
            </select>
          </div>
        </div>
        {/* {type === TYPES[3] && (
          <div className="m-b-md m-l-sm" style={{ width: '30%' }}>
            <label htmlFor="note-category" className="input-label">
              Charge
            </label>
            <div>
              <input
                className="input"
                type="number"
                defaultValue={charge}
                min="0"
                name="charge"
                onBlur={e =>
                  onAdditionalInfoChanged(e.target.name, e.target.value)
                }></input>
            </div>
          </div>
        )} */}
      </div>

      <label htmlFor="note-edit" className="input-label">
        Note Message
        {allowCancel && (
          <a
            href="#!"
            className="is-pulled-right p-b-none"
            onClick={() => onCancel()}>
            <FontAwesomeIcon icon={faTimes} size="xs" />
          </a>
        )}
      </label>
      <textarea
        className="textarea m-t-sm m-b-md"
        rows={4}
        placeholder="Add notes here..."
        id="note-edit"
        defaultValue={note}
        onBlur={e => onNoteChanged(e.target.value)}
      />
    </>
  )
}

export default NotesModalEdit
