import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'

export const icons = {
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff
}
