import React, { useEffect, useState } from 'react'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import HeadingTemplate from '../../components/forms-helper/HeadingTemplate'
import GeneralFieldNoPadding from '../../components/forms-helper/GeneralFieldNoPadding'
import EditIcon from './icons/edit.svg'
import DataTableMenu from '../../components/data-table-menu'
import { useParams } from 'react-router-dom'
import { capitalize } from '../../utils/helperFunctions'
import BigLoading from '../../components/big-loading'
import PasswordReset from '../../components/forms-helper/PasswordReset'
import EnableUserLogin from '../../components/forms-helper/EnableUserLogin'
import PermissionGate from '../../components/permission-gate'
import RestrictedElement from 'containers/restricted-element'
import './index.scss'
import { isEmpty } from 'lodash'

const VendorLoginViewUser = props => {
  const {
    history,
    newUserDetail,
    clearUser,
    user,
    setupUsers,
    allPropertiesList,
    context
  } = props || {}
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [permissionRule, setPermissionRule] = useState(null)

  const { id } = useParams()
  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  // Redux
  const { userDetail, propertiesList } = setupUsers || {}
  const { hasMadeInitialRequest, isRequesting, data: userDetailData } =
    userDetail || {}

  const onError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSuccess = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    allPropertiesList(pdbid)
    newUserDetail(pdbid, id, onSuccess, onError)
    return () => {
      clearUser()
    }
  }, [id])

  const {
    user_id,
    name,
    email,
    title,
    type,
    phone,
    alt_phone,
    notes,
    is_inactive,
    email_verified
  } = userDetailData || {}

  useEffect(() => {
    if (!isEmpty(userDetailData)) {
      const mappedPermissionRules = (
        userDetailData?.permission_rules || []
      ).map(rule => ({
        action: 'Update',
        internal_permission_rule_id: rule.internal_permission_rule_id,
        login_access: rule.login_access,
        permission_rule_id: rule.permission_rule_id,
        project_id: rule.project?.[0]?.project_id,
        property_id: rule.property_id || rule.project[0]?.property_id,
        role_id: rule.role_id,
        status: rule.status
      }))

      const propertyFound = (propertiesList || []).find(
        ({ property_code, project_code }) =>
          property_code === context.property && project_code === context.project
      )

      const foundRule = (mappedPermissionRules || []).find(
        ({ property_id, project_id }) =>
          propertyFound?.property_id === property_id &&
          propertyFound?.project_id === project_id
      )

      setPermissionRule(foundRule)
    }
  }, [userDetailData, propertiesList])

  const handleEnableLoginChange = value => {
    const login_access = value ? null : 'false'

    setPermissionRule(prevRule => ({
      ...prevRule,
      login_access
    }))
  }

  const capitalizeData = value => {
    return capitalize(value || '')
  }

  if ((hasMadeInitialRequest && !isRequesting) || isLoading) {
    return <BigLoading />
  }

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <div>
          <p>Something went wrong. Please try again later!!!</p>
        </div>
      </div>
    )
  }

  return (
    <div className="columns is-multiline vendor-login-view-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <HeadingTemplate>
            <div className="label-heading column mb-8">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <strong className="is-size-5">Personal Information</strong>
                <PermissionGate name={'edit-vendor'}>
                  <RestrictedElement>
                    <div className="list-container">
                      <DataTableMenu
                        options={[
                          {
                            caption: 'Edit Vendor User',
                            icon: EditIcon,
                            action: () =>
                              history.push(`/setup/vendors/edit/${user_id}`)
                          }
                        ]}
                        row={{
                          user_id
                        }}
                      />
                    </div>
                  </RestrictedElement>
                </PermissionGate>
              </div>
            </div>
          </HeadingTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Name:">
              <label>{capitalizeData(name)}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Email Address:">
              <label>{email || ''}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Title:">
              <label>{capitalizeData(title)}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Type:">
              <label>{capitalizeData(type)}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Phone:">
              <label>{phone || 'N/A'}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Alt Phone:">
              <label>{alt_phone || 'N/A'}</label>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Notes:">
              <div className="note-container">
                <label>{capitalizeData(notes)}</label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralFieldNoPadding fieldLabel="Enable Login:">
              <div className="note-container">
                <label>
                  {!permissionRule?.login_access ? 'Enabled' : 'Disabled'}
                </label>
              </div>
            </GeneralFieldNoPadding>
          </FieldTemplate>
          <div>
            <div className="button-wrapper">
              <PermissionGate name={'edit-vendor'}>
                <RestrictedElement>
                  <div className="box is-shadowless has-transition-opacity">
                    <button
                      className="button main-button is-primary"
                      onClick={() =>
                        history.push(`/setup/vendors/edit/${user_id}`)
                      }>
                      Edit
                    </button>
                  </div>
                </RestrictedElement>
              </PermissionGate>
            </div>
          </div>
        </div>
      </div>

      <PermissionGate name={'edit-vendor'}>
        <RestrictedElement>
          <div className="column is-half animated">
            <EnableUserLogin onEnableLoginChange={handleEnableLoginChange} />
            {email_verified ? <PasswordReset /> : null}
          </div>
        </RestrictedElement>
      </PermissionGate>
    </div>
  )
}

export default VendorLoginViewUser
