import React from 'react'
import {
  caretUpOn,
  caretUpOff,
  caretDownOffWhite,
  caretUpOffWhite,
  caretDownOn,
  caretDownOff
} from 'ui/icons'

const SortIcon = ({ column, showWhite }) => {
  const wrapperStyle = {
    verticalAlign: 'middle',
    display: 'inline-block',
    marginLeft: 5,
    marginTop: -4
  }

  const ascIconStyle = {
    display: 'block',
    marginTop: '3px'
  }

  const descIconStyle = {
    display: 'block',
    marginTop: '-3px'
  }

  const caretUpOffDefault = showWhite ? caretUpOffWhite : caretUpOff
  const caretDownOffDefault = showWhite ? caretDownOffWhite : caretDownOff

  const ascIcon = !column.isSortedDesc ? caretUpOn : caretUpOffDefault
  const descIcon = column.isSortedDesc ? caretDownOn : caretDownOffDefault

  if (column.isSorted) {
    return (
      <div style={wrapperStyle}>
        <img style={ascIconStyle} src={ascIcon} alt="Sort in ascending order" />
        <img
          style={descIconStyle}
          src={descIcon}
          alt="Sort in descending order"
        />
      </div>
    )
  } else if (column.canSort) {
    return (
      <div style={wrapperStyle}>
        <img
          style={ascIconStyle}
          src={caretUpOffDefault}
          alt="Sort in ascending order"
        />
        <img
          style={descIconStyle}
          src={caretDownOffDefault}
          alt="Sort in descending order"
        />
      </div>
    )
  }

  return ''
}

export default SortIcon
