import LOGOUT from '../auth'
import {
  INVOICE_APPROVE,
  INVOICE_CREATE_FAIL,
  INVOICE_CREATE_REQUESTED,
  INVOICE_CREATE_SUCCESS,
  INVOICE_DELETE_FAIL,
  INVOICE_DELETE_REQUESTED,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_REQUESTED,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_LINE_CREATE_FAIL,
  INVOICE_LINE_CREATE_REQUESTED,
  INVOICE_LINE_CREATE_SUCCESS,
  INVOICE_LINE_DELETE_FAIL,
  INVOICE_LINE_DELETE_REQUESTED,
  INVOICE_LINE_DELETE_SUCCESS,
  INVOICE_LINE_UPDATE_FAIL,
  INVOICE_LINE_UPDATE_REQUESTED,
  INVOICE_LINE_UPDATE_SUCCESS,
  INVOICE_LINES_GENERATE_FAIL,
  INVOICE_LINES_GENERATE_REQUESTED,
  INVOICE_LINES_GENERATE_SUCCESS,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_REQUESTED,
  INVOICE_LIST_SUCCESS,
  INVOICE_PAYED,
  INVOICE_SENT,
  INVOICE_UPDATE_FAIL,
  INVOICE_UPDATE_REQUESTED,
  INVOICE_UPDATE_SUCCESS,
  RESET_INVOICES,
  INVOICE_START_EDITING,
  INVOICE_FINISH_EDITING,
  INVOICE_BACK_TO_DRAFT,
  INVOICE_ADD_TAX_REQUESTED,
  INVOICE_ADD_TAX_SUCCESS,
  INVOICE_DETAILS_RESET,
  INVOICE_CHANGE_STATUS_REQUESTED,
  INVOICE_CHANGE_STATUS_SUCCESS,
  INVOICE_AMOUNT_PAID_REQUESTED,
  INVOICE_AMOUNT_PAID_SUCCESS
} from './types'

const initialState = {
  listHasRequested: false,
  listIsRequesting: false,
  listIsError: false,
  list: [],
  detailsIsRequesting: false,
  detailsIsError: false,
  details: {},
  deleteIsRequesting: false,
  deleteSuccess: false,
  addTaxesIsRequesting: false,
  addTaxesSuccess: false,
  tasks: [],
  addAmountPaidSuccess: false,
  addAmountPaidIsRequesting: false
}

/**
 * Invoices data reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_INVOICES:
      return initialState
    case INVOICE_LIST_REQUESTED:
      return {
        ...state,
        listHasRequested: true,
        listIsRequesting: true,
        listIsError: false
      }
    case INVOICE_LIST_SUCCESS:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: false,
        list: action.list,
        services: action.services
      }
    case INVOICE_LIST_FAIL:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: true
      }
    case INVOICE_DETAILS_REQUESTED:
    case INVOICE_CREATE_REQUESTED:
    case INVOICE_UPDATE_REQUESTED:
    case INVOICE_LINES_GENERATE_REQUESTED:
    case INVOICE_LINE_CREATE_REQUESTED:
    case INVOICE_LINE_UPDATE_REQUESTED:
    case INVOICE_LINE_DELETE_REQUESTED:
    case INVOICE_CHANGE_STATUS_REQUESTED:
      return {
        ...state,
        detailsIsRequesting: true,
        detailsIsError: false,
        deleteSuccess: false
      }
    case INVOICE_ADD_TAX_REQUESTED:
      return {
        ...state,
        addTaxesIsRequesting: true,
        addTaxesSuccess: false
      }
    case INVOICE_ADD_TAX_SUCCESS:
      return {
        ...state,
        addTaxesSuccess: true,
        addTaxesIsRequesting: false,
        details: {}
      }
    case INVOICE_AMOUNT_PAID_REQUESTED:
      return {
        ...state,
        addAmountPaidIsRequesting: true,
        addAmountPaidSuccess: false
      }
    case INVOICE_AMOUNT_PAID_SUCCESS:
      return {
        ...state,
        addAmountPaidSuccess: true,
        addAmountPaidIsRequesting: false,
        details: {}
      }
    case INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: action.details,
        tasks: action.tasks
      }
    case INVOICE_CREATE_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        list: state.list.concat([action.invoice]),
        tasks: action.tasks
      }
    case INVOICE_UPDATE_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: action.payload.data[0]
      }
    case INVOICE_LINES_GENERATE_SUCCESS: {
      const generateIndex = state.list.findIndex(
        list => list.invoice_id === action.payload.invoiceId
      )
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: action.payload.invoice,
        list: state.list
          .slice(0, generateIndex)
          .concat([action.payload.invoiceShort])
          .concat(state.list.slice(generateIndex + 1))
      }
    }
    case INVOICE_DELETE_REQUESTED: {
      return {
        ...state,
        deleteIsRequesting: true,
        detailsIsError: false,
        deleteSuccess: false
      }
    }
    case INVOICE_DELETE_SUCCESS: {
      const deleteIndex = state.list.findIndex(l => l.po_id === action.po_id)
      if (deleteIndex >= 0) {
        return {
          ...state,
          details: {},
          deleteIsRequesting: false,
          detailsIsError: false,
          deleteSuccess: true,
          list: state.list
            .slice(0, deleteIndex)
            .concat(state.list.slice(deleteIndex + 1))
        }
      }
      return {
        ...state,
        deleteIsRequesting: false,
        deleteSuccess: true,
        detailsIsError: false
      }
    }
    case INVOICE_LINE_CREATE_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: {
          ...state.details,
          lines: state.details.lines.concat([action.item])
        }
      }
    case INVOICE_LINE_UPDATE_SUCCESS: {
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: {
          ...state.details,
          items: action.payload.items
        }
      }
    }
    case INVOICE_LINE_DELETE_SUCCESS: {
      const lineDeleteIndex = state?.details?.lines.findIndex(
        l => l.item_id === action.itemId
      )
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: false,
        details: {
          ...state.details,
          lines: state.details.lines
            .slice(0, lineDeleteIndex)
            .concat(state.details.lines.slice(lineDeleteIndex + 1))
        }
      }
    }
    case INVOICE_DETAILS_FAIL:
    case INVOICE_CREATE_FAIL:
    case INVOICE_UPDATE_FAIL:
    case INVOICE_DELETE_FAIL:
    case INVOICE_LINES_GENERATE_FAIL:
    case INVOICE_LINE_CREATE_FAIL:
    case INVOICE_LINE_UPDATE_FAIL:
    case INVOICE_LINE_DELETE_FAIL:
      return {
        ...state,
        detailsIsRequesting: false,
        detailsIsError: true
      }
    case INVOICE_SENT:
    case INVOICE_APPROVE:
    case INVOICE_PAYED:
    case INVOICE_BACK_TO_DRAFT:
    case INVOICE_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        detailsIsRequesting: false,
        details: { ...state.details, status: action.details.status }
      }
    case INVOICE_START_EDITING:
      return {
        ...state,
        details: { ...state.details, editing: true }
      }
    case INVOICE_FINISH_EDITING:
      return {
        ...state,
        details: { ...state.details, editing: false }
      }
    case INVOICE_DETAILS_RESET:
      return {
        ...state,
        details: {}
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}
