import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCompanyTemplateList } from '../../modules/company-templates'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import CompanyTemplatesMain from '../../parents/company-template-main'
import BigLoading from '../../components/big-loading'

const CompanyTemplates = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <CompanyTemplatesMain {...props} />
  }

  return <BigLoading />
}

CompanyTemplates.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  companyTemplates: PropTypes.object
}

const mapStateToProps = ({ user, context, companyTemplates, modal }) => ({
  user,
  context,
  companyTemplates,
  modal
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCompanyTemplateList,
      openModal,
      closeModal,
      setModalContent
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompanyTemplates)
)
