import React from 'react'
import PropTypes from 'prop-types'

const Good = (props) => {
  const { current_Parent_id, current_Child_id, line_note, line_media, current_State, currentItems, items, index, getMediaBody, onUploadFileHandler, getNotesField } = props || {}
  return <div className="child-accordion-data">
    <div className="is-flex is-align-items-center">
      <div className="state-heading">
        <h4>This rating doesn't require a photo.</h4>
      </div>
      <div className="state-description is-flex is-align-items-center is-flex-wrap-wrap">
        {(line_media || []).map(media => {
          const { line_media_id, fileName, type, preview, deleted } = media || {}
          if(!deleted) {
            return getMediaBody(fileName, type, line_media_id, preview, current_Parent_id, current_Child_id, current_State, currentItems, items, index)
          }
        })}
        <button onClick={() => onUploadFileHandler(current_Parent_id, current_Child_id, current_State)} className="is-link">
        Click here to add one of your records
        </button>
      </div>
    </div>
    { getNotesField('', '', line_note, current_Parent_id, current_Child_id, line_media, 'Good') }
  </div>
}

Good.propTypes = {
  current_Parent_id: PropTypes.number,
  current_Child_id: PropTypes.string,
  line_note: PropTypes.string,
  line_media: PropTypes.array,
  current_State: PropTypes.string,
  currentItems: PropTypes.object,
  items: PropTypes.array,
  index: PropTypes.number,
  getMediaBody: PropTypes.func, 
  onUploadFileHandler: PropTypes.func, 
  getNotesField: PropTypes.func
}

export default Good
