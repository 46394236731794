import React, { memo, useMemo } from 'react'

const MemoizedSingleCell = ({
  isChecked,
  unit,
  unit_space,
  display,
  taskId,
  item_option_id,
  serviceName,
  serviceOptions,
  unit_space_id
}) => {
  const check = useMemo(() => isChecked, [isChecked])
  return (
    <label className={`checkbox ${check && 'is-active'}`}>
      <span className={`checkbox-input ${check && 'is-active'}`}>
        <span className="checkbox-inner" />
        <input
          type="checkbox"
          className="checkbox-original"
          checked={check}
          name={`${unit}_${unit_space}_${display}`}
          value={`${taskId}_${item_option_id}`}
          onClick={e => {
            e.preventDefault()
            const body = {
              task_id: taskId,
              item_option_id: item_option_id,
              unit,
              unit_space,
              unit_space_id
            }
            serviceOptions(body, serviceName)
          }}
        />
      </span>
    </label>
  )
}

const MemoizedCell = memo(
  ({
    row,
    serviceOptions,
    item_option_id,
    changedServiceOptionsId,
    serviceName,
    display
  }) => {
    const { unit, unit_space, taskId, unit_space_id } = row || {}
    const isChecked = useMemo(
      () =>
        (changedServiceOptionsId || []).includes(
          `${unit}_${item_option_id}_${unit_space}`
        ),
      [changedServiceOptionsId]
    )
    const props = {
      isChecked,
      unit,
      unit_space,
      display,
      item_option_id,
      taskId,
      serviceOptions,
      serviceName,
      unit_space_id
    }
    return <MemoizedSingleCell {...props} />
  }
)

const MemoizedSingleCellNA = memo(
  ({ isChecked, notAvailableServiceOptions, unit, unit_space }) => {
    const check = useMemo(() => isChecked, [isChecked])
    return (
      <label className={`checkbox ${check && 'is-active'}`}>
        <span className={`checkbox-input ${check && 'is-active'}`}>
          <span className="checkbox-inner" />
          <input
            type="checkbox"
            checked={check}
            className="checkbox-original"
            onClick={e => {
              e.preventDefault()
              notAvailableServiceOptions(unit, unit_space)
            }}
          />
        </span>
      </label>
    )
  },
  (prevProps, nextProps) => {
    if (prevProps.isChecked === nextProps.isChecked) {
      return true
    }
    return false
  }
)

const MemoizedCellNA = memo(
  ({ row, notAvailableServiceData, notAvailableServiceOptions }) => {
    const { unit, unit_space } = row || {}
    const isChecked = useMemo(
      () => (notAvailableServiceData || []).includes(`${unit}_${unit_space}`),
      [notAvailableServiceData]
    )
    const props = {
      notAvailableServiceOptions,
      unit,
      unit_space,
      isChecked
    }
    return <MemoizedSingleCellNA {...props} />
  }
)

export const headerData = ({
  services = [],
  serviceOptions = () => {},
  changedServiceOptionsId = [],
  notAvailableServiceData = [],
  notAvailableServiceOptions = () => {},
  isAllNotAvailableRadio,
  allServiceRadioOptions = [],
  onChangeAllRadioButton,
  onChangeNAAllRadioButton,
  currentLoginUserRole
}) => [
  {
    title: 'Unit Details',
    backgroundColor: '#8BD3ED',
    color: '#ffffff',
    columns: useMemo(
      () => [
        {
          name: 'Bldg.',
          selector: row => row.building,
          key: 'building',
          center: true,
          compact: true,
          maxWidth: '300px',
          minWidth: '50px'
          //grow:1
        },
        {
          name: 'Floor',
          selector: row => row.floor,
          key: 'floor',
          center: true,
          compact: true,
          wrap: false,
          maxWidth: '200px',
          minWidth: '50px'
        },
        {
          name: 'Unit',
          selector: row => row.unit,
          center: true,
          compact: true,
          key: 'unit',
          ///width:'auto',
          //grow:2,
          maxWidth: '350px'
        },
        {
          name: 'Space',
          selector: row => row.unit_space,
          center: true,
          compact: true,
          key: 'space',
          minWidth: '80px',
          maxWidth: '200px'
        }
      ],
      []
    )
  },
  {
    title: 'N/A',
    backgroundColor: '#122048',
    color: '#122048',
    columns: [
      {
        name: (
          <div
            style={{
              width: 'max-content',
              marginLeft: '5px',
              marginRight: '5px',
              cursor: 'default'
            }}>
            {currentLoginUserRole && (
              <label>
                <input
                  className="is-inline"
                  type="radio"
                  checked={isAllNotAvailableRadio}
                  disabled={!currentLoginUserRole}
                  onClick={e => {
                    onChangeNAAllRadioButton()
                  }}
                />
                <span
                  style={{
                    marginBottom: '7px',
                    cursor: !currentLoginUserRole ? 'not-allowed' : 'pointer'
                  }}
                  className="custom-radio"></span>
              </label>
            )}
            <p>N/A</p>
          </div>
        ),
        selector: row => row.building,
        center: true,
        compact: true,
        width: '35px',
        wrap: false,
        format: row => {
          return (
            <MemoizedCellNA
              row={row}
              notAvailableServiceData={notAvailableServiceData}
              notAvailableServiceOptions={notAvailableServiceOptions}
            />
          )
        }
      }
    ]
  },
  ...(services || []).map((service, i) => {
    const {
      label: display,
      services_options: childService = [],
      servicetype: key
    } = service || {}
    const serviceName = (key || '')
      .toString()
      .replace(' ', '_')
      .toLowerCase()
    return {
      title: key,
      backgroundColor: '#122048',
      color: '#ffffff',
      columns: (childService || []).map(cService => {
        const { item_option, item_option_id } = cService || {}
        return {
          name: (
            <div
              style={{
                width: 'max-content',
                marginLeft: '5px',
                marginRight: '5px',
                cursor: 'default'
              }}>
              {currentLoginUserRole && (
                <label>
                  <input
                    className="is-inline"
                    type="radio"
                    value={display}
                    disabled={!currentLoginUserRole}
                    data-key={display}
                    checked={allServiceRadioOptions.some(
                      item =>
                        item.type ===
                          `${serviceName}_${item_option}_${item_option_id}` &&
                        item.check === true
                    )}
                    onClick={e => {
                      onChangeAllRadioButton(
                        serviceName,
                        item_option,
                        item_option_id
                      )
                    }}
                    data-instance="item"
                    data-type="radio"
                    name={display}
                  />
                  <span
                    style={{
                      marginBottom: '7px',
                      cursor: !currentLoginUserRole ? 'not-allowed' : 'pointer'
                    }}
                    className="custom-radio"></span>
                </label>
              )}
              <p>{item_option}</p>
            </div>
          ),
          s_name: item_option,
          s_id: display,
          option_id: item_option_id,
          serviceName: serviceName,
          selector: row => row.service_option,
          center: true,
          //width: '100px',
          //wrap: false,
          allowOverflow: true,
          format: row => {
            let data = { ...(row || {}) }
            const optionName = (item_option || '')
              .toString()
              .replace(/ /g, '_')
              .toLowerCase()
            if (data[`${optionName}_option`] === item_option) {
              data.taskId = data[`${optionName}_taskId`]
            } else {
              data.taskId = ''
            }
            return (
              <MemoizedCell
                row={data}
                serviceOptions={serviceOptions}
                item_option_id={item_option_id}
                changedServiceOptionsId={changedServiceOptionsId}
                serviceName={serviceName}
                display={display}
              />
            )
          }
        }
      })
    }
  })
]
