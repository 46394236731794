import { available, transfers } from 'ui/icons'
import { removeArrayDuplicates } from '../../../utils'

export  const filters = [
  {
    key: 'activeUnits',
    icon: available,
    caption: 'Available',
  },
  {
    key: 'istransfer',
    icon: transfers,
    caption: 'Transfer',
  },
]

export const getFieldFilters = units => {

  const buildings = removeArrayDuplicates(units.map(unit => unit.building)).map(item => ({ key: item, caption: item }))
  const floorplans = removeArrayDuplicates(units.map(unit => unit.floorplan)).map(item => ({ key: item, caption: item }))
  const groups = removeArrayDuplicates(units.map(unit => unit.group)).map(item => ({ key: item, caption: item }))

  return [
    {
      display: 'Building',
      filterable: true,
      key: 'building',
      options: buildings
    },
    {
      display: 'Floorplan',
      filterable: true,
      key: 'floorplan',
      options: floorplans
    },
    {
      display: 'Groups',
      filterable: true,
      key: 'group',
      options: groups
    },
  ]

}
