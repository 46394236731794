import { useEffect } from 'react'
import { useAuth0 } from '../../components/lock/auth0-spa'

const Logout = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    if (logout) {
      logout({ returnTo: window.location.origin })
    }
  }, [logout])

  return null
}

export default Logout
