import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import BigLoading from '../../components/big-loading'
import DownloadInspectionsMain from '../../parents/download-inspections-main'
import {
  getDownloadInspectionsList,
  viewAllDownloads
} from '../../modules/download-inspections'
import { getInspectionDownloadMediaURL } from '../../modules/inspect'

const DownloadInspections = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <DownloadInspectionsMain {...props} />
  }

  return <BigLoading />
}

DownloadInspections.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  downloadInspections: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  downloadInspections,
  inspect,
  modal
}) => ({
  user,
  context,
  downloadInspections,
  inspect,
  modal
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getDownloadInspectionsList,
      viewAllDownloads,
      getInspectionDownloadMediaURL
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DownloadInspections)
)
