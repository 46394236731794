import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { close } from 'ui/icons'
import './index.scss'

const UserListSortModal = props => {
  const {
    onClose,
    onHandleUserSortQuery,
    onHandleUserSortInfo,
    AppliedSortingValues
  } = props || {}

  const column_name = [
    { key: 'User ID', value: 'user_id' },
    { key: 'Name', value: 'name' },
    { key: 'Email', value: 'email' },
    { key: 'Status', value: 'status' },
    { key: 'Last Login', value: 'last_login' }
  ]

  const sort_type = [
    { key: 'Ascending', value: 'ASC' },
    { key: 'Descending', value: 'DESC' }
  ]

  const { name, type } = AppliedSortingValues || {}

  const previousSelectedColumn = column_name?.find(
    column => column.value === name?.[0]?.key
  )
  const previousSelectedSort = sort_type?.find(
    column => column.value === type?.[0]?.key
  )

  const [columnName, setColumnName] = useState(previousSelectedColumn || '')
  const [sortType, setSortType] = useState(previousSelectedSort || '')

  const styles = {
    valueContainer: provided => ({
      ...provided,
      minHeight: '45px',
      maxHeight: '70px',
      overflow: 'auto',
      position: 'relative'
    })
  }

  return (
    <div className="user-filter-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Sort</p>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full" style={{ marginBottom: '30px' }}>
        <form>
          <div className="columns form-group">
            <div className="column is-half">
              <div className="label">
                <label>Column Name</label>
              </div>
              <div className="input-field">
                <MultiSelectDropdown
                  displayKey="key"
                  value={columnName}
                  defaultValues={column_name}
                  placeholder="Select"
                  onChange={v => setColumnName(v)}
                  isMulti={false}
                  isClearable
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
            <div className="column is-half">
              <div className="label">
                <label>Sort By</label>
              </div>
              <div className="input-field">
                <MultiSelectDropdown
                  displayKey="key"
                  value={sortType}
                  defaultValues={sort_type}
                  placeholder="Select"
                  onChange={v => setSortType(v)}
                  isClearable
                  isMulti={false}
                  customStyles={{
                    ...styles
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="columns is-vcentered m-b-sm m-t-sm">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={() => {
              onClose()
            }}>
            Cancel
          </button>
          <button
            className="button main-button is-primary"
            disabled={!columnName?.value || !sortType?.value}
            onClick={event => {
              event.preventDefault()
              onHandleUserSortQuery(
                `&order_by=${columnName?.value}&order_type=${sortType?.value}`
              )
              onHandleUserSortInfo({
                name: columnName?.value,
                type: sortType?.value
              })
              onClose()
            }}>
            Sort
          </button>
        </div>
      </div>
    </div>
  )
}

UserListSortModal.propTypes = {
  onClose: PropTypes.func,
  onHandleUserSortQuery: PropTypes.func,
  onHandleUserSortInfo: PropTypes.func
}

export default UserListSortModal
