import _, { isEmpty, isEqual } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory, useParams } from 'react-router-dom'
import Breadcrumbs from '../breadcrumbs'
import SetupStaffList from '../setup-staff-list'
import SetupStaffManage from '../setup-staff-manage'
import SetupStaffViewDetail from '../setup-staff-view-detail'
import './index.scss'
import { useContextChanged } from '../../helpers/hooks'

const SetupStaffMain = props => {
  const params = useParams()
  const history = useHistory()

  const {
    location,
    type,
    setupUsers,
    allPropertiesList,
    allRolesList,
    user,
    context
  } = props
  const { verifiedEmail } = setupUsers || {}
  const { pdbid } = user?.user_metadata

  const { contextChanged } = useContextChanged(context)

  const Component = useMemo(() => {
    switch (type) {
      case 'NewStaff':
      case 'EditStaffDetail':
        return SetupStaffManage
      case 'ViewStaffDetail':
        return SetupStaffViewDetail
      default:
        return SetupStaffList
    }
  }, [SetupStaffList, SetupStaffManage, type])

  const isListView = location.pathname === '/setup/staff'
  const columnSize = isListView ? 'is-full' : 'is-full'

  useEffect(() => {
    if (type === 'NewStaff' && !params.id && !verifiedEmail?.emailVerified) {
      return history.replace('/setup/staff')
    }
  }, [verifiedEmail, type])

  useEffect(() => {
    if (
      contextChanged &&
      (type === 'EditStaffDetail' || type === 'ViewStaffDetail')
    ) {
      return history.replace('/setup/staff')
    }
  }, [contextChanged, type])

  useEffect(() => {
    allPropertiesList(pdbid)
    allRolesList(pdbid)
  }, [])

  return (
    <div className="container setup-staff-main animated fadeIn">
      <div className={`section ${!isListView ? 'vendor-section' : ''}`}>
        <Breadcrumbs location={location} />
        {!isListView && (
          <div class="back-text">
            <Link to="/setup/staff" className="p-none">
              Back to List View
            </Link>
          </div>
        )}
        <div className="columns setup-staff-main">
          <div className={`column ${columnSize}`}>
            <Component {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

SetupStaffMain.propTypes = {
  location: PropTypes.object,
  setupStaff: PropTypes.object
}

export default SetupStaffMain
