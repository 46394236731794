import React, { useState } from 'react'
import Accordion from '../../components/accordion'
import InspectionMediaModal from '../../components/inspection-media-modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import './InspectionViewContent.scss'

const inspectionViewContent = props => {
  const { currentInspection, inspect, context, unit, inspectionData } =
    props || {}

  // Redux data
  const { inspectionDownloadMediaURL } = inspect || {}
  // States
  const [showMediaModal, setShowMediaModal] = useState(false)
  const [inspectionMediaList, setInspectionMediaList] = useState([])

  // To get media file to display
  const getFullMediaPath = file_name => {
    // Meta Data
    const { project, property } = context || {}
    return `${inspectionDownloadMediaURL || ''}Inspections/${project ||
      ''}_${property || ''}/${file_name || ''}`
  }
  // Media Body image/video
  const getMediaBody = (file_name, type, line_media_id, line_media) => {
    const toLowerType = (type || '').toString().toLowerCase()
    return (
      <div
        onClick={() => {
          setInspectionMediaList(line_media)
          toggleModal()
        }}
        key={line_media_id}
        className="inspection-thumbnail-inner is-flex is-align-items-center">
        {toLowerType === 'photo' && (
          <img src={getFullMediaPath(file_name)} alt="" />
        )}
        {toLowerType === 'video' && <video src={getFullMediaPath(file_name)} />}
      </div>
    )
  }
  const toggleModal = () => {
    setShowMediaModal(!showMediaModal)
  }
  // To show spaces states
  const getItemsState = (items, status) => {
    if (status === 'Not Required') {
      return 'Not Required'
    } else {
      const checkState = (items || []).map(item => item.state)
      let state = ''
      if ((checkState || []).every(element => element !== null)) {
        state = 'Completed'
      } else if ((checkState || []).some(element => element !== null)) {
        state = 'Started'
      }
      return state
    }
  }

  return (
    <div>
      {(currentInspection || []).map((inspectLines, i) => {
        const { location, items, status } = inspectLines || {}
        const spaceState = getItemsState(items, status)
        return (
          <Accordion
            isOpen={status === 'Not Required' ? false : true}
            isDisabled={spaceState === 'Not Required'}
            key={i}
            spaceState={spaceState}
            title={location || ''}>
            {(items || []).map(sItem => {
              const {
                state,
                item,
                observations,
                line_note,
                line_media,
                line_id
              } = sItem || {}
              let getSolutionsArray = []
              let isObservationSelected = false
              let filteredLineMedia = (line_media || []).filter(
                media => !media.deleted
              )
              filteredLineMedia = (filteredLineMedia || []).map(d => {
                d.location = location || ''
                d.unit = unit || ''
                return d
              })

              if (state === null) {
                return (
                  <div
                    className="inspection-data is-flex is-align-items-center"
                    key={line_id}>
                    <h3 className="default-heading">{item}</h3>
                    <div className="default-state">
                      <p className="inspection-btn">Good</p>
                      <p className="inspection-btn">Needs Work</p>
                    </div>
                  </div>
                )
              }
              if (state === 'Needs Work') {
                return (
                  <div className="inspection-data is-flex" key={line_id}>
                    <h3>{item}</h3>
                    <div className="is-flex">
                      <p className="state-dot red-state"></p>
                      <div className="inspection-data-inner">
                        <h4>Observation:</h4>
                        {(observations || []).map(observe => {
                          const {
                            observation,
                            line_observation_id,
                            solutions,
                            selected: selectedObservation
                          } = observe || {}
                          if (selectedObservation) {
                            getSolutionsArray = solutions
                            isObservationSelected = true
                            return (
                              <p key={line_observation_id}>{observation}</p>
                            )
                          }
                        })}
                      </div>
                      <div className="inspection-data-inner">
                        <h4>Solution:</h4>
                        {isObservationSelected &&
                          (getSolutionsArray || []).map(cSolution => {
                            const {
                              line_solution_id,
                              solution,
                              selected: selectedSolution
                            } = cSolution || {}
                            if (selectedSolution) {
                              return <p key={line_solution_id}>{solution}</p>
                            }
                          })}
                      </div>
                      <div className="inspection-data-inner inspection-data-detail">
                        <h4>Note:</h4>
                        <p>{line_note || ''}</p>
                      </div>
                      <div className="inspection-thumbnail is-flex is-flex-wrap-wrap">
                        {isObservationSelected &&
                          (filteredLineMedia || []).map((media, i) => {
                            const { line_media_id, fileName, type } =
                              media || {}
                            if (i === 0) {
                              return getMediaBody(
                                fileName,
                                type,
                                line_media_id,
                                filteredLineMedia
                              )
                            }
                          })}
                      </div>
                    </div>
                  </div>
                )
              }
              if (state === 'Good') {
                return (
                  <div className="inspection-data is-flex" key={line_id}>
                    <h3>{item}</h3>
                    <div className="is-flex">
                      <p className="state-dot green-state"></p>
                      <div className="inspection-data-inner">
                        <h4>Observation:</h4>
                      </div>
                      <div className="inspection-data-inner">
                        <h4>Solution:</h4>
                      </div>
                      <div className="inspection-data-inner inspection-data-detail">
                        <h4>Note:</h4>
                        <p>{line_note || ''}</p>
                      </div>
                      <div className="inspection-thumbnail is-flex is-flex-wrap-wrap">
                        {(filteredLineMedia || []).map((media, i) => {
                          const { line_media_id, fileName, type } = media || {}
                          if (i === 0) {
                            return getMediaBody(
                              fileName,
                              type,
                              line_media_id,
                              filteredLineMedia
                            )
                          }
                        })}
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </Accordion>
        )
      })}
      {showMediaModal && (
        <InspectionMediaModal
          {...props}
          onToggle={toggleModal}
          currentRow={inspectionData}
          inspectionMediaList={inspectionMediaList}
          inspectionDownloadMediaURL={inspectionDownloadMediaURL}
          unit={unit}
        />
      )}
    </div>
  )
}

inspectionViewContent.propTypes = {
  inspectionData: PropTypes.object,
  isEdit: PropTypes.bool,
  unit: PropTypes.string,
  inspect: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ inspect, context }) => ({
  inspect,
  context
})

export default connect(
  mapStateToProps,
  {}
)(inspectionViewContent)
