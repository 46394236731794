import { useEffect } from 'react'

const useClickOutside = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref?.current && !ref.current?.contains(event.target)) {
        callback && callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useClickOutside
