import React, { forwardRef } from 'react'
import PieChartCard from '../../components/pie-chart-card'

const PropertiesCompleted = ({ completed, total, minHeight }, ref) => {
  return (
    <div ref={ref}>
      <PieChartCard
        showLoading={true}
        title="Properties Completed"
        isDonut={true}
        centerLabel={`${completed} / ${total}`}
        data={[
          {
            category: 'Completed',
            value: completed
          },
          {
            category: 'Remaining',
            value: total - completed
          }
        ]}
        useColors={['#55c2b8', '#d4d8dd']}
        minHeight={minHeight}
      />
    </div>
  )
}

export default forwardRef(PropertiesCompleted)
