import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import propertyDetailsMock from '../mocks/property_details.mock'

/**
 * Setup property details data module
 * @redux
 * @reduxActionScope setup-properties-details
 * @module setup-details
 */

/**
 * Request details for a particular property
 * @type {Redux.ActionType}
 */
export const GET_REQUESTED = 'setup-properties-details/GET_REQUESTED'
/**
 * Property details request failed
 * @type {Redux.ActionType}
 */
export const GET_FAIL = 'setup-properties-details/GET_FAIL'
/**
 * Update requested property details with successful data from API
 * @type {Redux.ActionType}
 */
export const GET_SUCCESS = 'setup-properties-details/GET_SUCCESS'

/**
 * Request property creation
 * @type {Redux.ActionType}
 */
export const CREATE_REQUESTED = 'setup-properties-details/CREATE_REQUESTED'
/**
 * Property creation failed
 * @type {Redux.ActionType}
 */
export const CREATE_FAIL = 'setup-properties-details/CREATE_FAIL'
/**
 * Refresh property details after a successful creation verified by the API
 * @type {Redux.ActionType}
 */
export const CREATE_SUCCESS = 'setup-properties-details/CREATE_SUCCESS'

/**
 * Request property update
 * @type {Redux.ActionType}
 */
export const UPDATE_REQUESTED = 'setup-properties-details/UPDATE_REQUESTED'
/**
 * Property update failed
 * @type {Redux.ActionType}
 */
export const UPDATE_FAIL = 'setup-properties-details/UPDATE_FAIL'
/**
 * Refresh property details after a successful update verified by the API
 * @type {Redux.ActionType}
 */
export const UPDATE_SUCCESS = 'setup-properties-details/UPDATE_SUCCESS'

/**
 * Request property delete
 * @type {Redux.ActionType}
 */
export const DELETE_REQUESTED = 'setup-properties-details/DELETE_REQUESTED'
/**
 * Property delete failed
 * @type {Redux.ActionType}
 */
export const DELETE_FAIL = 'setup-properties-details/DELETE_FAIL'
/**
 * Refresh property details after a successful delete verified by the API
 * @type {Redux.ActionType}
 */
export const DELETE_SUCCESS = 'setup-properties-details/DELETE_SUCCESS'

const DP = ''
const OH = { timeFrom: DP, timeTo: DP }
const propertyInitialState = {
  name: DP,
  uri: DP,
  id: DP,
  general_manager: DP,
  assistant_gm: DP,
  regional_manager: DP,
  address: {
    address1: DP,
    address2: DP,
    city: DP,
    state: DP,
    zip: DP,
    country: DP
  },
  phone: DP,
  email: DP,
  office_hours: [OH, OH, OH, OH, OH, OH, OH],
  time_zone: ''
}

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: propertyInitialState
}

/**
 * Setup property details reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case DELETE_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case GET_FAIL:
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets the details for a property
 * @redux
 * @reduxActionCreator GET_REQUESTED, GET_SUCCESS, GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id for the requested property
 */
export const setupOnePropertyGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: GET_REQUESTED
    })
    Service.setupOnePropertyGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          const data = propertyDetailsMock
          dispatch({
            type: GET_SUCCESS,
            data: onePropertyAdapter(data)
          })
        } else {
          _setupOnePropertyGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyGetFail(dispatch)
      })
  }
}

const _setupOnePropertyGetFail = dispatch => {
  dispatch({
    type: GET_FAIL
  })
}

export const onePropertyAdapter = data => {
  return {
    uri: typeof data.property_uri === 'string' ? data.property_uri : DP,
    name: typeof data.property_name === 'string' ? data.property_name : DP,
    id: typeof data.property_id === 'string' ? data.property_id : DP,
    general_manager:
      typeof data.general_manager === 'string' ? data.general_manager : DP,
    assistant_gm:
      typeof data.assistant_gm === 'string' ? data.assistant_gm : DP,
    regional_manager:
      typeof data.regional_manager === 'string' ? data.regional_manager : DP,
    address: {
      address1: typeof data.address1 === 'string' ? data.address1 : DP,
      address2: typeof data.address2 === 'string' ? data.address2 : DP,
      address3: typeof data.address3 === 'string' ? data.address3 : DP,
      city: typeof data.city === 'string' ? data.city : DP,
      state: typeof data.state === 'string' ? data.state : DP,
      zip: typeof data.zip === 'string' ? data.zip : DP,
      country: typeof data.country === 'string' ? data.country : DP
    },
    phone: typeof data.phone === 'string' ? data.phone : DP,
    email: typeof data.email === 'string' ? data.email : DP,
    office_hours: Array.isArray(data.office_hours)
      ? _.map([OH, OH, OH, OH, OH, OH, OH], (day, index) => ({
        timeFrom: _.get(
          data,
          `office_hours.${index}.time_from`,
          day.timeFrom
        ),
        timeTo: _.get(data, `office_hours.${index}.time_to`, day.timeTo)
      }))
      : [OH, OH, OH, OH, OH, OH, OH],
    time_zone: typeof data.time_zone === 'string' ? data.time_zone : DP
  }
}

/**
 * Creates a new property
 * @redux
 * @reduxActionCreator CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new property
 */
export const setupOnePropertyCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: CREATE_REQUESTED
    })
    Service.setupOnePropertyCreate(pdbid, context, '', 'details', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CREATE_SUCCESS,
            data: onePropertyAdapter(data)
          })
        } else {
          _setupOnePropertyCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyCreateFail(dispatch)
      })
  }
}

const _setupOnePropertyCreateFail = dispatch => {
  dispatch({
    type: CREATE_FAIL
  })
}

/**
 * Updates an existing property
 * @redux
 * @reduxActionCreator UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing property
 * @param {Object} body - the JSON object for the updated property
 */
export const setupOnePropertyUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyUpdate(pdbid, context, id, 'details', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: onePropertyAdapter(data)
          })
        } else {
          _setupOnePropertyUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUpdateFail(dispatch)
      })
  }
}

const _setupOnePropertyUpdateFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}

/**
 * Deletes an existing property
 * @redux
 * @reduxActionCreator DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing property
 */
export const setupOnePropertyDelete = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyDelete(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: onePropertyAdapter(data)
          })
        } else {
          _setupOnePropertyDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyDeleteFail(dispatch)
      })
  }
}

const _setupOnePropertyDeleteFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}
