import React from 'react'
import PropTypes from 'prop-types'
import './index.scss'
import ComponentWithStatus from '../component-with-status'

/**
 * Days to move in box
 *
 * @component
 * @example
 * return (
 *   <DaysToMoveIn
 *     loading={false}
 *     error={false}
 *     days={5}
 *   />
 * )
 */
const DaysToMoveIn = ({ loading, error, days }) => {
  return (
    <div className="dashboard-card card-sm">
      <ComponentWithStatus loading={loading} error={error}>
        <div className="has-text-centered vertical-center">
          <p className="is-size-1 main-color strongest">{days}</p>
          <p style={{ paddingBottom: '16px' }}>Days To Move In</p>
        </div>
      </ComponentWithStatus>
    </div>
  )
}

DaysToMoveIn.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Total days remaining in turn
   */
  days: PropTypes.number
}

export default DaysToMoveIn
