import iconAnalytics from './icons/analytics@1x.svg'
import iconProjects from './icons/projects@1x.svg'
import iconSetup from './icons/setup@1x.svg'
export const boxValues = {
  setup: {
    icon: iconSetup,
    name: 'Setup',
    caption: 'Update your settings and information.',
    isPrimary: false,
    onButtonClick: ()=>{},
  },
  analytics: {
    icon: iconAnalytics,
    name: 'Analytics',
    caption: 'Track your project\'s performance in real-time.',
    isPrimary: false,
    onButtonClick: ()=>{},
  },
  projects: {
    icon: iconProjects,
    name: 'Untitled Project',
    caption: 'Manage your turn.',
    isPrimary: true,
    onButtonClick: (projectid, updateContext, requestContextChange) => {
      updateContext(projectid, null)
      requestContextChange()
    },
  },
}
