export const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '1px 8px',
    color: '#8e8e8e !important',
    fontSize: '13px !important'
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: '35px',
    maxHeight: 'fit-content',
    overflowY: 'scroll',
    //maxHeight: '35px',
    cursor: 'pointer',
    //height: '35px',
    border: '1px solid #e5e9f2',
    borderRadius: 3,
    boxShadow: 'none',
    ...(state.isDisabled && {
      pointerEvents: 'auto',
      cursor: 'not-allowed'
    }),
    '&:hover': {
      border: '1px solid #b5b5b5'
    },
    backgroundColor: state.isDisabled ? 'whitesmoke' : styles.backgroundColor,
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    ' &::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }),
  container: styles => ({
    ...styles,
    marginTop: '-3px'
  }),
  valueContainer: styles => ({
    ...styles,
    //minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  menuList: styles => ({
    ...styles,
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    ' &::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '4px',
    marginBottom: '0px',
    height: '20px',
    backgroundColor: 'transparent !important',
    borderRadius: '3px !important',
    fontSize: '14px',
    paddingLeft: '5px',
    paddingTop: '1px'
  }),
  multiValue: styles => ({
    ...styles,
    height: '20px',
    backgroundColor: 'transparent !important',
    borderRadius: '3px !important'
  }),
  multiValueLabel: styles => ({
    ...styles,
    paddingBottom: '8px'
  }),
  indicatorContainer: styles => ({
    ...styles,
    paddingTop: '4px'
  })
}
