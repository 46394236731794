import _ from 'lodash'
import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import TotalCost from './total-cost'
import PropertiesCompleted from './properties-completed'
import OverallHealth from './overall-health'
import {
  getDashboardHeader,
  getHealthData
} from '../../modules/dashboard-header'
import {
  openTooltip,
  closeTooltip,
  setTooltipContent
} from '../../modules/tooltip'
import { updateContext } from '../../modules/context'

const DashboardHeader = ({
  user,
  context,
  header,
  getDashboardHeader,
  getHealthData,
  openTooltip,
  closeTooltip,
  setTooltipContent,
  updateContext,
  onSelectionChanged,
  limitProperties
}) => {
  const totalCost = useRef(null)
  const propertiesCompleted = useRef(null)
  const healthMap = useRef(null)

  const [minHeight, setMinHeight] = useState(0)

  const isRequesting = header.isRequesting || header.health.isRequesting

  useEffect(() => {
    const { pdbid, properties } = user.user_metadata
    const headerProperties = limitProperties
      ? _.map(properties, property => property.id).join(',')
      : []
    getDashboardHeader(
      pdbid,
      context.project.id || context.project,
      headerProperties
    )
  }, [user, context, getDashboardHeader, limitProperties])

  useEffect(() => {
    const { pdbid, properties } = user.user_metadata
    const headerProperties = limitProperties
      ? _.map(properties, property => property.id).join(',')
      : []
    getHealthData(
      pdbid,
      context.project.id || context.project,
      headerProperties
    )
  }, [user, context, getHealthData, limitProperties])

  useEffect(() => {
    if (
      !isRequesting &&
      totalCost.current &&
      propertiesCompleted.current &&
      healthMap.current
    ) {
      setMinHeight(
        _.max([
          totalCost.current.clientHeight,
          propertiesCompleted.current.clientHeight,
          healthMap.current.clientHeight
        ])
      )
    }
  }, [setMinHeight, totalCost, propertiesCompleted, healthMap, isRequesting])

  const onPropertySelected = property => {
    updateContext(context.project, property)
    if (onSelectionChanged) {
      onSelectionChanged(property)
    }
  }

  if (header.isRequesting) {
    return (
      <div className="columns">
        <div className="column is-full">
          <div
            className="is-size-4 no-wrap"
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '120px',
              paddingBottom: '120px'
            }}>
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="columns">
      <div className="column">
        <TotalCost
          totalCost={header.totalCost}
          ref={totalCost}
          minHeight={minHeight}
        />
      </div>
      <div className="column" style={{ minHeight }}>
        <PropertiesCompleted
          completed={header.completedProperties}
          total={header.totalProperties}
          ref={propertiesCompleted}
          minHeight={minHeight}
        />
      </div>
      <div className="column" style={{ minHeight }}>
        <OverallHealth
          data={_.get(header, 'health.data')}
          healthMap={_.get(header, 'health.summary')}
          ref={healthMap}
          minHeight={minHeight}
          openTooltip={openTooltip}
          closeTooltip={closeTooltip}
          setTooltipContent={setTooltipContent}
          onPropertySelected={onPropertySelected}
        />
      </div>
    </div>
  )
}

DashboardHeader.propTypes = {
  user: PropTypes.object,
  header: PropTypes.object,
  onSelectionChanged: PropTypes.func,
  getDashboardHeader: PropTypes.func.isRequired,
  getHealthData: PropTypes.func.isRequired,
  openTooltip: PropTypes.func.isRequired,
  closeTooltip: PropTypes.func.isRequired,
  setTooltipContent: PropTypes.func.isRequired,
  updateContext: PropTypes.func.isRequired
}

const mapStateToProps = ({ user, context, dashboard }) => ({
  user,
  context,
  header: dashboard.header
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDashboardHeader,
      getHealthData,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      updateContext
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader)
