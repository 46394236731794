import Service from '../service'
import LOGOUT from './auth'
import _ from 'lodash'

/**
 * Request For Proposal data module
 * @redux
 * @reduxActionScope requests-for-proposal
 * @module requests-for-proposal
 */

/**
 * Request for proposal
 * @type {Redux.ActionType}
 */
export const REQUEST_FOR_PROPOSAL =
  'requests-for-proposal/REQUEST_FOR_PROPOSAL'
/**
 * Request for proposal failed
 * @type {Redux.ActionType}
 */
export const REQUEST_FOR_PROPOSAL_FAIL = 'requests-for-proposal/REQUEST_FOR_PROPOSAL_FAIL'
/**
 * Update requested for proposal with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_FOR_PROPOSAL_SUCCESS =
  'requests-for-proposal/REQUEST_FOR_PROPOSAL_SUCCESS'

const initialState = {
  allRFP: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
}

/**
 * Requests For Proposal Reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FOR_PROPOSAL:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_FOR_PROPOSAL_SUCCESS:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          isRequesting: false,
          isError: false,
          data: action.data,
        }
      }
    case REQUEST_FOR_PROPOSAL_FAIL:
      return {
        ...state,
        allRFP: {
          ...state.allRFP,
          isRequesting: false,
          isError: true,
          data: action.data,
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const vendorDetailsByEmailGet = (pdbid, context, email,) => {
  return dispatch => {
    Service.vendorDetailsByEmailGet(pdbid, context, email)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          const { vender_id } = data || {}
          if(vender_id) {
            dispatch(requestsForProposalGet(pdbid, context, vender_id))
          } else {
            dispatch({
              type: REQUEST_FOR_PROPOSAL_FAIL,
              data: []
            })
          }
        } else {
          dispatch({
            type: REQUEST_FOR_PROPOSAL_FAIL,
            data: []
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_FOR_PROPOSAL_FAIL,
          data: []
        })
      })
  }
}


export const requestsForProposalGet = (pdbid, context, vendorId) => {
  return dispatch => {
    dispatch({
      type: REQUEST_FOR_PROPOSAL
    })
    Service.requestsForProposalGet(pdbid, context, vendorId)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_FOR_PROPOSAL_SUCCESS,
            data: _allRequestRFPAdapter(data)
          })
        } else {
          dispatch({
            type: REQUEST_FOR_PROPOSAL_FAIL,
            data: []
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_FOR_PROPOSAL_FAIL,
          data: []
        })
      })
  }
}

const NS = ''
const _requestRFPAdapter = data => {
  return {
    rfp_request_id: typeof data.rfp_request_id === 'string' ? data.rfp_request_id : NS,
    vendor_name: typeof data.vendor_name === 'string' ? data.vendor_name : NS,
    form_name: typeof data.form_name === 'string' ? data.form_name : NS,
    property: typeof data.property === 'string' ? data.property : NS,
    due_date: typeof data.due_date === 'string' ? data.due_date : NS,
    status: typeof data.status === 'string' ? data.status.toLowerCase() : 'not-started'
    
  }
}

const _allRequestRFPAdapter = data => {
  const arrayUniqueByKey = [...new Map((data || []).map(item =>
    [item['rfp_request_id'], item])).values()]
  return arrayUniqueByKey.map(d => _requestRFPAdapter(d))
}

