import React, {memo} from 'react'
import DataTableComponent from '../../components/data-table-component'

const Table = ({columns, memorizedTableData, defaultStyles, dataLoading}) => {
    return <DataTableComponent
        columns={columns}
        hideHeaderSection={true}
        showPagination={false}
        showSearchBar={false}
        data={(memorizedTableData || [])}
        isDense={true}
        columnStyles={defaultStyles}
        fixedHeader={true}
    />
}

export default  memo(Table)
