import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { CRISP_WEBSITE_ID } from '../../service/config'

const CrispChat = () => {
  useEffect(() => {
    window.$crisp = []
    window.CRISP_WEBSITE_ID = CRISP_WEBSITE_ID
    window.$crisp.push(['do', 'chat:hide'])
    ;(function() {
      const d = document
      const s = d.createElement('script')
      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    })()
  }, [CRISP_WEBSITE_ID])
  return (
    <Helmet>
      <script src="https://client.crisp.chat/l.js" async />
    </Helmet>
  )
}

export default CrispChat
