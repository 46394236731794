import React, { useState } from 'react'
import hidePasswordIcon from './icons/hide-password.svg'
import showPasswordIcon from './icons/show-password.svg'

const PasswordField = ({
  id,
  name,
  label,
  placeholder,
  value,
  disabled = false,
  error,
  onChange,
  inputClassName = 'form-control',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const type = !showPassword ? 'password' : 'text'
  const icon = !showPassword ? hidePasswordIcon : showPasswordIcon

  const handleShowPassword = () => setShowPassword(!showPassword)

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
        className={inputClassName}></input>
      <img
        src={icon}
        alt="toggle-password"
        className="custom-icon"
        onClick={handleShowPassword}
        style={{
          height: 20,
          position: 'absolute',
          top: 8,
          right: 15,
          cursor: 'pointer'
        }}
      />
    </div>
  )
}

export default PasswordField
