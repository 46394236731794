import React, { useState, memo, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { icons } from './icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'

import './index.scss'

const TYPES = [
  'General',
  'Vendor General',
  'Vendor Service',
  'Furniture',
  'Additional Charge'
]

const TurnboardManageNote = props => {
  const {
    onClose,
    noteType = '',
    noteCharge = '0',
    noteUnit = '',
    unitNotesCreate,
    noteMessage = '',
    user = {},
    context = {},
    isNoteEdit = false,
    unitNotesUpdate,
    note_id = '',
    unitIds,
    turnboard,
    updateTurnboardDataByState,
    isNew,
    setupVendors,
    servicetype_id = '',
    vendor_id = ''
  } = props || {}
  // Icons
  const { close, closeFilled } = icons || {}
  // Redux
  const { data: turnBoardData, activePage } = turnboard || {}
  const { units, services } = turnBoardData || {}
  const { allVendors } = setupVendors || {}
  const { data: allVendorData } = allVendors || {}

  const [type, setType] = useState(noteType)
  const [selectedUnit, setSelectedUnit] = useState(noteUnit)
  const [charge, setCharge] = useState(noteCharge)
  const [message, setMessage] = useState(noteMessage)
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedVendorId, setSelectedVendorId] = useState(vendor_id)
  const [vendorServices, setVendorServices] = useState([])
  const [selectedVendorServiceId, setSelectedVendorServiceId] = useState(
    servicetype_id
  )

  useEffect(() => {
    if (vendor_id !== '') {
      const getServices = (allVendorData || []).filter(
        d => d?.vendor_id === vendor_id
      )
      if ((getServices || []).length) {
        setVendorServices(getServices[0]?.Services)
      }
    }
  }, [vendor_id])

  const onSuccess = () => {
    setErrorMessage('')
    if (!isNoteEdit) {
      const updateUnits = (units || []).map(unitData => {
        const { unit_id } = unitData || {}
        if (selectedUnit?.unit_id === unit_id) {
          return {
            ...unitData,
            has_note: true
          }
        }
        return {
          ...unitData
        }
      })
      updateTurnboardDataByState({
        data: {
          page: activePage,
          units: updateUnits || []
        }
      })
    }
    setIsSaving(false)
    //setRemoveAllActiveFilter(true)
    onClose()
  }
  const onError = () => {
    setIsSaving(false)
    setErrorMessage('Something went wrong, Please try again !!!')
  }

  const onSave = async () => {
    setIsSaving(true)
    const { name, user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    let body = {
      note: message,
      type,
      creation_date: moment().format('DD-MMM-YY'),
      created_by: name || '',
      charge,
      servicetype: '',
      servicetype_id: '',
      vendor_id: '',
      updated_amount: 0,
      do_not_invoice: null
    }
    if (type === TYPES[4]) {
      body = {
        ...body,
        servicetype_id: selectedVendorServiceId || '',
        do_not_invoice: null,
        vendor_id: selectedVendorId || '',
        updated_amount: 0
      }
    }
    if (type === TYPES[2]) {
      body = {
        ...body,
        servicetype_id: selectedVendorServiceId || ''
      }
    }
    const unitId = selectedUnit?.unit_id || ''
    if (isNoteEdit) {
      return await unitNotesUpdate(
        pdbid,
        context,
        unitId,
        { ...body, note_id: note_id },
        onSuccess,
        onError
      )
    }
    return unitNotesCreate(pdbid, context, unitId, body, onSuccess, onError)
  }

  const getUnitData = useMemo(() => {
    return (unitIds || []).map(uData => {
      const { unit_id, unit } = uData || {}
      return {
        unit,
        unit_id
      }
    })
  }, [unitIds])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  const onHandleCharge = ({ target }) => {
    const { value } = target || {}
    let filterValue = value || ''
    // Only allow Numbers and .
    filterValue = (value || '').replace(/[^0-9\.]/g, '')
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Check if . already exists
    if ((filterValue || '').toString().split('.').length > 2) {
      filterValue = (filterValue || '').replace(/\.+$/, '')
    }
    // Dont allow more than 2 decimal points
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setCharge(filterValue)
  }

  return (
    <div className="turnboard-manage-note-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full custom-column">
          <h2 className="is-pulled-left modal-sync-title">
            {isNoteEdit ? 'Edit Note' : 'Add Note'}
          </h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        {errorMessage !== '' && (
          <div className="notification is-danger is-light is-flex">
            <p>{errorMessage}</p>
            <button onClick={() => setErrorMessage('')}>
              <img src={closeFilled} alt="" />
            </button>
          </div>
        )}
        <div style={{ display: 'flex' }}>
          <div className="m-b-8" style={{ width: '50%' }}>
            <label htmlFor="select-unit" className="input-label custom-label">
              Unit
            </label>
            <MultiSelectDropdown
              displayKey="unit"
              value={selectedUnit}
              defaultValues={getUnitData}
              isDisabled={isNoteEdit || isNew}
              onChange={v => setSelectedUnit(v)}
              isMulti={false}
              placeholder="Select"
              noOptionsMessage="No Unit Available"
              dropdownIcon={true}
              customStyles={{
                valueContainer: provided => ({
                  ...provided,
                  minHeight: '35px',
                  maxHeight: '35px',
                  overflow: 'auto',
                  position: 'relative',
                  borderRadius: '3px'
                }),
                control: (base, state) => ({
                  ...base,
                  border: '1px solid #e5e9f2',
                  boxShadow: 'none',
                  '&:hover': {
                    border: '1px solid #b5b5b5'
                  },
                  backgroundColor: state.isDisabled
                    ? 'ghostwhite'
                    : base.backgroundColor,
                  cursor: isNoteEdit || isNew ? 'not-allowed' : 'default'
                }),
                container: styles => ({
                  ...styles,
                  pointerEvents: 'all',
                  width: '182px'
                }),
                clearIndicator: provided => {
                  return {
                    ...provided,
                    padding: '3px'
                  }
                },
                dropdownIndicator: base => ({
                  ...base,
                  color: '#122048',
                  cursor: isNoteEdit || isNew ? 'not-allowed' : 'pointer',
                  '&:hover': {
                    color: '#122048'
                  }
                }),
                menuList: base => ({
                  ...base,
                  maxHeight: '250px'
                })
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className="m-b-md" style={{ width: '50%' }}>
            <label htmlFor="note-category" className="input-label custom-label">
              Note Category
            </label>
            <div className="select">
              <select
                value={type}
                id="note-category"
                onChange={e => {
                  setType(e.target.value)
                  setErrorMessage('')
                }}>
                <option value="">Select Note Category</option>
                {_.map(TYPES || [], t => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {type === TYPES[2] && (
            <div className="m-b-md" style={{ width: '50%' }}>
              <label
                htmlFor="service-category"
                className="input-label custom-label">
                Service
              </label>
              <div className="select service-select">
                <select
                  value={selectedVendorServiceId}
                  disabled={type !== TYPES[2]}
                  id="service-category"
                  onChange={e => {
                    setSelectedVendorServiceId(e.target.value)
                    setErrorMessage('')
                  }}>
                  <option value="">Select</option>
                  {(services || []).map((service, i) => {
                    const { servicetype_id, servicetype } = service || {}
                    return (
                      <option value={servicetype_id} key={i}>
                        {servicetype || ''}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          )}
          {type === TYPES[4] && (
            <div className="m-b-md m-l-sm" style={{ width: '50%' }}>
              <label
                htmlFor="note-category"
                className="input-label custom-label">
                Charge
              </label>
              <div>
                <input
                  className="input"
                  type="text"
                  value={charge}
                  name="charge"
                  onChange={e => {
                    onHandleCharge(e)
                    setErrorMessage('')
                  }}></input>
              </div>
            </div>
          )}
        </div>
        {type === TYPES[4] && (
          <div style={{ display: 'flex' }}>
            <div className="m-b-md" style={{ width: '50%' }}>
              <label
                htmlFor="vendor-category"
                className="input-label custom-label">
                Vendor
              </label>
              <div className="select">
                <select
                  value={selectedVendorId}
                  disabled={
                    type !== TYPES[4] || (allVendorData || []).length === 0
                  }
                  id="vendor-category"
                  onChange={e => {
                    setVendorServices([])
                    setSelectedVendorServiceId('')
                    setSelectedVendorId(e.target.value)
                    setErrorMessage('')
                    const getServices = (allVendorData || []).filter(
                      d => d?.vendor_id === e.target.value
                    )
                    if ((getServices || []).length) {
                      setVendorServices(getServices[0]?.Services)
                    }
                  }}>
                  <option value="">Select</option>
                  {(allVendorData || []).map((vendor, i) => {
                    const { name, vendor_id } = vendor || {}
                    return (
                      <option value={vendor_id} key={i}>
                        {name || ''}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="m-b-md" style={{ width: '50%' }}>
              <label
                htmlFor="service-category"
                className="input-label custom-label">
                Service
              </label>
              <div className="select service-select">
                <select
                  value={selectedVendorServiceId}
                  disabled={!selectedVendorId || type !== TYPES[4]}
                  id="service-category"
                  onChange={e => {
                    setSelectedVendorServiceId(e.target.value)
                    setErrorMessage('')
                  }}>
                  <option value="">Select</option>
                  {(vendorServices || []).map((service, i) => {
                    const { servicetype_id, service_name } = service || {}
                    return (
                      <option value={servicetype_id} key={i}>
                        {service_name || ''}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        )}
        {(allVendorData || []).length === 0 && (
          <span className="general-error">
            There are no vendors in this turn. You can add vendors from Turn
            Setup.
          </span>
        )}
        {(vendorServices || []).length === 0 && selectedVendorId && (
          <span className="general-error">
            There are no services associated with this vendor.
          </span>
        )}
        <label htmlFor="note-edit" className="input-label">
          Note Message
        </label>
        <textarea
          className="textarea m-t-sm"
          rows={4}
          maxLength={3000}
          id="note-edit"
          defaultValue={message}
          onChange={e => {
            setMessage(e.target.value)
            setErrorMessage('')
          }}
        />
        <p className="character-limit m-b-40">
          Character Limit: <span>{(message || []).length}</span>
          /3000
        </p>
        <div className="columns is-vcentered m-b-sm m-t-sm">
          <div className="center-container">
            <button
              disabled={isSaving}
              className="button main-button is-secondary m-r-md"
              onClick={() => onClose()}>
              Cancel
            </button>
            <button
              className="button main-button is-primary"
              disabled={
                (selectedUnit || '')?.toString()?.trim() === '' ||
                type === '' ||
                (message || '')?.toString()?.trim() === '' ||
                (type?.toLowerCase() === 'vendor service' &&
                  selectedVendorServiceId === '') ||
                (type?.toLowerCase() === 'additional charge' &&
                  (!charge ||
                    charge === '0' ||
                    selectedVendorId === '' ||
                    selectedVendorServiceId === '' ||
                    parseFloat(charge || 0) === 0))
              }
              onClick={() => onSave()}>
              {isSaving ? 'Saving' : 'Save'}
              {isSaving && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  color="#ffffff"
                  className="m-l-sm"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

TurnboardManageNote.propTypes = {
  onClose: PropTypes.func,
  unitNotesCreate: PropTypes.func,
  unitNotesUpdate: PropTypes.func,
  updateTurnboardDataByState: PropTypes.func,
  noteType: PropTypes.string,
  noteCharge: PropTypes.string,
  noteUnit: PropTypes.string,
  noteMessage: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  isNoteEdit: PropTypes.bool,
  isNew: PropTypes.bool,
  note_id: PropTypes.string,
  unitIds: PropTypes.array,
  turnboard: PropTypes.object,
  servicetype_id: PropTypes.string,
  vendor_id: PropTypes.string
}

export default memo(TurnboardManageNote)
