import React from 'react'

const InvoiceCell = ({ row }) => {
  const value = row?.cell?.value

  return (
    <div className="cell-item" title={value}>
      <span>{value}</span>
    </div>
  )
}

export default InvoiceCell
