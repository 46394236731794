import React, { useState, useEffect } from 'react'
import AppliedFilters from '../../components/data-table/applied-filters'
import { PRINT_OPTIONS } from './index'

const PrintReportModal = ({
  open,
  activeFilters,
  customFilters,
  fieldFilters,
  getTagCaption,
  onClose,
  onReport
}) => {
  const [specifiedUnits, setSpecifiedUnits] = useState('')
  const [currentOption, setCurrentOption] = useState(
    Object.keys(activeFilters).length > 0
      ? PRINT_OPTIONS.FILTERS
      : PRINT_OPTIONS.ALL
  )
  const [includeActivityLog, setIncludeActivityLog] = useState(false)

  useEffect(() => {
    setCurrentOption(
      Object.keys(activeFilters).length > 0
        ? PRINT_OPTIONS.FILTERS
        : PRINT_OPTIONS.ALL
    )
  }, [activeFilters])

  return (
    <div className={`modal ${open ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-card" style={{ width: '430px' }}>
        <section className="modal-card-body">
          <div className="columns is-desktop is-mobile">
            <div className="column">
              <p className="heading-4 is-pulled-left">
                Print Inspection Report
              </p>
              <p
                className="is-pulled-right is-pointer is-unselectable has-text-grey-light"
                onClick={() => {
                  onClose()
                }}>
                X
              </p>
            </div>
          </div>
          <div className="columns m-b-md">
            <div className="column is-full p-b-none">
              <p>What would you like to print?</p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-narrow p-l-lg p-b-none p-t-xs">
              <input
                type="checkbox"
                disabled={Object.keys(activeFilters).length === 0}
                checked={currentOption === PRINT_OPTIONS.FILTERS}
                onChange={() => setCurrentOption(PRINT_OPTIONS.FILTERS)}
              />
            </div>
            <div className="column is-narrow p-b-none p-t-xs">
              <p className="p-b-sm">Only units with applied filter(s):</p>
              <AppliedFilters
                ignoreLabel
                alignLeft
                activeFilters={activeFilters}
                customFilters={customFilters}
                definitions={fieldFilters}
                getTagCaption={getTagCaption}
                isInner
              />
            </div>
          </div>
          <div className="columns is-vcentered" style={{ height: '35px' }}>
            <div className="column is-narrow p-l-lg p-b-none p-t-none">
              <input
                type="checkbox"
                checked={currentOption === PRINT_OPTIONS.SPECIFIED}
                onChange={() => setCurrentOption(PRINT_OPTIONS.SPECIFIED)}
              />
            </div>
            <div className="column is-narrow p-b-none p-t-none">
              Specific unit(s):
            </div>
            <div className="column is-narrow p-b-none p-t-none">
              <input
                type="text"
                className="input"
                disabled={currentOption !== PRINT_OPTIONS.SPECIFIED}
                onChange={e => setSpecifiedUnits(e.target.value)}
                placeholder="Separate with commas"
              />
            </div>
          </div>
          <div className="columns is-vcentered" style={{ height: '35px' }}>
            <div className="column is-narrow p-l-lg p-b-none p-t-none">
              <input
                type="checkbox"
                checked={currentOption === PRINT_OPTIONS.ALL}
                onChange={() => setCurrentOption(PRINT_OPTIONS.ALL)}
              />
            </div>
            <div className="column is-narrow p-b-none p-t-none">All units</div>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-narrow p-l-lg p-b-none m-t-sm">
              <div className="field is-horizontal">
                <input
                  id="includeActivityLog"
                  type="checkbox"
                  className="switch is-rounded"
                  value={includeActivityLog}
                  onChange={e => setIncludeActivityLog(e.target.checked)}
                />
                <label htmlFor="includeActivityLog">
                  Include all Activity Log information
                </label>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-full m-t-md">
              <div className="is-italic center-container has-text-centered">
                Note: This will take several minutes and will be saved in your
                default downloads folder.
              </div>
            </div>
          </div>
          <div className="center-container">
            <button
              className="button main-button m-r-md is-secondary"
              onClick={onClose}>
              Cancel
            </button>
            <button
              className="button main-button is-primary"
              onClick={() => {
                onReport(currentOption, specifiedUnits, includeActivityLog)
                onClose()
              }}>
              Filter
            </button>
          </div>
        </section>
      </div>
    </div>
  )
}

export default PrintReportModal
