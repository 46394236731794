import React from 'react'

const splitTypeOptions = [
  {
    label: 'All Residents',
    value: 1
  },
  {
    label: 'Non-renewed Residents',
    value: 2
  },
  {
    label: 'Custom',
    value: '3'
  }
]

const nonSplitTypeOptions = [
  {
    label: 'Select',
    value: null
  },
  ...splitTypeOptions
]

const CostDropdownField = ({ row, hasPermission }) => {
  const { cell, onDropdownItemChange } = row || {}
  const {
    value,
    row: { index, original }
  } = cell || {}
  const { is_ca, inspection_status } = original || {}

  return (
    <div
      className="damage-select damage-field-select"
      style={{
        marginTop: '7px'
      }}>
      <select
        onChange={event => {
          if (hasPermission) {
            onDropdownItemChange(event.target.value, index)
          }
        }}
        placeholder="Select"
        value={value}
        disabled={
          !hasPermission || !is_ca || inspection_status === 'Damages Approved'
        }>
        {(is_ca ? splitTypeOptions || [] : nonSplitTypeOptions || [])?.map(
          item => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          )
        )}
      </select>
    </div>
  )
}

export default CostDropdownField
