import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import withScrolling from 'react-dnd-scrolling'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faChevronUp,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import { move, doubleArrow } from 'ui/icons'
import { useAppContext, useAppUser } from 'hooks'
import {
  newTemplate,
  templateConfigSubmenu,
  sortAndMapTemplateElements
} from './templageConfig'
import {
  getCompanyTemplateDetails,
  resetCompanyTemplateDetails,
  getTemplateElements,
  resetTemplateElements,
  createOrUpdateCompanyTemplate
} from '../../modules/company-templates'
import EditField from '../../components/edit-field'
import Draggable from '../../components/draggable-form-template'
import Droppable from '../../components/droppable-form-template'
import TemplateElements from './template-elements'
import TemplateDetails from './template-details'
import { closeFilled, warning } from 'ui/icons'
import './index.scss'
import usePageReload from '../../hooks/usePageReload'
import { isEmpty, isEqual, omit } from 'lodash'
import moment from 'moment'
import { usePermissionGate } from '../../helpers/hooks'
import { RouterPrompt } from '../../components/common/Prompt/RouterPrompt'
import BigLoading from '../../components/big-loading'
import { uuid } from 'short-uuid'

const ScrollingComponent = withScrolling('div')

const removeCollapsedProperty = template => {
  const templateClone = cloneDeep(template)

  templateClone.spaces = (templateClone.spaces || []).map(space => {
    delete space.collapsed
    return {
      ...space,
      items: (space?.items || []).map(item => {
        delete item.collapsed
        return item
      })
    }
  })

  return templateClone
}

const filterSpace = space => space.do_delete_space === 'false'

const filterItem = item => item.do_delete_item === 'false'

const filterObservation = observation =>
  observation.do_delete_observation === 'false'

const filterSolution = solution => solution.do_delete_solution === 'false'

const CompanyTemplateManage = ({ history, templateId }) => {
  const dispatch = useDispatch()

  const {
    user: { pdbid },
    context
  } = useAppContext()

  const { user } = useAppUser()
  const { hasPermission } = usePermissionGate('create-or-modify-template')
  const {
    elementsIsRequesting,
    elementsIsError,
    elements,
    detailsIsRequesting,
    detailsIsError,
    detailsHasRequested,
    details,
    deleteIsError,
    duplicateIsError,
    createOrUpdateTemplateError,
    createOrUpdateTemplateRequested,
    list
  } = useSelector(state => state.companyTemplates)
  const [error, setError] = useState(false)
  const [
    templateConfigSubmenuActiveIndex,
    setTemplateConfigSubmenuActiveIndex
  ] = useState(0)
  const [elementsCustomData, setElementsCustomData] = useState({})
  const [templateCustomData, setTemplateCustomData] = useState(newTemplate)
  const templateEditMode = !!templateId
  const [loading, setLoading] = useState(templateEditMode)
  const [activeSpace, setActiveSpace] = useState(null)
  const [templateErrors, setTemplateErrors] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSectionOpened, setIsSectionOpened] = useState(false)

  const templateRef = useRef(null)

  const canEditTemplate = useMemo(() => {
    return templateCustomData?.shared?.length <= 0 && hasPermission
  }, [templateCustomData, hasPermission])

  const originalTemplate = useMemo(() => {
    return removeCollapsedProperty(details)
  }, [details])

  const templateToCompare = useMemo(() => {
    return removeCollapsedProperty(templateCustomData)
  }, [templateCustomData])

  const hasUnsavedChanges = useMemo(() => {
    if (isSubmitted) return false
    return !isEqual(originalTemplate, templateToCompare) && hasPermission
  }, [originalTemplate, templateToCompare, isSubmitted, hasPermission])

  const templateToRender = useMemo(() => {
    const template = cloneDeep(templateCustomData)

    template.spaces = (template.spaces || [])
      .filter(space => filterSpace(space))
      .map(space => ({
        ...space,
        items: (space.items || [])
          .filter(item => filterItem(item))
          .map(item => ({
            ...item,
            observations: (item.observations || [])
              .filter(observation => filterObservation(observation))
              .map(observation => ({
                ...observation,
                solutions: (observation.solutions || []).filter(solution =>
                  filterSolution(solution)
                )
              }))
          }))
      }))

    return template
  }, [templateCustomData])

  const spacesLeft = useMemo(() => {
    return (elementsCustomData.spaces || []).filter(
      space => space.status !== 'inactive'
    )
  }, [elementsCustomData.spaces])

  const isTemplateComplete = useMemo(() => {
    const { spaces } = originalTemplate

    if (!spaces?.length) return false

    for (let space of spaces || []) {
      if (!space?.items?.length) return false

      for (let item of space.items || []) {
        if (!item?.observations?.length) return false

        for (let observation of item.observations || []) {
          if (!observation?.solutions?.length) return false
        }
      }
    }

    return true
  }, [originalTemplate])

  const checkIfTemplateComplete = useMemo(() => {
    const spaces = templateToRender.spaces.filter(space => space.space_id)

    if (!spaces?.length) return false

    for (let space of spaces || []) {
      if (!space?.items?.length) return false

      for (let item of space.items || []) {
        if (!item?.observations?.length) return false

        for (let observation of item.observations || []) {
          if (!observation?.solutions?.length) return false
        }
      }
    }

    return true
  }, [templateToRender])

  usePageReload(hasUnsavedChanges)

  const validateTemplate = useCallback(() => {
    const errors = {}

    const isNameExist = (list || [])
      .filter(template => template.name !== originalTemplate.name)
      .some(template => template.name === templateCustomData.name.trim())

    if (!templateCustomData.name?.trim()) {
      errors.name = 'Please enter template name.'
    }

    if (isNameExist) {
      errors.name = 'Template with this name already exist.'
    }

    if (!templateCustomData.type?.trim()) {
      errors.type = 'Please enter template type.'
    }

    if (templateCustomData.type.length > 50) {
      errors.type = 'Template type should be 50 characters long.'
    }

    return errors
  }, [templateCustomData.name, templateCustomData.type])

  const disableSaveButton = useMemo(() => {
    const errors = validateTemplate()

    return (
      !isEmpty(errors) ||
      !hasPermission ||
      isEqual(originalTemplate, templateToCompare)
    )
  }, [validateTemplate, canEditTemplate, originalTemplate, templateToCompare])

  // toggle collapsible cards
  const toggleCollapsible = useCallback(spaceIndex => {
    setTemplateCustomData(prevData => {
      const newData = { ...prevData }
      newData['spaces'] = newData['spaces'].map((space, index) => ({
        ...space,
        collapsed:
          spaceIndex === space.space_index
            ? !space['collapsed']
            : space.collapsed
      }))

      return newData
    })
  }, [])

  // Template name handler
  const setTemplateName = useCallback(
    name => {
      setTemplateCustomData(prevData => {
        const newData = cloneDeep(prevData)
        newData['name'] = name
        return newData
      })
    },
    [EditField, validateTemplate]
  )

  const setTemplateType = useCallback(
    type => {
      setTemplateCustomData(prevData => {
        const newData = cloneDeep(prevData)
        newData['type'] = type
        return newData
      })
    },
    [EditField, validateTemplate]
  )

  const getFinalTemplateData = useCallback(() => {
    const templateData = omit(cloneDeep(templateCustomData), [
      'template_name',
      'template_type',
      'shared',
      'usedSpaces'
    ])

    templateData.template_id = !templateData.template_id
      ? null
      : templateData.template_id
    templateData.name = templateData.name.trim()
    templateData.type = templateData.type.trim()
    templateData.created_at = !templateData.template_id
      ? moment(new Date()).format('DD-MMM-YY')
      : moment(templateData.created_at).format('DD-MMM-YY')
    templateData.updated_at = templateData.template_id
      ? moment(new Date()).format('DD-MMM-YY')
      : null
    templateData.created_by = !templateData.created_by
      ? user.user_id
      : templateData.created_by

    templateData.spaces = templateData?.spaces?.length
      ? templateData.spaces.map((space, spaceIndex) => ({
          space_id: space.space_id,
          sorting_order: `${spaceIndex + 1}`,
          template_space_id: !templateData.template_id
            ? null
            : space.template_space_id,
          do_delete_space: space.do_delete_space,
          required_on_property_forms: space.required_on_property_forms,
          items: space?.items?.length
            ? space.items.map((item, itemIndex) => ({
                item_id: item.item_id,
                sorting_order_item: `${itemIndex + 1}`,
                template_item_id: !templateData.template_id
                  ? null
                  : item.template_item_id,
                do_delete_item: item.do_delete_item,
                observations: item?.observations?.length
                  ? item.observations.map((observation, observationIndex) => ({
                      observation_id: observation.observation_id,
                      sorting_order_observation: `${observationIndex + 1}`,
                      template_observation_id: !templateData.template_id
                        ? null
                        : observation.template_observation_id,
                      do_delete_observation: observation.do_delete_observation,
                      solution_type: observation.solution_type,
                      media_required: observation.media_required,
                      media_minimum: observation.media_minimum || '1',
                      solutions: observation?.solutions?.length
                        ? observation.solutions.map(
                            (solution, solutionIndex) => ({
                              solution_id: solution.solution_id,
                              sorting_order_solution: `${solutionIndex + 1}`,
                              template_solution_id: !templateData.template_id
                                ? null
                                : solution.template_solution_id,
                              do_delete_solution: solution.do_delete_solution
                            })
                          )
                        : [{ solution_id: null }]
                    }))
                  : [{ observation_id: null }]
              }))
            : [{ item_id: null }]
        }))
      : [{ space_id: null }]
    templateData.state = checkIfTemplateComplete ? null : 'incomplete'
    return templateData
  }, [templateCustomData])

  const saveTemplateHandler = useCallback(() => {
    if (!disableSaveButton) {
      const templateData = getFinalTemplateData()

      setIsSubmitted(true)

      const template_id = !templateData.template_id
        ? 0
        : templateData.template_id

      dispatch(
        createOrUpdateCompanyTemplate(
          pdbid,
          context,
          templateData,
          template_id,
          template => {
            if (templateId) {
              dispatch(getCompanyTemplateDetails(pdbid, context, templateId))
            } else {
              history.replace(
                `/inspections-setup/company-templates/manage?template=${template.template_id}`
              )
            }
            setIsSubmitted(false)
          },
          () => setIsSubmitted(false)
        )
      )
    }
  }, [templateCustomData])

  const elementsListUpdate = (elementType, elementId) => {
    setElementsCustomData(prevData => {
      const indexToRemove = prevData[elementType].findIndex(
        element =>
          element[`${elementType.substring(0, elementType.length - 1)}_id`] ===
          elementId
      )
      const newElementData = [...prevData[elementType]]
      newElementData.splice(indexToRemove, 1)

      return {
        ...prevData,
        [elementType]: newElementData
      }
    })
  }

  const removeTemplateElement = element => {
    const {
      type,
      space_index,
      item_index,
      observation_index,
      solution_index,
      template_space_id,
      template_item_id,
      template_observation_id,
      template_solution_id
    } = element

    setElementsCustomData(prevData => {
      return {
        ...prevData,
        spaces:
          type === 'space' && element.status === 'active'
            ? sortAndMapTemplateElements(
                [...prevData['spaces'], element],
                'space'
              )
            : prevData['spaces']
      }
    })

    setTemplateCustomData(prevData => {
      let newData = cloneDeep(prevData)

      switch (type) {
        case 'space':
          if (template_space_id) {
            newData['spaces'][space_index]['do_delete_space'] = null
            newData['spaces'][space_index]['collapsed'] = true
            newData['spaces'][space_index]['items'] = (
              newData['spaces'][space_index]['items'] || []
            )
              .filter(item => item.template_item_id !== null)
              .map(item => ({
                ...item,
                do_delete_item: null,
                collapsed: true,
                observations: (item?.observations || [])
                  .filter(
                    observation => observation.template_observation_id !== null
                  )
                  .map(observation => ({
                    ...observation,
                    do_delete_observation: null,
                    solutions: (observation?.solutions || [])
                      .filter(
                        solution => solution.template_solution_id !== null
                      )
                      .map(solution => ({
                        ...solution,
                        do_delete_solution: null
                      }))
                  }))
              }))
          } else {
            newData['spaces'][space_index]['items'] = []
            newData['spaces'].splice(space_index, 1)
            newData['spaces'] = newData['spaces'].map((space, index) => ({
              ...space,
              space_index: index,
              items: space['items'].map(item => ({
                ...item,
                space_index: index,
                observations: item['observations'].map(observation => ({
                  ...observation,
                  space_index: index,
                  solutions: observation['solutions'].map(solution => ({
                    ...solution,
                    space_index: index
                  }))
                }))
              }))
            }))
          }

          if (activeSpace === space_index) {
            setActiveSpace(null)
            setIsSectionOpened(false)
          }
          return newData

        case 'item':
          if (template_item_id) {
            newData['spaces'][space_index]['items'][item_index][
              'do_delete_item'
            ] = null
            newData['spaces'][space_index]['items'][item_index][
              'collapsed'
            ] = true

            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = (
              newData['spaces'][space_index]['items'][item_index][
                'observations'
              ] || []
            )
              .filter(
                observation => observation.template_observation_id !== null
              )
              .map(observation => ({
                ...observation,
                do_delete_observation: null,
                solutions: (observation?.solutions || [])
                  .filter(solution => solution.template_solution_id !== null)
                  .map(solution => ({
                    ...solution,
                    do_delete_solution: null
                  }))
              }))
          } else {
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = []
            newData['spaces'][space_index]['items'].splice(item_index, 1)
            newData['spaces'][space_index]['items'] = newData['spaces'][
              space_index
            ]['items'].map((item, index) => ({
              ...item,
              item_index: index,
              observations: item['observations'].map(observation => ({
                ...observation,
                item_index: index,
                solutions: observation['solutions'].map(solution => ({
                  ...solution,
                  item_index: index
                }))
              }))
            }))
          }
          return newData

        case 'observation':
          if (template_observation_id) {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['do_delete_observation'] = null

            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = (
              newData['spaces'][space_index]['items'][item_index][
                'observations'
              ][observation_index]['solutions'] || []
            )
              .filter(solution => solution.template_solution_id !== null)
              .map(solution => ({
                ...solution,
                do_delete_solution: null
              }))
          } else {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = []
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ].splice(observation_index, 1)
            newData['spaces'][space_index]['items'][item_index][
              'observations'
            ] = newData['spaces'][space_index]['items'][item_index][
              'observations'
            ].map((observation, index) => ({
              ...observation,
              observation_index: index,
              solutions: observation['solutions'].map(solution => ({
                ...solution,
                observation_index: index
              }))
            }))
          }
          return newData

        case 'solution':
          if (template_solution_id) {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'][solution_index]['do_delete_solution'] = null
          } else {
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'].splice(solution_index, 1)
            newData['spaces'][space_index]['items'][item_index]['observations'][
              observation_index
            ]['solutions'] = newData['spaces'][space_index]['items'][
              item_index
            ]['observations'][observation_index]['solutions'].map(
              (item, index) => ({
                ...item,
                solution_index: index
              })
            )
          }
          return newData

        default:
          return newData
      }
    })
  }

  const addSpaceHandler = useCallback(() => {
    setTemplateCustomData(prevData => {
      const newData = cloneDeep(prevData)

      newData['spaces'] = newData['spaces'].map(space => ({
        ...space
      }))

      newData.spaces.push({
        space_name: '',
        space_index: newData.spaces.length,
        collapsed: false,
        required_on_property_forms: null,
        do_delete_space: 'false',
        space_uuid: uuid(),
        items: []
      })

      return newData
    })
  }, [])

  const spaceDropHandler = (dropData, space) => {
    const canDrop = !('space_index' in space) && !dropData.space_id

    if (canDrop) {
      setTemplateCustomData(prevData => {
        const newData = cloneDeep(prevData)

        newData.spaces[dropData['space_index']] = {
          ...dropData,
          ...space
        }

        return newData
      })

      elementsListUpdate('spaces', space.space_id)
    }
  }

  const itemDropHandler = (dropData, item) => {
    const parentSpace = dropData.parent_space

    const itemChangingParent =
      item.space_index !== undefined && parentSpace !== item.space_index

    const foundItems = templateCustomData.spaces[parentSpace]['items'].filter(
      element => element.item_id === item.item_id
    )

    if (
      templateCustomData.spaces[parentSpace]['items'].length === 0 ||
      !foundItems.length ||
      (foundItems.length === 1 && !foundItems[0].do_delete_item)
    ) {
      setTemplateCustomData(prevData => {
        const newData = { ...prevData }

        if (itemChangingParent) {
          if (item.template_item_id) {
            newData['spaces'][item.space_index]['items'][item.item_index][
              'do_delete_item'
            ] = null

            newData['spaces'][item.space_index]['items'][item.item_index][
              'observations'
            ] = newData['spaces'][item.space_index]['items'][item.item_index][
              'observations'
            ]
              .filter(
                observation => observation.template_observation_id !== null
              )
              .map(observation => ({
                ...observation,
                do_delete_observation: null,
                solutions: (observation?.solutions || [])
                  .filter(solution => solution.template_solution_id !== null)
                  .map(solution => ({
                    ...solution,
                    do_delete_solution: null
                  }))
              }))
          } else {
            newData['spaces'][item.space_index]['items'].splice(
              item.item_index,
              1
            )

            newData['spaces'][item.space_index]['items'] = newData['spaces'][
              item.space_index
            ]['items'].map((item, itemIndex) => ({
              ...item,
              item_index: itemIndex,
              observations: (item?.observations || []).map(observation => ({
                ...observation,
                item_index: itemIndex,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  item_index: itemIndex
                }))
              }))
            }))
          }
        }

        const itemsData = newData.spaces[parentSpace]['items']
        itemsData.push({
          ...item,
          space_index: parentSpace,
          item_index: itemsData.length,
          collapsed: true,
          do_delete_item: 'false',
          template_item_id: null,
          item_uuid: uuid(),
          observations: itemChangingParent
            ? (item?.observations || []).map(observation => ({
                ...observation,
                space_index: parentSpace,
                item_index: itemsData.length,
                template_observation_id: null,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  space_index: parentSpace,
                  item_index: itemsData.length,
                  template_solution_id: null
                }))
              }))
            : [],
          multiple: false,
          totalInSpace: 0
        })
        return newData
      })
    }
  }

  const observationDropHandler = (dropData, observation) => {
    const parentSpace = dropData.parent_space
    const parentItem = dropData.parent_item

    const observationChangingParentItem =
      observation.item_index !== undefined &&
      (parentItem !== observation.item_index ||
        parentSpace !== observation.space_index)

    const foundObservations = templateCustomData.spaces[parentSpace]['items'][
      parentItem
    ]['observations'].filter(
      obs => obs.observation_id === observation.observation_id
    )

    if (
      templateCustomData.spaces[parentSpace]['items'][parentItem][
        'observations'
      ].length === 0 ||
      !foundObservations.length ||
      (foundObservations.length === 1 &&
        !foundObservations[0].do_delete_observation)
    ) {
      setTemplateCustomData(prevData => {
        const newData = { ...prevData }

        if (observationChangingParentItem) {
          if (observation.template_observation_id) {
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index][
              'do_delete_observation'
            ] = null

            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index][
              'solutions'
            ] = newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'][observation.observation_index]['solutions']
              .filter(solution => solution.template_solution_id !== null)
              .map(solution => ({
                ...solution,
                do_delete_solution: null
              }))
          } else {
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'].splice(observation.observation_index, 1)
            newData['spaces'][observation.space_index]['items'][
              observation.item_index
            ]['observations'] = newData['spaces'][observation.space_index][
              'items'
            ][observation.item_index]['observations'].map(
              (observation, index) => ({
                ...observation,
                observation_index: index,
                solutions: observation['solutions'].map(solution => ({
                  ...solution,
                  observation_index: index
                }))
              })
            )
          }
        }

        const observationsData =
          newData.spaces[parentSpace]['items'][parentItem]['observations']
        observationsData.push({
          ...observation,
          space_index: parentSpace,
          item_index: parentItem,
          currentIndex: observationsData.length,
          observation_index: observationsData.length,
          template_observation_id: null,
          do_delete_observation: 'false',
          solution_type: 'single',
          media_required: null,
          media_minimum: '1',
          observation_uuid: uuid(),
          solutions: observationChangingParentItem
            ? observation['solutions'].map(solution => ({
                ...solution,
                item_index: parentItem,
                observation_index: observationsData.length,
                space_index: parentSpace,
                template_solution_id: null
              }))
            : []
        })

        return newData
      })
    }
  }

  const solutionDropHandler = (dropData, solution) => {
    const parentSpace = dropData.parent_space
    const parentItem = dropData.parent_item
    const parentObservation = dropData.parent_observation

    const solutionChangingParentItem =
      solution.observation_index !== undefined &&
      (parentObservation !== solution.observation_index ||
        parentItem !== solution.item_index ||
        parentSpace !== solution.space_index)

    const foundSolutions = templateCustomData.spaces[parentSpace]['items'][
      parentItem
    ]['observations'][parentObservation]['solutions'].filter(
      sol => sol.solution_id === solution.solution_id
    )

    if (
      templateCustomData.spaces[parentSpace]['items'][parentItem][
        'observations'
      ][parentObservation]['solutions'].length === 0 ||
      !foundSolutions.length ||
      (foundSolutions.length === 1 && !foundSolutions[0].do_delete_solution)
    ) {
      setTemplateCustomData(prevData => {
        const newData = { ...prevData }
        const solutionsData =
          newData.spaces[parentSpace]['items'][parentItem]['observations'][
            parentObservation
          ]['solutions']

        if (solutionChangingParentItem) {
          if (solution.template_solution_id) {
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'][
              solution.solution_index
            ]['do_delete_solution'] = null
          } else {
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'].splice(
              solution.solution_index,
              1
            )
            newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index][
              'solutions'
            ] = newData['spaces'][solution.space_index]['items'][
              solution.item_index
            ]['observations'][solution.observation_index]['solutions'].map(
              (solution, index) => ({
                ...solution,
                solution_index: index
              })
            )
          }
        }

        solutionsData.push({
          ...solution,
          space_index: parentSpace,
          item_index: parentItem,
          observation_index: parentObservation,
          currentIndex: solutionsData.length,
          solution_index: solutionsData.length,
          template_solution_id: null,
          do_delete_solution: 'false',
          solution_uuid: uuid()
        })
        return newData
      })
    }
  }

  const handleSort = useCallback(
    (
      dragIndex,
      hoverIndex,
      type,
      space_index,
      item_index,
      observation_index
    ) => {
      setTemplateCustomData(prev => {
        const newData = cloneDeep(prev)

        switch (type) {
          case 'space':
            newData.spaces = update(newData.spaces, {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, newData.spaces[dragIndex]]
              ]
            }).map((space, index) => ({
              ...space,
              space_index: index,
              items: (space?.items || []).map(item => ({
                ...item,
                space_index: index,
                observations: (item?.observations || []).map(observation => ({
                  ...observation,
                  space_index: index,
                  solutions: (observation?.solutions || []).map(solution => ({
                    ...solution,
                    space_index: index
                  }))
                }))
              }))
            }))

            break
          case 'item':
            if (space_index !== undefined) {
              newData.spaces[space_index]['items'] = update(
                newData.spaces[space_index]['items'],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][dragIndex]
                    ]
                  ]
                }
              ).map((item, index) => ({
                ...item,
                item_index: index,
                observations: (item?.observations || []).map(observation => ({
                  ...observation,
                  item_index: index,
                  solutions: (observation?.solutions || []).map(solution => ({
                    ...solution,
                    item_index: index
                  }))
                }))
              }))
            }
            break
          case 'observation':
            if (space_index !== undefined && item_index !== undefined) {
              newData.spaces[space_index]['items'][item_index][
                'observations'
              ] = update(
                newData.spaces[space_index]['items'][item_index][
                  'observations'
                ],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][item_index][
                        'observations'
                      ][dragIndex]
                    ]
                  ]
                }
              ).map((observation, index) => ({
                ...observation,
                observation_index: index,
                solutions: (observation?.solutions || []).map(solution => ({
                  ...solution,
                  observation_index: index
                }))
              }))
            }
            break
          case 'solution':
            if (
              space_index !== undefined &&
              item_index !== undefined &&
              observation_index !== undefined
            ) {
              newData.spaces[space_index]['items'][item_index]['observations'][
                observation_index
              ]['solutions'] = update(
                newData.spaces[space_index]['items'][item_index][
                  'observations'
                ][observation_index]['solutions'],
                {
                  $splice: [
                    [dragIndex, 1],
                    [
                      hoverIndex,
                      0,
                      newData.spaces[space_index]['items'][item_index][
                        'observations'
                      ][observation_index]['solutions'][dragIndex]
                    ]
                  ]
                }
              ).map((solution, index) => ({
                ...solution,
                solution_index: index
              }))
            }
            break
          default:
            break
        }
        return newData
      })
    },
    []
  )

  useEffect(() => {
    const errors = validateTemplate()
    setTemplateErrors(() => errors)
  }, [validateTemplate])

  useEffect(() => {
    if (templateEditMode) {
      dispatch(getCompanyTemplateDetails(pdbid, context, templateId))
    } else {
      dispatch(getTemplateElements(pdbid, context))
    }

    return () => {
      dispatch(resetCompanyTemplateDetails())
      dispatch(resetTemplateElements())
    }
  }, [templateEditMode, templateId])

  useEffect(() => {
    setTemplateCustomData(details)
  }, [details])

  useEffect(() => {
    setElementsCustomData(elements)
  }, [elements])

  useEffect(() => {
    if (
      elementsIsError ||
      deleteIsError ||
      detailsIsError ||
      createOrUpdateTemplateError ||
      duplicateIsError
    )
      setError(true)
  }, [
    detailsIsError,
    elementsIsError,
    deleteIsError,
    createOrUpdateTemplateError,
    duplicateIsError
  ])

  useEffect(() => {
    if (templateEditMode && !detailsIsRequesting && detailsHasRequested) {
      return setLoading(false)
    }
  }, [detailsIsRequesting, elementsIsRequesting])

  useEffect(() => {
    if (detailsIsRequesting) {
      return setLoading(true)
    }
  }, [detailsIsRequesting, elementsIsRequesting])

  useEffect(() => {
    if (templateRef.current) {
      templateRef.current.scrollIntoView({ behavior: 'auto' })
    }
  }, [templateCustomData.spaces.length])

  const getTemplateBody = () => {
    return (
      <ScrollingComponent className="space-container__wrapper">
        {(templateToRender?.spaces || []).map((space, spaceIndex) => (
          <Draggable
            dragType="space"
            dragData={{ currentIndex: spaceIndex, ...space }}
            previewClass={['space-container__draggable']}
            canDrag={canEditTemplate}
            key={space.space_uuid}
            index={space.space_index}
            onSort={handleSort}>
            <div
              className={`space-container ${
                activeSpace === space.space_index && isSectionOpened
                  ? 'active-space'
                  : ''
              }`}>
              <Droppable
                dropType="space"
                dropAction={spaceDropHandler}
                dropData={space}
                onClick={e => {
                  e.stopPropagation()
                  setActiveSpace(space.space_index)
                }}
                dropClass={['space-container__draggable']}>
                <div className="space-container__title">
                  <img
                    src={move}
                    alt="move-icon"
                    className="space-container__icon"
                  />
                  {space.space_id && space.space_name ? (
                    <div className="draggable-item" style={{ left: 0 }}>
                      <span>{space.space_name}</span>
                      {canEditTemplate && (
                        <img
                          src={closeFilled}
                          alt="remove item"
                          className="draggable-item__close"
                          onClick={() => removeTemplateElement(space)}
                        />
                      )}
                    </div>
                  ) : (
                    <span className="draggable-item__instructions">
                      Drop a space here to get started.
                    </span>
                  )}
                  <FontAwesomeIcon
                    icon={!space.collapsed ? faChevronUp : faChevronDown}
                    className="collapsible-toggler"
                    onClick={e => {
                      e.stopPropagation()
                      toggleCollapsible(space.space_index)
                    }}
                  />
                </div>
              </Droppable>
              {!space.collapsed && space.space_id && (
                <Droppable
                  dropType="item"
                  dropAction={itemDropHandler}
                  dropData={{ parent_space: space.space_index }}
                  dropClass={['space-container__body']}>
                  {(space?.items || []).length === 0 && space.space_id ? (
                    <span className="draggable-item__instructions">
                      Add an item to this space.
                    </span>
                  ) : (
                    (space?.items || []).map((item, index) => (
                      <div
                        className="item-container__body"
                        key={item.item_uuid}>
                        <span className="title">Item {index + 1}</span>
                        <div className="content">
                          <Draggable
                            dragType="item"
                            dragData={{
                              currentIndex: index,
                              ...item,
                              listId: space?.space_uuid
                            }}
                            previewClass={['draggable-item__preview']}
                            canDrag={canEditTemplate}
                            onSort={handleSort}
                            index={item.item_index}
                            listId={space.space_uuid}
                            key={item.item_uuid}>
                            <div className="draggable-item">
                              <img
                                src={move}
                                alt="move-icon"
                                className="draggable-item__icon"
                              />
                              <span>{item.item_name}</span>
                              {canEditTemplate && (
                                <img
                                  src={closeFilled}
                                  alt="remove item"
                                  className="draggable-item__close"
                                  onClick={() => removeTemplateElement(item)}
                                />
                              )}
                            </div>
                            <Droppable
                              dropType="observation"
                              dropAction={observationDropHandler}
                              dropData={{
                                parent_space: space.space_index,
                                parent_item: item.item_index
                              }}
                              dropClass={['observation-container__body']}>
                              {(item?.observations || []).length === 0 ? (
                                <span className="draggable-item__instructions">
                                  Add one or more observations to this item.
                                </span>
                              ) : (
                                (item?.observations || []).map(
                                  (observation, index) => (
                                    <div key={observation.observation_uuid}>
                                      <span className="title">
                                        Observation {index + 1}
                                      </span>
                                      <div className="content">
                                        <Draggable
                                          dragType="observation"
                                          dragData={{
                                            currentIndex: index,
                                            ...observation,
                                            listId: item.item_uuid
                                          }}
                                          previewClass={[
                                            'draggable-item__preview'
                                          ]}
                                          canDrag={canEditTemplate}
                                          listId={item.item_uuid}
                                          index={observation.observation_index}
                                          key={observation.observation_uuid}
                                          onSort={handleSort}>
                                          <div className="draggable-item">
                                            <img
                                              src={move}
                                              alt="move-icon"
                                              className="draggable-item__icon"
                                            />
                                            <span>
                                              {observation.observation_name}
                                            </span>
                                            {templateCustomData.shared.length <=
                                              0 && (
                                              <img
                                                src={closeFilled}
                                                alt="remove item"
                                                className="draggable-item__close"
                                                onClick={() =>
                                                  removeTemplateElement(
                                                    observation
                                                  )
                                                }
                                              />
                                            )}
                                          </div>
                                          {(observation?.solutions || [])
                                            .length === 0 ? (
                                            <Droppable
                                              dropType="solution"
                                              dropAction={solutionDropHandler}
                                              dropData={{
                                                parent_space: space.space_index,
                                                parent_item: item.item_index,
                                                parent_observation:
                                                  observation.observation_index
                                              }}
                                              dropClass={[
                                                'solution-container__body'
                                              ]}>
                                              <span className="draggable-item__instructions">
                                                Add one or more solutions to
                                                this observation.
                                              </span>
                                            </Droppable>
                                          ) : (
                                            <Droppable
                                              dropType="solution"
                                              dropAction={solutionDropHandler}
                                              dropData={{
                                                parent_space: space.space_index,
                                                parent_item: item.item_index,
                                                parent_observation:
                                                  observation.observation_index
                                              }}
                                              dropClass={[
                                                'solution-container__body'
                                              ]}>
                                              <img
                                                src={doubleArrow}
                                                alt="solutions-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: 38,
                                                  top: 25
                                                }}
                                              />
                                              {(
                                                observation?.solutions || []
                                              ).map(solution => (
                                                <Draggable
                                                  dragType="solution"
                                                  dragData={{
                                                    currentIndex: index,
                                                    ...solution,
                                                    listId:
                                                      observation.observation_uuid
                                                  }}
                                                  previewClass={[
                                                    'draggable-item__preview'
                                                  ]}
                                                  canDrag={
                                                    templateCustomData.shared
                                                      .length <= 0
                                                  }
                                                  key={solution.solution_uuid}
                                                  index={
                                                    solution.solution_index
                                                  }
                                                  listId={
                                                    observation.observation_uuid
                                                  }
                                                  onSort={handleSort}>
                                                  <div className="draggable-item">
                                                    <span>
                                                      {solution.solution_name}
                                                    </span>
                                                    {templateCustomData.shared
                                                      .length <= 0 && (
                                                      <img
                                                        src={closeFilled}
                                                        alt="remove item"
                                                        className="draggable-item__close"
                                                        onClick={() =>
                                                          removeTemplateElement(
                                                            solution
                                                          )
                                                        }
                                                      />
                                                    )}
                                                  </div>
                                                </Draggable>
                                              ))}
                                            </Droppable>
                                          )}
                                        </Draggable>
                                      </div>
                                    </div>
                                  )
                                )
                              )}
                            </Droppable>
                          </Draggable>
                        </div>
                        <div className="item-container__border"></div>
                      </div>
                    ))
                  )}
                </Droppable>
              )}
            </div>
          </Draggable>
        ))}
        <div style={{ float: 'left', clear: 'both' }} ref={templateRef}></div>
      </ScrollingComponent>
    )
  }

  if (loading) {
    return <BigLoading />
  }

  return (
    <>
      <RouterPrompt
        title="Please save your changes before proceeding to go back. If you do not save, the changes will be discarded."
        okText="Discard"
        cancelText="Cancel"
        when={hasUnsavedChanges}
      />

      {error && (
        <div className="columns" style={{ marginLeft: '0' }}>
          <div className="column is-full">
            <div className={`${error ? 'error' : ''}`}>
              <div className="error-container">
                <img src={warning} alt="warning" className="m-r-sm" />
                <span className="error-description">Error.</span> Something went
                wrong. Please try again.
              </div>
            </div>
          </div>
        </div>
      )}

      {templateCustomData.shared.length > 0 && (
        <div className="columns" style={{ marginLeft: '0' }}>
          <div className="column is-full p-l-none p-r-none">
            <div className="caveat-container">
              <span className="caveat-description">
                <strong>Note:</strong>&nbsp; This template cannot be updated
                because it has been shared with a property. To create an updated
                version, you can duplicate this template and update the new
                version.
              </span>
            </div>
          </div>
        </div>
      )}

      <div
        className="columns is-desktop"
        style={{ alignItems: 'flex-start', marginLeft: '0' }}>
        <div className="column is-two-thirds-desktop template-manage-dnd__spaces">
          <div className="title m-b-md">
            <div className="input-fields">
              <EditField
                id="name"
                label="Enter template name"
                locked={!canEditTemplate}
                active={false}
                value={templateCustomData.name}
                error={templateErrors.name}
                onChange={value => {
                  setTemplateName(value)
                }}
              />
              <EditField
                id="type"
                label="Enter template type"
                locked={!canEditTemplate}
                active={false}
                value={templateCustomData.type}
                error={templateErrors.type}
                onChange={value => {
                  setTemplateType(value)
                }}
              />
            </div>
          </div>
          {(templateToRender.spaces || []).length === 0 && (
            <p className="m-t-lg has-text-centered">
              There are no spaces added yet
            </p>
          )}
          {getTemplateBody()}
          {canEditTemplate && (
            <button
              className="add-space"
              onClick={addSpaceHandler}
              disabled={!spacesLeft.length}>
              + Add a space
            </button>
          )}
        </div>

        {/* details / elements section */}
        <div className="column template-manage-dnd__details">
          <div className="template-manage-dnd__details__header">
            <ul className="submenu m-b-md">
              {templateConfigSubmenu.map((item, index) => (
                <li
                  key={item.label}
                  className={cn({
                    active: index === templateConfigSubmenuActiveIndex
                  })}
                  onClick={() => setTemplateConfigSubmenuActiveIndex(index)}>
                  {item.label}
                </li>
              ))}
            </ul>
            <button
              className="button is-success btn-save m-b-md"
              onClick={saveTemplateHandler}
              disabled={disableSaveButton}>
              {createOrUpdateTemplateRequested && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
              )}
              Save
            </button>
          </div>
          <div className="template-manage-dnd__details__body">
            {templateConfigSubmenuActiveIndex === 0 && (
              <TemplateElements
                elements={elementsCustomData}
                isLoading={elementsIsRequesting}
                canDrag={canEditTemplate}
              />
            )}
            {templateConfigSubmenuActiveIndex === 1 && (
              <TemplateDetails
                onSubmit={setIsSubmitted}
                originalTemplate={originalTemplate}
                canEditTemplate={canEditTemplate}
                templateData={templateCustomData}
                templateToExport={templateToRender}
                setTemplateData={setTemplateCustomData}
                history={history}
                hasPermission={hasPermission}
                activeSpace={activeSpace}
                isTemplateComplete={isTemplateComplete}
                onRemoveTemplateElement={removeTemplateElement}
                onOpenSection={value => setIsSectionOpened(value)}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

CompanyTemplateManage.propTypes = {
  history: PropTypes.object,
  templateId: PropTypes.string
}

export default CompanyTemplateManage
