import React  from 'react'
import FilterButton from '../data-table/filter-button'
import './index.scss'


const TurnboardFilters = ({ customFilters, activeFilters, setActiveFilters }) => {
  return (
    <div className="turnboard-filter-wrapper">
      <FilterButton
        header={''}
        activeFilters={activeFilters}
        customFilters={ customFilters }
        oneFilterAtTime={true}
        filterLabel={'Add Filter'}
        onSetFilters={setActiveFilters}
        showCustomFilterOption = { false }
        onToggleModal={() => {
        
        }}
      />
    </div>

  )
}


export default TurnboardFilters
