import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../breadcrumbs'
import HoldoversListOccupied from '../holdovers-list-occupied'
import './index.scss'

const HoldoversMain = props => {
  const getLoadingState = () => <BigLoading />

  const { location, holdovers } = props

  if (holdovers.occupied.isRequesting) {
    return getLoadingState()
  }
  return (
    <div className="container setup-staff-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns setup-staff-main">
          <div className="column is-full">
            <HoldoversListOccupied {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

HoldoversMain.propTypes = {
  location: PropTypes.object,
  setupStaff: PropTypes.object
}

export default HoldoversMain
