import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import Breadcrumbs from '../breadcrumbs'
import SetupUsersList from '../setup-users-list'
import UserList from '../user-list'
import SetupUsersDetail from '../setup-users-detail'
import SetupUsersNew from '../setup-users-new'
import AddNewUser from '../add-new-user'
import EditUser from '../edit-user'
import ViewUser from '../view-user'
import './index.scss'

const SetupUsersMain = props => {
  const [isLoading, setIsLoading] = useState(true)

  const {
    user,
    isAuthenticated,
    setupUsers,
    // setupAllUsersGet,
    setupAllRolesGet,
    setAlert,
    resetUser,
    type,
    // context,
    allPropertiesList,
    allRolesList
  } = props
  const { allRoles } = setupUsers
  const { pdbid } = user?.user_metadata

  useEffect(() => {
    allPropertiesList(pdbid)
    allRolesList(pdbid)
  }, [])

  useEffect(() => {
    if (isAuthenticated && !allRoles.hasMadeInitialRequest) {
      setupAllRolesGet()
    }
  }, [allRoles.hasMadeInitialRequest, isAuthenticated])

  // useEffect(() => {
  //   if (
  //     !setupUsers.allUsers.isRequesting &&
  //     !setupUsers.allRoles.isRequesting &&
  //     !setupUsers.oneUser.isRequesting &&
  //     !user.isRequesting &&
  //     user.hasMadeInitialRequest
  //   ) {
  //     setIsLoading(false)
  //   } else {
  //     setIsLoading(true)
  //   }
  // }, [
  //   setupUsers.allUsers.isRequesting,
  //   setupUsers.allRoles.isRequesting,
  //   setupUsers.oneUser.isRequesting
  // ])

  const getListViewLink = () => {
    const { pathname } = props.location
    if (pathname === '/setup/users') return null
    return (
      <Link to="/setup/users" className=" p-none" onClick={() => resetUser()}>
        Back to List View
      </Link>
    )
  }

  // useEffect(() => {
  //   if (
  //     setupUsers.oneUser.hasMadeInitialRequest &&
  //     !setupUsers.oneUser.isRequesting &&
  //     setupUsers.oneUser.isUpdating
  //   ) {
  //     if (!setupUsers.oneUser.isError) {
  //       setAlert('Users database updated', 'success')
  //     } else {
  //       setAlert('Error updating users database', 'danger')
  //     }
  //   }
  // }, [setupUsers.oneUser])

  // if (isLoading) {
  //   return <BigLoading />
  // }

  let Component
  if (type === 'UserList') {
    Component = UserList
  } else if (type === 'NewUser') {
    Component = AddNewUser
  } else if (type === 'EditUserDetail') {
    Component = EditUser
  } else {
    Component = ViewUser
  }

  return (
    <div className="container setup-staff-main animated fadeIn">
      <div className="section manage-users">
        <div className="breadcrumbs-spaces">
          <Breadcrumbs location={props.location} />
        </div>

        <div className="back-text">{getListViewLink()}</div>
        <div className="columns setup-staff-main">
          <div className="column is-full">
            <Component isLoading={isLoading} {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetupUsersMain
