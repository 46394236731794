import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setupServicesGet } from '../../modules/setup-vendors'
import {
  setupAllBudgetsGet,
  setupOneBudgetGet,
  setupOneBudgetCreate,
  setupOneBudgetUpdate,
  setupBudgetLineGet,
  setupBudgetLineCreate,
  setupBudgetLineUpdate
} from '../../modules/setup-budgets'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { openModal, closeModal, setModalContent } from '../../modules/modal'
import {
  openTooltip,
  closeTooltip,
  setTooltipContent
} from '../../modules/tooltip'
import SetupBudgetsMain from '../../parents/setup-budgets-main'
import BigLoading from '../../components/big-loading'

const SetupBudgets = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <SetupBudgetsMain {...props} />
  }

  return <BigLoading />
}

SetupBudgets.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupBudgets: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  modal,
  tooltip,
  setupVendors,
  setupBudgets
}) => ({
  user,
  context,
  modal,
  tooltip,
  setupVendors,
  setupBudgets
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllVendorsGet,
      setupServicesGet,
      setupAllBudgetsGet,
      setupOneBudgetGet,
      setupOneBudgetCreate,
      setupOneBudgetUpdate,
      setupBudgetLineGet,
      setupBudgetLineCreate,
      setupBudgetLineUpdate,
      openModal,
      closeModal,
      setModalContent,
      openTooltip,
      closeTooltip,
      setTooltipContent
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupBudgets)
)
