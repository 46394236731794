import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { associateUserToVendor } from '../../../modules/setup-users'

const DelinkUserModal = props => {
  const { user, associateUserToVendor, dismissModal, row, context } =
    props || {}
  const { user_id, vendor_user_id, vendor_id } = row || {}
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  const onErrorVendorAssociated = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onSuccessVendorAssociated = () => {
    setIsLoading(false)
    setErrorMessage('')
    dismissModal()
  }

  return (
    <div className="confirmation-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Delink User</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={dismissModal}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full delete-user">
          <p className="is-size-6">
            This action cannot be undone and the user will need to be manually
            re-link if needed in the future.
          </p>
          <p className="is-size-6 sub-message">
            Are you sure you want to delink this user?
          </p>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <div>
            <p>Something went wrong. Please try again later !!!</p>
          </div>
          <button className="error-button" onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-primary m-r-md"
            onClick={dismissModal}>
            Cancel
          </button>
          <button
            className="button main-button is-danger"
            onClick={() => {
              setIsLoading(true)
              setErrorMessage('')
              const body = {
                vendor_user_id,
                user_id,
                vendor_id
              }
              associateUserToVendor(
                pdbid,
                context,
                body,
                onSuccessVendorAssociated,
                onErrorVendorAssociated
              )
            }}>
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user, context }) => ({
  user,
  context
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      associateUserToVendor
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DelinkUserModal)
)
