import _ from 'lodash'
import React, { Fragment, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TurnableSpinner from '../turnable-spinner'
import ServiceCard from '../service-card'

const MAX_CARDS_WIDE = 3
const MAX_CARDS_DESKTOP = 2
const MAX_CARDS_NARROW = 2

const columnsNumber = responsive => {
  if (responsive.isFullHD) {
    return MAX_CARDS_WIDE
  } else if (responsive.isDesktop) {
    return MAX_CARDS_DESKTOP
  } else {
    return MAX_CARDS_NARROW
  }
}

const columnSize = (stats, responsive) => {
  let maxCards = columnsNumber(responsive)
  if (stats.length < maxCards) maxCards = stats.length
  switch (maxCards) {
    case 1:
      return 'is-full'
    case 2:
      return 'is-one-half'
    case 3:
    case 4:
    case 5:
      return 'is-one-third'
      // return 'is-one-fourth'
      // return 'is-one-fifth'
    default:
      return ''
  }
}

/**
 * Component for rendering all service cards in a summary container
 *
 * @component
 * @example
 * const stats = [
 *   {
 *       "service": "Paint",
 *       "value": 30500,
 *       "options": [
 *           {
 *               "option_name": "Full Paint Common Area",
 *               "quantity": 1,
 *               "est_cost": 0
 *           },
 *           ...
 *       ],
 *       "total": 100,
 *       "budget": "0"
 *   },
 *   ...
 * ]
 * return (
 *   <ServicesSummary
 *      stats={stats}
 *      isLoading={false}
 *      responsive={<responsive Redux object>}
 *      replaceLabels={false}
 *      chartCard={false}
 *      cardClass="p-none"
 *   />
 * )
 */

const ServicesSummary = ({ responsive, stats, isLoading, cardClass, replaceLabels }) => {
  const [maxHeight, setMaxHeight] = useState(0)
  const refs = useRef([])

  useEffect(() => {
    _.forEach(refs.current, ref => {
      ref.style.height = `${maxHeight}px`
    })
  }, [maxHeight])

  if (stats.length < 1) {
    return (
      <div className="is-full-width p-b-lg">
        <div className="has-text-centered">
          <TurnableSpinner size="large" className="loading-spinner" />
          <p className="loading-text">Loading...</p>
        </div>
      </div>
    )
  }

  const getServiceCardComponent = (cardInfo, cardIndex) => {
    const serviceCardProps = {
      showLoading: isLoading,
      replaceLabels: replaceLabels,
      service: cardInfo.service,
      value: cardInfo.value,
      total: cardInfo.total,
      quantityTotal: cardInfo.quantityTotal,
      // TODO Replaced the hardcoded data
      potential: cardInfo.potential,
      inspected: cardInfo.inspected,
      identified: cardInfo.identified,
      notNeeded: 4,
      budget: cardInfo.budget,
      options: cardInfo.options,
      className: cardClass,
      ref: obj => {
        if (obj) {
          if (obj.offsetHeight > maxHeight) {
            setMaxHeight(obj.offsetHeight)
          }
          refs.current[cardIndex] = obj
        }
      }
    }

    return <ServiceCard {...serviceCardProps} />
  }

  const cols = columnsNumber(responsive)
  return (
    <div className="columns is-multiline">
      {_.map(stats, (card, i) => (
        <Fragment key={i}>
          <div className={`column card-container ${columnSize(stats, responsive)}`} style={{ zIndex: 100 }}>
            {getServiceCardComponent(card, i)}
          </div>
          {(i + 1) % cols === 0 && <div className="break" />}
        </Fragment>
      ))}
    </div>
  )
}

ServicesSummary.propTypes = {
  /**
   * The responsive Redux object
   */
  responsive: PropTypes.object,
  /**
   * Array of stats to generate the cards
   */
  stats: PropTypes.array,
  /**
   * Determines if the data is currently being loaded
   */
  isLoading: PropTypes.bool,
  /**
   * Additional class to be included in each of the service cards main DIVs
   */
  cardClass: PropTypes.string,
  /**
   * Determines if the labels should be replaced with their alternate names -- Temporary fix
   */
  replaceLabels: PropTypes.bool
}

export default ServicesSummary
