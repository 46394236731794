import React, { useCallback, useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { icons } from './icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  unitNotesGet,
  unitNotesDelete,
  unitNotesCreate,
  unitNotesUpdate,
  resetSingleNoteGET
} from '../../modules/notes'
import { updateTurnboardDataByState } from '../../modules/turnboard'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BasicLoading from '../../components/basic-loading'
import TurnboardManageNote from './turnboard-manage-note'
import { GetToolTip } from './helper'

import './index.scss'
import PermissionGate from '../../components/permission-gate'

const TurnboardViewAllNotesModal = props => {
  const {
    onClose,
    unit = '',
    unit_id = '',
    user = {},
    context = {},
    unitNotesGet,
    notes = {},
    unitNotesDelete,
    unitNotesCreate,
    unitNotesUpdate,
    AllUnitIds = [],
    updateTurnboardDataByState,
    turnboard = {},
    setModalContent,
    closeModal,
    openModal,
    resetSingleNoteGET,
    setRemoveAllActiveFilter,
    unitsData,
    setupVendors
  } = props || {}
  const [isNoteLoading, setIsNoteLoading] = useState(true)
  const [noteDeleteId, setNoteDeleteId] = useState('')
  const [allNoteData, setAllNoteData] = useState([])
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  // Icons
  const { close, addButton, pencil, trash, closeFilled } = icons || {}

  const onNoteGetSuccess = () => {
    setIsNoteLoading(false)
  }
  const onNoteGetError = () => {
    setErrorMessage('Something went wrong, Please try again !!!')
    setIsNoteLoading(false)
  }

  useEffect(() => {
    loadUnitData()
    return () => resetSingleNoteGET()
  }, [])

  const loadUnitData = async () => {
    setIsNoteLoading(true)
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    if (unit_id) {
      await unitNotesGet(
        pdbid,
        context,
        unit_id,
        onNoteGetSuccess,
        onNoteGetError
      )
    }
  }

  // redux data
  const { data: allNoteRawData = [], hasMadeInitialRequest, isRequesting } =
    notes || {}
  const { activePage } = turnboard || {}

  useEffect(() => {
    setAllNoteData(allNoteRawData)
  }, [allNoteRawData])

  const onDeleteNoteSuccess = () => {
    setIsDeleteLoading(false)
    setErrorMessage('')
    setNoteDeleteId('')
    const filterSingleNoteData = (allNoteData || []).filter(
      note => note.id !== noteDeleteId
    )
    if ((filterSingleNoteData || []).length === 0) {
      const updateUnits = (unitsData || []).map(unitData => {
        const { unit_id: unitNumber } = unitData || {}
        if (
          unitNumber === unit_id &&
          (filterSingleNoteData || []).length === 0
        ) {
          return {
            ...unitData,
            has_note: false
          }
        }
        return {
          ...unitData
        }
      })
      updateTurnboardDataByState({
        data: {
          page: activePage,
          units: updateUnits || []
        }
      })
    }
    setAllNoteData(filterSingleNoteData)
  }

  const onDeleteNoteError = () => {
    setIsDeleteLoading(false)
    setNoteDeleteId('')
    setErrorMessage('Something went wrong, Please try again !!!')
  }

  const deleteNote = async () => {
    setIsDeleteLoading(true)
    const onError = onDeleteNoteError
    const onSuccess = onDeleteNoteSuccess
    const unitId = noteDeleteId
    const { user_metadata } = user || {}
    const { pdbid } = user_metadata || {}
    await unitNotesDelete(
      pdbid,
      context,
      unitId,
      { note_id: noteDeleteId },
      onSuccess,
      onError,
      false
    )
  }

  const manageNote = useCallback((row = {}) => {
    const {
      charge = '0',
      type = '',
      unit_id = '',
      note = '',
      id = '',
      isNew = false,
      servicetype_id = '',
      vendor_id = '',
      allNoteRawData = [],
      unit = ''
    } = row || {}
    const addNoteProps = {
      unitNotesCreate,
      user,
      context,
      noteCharge: charge,
      noteType: type,
      noteUnit: {
        unit_id,
        unit
      },
      noteMessage: note,
      isNoteEdit: unit_id !== '' && !isNew,
      isNew,
      unitNotesUpdate,
      note_id: id,
      unitIds: AllUnitIds,
      updateTurnboardDataByState,
      turnboard,
      setRemoveAllActiveFilter,
      setupVendors,
      servicetype_id,
      vendor_id,
      notes: allNoteRawData
    }
    setModalContent(() => (
      <TurnboardManageNote
        {...addNoteProps}
        onClose={() => {
          closeModal()
        }}
      />
    ))
    openModal({
      width: '480px',
      maxWidth: '100%',
      additionalClasses: 'turnboard-manage-note-modal'
    })
  }, [])

  return (
    <div className="turnboard-single-notes-modal">
      <div className="is-desktop is-mobile">
        <div className="is-full">
          <h2 className="modal-sync-title">Notes</h2>
          <p className="close is-pointer has-text-grey-light" onClick={onClose}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      {hasMadeInitialRequest && !isRequesting && !isNoteLoading ? (
        <div className="is-full">
          <h4>{`Unit ${unit || ''}`}</h4>
          {errorMessage !== '' && (
            <div className="notification is-danger is-light is-flex">
              <p>{errorMessage}</p>
              <button onClick={() => setErrorMessage('')}>
                <img src={closeFilled} alt="" />
              </button>
            </div>
          )}
          <div className="notes-container p-b-md m-b-md">
            {(allNoteData || []).length !== 0 ? (
              (allNoteData || []).map((note, i) => {
                const {
                  creation_date,
                  type,
                  charge,
                  note: noteText,
                  id: noteId,
                  purchase_order_id
                } = note || {}
                return (
                  <div className="columns" key={i}>
                    <div className="column note-item">
                      <div className="columns p-b-xs m-b-none">
                        <div className="column is-full p-b-none">
                          {noteId === noteDeleteId && (
                            <div className="columns">
                              <div className="column">
                                Do you really want to delete this note?
                              </div>
                              <div className="column is-narrow is-pulled-right">
                                <button
                                  className={'button is-danger m-r-sm'}
                                  onClick={() => deleteNote()}>
                                  Yes
                                  {isDeleteLoading && (
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      spin
                                      color="#ffffff"
                                      className="m-l-sm"
                                    />
                                  )}
                                </button>
                                <button
                                  className={'button is-default'}
                                  onClick={() => setNoteDeleteId('')}>
                                  No
                                </button>
                              </div>
                            </div>
                          )}
                          <span className="note-title">
                            {creation_date} - {type}
                          </span>
                          <PermissionGate name={'turnboard-actions'}>
                            <a href="#!" className="is-pulled-right">
                              <GetToolTip
                                purchaseOrder={purchase_order_id}
                                type="delete">
                                <img
                                  alt="Delete Note"
                                  src={trash}
                                  className="is-pointer"
                                  onClick={() => {
                                    if (!purchase_order_id) {
                                      setNoteDeleteId(noteId)
                                    }
                                  }}
                                />
                              </GetToolTip>
                            </a>
                            <a className="is-pulled-right m-r-sm">
                              <GetToolTip
                                purchaseOrder={purchase_order_id}
                                type="edit">
                                <img
                                  alt="Edit Note"
                                  className="is-pointer"
                                  src={pencil}
                                  onClick={() => {
                                    if (!purchase_order_id) {
                                      manageNote(note, setRemoveAllActiveFilter)
                                    }
                                  }}
                                />
                              </GetToolTip>
                            </a>
                          </PermissionGate>
                        </div>
                      </div>
                      <span className="note-text">
                        {charge && <span className="m-r-sm">${charge}.</span>}
                        {noteText}
                      </span>
                    </div>
                  </div>
                )
              })
            ) : (
              <p className="no-notes">No Notes Found</p>
            )}
          </div>
          <PermissionGate name={'turnboard-actions'}>
            <div className="columns is-vcentered m-b-none">
              <div className="column is-size-6 is-unselectable">
                <a
                  className="add-note"
                  onClick={() =>
                    manageNote(
                      { unit_id, isNew: true, allNoteRawData, unit },
                      setRemoveAllActiveFilter
                    )
                  }>
                  <img
                    alt="Add Note"
                    src={addButton}
                    className="m-r-sm"
                    style={{ marginTop: '-2px', height: 24, width: 24 }}
                  />
                  Add a Note
                </a>
              </div>
            </div>
          </PermissionGate>
        </div>
      ) : (
        <BasicLoading />
      )}
    </div>
  )
}

TurnboardViewAllNotesModal.propTypes = {
  onClose: PropTypes.func,
  unitNotesGet: PropTypes.func,
  unitNotesDelete: PropTypes.func,
  unitNotesCreate: PropTypes.func,
  unitNotesUpdate: PropTypes.func,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  resetSingleNoteGET: PropTypes.func,
  closeModal: PropTypes.func,
  updateTurnboardDataByState: PropTypes.func,
  allNotes: PropTypes.array,
  unit: PropTypes.string,
  unit_id: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  notes: PropTypes.object,
  AllUnitIds: PropTypes.array,
  turnboard: PropTypes.object
}

const mapStateToProps = ({
  turnboard,
  user,
  context,
  notes,
  setupVendors
}) => ({
  turnboard,
  user,
  context,
  notes,
  setupVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setModalContent,
      openModal,
      closeModal,
      unitNotesGet,
      unitNotesDelete,
      unitNotesCreate,
      unitNotesUpdate,
      updateTurnboardDataByState,
      resetSingleNoteGET
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(TurnboardViewAllNotesModal))
