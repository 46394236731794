import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  setupOneStaffCreate,
  setupOneStaffUpdate,
  setupOneStaffDelete,
  setupOneStaffGet,
  setupAllStaffGet,
  allStaffListGet,
  resetAllStaffListGet,
  deleteStaffUser
} from '../../modules/setup-staff'

import { setupAllVendorsGet } from '../../modules/setup-vendors'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'

import {
  resetCheckEmail,
  setupAllRolesGet,
  allRolesList,
  allPropertiesList,
  newUserDetail,
  clearUser,
  createOrEditUser
} from '../../modules/setup-users'

import { setModalContent, openModal, closeModal } from '../../modules/modal'
import SetupStaffMain from '../../parents/setup-staff-main'
import BigLoading from '../../components/big-loading'
import { setAlert } from '../../modules/alerts'

const SetupStaff = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <SetupStaffMain {...props} />
  }

  return <BigLoading />
}

SetupStaff.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupStaff: PropTypes.object,
  tooltip: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  setupStaff,
  setupVendors,
  setupUsers,
  tooltip
}) => ({
  user,
  context,
  setupStaff,
  setupVendors,
  setupUsers,
  tooltip
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllStaffGet,
      setupOneStaffGet,
      setupOneStaffCreate,
      setupOneStaffUpdate,
      setupOneStaffDelete,
      setupAllVendorsGet,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      setModalContent,
      openModal,
      closeModal,
      allStaffListGet,
      resetAllStaffListGet,
      setupAllRolesGet,
      resetCheckEmail,
      allRolesList,
      allPropertiesList,
      newUserDetail,
      clearUser,
      createOrEditUser,
      deleteStaffUser,
      setAlert
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupStaff)
)
