import _ from 'lodash'
import React, { useMemo, useEffect, useRef, memo } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import {
  useTheme,
  create,
  ColorSet,
  color,
  Container,
  registry
} from '@amcharts/amcharts4/core'
import { PieChart, PieSeries, Legend } from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import ComponentWithStatus from '../component-with-status'
import { KEYS_SERIES_LABELS } from './constants'

const COLORS = [
  '#8dc85c',
  '#122048',
  '#0081c3',
  '#8bd3ed',
  '#ced4da',
  '#e93f33'
]

/**
 * Goals summary bar charts
 *
 * @component
 * @example
 * const stats = [
 *   {
 *     "id": "expected",
 *     "label": "Expected",
 *     "value": 286,
 *     "href": "/keys?filter=expected"
 *   },
 *   {
 *     "id": "returned",
 *     "label": "Returned",
 *     "value": 56,
 *     "href": "/keys?filter=returned"
 *   },
 *   {
 *     "id": "rti",
 *     "label": "Transfers",
 *     "value": 8,
 *     "href": "/keys?filter=rti"
 *   },
 *   {
 *     "id": "potential_holdovers",
 *     "label": "Potential HOs",
 *     "value": 221,
 *     "href": "/keys?filter=potential_holdovers"
 *   },
 *   {
 *     "id": "holdovers",
 *     "label": "Holdover",
 *     "value": 4,
 *     "href": "/keys?filter=holdovers"
 *   }
 * ]
 * return (
 *   <Keys
 *     loading={false}
 *     error={false}
 *     stats={stats}
 *   />
 * )
 */
const Keys = ({ loading, error, stats }) => {
  const uuid = useMemo(() => Short.uuid(), [])
  const ref = useRef()

  const data = {}
  _.forEach(Object.keys(KEYS_SERIES_LABELS), key => {
    const stat = _.find(stats, s => s.id === key)
    data[key] = _.get(stat, 'value', 0)
  })

  const findChart = () =>
    registry.baseSprites.find(
      chartObj => chartObj.htmlContainer.id === `pie-chart-${uuid}`
    ) || null

  const findLegend = () =>
    registry.baseSprites.find(
      chartObj => chartObj.htmlContainer.id === `legend-container-${uuid}`
    ) || null

  const getChart = () => {
    if (!ref.current || findChart()) return

    useTheme(am4themes_animated)

    const legendContainer = create(`legend-container-${uuid}`, Container)
    const updated = create(`pie-chart-${uuid}`, PieChart)
    updated.data = _.map(Object.keys(data), key => ({
      category: KEYS_SERIES_LABELS[key],
      value: data[key]
    }))

    const pieSeries = updated.series.push(new PieSeries())
    pieSeries.dataFields.value = 'value'
    pieSeries.dataFields.category = 'category'
    pieSeries.labels.template.disabled = true

    const colorSet = new ColorSet()
    colorSet.list = COLORS.map(c => {
      return new color(c)
    })
    pieSeries.colors = colorSet

    updated.legend = new Legend()
    updated.legend.position = 'right'
    updated.legend.itemContainers.template.paddingTop = 2
    updated.legend.itemContainers.template.paddingBottom = 2
    updated.legend.useDefaultMarker = true
    const marker = updated.legend.markers.template.children.getIndex(0)
    marker.dy = 3
    marker.cornerRadius(12, 12, 12, 12)
    marker.height = 16
    marker.width = 16
    updated.legend.labels.template.adapter.add('text', (text, target) => {
      return `${_.get(target, 'parent.dataItem.name')}: ${
        data[
          Object.keys(KEYS_SERIES_LABELS)[
            _.indexOf(
              Object.values(KEYS_SERIES_LABELS),
              _.get(target, 'parent.dataItem.name')
            )
          ]
        ]
      }`
    })
    updated.legend.valueLabels.template.disabled = true
    updated.legend.fontSize = 14
    updated.legend.marginLeft = 20
    updated.legend.marginRight = 20
    updated.legend.divId = 'keys_legend'
    updated.legend.parent = legendContainer

    return updated
  }

  const disposeChart = () => {
    const foundChart = findChart()
    if (foundChart) {
      foundChart.dispose()
    }
    const foundLegend = findLegend()
    if (foundLegend) {
      foundLegend.dispose()
    }
  }

  useEffect(() => {
    return () => {
      disposeChart()
    }
  }, [])

  useEffect(() => {
    disposeChart()
    getChart()
  }, [ref.current, stats])

  const getExpectedValue = useMemo(() => {
    const getExpectedValue = (stats || []).filter(s => s.id === 'expected')
    if ((getExpectedValue || []).length) {
      return getExpectedValue[0]?.value
    }
    return 0
  }, [stats])

  return (
    <div className="dashboard-card card-fill">
      <div className="inner-content">
        <ComponentWithStatus loading={loading} error={error}>
          <>
            <p className="heading-5">Breakdown: Keys</p>
            <div className="columns">
              <div className="column is-half">
                <div
                  id={`pie-chart-${uuid}`}
                  className={`pie-chart-${uuid} has-text-centered`}
                  ref={ref}
                  style={{
                    top: '3em',
                    bottom: '1em',
                    left: '1em',
                    position: 'absolute',
                    width: '50%'
                  }}></div>
              </div>
              <div
                className="column is-half"
                style={{
                  height: '100%',
                  bottom: '1em',
                  position: 'absolute',
                  right: '1em'
                }}>
                <div className="columns">
                  <div
                    className="column is-full has-text-weight-semibold"
                    style={{
                      top: '3rem',
                      left: '10px',
                      position: 'absolute'
                    }}>
                    Expected = {getExpectedValue}
                    {/* {_.reduce(
                      Object.keys(data),
                      (acc, current) => {
                        console.log(current)
                        if (current !== 'rti') {
                          return acc + data[current]
                        }
                        return acc
                      },
                      0
                    )} */}
                  </div>
                </div>
                <div
                  className="columns"
                  style={{ position: 'relative', height: '100%' }}>
                  <div
                    className={`legend-container-${uuid} column is-full`}
                    style={{
                      marginTop: '4.5rem',
                      height: '100%',
                      paddingBottom: '3rem'
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        </ComponentWithStatus>
      </div>
    </div>
  )
}

Keys.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Stats for the current property
   */
  stats: PropTypes.array
}

export default memo(Keys)
