import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'ui'
import { changePurchaseOrderStatus } from 'modules/invoices'

const sendToDraftModal = ({ invoice }) => {
  const dispatch = useDispatch()
  const {
    user_metadata: { pdbid }
  } = useSelector(state => state.user)
  const context = useSelector(state => state.context)

  return (
    <Modal
      title="Confirm Status Change"
      okText="Move to Draft"
      okType="primary"
      onConfirm={() =>
        dispatch(
          changePurchaseOrderStatus({
            pdbid,
            context,
            invoiceId: invoice.po_id,
            status: 'draft',
            invoice_file: invoice?.invoice_file
          })
        )
      }>
      <div className="columns">
        <div className="column">
          Are you sure you want to move this back to draft mode?
        </div>
      </div>
    </Modal>
  )
}

export default sendToDraftModal
