import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAppContext } from '../../helpers/hooks'
import {
  addStaffUserToExistingProperty,
  verifyStaffUserWithEmail
} from '../../modules/setup-staff'
import { checkEmailExists, createOrEditUser } from '../../modules/setup-users'
import CreateStaffUserModal from './CreateStaffUserModal'
import EmailAlreadyExistsModal from './EmailAlreadyExistsModal'
import EmailVerificationModal from './EmailVerificationModal'
import './index.scss'

const SetupStaffUserStepperModal = ({
  onDismiss,
  onCreateNewStaffUser,
  onAfterAddStaffUserToProperty
}) => {
  const dispatch = useDispatch()
  const { user, context } = useAppContext()
  const {
    setupUsers: { verifiedEmail, propertiesList, rolesList }
  } = useSelector(state => state)

  const { pdbid, properties, projects } = user || {}

  const { emailExists, emailVerified, loading, data: staffUser, serverError } =
    verifiedEmail || {}

  const [shouldAddUser, setShouldAddUser] = useState(false)
  const [createOrEditUserLoading, setCreateOrEditUserLoading] = useState(false)
  const [createOrEditUserError, setCreateOrEditUserError] = useState('')

  const currentProperty = useMemo(() => {
    const foundProperty = (properties || []).find(
      p => p.id === context.property
    )

    const foundProject = (projects || []).find(p => p.id === context.project)

    return foundProperty && foundProject
      ? `${foundProperty?.name} (${foundProject?.name})`
      : ''
  }, [properties, projects, context])

  const filteredRoles = useMemo(() => {
    const rolesToToRemove = ['Vendor', 'Administrator', 'Accounting']

    return (rolesList || []).filter(
      role => !rolesToToRemove.includes(role.name)
    )
  }, [rolesList])

  const filteredProperties = useMemo(() => {
    return (propertiesList || []).filter(property =>
      verifiedEmail?.properties?.includes(property.property_id)
    )
  }, [verifiedEmail.properties, propertiesList])

  const propertyToAdd = useMemo(() => {
    return (propertiesList || []).find(
      ({ property_code, project_code }) =>
        property_code === context.property && project_code === context.project
    )
  }, [propertiesList, context])

  const isVendor = useMemo(() => {
    return (staffUser?.type || '').toLowerCase() === 'vendor'
  }, [staffUser])

  const existsInCurrentProperty = useMemo(() => {
    return (filteredProperties || []).some(
      property =>
        property.property_code === context.property &&
        property.project_code === context.project
    )
  }, [filteredProperties, context])

  const showEmailVerificationModal = useMemo(
    () => !emailVerified || existsInCurrentProperty || isVendor,
    [emailVerified, existsInCurrentProperty]
  )

  const showAlreadyExistsModal = useMemo(
    () =>
      emailVerified &&
      emailExists &&
      !existsInCurrentProperty &&
      !isVendor &&
      !shouldAddUser,
    [
      emailExists,
      emailVerified,
      shouldAddUser,
      existsInCurrentProperty,
      isVendor
    ]
  )

  const showCreateStaffUserModal = useMemo(
    () =>
      emailVerified &&
      emailExists &&
      shouldAddUser &&
      !existsInCurrentProperty &&
      !isVendor,
    [
      emailExists,
      emailVerified,
      shouldAddUser,
      existsInCurrentProperty,
      isVendor
    ]
  )

  const handleVerifyStaff = email => {
    dispatch(
      checkEmailExists(
        pdbid,
        email,
        () => {},
        () => {
          onCreateNewStaffUser()
        }
      )
    )
  }

  const handleShouldAddUserToProperty = () => {
    setShouldAddUser(true)
  }

  const handleCreateStaffUser = body => {
    const onSuccess = () => {
      setCreateOrEditUserLoading(false)
      onAfterAddStaffUserToProperty()
    }

    const onError = () => {
      setCreateOrEditUserLoading(false)
      setCreateOrEditUserError(
        'Something went wrong. Please try again later !!!'
      )
    }

    setCreateOrEditUserError('')
    setCreateOrEditUserLoading(true)
    dispatch(createOrEditUser(pdbid, body, onSuccess, onError))
  }

  return (
    <div className="setup-Staff-user-stepper-modal">
      {showEmailVerificationModal && (
        <EmailVerificationModal
          existsInCurrentProperty={existsInCurrentProperty}
          isVendor={isVendor}
          serverError={serverError}
          loading={loading}
          onDismiss={onDismiss}
          onNext={handleVerifyStaff}
        />
      )}

      {showAlreadyExistsModal && (
        <EmailAlreadyExistsModal
          properties={filteredProperties}
          currentProperty={currentProperty}
          onDismiss={onDismiss}
          onAddUser={handleShouldAddUserToProperty}
        />
      )}

      {showCreateStaffUserModal && (
        <CreateStaffUserModal
          roles={filteredRoles}
          staffUser={staffUser}
          loading={createOrEditUserLoading}
          serverError={createOrEditUserError}
          currentProperty={currentProperty}
          propertyToAdd={propertyToAdd}
          onSubmit={handleCreateStaffUser}
          onDismiss={onDismiss}
        />
      )}
    </div>
  )
}

export default SetupStaffUserStepperModal
