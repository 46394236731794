import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import AdditionalActions from 'components/additional-actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { trash } from 'icons'

const SectionDetails = ({
  activeSpace,
  setTemplateData,
  templateData,
  removeActiveSpace,
  canEditTemplate
}) => {
  const [activeSpaceInfo, setActiveSpaceInfo] = useState({})

  useEffect(() => {
    activeSpace !== null
      ? setActiveSpaceInfo(cloneDeep(templateData['spaces'][activeSpace]))
      : setActiveSpaceInfo({ items: [] })
  }, [activeSpace, templateData])

  const handleInputChange = (e, instanceIndex, observationIndex) => {
    const instance = e?.target?.getAttribute('data-instance')
    const key = e?.target?.getAttribute('data-key')
    const type = e?.target?.getAttribute('data-type')
    const value = e?.target?.value === 'null' ? null : e?.target?.value

    if (canEditTemplate) {
      setActiveSpaceInfo(prevData => {
        const currentSpace = cloneDeep(prevData)

        switch (instance) {
          case 'space':
            currentSpace[key] = value
            break

          case 'item':
            currentSpace['items'][instanceIndex][key] = value

            if (type === 'radio' && key === 'multiple') {
              currentSpace['items'][instanceIndex]['totalInSpace'] = 1
            }

            break

          case 'observation':
            currentSpace['items'][instanceIndex]['observations'][
              observationIndex
            ][key] = value
            break

          default:
            return currentSpace
        }

        setTemplateData(prevState => {
          const spaces = cloneDeep(prevState.spaces)
          spaces[activeSpace] = cloneDeep(currentSpace)
          return {
            ...prevState,
            spaces
          }
        })
        return currentSpace
      })
    }
  }

  const additionalActions = [
    {
      action: removeActiveSpace,
      disabled: templateData.shared.length > 0 || !canEditTemplate,
      icon: trash,
      iconAlt: 'Delete',
      label: 'Delete Section'
    }
  ]

  // toggle collapsible cards
  const toggleCollapsible = useCallback(
    itemIndex => {
      setActiveSpaceInfo(prevData => {
        const newData = { ...prevData }
        newData['items'][itemIndex] = {
          ...newData['items'][itemIndex],
          collapsed: !newData['items'][itemIndex]['collapsed']
        }

        return newData
      })
    },
    [activeSpaceInfo]
  )

  return (
    <div className="template__details__container">
      {activeSpace !== null ? (
        <>
          <div className="template__details__general m-t-md">
            <p className="has-text-weight-bold">General</p>
            <div>
              <div
                className="is-flex m-t-sm m-b-sm"
                style={{ alignItems: 'baseline', flexWrap: 'wrap' }}>
                <p className="template__details__general__text">
                  Section required on property forms?
                </p>
                <div className="m-l-sm m-t-sm">
                  <label className="m-r-sm">
                    <input
                      className="is-inline"
                      type="radio"
                      name="required_on_property_forms"
                      value="null"
                      checked={!activeSpaceInfo?.required_on_property_forms}
                      onChange={handleInputChange}
                      data-key="required_on_property_forms"
                      data-instance="space"
                      data-type="radio"
                    />
                    <span className="custom-radio"></span>
                    <span className="template__details__general__text">
                      Yes
                    </span>
                  </label>
                  <label>
                    <input
                      className="is-inline template__details__general__text"
                      type="radio"
                      value="false"
                      name="required_on_property_forms"
                      checked={
                        activeSpaceInfo?.required_on_property_forms === 'false'
                      }
                      onChange={handleInputChange}
                      data-key="required_on_property_forms"
                      data-instance="space"
                      data-type="radio"
                    />
                    <span className="custom-radio"></span>
                    <span className="template__details__general__text">No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="template__details__general m-t-md">
            <p className="has-text-weight-bold">Items & Observations</p>
            {activeSpaceInfo?.items && activeSpaceInfo.items.length > 0 ? (
              activeSpaceInfo.items.map(
                ({
                  item_uuid,
                  item_name,
                  collapsed,
                  item_index,
                  observations
                }) => (
                  <div className="template__details__card" key={item_uuid}>
                    <div className="template__details__card__header">
                      <p className="template__details__card__title">
                        {item_name}
                      </p>
                      <FontAwesomeIcon
                        icon={!collapsed ? faChevronUp : faChevronDown}
                        className="collapsible-toggler"
                        onClick={() => toggleCollapsible(item_index)}
                      />
                    </div>
                    {!collapsed && (
                      <>
                        <div className="template__details__card__child">
                          {/* <div className="is-flex" style={{ flexWrap: 'wrap' }}>
                          <label className="m-r-sm">
                            <input
                              className="is-inline"
                              type="radio"
                              name={item.item_id}
                              value="0"
                              checked={!item.multiple ? 'checked' : ''}
                              onChange={e => handleInputChange(e, itemIndex)}
                              data-key="multiple"
                              data-instance="item"
                              data-type="radio"
                              disabled={templateData.shared.length > 0}
                            />
                            <span className="custom-radio"></span>
                            <span className="template__details__general__text">
                              Singular item
                            </span>
                          </label>
                          <label>
                            <input
                              className="is-inline"
                              type="radio"
                              value="1"
                              name={item.item_id}
                              checked={item.multiple ? 'checked' : ''}
                              onChange={e => handleInputChange(e, itemIndex)}
                              data-key="multiple"
                              data-instance="item"
                              data-type="radio"
                              disabled
                            />
                            <span className="custom-radio"></span>
                            <span className="template__details__general__text">
                              Multiple of this item
                            </span>
                          </label>
                        </div>
                        <div className="m-t-sm">
                          <span className="template__details__general__text">
                            How many in this space?
                          </span>
                          <input
                            type="text"
                            placeholder="1"
                            className="input template__details__input"
                            disabled={
                              !item.multiple || templateData.shared.length > 0
                            }
                            value={item.totalInSpace}
                            onChange={e => handleInputChange(e, itemIndex)}
                            data-key="totalInSpace"
                            data-instance="item"
                            data-type="text"
                          />
                        </div> */}
                        </div>
                        {observations && observations.length > 0
                          ? observations.map(
                              ({
                                observation_name,
                                media_required,
                                media_minimum,
                                solution_type,
                                observation_index,
                                observation_uuid
                              }) => (
                                <div
                                  className="template__details__card__child"
                                  key={observation_uuid}>
                                  <p className="template__details__card__subtitle">
                                    {observation_name}
                                  </p>
                                  <div
                                    className="is-flex"
                                    style={{
                                      alignItems: 'baseline',
                                      flexWrap: 'wrap'
                                    }}>
                                    <p className="template__details__general__text m-r-sm">
                                      Photo required if selected?
                                    </p>
                                    <div className="m-t-sm">
                                      <label className="m-r-sm">
                                        <input
                                          className="is-inline"
                                          type="radio"
                                          value="null"
                                          name={`media_required${observation_uuid}`}
                                          checked={!media_required}
                                          onChange={e =>
                                            handleInputChange(
                                              e,
                                              item_index,
                                              observation_index
                                            )
                                          }
                                          data-key="media_required"
                                          data-instance="observation"
                                          data-type="radio"
                                        />
                                        <span className="custom-radio"></span>
                                        <span className="template__details__general__text">
                                          Yes
                                        </span>
                                      </label>
                                      <label>
                                        <input
                                          className="is-inline m-l-sm template__details__general__text"
                                          type="radio"
                                          value="false"
                                          name={`media_required${observation_uuid}`}
                                          checked={media_required === 'false'}
                                          onChange={e =>
                                            handleInputChange(
                                              e,
                                              item_index,
                                              observation_index
                                            )
                                          }
                                          data-key="media_required"
                                          data-instance="observation"
                                          data-type="radio"
                                        />
                                        <span className="custom-radio"></span>
                                        <span className="template__details__general__text">
                                          No
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="m-l-lg m-t-sm">
                                    <span className="template__details__general__text">
                                      Minimum # of photos:
                                    </span>
                                    <input
                                      type="text"
                                      placeholder="1"
                                      name={`media_minimum${observation_uuid}`}
                                      className="input template__details__input"
                                      value={media_minimum}
                                      onChange={e => {
                                        const pattern = /[^0-9]/g

                                        if (
                                          !pattern.test(e.target.value) &&
                                          e.target.value !== '0'
                                        ) {
                                          handleInputChange(
                                            e,
                                            item_index,
                                            observation_index
                                          )
                                        } else {
                                          e.preventDefault()
                                        }
                                      }}
                                      data-key="media_minimum"
                                      data-instance="observation"
                                      data-type="text"
                                      disabled={media_required === 'false'}
                                    />
                                  </div>
                                  <div
                                    className="is-flex"
                                    style={{
                                      alignItems: 'baseline',
                                      flexWrap: 'wrap'
                                    }}>
                                    <p className="template__details__general__text">
                                      Solutions possible:
                                    </p>
                                    <div className="m-l-sm m-t-sm">
                                      <div
                                        className="is-flex"
                                        style={{ flexWrap: 'wrap' }}>
                                        <label className="m-r-sm">
                                          <input
                                            className="is-inline m-r-sm"
                                            type="radio"
                                            value="single"
                                            name={`solution_type${observation_uuid}`}
                                            checked={solution_type === 'single'}
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                item_index,
                                                observation_index
                                              )
                                            }
                                            data-key="solution_type"
                                            data-instance="observation"
                                            data-type="radio"
                                          />
                                          <span className="custom-radio"></span>
                                          <span className="template__details__general__text m-r-sm">
                                            Only one
                                          </span>
                                        </label>
                                        <label>
                                          <input
                                            className="is-inline m-r-sm"
                                            type="radio"
                                            value="multiple"
                                            name={`solution_type${observation_uuid}`}
                                            checked={
                                              solution_type === 'multiple'
                                            }
                                            onChange={e =>
                                              handleInputChange(
                                                e,
                                                item_index,
                                                observation_index
                                              )
                                            }
                                            data-key="solution_type"
                                            data-instance="observation"
                                            data-type="radio"
                                          />
                                          <span className="custom-radio"></span>
                                          <span className="template__details__general__text">
                                            Allow multiple
                                          </span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </>
                    )}
                  </div>
                )
              )
            ) : (
              <p className="m-t-sm">
                <i>No items added yet</i>
              </p>
            )}
          </div>
          <AdditionalActions actions={additionalActions} />
        </>
      ) : (
        <i>Select a section to see details</i>
      )}
    </div>
  )
}

SectionDetails.propTypes = {
  activeSpace: PropTypes.number,
  setTemplateData: PropTypes.func,
  templateData: PropTypes.object
}

export default SectionDetails
