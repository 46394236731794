import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { setFilterValue } from './helpers'
import { isDescendant } from '../../utils'

const FilterButton = ({
  header,
  activeFilters,
  customFilters,
  oneFilterAtTime,
  onSetFilters,
  onToggleModal,
  showCustomFilterOption,
  filterLabel = 'Filter',
  filterButtonStyle
}) => {
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false)

  useEffect(() => {
    document.addEventListener('click', onWindowClick)
    return () => document.removeEventListener('click', onWindowClick)
  }, [filterDropdownOpen])

  const onWindowClick = e => {
    if (e.target && !isDescendant(e.target, 'filter-dropdown')) {
      if (filterDropdownOpen) {
        setFilterDropdownOpen(false)
      }
    }
  }

  const disableFilter = itemKey => {
    if (oneFilterAtTime) {
      return (
        Object.keys(activeFilters).length > 0 &&
        !_.get(activeFilters, itemKey, false)
      )
    }

    return false
  }
  const renderBasicFilters = () => {
    const filterableColumns = _.map(
      _.filter(header, col => col.filterable),
      filter => filter.key
    )
    const hasCustomFilter = _.some(Object.keys(activeFilters), filter =>
      filterableColumns.includes(filter)
    )

    return (
      <>
        {_.map(customFilters, (item, index) => (
          <button
            className={`button filter-button ${
              _.get(activeFilters, item.key, false)
                ? 'is-primary'
                : 'is-secondary'
            }`}
            onClick={() => {
              setFilterDropdownOpen(false)
              onSetFilters(
                setFilterValue(
                  activeFilters,
                  item.key,
                  _.get(activeFilters, item.key) ? null : true
                )
              )
            }}
            disabled={disableFilter(item.key)}
            key={`custom-filter-${index}`}>
            {item.icon && (
              <img
                src={item.icon}
                alt={item.caption}
                className="p-r-md"
                style={{ width: 34, ...item.iconStyle }}
              />
            )}
            {item.caption}
          </button>
        ))}
        {showCustomFilterOption && (
          <button
            className={`button filter-button ${
              hasCustomFilter ? 'is-primary' : 'is-secondary'
            }`}
            onClick={() => {
              onToggleModal(true)
              setFilterDropdownOpen(false)
            }}
            key="custom-filter">
            Custom Filter
          </button>
        )}
      </>
    )
  }

  return (
    <div
      className={`dropdown filter-dropdown ${
        filterDropdownOpen ? 'is-active' : ''
      }`}
      id="filter-dropdown">
      <div className="dropdown-trigger">
        <button
          className={`button tool-button ${
            Object.keys(activeFilters).length ? 'selected' : ''
          }`}
          style={filterButtonStyle}
          type="button"
          aria-haspopup={true}
          aria-controls="filter-options"
          onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}>
          <span className="m-r-xs">+</span>
          {filterLabel}
        </button>
      </div>
      <div className="dropdown-menu" role="menu" id="filter-options">
        <div className="dropdown-content has-text-left">
          {renderBasicFilters()}
        </div>
      </div>
    </div>
  )
}

export default FilterButton
