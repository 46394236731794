import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import VendorLoginUserManageMain from '../../parents/vendor-login-user-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { checkVendorExists } from '../../modules/request-vendors'
import {
  checkEmailExists,
  resetCheckEmail,
  createOrEditUser,
  clearUser,
  allPropertiesList,
  allRolesList,
  newUserDetail,
  associateUserToVendor,
  userAccountDelete,
  updateUserPassword
} from '../../modules/setup-users'
import { setAlert } from '../../modules/alerts'

const VendorLoginUserManage = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <VendorLoginUserManageMain {...props} />
  }

  return <BigLoading />
}

VendorLoginUserManage.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, inspect, setupUsers }) => ({
  user,
  context,
  modal,
  inspect,
  setupUsers
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      checkVendorExists,
      checkEmailExists,
      resetCheckEmail,
      createOrEditUser,
      clearUser,
      allPropertiesList,
      allRolesList,
      setAlert,
      newUserDetail,
      associateUserToVendor,
      userAccountDelete,
      updateUserPassword
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VendorLoginUserManage)
)
