import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  setupOneTeamCreate,
  setupOneTeamUpdate,
  setupOneTeamDelete,
  setupOneTeamCapacityCreate,
  setupOneTeamCapacityUpdate,
  resetTeamCreation,
  setupOneTeamGet,
  setupAllTeamsGet,
  setupAllServicesGet,
  allTeamListGet
} from '../../modules/setup-teams'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { setupAllStaffGet } from '../../modules/setup-staff'
import {
  setTooltipContent,
  openTooltip,
  closeTooltip
} from '../../modules/tooltip'
import { openModal, closeModal, setModalContent } from '../../modules/modal'
import SetupTeamsMain from '../../parents/setup-teams-main'
import BigLoading from '../../components/big-loading'

const SetupTeams = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <SetupTeamsMain {...props} />
  }

  return <BigLoading />
}

SetupTeams.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  setupTeams: PropTypes.object,
  setupVendors: PropTypes.object,
  setupStaff: PropTypes.object,
  tooltip: PropTypes.object,
  modal: PropTypes.object
}

const mapStateToProps = ({
  user,
  context,
  setupTeams,
  setupVendors,
  setupStaff,
  tooltip,
  modal
}) => ({
  user,
  context,
  setupTeams,
  setupVendors,
  setupStaff,
  tooltip,
  modal
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setupAllTeamsGet,
      setupOneTeamGet,
      setupOneTeamCreate,
      setupOneTeamUpdate,
      setupOneTeamDelete,
      setupOneTeamCapacityCreate,
      setupOneTeamCapacityUpdate,
      resetTeamCreation,
      setupAllServicesGet,
      setupAllVendorsGet,
      setupAllStaffGet,
      openModal,
      closeModal,
      setModalContent,
      openTooltip,
      closeTooltip,
      setTooltipContent,
      allTeamListGet
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SetupTeams)
)
