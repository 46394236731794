import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getInspectionList,
  getInspectionMedia,
  getInspectionFormList,
  getInspectionUnitList,
  getInspectionDownloadMediaURL,
  addNewInspection,
  getInspectionAssignToList,
  getInspectionPDFURL,
  editNewInspection,
  bulkUpdateInspectionsProfile,
  getFilteredInspectionList,
  downloadInspectionMedia
} from '../../modules/inspect'
import InspectionDashboardMain from '../../parents/inspection-dashboard-main'
import BigLoading from '../../components/big-loading'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { getDownloadState } from '../../modules/download-inspections'

const Inspections = props => {
  const { user } = props
  const { hasMadeInitialRequest, isRequesting } = user || {}
  if (hasMadeInitialRequest && !isRequesting) {
    return <InspectionDashboardMain {...props} />
  }

  return <BigLoading />
}

Inspections.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, inspect }) => ({
  user,
  context,
  modal,
  inspect
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      setModalContent,
      getInspectionList,
      getInspectionMedia,
      getInspectionFormList,
      getInspectionUnitList,
      getInspectionDownloadMediaURL,
      addNewInspection,
      getInspectionAssignToList,
      getInspectionPDFURL,
      editNewInspection,
      bulkUpdateInspectionsProfile,
      getFilteredInspectionList,
      downloadInspectionMedia,
      getDownloadState
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Inspections)
)
