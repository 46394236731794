import _ from 'lodash'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSquare,
  faCaretUp,
  faCaretDown
} from '@fortawesome/free-solid-svg-icons'
import Short from 'short-uuid'
import { SUMMARY_CARDS } from './constants'
import { currencyFormatter } from '../../utils'

const getDifference = difference => {
  if (difference > 0) {
    return (
      <div
        className="columns is-vcentered green-color"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '0.1rem',
          marginLeft: '0.1rem'
        }}>
        <div className="column is-size-5 is-narrow p-r-none">
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <div className="column small-text is-narrow p-l-xs">
          {currencyFormatter(difference)}
        </div>
      </div>
    )
  } else if (difference < 0) {
    return (
      <div
        className="columns is-vcentered red-color"
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '0.1rem',
          marginLeft: '0.1rem'
        }}>
        <div className="column is-size-5 is-narrow p-r-none">
          <FontAwesomeIcon icon={faCaretUp} />
        </div>
        <div className="column is-size-7 is-narrow p-l-xs">
          {currencyFormatter(Math.abs(difference))}
        </div>
      </div>
    )
  }
  return (
    <div
      className="columns is-vcentered faded-color"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '0.1rem'
      }}>
      <div className="column is-size-7 is-narrow p-r-none">
        <FontAwesomeIcon icon={faSquare} />
      </div>
      <div className="column is-size-7 is-narrow p-l-xs">
        {currencyFormatter(difference)}
      </div>
    </div>
  )
}

/**
 * Single service with costs vs. budget box
 *
 * @component
 * @example
 * return (
 *   <SingleService
 *     service={{
 *       service: 'Paint',
 *       budget: 75000,
 *       difference: 25000,
 *       actual: 50000
 *     }}
 *   />
 * )
 */
const SingleService = service => (
  <Fragment key={Short.uuid()}>
    <p style={{ paddingLeft: '20px', paddingBottom: '8px' }}>
      {_.get(
        _.find(SUMMARY_CARDS, c => c.id === service.service),
        'label',
        service.service
      )}
    </p>
    <table
      className="bottom-separator"
      key={Short.uuid()}
      style={{ width: '100%', marginLeft: '0.5rem', marginRight: '1rem' }}>
      <tbody>
        <tr>
          <td className="is-half heading-5 has-text-centered">
            {currencyFormatter(service.projected)}
          </td>
          <td className="is-half heading-5 has-text-centered">
            {currencyFormatter(service.actual)}
          </td>
          <td className="is-half heading-5 has-text-centered">
            {currencyFormatter(service.budget)}
          </td>
        </tr>
        <tr>
          <td className="is-half small-text semi-faded-color has-text-centered">
            Projected
          </td>
          <td className="is-half small-text semi-faded-color has-text-centered">
            Actual
          </td>
          <td className="is-half small-text semi-faded-color has-text-centered">
            Budget
          </td>
        </tr>
        <tr>
          <td colSpan="3" className="has-text-centered">
            {getDifference(service.difference)}
          </td>
        </tr>
      </tbody>
    </table>
  </Fragment>
)

/**
 * Costs by service with difference indicators
 *
 * @component
 * @example
 * return (
 *   <CostsByService
 *     costs={[
 *       {
 *         service: 'Paint',
 *         budget: 75000,
 *         difference: 25000,
 *         actual: 50000
 *       },
 *       {
 *         service: 'Clean',
 *         budget: 50000,
 *         difference: -10000,
 *         actual: 60000
 *       },
 *       {
 *         service: 'CP Rep',
 *         budget: 20000,
 *         difference: 20000,
 *         actual: 0
 *       }
 *     ]}
 *   />
 * )
 */
const CostsByService = ({ costs }) => {
  return (
    <div className="dashboard-card card-fill">
      <div className="inner-content" style={{ paddingRight: '2rem' }}>
        <p className="is-size-4-fullhd heading-5 p-b-md">Costs by Service</p>
        {_.map(costs, cost => SingleService(cost))}
      </div>
    </div>
  )
}

CostsByService.propTypes = {
  /**
   * Array with the costs on the current property
   */
  costs: PropTypes.array
}

export default CostsByService
