import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { formatDateString } from '../utils'
import context from './context'
import { getDownloadState } from './download-inspections'
import { setAlert } from './alerts'
import { removeExtraWhiteSpaces } from '../utils/helperFunctions'

/**
 * Holdovers data module
 * @redux
 * @reduxActionScope holdovers
 * @module holdovers
 */

/**
 * Request inspect list
 * @type {Redux.ActionType}
 */
export const INSPECT_LIST_REQUESTED = 'inspect/INSPECT_LIST_REQUESTED'
/**
 * Inspect list failed
 * @type {Redux.ActionType}
 */
export const INSPECT_LIST_FAIL = 'inspect/INSPECT_LIST_FAIL'
/**
 * Update requested inspect list with successful data from API
 * @type {Redux.ActionType}
 */
export const INSPECT_LIST_SUCCESS = 'inspect/INSPECT_LIST_SUCCESS'
/**
 * Inspect list reset
 * @type {Redux.ActionType}
 */
export const INSPECT_LIST_RESET = 'inspect/INSPECT_LIST_RESET'

/**
 * Request inspect stats
 * @type {Redux.ActionType}
 */
export const INSPECT_STATS_REQUESTED = 'inspect/INSPECT_STATS_REQUESTED'
/**
 * Inspect stats failed
 * @type {Redux.ActionType}
 */
export const INSPECT_STATS_FAIL = 'inspect/INSPECT_STATS_FAIL'
/**
 * Update requested inspect stats with successful data from API
 * @type {Redux.ActionType}
 */
export const INSPECT_STATS_SUCCESS = 'inspect/INSPECT_STATS_SUCCESS'
/**
 * Inspect stats reset
 * @type {Redux.ActionType}
 */
export const INSPECT_STATS_RESET = 'inspect/INSPECT_STATS_RESET'

/**
 * Request all services
 * @type {Redux.ActionType}
 */
export const ALL_SERVICES_REQUESTED = 'inspect/ALL_SERVICES_REQUESTED'
/**
 * All services failed
 * @type {Redux.ActionType}
 */
export const ALL_SERVICES_FAIL = 'inspect/ALL_SERVICES_FAIL'
/**
 * Update requested all services with successful data from API
 * @type {Redux.ActionType}
 */
export const ALL_SERVICES_SUCCESS = 'inspect/ALL_SERVICES_SUCCESS'

/**
 * Request inspections sync
 * @type {Redux.ActionType}
 */
export const INSPECT_SYNC_REQUESTED = 'inspect/INSPECT_SYNC_REQUESTED'
/**
 * Inspect sync failed
 * @type {Redux.ActionType}
 */
export const INSPECT_SYNC_FAIL = 'inspect/INSPECT_SYNC_FAIL'
/**
 * Update requested inspection sync with successful data from API
 * @type {Redux.ActionType}
 */
export const INSPECT_SYNC_SUCCESS = 'inspect/INSPECT_SYNC_SUCCESS'

/**
 * Request inspections report
 * @type {Redux.ActionType}
 */
export const REPORT_REQUESTED = 'inspect/REPORT_REQUESTED'
/**
 * Inspect report failed
 * @type {Redux.ActionType}
 */
export const REPORT_FAIL = 'inspect/REPORT_FAIL'
/**
 * Update requested report id with successful data from API
 * @type {Redux.ActionType}
 */
export const REPORT_SET_ID = 'inspect/REPORT_SET_ID'
/**
 * Update requested report data with successful data from API
 * @type {Redux.ActionType}
 */
export const REPORT_SUCCESS = 'inspect/REPORT_SUCCESS'
/**
 * Request for inspect service options
 * @type {Redux.ActionType}
 */
export const INSPECT_SET_SERVICE_OPTIONS_REQUESTED =
  'inspect/INSPECT_SET_SERVICE_OPTIONS_REQUESTED'
/**
 * Request for inspect service options was successful
 * @type {Redux.ActionType}
 */
export const INSPECT_SET_SERVICE_OPTIONS_SUCCESS =
  'inspect/INSPECT_SET_SERVICE_OPTIONS_SUCCESS'
/**
 * Request for inspect service options failed
 * @type {Redux.ActionType}
 */
export const INSPECT_SET_SERVICE_OPTIONS_FAIL =
  'inspect/INSPECT_SET_SERVICE_OPTIONS_FAIL'
/**
 * Reset inspect service options
 * @type {Redux.ActionType}
 */
export const INSPECT_SET_SERVICE_OPTIONS_RESET =
  'inspect/INSPECT_SET_SERVICE_OPTIONS_RESET'

/**
 * Request inspect service options
 * @type {Redux.ActionType}
 */
export const INSPECT_SERVICE_OPTIONS_REQUESTED =
  'turnboard2/INSPECT_SERVICE_OPTIONS_REQUESTED'

/**
 * Request inspect service options was successful
 * @type {Redux.ActionType}
 */
export const INSPECT_SERVICE_OPTIONS_SUCCESS =
  'turnboard2/INSPECT_SERVICE_OPTIONS_SUCCESS'

/**
 * Request inspect service options failed
 * @type {Redux.ActionType}
 */
export const INSPECT_SERVICE_OPTIONS_FAIL =
  'turnboard2/INSPECT_SERVICE_OPTIONS_FAIL'

/**
 * Reset inspect service options
 * @type {Redux.ActionType}
 */
export const INSPECT_SERVICE_OPTIONS_RESET =
  'turnboard2/INSPECT_SERVICE_OPTIONS_RESET'

export const REQUEST_LIST_INSPECTION_ITEMS = 'REQUEST_LIST_INSPECTION_ITEMS'
export const REQUEST_LIST_INSPECTION_ITEMS_SUCCESS =
  'REQUEST_LIST_INSPECTION_ITEMS_SUCCESS'
export const REQUEST_LIST_INSPECTION_ITEMS_FAIL =
  'REQUEST_LIST_INSPECTION_ITEMS_FAIL'
export const LIST_INSPECTION_MEDIA = 'LIST_INSPECTION_MEDIA'
export const LIST_INSPECTION_FORMS = 'LIST_INSPECTION_FORMS'
export const LIST_INSPECTION_UNITS = 'LIST_INSPECTION_UNITS'
export const LIST_INSPECTION_ASSIGN_TO = 'LIST_INSPECTION_ASSIGN_TO'
export const DOWNLOAD_INSPECTION_PDF_URL = 'DOWNLOAD_INSPECTION_PDF_URL'
export const ADD_NEW_INSPECTION = 'ADD_NEW_INSPECTION'
export const DOWNLOAD_INSPECTION_MEDIA_URL = 'DOWNLOAD_INSPECTION_MEDIA_URL'
export const UPLOAD_INSPECTION_MEDIA_URL = 'UPLOAD_INSPECTION_MEDIA_URL'
export const REQUEST_GET_INSPECTION_ITEM = 'REQUEST_GET_INSPECTION_ITEM'
export const REQUEST_GET_INSPECTION_ITEM_SUCCESS =
  'REQUEST_GET_INSPECTION_ITEM_SUCCESS'
export const REQUEST_GET_INSPECTION_ITEM_FAIL =
  'REQUEST_GET_INSPECTION_ITEM_FAIL'
export const RESET_GET_INSPECTION_ITEM = 'RESET_GET_INSPECTION_ITEM'

export const DOWNLOAD_INSPECTION_MEDIA_REQUESTED =
  'DOWNLOAD_INSPECTION_MEDIA_REQUESTED'
export const DOWNLOAD_INSPECTION_MEDIA_SUCCESS =
  'DOWNLOAD_INSPECTION_MEDIA_SUCCESS'
export const DOWNLOAD_INSPECTION_MEDIA_FAIL = 'DOWNLOAD_INSPECTION_MEDIA_FAIL'

const initialState = {
  listHasRequested: false,
  listIsRequesting: false,
  listIsError: false,
  list: [],
  services: [],
  statsHasRequested: false,
  statsIsRequesting: false,
  statsIsError: false,
  stats: [],
  syncHasRequested: false,
  syncIsRequesting: false,
  syncIsError: false,
  inspectionSource: '',
  lastSync: null,
  reportHasRequested: false,
  reportIsRequesting: false,
  reportIsError: false,
  reportId: null,
  reportData: {},
  serviceOptionsHasRequested: false,
  serviceOptionsIsRequesting: false,
  serviceOptionsIsError: false,
  serviceOptions: null,
  isRequestingUpdate: false,
  inspectionList: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  singleInspection: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  inspectionFormList: [],
  inspectionUnitList: [],
  inspectionMediaList: [],
  inspectionDownloadMediaURL: '',
  inspectionUploadMediaURL: '',
  inspectionAssignToList: [],
  inspectionPDFURL: {},
  downloadInspectionMediaRequested: false,
  downloadInspectionMediaError: false
}

/**
 * Inspect board reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case INSPECT_LIST_REQUESTED:
      return {
        ...state,
        list: [],
        listHasRequested: true,
        listIsRequesting: true,
        listIsError: false
      }
    case INSPECT_LIST_SUCCESS:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: false,
        list: action.list,
        services: action.services
      }
    case INSPECT_LIST_FAIL:
      return {
        ...state,
        listIsRequesting: false,
        listIsError: true
      }
    case INSPECT_LIST_RESET:
      return {
        ...state,
        listHasRequested: false,
        listIsRequesting: false,
        listIsError: false,
        list: [],
        services: []
      }
    case INSPECT_STATS_REQUESTED:
      return {
        ...state,
        statsHasRequested: true,
        statsIsRequesting: true,
        statsIsError: false
      }
    case INSPECT_STATS_SUCCESS:
      return {
        ...state,
        statsIsRequesting: false,
        statsIsError: false,
        stats: action.stats
      }
    case INSPECT_STATS_FAIL:
      return {
        ...state,
        statsIsRequesting: false,
        statsIsError: true
      }
    case INSPECT_STATS_RESET:
      return {
        ...state,
        statsHasRequested: false,
        statsIsRequesting: false,
        statsIsError: false,
        stats: []
      }
    case LIST_INSPECTION_FORMS: {
      const { data } = action || {}
      return {
        ...state,
        inspectionFormList: data || []
      }
    }
    case LIST_INSPECTION_UNITS: {
      const { data } = action || {}
      return {
        ...state,
        inspectionUnitList: data || []
      }
    }
    case LIST_INSPECTION_ASSIGN_TO: {
      const { data } = action || {}
      return {
        ...state,
        inspectionAssignToList: data || []
      }
    }
    case LIST_INSPECTION_MEDIA: {
      const { data } = action || {}
      return {
        ...state,
        inspectionMediaList: data || []
      }
    }
    case DOWNLOAD_INSPECTION_MEDIA_URL: {
      const { data } = action || {}
      return {
        ...state,
        inspectionDownloadMediaURL: data || []
      }
    }
    case DOWNLOAD_INSPECTION_PDF_URL: {
      const { data } = action || {}
      return {
        ...state,
        inspectionPDFURL: data || {}
      }
    }
    case UPLOAD_INSPECTION_MEDIA_URL: {
      const { data } = action || {}
      return {
        ...state,
        inspectionUploadMediaURL: data || []
      }
    }
    case REQUEST_GET_INSPECTION_ITEM:
      return {
        ...state,
        singleInspection: {
          ...state.singleInspection,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_LIST_INSPECTION_ITEMS:
      return {
        ...state,
        inspectionList: {
          ...state.inspectionList,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_LIST_INSPECTION_ITEMS_SUCCESS: {
      const { data } = action || {}
      return {
        ...state,
        inspectionList: {
          ...state.inspectionList,
          isRequesting: false,
          isError: false,
          data
        }
      }
    }
    case REQUEST_LIST_INSPECTION_ITEMS_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        inspectionList: {
          ...state.inspectionList,
          isRequesting: false,
          isError: true,
          data
        }
      }
    }
    case RESET_GET_INSPECTION_ITEM:
      return {
        ...state,
        singleInspection: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          data: []
        }
      }
    case REQUEST_GET_INSPECTION_ITEM_SUCCESS: {
      const { data } = action || {}
      return {
        ...state,
        singleInspection: {
          ...state.singleInspection,
          isRequesting: false,
          isError: false,
          data
        }
      }
    }
    case REQUEST_GET_INSPECTION_ITEM_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        singleInspection: {
          ...state.singleInspection,
          isRequesting: false,
          isError: true,
          data
        }
      }
    }
    case INSPECT_SYNC_REQUESTED:
      return {
        ...state,
        syncIsRequesting: true,
        syncIsError: false
      }
    case INSPECT_SYNC_SUCCESS:
      return {
        ...state,
        syncIsRequesting: false,
        syncIsError: false,
        inspectionSource: action.source,
        lastSync: action.date,
        status: action.status,
        errorMessage:
          action.errorMessage && action.errorMessage.length > 0
            ? action.errorMessage
                .split(' ')
                .map((item, index) => {
                  if (index % 5 === 0 && index > 0) {
                    return `${item}\n`
                  }

                  return `${item}\xa0`
                })
                .join('')
            : action.errorMessage
      }
    case INSPECT_SYNC_FAIL:
      return {
        ...state,
        syncIsRequesting: false,
        syncIsError: true
      }
    case REPORT_REQUESTED:
      return {
        ...state,
        reportHasRequested: true,
        reportIsRequesting: true,
        reportIsError: false
      }
    case REPORT_SET_ID: {
      return {
        ...state,
        reportIsRequesting: false,
        reportId: action.id
      }
    }
    case REPORT_SUCCESS:
      return {
        ...state,
        reportIsRequesting: false,
        reportIsError: false,
        reportData: action.data
      }
    case REPORT_FAIL:
      return {
        ...state,
        reportIsRequesting: false,
        reportIsError: true
      }
    case INSPECT_SERVICE_OPTIONS_REQUESTED:
      return {
        ...state,
        serviceOptionsHasRequested: true,
        serviceOptionsIsRequesting: true,
        serviceOptionsIsError: false
      }
    case INSPECT_SERVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        serviceOptionsIsRequesting: false,
        serviceOptionsIsError: false,
        serviceOptions: action.serviceOptions
      }
    case INSPECT_SERVICE_OPTIONS_FAIL:
      return {
        ...state,
        serviceOptionsIsRequesting: false,
        serviceOptionsIsError: true
      }
    case INSPECT_SERVICE_OPTIONS_RESET:
      return {
        ...state,
        serviceOptionsHasRequested: false,
        serviceOptionsIsRequesting: false,
        serviceOptionsIsError: false,
        serviceOptions: null
      }
    case INSPECT_SET_SERVICE_OPTIONS_REQUESTED:
      return {
        ...state,
        isRequestingUpdate: true,
        isErrorUpdate: false
      }
    case INSPECT_SET_SERVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: false
      }
    case INSPECT_SET_SERVICE_OPTIONS_FAIL:
      return {
        ...state,
        isRequestingUpdate: false,
        isErrorUpdate: true
      }
    case INSPECT_SET_SERVICE_OPTIONS_RESET:
      return {
        ...state,
        serviceOptionsHasRequested: false
      }
    case DOWNLOAD_INSPECTION_MEDIA_REQUESTED:
      return {
        ...state,
        downloadInspectionMediaRequested: true,
        downloadInspectionMediaError: false
      }
    case DOWNLOAD_INSPECTION_MEDIA_SUCCESS:
      return {
        ...state,
        downloadInspectionMediaRequested: false,
        downloadInspectionMediaError: false
      }
    case DOWNLOAD_INSPECTION_MEDIA_FAIL:
      return {
        ...state,
        downloadInspectionMediaRequested: false,
        downloadInspectionMediaError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const getInspectionMedia = ({ data, onSuccess, onError }) => {
  const { pdbid, context, inspectionId } = data || {}
  return dispatch => {
    Service.getInspectionMedia(pdbid, context, inspectionId).then(async res => {
      if (res.ok) {
        const data = await res.json()
        dispatch({
          type: LIST_INSPECTION_MEDIA,
          data: data || []
        })
        if (onSuccess) {
          onSuccess()
        }
      }
    })
  }
}

export const getInspectionList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: REQUEST_LIST_INSPECTION_ITEMS
    })
    Service.getInspectionList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const updatedJson = (json || []).map(data => {
            const { inspection_lines, inspection_date } = data || {}
            let allNotes = []
            ;(inspection_lines || []).map(inspection => {
              const { items, location } = inspection || {}
              ;(items || []).map(item => {
                const { line_note, item: currentItem, observations, state } =
                  item || {}
                let getObservation = 'N/A'
                if (state !== null && state !== 'Good') {
                  if (observations !== null) {
                    getObservation =
                      (observations || []).length !== 0
                        ? observations[0].observation
                        : 'N/A'
                  }
                }
                if (line_note !== null) {
                  data.has_notes = true
                  allNotes.push({
                    Space: location || '',
                    Item: currentItem || '',
                    Observation: getObservation || 'N/A',
                    Message: line_note || ''
                  })
                }
              })
            })
            data.allNotes = allNotes || []
            data.rawInspectionDate = data.inspection_date
            data.inspection_date = inspection_date
              ? formatDateString(inspection_date, 'MM/DD/YYYY')
              : 'N/A'
            return data
          })
          dispatch({
            type: REQUEST_LIST_INSPECTION_ITEMS_SUCCESS,
            data: updatedJson || []
          })
        } else {
          dispatch({
            type: REQUEST_LIST_INSPECTION_ITEMS_FAIL,
            data: []
          })
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_LIST_INSPECTION_ITEMS_FAIL,
          data: []
        })
      })
  }
}

export const getFilteredInspectionList = (
  pdbid,
  context,
  to,
  from,
  onSuccess,
  onError
) => {
  return async dispatch => {
    dispatch({
      type: REQUEST_LIST_INSPECTION_ITEMS
    })
    try {
      const [
        inspectionFilteredListResponse,
        inspectionMediaListResponse
      ] = await Promise.all([
        Service.getFilteredInspectionList(pdbid, context, to, from),
        Service.getFilteredInspectionMediaList(pdbid, context, to, from)
      ])
      if (inspectionFilteredListResponse.ok && inspectionMediaListResponse.ok) {
        const inspectionFilteredListResponseJson = await inspectionFilteredListResponse.json()
        const inspectionMediaListResponseJson = await inspectionMediaListResponse.json()
        const updatedJson = (inspectionFilteredListResponseJson || []).map(
          data => {
            const { inspection_lines, inspection_date, inspection_id } =
              data || {}
            let allNotes = []
            let hasMedia = false
            if (inspectionMediaListResponseJson?.length > 0) {
              for (const mediaData of inspectionMediaListResponseJson) {
                const { observations } = mediaData || {}
                if (
                  mediaData?.inspection_id === inspection_id &&
                  Array.isArray(observations)
                ) {
                  for (const line of observations) {
                    if (line?.images?.length > 0) {
                      hasMedia = true
                      break
                    }
                  }
                }
                if (hasMedia) break
              }
            }
            ;(inspection_lines || []).map(inspection => {
              const { items, location } = inspection || {}
              ;(items || []).map(item => {
                const { line_note, item: currentItem, observations, state } =
                  item || {}
                let getObservation = 'N/A'
                if (state !== null && state !== 'Good') {
                  if (observations !== null) {
                    getObservation =
                      (observations || []).length !== 0
                        ? observations[0].observation
                        : 'N/A'
                  }
                }
                if (line_note !== null) {
                  data.has_notes = true
                  allNotes.push({
                    Space: location || '',
                    Item: currentItem || '',
                    Observation: getObservation || 'N/A',
                    Message: line_note || ''
                  })
                }
              })
            })
            data.allNotes = allNotes || []
            data.rawInspectionDate = data?.inspection_date || ''
            data.inspection_date = inspection_date
              ? formatDateString(inspection_date, 'MM/DD/YYYY')
              : 'N/A'
            data.type = (data.type || '').trim()
            data.hasMedia = hasMedia
            return data
          }
        )
        dispatch({
          type: REQUEST_LIST_INSPECTION_ITEMS_SUCCESS,
          data: updatedJson || []
        })
        if (onSuccess) {
          onSuccess()
        } else {
          dispatch({
            type: REQUEST_LIST_INSPECTION_ITEMS_FAIL,
            data: []
          })
          if (onError) {
            onError()
          }
        }
        return
      } else {
        dispatch({
          type: REQUEST_LIST_INSPECTION_ITEMS_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      }
    } catch (error) {
      dispatch({
        type: REQUEST_LIST_INSPECTION_ITEMS_FAIL,
        data: []
      })
      if (onError) {
        onError()
      }
    }
  }
}

export const getInspectionItem = (pdbid, context, inspection_id) => {
  return dispatch => {
    dispatch({
      type: REQUEST_GET_INSPECTION_ITEM
    })
    Service.getInspectionItem(pdbid, context, inspection_id)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          dispatch({
            type: REQUEST_GET_INSPECTION_ITEM_SUCCESS,
            data: json
          })
        } else {
          dispatch({
            type: REQUEST_GET_INSPECTION_ITEM_FAIL,
            data: []
          })
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_GET_INSPECTION_ITEM_FAIL,
          data: []
        })
      })
  }
}

const sortList = (list, uniqueKey, sortKey) => {
  const arrayUniqueByKey = [
    ...new Map((list || []).map(item => [item[uniqueKey], item])).values()
  ]

  return (arrayUniqueByKey || [])?.sort((a, b) => {
    const valueOne = removeExtraWhiteSpaces(a?.[sortKey] || '')
    const valueTwo = removeExtraWhiteSpaces(b?.[sortKey] || '')

    return valueOne.localeCompare(valueTwo, 'en', { numeric: true })

    // const x = a?.[sortKey]?.toLowerCase()
    // const y = b?.[sortKey]?.toLowerCase()
    // if (x > y) return 1
    // if (x < y) return -1
    // return 0
  })
}

export const getInspectionFormList = (pdbid, context, onSuccess, onError) => {
  return dispatch => {
    Service.getInspectionFormList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const sortedJson = sortList(json, 'form_id', 'name')
          dispatch({
            type: LIST_INSPECTION_FORMS,
            data: sortedJson || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        console.log({
          error
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const getInspectionUnitList = (pdbid, context) => {
  return dispatch => {
    Service.getInspectionUnitList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          dispatch({
            type: LIST_INSPECTION_UNITS,
            data: json || []
          })
        }
      })
      .catch(error => {
        console.log({
          error
        })
      })
  }
}

export const getInspectionDownloadMediaURL = (pdbid, context) => {
  return dispatch => {
    Service.getInspectionDownloadMediaURL(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const URL = json?.parURL || ''
          dispatch({
            type: DOWNLOAD_INSPECTION_MEDIA_URL,
            data: URL || ''
          })
        }
      })
      .catch(error => {
        console.log({
          error
        })
      })
  }
}

export const getInspectionPDFURL = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.getInspectionPDFURL(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const json = await res.json()
          const { url } = json || {}
          dispatch({
            type: DOWNLOAD_INSPECTION_PDF_URL,
            data: url || ''
          })
          if (onSuccess) {
            onSuccess()
          } else {
            if (onError) {
              onError()
            }
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const getInspectionUploadMediaURL = (pdbid, context) => {
  return dispatch => {
    Service.getInspectionUploadMediaURL(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const URL = json?.parURL || ''
          dispatch({
            type: UPLOAD_INSPECTION_MEDIA_URL,
            data: URL || ''
          })
        }
      })
      .catch(error => {
        console.log({
          error
        })
      })
  }
}

export const addInspectionMedia = (url, body, contentType) => dispatch => {
  return new Promise(function(resolve, reject) {
    Service.addInspectionMedia(url, body, contentType)
      .then(res => {
        if (res.ok) {
          resolve('success')
        } else {
          reject('error')
        }
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const addNewInspection = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.addNewInspection(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const editNewInspection = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError,
  inspectionId
}) => {
  return dispatch => {
    Service.editNewInspection(pdbid, context, body, inspectionId)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}

export const updateInspectionItem = ({
  pdbid,
  context,
  body,
  inspection_id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.updateInspectionItem(pdbid, context, body, inspection_id)
      .then(async res => {
        const { ok } = res || {}
        const json = await res.json()
        if (ok) {
          dispatch({
            type: REQUEST_GET_INSPECTION_ITEM_SUCCESS,
            data: json
          })
          if (onSuccess) {
            onSuccess()
          }
        } else {
          if (onError) {
            onError()
          }
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const getInspectionAssignToList = (pdbid, context) => {
  return dispatch => {
    Service.getInspectionAssignToList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          const sortedData = sortList(data, 'email', 'name')
          const filterData = (sortedData || []).map(user => {
            const { name, user_id, auth_id } = user || {}
            return {
              name,
              user_id,
              auth_id
            }
          })
          dispatch({
            type: LIST_INSPECTION_ASSIGN_TO,
            data: filterData || []
          })
        }
      })
      .catch(error => {
        console.log({
          error
        })
      })
  }
}

export const bulkUpdateInspectionsProfile = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.bulkUpdateInspectionsProfile(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const resetGetInspectionItem = () => {
  return dispatch => {
    dispatch({ type: RESET_GET_INSPECTION_ITEM })
  }
}

/**
 * Gets inspect board unit list
 * @redux
 * @reduxActionCreator INSPECT_LIST_REQUESTED, INSPECT_LIST_SUCCESS, INSPECT_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getInspectList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: INSPECT_LIST_REQUESTED
    })
    Service.getInspectList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          dispatch({
            type: INSPECT_LIST_SUCCESS,
            source: _.get(json, 'inspection_source', 'Unavailable'), // NOTE: This and the following field depend on the actual data returned by the API. We're assuming it will be like this
            date: json.last_updated ? new Date(json.last_updated) : null,
            list: _listAdapter(json.inspections.inspection),
            services: _servicesAdapter(json.inspections.services)
          })
        } else {
          dispatch({
            type: INSPECT_LIST_FAIL
          })
        }
      })
      .catch(e => {
        dispatch({
          type: INSPECT_LIST_FAIL
        })
      })
  }
}

/**
 * Gets inspect stats
 * @redux
 * @reduxActionCreator INSPECT_STATS_REQUESTED, INSPECT_STATS_SUCCESS, INSPECT_STATS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getInspectStats = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: INSPECT_STATS_REQUESTED
    })
    Service.getInspectStats(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: INSPECT_STATS_SUCCESS,
            stats: _statsAdapter(data)
          })
        } else {
          dispatch({
            type: INSPECT_STATS_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: INSPECT_STATS_FAIL
        })
      })
  }
}

/**
 * Request inspections sync
 * @redux
 * @reduxActionCreator INSPECT_SYNC_REQUESTED
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const syncInspectData = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: INSPECT_SYNC_REQUESTED
    })
    Service.syncInspect(pdbid, context)
  }
}

/**
 * Check sync status
 * @redux
 * @reduxActionCreator INSPECT_SYNC_SUCCESS, INSPECT_SYNC_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const checkSyncData = (pdbid, context) => {
  return dispatch => {
    Service.checkSyncInspect(pdbid, context).then(async res => {
      if (res.ok) {
        try {
          const json = await res.json()
          const statusData = json['items'][0]
          dispatch({
            type: INSPECT_SYNC_SUCCESS,
            source: statusData.inspect_source,
            date: statusData.last_updated
              ? new Date(statusData.last_updated)
              : new Date(),
            status: statusData.status || 'complete',
            errorMessage: statusData.error_message
          })
        } catch (err) {
          dispatch({
            type: INSPECT_SYNC_FAIL
          })
        }
      }
    })
  }
}

/**
 * Request print report
 * @redux
 * @reduxActionCreator INSPECT_SYNC_REQUESTED
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const printReport = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: REPORT_REQUESTED
    })
    Service.requestInspectReport(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          //const data = res.json()
          const data = {
            report_id: 'rep102030'
          }
          dispatch({
            type: REPORT_SET_ID,
            id: data.report_id
          })
        } else {
          dispatch({
            type: REPORT_FAIL
          })
        }
      })
      .catch(() => {
        dispatch({
          type: REPORT_FAIL
        })
      })
  }
}

/**
 * Get report data, if available
 * @redux
 * @reduxActionCreator INSPECT_SYNC_SUCCESS, INSPECT_SYNC_SUCCESS
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getPrintReport = (pdbid, context, reportId) => {
  return dispatch => {
    Service.checkInspectReport(pdbid, context, reportId).then(async res => {
      if (res.ok) {
        const json = {
          status: 'true',
          data: {}
        }
        //const json = await res.json()
        // NOTE: Again, we're assuming this will be the case
        if (json.status === 'true')
          dispatch({
            type: REPORT_SUCCESS,
            data: json.data
          })
        else if (json.status === 'false') {
          dispatch({
            type: REPORT_FAIL
          })
        }
      }
    })
  }
}

/**
 * Set turnboard service options
 * @redux
 * @reduxActionCreator INSPECT_SET_SERVICE_OPTIONS_REQUESTED, INSPECT_SET_SERVICE_OPTIONS_SUCCESS, INSPECT_SET_SERVICE_OPTIONS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitSpaceId - the unitSpaceId to be requested
 * @param {string} serviceId - the serviceId to be requested
 * @param {object} body - request body
 */
export const setInspectServiceOptions = (
  pdbid,
  context,
  unitSpaceId,
  serviceId,
  body
) => {
  return dispatch => {
    dispatch({
      type: INSPECT_SET_SERVICE_OPTIONS_REQUESTED
    })
    Service.setTurnboard2ServiceOptions(
      pdbid,
      context,
      unitSpaceId,
      serviceId,
      body
    )
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: INSPECT_SET_SERVICE_OPTIONS_SUCCESS,
            serviceOptions: data
          })
        } else {
          dispatch({
            type: INSPECT_SET_SERVICE_OPTIONS_FAIL
          })
        }
      })
      .catch(err => {
        dispatch({
          type: INSPECT_SET_SERVICE_OPTIONS_FAIL
        })
      })
  }
}

/**
 * Gets turnboard service options
 * @redux
 * @reduxActionCreator INSPECT_SERVICE_OPTIONS_REQUESTED, INSPECT_SERVICE_OPTIONS_SUCCESS, INSPECT_SERVICE_OPTIONS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} unitSpaceId - the unitSpaceId to be requested
 * @param {string} serviceId - the serviceId to be requested
 */
export const getInspectServiceOptions = (
  pdbid,
  context,
  unitSpaceId,
  serviceId
) => {
  return dispatch => {
    dispatch({
      type: INSPECT_SERVICE_OPTIONS_REQUESTED
    })
    Service.getTurnboard2ServiceOptions(pdbid, context, unitSpaceId, serviceId)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: INSPECT_SERVICE_OPTIONS_SUCCESS,
            serviceOptions: data
          })
        } else {
          dispatch({
            type: INSPECT_SERVICE_OPTIONS_FAIL
          })
        }
      })
      .catch(err => {
        dispatch({
          type: INSPECT_SERVICE_OPTIONS_FAIL
        })
      })
  }
}

/**
 * Reset service options state
 * @redux
 * @reduxActionCreator INSPECT_SERVICE_OPTIONS_RESET
 */
export const resetInspectServiceOptions = () => {
  return dispatch => {
    dispatch({ type: INSPECT_SERVICE_OPTIONS_RESET })
  }
}

/**
 * Gets turnboard service options
 * @redux
 * @reduxActionCreator INSPECT_SERVICE_OPTIONS_REQUESTED, INSPECT_SERVICE_OPTIONS_SUCCESS, INSPECT_SERVICE_OPTIONS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} data - the data to submit
 * @param {string} onSuccess - success callback to fire
 * @param {string} onFail - Failure callback to fire
 */

export const downloadInspectionMedia = (
  pdbid,
  context,
  data,
  onSuccess,
  onFail
) => {
  return async dispatch => {
    dispatch({
      type: DOWNLOAD_INSPECTION_MEDIA_REQUESTED
    })

    dispatch(setAlert('Download has started!', 'success'))

    try {
      const res = await Service.downloadInspectionMedia(pdbid, context, data)

      if (res.ok) {
        onSuccess && onSuccess()
        dispatch({
          type: DOWNLOAD_INSPECTION_MEDIA_SUCCESS
        })

        return dispatch(getDownloadState(pdbid, context))
      }

      if (res.status === 500) {
        dispatch(setAlert('Download has Failed!', 'danger'))
        onFail && onFail()
      }

      dispatch({
        type: DOWNLOAD_INSPECTION_MEDIA_FAIL
      })
    } catch (error) {
      if (error?.response?.status === 500) {
        dispatch(setAlert('Download has Failed!', 'danger'))
        onFail && onFail()
      }
      dispatch({
        type: DOWNLOAD_INSPECTION_MEDIA_FAIL
      })
    }
  }
}
// Adapters

const DEFAULT_VALUE = 0

const _iscarpet = unit_space =>
  (_.get(unit_space, 'Carpet Replace', '') || '').toLowerCase() === 'replace' &&
  ['clean needed', 'carpet clean'].indexOf(
    (_.get(unit_space, 'Carpet Clean', '') || '').toLowerCase()
  ) >= 0

const _getUnitSpaces = unitSpaces => {
  const commonSpace = _.find(unitSpaces, us => us.unit_space === 'Common') || {}

  const transformed = _.map(unitSpaces, us => {
    const t = {
      ...us,
      is_transfer: typeof us.is_transfer === 'string',
      is_asis: typeof us.is_asis === 'string',
      is_carpet: _iscarpet(us),
      lease_status:
        us.lease_status === 'Vacant'
          ? 'Not Leased'
          : us.lease_status === 'Occupied'
          ? 'Leased'
          : null
    }
    if (t.unit_space === 'Common') {
      if (_.get(t, 'Punch', '') === 'Yes') {
        _.set(t, 'Punch', 'Priority')
      } else if (_.get(t, 'Punch', '') === 'No') {
        _.set(t, 'Punch', 'Punch')
      }
    } else {
      // if (
      //   t.lease_status !== 'Renewal' &&
      //   t.lease_status !== 'As Is' &&
      //   commonSpace['Sparkle']
      // ) {
      //   t['Sparkle'] = commonSpace['Sparkle']
      // }
      if (
        commonSpace &&
        (commonSpace.Punch === 'Yes' || commonSpace.Punch === 'No')
      ) {
      }
      if (
        t.lease_status !== 'Renewal' &&
        t.lease_status !== 'As Is' &&
        t['Punch'] !== 'Punch Paint' &&
        t['Punch'] !== 'PUP'
      ) {
        if (_.get(commonSpace, 'Punch', '') === 'Yes') {
          _.set(t, 'Punch', 'Priority')
        } else if (_.get(commonSpace, 'Punch', '') === 'No') {
          _.set(t, 'Punch', 'Punch')
        }
      }
    }
    return t
  })

  const caSpace = _.find(
    transformed,
    t => t.unit_space.toUpperCase() === 'COMMON'
  )
  const normalUnits = _.orderBy(
    _.filter(transformed, t => t.unit_space.toUpperCase() !== 'COMMON'),
    t => t.unit_space
  )
  if (caSpace) {
    return [caSpace].concat(normalUnits)
  }
  return normalUnits
}

const _listAdapter = data => {
  let list = []
  for (let i = 0; i < data.length; i += 1) {
    const d = data[i]
    list.push({
      unit: typeof d.unit === 'string' ? d.unit : DEFAULT_VALUE,
      unit_id: typeof d.unit_id === 'string' ? d.unit : DEFAULT_VALUE,
      unit_cost: typeof d.unit_cost === 'number' ? d.unit_cost : 0,
      unit_spaces: Array.isArray(d.unit_spaces)
        ? _getUnitSpaces(d.unit_spaces, d)
        : [],
      has_note: typeof d.has_note === 'string',
      is_transfer:
        _.get(d, 'unit_spaces', []).filter(
          us => typeof us.is_transfer === 'string'
        ).length > 0,
      is_asis:
        _.get(d, 'unit_spaces', []).filter(us => typeof us.is_asis === 'string')
          .length > 0,
      is_full_asis: _.every(
        _.get(d, 'unit_spaces'),
        us => typeof us.is_asis === 'string'
      ),
      is_carpet:
        _.get(d, 'unit_spaces', []).filter(us => _iscarpet(us)).length > 0,
      fully_vacant:
        _.get(d, 'unit_spaces', []).filter(
          us => !us.lease_status || us.lease_status === 'Vacant'
        ).length === _.get(d, 'unit_spaces', []).length,
      partially_occupied: (() => {
        const renewals = _.get(d, 'unit_spaces', []).filter(
          us => us.lease_status === 'Renewal'
        ).length
        const commonArea = 1
        return (
          renewals > 0 &&
          renewals !== _.get(d, 'unit_spaces', []).length - commonArea
        )
      })()
    })
  }
  return list
}

const _optionsAdapter = options => {
  const mappedOptions = _.map(options, option => ({
    option_name:
      typeof option.option_name === 'string'
        ? option.option_name
        : DEFAULT_VALUE,
    quantity: typeof option.quantity === 'number' ? option.quantity : 0,
    est_cost: typeof option.est_cost === 'number' ? option.est_cost : 0
  }))

  const sortedOptions = _.orderBy(
    mappedOptions,
    [option => option.option_name.toLowerCase()],
    ['asc']
  )

  const newArray = []

  let lastIndex = 0
  sortedOptions.forEach((option, index) => {
    if (option.option_name.toLowerCase().includes('common')) {
      newArray.splice(index > 1 ? lastIndex + 1 : lastIndex, null, option)
      lastIndex = index
    } else {
      newArray.push(option)
    }
  })

  return newArray
}

const _statsAdapter = data => {
  return _.map(data, item => ({
    service: typeof item.service === 'string' ? item.service : DEFAULT_VALUE,
    value: typeof item.cost === 'number' ? item.cost : 0,
    options: Array.isArray(item.options) ? _optionsAdapter(item.options) : [],
    total: _.reduce(
      _.get(item, 'options', []),
      (acc, current) => acc + parseFloat(_.get(current, 'est_cost', 0)),
      0
    ),
    quantityTotal: _.reduce(
      _.get(item, 'options', []),
      (acc, current) => acc + parseFloat(_.get(current, 'quantity', 0)),
      0
    ),
    budget: typeof item.budget_amount === 'number' ? item.budget_amount : '0'
  }))
}

const _servicesAdapter = data => {
  return data.map(d => ({
    servicetype_uri:
      typeof d.servicetype_uri === 'string' ? d.servicetype_uri : '',
    servicetype_id:
      typeof d.servicetype_id === 'string' ? d.servicetype_id : '',
    servicetype: typeof d.servicetype === 'string' ? d.servicetype : '',
    label: typeof d.label === 'string' ? d.label : '',
    options: Array.isArray(d.options) ? d.options : []
  }))
}

const _hasMediaAdapter = (inspection_lines = []) => {
  let hasMedia = false

  for (let line of inspection_lines) {
    hasMedia = !(line.items || []).every(
      lineItem => !lineItem?.line_media?.length
    )

    if (hasMedia) {
      break
    }
  }

  return hasMedia
}
