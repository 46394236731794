import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, withRouter, Redirect } from 'react-router-dom'
import PrivateRoute from '../private-route'
import UpdatePassword from '../../containers/update-password'
// import ChangePassword from '../../containers/change-password'
import QcMain from '../../parents/qc-main'
import VendorMain from '../../parents/vendor-main'
import VendorsQuotation from '../vendors-quotation'
import AccessDenied from '../access-denied'

import { isMobile } from '../../utils/helperFunctions'
import useAppRoutes from './hooks/useAppRoutes'

const AppRoutes = ({ user, history, context }) => {
  const { routes } = useAppRoutes()
  const [isQCLocked, setIsQCLocked] = useState(false)
  const [isVendorLocked, setIsVendorLocked] = useState(false)
  const { roles } = user || {}
  const roleName = roles?.[0] || ''

  useEffect(() => {
    const { pathname } = history.location
    const { roles } = user || {}
    const role = (roles?.[0] || '').toLowerCase()

    // Accessing to QC routes as non-staff & non-all user is not allowed.
    const newIsQCLocked =
      pathname.startsWith('/qc') &&
      ((!isMobile() && role !== 'staff') || (isMobile() && role === 'vendor'))

    // Accessing to Vendor routes as non-vendor & non-all user is not allowed.
    const newIsVendorLocked =
      pathname.startsWith('/vendor') && role !== 'vendor'

    setIsQCLocked(newIsQCLocked)
    setIsVendorLocked(newIsVendorLocked)
  }, [history, user, context])

  const { user_metadata } = user || {}
  const { firstTimeLogin } = user_metadata || {}

  useEffect(() => {
    if (firstTimeLogin) history.push('/onboarding/vendor/update-password')
  }, [firstTimeLogin])

  return (
    <Switch>
      {firstTimeLogin && (
        <PrivateRoute
          skipContext={true}
          exact
          path="/onboarding/vendor/update-password"
          component={UpdatePassword}
        />
      )}

      {/* <PrivateRoute
        skipContext={true}
        exact
        path="/change-password"
        component={ChangePassword}
      /> */}

      {(routes || []).map(({ path, Component, id, ...rest }) => (
        <PrivateRoute key={id} path={path} component={Component} {...rest} />
      ))}

      {roleName === 'RFP Vendor' && (
        <PrivateRoute
          exact
          skipContext
          path="/request/:id/quotation"
          component={VendorsQuotation}
        />
      )}

      {/* <PrivateRoute path="/inspections-setup/turnable-templates" component={Inspections} /> */}

      {/* Vendor Routes */}
      {!isVendorLocked && (
        <PrivateRoute path="/vendor" component={VendorMain} />
      )}

      {/* QC Routes */}
      {!isQCLocked && <PrivateRoute path="/qc" component={QcMain} />}

      <Redirect exact from="/logout" to="/logout" />
      <Redirect exact from="/" to="/" />
      <PrivateRoute skipContext={true} path="*" component={AccessDenied} />
    </Switch>
  )
}

AppRoutes.propTypes = {
  history: PropTypes.object,
  context: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = ({ context, user }) => ({
  context,
  user
})

export default withRouter(connect(mapStateToProps)(AppRoutes))
