import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { arrowDown } from '../../ui/icons'

import './styles.scss'

const MobileWorkDetailNote = ({ title, notes }) => {
  const [open, setOpen] = useState(false)

  const mountNote = (note, first, last, unique) => (
    <div
      className={`mobile-work-note ${first &&
        'mobile-work-note-first'} ${last && 'mobile-work-note-last'} ${unique &&
        'mobile-work-note-unique'}`}>
      <div className="mobile-work-note-title">
        {note?.creation_date} {note?.type}
      </div>
      <div>
        {note?.note}
        {note?.reason}
      </div>
    </div>
  )

  return (
    <div className="mobile-work-detail-note">
      {notes.length > 0 ? (
        <>
          <div className="mobile-work-detail-note-title">{title}</div>
          <div
            className="is-flex mobile-work-detail-note-arrow"
            onClick={() => setOpen(!open)}>
            {!open ? (
              <div className="is-fullwidth mobile-work-detail-note-first">
                {mountNote(notes[0], true, true)}
              </div>
            ) : (
              <div className="is-fullwidth">
                {notes.map((note, index) => (
                  <div key={index}>
                    {mountNote(
                      note,
                      index === 0,
                      index === notes.length - 1,
                      false
                    )}
                  </div>
                ))}
              </div>
            )}
            {notes.length > 1 && (
              <img
                src={arrowDown}
                className={`mobile-work-detail-note-arrow-image ${
                  open ? 'mobile-work-detail-note-arrow-rotate' : ''
                }`}
                alt="arrow-down"
              />
            )}
          </div>
        </>
      ) : (
        <div className="mobile-work-detail-note-title">No Notes Found</div>
      )}
    </div>
  )
}

MobileWorkDetailNote.propTypes = {
  title: PropTypes.string,
  notes: PropTypes.any
}

export default MobileWorkDetailNote
