import React, { useState, useCallback, useMemo } from 'react'
import ErrorTemplateNoPadding from './ErrorTemplateNoPadding'
import FieldTemplateNoPadding from './FieldTemplateNoPadding'
import GeneralFieldNoPadding from './GeneralFieldNoPadding'
import { closeFilled } from 'ui/icons'
import { passwordPolicy } from './ValidationPolicies'
import ReactTooltip from 'react-tooltip'
import PasswordField from './PasswordField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { updateUserPassword } from '../../modules/setup-users'
import { setAlert } from '../../modules/alerts'
import '../../styles/common.scss'

const PasswordReset = props => {
  const { updateUserPassword, setAlert, setupUsers } = props || {}
  const [password, setPassword] = useState('')
  const [isValidpassword, setIsValidPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidConfirmpassword, setIsValidConfirmPassword] = useState(false)
  const [shouldGeneratePassword, setShouldGeneratePassword] = useState(true)
  const [resetPassword, setResetPassword] = useState(false)
  const [
    errorMessageUserUpdatePassword,
    setErrorMessageUserUpdatePassword
  ] = useState('')
  const [
    isUserUpdatePasswordLoading,
    setIsUserUpdatePasswordLoading
  ] = useState(false)

  const getValidPassword = value => value?.match(passwordPolicy) !== null

  const optimizedPasswordValidation = useCallback((value, type) => {
    if (!getValidPassword(value)) {
      type(true)
    } else {
      type(false)
    }
  }, [])

  const toggleGeneratePassword = value => {
    setErrorMessageUserUpdatePassword('')
    setShouldGeneratePassword(value)
    setPassword('')
    setConfirmPassword('')
    setIsValidConfirmPassword(false)
    setIsValidPassword(false)
  }

  const notValidPasswordError = resetState => {
    return (
      <ErrorTemplateNoPadding>
        <div>
          <p>Invalid password. Password must match the following criteria:</p>
          <ol className="password-error">
            <li>Password must contain at least 8 Characters.</li>
            <li>An Uppercase character.</li>
            <li>A lowercase character.</li>
            <li>A Number.</li>
            <li>A Special Character.</li>
          </ol>
        </div>
        {/* <button className="error-button" onClick={() => resetState(false)}>
          <img src={closeFilled} alt="" />
        </button> */}
      </ErrorTemplateNoPadding>
    )
  }

  const isSavePasswordEnable = useMemo(() => {
    if (
      (password || '').trim() === '' ||
      (confirmPassword || '').trim() === '' ||
      isValidpassword ||
      isValidConfirmpassword ||
      (password || '').trim() !== (confirmPassword || '').trim()
    ) {
      return true
    }
    return false
  }, [password, confirmPassword, isValidpassword, isValidConfirmpassword])

  // Redux
  const { userDetail } = setupUsers || {}
  const { data: userDetailData } = userDetail || {}
  const { email, user_id } = userDetailData || {}

  const resetPasswordCard = () => {
    setErrorMessageUserUpdatePassword('')
    toggleGeneratePassword(true)
    setResetPassword(false)
  }

  const onSuccessUpdatePassword = () => {
    setIsUserUpdatePasswordLoading(false)
    setErrorMessageUserUpdatePassword('')
    resetPasswordCard()
    setAlert(
      'An email with the reset password is shared with the user email',
      'success'
    )
  }

  const onErrorUpdatePassword = () => {
    setIsUserUpdatePasswordLoading(false)
    resetPasswordCard()
    setErrorMessageUserUpdatePassword(
      'Something went wrong. Please try again later!!!'
    )
  }

  const onHandleUpdatePassword = password => {
    setIsUserUpdatePasswordLoading(true)
    setErrorMessageUserUpdatePassword('')
    const body = {
      user_id,
      password
    }
    if (password) {
      return updateUserPassword(
        body,
        onSuccessUpdatePassword,
        onErrorUpdatePassword
      )
    }
    delete body.password
    return updateUserPassword(
      body,
      onSuccessUpdatePassword,
      onErrorUpdatePassword
    )
  }

  return (
    <div className="box is-shadowless has-transition-opacity">
      {errorMessageUserUpdatePassword !== '' ? (
        <ErrorTemplateNoPadding>
          <p>Something went wrong. Please try again later!!!</p>
          <button
            className="error-button"
            onClick={() => setErrorMessageUserUpdatePassword('')}>
            <img src={closeFilled} alt="" />
          </button>
        </ErrorTemplateNoPadding>
      ) : null}
      <strong className="is-size-4">User Account</strong>
      <div className="columns is-vcentered mt-8 mb-0">
        <div className="column is-full is-10-fullhd">
          <div className="columns is-desktop is-mobile">
            <div className="label column is-one-quarter m-0">
              <label>Username:</label>
            </div>
            <div className=" column is-four-thirds label m-0">
              <label>{email}</label>
            </div>
          </div>
        </div>
      </div>
      {shouldGeneratePassword && (
        <div className="columns is-vcentered ">
          <div className="column is-full is-10-fullhd pt-7">
            <div className="columns is-desktop is-mobile">
              <div className="label column is-one-quarter m-0">
                <label>Password:</label>
              </div>
              <div className=" column is-four-thirds label m-0">
                <div className="user-pass-container">
                  <label
                    className="link"
                    onClick={() => {
                      setErrorMessageUserUpdatePassword('')
                      setResetPassword(true)
                    }}>
                    Reset Password
                  </label>
                  <p className="password-seperator">Or</p>
                  <label
                    className="link"
                    onClick={() => {
                      setErrorMessageUserUpdatePassword('')
                      toggleGeneratePassword(false)
                      setResetPassword(false)
                    }}>
                    Manually Reset Password
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!shouldGeneratePassword && (
        <>
          <FieldTemplateNoPadding>
            <GeneralFieldNoPadding fieldLabel="Password:" isRequired>
              <PasswordField
                id="password"
                name="password"
                value={password}
                autoFocus={true}
                onChange={e => {
                  let value = e.target.value
                  if (!value.includes(' ')) {
                    setConfirmPassword('')
                  }
                  value = value.replace(' ', '')
                  setErrorMessageUserUpdatePassword('')
                  setPassword(value)
                  setIsValidConfirmPassword(false)
                  optimizedPasswordValidation(value, setIsValidPassword)
                }}
              />
            </GeneralFieldNoPadding>
          </FieldTemplateNoPadding>
          {isValidpassword && notValidPasswordError(setIsValidPassword)}
          <FieldTemplateNoPadding>
            <GeneralFieldNoPadding fieldLabel="Confirm Password:" isRequired>
              <PasswordField
                id="confirmpassword"
                name="confirmpassword"
                value={confirmPassword}
                disabled={!(password || '').trim() || isValidpassword}
                onChange={e => {
                  let value = e.target.value
                  value = value.replace(' ', '')
                  setErrorMessageUserUpdatePassword('')
                  setConfirmPassword(value)
                  optimizedPasswordValidation(value, setIsValidConfirmPassword)
                }}
              />
            </GeneralFieldNoPadding>
          </FieldTemplateNoPadding>
          {isValidConfirmpassword &&
            notValidPasswordError(setIsValidConfirmPassword)}
          {!isValidpassword &&
            !isValidConfirmpassword &&
            password &&
            confirmPassword &&
            (password || '').trim() !== (confirmPassword || '').trim() && (
              <ErrorTemplateNoPadding>
                <p>Password and Confirm Password do not match</p>
              </ErrorTemplateNoPadding>
            )}
          <p className="user-pass-desc">
            Upon send, this password will be shared with the user via email.
          </p>
          <FieldTemplateNoPadding>
            <div className="label column is-one-third"></div>
            <div className=" column is-four-thirds password-button-wrapper ">
              <button
                onClick={e => resetPasswordCard()}
                className="link password-action-buttons">
                Cancel
              </button>
              <button
                data-tip
                data-for="addNewPassword"
                onClick={() => {
                  if (!isSavePasswordEnable || isUserUpdatePasswordLoading) {
                    onHandleUpdatePassword(password)
                  }
                }}
                className={`link ml-8 password-action-buttons ${
                  isSavePasswordEnable || isUserUpdatePasswordLoading
                    ? 'button-disabled'
                    : ''
                }`}>
                Send
                {isUserUpdatePasswordLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                ) : null}
              </button>
              <ReactTooltip
                className="customTooltipTheme"
                id="addNewPassword"
                place="top"
                disable={!isSavePasswordEnable || isUserUpdatePasswordLoading}
                effect="solid">
                <div>
                  <p>
                    Please fill all the required (
                    <span className="required-star">*</span>) fields
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </FieldTemplateNoPadding>
        </>
      )}
      {resetPassword && (
        <>
          <p className="user-pass-desc">
            Upon send, an email with the reset password will be shared with the
            user. If they did not receive the email, click "Reset Password" to
            send a new one
          </p>
          <FieldTemplateNoPadding>
            <div className="label column is-one-third"></div>
            <div className=" column is-four-thirds password-button-wrapper ">
              <button
                disabled={isUserUpdatePasswordLoading}
                onClick={e => resetPasswordCard()}
                className="link password-action-buttons">
                Cancel
              </button>
              <button
                onClick={() => onHandleUpdatePassword()}
                disabled={isUserUpdatePasswordLoading}
                className="link ml-8 password-action-buttons">
                Send
                {isUserUpdatePasswordLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                ) : null}
              </button>
            </div>
          </FieldTemplateNoPadding>
        </>
      )}
    </div>
  )
}

const mapStateToProps = ({ setupUsers }) => ({
  setupUsers
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserPassword,
      setAlert
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset)
)
