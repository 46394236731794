import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useContextChanged, usePrevious } from '../../helpers/hooks'
import Breadcrumbs from '../../parents/breadcrumbs'
import VendorsList from '../setup-vendors-list'
import VendorCompanyList from '../setup-vendor-company-list'
import VendorManage from '../setup-vendor-manage'
import './index.scss'

const SetupVendorsMain = props => {
  const { setupServicesGet, user, context, location, history } = props
  const { pdbid } = user.user_metadata

  const prevContext = usePrevious(context)
  const { contextChanged } = useContextChanged(context)

  // useEffect(() => {
  //   setupServicesGet(pdbid, context)
  // }, [])

  useEffect(() => {
    if (
      context?.project !== prevContext?.project ||
      context?.property !== prevContext?.property
    ) {
      setupServicesGet(pdbid, context)
    }
  }, [context])

  useEffect(() => {
    if (contextChanged && location.pathname === '/setup/vendor-manage') {
      return history.replace('/setup/vendors')
    }
  }, [contextChanged, location.pathname])

  const getListViewLink = () => {
    const { pathname } = location
    if (pathname === '/setup/vendors') return null
    return (
      <Link to="/setup/vendors" className="p-none">
        List View
      </Link>
    )
  }

  let inner = <VendorCompanyList {...props} />
  if (location.pathname === '/setup/vendor-manage') {
    inner = <VendorManage {...props} />
  }
  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div
        className={`section ${
          location.pathname === '/setup/vendor-manage' ? 'vendor-section' : ''
        }`}>
        <Breadcrumbs location={location} />
        {location.pathname === '/setup/vendor-manage' && (
          <div class="back-text">
            <Link to="/setup/vendors" className="p-none">
              Back to List View
            </Link>
          </div>
        )}
        {inner}
      </div>
    </div>
  )
}

SetupVendorsMain.propTypes = {
  setupVendors: PropTypes.object
}

export default SetupVendorsMain
