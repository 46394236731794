import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import { getProjectId } from './dashboard-helpers'

/**
 * Dashboard goals data module
 * @redux
 * @reduxActionScope dashboard-goals
 * @module dashboard-goals
 */

/**
 * Request dashboard goals data
 * @type {Redux.ActionType}
 */
export const DASHBOARD_GOALS_SUMMARY_REQUESTED =
  'dashboard/DASHBOARD_GOALS_SUMMARY_REQUESTED'
/**
 * Dashboard goals data failed
 * @type {Redux.ActionType}
 */
export const DASHBOARD_GOALS_SUMMARY_FAIL =
  'dashboard/DASHBOARD_GOALS_SUMMARY_FAIL'
/**
 * Update request dashboard goals with successful data from API
 * @type {Redux.ActionType}
 */
export const DASHBOARD_GOALS_SUMMARY_SUCCESS =
  'dashboard/DASHBOARD_GOALS_SUMMARY_SUCCESS'

const initialPropertyState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  stars: 0,
  bars: []
}

const initialState = {}

/**
 * Dashboard goals reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_GOALS_SUMMARY_REQUESTED:
      const grData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...grData,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case DASHBOARD_GOALS_SUMMARY_SUCCESS:
      const gsData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...gsData,
          isRequesting: false,
          isError: false,
          bars: action.bars,
          stars: action.stars
        }
      }
    case DASHBOARD_GOALS_SUMMARY_FAIL:
      const gfData = state[getProjectId(action.context)] || initialPropertyState
      return {
        ...state,
        [getProjectId(action.context)]: {
          ...gfData,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Updates dashboard goals data
 * @redux
 * @reduxActionCreator DASHBOARD_GOALS_SUMMARY_REQUESTED, DASHBOARD_GOALS_SUMMARY_SUCCESS, DASHBOARD_GOALS_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getGoalsSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_GOALS_SUMMARY_REQUESTED,
      context
    })
    return Service.getDashboardGoalsSummary(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const result = await res.json()
            const data = goalsCardAdapter(result.dashboard)
            dispatch({
              type: DASHBOARD_GOALS_SUMMARY_SUCCESS,
              stars: data.stars,
              bars: data.bars,
              context
            })
          } catch {
            _goalsSummaryFail(dispatch, context)
          }
        } else {
          _goalsSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _goalsSummaryFail(dispatch, context)
        throw err
      })
  }
}
/**
 * Automatic dashboard goals data
 * @redux
 * @reduxActionCreator DASHBOARD_GOALS_SUMMARY_REQUESTED, DASHBOARD_GOALS_SUMMARY_SUCCESS, DASHBOARD_GOALS_SUMMARY_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getAutomaticGoalsSummary = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: DASHBOARD_GOALS_SUMMARY_REQUESTED,
      context
    })
    return Service.getDashboardAutomaticGoalsSummary(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const result = await res.json()
            const data = automaticGoalsAdapter(result.dashboard)
            dispatch({
              type: DASHBOARD_GOALS_SUMMARY_SUCCESS,
              stars: data.stars,
              bars: data.bars,
              context
            })
          } catch {
            _goalsSummaryFail(dispatch, context)
          }
        } else {
          _goalsSummaryFail(dispatch, context)
        }
      })
      .catch(err => {
        _goalsSummaryFail(dispatch, context)
        throw err
      })
  }
}

const _goalsSummaryFail = (dispatch, context) => {
  dispatch({
    type: DASHBOARD_GOALS_SUMMARY_FAIL,
    context
  })
}

const goalsCardAdapter = data => {
  const { services, goals } = data
  const bars = _.map(services, service => {
    const goal =
      _.find(
        _.get(goals, '0.goal_lines', []),
        g => g.servicetype_id === service.servicetype_id
      ) || {}
    return {
      ...goalsGraphStructure(goal, service)
    }
  })
  return {
    stars: parseInt(_.get(goals[0], 'goal_star', 0)),
    bars
  }
}

const automaticGoalsAdapter = data => {
  const { services, goals } = data
  const bars = (services || [])
    .filter(service => service?.servicetype?.toLowerCase() !== 'walk')
    .map(service => {
      const selectedGoal =
        (goals || []).find(
          goal => goal.servicetype_id === service.servicetype_id
        ) || {}

      return {
        ...goalsGraphStructure(selectedGoal, service),
        unschedule: parseInt(_.get(selectedGoal, 'unschedule', 0))
      }
    })

  return {
    stars: parseInt(_.get(goals[0], 'goal_star', 0)),
    bars
  }
}

const goalsGraphStructure = (goal = {}, service = {}) => {
  return {
    category: service.label,
    goal: parseInt(_.get(goal, 'turn_goal', 0)),
    completed: parseInt(_.get(goal, 'total_completed', 0)),
    goal_today: parseInt(_.get(goal, 'todays_goal', 0)),
    remaining_today: parseInt(
      Math.max(
        parseFloat(_.get(goal, 'todays_goal', 0)) -
          parseFloat(_.get(goal, 'total_completed', 0)),
        0
      )
    ),
    remaining: parseInt(
      Math.max(
        parseFloat(_.get(goal, 'turn_goal', 0)) -
          parseFloat(_.get(goal, 'total_completed', 0)),
        0
      )
    ),
    variance:
      parseFloat(_.get(goal, 'total_completed', 0)) -
      parseFloat(_.get(goal, 'todays_goal', 0)),
    variance_symbol:
      parseFloat(_.get(goal, 'total_completed', 0)) <
      parseFloat(_.get(goal, 'todays_goal', 0))
        ? '-'
        : '+',
    variance_color:
      parseFloat(_.get(goal, 'total_completed', 0)) <
      parseFloat(_.get(goal, 'todays_goal', 0))
        ? 'red'
        : 'green'
  }
}
