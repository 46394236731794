import React, { useEffect, useState } from 'react'
import { hasDecimalPlace } from '../../../helpers'

const InputField = ({ row, updateInspectionValue, type, hasPermission }) => {
  const { cell, flatRows } = row || {}
  const {
    value: initialValue,
    row: { index, original }
  } = cell || {}

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <div
      title={value}
      className="field-input"
      style={{ paddingLeft: flatRows?.length > 8 && '5px' }}>
      <input
        type="text"
        placeholder={type === 'damage_cost' ? 'Enter Cost' : 'Enter Code'}
        value={value || ''}
        disabled={!hasPermission}
        onChange={e => {
          const { target } = e || {}
          const { value } = target || {}
          let filterValue = value || ''

          if (type === 'damage_cost') {
            // Only allow Numbers and .
            filterValue = (value || '').replace(/[^0-9\.]/g, '')
            // Don't allow . as first character
            if ((filterValue || '')[0] === '.') {
              filterValue = ''
            }
            // Check if . already exists
            if ((filterValue || '').toString().split('.').length > 2) {
              filterValue = (filterValue || '').replace(/\.+$/, '')
            }
            // Dont allow more than 2 decimal points
            if (hasDecimalPlace(filterValue, 3)) {
              return
            }
          }

          if (type === 'damage_code') {
            // Only allow Numbers and - /
            filterValue = (value || '').replace(/[^0-9A-Za-z\-/]/g, '')
            // Don't allow . as first character
            if ((filterValue || '')[0] === '.') {
              filterValue = ''
            }
            // Don't allow decimal points
            if (hasDecimalPlace(filterValue, 0)) {
              return
            }
          }

          updateInspectionValue(index, filterValue, type)
          setValue(filterValue)
        }}
      />
    </div>
  )
}

export default InputField
