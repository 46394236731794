import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Notification from '../../components/notification'
import LoggerService from '../../service/logger'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null
    }
  }

  static getDerivedStateFromError(error) {
    return ({
      error
    })
  }

  componentDidCatch(error) {
    // Catch errors in any components below and re-render with error message
    // You can also log error messages to an error reporting service here
    LoggerService.error({
      error,
      location: this.props.location
    })
  }

  render() {
    const { error } = this.state

    return (
      <>
        {this.props.children}
        {error && <Notification color={'danger'} message={error.toString()} />}
      </>
    )
  }
}

ErrorBoundary.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(ErrorBoundary)
