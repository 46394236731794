import Service from '../service'

import LOGOUT from './auth'

export const USER_REQUESTED = 'user/USER_REQUESTED'
export const USER_FAIL = 'user/USER_FAIL'
export const USER_SUCCESS = 'user/USER_SUCCESS'
export const USER_UPDATE = 'USER_UPDATE'
export const USER_RESET_PASSWORD_SUCCESS = 'user/USER_RESET_PASSWORD_SUCCESS'
export const USER_ALLOWED_SECTION_UPDATE = 'USER_ALLOWED_SECTION_UPDATE'

export const CHANGE_PASSWORD_REQUESTED = 'user/CHANGE_PASSWORD_REQUESTED'
export const CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAIL = 'user/CHANGE_PASSWORD_FAIL'

const initialMetaData = {
  pdbid: null,
  pdbids: [],
  custom_logo: null,
  projects: [],
  properties: [],
  staffid: undefined,
  vendorid: undefined,
  auto_goals: null,
  in_house: null,
  type: undefined
}

const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  sub: null,
  email: null,
  email_verified: false,
  name: null,
  nickname: null,
  picture: null,
  updated_at: null,
  user_metadata: initialMetaData,
  roles: [],
  user_id: null,
  blocked: false,
  changePasswordError: false,
  changePasswordLoader: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case USER_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        email: action.email,
        email_verified: action.email_verified,
        name: action.name,
        nickname: action.nickname,
        user_id: action.user_id,
        sub: action.sub,
        picture: action.picture,
        updated_at: action.updated_at,
        user_metadata: action.user_metadata,
        roles: action.roles,
        blocked: action.blocked,
        ords_user_id: action.ords_user_id
      }
    case USER_UPDATE:
      const { pdbid } = action || {}
      const { user_metadata } = state || {}
      return {
        ...state,
        user_metadata: {
          ...user_metadata,
          pdbid
        }
      }
    case USER_ALLOWED_SECTION_UPDATE: {
      const { property, user, role, project } = action || {}
      const { user_metadata } = state || {}
      const getProperty = (user_metadata?.properties || []).filter(
        d => d?.id === property && d?.project[0].id === project
      )
      if ((getProperty || []).length) {
        const metadata = user?.user_metadata || {}
        return {
          ...state,
          roles: role,
          user_metadata: {
            ...metadata,
            allowedSections: getProperty[0]?.allowedSections || [],
            vendorid: getProperty[0]?.vendorid || '',
            staffid: getProperty[0]?.staffid || '',
            auto_goals: getProperty[0]?.auto_goals,
            in_house: getProperty[0]?.in_house
          }
        }
      }
    }

    case USER_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        user_metadata: {
          ...state.user_metadata,
          firstTimeLogin: false
        }
      }
    }
    case CHANGE_PASSWORD_REQUESTED: {
      return {
        ...state,
        changePasswordError: false,
        changePasswordLoader: true
      }
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordError: false,
        changePasswordLoader: false
      }
    }
    case CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        changePasswordError: true,
        changePasswordLoader: false
      }
    }
    case USER_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState

    default:
      return state
  }
}

export const getUser = currentContext => {
  return dispatch => {
    dispatch({
      type: USER_REQUESTED
    })
    Service.getUserDetail()
      .then(async res => {
        if (res.ok) {
          const user = await res.json()
          user.user_metadata.allowedSections = []
          let roles = []

          if (currentContext) {
            const currentUser = user || {}
            const { user_metadata } = currentUser || {}
            const { properties } = user_metadata || {}
            const filterCurrentProperty = (properties || []).filter(
              property => {
                const { id, project } = property || {}
                if (
                  id === currentContext[1] &&
                  project[0]?.id === currentContext[0]
                ) {
                  return property
                }
              }
            )
            if ((filterCurrentProperty || []).length) {
              roles = [filterCurrentProperty[0]?.role?.name]
              user.user_metadata.allowedSections =
                filterCurrentProperty[0]?.allowedSections
              user.user_metadata.vendorid = filterCurrentProperty[0]?.vendorid
              user.user_metadata.staffid = filterCurrentProperty[0]?.staffid
              user.user_metadata.auto_goals =
                filterCurrentProperty[0]?.auto_goals
              user.user_metadata.in_house = filterCurrentProperty[0]?.in_house
            }
          }

          dispatch({
            type: USER_SUCCESS,
            email: user.email || null,
            email_verified: user.email_verified || false,
            name: user.name || null,
            sub: user.user_id || null,
            nickname: user.nickname || null,
            picture: user.picture || null,
            updated_at: user.updated_at || null,
            user_metadata: user.user_metadata || initialMetaData,
            roles,
            user_id: user.user_id,
            ords_user_id: user.ords_user_id,
            blocked: user.blocked
          })
        } else {
          _userFail(dispatch)
        }
      })
      .catch(() => {
        _userFail(dispatch)
      })
  }
}

export const updateProfile = options => {
  const { data, onSuccess, onError, onRedirect, pdbid, context } = options || {}
  return dispatch => {
    Service.updateVendorProfile(pdbid, context, data)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          const { statusCode, message } = data || {}

          if (onSuccess) {
            onSuccess()
          }

          if (onRedirect) {
            setTimeout(() => {
              onRedirect()
              dispatch({
                type: USER_RESET_PASSWORD_SUCCESS
              })
            }, 1000)
          }
          return
        }

        onError(data)
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}

export const changePassword = (pdbid, context, body, onSuccess, onError) => {
  return dispatch => {
    dispatch({ type: CHANGE_PASSWORD_REQUESTED })
    Service.changePassword(pdbid, context, body)
      .then(res => {
        if (res.ok) {
          dispatch({ type: CHANGE_PASSWORD_SUCCESS })

          onSuccess && onSuccess()
          return
        }

        dispatch({ type: CHANGE_PASSWORD_FAIL })
        onError && onError()
      })
      .catch(err => {
        dispatch({ type: CHANGE_PASSWORD_FAIL })
        onError && onError()
      })
  }
}

const _userFail = dispatch => {
  dispatch({
    type: USER_FAIL
  })
}

// to update the vendor user current selected tenant id as pdbid

export const updateUser = ({ pdbid, onSuccess }) => {
  Service.setTntCookie(pdbid)
  return dispatch => {
    dispatch({
      type: USER_UPDATE,
      pdbid
    })
    onSuccess()
  }
}

export const updateUserAllowedSection = (project, property, user, role) => {
  return dispatch => {
    dispatch({
      type: USER_ALLOWED_SECTION_UPDATE,
      project,
      property,
      user,
      role
    })
  }
}
