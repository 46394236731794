import React from 'react'
import PropTypes from 'prop-types'
import { approvedSolution } from 'ui/icons'

const NeedsWork = props => {
  const {
    observations,
    current_Parent_id,
    current_Child_id,
    line_note,
    line_media,
    current_State,
    currentItems,
    items,
    index,
    updateInspectionObservation,
    getMediaBody,
    onUploadFileHandler,
    onChangeRadioValue,
    onChangeCheckboxValue,
    getNotesField
  } = props || {}
  let currentSolutions = []
  let currentSolutionType = ''
  let observationId = ''
  let isObservationCompleted = false
  let isSolutionComplete = false
  let minimumFileUpload = false
  let isMediaRequired = true
  const lineMediaExists = (line_media || []).filter(media => !media.deleted)
  return (
    <div className="child-accordion-data child-accordion-need-work">
      <div className="is-flex m-b-8">
        <div className="state-heading">
          <h3>How does it look ?</h3>
        </div>
        <div className="state-description is-flex is-align-items-center is-flex-wrap-wrap">
          {(observations || []).map(observeItem => {
            const {
              observation,
              selected: selectedObservation,
              solutions,
              solution_type,
              line_observation_id,
              media_minimum,
              media_required
            } = observeItem || {}
            const selected = selectedObservation
            if (selected) {
              currentSolutions = solutions || []
              currentSolutionType = solution_type?.toLowerCase() || ''
              observationId = line_observation_id
              isObservationCompleted = true
              minimumFileUpload = media_minimum ? Number(media_minimum) : 0
              isMediaRequired = media_required === null ? true : false
            }
            return (
              <button
                title={observation}
                onClick={() => {
                  if (!selected) {
                    updateInspectionObservation(
                      observation,
                      line_observation_id,
                      current_Parent_id,
                      current_Child_id,
                      currentItems,
                      items,
                      index
                    )
                  }
                }}
                key={line_observation_id}
                className={`button ${selected && 'activeButton'}`}>
                <span className="custom-text-responsive">{observation}</span>
              </button>
            )
          })}
        </div>
      </div>
      <div className="is-flex is-align-items-center m-b-8">
        {isObservationCompleted && (
          <>
            <div className="state-heading">
              <h3>Upload Photo:</h3>
              {isMediaRequired && (
                <p>
                  Minimum photos required:{' '}
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#122048',
                      fontSize: '11px'
                    }}>
                    {minimumFileUpload}
                  </span>
                </p>
              )}
            </div>
            <div className="state-description is-flex is-align-items-center is-flex-wrap-wrap">
              {(line_media || []).map(media => {
                const { line_media_id, fileName, type, preview, deleted } =
                  media || {}
                if (!deleted) {
                  return getMediaBody(
                    fileName,
                    type,
                    line_media_id,
                    preview,
                    current_Parent_id,
                    current_Child_id,
                    current_State,
                    currentItems,
                    items,
                    index,
                    isMediaRequired
                  )
                }
              })}
              <button
                onClick={() =>
                  onUploadFileHandler(
                    current_Parent_id,
                    current_Child_id,
                    current_State
                  )
                }
                className="is-link">
                {(lineMediaExists || []).length !== 0
                  ? 'Add another photo'
                  : 'Choose File'}
              </button>
            </div>
          </>
        )}
      </div>
      <div className="is-flex m-b-8">
        {isObservationCompleted &&
          ((lineMediaExists || []).length !== 0 || !isMediaRequired) && (
            <>
              <div className="state-heading">
                <h3>What should be done ?</h3>
              </div>
              <div className="state-description is-flex is-align-items-center is-flex-wrap-wrap">
                {(currentSolutions || []).length === 1
                  ? (currentSolutions || []).map(solutionItem => {
                      const { line_solution_id, solution } = solutionItem || {}
                      isSolutionComplete = true
                      if (currentSolutionType === 'multiple') {
                        return (
                          <div
                            className="solution-button"
                            title={solution}
                            key={line_solution_id}>
                            <input
                              type="checkbox"
                              id={line_solution_id}
                              name={observationId}
                              value={true}
                              defaultChecked={true}
                            />
                            <label
                              className="button solution-button-checked"
                              htmlFor={line_solution_id}>
                              <img src={approvedSolution} alt="" />
                              <span className="custom-text-responsive">
                                {solution}
                              </span>
                            </label>
                          </div>
                        )
                      }
                      return (
                        <div
                          className="solution-button"
                          key={line_solution_id}
                          title={solution}>
                          <input
                            type="radio"
                            id={line_solution_id}
                            name={observationId}
                            value={true}
                            checked={true}
                          />
                          <label
                            className="button solution-button-checked"
                            htmlFor={line_solution_id}>
                            <img src={approvedSolution} alt="" />
                            <span className="custom-text-responsive">
                              {solution}
                            </span>
                          </label>
                        </div>
                      )
                    })
                  : (currentSolutions || []).map(solutionItem => {
                      const {
                        line_solution_id,
                        selected: selectedSolution,
                        solution
                      } = solutionItem || {}
                      const selected = selectedSolution
                      if (selected) {
                        isSolutionComplete = selected
                      }
                      let convertSelectedValue = false
                      if (selected) {
                        convertSelectedValue = true
                      }
                      if (currentSolutionType === 'multiple') {
                        return (
                          <div
                            className="solution-button"
                            title={solution}
                            key={line_solution_id}
                            onChange={e =>
                              onChangeCheckboxValue(
                                e,
                                observationId,
                                line_solution_id,
                                current_Parent_id,
                                current_Child_id,
                                currentItems,
                                items,
                                index
                              )
                            }>
                            <input
                              type="checkbox"
                              id={line_solution_id}
                              name={observationId}
                              value={convertSelectedValue}
                              defaultChecked={convertSelectedValue}
                            />
                            <label
                              className={`button ${convertSelectedValue &&
                                'solution-button-checked'}`}
                              htmlFor={line_solution_id}>
                              {convertSelectedValue && (
                                <img src={approvedSolution} alt="" />
                              )}
                              <span className="custom-text-responsive">
                                {solution}
                              </span>
                            </label>
                          </div>
                        )
                      }
                      return (
                        <div
                          className="solution-button"
                          key={line_solution_id}
                          title={solution}
                          onChange={() =>
                            onChangeRadioValue(
                              observationId,
                              line_solution_id,
                              current_Parent_id,
                              current_Child_id,
                              currentItems,
                              items,
                              index
                            )
                          }>
                          <input
                            type="radio"
                            id={line_solution_id}
                            name={observationId}
                            value={convertSelectedValue}
                            checked={convertSelectedValue}
                          />
                          <label
                            className={`button ${convertSelectedValue &&
                              'solution-button-checked'}`}
                            htmlFor={line_solution_id}>
                            {convertSelectedValue && (
                              <img src={approvedSolution} alt="" />
                            )}
                            <span className="custom-text-responsive">
                              {solution}
                            </span>
                          </label>
                        </div>
                      )
                    })}
                {}
              </div>
            </>
          )}
      </div>
      <div className="comment-area">
        {getNotesField(
          isObservationCompleted,
          isSolutionComplete,
          line_note,
          current_Parent_id,
          current_Child_id,
          line_media,
          'Needs Work'
        )}
      </div>
    </div>
  )
}

NeedsWork.propTypes = {
  observations: PropTypes.array,
  current_Parent_id: PropTypes.number,
  current_Child_id: PropTypes.string,
  line_note: PropTypes.string,
  line_media: PropTypes.array,
  current_State: PropTypes.string,
  currentItems: PropTypes.object,
  items: PropTypes.array,
  index: PropTypes.number,
  updateInspectionObservation: PropTypes.func,
  getMediaBody: PropTypes.func,
  onUploadFileHandler: PropTypes.func,
  onChangeRadioValue: PropTypes.func,
  onChangeCheckboxValue: PropTypes.func,
  getNotesField: PropTypes.func
}

export default NeedsWork
