import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const VendorUserHeader = ({ title, message }) => {
  return (
    <>
      <div className="vendor-header-user">{title}</div>
      <div className="vendor-header-user-message">{message}</div>
    </>
  )
}

VendorUserHeader.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
}

export default VendorUserHeader
