import React from 'react'
import ToggleCheckbox from '../../../components/toggle-checkbox'

const ToggleButton = ({
  onChange: parentChangeHook,
  label,
  isActive = false
}) => {
  return (
    <div className="toggle-column-input-wrapper">
      <ToggleCheckbox
        onChange={state => {
          parentChangeHook(state)
        }}
        isActive={isActive}
      />
      <span>{label}</span>
    </div>
  )
}

export default ToggleButton
