import _ from 'lodash'
import { Link } from 'react-router-dom'
import React from 'react'
import RestrictedElement from '../../containers/restricted-element'
import { setupIcons } from '../setup-vendors-main/setup-icons'
import PermissionGate from '../../components/permission-gate'

const MAX_MEMBERS_TO_DISPLAY_IN_HEADER = 3

const bottomRow = team => {
  const { team_id } = team
  return (
    <PermissionGate name={'add-team-member'}>
      <RestrictedElement>
        <div
          key="setup-team-item-row-bottom"
          className="is-size-6 setup-team-member-add-row">
          <Link to={`/setup/team-manage?t=${team_id}&modal=true`}>
            <img
              src={setupIcons.addButton}
              className="setup-team-add-button"
              alt="Add Team Membrer"
            />
            Add Team Member
          </Link>
        </div>
      </RestrictedElement>
    </PermissionGate>
  )
}

export const getHeaderData = services => [
  {
    key: 'team_name',
    display: 'Team Name',
    textAlign: 'left',
    template: row => (
      <div>
        <Link to={`/setup/team-manage?t=${row.team_id}`}>{row.team_name}</Link>
      </div>
    )
  },
  {
    key: 'members',
    display: 'Team Member(s)',
    textAlign: 'left',
    notSortable: true,
    template: row => {
      const usableMembers = _.filter(
        row.members,
        m => m.vendor_name !== '' || m.staff_name !== ''
      )
      if (usableMembers.length === 0) return '-None yet-'
      else if (usableMembers.length > MAX_MEMBERS_TO_DISPLAY_IN_HEADER)
        return '-Multiple-'
      return _.map(usableMembers, m => m.vendor_name || m.staff_name).join(', ')
    }
  },
  {
    key: 'simplifiedServices',
    display: 'Service',
    filterable: true,
    getValues: data => {
      const allData = data || []
      return _.filter(
        _.reduce(
          _.flatten(
            _.map(allData || [], o =>
              _.map(o.services, s => ({
                key: s.service_name,
                caption: s.service_name
              }))
            )
          ),
          (acc, itr) =>
            _.find(acc, a => a.key === itr.key) ? acc : [...acc, itr],
          []
        ),
        Boolean
      )
    },
    evaluateMatch: (filter, element = {}) => {
      return (
        _.intersection(
          _.map(element.services, s => s.service_name),
          filter || []
        ).length > 0
      )
    }
  },
  {
    key: 'team_type',
    display: 'Type',
    filterable: true,
    getValues: data => _.uniq(_.map(data, team => team.team_type)),
    evaluateMatch: (filter, element) => filter.indexOf(element.team_type) >= 0
  },
  {
    key: 'status',
    display: 'Status',
    filterable: true,
    textAlign: 'left'
  }
]

export const pivotConfig = {
  keyField: 'uri',
  idField: 'team_id',
  collection: 'members',
  columns: [
    {
      key: 'vendor_name',
      notSortable: true,
      textAlign: 'left',
      display: 'Team Member',
      template: (team, elementId) => {
        const row = _.find(_.get(team, 'members'), r => r.uri === elementId)
        return <div>{row.vendor_name || row.staff_name}</div>
      }
    },
    {
      key: 'vendor_phone',
      notSortable: true,
      textAlign: 'left',
      display: 'Phone Number',
      template: (team, elementId) => {
        const row = _.find(_.get(team, 'members'), r => r.uri === elementId)
        return <div>{row.vendor_phone || row.staff_phone}</div>
      }
    },
    {
      key: 'vendor_email',
      notSortable: true,
      textAlign: 'left',
      display: 'Email Address',
      template: (team, elementId) => {
        const row = _.find(_.get(team, 'members'), r => r.uri === elementId)
        return <div>{row.vendor_email || row.staff_email}</div>
      }
    }
  ],
  bottomRow: id => bottomRow(id)
}
