import _ from 'lodash'
import React from 'react'
import { servicesIcons } from './services-icons'
import { formatDateString } from '../../utils/index'
import { checkPermission } from '../../utils/helperFunctions'
import * as section from '../../utils/sectionConstants'
import { lock } from 'ui/icons'
import moment from 'moment'

const renderUnitSpace = (us, isLast, item) => {
  const template = _.get(us, 'service', null) && (
    <>
      <span
        className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
        data-tooltip={_.get(us, 'tooltip', '')}>
        {_.get(us, 'display')}
      </span>
      <div className="break" />
      {_.get(us, 'is_transfer', null) && (
        <span
          className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
          //style={{ position: 'absolute', right: '4px', top: '0' }}
          data-tooltip="Incoming Transfer">
          <img src={servicesIcons.transfersSmall} alt="Incoming Transfer" />
        </span>
      )}
      {_.get(us, 'is_goback', null) && (
        <span
          className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
          //style={{ position: 'absolute', right: '4px', top: '16px' }}
          data-tooltip="This unit space has a go-back request">
          <img src={servicesIcons.goBackSmall} alt="Is Go-Back" />
        </span>
      )}
      {_.get(us, 'is_asis', null) && (
        <span
          className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
          //style={{ position: 'absolute', left: '4px', top: '0' }}
          data-tooltip="This is an As-Is Unit Space">
          <img src={servicesIcons.asIsSmall} alt="Is As-Is" />
        </span>
      )}
      {_.get(us, 'is_priority', null) && (
        <span
          className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
          //style={{ position: 'absolute', left: '4px', top: '0' }}
          data-tooltip="This is a priority Unit Space">
          <img src={servicesIcons.prioritySmall} alt="Priority Punch" />
        </span>
      )}
      {_.get(us, 'is_carpet', null) && (
        <span
          className={`has-tooltip-${isLast ? 'top' : 'bottom'}`}
          //style={{ position: 'absolute', left: '4px', top: '0' }}
          data-tooltip="This Unit Space has carpet">
          <img src={servicesIcons.carpetSmall} alt="Carpet" />
        </span>
      )}
    </>
  )
  return template
}

export const getHeaderData = (
  phase,
  scheduleEnabled,
  serviceColumns,
  currentService,
  allowedSections,
  onHandleConfirmationModal,
  inspection
) => {
  const headerData = [
    {
      key: 'unit',
      display: 'Unit',
      textAlign: 'left',
      filterable: true,
      template: (row, methods, isLast) => {
        let UnitValue = <span>{row.unit}</span>
        if (currentService === 'Walk' && phase === 'InProgress') {
          if (inspection === null && row?.inspection_id) {
            UnitValue = (
              <a
                className={`p-l-sm link is-pointer has-tooltip-${
                  isLast ? 'top' : 'bottom'
                }`}
                href={`/inspections/manage-inspection/${row?.inspection_id}`}
                target="_blank"
                rel="noopener noreferrer">
                {row.unit}
              </a>
            )
          } else {
            UnitValue = (
              <span
                className={`p-l-sm is-pointer has-tooltip-${
                  isLast ? 'top' : 'bottom'
                }`}>
                {row.unit}
              </span>
            )
          }
        }
        return (
          <>
            {UnitValue}
            {row.has_note && (
              <span
                className={`p-l-sm is-pointer has-tooltip-${
                  isLast ? 'top' : 'bottom'
                }`}
                data-tooltip="This unit has notes">
                <img
                  src={servicesIcons.noteSmall}
                  alt="Has Note"
                  onClick={() => methods.openNotes(row)}
                />
              </span>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <div />
              {row.is_blocked && (
                <>
                  <span
                    className={`p-l-sm is-pointer has-tooltip-${
                      isLast ? 'top' : 'bottom'
                    }`}
                    style={{ width: '100%' }}
                    data-tooltip="This unit is blocked">
                    <img src={lock} alt="Has Note" />
                  </span>
                </>
              )}
              {row.has_charge && (
                <>
                  <span
                    style={{ width: '100%' }}
                    className={`p-l-sm is-pointer has-tooltip-${
                      isLast ? 'top' : 'bottom'
                    }`}
                    data-tooltip={`
                    You cannot return this unit 
                    because it has been invoiced. 
                    Please remove the charge from 
                    the invoice to proceed with returning.`}>
                    <img src={servicesIcons.Invoice} alt="has charge" />
                  </span>
                </>
              )}
            </div>
          </>
        )
      }
    },
    {
      key: 'grouping',
      display: 'Group',
      filterable: true,
      ignoreEmpty: true,
      template: row => <span>{row.grouping || 'N/A'}</span>
    },
    {
      key: 'type',
      display: 'Type',
      filterable: true,
      notSortable: true
    },
    {
      key: 'common.display',
      display: 'Common',
      tooltip: 'common.tooltip',
      template: (row, _methods, isLast) => renderUnitSpace(row?.Common, isLast),
      notSortable: true
    },
    ...(serviceColumns || []).map(item => {
      const { unitspace } = item || {}
      return {
        key: `${unitspace}.display`,
        display: unitspace,
        tooltip: `${unitspace}.tooltip`,
        template: (row, _methods, isLast) =>
          renderUnitSpace(row[unitspace], isLast, unitspace),
        notSortable: true
      }
    }),
    {
      key: 'team_member',
      display: 'Team',
      filterable: true,
      notSortable: false
    },
    {
      key: 'building',
      display: 'Building',
      filterable: true,
      notSortable: false,
      hidden: true
    },
    {
      key: 'floorplan',
      display: 'Floorplan',
      filterable: true,
      notSortable: false,
      hidden: true
    }
  ]

  headerData.splice(2, 0, {
    key: phase !== 'Approved' ? 'scheduled_date' : 'complete_at',
    display: phase !== 'Approved' ? 'Scheduled' : 'Completed',
    filterable: true,
    ignoreEmpty: true,
    template: row => {
      const date =
        phase !== 'Approved'
          ? row.scheduled_date || 'N/A'
          : row.complete_at || 'N/A'
      const isValidDate = moment(date).isValid()

      return (
        <span>{isValidDate ? formatDateString(date, 'MM/DD/YYYY') : date}</span>
      )
    }
  })

  return headerData
}

export const getOutsideFilters = (dates, initialSetup = false) => {
  const currentDateStart = new Date().setHours(0, 0, 0, 0)
  const currentDateEnd = new Date().setHours(23, 59, 59, 0)

  return {
    today: {
      label: 'Today',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        //   Today - Should only show data with today’s date in the schedule date
        const dateTime = new Date(itemDate).getTime()
        return dateTime >= currentDateStart && dateTime <= currentDateEnd
      },
      active: false
    },
    thruToday: {
      label: 'Thru Today',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        // Thru Today - Should only show data with today’s date or earlier in the schedule date
        const dateTime = new Date(itemDate).getTime()
        return dateTime <= currentDateEnd
      },
      active: false
    },
    tomorrow: {
      label: 'Tomorrow',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        // Tomorrow - Should only show data with tomorrow’s date in the schedule date
        const dateTime = new Date(itemDate).getTime()
        const tomorrowStart = new Date(currentDateStart).setDate(
          new Date(currentDateStart).getDate() + 1
        )
        const tomorrowEnd = new Date(currentDateEnd).setDate(
          new Date(currentDateEnd).getDate() + 1
        )
        return dateTime >= tomorrowStart && dateTime <= tomorrowEnd
      },
      active: false
    },
    yesterday: {
      label: 'Yesterday',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        // Yesterday - Should only show data with yesterday’s date in the schedule date
        const dateTime = new Date(itemDate).getTime()
        const yesterdayStart = new Date(currentDateStart).setDate(
          new Date(currentDateStart).getDate() - 1
        )
        const yesterdayEnd = new Date(currentDateEnd).setDate(
          new Date(currentDateEnd).getDate() - 1
        )
        return dateTime >= yesterdayStart && dateTime <= yesterdayEnd
      },
      active: false
    },
    all: {
      label: 'All',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        // All - Should show all data regardless of schedule date (or if schedule date is blank)
        return itemDate || !itemDate
      },
      active: initialSetup ? true : false
    },
    unscheduled: {
      label: 'Unscheduled',
      keyToFilter: 'scheduled_date',
      filterAction: itemDate => {
        // Unscheduled - Should show all rows that do not have a scheduled date
        return !itemDate
      },
      active: false
    },
    custom: {
      label: 'Custom',
      keyToFilter: 'scheduled_date',
      readOnly: true,
      filterAction: itemDate => {
        const dateTime = new Date(itemDate).getTime()
        return (
          dateTime >= dates.from?.getTime() && dateTime <= dates.to?.getTime()
        )
      },
      active: false
    }
  }
}
