import React from 'react'
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../../containers/private-route'
import VendorWork from '../vendor-work'
import VendorPending from '../vendor-pending'
import VendorApproved from '../vendor-approved'
import VendorOrderDetail from '../vendor-order-detail'
import VendorSearch from '../vendor-search'
import PropertyMap from '../property-map'
import MobileTabs from '../../components/mobile-tabs'
import {
  home,
  homeClicked,
  pending,
  pendingClicked,
  approved,
  approvedClicked,
  tabSearch,
  tabSearchClicked
} from '../../ui/icons'
import './index.scss'

export const vendorTabs = [
  {
    pathname: '/vendor/work',
    title: 'My Work',
    icon: home,
    clicked: homeClicked
  },
  {
    pathname: '/vendor/pending',
    title: 'Pending Approval',
    icon: pending,
    clicked: pendingClicked
  },
  {
    pathname: '/vendor/approved',
    title: 'Approved',
    icon: approved,
    clicked: approvedClicked
  }
  // {
  //   pathname: '/vendor/search',
  //   title: 'Search',
  //   icon: tabSearch,
  //   clicked: tabSearchClicked
  // },
]

const Vendor = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  return (
    <div className="vendor-main">
      <div className="vendor-main-content">
        <Switch>
          <PrivateRoute exact path={`${path}/work`} component={VendorWork} />
          <PrivateRoute
            exact
            path={`${path}/pending`}
            component={VendorPending}
          />
          <PrivateRoute
            exact
            path={`${path}/approved`}
            component={VendorApproved}
          />
          <PrivateRoute
            exact
            path={`${path}/job/:id`}
            component={VendorOrderDetail}
          />
          <PrivateRoute
            exact
            path={`${path}/property-map`}
            component={PropertyMap}
          />
          {/* <PrivateRoute
            exact
            path={`${path}/search`}
            component={VendorSearch}
          /> */}
          <Redirect from="/vendor" to="/vendor/work" />
        </Switch>
      </div>
      <div className="vendor-main-footer">
        <MobileTabs pathname={pathname} tabs={vendorTabs} />
      </div>
    </div>
  )
}

export default Vendor
