import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'

const SetupStaffManageModalDelete = ({
  context,
  user,
  staffId,
  permissionRuleId,
  deleteStaffUser,
  onCancel,
  onConfirm
}) => {
  const { pdbid } = user.user_metadata

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const onSuccess = () => {
    setLoading(false)
    onConfirm()
  }

  const onError = () => {
    setLoading(false)
    setError('Something went wrong. Please try again later!!!')
  }

  const handleSubmit = () => {
    const body = {
      permission_rule_id: permissionRuleId
    }

    setLoading(true)
    deleteStaffUser(pdbid, context, staffId, body, onSuccess, onError)
  }

  return (
    <div>
      <p className="is-size-4 m-b-sm">
        You are about to remove this staff member.
      </p>
      <p className="is-size-6 m-b-md">
        This action cannot be undone and they will need to be manually re-added
        if needed in the future.
      </p>
      <p className="is-size-4 has-text-link has-text-centered m-b-lg">
        Are you sure you want to remove this staff member?
      </p>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <div className="columns is-desktop is-mobile">
        <div className="column is-half has-text-right">
          <button
            type="button"
            className="button is-primary"
            style={{ width: 130 }}
            onClick={() => {
              onCancel()
            }}>
            No, Cancel
          </button>
        </div>
        <div className="column is-half">
          <button
            type="button"
            className="button is-danger"
            style={{ width: 130 }}
            onClick={handleSubmit}>
            {loading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes, Remove
          </button>
        </div>
      </div>
    </div>
  )
}

SetupStaffManageModalDelete.propTypes = {
  closeModal: PropTypes.func,
  setupOneStaffDelete: PropTypes.func,
  staffId: PropTypes.string,
  user: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object
}

export default SetupStaffManageModalDelete
