import React from 'react'
import { pin, floorplan, checklist, checklistNotRead } from '../../ui/icons'
import { ServiceColors } from '../../data'
import './styles.scss'

const MobileSumaryOrder = ({
  order,
  checklistOpen,
  handleCheckList,
  handleMapClick,
  handleFloorplanClick
}) => {
  return (
    <div className="mobile-sumary-order">
      <div
        className="tag is-medium is-uppercase is-dark mobile-sumary-order-tag"
        style={{ backgroundColor: ServiceColors[order.service] || 'grey' }}>
        {order.service}
      </div>

      <div className="mobile-sumary-order-unit-content">
        <div className="mobile-sumary-order-unit">
          <div className="mobile-sumary-order-unit-title">{`Unit ${order?.unit ||
            ''} - ${order?.qc_team_member || order?.team_member}`}</div>
        </div>

        {/* <div className="mobile-sumary-order-unit-action">
          <button
            className="mobile-sumary-order-action"
            onClick={handleCheckList}>
            <img src={checklist} alt="Checklist" />
          </button>
          <button
            className="mobile-sumary-order-action"
            onClick={handleMapClick}>
            <img src={pin} alt="Pin" />
          </button>
          <button
            className="mobile-sumary-order-action"
            onClick={handleFloorplanClick}>
            <img src={floorplan} alt="Floorplan" />
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default MobileSumaryOrder
