import React from 'react'

const FieldTemplate = ({ children, isDisabledVcentered }) => {
  return (
    <div className="columns is-vcentered mb-0">
      <div className="column is-full is-10-fullhd is-offset-1-fullhd">
        <div
          className={`columns is-desktop is-mobile content-spacing ${
            isDisabledVcentered ? '' : 'is-vcentered'
          }`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FieldTemplate
