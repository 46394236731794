import _ from 'lodash'
import React, {useState, useEffect, useMemo} from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import { getFormData, animate } from '../../helpers'

const DEFAULT = 'none'

const SetupTeamManageModalVendor = ({
  modal,
  location,
  user,
  defaults,
  setupOneTeamUpdate,
  setupTeams,
  setupVendors,
  context,
  closeModal,
  history
}) => {
  const [vendor, setVendor] = useState(DEFAULT)
  const [service, setService] = useState([])
  const { teamId } = defaults || {}

  const prevModal = usePrevious(modal)

  const params = useMemo(() => {
    const newParams = new URLSearchParams(location.search)

    return {
      t: newParams.get('t') || DEFAULT,
    }
  }, [location])

  const { t } = params

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    const {
      isActive,
    } = modal
    const wasActive = _.get(prevModal, 'isActive')
    const aChanged = isActive !== wasActive
    if (aChanged && isActive) reset()
  }, [modal])

  const reset = () => {
    const {
      vendor = DEFAULT,
      service = [],
    } = defaults
    setVendor(vendor)
    setService(service)
  }

  const submit = () => {
    const { pdbid } = user.user_metadata
    const form = document.getElementById('setup-teams-modal-vendor')
    const body = getFormData(form)
    if (body.vendor_uri === 'none') {
      const el = document.getElementById('setup-teams-modal-vendor-select')
      el.focus()
      animate(el, 'shake')
      return
    }
    setupOneTeamUpdate(pdbid, context, t, body)
    history.push(`/setup/team-manage?t=${teamId}`)
    closeModal()
  }

  const getTeamById = id => {
    const {
      data,
    } = setupTeams.allTeams
    return data.filter(team=>team.team_id===id)[0] || null
  }

  const team = getTeamById(t)
  const { services } = team || {}
  const getTeamServices = (services || []).map(service => service.service_id)
  const {
    allVendors,
  } = setupVendors
  const filteredVendors = allVendors.data.filter(vendor=>{
    const { Services } = vendor
    const filteredServices = Services.filter(s => (getTeamServices || []).includes(s.servicetype_id))
    return filteredServices.length > 0
  })
  const onSubmit = (e) => e.preventDefault()
  
  return (
    <form onSubmit={(e) => onSubmit(e)} id="setup-teams-modal-vendor">
      <div className="columns is-multiline">
        <div className="column is-half">
          <p className="is-size-4 m-b-md">Vendor</p>
          <div className="form-control">
            <label forhtml="service">Vendor:</label>
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                id="setup-teams-modal-vendor-select"
                onChange={e => setVendor(e.target.value)}
                value={vendor}
              >
                <option value="none">Select</option>
                {filteredVendors.map(v=>(
                  <option value={v.vendor_uri} key={v.vendor_uri}>{v.name}</option>
                ))}
              </select>
              <input
                type="hidden"
                name="vendor_uri"
                value={vendor}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="form-control">
            <label forhtml="option">Associated Service(s):</label>
            {(service || []).map((serv, i) => <p key={i} className="associate-service">{serv.service_name === 'Cleaning' ? 'Clean' : serv.service_name}</p>)}
          </div>
        </div>
      </div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-one-quarter is-offset-one-quarter has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary"
              onClick={()=>{
                history.push(`/setup/team-manage?t=${teamId}`)
                closeModal()
              }}
            >Cancel</button>
          </div>
        </div>
        <div className="column is-one-quarter has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary"
              onClick={() => submit()}
            >Submit</button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupTeamManageModalVendor.propTypes = {
  modal: PropTypes.object,
  defaults: PropTypes.object,
  user: PropTypes.object,
  allVendors: PropTypes.object,
  setupOneTeamUpdate: PropTypes.func,
  closeModal: PropTypes.func,
}

export default SetupTeamManageModalVendor
