import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { deleteInvoice, resetInvoiceDetails } from 'modules/invoices'
import { closeModal } from 'modules/modal'
import { Modal } from 'ui'
import { useAppContext } from 'hooks'
import { useEffect } from 'react'

const ConfirmDeleteInvoice = ({ item, history }) => {
  const dispatch = useDispatch()
  const {
    user: { pdbid },
    context
  } = useAppContext()
  const invoice = useSelector(state => state.invoices?.details)
  const { deleteIsRequesting, deleteSuccess } = useSelector(
    state => state.invoices
  )

  useEffect(() => {
    if (!deleteIsRequesting && deleteSuccess) {
      dispatch(resetInvoiceDetails()).then(() => {
        history.push('/payables/purchase-orders')
        dispatch(closeModal())
      })
    }
  }, [deleteIsRequesting, deleteSuccess])

  const deleteInvoiceHandler = () => {
    dispatch(deleteInvoice(pdbid, context, invoice.po_id))
  }

  return (
    <Modal
      title="Are you sure you want to delete this purcharse order?"
      okText="Yes, Delete"
      okType="danger"
      titleSize="4"
      cancelText="No, Cancel"
      cancelType="third"
      onConfirm={deleteInvoiceHandler}
      autoClose={false}
      loading={deleteIsRequesting}>
      <div className="columns">
        <div className="column has-text-centered is-italic">
          Note: This action cannot be undone.
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(ConfirmDeleteInvoice)
