import exportFile from './icons/export.svg'
import transfers from './icons/transfers.svg'
import asIs from './icons/asis.svg'
import fullAsIs from './icons/full-asis.svg'
import carpet from './icons/carpet.svg'
import note from './icons/note.svg'
import noteAdd from './icons/note-add.svg'
import check from './icons/solidgreencheck.svg'
import print from './icons/print.svg'
import importFile from './icons/import.svg'
import partiallyOccupied from './icons/partially-occupied.svg'
import fullyVacant from './icons/fully-vacant.svg'
import alert from './icons/alert.svg'

export const icons = {
  exportFile,
  transfers,
  asIs,
  fullAsIs,
  carpet,
  note,
  noteAdd,
  check,
  print,
  importFile,
  partiallyOccupied,
  fullyVacant,
  alert
}
