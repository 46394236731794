import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cloneDeep from 'lodash/cloneDeep'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faFileImage } from '@fortawesome/free-solid-svg-icons'
import MobileWorkDetailNote from '../../components/mobile-work-detail-note'
import QcRejectionModal from '../../components/qc-rejection-modal'
import BottomSheet, { useBottomSheet } from '../../components/bottomsheet'
import MobileSumaryOrder from '../../components/mobile-sumary-order'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { completeJob } from '../../modules/qc'
import { unitNotesGet, getQCVendorUnitServiceNotes } from '../../modules/notes'
import { done } from '../../ui/icons'
import QCJobUnitDetail, { QCJobUnitDetailTitle } from './unitDetail'
import { useParams } from 'react-router'
import { setAlert } from '../../modules/alerts'

import './index.scss'

function QcJobDetail({
  user,
  context,
  completeJob,
  unitNotesGet,
  notes,
  setModalContent,
  openModal,
  closeModal,
  getQCVendorUnitServiceNotes,
  setAlert
}) {
  const history = useHistory()

  let selectedSpace = null
  const { job, status = 'working' } = history.location.state
  const { pdbid, staffid } = user.user_metadata
  const { project, property } = context

  const [checklistOpen, setChecklistOpen] = useState(false)
  const [innerJob, setInnerJob] = useState(cloneDeep(job))
  const { ref, show } = useBottomSheet()
  const { id } = useParams()

  useEffect(() => {
    if (project && property && pdbid && staffid) {
      getNotes()
    }
  }, [user, context])

  const getServiceIdFromParams = useMemo(() => {
    const getId = id?.substring(0, id.indexOf('__'))
    return getId || ''
  }, [id])

  const getNotes = () => {
    const [, unitId] = job.workorder.split('__')
    //unitNotesGet(pdbid, context, unitId)
    getQCVendorUnitServiceNotes(
      pdbid,
      context,
      unitId,
      getServiceIdFromParams,
      () => {},
      () => {
        setAlert('Something went wrong while fetching notes', 'danger')
      }
    )
  }

  const setRejected = ({ reason, notes }) => {
    const index = innerJob?.UnitSpaces?.indexOf(selectedSpace)
    const clonedSpaces = innerJob?.UnitSpaces?.slice()
    clonedSpaces.splice(index, 1, {
      ...selectedSpace,
      qc_result: 'rejected',
      reason,
      notes
    })
    setInnerJob({
      ...innerJob,
      UnitSpaces: clonedSpaces
    })
  }

  const allSelected = () => {
    const selected = innerJob?.UnitSpaces?.filter(
      unit =>
        unit.qc_result === undefined &&
        unit.item_option &&
        unit.task_uri !== null
    )
    return selected?.length === 0 || false
  }

  const goBack = () => {
    history.goBack()
  }

  const handleConfirmComplete = () => {
    openModal({ width: '94%' })
    setModalContent(() => (
      <div className="unit-confirm-complete-dialog">
        <button className="delete" aria-label="close" onClick={closeModal} />
        <div className="subtitle is-4 unit-confirm-complete-dialog-title">
          Mark Complete
        </div>
        <div className="unit-confirm-complete-dialog-subtitle">
          {' '}
          This unit will be immediately moved to approved.{' '}
        </div>
        <div className="unit-confirm-complete-dialog-question">
          {' '}
          Are you ready to mark this unit complete?{' '}
        </div>
        <button
          className="button is-primary is-medium is-fullwidth"
          onClick={handleMarkComplete}>
          Confirm
        </button>
      </div>
    ))
  }

  const onHandleButtonMarkComplete = () => {
    closeModal()
    history.push('/qc/work', { noRefetch: true })
  }

  const onMarkComplete = async () => {
    openModal({ width: '300px' })

    setModalContent(() => (
      <div className="has-text-centered">
        <img src={done} alt="done" />
        <p className="order-complete-title">Work order complete!</p>
        <button
          className="button is-primary is-medium"
          onClick={onHandleButtonMarkComplete}>
          Continue
        </button>
      </div>
    ))
  }

  const handleMarkComplete = async () => {
    try {
      await completeJob(pdbid, context, staffid, innerJob)
      closeModal()
      onMarkComplete()
    } catch (error) {
      closeModal()
      console.log('QC Mark Complete error:', error)
    }
  }

  const handleReasonSaveClick = ({ reason, notes }) => {
    setRejected({ reason, notes })
    closeModal()
  }

  const handleRejectCheck = space => () => {
    selectedSpace = space
    setModalContent(() => (
      <QcRejectionModal
        unit={job.unit}
        unitspace={selectedSpace.unitspace}
        onClose={closeModal}
        onSubmit={handleReasonSaveClick}
      />
    ))
    openModal({ width: '94%' })
  }

  const handleApproveCheck = space => () => {
    const index = innerJob?.UnitSpaces?.indexOf(space)
    const clonedSpaces = innerJob?.UnitSpaces?.slice()
    clonedSpaces.splice(index, 1, { ...space, qc_result: 'approved' })
    setInnerJob({
      ...innerJob,
      UnitSpaces: clonedSpaces
    })
  }

  const handleMapClick = () => {
    const propertyMap = property && property.propmap
    openModal({ width: '100%', backgroundColor: 'transparent' })
    setModalContent(() => (
      <div className="unit-map-dialog">
        <button className="delete" aria-label="close" onClick={closeModal} />
        <figure className="image">
          {propertyMap ? (
            <img src={propertyMap} alt="Unit floorplan" />
          ) : (
            <div className="level is-mobile m-t-lg">
              <div className="level-item has-text-centered">
                <div className="has-text-grey">
                  <FontAwesomeIcon icon={faFileImage} size="2x" />
                  <p className="title is-5 has-text-grey m-t-md">
                    No property image
                  </p>
                </div>
              </div>
            </div>
          )}
        </figure>
      </div>
    ))
  }

  const handleFloorplanClick = () => {
    if (job) {
      const floorplan = job.unit_floorplan
      openModal({ width: '94%' })
      setModalContent(() => (
        <div className="unit-floorplan-dialog">
          <button className="delete" aria-label="close" onClick={closeModal} />
          <div className="subtitle is-4 m-b-sm">{`Unit ${job.unit}`}</div>
          <p className="subtitle is-6">{job.type}</p>
          <figure className="image">
            {floorplan ? (
              <img src={floorplan} alt="Unit floorplan" />
            ) : (
              <div className="level is-mobile m-t-lg">
                <div className="level-item has-text-centered">
                  <div className="has-text-grey">
                    <FontAwesomeIcon icon={faFileImage} size="2x" />
                    <p className="title is-5 has-text-grey m-t-md">
                      No property image
                    </p>
                  </div>
                </div>
              </div>
            )}
          </figure>
        </div>
      ))
    }
  }

  const handleCheckList = () => {
    show()
    setChecklistOpen(true)
  }

  return (
    <div className="qc-job-detail is-fullheight">
      <div className="qc-job-detail-header">
        <div className="qc-job-detail-header-back">
          <FontAwesomeIcon icon={faChevronLeft} size="lg" onClick={goBack} />
        </div>
        <div className="qc-job-detail-header-title">Work Order Details</div>
      </div>

      <div className="qc-job-detail-content">
        {job ? (
          <>
            <MobileSumaryOrder
              order={job}
              checklistOpen={checklistOpen}
              handleCheckList={handleCheckList}
              handleMapClick={handleMapClick}
              handleFloorplanClick={handleFloorplanClick}
            />

            {notes.isRequesting ? (
              <div className="qc-job-detail-loading-notes">
                Loading notes...
              </div>
            ) : (
              <div className="qc-job-detail-notes-container">
                <MobileWorkDetailNote title="Notes" notes={notes.data} />
              </div>
            )}
            <div>
              <QCJobUnitDetailTitle
                unitspace="Space"
                description="Option"
                action="Approved?"
              />
              {(innerJob?.UnitSpaces || [])
                .filter(item => item.task_uri !== null)
                .map((item, index) => (
                  <QCJobUnitDetail
                    key={index}
                    job={item}
                    status={status}
                    handleRejectCheck={handleRejectCheck}
                    handleApproveCheck={handleApproveCheck}
                  />
                ))}
            </div>

            {status === 'working' ? (
              <div className="p-l-md p-r-md p-t-md">
                <button
                  className="button is-primary is-medium is-fullwidth"
                  onClick={handleConfirmComplete}
                  disabled={!allSelected()}>
                  Mark Unit QC Complete
                </button>
              </div>
            ) : null}
          </>
        ) : (
          <div>Job not found</div>
        )}
      </div>

      <BottomSheet ref={ref} />
    </div>
  )
}

QcJobDetail.propTypes = {
  // states
  user: PropTypes.object,
  context: PropTypes.object,
  modal: PropTypes.object,
  // actions
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  completeJob: PropTypes.func
}

const mapStateToProps = ({ user, context, modal, notes }) => ({
  user,
  context,
  modal,
  notes
})

const mapDispatchToProps = {
  completeJob,
  unitNotesGet,
  setModalContent,
  openModal,
  closeModal,
  getQCVendorUnitServiceNotes,
  setAlert
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcJobDetail)
