import _ from 'lodash'
import Service from '../service'
import LOGOUT from './auth'
import propertyUnitsMock from '../mocks/property_units.mock'

/**
 * Setup properties (building units) data module
 * @redux
 * @reduxActionScope setup-properties-units
 * @module setup-properties-units
 */

/**
 * Request units list for the current building
 * @type {Redux.ActionType}
 */
export const GET_REQUESTED = 'setup-properties-units/GET_REQUESTED'
/**
 * units list request failed
 * @type {Redux.ActionType}
 */
export const GET_FAIL = 'setup-properties-units/GET_FAIL'
/**
 * Update requested units list with successful data from API
 * @type {Redux.ActionType}
 */
export const GET_SUCCESS = 'setup-properties-units/GET_SUCCESS'

/**
 * Request building unit creation
 * @type {Redux.ActionType}
 */
export const CREATE_REQUESTED = 'setup-properties-units/CREATE_REQUESTED'
/**
 * building unit creation failed
 * @type {Redux.ActionType}
 */
export const CREATE_FAIL = 'setup-properties-units/CREATE_FAIL'
/**
 * Refresh building details after a successful unit creation verified by the API
 * @type {Redux.ActionType}
 */
export const CREATE_SUCCESS = 'setup-properties-units/CREATE_SUCCESS'

/**
 * Request building unit update
 * @type {Redux.ActionType}
 */
export const UPDATE_REQUESTED = 'setup-properties-units/UPDATE_REQUESTED'
/**
 * building unit update failed
 * @type {Redux.ActionType}
 */
export const UPDATE_FAIL = 'setup-properties-units/UPDATE_FAIL'
/**
 * Refresh building details after a successful unit update verified by the API
 * @type {Redux.ActionType}
 */
export const UPDATE_SUCCESS = 'setup-properties-units/UPDATE_SUCCESS'

/**
 * Request building unit delete
 * @type {Redux.ActionType}
 */
export const DELETE_REQUESTED = 'setup-properties-units/DELETE_REQUESTED'
/**
 * building unit delete failed
 * @type {Redux.ActionType}
 */
export const DELETE_FAIL = 'setup-properties-units/DELETE_FAIL'
/**
 * Refresh building details after a successful unit delete verified by the API
 * @type {Redux.ActionType}
 */
export const DELETE_SUCCESS = 'setup-properties-units/DELETE_SUCCESS'

/**
 * Request building unit space create
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_CREATE_REQUESTED =
  'setup-properties/UNIT_SPACE_CREATE_REQUESTED'
/**
 * building unit space create failed
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_CREATE_FAIL = 'setup-properties/UNIT_SPACE_CREATE_FAIL'
/**
 * Refresh building details after a successful unit space create verified by the API
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_CREATE_SUCCESS =
  'setup-properties/UNIT_SPACE_CREATE_SUCCESS'

/**
 * Request building unit space update
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_UPDATE_REQUESTED =
  'setup-properties/UNIT_SPACE_UPDATE_REQUESTED'
/**
 * building unit space update failed
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_UPDATE_FAIL = 'setup-properties/UNIT_SPACE_UPDATE_FAIL'
/**
 * Refresh building details after a successful unit space update verified by the API
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_UPDATE_SUCCESS =
  'setup-properties/UNIT_SPACE_UPDATE_SUCCESS'

/**
 * Request building unit space delete
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_DELETE_REQUESTED =
  'setup-properties/UNIT_SPACE_DELETE_REQUESTED'
/**
 * building unit space delete failed
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_DELETE_FAIL =
  'setup-properties/UNIT_SPACE_DELETE_REQUESTED'
/**
 * Refresh building details after a successful unit space delete verified by the API
 * @type {Redux.ActionType}
 */
export const UNIT_SPACE_DELETE_SUCCESS =
  'setup-properties/UNIT_SPACE_DELETE_REQUESTED'

const DU = ''

const unitInitialState = {
  uri: DU,
  id: DU,
  name: DU,
  unit_uri: DU,
  unit_id: DU,
  unit_name: DU,
  building_uri: DU,
  building_id: DU,
  building_name: DU,
  floor: DU,
  team_type: DU,
  furnished: false,
  unit_spaces: []
}

/**
 * Setup properties (units) reducer
 * @redux
 * @reduxReducer
 */
const initialState = {
  hasMadeInitialRequest: false,
  isRequesting: false,
  isError: false,
  data: unitInitialState
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case DELETE_REQUESTED:
    case UNIT_SPACE_CREATE_REQUESTED:
    case UNIT_SPACE_UPDATE_REQUESTED:
    case UNIT_SPACE_DELETE_REQUESTED:
      return {
        ...state,
        hasMadeInitialRequest: true,
        isRequesting: true,
        isError: false
      }
    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case DELETE_SUCCESS:
    case UNIT_SPACE_CREATE_SUCCESS:
    case UNIT_SPACE_UPDATE_SUCCESS:
    case UNIT_SPACE_DELETE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        data: action.data
      }
    case GET_FAIL:
    case CREATE_FAIL:
    case UPDATE_FAIL:
    case DELETE_FAIL:
    case UNIT_SPACE_CREATE_FAIL:
    case UNIT_SPACE_UPDATE_FAIL:
    case UNIT_SPACE_DELETE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all units for a building
 * @redux
 * @reduxActionCreator GET_REQUESTED, GET_SUCCESS, GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id for the requested building
 */
export const setupOnePropertyGetUnits = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: GET_REQUESTED
    })
    Service.setupOnePropertyGet(pdbid, context, id, 'units')
      .then(async res => {
        if (res.ok) {
          //const data = await res.json()
          const data = propertyUnitsMock
          dispatch({
            type: GET_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyGetUnitsFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyGetUnitsFail(dispatch)
      })
  }
}

const _setupOnePropertyGetUnitsFail = dispatch => {
  dispatch({
    type: GET_FAIL
  })
}

const _unitsAdapter = units => {
  return units.map(u => ({
    uri: typeof u.unit_uri === 'string' ? u.unit_uri : DU,
    id: typeof u.unit_id === 'string' ? u.unit_id : DU,
    name: typeof u.unit_name === 'string' ? u.unit_name : DU,
    unit_uri: typeof u.unit_uri === 'string' ? u.unit_uri : DU,
    unit_id: typeof u.unit_id === 'string' ? u.unit_id : DU,
    unit_name: typeof u.unit_name === 'string' ? u.unit_name : DU,
    building_uri: typeof u.building_uri === 'string' ? u.building_uri : DU,
    building_id: typeof u.building_id === 'string' ? u.building_id : DU,
    building_name: typeof u.building_name === 'string' ? u.building_name : DU,
    floor: typeof u.floor === 'string' ? u.floor : DU,
    team_type: typeof u.team_type === 'string' ? u.team_type : DU,
    furnished: typeof u.furnished === 'string' ? u.team_type === 'YES' : false, // TODO: Check if this works
    unit_spaces: _unitSpacesAdapter(u.unit_spaces)
  }))
}

const _unitSpacesAdapter = unitspaces =>
  _.map(unitspaces, us => ({
    uri: typeof us.unit_space_uri === 'string' ? us.unit_space_uri : DU,
    id: typeof us.unit_space_id === 'string' ? us.unit_space_id : DU,
    name: typeof us.unit_space === 'string' ? us.unit_space : DU,
    unit_uri: typeof us.unit_uri === 'string' ? us.unit_uri : DU,
    unit_id: typeof us.unit_id === 'string' ? us.unit_id : DU,
    unit_name: typeof us.unit_name === 'string' ? us.unit_name : DU
  }))

/**
 * Creates a new unit for a building
 * @redux
 * @reduxActionCreator CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new unit (including the building id)
 */
export const setupOnePropertyUnitCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: CREATE_REQUESTED
    })
    Service.setupOnePropertyCreate(pdbid, context, '', 'units', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CREATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitCreateFail(dispatch)
      })
  }
}

const _setupOnePropertyUnitCreateFail = dispatch => {
  dispatch({
    type: CREATE_FAIL
  })
}

/**
 * Updates an existing unit
 * @redux
 * @reduxActionCreator UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing unit
 * @param {Object} body - the JSON object for the updated unit
 */
export const setupOnePropertyUnitUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyUpdate(pdbid, context, id, 'units', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitUpdateFail(dispatch)
      })
  }
}

const _setupOnePropertyUnitUpdateFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}

/**
 * Deletes an existing unit
 * @redux
 * @reduxActionCreator DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing unit
 */
export const setupOnePropertyUnitDelete = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyDelete(pdbid, context, id, 'units')
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitDeleteFail(dispatch)
      })
  }
}

const _setupOnePropertyUnitDeleteFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}

const _setupOnePropertyUnitSpaceUpdateFail = dispatch => {
  dispatch({
    type: UNIT_SPACE_UPDATE_FAIL
  })
}

/**
 * Creates a new unit space for a building
 * @redux
 * @reduxActionCreator CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new unit (including the building id)
 */
export const setupOnePropertyUnitSpaceCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: CREATE_REQUESTED
    })
    Service.setupOnePropertyCreate(pdbid, context, '', 'units', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: CREATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitSpaceCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitSpaceCreateFail(dispatch)
      })
  }
}

const _setupOnePropertyUnitSpaceCreateFail = dispatch => {
  dispatch({
    type: CREATE_FAIL
  })
}

/**
 * Updates an existing unit space
 * @redux
 * @reduxActionCreator UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing unit
 * @param {Object} body - the JSON object for the updated unit
 */
export const setupOnePropertyUnitSpaceUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyUpdate(pdbid, context, id, 'units', body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitSpaceUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitSpaceUpdateFail(dispatch)
      })
  }
}

/**
 * Deletes an existing unit space
 * @redux
 * @reduxActionCreator DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing unit
 */
export const setupOnePropertyUnitSpaceDelete = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: UPDATE_REQUESTED
    })
    Service.setupOnePropertyDelete(pdbid, context, id, 'units')
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: UPDATE_SUCCESS,
            data: _unitsAdapter(data)
          })
        } else {
          _setupOnePropertyUnitSpaceDeleteFail(dispatch)
        }
      })
      .catch(() => {
        _setupOnePropertyUnitSpaceDeleteFail(dispatch)
      })
  }
}

const _setupOnePropertyUnitSpaceDeleteFail = dispatch => {
  dispatch({
    type: UPDATE_FAIL
  })
}
