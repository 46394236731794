import pencil from './icons/edit@2x.svg'
import pencilDisabled from './icons/pencil-disabled.svg'
import trash from './icons/trash@2x.svg'
import trashDisabled from './icons/trash-disabled.svg'
import setupAdd from './icons/setup-add.svg'
import close from './icons/close.svg'

export const icons = {
  close,
  pencil,
  pencilDisabled,
  setupAdd,
  trash,
  trashDisabled,
}
