import React from 'react'

const CASplitType = ({ column, toggleAllRowsSelected, selectedFlatRows }) => {
  const { filterValue, setFilter } = column || {}

  return (
    <select
      style={{ marginTop: '8px' }}
      name="shared"
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value)
        if ((selectedFlatRows || []).length !== 0) {
          toggleAllRowsSelected(false)
        }
      }}>
      <option value="">All</option>
      <option value="1">All Residents</option>
      <option value="2">Non-renewed Residents</option>
    </select>
  )
}

export default CASplitType
