import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'

import close from './icons/close.svg'
import './index.scss'
import ErrorMessage from '../../components/forms-helper/ErrorMessage'

const TurnboardEnableServicesModal = ({
  onConfirm,
  onCancel,
  pdbid,
  context,
  updateEnableServices,
  dataToSubmit,
  shouldMarkEnableServices
}) => {
  const { enableServicesLoader } = useSelector(state => state.turnboard)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    setError('')

    const data = {
      items: dataToSubmit
    }

    await updateEnableServices({
      data,
      pdbid,
      context,
      onSuccess: () => {
        onConfirm()
      },
      onError: () => {
        setError('Something went wrong. Please try again later !!!')
      }
    })
  }

  return (
    <div className="enable-services-modal">
      <div className="modal-heading">
        <h3>
          {shouldMarkEnableServices
            ? 'Mark Enable Services'
            : 'Unmark Enable Services'}
        </h3>
        <p className="close is-pointer has-text-grey-light" onClick={onCancel}>
          <img alt="Close Modal" src={close} />
        </p>
      </div>
      <div className="modal-desceription">
        {error && (
          <ErrorMessage>
            <p>{error}</p>
          </ErrorMessage>
        )}
        {shouldMarkEnableServices ? (
          <p>You are about to enable work to be done on these spaces.</p>
        ) : (
          <p>You are about to Unmark Enable Services for these spaces.</p>
        )}
      </div>
      <div className="modal-confirmation-text center-container">
        <p>Would you like to continue?</p>
      </div>
      <div className="modal-actions center-container">
        <button
          className="button main-button is-secondary m-r-md"
          onClick={onCancel}>
          Cancel
        </button>
        <button
          className="button main-button is-primary"
          onClick={handleSubmit}>
          Yes
          {enableServicesLoader && (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#ffffff"
              className="m-l-sm"
            />
          )}
        </button>
      </div>
    </div>
  )
}

export default TurnboardEnableServicesModal
