import React, { useEffect, useState } from 'react'

const PriceField = ({ row, onHandleAmountChange }) => {
  const { cell } = row || {}
  const {
    value: initialValue,
    row: { index, original }
  } = cell || {}

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  return (
    <div title={value}>
      <input
        type="text"
        className="input action-input price-input"
        placeholder="Enter price"
        value={value || ''}
        onChange={e => {
          const { target } = e || {}
          const { value } = target || {}
          let filterValue = value || ''
          // Only allow Numbers and .
          filterValue = (value || '').replace(/[^0-9\.]/g, '')
          // Don't allow . as first character
          if ((filterValue || '')[0] === '.') {
            filterValue = ''
          }
          // Check if . already exists
          if ((filterValue || '').toString().split('.').length > 2) {
            filterValue = (filterValue || '').replace(/\.+$/, '')
          }
          // Dont allow more than 2 decimal points
          if (hasDecimalPlace(filterValue, 3)) {
            return
          }
          onHandleAmountChange(filterValue, index, 'price', original)
          setValue(filterValue)
        }}
      />
    </div>
  )
}

export default PriceField
