import React, { useEffect, useState, useCallback, useMemo } from 'react'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { close, closeFilled } from 'ui/icons'
import ErrorTemplate from '../../components/forms-helper/ErrorTemplate'
import PermissionRuleFieldTemplate from '../../components/forms-helper/PermissionRuleFieldTemplate'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import ToggleEnableLogin from './ToggleEnableLogin'
import _, { cloneDeep, isEmpty } from 'lodash'
import { ALLUSERTYPE } from './metadata'
import Short from 'short-uuid'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import {
  filteredProjectList,
  getDistinctPropertiesOrProjects
} from './helper-methods'
import GeneralField from '../../components/forms-helper/GeneralField'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import '../../styles/common.scss'
import BigLoading from '../../components/big-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router'
import PasswordField from '../../components/forms-helper/PasswordField'
import { passwordPolicy } from '../../components/forms-helper/ValidationPolicies'

const EditUser = props => {
  const {
    history,
    setupUsers,
    user,
    newUserDetail,
    clearUser,
    createOrEditUser,
    setAlert
  } = props || {}
  const { pdbid } = user?.user_metadata
  const [errorMessage, setErrorMessage] = useState('')
  const [permissionErrorMessage, setPermissionErrorMessage] = useState('')
  const [permissionRules, setPermissionRules] = useState([])
  const [allPropertyList, setAllPropertyList] = useState([])
  const [UserName, setUserName] = useState('')
  const [userId, setUserId] = useState(null)
  const [userTitle, setUserTitle] = useState('')
  const [userType, setUserType] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userAltPhone, setUserAltPhone] = useState('')
  const [userNote, setUserNote] = useState('')

  const [shouldGeneratePassword, setShouldGeneratePassword] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidpassword, setIsValidPassword] = useState(false)
  const [isValidConfirmpassword, setIsValidConfirmPassword] = useState(false)

  const [emailError, setEmailError] = useState('')
  const [email, setEmail] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { propertiesList, propertiesListLoading, rolesList, userDetail } =
    setupUsers || {}
  const { isRequesting, data: userDetailData } = userDetail || {}

  const params = useParams()

  const checkEnableLoginAccessInPermissionRule = useMemo(
    () => (permissionRules || []).some(rule => !rule.login_access),
    [permissionRules]
  )

  const allDistinctProperties = useMemo(() => {
    return getDistinctPropertiesOrProjects(
      [...new Set(propertiesList || [])],
      'property_id'
    )
  }, [propertiesList])

  const filteredRoles = useMemo(() => {
    const { roles } = user || {}
    const rolesToToRemove = ['Vendor']

    const isPropertyManager = (roles || []).some(
      role => role?.name === 'Property Manager'
    )

    if (isPropertyManager) {
      rolesToToRemove.push('Administrator')
    }

    return (rolesList || []).filter(
      role => !rolesToToRemove.includes(role.name)
    )
  }, [user, rolesList])

  const getProperty = id => {
    return (allDistinctProperties || []).filter(
      property => property?.property_id === id
    )
  }

  const getProject = (property_id, project_id) => {
    const selectedProject = (allDistinctProperties || []).filter(
      project =>
        project?.property_id === property_id &&
        project.project_id === project_id
    )
    return selectedProject || []
  }

  const getRole = id => {
    return (filteredRoles || []).filter(role => role?.role_id === id)
  }

  const groupByAndContructData = (ruleData, cardesOrder) => {
    const groupedItems = _.groupBy(
      ruleData || [],
      item => item.uniqueRuleCardId
    )

    let data = []
    let groupedItemsData = Object.keys(groupedItems || {}) || []
    groupedItemsData.forEach(itemData => {
      if (groupedItems[itemData]?.length) {
        // For single group by array data
        if (groupedItems[itemData]?.length === 1) {
          data.push({
            ...groupedItems[itemData][0],
            uniqueRuleCardId: itemData
          })
        }
        // For multiple group by array data
        if (groupedItems[itemData]?.length > 1) {
          // For adding same id data in single attb
          let properties = []
          let projects = []
          let remainingData = {}

            ; (groupedItems[itemData] || []).forEach(item => {
              remainingData = {
                ...remainingData,
                [item?.property_id]: item?.permission_rule_id
              }
              properties.push(...item?.properties)
              projects.push(...item?.projects)
            })

          properties = getDistinctPropertiesOrProjects(
            properties,
            'property_id'
          )

          projects = getDistinctPropertiesOrProjects(properties, 'project_id')

          const newData = {
            ...remainingData,
            internal_permission_rule_id:
              groupedItems[itemData][0].internal_permission_rule_id,
            uniqueRuleCardId: itemData,
            action: groupedItems[itemData][0].action,
            login_access: groupedItems[itemData][0].login_access,
            status: groupedItems[itemData][0].status,
            properties,
            projects: [
              ...new Map(
                (projects || []).map(item => [item['project_id'], item])
              ).values()
            ],
            role: groupedItems[itemData][0].role
          }

          data.push(newData)
        }
      }
    })
    return sortArray(cardesOrder, data)

  }

  const getOrder = (permRule) => {
    let cardOrder = []
    permRule.map(v => {
      if (!cardOrder.includes(v.internal_permission_rule_id))
        cardOrder.push(v.internal_permission_rule_id)
    })
 return cardOrder

  }

  const getPermissionDataFromId = (ruleData) => {


    const cardesOrder = getOrder(ruleData)


    const fillDataById = (ruleData || [])
      .filter(rule => rule.action !== 'Delete')
      .map(rule => {
        const {
          property_id,
          project_id,
          role_id,
          permission_rule_id,
          action,
          status,
          internal_permission_rule_id
        } = rule || {}
        const login_access = rule?.login_access === 'false' ? false : true
        const uniqueRuleCardId = `${internal_permission_rule_id}-${login_access}-${role_id}`

        return {
          properties: getProperty(property_id),
          projects: getProject(property_id, project_id),
          role: getRole(role_id),
          permission_rule_id,
          login_access,
          action: 'Update',
          uniqueRuleCardId,
          status,
          internal_permission_rule_id,
          [property_id]: permission_rule_id,
          property_id
        }
      })



    return groupByAndContructData(fillDataById, cardesOrder)
  }

  const sortArray = (requiredOrder, permissionRules) => {
    // Reorder array2 based on array1's order
    const sortedArray = requiredOrder.map(aValue =>
      permissionRules.find(item => item.internal_permission_rule_id === aValue)
    ).filter(Boolean)// Remove undefined values if any 'a' in array1 is not in array2
    return sortedArray
  }

  const getStaffRole = useMemo(
    () => (filteredRoles || []).filter(d => d.name === 'Staff'),
    [filteredRoles]
  )

  const filteredPermissionRules = useMemo(() => {
    return (permissionRules || []).filter(rule => rule.action !== 'Delete')
  }, [permissionRules])

  const permissionRulesToRender = useMemo(() => {
   return getPermissionDataFromId(permissionRules)
  }, [permissionRules])




  const toggleGeneratePassword = value => {
    setShouldGeneratePassword(value)
    setPassword('')
    setConfirmPassword('')
    setIsValidConfirmPassword(false)
    setIsValidPassword(false)
  }

  const getValidPassword = value => value?.match(passwordPolicy) !== null

  const optimizedPasswordValidation = useCallback((value, type) => {
    if (!getValidPassword(value)) {
      type(true)
    } else {
      type(false)
    }
  }, [])

  const notValidPasswordError = resetState => {
    return (
      <ErrorTemplate>
        <div>
          <p>Invalid password. Password must match the following criteria:</p>
          <ol className="password-error">
            <li>Password must contain at least 8 Characters.</li>
            <li>An Uppercase character.</li>
            <li>A lowercase character.</li>
            <li>A Number.</li>
            <li>A Special Character.</li>
          </ol>
        </div>
      </ErrorTemplate>
    )
  }

  const convertNullToString = data => {
    if (data === null) {
      return ''
    }
    return data
  }

  const resetErrorMessage = () => {
    setPermissionErrorMessage('')
    setErrorMessage('')
  }

  const onHandleNewPermission = () => {
    resetErrorMessage()
    const internal_permission_rule_id = Short.uuid()
    setPermissionRules([
      ...permissionRules,
      {
        property_id: null,
        project_id: null,
        internal_permission_rule_id,
        permission_rule_id: null,
        action: 'Create',
        status: null,
        role_id: getStaffRole[0]?.role_id,
        login_access: null
      }
    ])
  }

  const getUniqueRuleCardId = rule => {
    const login_access = rule?.login_access === 'false' ? false : true
    return `${rule.internal_permission_rule_id}-${login_access}-${rule?.role_id}`
  }

  const onHandleRemovePermission = (id, currentPermission) => {
    resetErrorMessage()

    const isNewPermission = (currentPermission || {}).action === 'Create'

    if (isNewPermission) {
      setPermissionRules(prev =>
        prev.filter(permission => getUniqueRuleCardId(permission) !== id)
      )
    } else {
      setPermissionRules(prev =>
        prev
          // .filter(permission => permission.permission_rule_id)
          .map(permission =>
            getUniqueRuleCardId(permission) === id
              ? {
                ...permission,
                action: 'Delete'
              }
              : permission
          )
      )
    }
  }

  const removeNewlyCreatedPropertiesOrProjects = (
    rules,
    removedPropertyOrProjectId,
    uniqueRuleCardId,
    key
  ) => {
    const ruleFound = (rules || []).find(
      rule =>
        removedPropertyOrProjectId === rule[key] &&
        getUniqueRuleCardId(rule) === uniqueRuleCardId &&
        rule.action === 'Create' &&
        !rule.permission_rule_id
    )

    const index = (rules || []).indexOf(ruleFound)

    const filteredRules = (rules || []).filter(
      rule =>
        !(
          removedPropertyOrProjectId === rule[key] &&
          getUniqueRuleCardId(rule) === uniqueRuleCardId &&
          rule.action === 'Create' &&
          !rule.permission_rule_id
        )
    )

    return { filteredRules, index }
  }

  const removeExistingPropertiesOrProjects = (
    rules,
    removedPropertyOrProjectId,
    uniqueRuleCardId,
    key
  ) => {
    return (rules || []).map(rule => {
      if (
        getUniqueRuleCardId(rule) === uniqueRuleCardId &&
        removedPropertyOrProjectId === rule[key]
      ) {
        return {
          ...rule,
          action: 'Delete'
        }
      }
      return rule
    })
  }

  const wasPropertyOrProjectExist = (
    rules,
    newPropertyOrProjectId,
    uniqueRuleCardId,
    key
  ) => {
    return rules?.some(
      rule =>
        getUniqueRuleCardId(rule) === uniqueRuleCardId &&
        rule[key] === newPropertyOrProjectId &&
        rule.action === 'Delete'
    )
  }

  const updateActionKeyOfExistingProperty = (
    rules,
    newPropertyOrProjectId,
    uniqueRuleCardId,
    key
  ) => {
    return (rules || []).map(rule => {
      if (
        getUniqueRuleCardId(rule) === uniqueRuleCardId &&
        rule[key] === newPropertyOrProjectId &&
        rule.action === 'Delete'
      ) {
        return {
          ...rule,
          action: 'Update'
        }
      }
      return rule
    })
  }

  const checkIfPermissionRulesAreEmpty = rules => {
    return (rules || []).every(rule => rule.action === 'Delete')
  }

  const updateProperties = (
    newPropertyId,
    removedPropertyId,
    projects,
    updatedPermission,
    uniqueRuleCardId
  ) => {

    const rules = cloneDeep(permissionRules)


    // This will remove all the newly created permission rules which were not saved before

    const { filteredRules, index } = removeNewlyCreatedPropertiesOrProjects(
      rules,
      removedPropertyId,
      uniqueRuleCardId,
      'property_id'
    )

 

    // This will remove all the existing permission rules
    // which were previously saved on backend by just changing there action key to Delete

    let updatedRules = removeExistingPropertiesOrProjects(
      filteredRules,
      removedPropertyId,
      uniqueRuleCardId,
      'property_id'
    )



    // This function will verify the property we are just going to add already exists in the current
    // internal_permission_rule_id with action equals to Delete

    let newPermissionRules = []

    newPropertyId.map(v => {
      const wasPropertyExists = wasPropertyOrProjectExist(
        updatedRules,
        v,
        uniqueRuleCardId,
        'property_id'
      )


      if (wasPropertyExists) {
        //Change the action key from Delete to Update if property was exists



        updatedRules = updateActionKeyOfExistingProperty(
          updatedRules,
          v,
          uniqueRuleCardId,
          'property_id'
        )

      } else {
        const newPropertyProjects = (projects || []).filter(
          project => project.property_id === v
        )

        const temp = newPropertyProjects?.map(({ project_id }) => ({
          property_id: v,
          project_id,
          internal_permission_rule_id:
            updatedPermission.internal_permission_rule_id,
          uniqueRuleCardId,
          permission_rule_id: null,
          action: 'Create',
          status: updatedPermission.status,
          role_id: updatedPermission?.role[0]?.role_id,
          login_access: updatedPermission?.login_access ? null : 'false'
        }))

        if (temp.length == 1)

          newPermissionRules.push(temp[0])
      }


    })





    let finalPermissionRules = [...updatedRules]

    if (!isEmpty(newPermissionRules)) {

      if (index >= 0)
        finalPermissionRules.splice(index, 0, ...newPermissionRules)
      else
        finalPermissionRules = [...finalPermissionRules, ...newPermissionRules]
    }




    const arePermissionRulesEmpty = checkIfPermissionRulesAreEmpty(
      finalPermissionRules
    )

    if (arePermissionRulesEmpty) {
      const lastRemovedRule = (finalPermissionRules || []).find(
        rule => rule.property_id === removedPropertyId
      )
      finalPermissionRules = [
        ...finalPermissionRules,
        {
          property_id: null,
          project_id: null,
          uniqueRuleCardId,
          internal_permission_rule_id:
            lastRemovedRule?.internal_permission_rule_id,
          permission_rule_id: null,
          action: 'Create',
          status: lastRemovedRule?.status,
          role_id: lastRemovedRule?.role_id,
          login_access: lastRemovedRule?.login_access
        }
      ]
    }

    setPermissionRules(() => finalPermissionRules)
  }




  const updateProjects = (
    newProjectId,
    removedProjectId,
    updatedPermission,
    uniqueRuleCardId
  ) => {
    const rules = cloneDeep(permissionRules)

    // This will remove all the newly created permission rules which were not saved before

    const { filteredRules, index } = removeNewlyCreatedPropertiesOrProjects(
      rules,
      removedProjectId,
      uniqueRuleCardId,
      'project_id'
    )

    let updatedRules = removeExistingPropertiesOrProjects(
      filteredRules,
      removedProjectId,
      uniqueRuleCardId,
      'project_id'
    )

    const wasProjectExists = wasPropertyOrProjectExist(
      updatedRules,
      newProjectId,
      uniqueRuleCardId,
      'project_id'
    )

    let newPermissionRule = {}

    if (wasProjectExists) {
      //Change the action key from Delete to Update if property was exists

      updatedRules = updateActionKeyOfExistingProperty(
        updatedRules,
        newProjectId,
        uniqueRuleCardId,
        'project_id'
      )
    } else {
      let foundProperty = (propertiesList || []).find(
        property => property.project_id === newProjectId
      )

      if (foundProperty) {
        newPermissionRule = {
          property_id: foundProperty?.property_id,
          project_id: newProjectId,
          uniqueRuleCardId,
          internal_permission_rule_id:
            updatedPermission.internal_permission_rule_id,
          permission_rule_id: null,
          action: 'Create',
          status: updatedPermission.status,
          role_id: updatedPermission?.role[0]?.role_id,
          login_access: updatedPermission?.login_access ? null : 'false'
        }
      }
    }

    let finalPermissionRules = [...updatedRules]

    if (!isEmpty(newPermissionRule)) {
      if (index >= 0) finalPermissionRules.splice(index, 0, newPermissionRule)
      else finalPermissionRules = [...finalPermissionRules, newPermissionRule]
    }

    const arePermissionRulesEmpty = (finalPermissionRules || []).every(
      rule => rule.action === 'Delete'
    )

    if (arePermissionRulesEmpty) {
      const lastRemovedRule = (finalPermissionRules || []).find(
        rule => rule.project_id === removedProjectId
      )

      finalPermissionRules = [
        ...finalPermissionRules,
        {
          property_id: null,
          project_id: null,
          uniqueRuleCardId,
          internal_permission_rule_id:
            updatedPermission?.internal_permission_rule_id,
          permission_rule_id: null,
          action: 'Create',
          status: lastRemovedRule?.status,
          role_id: lastRemovedRule?.role_id,
          login_access: lastRemovedRule?.login_access
        }
      ]
    }

    setPermissionRules(() => finalPermissionRules)
  }

  const intersection = (listOne, listTwo, key) => {
    const ids = (listTwo || []).map(property => property[key])

    return (listOne || [])
      .filter(property => {
        return !ids?.includes(property[key])
      })
      ?.map(property => property[key])
  }

  const onHandleSelectedProperties = (id, data, index, currentPermission) => {
    resetErrorMessage()
    const updatedPermission = cloneDeep(currentPermission)

    const isSelectAllSelected = data.filter((value) => value.property_id == 'select-all')

    if (isSelectAllSelected.length != 0) {

      let updatedData = data.filter(i => i.property_id != 'select-all')
      let selected_properties = []
      permissionRulesToRender.map(a => a.properties.map(b => selected_properties.push(b.property_id)))

      let properties_id_list = []
      allPropertyList.map(v => {
        if (!selected_properties.includes(v)) {
          properties_id_list.push(v.property_id)
          updatedData.push(v)

        }

      })

      const projects = filteredProjectList(updatedData, propertiesList || []) || []

      updateProperties(
        properties_id_list,
        null,
        projects,
        updatedPermission,
        id
      )
    }
    else {


      const newPropertyId =
        intersection(data, updatedPermission.properties, 'property_id')[0] || null

      const removedPropertyId =
        intersection(updatedPermission.properties, data, 'property_id')[0] || null

      const projects = filteredProjectList(data, propertiesList || []) || []


      updateProperties(
        [newPropertyId],
        removedPropertyId,
        projects,
        updatedPermission,
        id
      )

    }



  }

  const onHandleUserRole = (id, data, index, currentPermission) => {
    resetErrorMessage()

    if ((data || []).length) {
      setPermissionRules(prev =>
        prev.map(permission =>
          getUniqueRuleCardId(permission) === id
            ? { ...permission, role_id: data[0]?.role_id }
            : permission
        )
      )
    }
  }

  const onHandleEnableLogin = (id, data, index, currentPermission) => {
    resetErrorMessage()
    setPermissionRules(prev =>
      prev.map(permission =>
        getUniqueRuleCardId(permission) === id
          ? {
            ...permission,
            login_access: data ? null : 'false'
          }
          : permission
      )
    )
  }

  const onHandleUserProject = (id, data, index, currentPermission) => {
    resetErrorMessage()
    const updatedPermission = cloneDeep(currentPermission)

    const newProjectId =
      intersection(data, updatedPermission.projects, 'project_id')[0] || null
    const removedProjectId =
      intersection(updatedPermission.projects, data, 'project_id')[0] || null

    updateProjects(newProjectId, removedProjectId, updatedPermission, id)
  }

  const isSubmitButtonEnable = useMemo(() => {
    if (
      !(UserName || '').trim() ||
      !(email || '').trim() ||
      emailError ||
      !userType
    ) {
      return true
    }

    if (
      checkEnableLoginAccessInPermissionRule &&
      !shouldGeneratePassword &&
      (!(password || '').trim() ||
        !(confirmPassword || '').trim() ||
        isValidpassword ||
        isValidConfirmpassword ||
        (password || '').trim() !== (confirmPassword || '').trim())
    ) {
      return true
    }

    if (!(permissionRulesToRender || []).length) {
      return true
    }

    if ((permissionRulesToRender || []).length) {
      const checkIfPermssionSetEmpty = (permissionRulesToRender || []).map(
        perm_set => {
          const { properties, projects, role } = perm_set || {}
          if (
            !(properties || []).length ||
            !(projects || []).length ||
            !(role || []).length
          ) {
            return true
          }

          return false
        }
      )
      const isEmpty = (checkIfPermssionSetEmpty || []).filter(d => d === true)
      if ((isEmpty || []).length) {
        return true
      }
    }
    return false
  }, [
    UserName,
    email,
    emailError,
    permissionRulesToRender,
    userType,
    checkEnableLoginAccessInPermissionRule,
    shouldGeneratePassword,
    password,
    confirmPassword,
    isValidpassword,
    isValidConfirmpassword
  ])

  const onError = () => {
    setIsSubmitting(false)
    setAlert('Something went wrong. Please try again later!!!', 'danger')
  }

  const onSuccess = () => {
    setIsSubmitting(false)
    setAlert('User is successfully Updated', 'success')
    return history.push(`/setup/users/view/${userId}`)
  }

  const onHandleAddUser = () => {
    const body = {
      user_id: userId,
      auth_id: userDetailData?.auth_id,
      email,
      name: UserName,
      type: userType?.name,
      role_type: 'Staff',
      title: userTitle || null,
      phone: userPhone || null,
      alt_phone: userAltPhone || null,
      notes: userNote || null,
      hire_date: null,
      term_date: null,
      is_inactive: userDetailData?.is_inactive,
      permission_rules: permissionRules,
      app_access: userDetailData?.app_access ? null : 'false'
    }
    if (!shouldGeneratePassword) {
      body.password = password
    }
    setIsSubmitting(true)
    createOrEditUser(pdbid, body, onSuccess, onError)
  }

  useEffect(() => {
    newUserDetail(
      pdbid,
      params.id,
      () => { },
      () => {
        setErrorMessage('Something went wrong. Please try again later!!!')
      }
    )
    return () => {
      clearUser()
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(userDetailData)) {
      const { user_id, name, email, type, title, phone, alt_phone, notes } =
        userDetailData || {}

      setUserId(user_id)
      setUserName(convertNullToString(name))
      setEmail(convertNullToString(email))
      setUserType(
        convertNullToString(email) !== ''
          ? {
            name: type
          }
          : ''
      )
      setUserTitle(convertNullToString(title))
      setUserPhone(convertNullToString(phone))
      setUserAltPhone(convertNullToString(alt_phone))
      setUserNote(convertNullToString(notes))

      const mappedPermissionRules = (
        userDetailData?.permission_rules || []
      ).map(rule => ({
        action: 'Update',
        internal_permission_rule_id: rule.internal_permission_rule_id,
        login_access: rule.login_access,
        permission_rule_id: rule.permission_rule_id,
        project_id: rule.project?.[0]?.project_id,
        property_id: rule.property_id || rule.project[0]?.property_id,
        role_id: rule.role_id,
        status: rule.status
      }))
      setPermissionRules(mappedPermissionRules)
    }
  }, [userDetailData])

  useEffect(() => {
    const allSelectedPropertyIds = (filteredPermissionRules || []).map(
      rule => rule.property_id
    )

    const filterProperties = (allDistinctProperties || []).filter(
      property =>
        !(allSelectedPropertyIds || [])?.includes(property?.property_id)
    )

    setAllPropertyList(filterProperties)
  }, [filteredPermissionRules, allDistinctProperties])

  useEffect(() => {
    if (!checkEnableLoginAccessInPermissionRule) {
      toggleGeneratePassword(true)
    }
  }, [checkEnableLoginAccessInPermissionRule])

  if (isRequesting || propertiesListLoading) {
    return <BigLoading />
  }

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>{errorMessage}</p>
      </div>
    )
  }

  if (userDetailData?.type === 'Vendor') {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>
          Unfortunately, administrators do not have the ability to edit vendor
          user's details.
        </p>
      </div>
    )
  }

  return (
    <div className="columns is-multiline invite-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Personal Information</strong>
            </div>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Name">
              <input
                id="name"
                name="name"
                value={UserName}
                onChange={e => setUserName(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Name"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Email Address">
              <input
                id="email"
                name="email"
                value={email}
                type="text"
                disabled={true}
                autoComplete="off"
                // onChange={e => {
                //   setEmail(e.target.value)
                //   optimizedEmailHandleValidation(e.target.value)
                // }}
                placeholder="Enter Email Address"
                className="input form-control"></input>
            </GeneralField>
          </FieldTemplate>
          {emailError !== '' && (
            <ErrorTemplate>
              <div>
                <p>{emailError}</p>
                <p>
                  Example:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    noname@myturnable.com
                  </span>
                </p>
              </div>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <GeneralField fieldLabel="Title" isRequired={false}>
              <input
                id="title"
                name="title"
                value={userTitle}
                onChange={e => setUserTitle(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Title"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Type">
              <div style={{ marginTop: '2px' }}>
                <MultiSelectDropdown
                  displayKey="name"
                  value={userType}
                  defaultValues={ALLUSERTYPE}
                  onChange={v => setUserType(v)}
                  dropdownIcon={true}
                  isMulti={false}
                  placeholder="Select User Type"
                  noOptionsMessage="No Type Available"
                  customStyles={selectStyles}
                  menuPortalTarget={document.body}
                />
              </div>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Phone" isRequired={false}>
              <input
                id="phone"
                name="phone"
                value={userPhone}
                onChange={e => setUserPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Alt Phone" isRequired={false}>
              <input
                id="altphone"
                name="altphone"
                value={userAltPhone}
                onChange={e => setUserAltPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Alt Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate isDisabledVcentered>
            <GeneralField fieldLabel="Notes" isRequired={false}>
              <textarea
                className="textarea  input form-control"
                rows={3}
                style={{ resize: 'none', marginTop: '2px' }}
                defaultValue={userNote}
                onChange={e => setUserNote(e.target.value)}
              />
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Permission Rules</strong>
            </div>
          </FieldTemplate>
          {(permissionRulesToRender || []).map((userPerm, i) => {
            const { uniqueRuleCardId } = userPerm

            return (
              <div className="columns is-vcentered mb-0" key={uniqueRuleCardId}>
                <div
                  className="column is-full is-10-fullhd is-offset-1-fullhd"
                  key={uniqueRuleCardId}>
                  <div className="user-permission-container">
                    <div className="columns is-desktop is-mobile mb-0">
                      <div className="column permission-label">
                        <strong>{`Permission Rule ${i + 1}:`}</strong>
                        {(permissionRulesToRender || []).length > 1 && (
                          <img
                            onClick={() =>
                              onHandleRemovePermission(
                                uniqueRuleCardId,
                                userPerm
                              )
                            }
                            alt="Delete Permission Rule"
                            className="permission-delete"
                            src={close}
                          />
                        )}
                      </div>
                    </div>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Properties">
                        <MultiSelectDropdown
                          displayKey="property_name"
                          uniqueKey="property_id"
                          value={userPerm?.properties}
                          defaultValues={allPropertyList}
                          dropdownIcon={true}
                          onChange={v =>
                            onHandleSelectedProperties(
                              uniqueRuleCardId,
                              v,
                              i,
                              userPerm
                            )
                          }
                          isMulti={true}
                          placeholder="Select Properties"
                          noOptionsMessage= {propertiesList.length == 0?'No Properties Available':'All properties have already been selected' } 
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Projects">
                        <MultiSelectDropdown
                          displayKey="project_name"
                          uniqueKey="project_id"
                          value={userPerm?.projects}
                          dropdownIcon={true}
                          //isDisabled={!(userPerm?.properties || []).length}
                          defaultValues={filteredProjectList(
                            userPerm?.properties,
                            propertiesList || []
                          )}
                          onChange={v =>
                            onHandleUserProject(
                              uniqueRuleCardId,
                              v,
                              i,
                              userPerm
                            )
                          }
                          isMulti={true}
                          placeholder="Select Projects"
                          noOptionsMessage="No Projects Available"
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField
                        fieldLabel="Enable Login"
                        isRequired={false}>
                        <ToggleEnableLogin
                          onChange={v =>
                            onHandleEnableLogin(
                              uniqueRuleCardId,
                              v,
                              i,
                              userPerm
                            )
                          }
                          isActive={userPerm?.login_access}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                    <PermissionRuleFieldTemplate>
                      <GeneralField fieldLabel="Role">
                        <MultiSelectDropdown
                          displayKey="name"
                          value={userPerm?.role}
                          defaultValues={filteredRoles}
                          dropdownIcon={true}
                          onChange={v =>
                            onHandleUserRole(uniqueRuleCardId, [v], i, userPerm)
                          }
                          isMulti={false}
                          isDisabled={!userPerm?.login_access}
                          placeholder="Select Role"
                          noOptionsMessage="No Roles Available"
                          customStyles={selectStyles}
                        />
                      </GeneralField>
                    </PermissionRuleFieldTemplate>
                  </div>
                </div>
              </div>
            )
          })}
          {permissionErrorMessage !== '' && (
            <ErrorTemplate>
              <p>{permissionErrorMessage}</p>
            </ErrorTemplate>
          )}
          <FieldTemplate>
            <div className="column">
              <label className="link" onClick={() => onHandleNewPermission()}>
                + Add Another Permission Rule
              </label>
            </div>
          </FieldTemplate>

          {!userDetailData.email_verified &&
            checkEnableLoginAccessInPermissionRule && (
              <>
                {' '}
                <FieldTemplate>
                  <div className="label-heading column">
                    <strong>Password</strong>
                  </div>
                </FieldTemplate>
                <FieldTemplate>
                  <div className="column">
                    <label>
                      The password will be shared with the user via email
                    </label>
                  </div>
                </FieldTemplate>
                <FieldTemplate>
                  <div className="column">
                    <label
                      className={`checkbox ${shouldGeneratePassword &&
                        'is-active'}`}>
                      <span
                        className={`checkbox-input ${shouldGeneratePassword &&
                          'is-active'}`}>
                        <span className="checkbox-inner" />
                        <input
                          type="checkbox"
                          className="checkbox-original"
                          checked={shouldGeneratePassword}
                          name="generatepassword"
                          onChange={e =>
                            toggleGeneratePassword(e.target.checked)
                          }
                        />
                      </span>
                      <span
                        className={`checkbox-label ${shouldGeneratePassword &&
                          'is-active'}`}>
                        Generate Password
                      </span>
                    </label>
                  </div>
                </FieldTemplate>
                {!shouldGeneratePassword && (
                  <>
                    <FieldTemplate>
                      <GeneralField fieldLabel="Password">
                        <PasswordField
                          id="password"
                          name="password"
                          value={password}
                          onChange={e => {
                            let value = e.target.value
                            if (!value.includes(' ')) {
                              setConfirmPassword('')
                            }
                            value = value.replace(' ', '')
                            setPassword(value)
                            setIsValidConfirmPassword(false)
                            optimizedPasswordValidation(
                              value,
                              setIsValidPassword
                            )
                          }}
                        />
                      </GeneralField>
                    </FieldTemplate>
                    {isValidpassword &&
                      notValidPasswordError(setIsValidPassword)}
                    <FieldTemplate>
                      <GeneralField fieldLabel="Confirm Password">
                        <PasswordField
                          id="confirmpassword"
                          name="confirmpassword"
                          value={confirmPassword}
                          disabled={!(password || '').trim() || isValidpassword}
                          onChange={e => {
                            let value = e.target.value
                            value = value.replace(' ', '')
                            setConfirmPassword(value)
                            optimizedPasswordValidation(
                              value,
                              setIsValidConfirmPassword
                            )
                          }}
                        />
                      </GeneralField>
                    </FieldTemplate>
                    {isValidConfirmpassword &&
                      notValidPasswordError(setIsValidConfirmPassword)}
                    {!isValidpassword &&
                      !isValidConfirmpassword &&
                      password &&
                      confirmPassword &&
                      (password || '').trim() !==
                      (confirmPassword || '').trim() && (
                        <ErrorTemplate>
                          <p>Password and Confirm Password do not match</p>
                        </ErrorTemplate>
                      )}
                  </>
                )}
              </>
            )}
          <div>
            <div className="button-wrapper">
              <button
                className="button main-button is-secondary m-r-md"
                onClick={() => history.push(`/setup/users/view/${params.id}`)}>
                Cancel
              </button>

              <span data-tip data-for="editNewUser">
                <button
                  onClick={() => {
                    if (!isSubmitButtonEnable) {
                      onHandleAddUser()
                    }
                  }}
                  className={`button main-button is-primary ${isSubmitButtonEnable || isSubmitting ? 'isDisabled' : ''
                    }`}>
                  Save
                  {isSubmitting && (
                    <FontAwesomeIcon icon={faSpinner} spin className="m-l-sm" />
                  )}
                </button>
              </span>
              <ReactTooltip
                className="customTooltipTheme"
                id="editNewUser"
                place="top"
                disable={!isSubmitButtonEnable}
                effect="solid">
                <div>
                  <p>
                    Please fill all the required (
                    <span className="required-star">*</span>) fields
                  </p>
                </div>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditUser
