import React from 'react'
import './index.scss'
import _ from 'lodash'

const ReactTablePagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  currentPage,
  previousPage,
  nextPage,
  setRowsPerPage,
  limit = [],
  addAllLimit = true
}) => {
  const getNumberOfPages = (row_count, rows_per_page) => {
    return Math.ceil(row_count / rows_per_page)
  }
  const page = currentPage || 0

  const toPages = all_pages => {
    const results = []

    for (let i = 1; i <= (all_pages || []); i++) {
      results.push(i)
    }

    return results
  }

  const pages = getNumberOfPages(rowCount, rowsPerPage)

  const pageItems = toPages(pages)

  const nextDisabled = currentPage === pageItems.length
  const previousDisabled = currentPage === 1

  const getPaginationList = () => {
    let template = []
    let totalPages = getNumberOfPages(rowCount, rowsPerPage) || 0

    if (totalPages > 6) {
      let start = page - 1
      if (page === 1 || page === 2) {
        start = 2
      } else if (page === totalPages) {
        start = page - 3
      } else if (page === totalPages - 1) {
        start = page - 2
      }

      template.push(
        <ul key={0} className="paginator-container">
          <li>
            <button
              className={`pagination-link is-pointer ${
                page === 1 ? 'is-current' : ''
              }`}
              aria-label="Goto page 1"
              onClick={() => {
                onChangePage(1)
              }}>
              1
            </button>
          </li>
          {page > 3 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
          {page < totalPages - 1 && (
            <li>
              <button
                className={`pagination-link is-pointer ${
                  page === start ? 'is-current' : ''
                }`}
                aria-label={`Goto page ${start}`}
                onClick={() => {
                  onChangePage(start)
                }}>
                {start}
              </button>
            </li>
          )}
          <li>
            <button
              className={`pagination-link is-pointer ${
                page === start + 1 ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${start + 1}`}
              onClick={() => {
                onChangePage(start + 1)
              }}>
              {start + 1}
            </button>
          </li>
          {page > 2 && (
            <li>
              <button
                className={`pagination-link is-pointer ${
                  page === start + 2 ? 'is-current' : ''
                }`}
                aria-label={`Goto page ${start + 2}`}
                onClick={() => {
                  onChangePage(start + 2)
                }}>
                {start + 2}
              </button>
            </li>
          )}
          {page < totalPages - 2 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}

          <li>
            <button
              className={`pagination-link is-pointer ${
                page === totalPages ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${totalPages}`}
              onClick={() => {
                onChangePage(totalPages)
              }}>
              {totalPages}
            </button>
          </li>
        </ul>
      )
    } else {
      for (let i = 1; i <= totalPages; i += 1) {
        template.push(
          <li key={i}>
            <button
              className={`pagination-link is-pointer ${
                page === i ? 'is-current' : ''
              }`}
              aria-label={`Goto page ${i}`}
              onClick={() => {
                onChangePage(i)
              }}>
              {i}
            </button>
          </li>
        )
      }
    }
    return template
  }

  const startNumber = 1 + (page - 1) * rowsPerPage
  const endNumber = Math.min(page * rowsPerPage, rowCount)

  const optionsLimit = _.orderBy(limit)

  return (
    <div className={rowCount > 0 ? 'react-table-pagination' : ''}>
      {(optionsLimit || []).length > 0 && optionsLimit[0] < rowCount && (
        <div className="row-per-page counter-section">
          <div style={{ marginRight: '5px' }}>
            <label>Rows per page</label>
          </div>
          <div className="select input-field">
            <select
              className="input is-inline"
              value={rowsPerPage}
              onChange={e => setRowsPerPage(Number(e.target.value))}>
              {_.map(
                _.filter(optionsLimit, option => option < rowCount),
                option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              )}

              {addAllLimit && (
                <option key={rowCount} value={rowCount}>
                  All
                </option>
              )}
            </select>
          </div>
        </div>
      )}
      <div className="table-pagination-wrapper  counter-section">
        {rowCount > 0 ? (
          <>
            <div className="pagination-count">
              <p>{`Showing ${startNumber} to ${endNumber} of ${rowCount} entries`}</p>
            </div>
            <div className="pages">
              <nav>
                <ul
                  className="pagination"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <li className="page-item">
                    <button
                      className="page-link previous-page"
                      onClick={previousPage}
                      style={{
                        cursor: previousDisabled ? 'not-allowed' : 'pointer'
                      }}
                      disabled={previousDisabled}
                      aria-label="previous page">
                      Previous
                    </button>
                  </li>
                  {getPaginationList()}
                  <li className="page-item">
                    <button
                      className="page-link next-page"
                      onClick={nextPage}
                      style={{
                        cursor: nextDisabled ? 'not-allowed' : 'pointer'
                      }}
                      disabled={nextDisabled}
                      aria-label="next page">
                      Next Page
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </>
        ) : (
          <>
            <p>No data to show</p>
          </>
        )}
      </div>
    </div>
  )
}

export default ReactTablePagination
