import React, { useMemo } from 'react'
import ApprovalCell from './components/ApprovalCell'
import { icons } from './icons'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import _ from 'lodash'

const getPropertyObject = (user, currentProperty, currentProject) => {
  const { user_metadata } = user || {}
  const { properties } = user_metadata || {}

  const propertyFound = (properties || []).find(
    p => p?.id === currentProperty && p?.project[0].id === currentProject
  )

  return propertyFound
}

export const headerData = ({
  SelectColumnFilter,
  resetPurchaseOrderDetail,
  onHandleViewInvoice,
  updateContext,
  updateUserAllowedSection,
  history,
  user
}) => [
  {
    Header: 'Property',
    accessor: 'property',
    textAlign: 'left',
    Filter: SearchDropdownFilter,
    filter: 'customEquals',
    width: 150,
    Cell: row => {
      return <ApprovalCell row={row} />
    }
  },
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Vendor',
        accessor: 'vendor_name',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        textAlign: 'left',
        width: 150,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow, value } = cell || {}
          const { original } = currentRow || {}
          const { po_number, purchase_order_id, vendor_id, model } =
            original || {}
          let project = ''
          let property = ''
          if (model) {
            project = (model || '')?.split('_')[0]
            property = (model || '')?.split('_')[1]
          }

          const propertyFound = useMemo(
            () => getPropertyObject(user, property, project),
            [property, project]
          )

          const currentRole = [propertyFound?.role?.name]

          if (po_number) {
            return (
              <div
                className="cell-item"
                onClick={() => {
                  if (!_.isEmpty(propertyFound)) {
                    updateUserAllowedSection(
                      project,
                      property,
                      user,
                      currentRole
                    )

                    updateContext(project, property, () => {
                      history.push(
                        `/payables/purchase-orders/${purchase_order_id}/${vendor_id}`
                      )
                      resetPurchaseOrderDetail()
                    })
                  }
                }}>
                <span className={`${!_.isEmpty(propertyFound) ? 'link' : ''}`}>
                  {value || ''}
                </span>
              </div>
            )
          }
          return ''
        }
      },
      {
        Header: 'Invoice Number',
        accessor: 'vendor_purchase_order_number',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'Invoice Date',
        accessor: 'po_date',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'Due Date',
        accessor: 'due_date',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'P.O. Number',
        accessor: 'po_number',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'Total Amount ($)',
        accessor: 'total_amount',
        textAlign: 'left',
        disableFilters: true,
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'Amount Paid ($)',
        accessor: 'amount_paid',
        textAlign: 'left',
        disableFilters: true,
        width: 150,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      {
        Header: 'Date Created',
        accessor: 'custom_complete_date',
        textAlign: 'left',
        width: 130,
        Cell: row => {
          return <ApprovalCell row={row} />
        }
      },
      // {
      //   Header: 'Property',
      //   accessor: 'property',
      //   textAlign: 'left',
      //   Filter: SearchDropdownFilter,
      //   filter: 'customEquals',
      //   //flexGrow: 1,
      //   width: 250,
      //   Cell: row => {
      //     return <ApprovalCell row={row} />
      //   }
      // },
      // {
      //   Header: 'Approved By',
      //   accessor: 'approved_by_name',
      //   textAlign: 'left',
      //   Filter: SearchDropdownFilter,
      //   filter: 'customEquals',
      //   //flexGrow: 1,
      //   width: 160,
      //   Cell: row => {
      //     return <ApprovalCell row={row} />
      //   }
      // },
      // {
      //   Header: 'Approval Date & Time',
      //   accessor: 'approved_at',
      //   textAlign: 'left',
      //   width: 185,
      //   Cell: row => {
      //     return <ApprovalCell row={row} />
      //   }
      // },
      {
        Header: 'Status',
        accessor: 'status',
        textAlign: 'left',
        Filter: SelectColumnFilter,
        filter: 'equals',
        flexGrow: 1,
        width: 185,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { status } = original || {}
          return (
            <div className="cell-item" title={status}>
              <span className={status === 'Approved' && 'is-approved'}>
                {status}
              </span>
            </div>
          )
        }
      },
      {
        Header: 'Invoice',
        accessor: 'invoice_file',
        textAlign: 'center',
        disableSortBy: true,
        disableFilters: true,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { invoice_file, po_number, vendor_purchase_order_number } =
            original || {}
          return (
            <div className="cell-item invoice-icon">
              {invoice_file && (
                <img
                  className="is-pointer is-icon"
                  src={icons.invoiceAttachment}
                  alt="View Invoice"
                  title="View Invoice"
                  onClick={() =>
                    onHandleViewInvoice(
                      invoice_file,
                      po_number,
                      vendor_purchase_order_number
                    )
                  }
                />
              )}
            </div>
          )
        },
        width: 100
      }
    ]
  }
]
