import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { usePrevious } from '../../helpers/hooks'
import {
  resetInvoices,
  createInvoice,
  updateInvoice,
  deleteInvoice,
  generateInvoiceLines,
  createInvoiceLine,
  updateInvoiceLine,
  deleteInvoiceLine
} from '../../modules/invoices'
import { setupAllVendorsGet } from '../../modules/setup-vendors'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import { setAlert } from '../../modules/alerts'
import InvoicesList from '../../parents/invoices-list'
import { getPurchaseOrders } from '../../modules/purchase-orders'

const Invoices = ({
  auth,
  user,
  context,
  invoices,
  setupVendors,
  setupAllVendorsGet,
}) => {
  const dispatch = useDispatch()
  const prevContext = usePrevious(context)
  const { allVendors } = setupVendors

  const populateList = () => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata

    if (
      isAuthenticated &&
      hasMgmtToken &&
      pdbid !== null
    ) {
      dispatch(getPurchaseOrders(pdbid, context))
    }
  }

  const populateVendors = () => {
    const { isAuthenticated, hasMgmtToken } = auth
    const { pdbid } = user.user_metadata
    if (
      isAuthenticated &&
      hasMgmtToken &&
      pdbid !== null
    ) {
      setupAllVendorsGet(pdbid, context)
    }
  }

  useEffect(() => {
    if (
      !invoices.listHasRequested ||
      !prevContext ||
      context.project !== prevContext.project ||
      context.property !== prevContext.property
    ) {
      populateList()
    }

    if (
      !allVendors.hasMadeInitialRequest ||
      !prevContext ||
      context.project !== prevContext.project ||
      context.property !== prevContext.property
    ) {
      populateVendors()
    }
  }, [
    context,
    invoices.listHasRequested,
    user.user_metadata,
    allVendors.hasMadeInitialRequest
  ])

  return (
    <InvoicesList vendors={allVendors.data} />
  )
}

Invoices.propTypes = {
  auth: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  location: PropTypes.object,
  inspect: PropTypes.object,
  getInspectList: PropTypes.func,
  getInspectStats: PropTypes.func,
  modal: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

const mapStateToProps = ({
  modal,
  auth,
  user,
  context,
  invoices,
  setupVendors
}) => ({
  modal,
  auth,
  user,
  context,
  invoices,
  setupVendors
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetInvoices,
      setModalContent,
      openModal,
      closeModal,
      setAlert,
      createInvoice,
      updateInvoice,
      deleteInvoice,
      generateInvoiceLines,
      createInvoiceLine,
      updateInvoiceLine,
      deleteInvoiceLine,
      setupAllVendorsGet
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Invoices)
)
