import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import QcHeader from '../qc-header'
import MobileEmptyState from '../../components/mobile-empty-state'
import { toggleSidebar } from '../../modules/sidebar'
import { emptySearch, arrowRight } from '../../ui/icons'

import './styles.scss'

const QcSearch = ({ toggleSidebar }) => {
  // TODO; mock for search data; [{ unit: 'Unit 1001' }, { unit: 'Unit 1002' }, { unit: 'Unit 1003' }]
  const searchData = []

  return (
    <div className="qc-search is-fullheight">
      <QcHeader title="Search" toggleSidebar={toggleSidebar} />

      <div className="qc-search-content">
        <div className="qc-search-box">
          <div className="is-fullwidth pl-4 pr-4 mt-4">
            <input className="is-fullwidth qc-search-input" placeholder="Start typing here..." />
          </div>
        </div>

        <div className="qc-search-result-title">Results</div>

        {searchData?.map(item => (
          <div className="is-flex qc-search-result-item">
            <div className="is-fullwidth">{item.unit}</div>
            <img src={arrowRight} alt="right-arrow" />
          </div>
        ))}

        {searchData?.length === 0 && (
          <div className="qc-search-empty">
            <MobileEmptyState 
              image={emptySearch}
              title="No results found."
              subtitle="Try adjusting your search to find the unit you’re looking for." />
          </div>
        )}
      </div>
    </div>
  )
}

QcSearch.propTypes = {
  toggleSidebar: PropTypes.func,
}

const mapDispatchToProps = {
  toggleSidebar,
}

export default connect(
  null,
  mapDispatchToProps
)(QcSearch)
