import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Breadcrumbs from '../breadcrumbs'
import PropertyFormsList from '../property-forms-list'
import PropertyFormsManage from '../property-forms-manage'

const PropertyFormsMain = props => {
  const { getPropertyFormsList, user, context, location, history } = props
  const { pdbid } = user.user_metadata
  const templateManageMode = location.pathname.includes('manage')
  const searchParams = new URLSearchParams(location.search)
  const formId = searchParams.get('form')

  useEffect(() => {
    const paramsLength = Array.from(searchParams).length

    if (!paramsLength) {
      getPropertyFormsList(pdbid, context)
    }
  }, [location.search, context, pdbid])

  return (
    <div className="container setup-vendors-main animated fadeIn">
      <div className="section">
        <Breadcrumbs location={location} />
        <div className="columns">
          <div className="column">
            {!templateManageMode ? (
              <PropertyFormsList {...props} />
            ) : (
              <>
                <div className="columns m-b-lg" style={{ marginLeft: '0' }}>
                  <Link to="/inspections-setup/property-forms">
                    Back to List View
                  </Link>
                </div>
                <PropertyFormsManage formId={formId} history={history} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PropertyFormsMain.propTypes = {
  context: PropTypes.object,
  getPropertyFormsList: PropTypes.func,
  location: PropTypes.object,
  user: PropTypes.object
}

export default PropertyFormsMain
