import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import './index.scss'

const DEFAULT_TITLE = 'Are you sure?'

/**
 * Component for showing a confirmation modal
 *
 * @component
 * @example
 * return (
 *   <ConfirmationModal
 *     msg="Are you sure?"
 *     dismissLabel = 'No',
 *     submitLabel = 'Yes',
 *     submit = () => {},
 *     dismiss = () => {}
 *   />
 * )
 */
const ConfirmationModal = ({
  msg,
  dismissLabel = 'No',
  submitLabel = 'Yes',
  modalTitle = DEFAULT_TITLE,
  additionalSubmitButtonClasses = '',
  subMsg,
  submit = () => {},
  dismiss = () => {},
  useLoading = false,
  showSubmitButton = true
}) => {
  const [submitFire, setSubmitFired] = useState(false)

  const handleSubmit = useCallback(() => {
    setSubmitFired(true)
    submit()
  }, [submit])

  return (
    <div className="confirmation-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">{modalTitle}</p>
          <p className="close is-pointer has-text-grey-light" onClick={dismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      {msg && (
        <div className="columns">
          <div className="column is-full">
            <p className="is-size-6">{msg}</p>
            {subMsg && <p className="is-size-6 sub-message">{subMsg}</p>}
          </div>
        </div>
      )}

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className={`button main-button ${
              showSubmitButton ? 'is-secondary' : 'is-primary'
            } m-r-md`}
            onClick={e => {
              e.preventDefault()
              dismiss()
            }}>
            {dismissLabel}
          </button>
          {showSubmitButton && (
            <button
              className={`button main-button is-primary ${additionalSubmitButtonClasses}`}
              onClick={handleSubmit}>
              {useLoading && submitFire && (
                <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
              )}
              {submitLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  msg: PropTypes.string,
  submit: PropTypes.func,
  dismiss: PropTypes.func,
  dismissLabel: PropTypes.string,
  submitLabel: PropTypes.string,
  useLoading: PropTypes.bool
}

ConfirmationModal.defaultProps = {
  /**
   * Title for the modal
   */
  title: DEFAULT_TITLE,
  /**
   * Message for the modal
   */
  msg: '',
  /**
   * Event handler for submit action
   */
  submit: () => {},
  /**
   * Event handler for dismiss action
   */
  dismiss: () => {},
  /**
   * Title for the modal
   */
  dismissLabel: 'No',
  /**
   * Title for the modal
   */
  submitLabel: 'Yes',
  /**
   * Loading state for the modal
   */
  useLoading: false,
  /**
   * Title for the modal
   */
  modalTitle: 'Are you sure?',
  /**
   * Sub Message for the modal
   */
  subMsg: '',
  /**
   * if you want to add additional css classes on Submit Button
   */
  additionalSubmitButtonClasses: '',
  /**
   * Show submit button for the modal
   */
  showSubmitButton: true
}

export default ConfirmationModal
