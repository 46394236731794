import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { getQueue, assignJobs } from '../../modules/qc'
import { toggleSidebar } from '../../modules/sidebar'
import MobileEmptyState from '../../components/mobile-empty-state'
import QcHeader from '../qc-header'
import BigLoading from '../../components/big-loading'
import { ServiceColors } from '../../data'
import { noWork } from '../../ui/icons'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import ColumnSorting from '../../components/app-sorting'

import './index.scss'

const selectStyles = {
  input: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  placeholder: styles => ({
    ...styles,
    padding: '1px 8px'
  }),
  control: (styles, state) => ({
    ...styles,
    minHeight: 23,
    maxHeight: '2.5em',
    cursor: 'pointer',
    height: '2.5em',
    border: '1px solid #e5e9f2',
    borderRadius: 2,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #e5e9f2'
    },
    backgroundColor: state.isDisabled ? 'ghostwhite' : styles.backgroundColor
  }),
  container: styles => ({
    ...styles,
    marginTop: '-3px'
  }),
  valueContainer: styles => ({
    ...styles,
    minHeight: 20,
    width: '100%',
    padding: 0,
    position: 'relative'
  }),
  singleValue: styles => ({
    ...styles,
    marginLeft: '10px',
    marginBottom: '3px'
  }),
  indicatorContainer: styles => ({
    ...styles,
    paddingTop: '4px'
  })
}
function QcQueue({ user, qc, context, getQueue, assignJobs, toggleSidebar }) {
  const history = useHistory()
  const { project, property } = context
  const { projects, properties, pdbid, staffid } = user.user_metadata

  const projectsById = projects.reduce(
    (acc, project) => ({ ...acc, [project.id]: project }),
    {}
  )
  const propertiesById = properties.reduce(
    (acc, property) => ({ ...acc, [property.id]: property }),
    {}
  )
  const projectName = projectsById[project]
    ? projectsById[project].name
    : 'Undefined'
  const propertyName = propertiesById[property]
    ? propertiesById[property].name
    : 'Undefined'

  const { queue, isRequesting } = qc

  const [userTeams, setUserTeams] = useState([])
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [selected, setSelected] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState('')
  const [selectedService, setSelectedService] = useState('')
  const [newQueue, setNewQueue] = useState([])
  const [sorting, setSorting] = useState({ key: '', ascending: true })

  useEffect(() => {
    if (project && property && pdbid && staffid && !isRequesting) {
      getQueue(pdbid, { project, property }, staffid)
    }
  }, [context, user])

  useEffect(() => {
    setNewQueue(queue)
  }, [queue])

  const handleJobClick = job => e => {
    e.stopPropagation()
    history.push(`/qc/job/${job.workorder}`, {
      job,
      property: qc.property,
      status: 'queue'
    })
  }

  const handleJobCheck = job => e => {
    e.stopPropagation()
    setUserTeams(job.qc_team_uri)

    if (job.qc_team_uri.length === 1) {
      setSelectedTeam(job.qc_team_uri[0])
    }

    let newSelected = selected.filter(o => o.workorder !== job.workorder)

    if (newSelected.length === selected.length) {
      newSelected = [...selected, job]
    }

    setSelected(newSelected)
    setOpenPopup(!!newSelected.length)
  }

  const handleAssignClick = async () => {
    try {
      if (!selectedTeam || selectedTeam === 'select-one') {
        return false
      }

      await assignJobs(pdbid, context, staffid, selectedTeam, selected)
      setOpenPopup(false)
      setSelected([])
      setSelectedTeam('select-one')
    } catch (error) {
      console.log('QC Assign failed:', error)
    }
  }

  const isSelected = job => selected.some(o => o.workorder === job.workorder)

  const onSelectTeam = event => setSelectedTeam(event.target.value)

  const applySorting = (key, ascending) => {
    setSorting({ key: key, ascending: ascending })
  }

  useEffect(() => {
    let defaultNewQueue = queue || []
    if (selectedUnit?.unit && selectedService?.service) {
      defaultNewQueue = (defaultNewQueue || []).filter(
        o =>
          o.unit === selectedUnit?.unit &&
          o.service === selectedService?.service
      )
    } else if (selectedUnit?.unit || selectedService?.service) {
      defaultNewQueue = (defaultNewQueue || []).filter(
        o =>
          o.unit === selectedUnit?.unit ||
          o.service === selectedService?.service
      )
    } else {
      defaultNewQueue = defaultNewQueue || []
    }
    setNewQueue(defaultNewQueue)
  }, [selectedUnit, selectedService])

  useEffect(() => {
    if (sorting?.key) {
      if (sorting?.ascending) {
        const myData = []
          .concat(newQueue || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() <
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewQueue(myData)
      } else {
        const myData = []
          .concat(newQueue || [])
          .sort((a, b) =>
            a[sorting?.key || '']?.toString()?.toLowerCase() >
            b[sorting?.key || '']?.toString()?.toLowerCase()
              ? 1
              : -1
          )
        setNewQueue(myData)
      }
    }
  }, [sorting])

  return (
    <div className="qc-queue is-fullheight">
      <QcHeader title="Queue" toggleSidebar={toggleSidebar} />
      <div className="qc-queue-content">
        <nav className="breadcrumb is-medium is-centered">
          <ul>
            <li className="subtitle is-6 has-text-weight-normal m-b-none">
              {projectName}
            </li>
            <li className="subtitle is-6 has-text-weight-normal m-b-none">
              {propertyName}
            </li>
          </ul>
        </nav>
        {isRequesting ? (
          <BigLoading />
        ) : newQueue.length ? (
          <div className="p-l-md p-r-md">
            <div className="subtitle is-5 has-text-weight-medium m-b-sm">
              These units are ready for inspection.
            </div>
            <div className="subtitle is-6 has-text-weight-normal">
              Once you have time, feel free to assign yourself to one or more of
              these units.
            </div>
            {newQueue.length ? (
              <div className="columns is-mobile p-t-sm">
                <div className="column is-half">
                  <div className="columns">
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Unit
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="unit"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="unit"
                        value={selectedUnit}
                        defaultValues={newQueue}
                        onChange={v => setSelectedUnit(v)}
                        isClearable={true}
                        isMulti={false}
                        placeholder="Select Unit"
                        noOptionsMessage="No Unit Available"
                        customStyles={selectStyles}
                      />
                    </div>
                    <div className="column is-half">
                      <label htmlFor="unit" className="is-size-5">
                        Service
                        <ColumnSorting
                          data={sorting}
                          applySorting={applySorting}
                          type="service"
                        />
                      </label>
                      <MultiSelectDropdown
                        displayKey="service"
                        value={selectedService}
                        isClearable={true}
                        defaultValues={_.uniqBy(
                          newQueue || [],
                          obj => obj?.service
                        )}
                        onChange={v => setSelectedService(v)}
                        isMulti={false}
                        placeholder="Select Service"
                        noOptionsMessage="No Service Available"
                        customStyles={selectStyles}
                      />
                    </div>
                  </div>
                </div>
                {(selectedService?.service ||
                  selectedUnit?.unit ||
                  sorting?.key) && (
                  <div className="column is-half">
                    <label
                      htmlFor="clear"
                      style={{ color: 'white' }}
                      className="is-size-5">
                      Clear
                    </label>
                    <div>
                      <button
                        style={{ marginTop: '-3px' }}
                        onClick={() => {
                          setSelectedUnit('')
                          setSelectedService('')
                          setSorting({ key: '', ascending: false })
                          setNewQueue(newQueue)
                        }}
                        className="button is-primary">
                        Clear All Filter(s)
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            <div className="columns is-mobile is-multiline">
              {newQueue.map(job => (
                <div
                  className="column is-half p-l-sm p-r-sm"
                  key={job.workorder}
                  onClick={handleJobClick(job)}>
                  <div className="card is-fullheight">
                    <div className="card-content has-text-centered">
                      <button
                        className="button is-text check-button"
                        onClick={handleJobCheck(job)}>
                        <FontAwesomeIcon
                          icon={isSelected(job) ? faCheckCircle : faCircle}
                          size="lg"
                          color={isSelected(job) ? '#98C368' : '#eceef4'}
                        />
                      </button>
                      <span
                        className="tag is-medium is-uppercase is-dark has-text-weight-medium"
                        style={{
                          backgroundColor: ServiceColors[job.service] || 'grey'
                        }}>
                        {job.service}
                      </span>
                      <div className="qc-queue-card-unit">{`Unit ${job.unit}`}</div>
                      <p className="subtitle is-6 is-marginless">{job.type}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={`card animated faster assign-popup ${
                openPopup ? 'slideInUp' : 'is-hidden'
              }`}>
              <div className="card-content">
                <div className="subtitle is-5 has-text-weight-normal">
                  You've selected {selected.length} unit
                  {selected.length > 1 && 's'}.
                  {userTeams.length > 1 && (
                    <select
                      value={selectedTeam}
                      className="is-fullwidth input mt-4"
                      onChange={onSelectTeam}>
                      <option value="select-one">Select one...</option>
                      {userTeams.map(team => (
                        <option value={team}>{team}</option>
                      ))}
                    </select>
                  )}
                </div>
                <button
                  className="button is-primary"
                  onClick={handleAssignClick}>
                  Assign
                </button>
              </div>
            </div>
          </div>
        ) : (
          <MobileEmptyState
            image={noWork}
            title="You’re all caught up."
            subtitle="All work orders assigned to you will be listed here."
          />
        )}
      </div>
    </div>
  )
}

QcQueue.propTypes = {
  qc: PropTypes.object,
  user: PropTypes.object,
  context: PropTypes.object,
  getQueue: PropTypes.func,
  assignJobs: PropTypes.func,
  toggleSidebar: PropTypes.func
}

const mapStateToProps = ({ user, context, qc }) => ({
  qc,
  user,
  context
})

const mapDispatchToProps = {
  getQueue,
  assignJobs,
  toggleSidebar
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcQueue)
