import React from 'react'
import PropTypes from 'prop-types'
// import turnableCircle from './img/turnable_circle.png'
import TurnableLoader from '../turnable-loader'
import './index.scss'

const TurnableSpinner = ({ size = 'medium', className = '' }) => (
  // <img
  //   src={turnableCircle}
  //   className={`turnable-spinner ${size} ${className}`}
  //   alt="Tunable Spinner"
  <TurnableLoader />
)

TurnableSpinner.propTypes = {
  size: PropTypes.string
}

export default TurnableSpinner
