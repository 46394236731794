import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { usePrevious } from '../../helpers/hooks'
import { getFormData, animate } from '../../helpers'
import PropTypes from 'prop-types'

const DEFAULT_AMOUNT = 100
const SetupBudgetsServicesModalAllocate = ({
  context,
  user,
  allocation_amount,
  vendor_uri = null,
  modal,
  closeModal,
  setupBudgets,
  setupVendors,
  setupBudgetLineUpdate,
  updateData,
  servicetype_uri
}) => {
  const [selectedVendor, setSelectedVendor] = useState('none')
  //const [vendorDisabled, setVendorDisabled] = useState(false)
  const [allocationAmount, setAllocationAmount] = useState(DEFAULT_AMOUNT)

  const prevModal = usePrevious(modal)

  useEffect(() => {
    reset()
  }, [])

  useEffect(() => {
    const { isActive } = modal
    const wasActive = _.get(prevModal, 'isActive', false)
    const justOpened = isActive !== wasActive && isActive
    if (justOpened) reset()
  }, [modal])

  const reset = () => {
    let vendor = 'none'
    //let disabled = false
    let amount = DEFAULT_AMOUNT
    if (typeof vendor_uri === 'string') {
      vendor = vendor_uri
      //disabled = true
    }
    if (typeof allocation_amount === 'string') {
      amount = parseFloat(allocation_amount)
    }
    setSelectedVendor(vendor)
    //setVendorDisabled(disabled)
    setAllocationAmount(amount)
  }

  const submit = () => {
    const { budget_id } = setupBudgets.budget.data
    const { pdbid } = user.user_metadata
    const el = document.getElementById('setup-budget-services-modal-create')
    const body = getFormData(el)
    if (typeof body.vendor_allocation === 'string') {
      body.vendor_allocation = parseFloat(body.vendor_allocation)
    }
    if (
      typeof body.vendor_allocation === 'number' &&
      body.vendor_allocation > -1 &&
      selectedVendor !== 'none'
    ) {
      setupBudgetLineUpdate(pdbid, context, budget_id, body)
      updateData()
      closeModal()
    } else {
      const modalCard = document.getElementById('modal-card')
      animate(modalCard, 'shake')
      let toFocus = null
      let element = null
      if (selectedVendor === 'none') toFocus = 'budget-services-vendor-uri'
      else if (body.vendor_allocation < 0)
        toFocus = 'budget-services-vendor-allocation'
      if (toFocus !== null) element = document.getElementById(toFocus)
      if (element !== null) element.focus()
    }
  }

  const getUnusedVendors = () => {
    const { allVendors } = setupVendors
    const { allocations } = setupBudgets.budget.data.budget_lines.find(
      bl => bl.servicetype_uri === servicetype_uri
    )
    let vendorsUsed = []
    for (let i = 0; i < allocations.length; i += 1) {
      const a = allocations[i]
      vendorsUsed.push(a.vendor_uri)
    }
    return allVendors.data.filter(d => vendorsUsed.indexOf(d.vendor_uri) === -1)
  }

  const getVendors = () => {
    const { data } = setupVendors.allVendors
    let template = []
    let vendors = data
    if (vendor_uri !== null) {
      vendors = data.filter(d => d.vendor_uri === vendor_uri)
    } else {
      vendors = getUnusedVendors()
    }
    for (let i = 0; i < vendors.length; i += 1) {
      const vendor = vendors[i]
      template.push(
        <option
          value={vendor.vendor_uri}
          key={`service-option-${vendor.vendor_id}`}>
          {vendor.name}
        </option>
      )
    }
    return template
  }

  const { budget_uri } = setupBudgets.budget.data
  const isDisabled = vendor_uri !== null
  const hiddenVendor = (
    <input type="hidden" name="vendor_uri" value={selectedVendor} />
  )
  const onSubmit = (e) => e.preventDefault()
  
  return (
    <form onSubmit={(e) => onSubmit(e)} id="setup-budget-services-modal-create">
      <input type="hidden" name="budget_uri" value={budget_uri} />
      <input type="hidden" name="servicetype_uri" value={servicetype_uri} />
      <div className="columns is-multiline">
        <div className="column is-two-thirds">
          <p className="is-size-4 m-b-md">Allocate to Vendor</p>
          <div className="form-control">
            <label forhtml="vendor">Vendor</label>
            {isDisabled ? hiddenVendor : null}
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                id="budget-services-vendor-uri"
                onChange={e => setSelectedVendor(e.target.value)}
                value={selectedVendor}
                disabled={isDisabled}>
                <option value="none">Select Vendor</option>
                {getVendors()}
              </select>
              <input type="hidden" name="vendor_uri" value={selectedVendor} />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="form-control">
            <label forhtml="option">Total Budget</label>
            <input
              type="number"
              className="input"
              min="0.01"
              step="0.01"
              name="vendor_allocation"
              id="budget-services-vendor-allocation"
              value={allocationAmount}
              onChange={e => setAllocationAmount(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-quarter is-offset-one-quarter has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary is-fullwidth"
              onClick={() => {
                closeModal()
              }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="column is-one-quarter has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary is-fullwidth"
              onClick={() => submit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupBudgetsServicesModalAllocate.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  allocation_amount: PropTypes.string,
  vendor_uri: PropTypes.string,
  servicetype_uri: PropTypes.string,
  modal: PropTypes.object,
  setupBudgets: PropTypes.object,
  closeModal: PropTypes.func,
  setupBudgetLineUpdate: PropTypes.func
}

export default SetupBudgetsServicesModalAllocate
