import Service from '../service'
import LOGOUT from './auth'

/**
 * Setup budget data module
 * @redux
 * @reduxActionScope setup-budgets
 * @module setup-budgets
 */

/**
 * Request all budgets list
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_BUDGETS_GET_REQUESTED =
  'setup-budgets/SETUP_ALL_BUDGETS_GET_REQUESTED'
/**
 * All budgets list failed
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_BUDGETS_GET_FAIL =
  'setup-budgets/SETUP_ALL_BUDGETS_GET_FAIL'
/**
 * Update requested budgets list with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ALL_BUDGETS_GET_SUCCESS =
  'setup-budgets/SETUP_ALL_BUDGETS_GET_SUCCESS'

/**
 * Request single budget details
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_GET_REQUESTED =
  'setup-budgets/SETUP_ONE_BUDGET_REQUESTED'
/**
 * Budget details request failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_GET_FAIL = 'setup-budgets/SETUP_ONE_BUDGET_FAIL'
/**
 * Update details for requested budget with successful data from API
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_GET_SUCCESS =
  'setup-budgets/SETUP_ONE_BUDGET_SUCCESS'

/**
 * Request budget creation
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_CREATE_REQUESTED =
  'setup-budgets/SETUP_ONE_BUDGET_CREATE_REQUESTED'
/**
 * Budget creation failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_CREATE_FAIL =
  'setup-budgets/SETUP_ONE_BUDGET_CREATE_FAIL'
/**
 * Refresh budget details after a successful budget creation verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_CREATE_SUCCESS =
  'setup-budgets/SETUP_ONE_BUDGET_CREATE_SUCCESS'

/**
 * Request budget update
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_UPDATE_REQUESTED =
  'setup-budgets/SETUP_ONE_BUDGET_UPDATE_REQUESTED'
/**
 * Budget update failed
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_UPDATE_FAIL =
  'setup-budgets/SETUP_ONE_BUDGET_UPDATE_FAIL'
/**
 * Refresh budget details after a successful budget update verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_ONE_BUDGET_UPDATE_SUCCESS =
  'setup-budgets/SETUP_ONE_BUDGET_UPDATE_SUCCESS'

/**
 * Request budget line details
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_GET_REQUESTED =
  'setup-budget-line/SETUP_BUDGET_LINE_REQUESTED'
/**
 * Budget line details failed
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_GET_FAIL =
  'setup-budget-line/SETUP_BUDGET_LINE_FAIL'
/**
 * Refresh budget details after a successful budget line details request
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_GET_SUCCESS =
  'setup-budget-line/SETUP_BUDGET_LINE_SUCCESS'

/**
 * Request budget line creation
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_CREATE_REQUESTED =
  'setup-budget-line/SETUP_BUDGET_LINE_CREATE_REQUESTED'
/**
 * Budget line creation failed
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_CREATE_FAIL =
  'setup-budget-line/SETUP_BUDGET_LINE_CREATE_FAIL'
/**
 * Refresh budget details after a successful budget line creation verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_CREATE_SUCCESS =
  'setup-budget-line/SETUP_BUDGET_LINE_CREATE_SUCCESS'

/**
 * Request budget line update
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_UPDATE_REQUESTED =
  'setup-budget-line/SETUP_BUDGET_LINE_UPDATE_REQUESTED'
/**
 * Budget line update failed
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_UPDATE_FAIL =
  'setup-budget-line/SETUP_BUDGET_LINE_UPDATE_FAIL'
/**
 * Refresh budget details after a successful budget line update verified by the API
 * @type {Redux.ActionType}
 */
export const SETUP_BUDGET_LINE_UPDATE_SUCCESS =
  'setup-budget-line/SETUP_BUDGET_LINE_UPDATE_SUCCESS'

const initialState = {
  budget: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: {}
  }
}

/**
 * Setup budget reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SETUP_BUDGET_LINE_GET_REQUESTED:
    case SETUP_BUDGET_LINE_CREATE_REQUESTED:
    case SETUP_BUDGET_LINE_UPDATE_REQUESTED:
    case SETUP_ALL_BUDGETS_GET_REQUESTED:
    case SETUP_ONE_BUDGET_GET_REQUESTED:
    case SETUP_ONE_BUDGET_CREATE_REQUESTED:
    case SETUP_ONE_BUDGET_UPDATE_REQUESTED:
      return {
        ...state,
        budget: {
          ...state.budget,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case SETUP_BUDGET_LINE_GET_SUCCESS:
    case SETUP_BUDGET_LINE_CREATE_SUCCESS:
    case SETUP_BUDGET_LINE_UPDATE_SUCCESS:
    case SETUP_ALL_BUDGETS_GET_SUCCESS:
    case SETUP_ONE_BUDGET_GET_SUCCESS:
    case SETUP_ONE_BUDGET_CREATE_SUCCESS:
    case SETUP_ONE_BUDGET_UPDATE_SUCCESS:
      return {
        ...state,
        budget: {
          ...state.budget,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case SETUP_BUDGET_LINE_GET_FAIL:
    case SETUP_BUDGET_LINE_CREATE_FAIL:
    case SETUP_BUDGET_LINE_UPDATE_FAIL:
    case SETUP_ALL_BUDGETS_GET_FAIL:
    case SETUP_ONE_BUDGET_GET_FAIL:
    case SETUP_ONE_BUDGET_CREATE_FAIL:
    case SETUP_ONE_BUDGET_UPDATE_FAIL:
      return {
        ...state,
        budget: {
          ...state.budget,
          isRequesting: false,
          isError: true
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all available budgets
 * @redux
 * @reduxActionCreator SETUP_ALL_BUDGETS_REQUESTED, SETUP_ALL_BUDGETS_SUCCESS, SETUP_ALL_BUDGETS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const setupAllBudgetsGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: SETUP_ALL_BUDGETS_GET_REQUESTED
    })
    Service.setupAllBudgetsGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ALL_BUDGETS_GET_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupAllBudgetsGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupAllBudgetsGetFail(dispatch)
      })
  }
}

const _allBudgetsAdapter = data => {
  return Array.isArray(data) && data.length > 0
    ? _oneBudgetAdapter(data[0])
    : {}
}

const _setupAllBudgetsGetFail = dispatch => {
  dispatch({
    type: SETUP_ALL_BUDGETS_GET_FAIL
  })
}

const EMPTY = ''
const _oneBudgetAdapter = b => {
  return {
    budget_uri: typeof b.budget_uri === 'string' ? b.budget_uri : EMPTY,
    budget_id: typeof b.budget_id === 'string' ? b.budget_id : EMPTY,
    budget_name: typeof b.budget_name === 'string' ? b.budget_name : EMPTY,
    budget_lines: Array.isArray(b.budget_lines)
      ? _budgetLinesAdapter(b.budget_lines)
      : [],
    total_actual:
      typeof b.total_actual === 'string' ? parseFloat(b.total_actual) : 0,
    total_budget:
      typeof b.total_budget === 'string' ? parseFloat(b.total_budget) : 0,
    total_different:
      typeof b.total_different === 'string' ? parseFloat(b.total_different) : 0,
    total_estimate:
      typeof b.total_estimate === 'string' ? parseFloat(b.total_estimate) : 0
  }
}
const _allEmpty = obj => {
  let allEmpty = true
  for (let key in obj) {
    const value = obj[key]
    if (value !== null) allEmpty = false
  }
  return allEmpty
}
const _allocationsAdapter = arr => {
  let allocations = []
  for (let i = 0; i < arr.length; i += 1) {
    let a = arr[i]
    if (_allEmpty(a)) continue
    allocations.push({
      vendor_uri: typeof a.vendor_uri === 'string' ? a.vendor_uri : EMPTY,
      vendor: typeof a.vendor === 'string' ? a.vendor : '',
      allocation_amount:
        typeof a.allocation_amount === 'string' ? a.allocation_amount : EMPTY
    })
  }
  return allocations
}
const _budgetLinesAdapter = arr => {
  let lines = []
  for (let i = 0; i < arr.length; i += 1) {
    const a = arr[i]
    if (_allEmpty(a)) continue
    lines.push({
      budget_line_uri:
        typeof a.budget_line_uri === 'string' ? a.budget_line_uri : EMPTY,
      servicetype_uri:
        typeof a.servicetype_uri === 'string' ? a.servicetype_uri : EMPTY,
      servicetype_id:
        typeof a.servicetype_id === 'string' ? a.servicetype_id : EMPTY,
      servicetype: typeof a.servicetype === 'string' ? a.servicetype : EMPTY,
      budget: typeof a.budget === 'string' ? a.budget : EMPTY,
      allocations: Array.isArray(a.allocations)
        ? _allocationsAdapter(a.allocations)
        : []
    })
  }
  return lines
}

/**
 * Gets the details for a single budget
 * @redux
 * @reduxActionCreator SETUP_ONE_BUDGET_REQUESTED, SETUP_ONE_BUDGET_SUCCESS, SETUP_ONE_BUDGET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id for the budget requested
 */
export const setupOneBudgetGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_BUDGET_GET_REQUESTED
    })
    Service.setupOneBudgetGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_BUDGET_GET_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupOneBudgetGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneBudgetGetFail(dispatch)
      })
  }
}

const _setupOneBudgetGetFail = dispatch => {
  dispatch({
    type: SETUP_ONE_BUDGET_GET_FAIL
  })
}

/**
 * Creates a new budget
 * @redux
 * @reduxActionCreator SETUP_ONE_BUDGET_CREATE_REQUESTED, SETUP_ONE_BUDGET_CREATE_SUCCESS, SETUP_ONE_BUDGET_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the JSON object for the new budget
 */
export const setupOneBudgetCreate = (pdbid, context, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_BUDGET_CREATE_REQUESTED
    })
    Service.setupOneBudgetCreate(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_BUDGET_CREATE_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupOneBudgetCreateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneBudgetCreateFail(dispatch)
      })
  }
}

const _setupOneBudgetCreateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_BUDGET_CREATE_FAIL
  })
}

/**
 * Updates the main data for an existing budget
 * @redux
 * @reduxActionCreator SETUP_ONE_BUDGET_UPDATE_REQUESTED, SETUP_ONE_BUDGET_UPDATE_SUCCESS, SETUP_ONE_BUDGET_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing budget
 * @param {Object} body - the JSON object for the updated budget
 */
export const setupOneBudgetUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_ONE_BUDGET_UPDATE_REQUESTED
    })
    Service.setupOneBudgetUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_ONE_BUDGET_UPDATE_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupOneBudgetUpdateFail(dispatch)
        }
      })
      .catch(() => {
        _setupOneBudgetUpdateFail(dispatch)
      })
  }
}

const _setupOneBudgetUpdateFail = dispatch => {
  dispatch({
    type: SETUP_ONE_BUDGET_UPDATE_FAIL
  })
}

/**
 * Gets all the lines for an existing budget
 * @redux
 * @reduxActionCreator SETUP_BUDGET_LINE_REQUESTED, SETUP_BUDGET_LINE_SUCCESS, SETUP_BUDGET_LINE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing budget
 */
export const setupBudgetLineGet = (pdbid, context, id) => {
  return dispatch => {
    dispatch({
      type: SETUP_BUDGET_LINE_GET_REQUESTED
    })
    Service.setupBudgetLineGet(pdbid, context, id)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_BUDGET_LINE_GET_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupBudgetLineGetFail(dispatch)
        }
      })
      .catch(() => {
        _setupBudgetLineGetFail(dispatch)
      })
  }
}

const _setupBudgetLineGetFail = dispatch => {
  dispatch({
    type: SETUP_BUDGET_LINE_GET_FAIL
  })
}

/**
 * Creates a new line for an existing budget
 * @redux
 * @reduxActionCreator SETUP_BUDGET_LINE_CREATE_REQUESTED, SETUP_BUDGET_LINE_CREATE_SUCCESS, SETUP_BUDGET_LINE_CREATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing budget
 * @param {Object} body - the JSON object for the new budget line
 */
export const setupBudgetLineCreate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_BUDGET_LINE_CREATE_REQUESTED
    })
    Service.setupBudgetLineCreate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_BUDGET_LINE_CREATE_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupBudgetLineCreateFail(dispatch)
        }
      })
      .catch(e => {
        console.log(e)
        _setupBudgetLineCreateFail(dispatch)
      })
  }
}

const _setupBudgetLineCreateFail = dispatch => {
  dispatch({
    type: SETUP_BUDGET_LINE_CREATE_FAIL
  })
}

/**
 * Updates an existing line for a budget
 * @redux
 * @reduxActionCreator SETUP_BUDGET_LINE_UPDATE_REQUESTED, SETUP_BUDGET_LINE_UPDATE_SUCCESS, SETUP_BUDGET_LINE_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} id - the id of the existing budget line
 * @param {Object} body - the JSON object for the updated budget line
 */
export const setupBudgetLineUpdate = (pdbid, context, id, body) => {
  return dispatch => {
    dispatch({
      type: SETUP_BUDGET_LINE_UPDATE_REQUESTED
    })
    Service.setupBudgetLineUpdate(pdbid, context, id, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: SETUP_BUDGET_LINE_UPDATE_SUCCESS,
            data: _allBudgetsAdapter(data)
          })
        } else {
          _setupBudgetLineUpdateFail(dispatch)
        }
      })
      .catch(e => {
        console.log(e)
        _setupBudgetLineUpdateFail(dispatch)
      })
  }
}

const _setupBudgetLineUpdateFail = dispatch => {
  dispatch({
    type: SETUP_BUDGET_LINE_UPDATE_FAIL
  })
}
