import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import MultiSelectDropdown from '../../components/multi-select-dropdown'
import { selectStyles } from '../../components/forms-helper/MultiDropdownStyles'
import { Link } from 'react-router-dom'
import { usePermissionGate, usePrevious } from '../../helpers/hooks'

const DamageSetupFormSelect = props => {
  const { user, context, inspect, getInspectionFormList } = props || {}
  const [errorMessage, setErrorMessage] = useState('')
  const [form, setForm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  // Redux
  const { inspectionFormList } = inspect || {}

  const pdbid = user?.user_metadata?.pdbid

  const onSuccess = () => {
    setIsLoading(false)
    setErrorMessage('')
  }

  const onError = () => {
    setErrorMessage('Something went wrong, Please try again later!!!')
    setIsLoading(false)
  }

  const prevContext = usePrevious(context)

  useEffect(() => {
    const propertyChanged =
      _.get(context, 'property') !== _.get(prevContext, 'property')
    const projectChanged =
      _.get(context, 'project') !== _.get(prevContext, 'project')
    const contextChanged = propertyChanged || projectChanged
    if (contextChanged) {
      setIsLoading(true)
      setErrorMessage('')
      getInspectionFormList(pdbid, context, onSuccess, onError)
    }
  }, [context, pdbid])

  const { hasPermission } = usePermissionGate('damage-action')

  return (
    <div
      className="container setup-vendors-main animated fadeIn"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <div
        className="inspection-list-container"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40%'
        }}>
        <div className="data-table-wrapper" style={{ minHeight: 0 }}>
          <div className="data-table-header">
            <div className={`header control ${isLoading ? 'is-loading' : ''}`}>
              <h2 className="heading-5">
                Please select a form below to proceed
              </h2>
            </div>
            {errorMessage !== '' && (
              <div className="notification is-danger is-light is-flex">
                <p>{errorMessage}</p>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <div style={{ width: '100%' }}>
                <MultiSelectDropdown
                  displayKey="name"
                  value={form}
                  defaultValues={inspectionFormList || []}
                  onChange={value => setForm(value)}
                  isDisabled={isLoading || !hasPermission}
                  dropdownIcon={true}
                  isMulti={false}
                  placeholder="Select"
                  noOptionsMessage="No Form Available"
                  customStyles={selectStyles}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '12px'
              }}>
              <Link to={`/inspections/damagesetup/${form?.form_id}`}>
                <button
                  disabled={form === null || !hasPermission}
                  className="button is-primary">
                  Next
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DamageSetupFormSelect
