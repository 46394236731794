import React from 'react'

const InspectionDamageCellComponent = ({ row }) => {
  const { cell, column } = row || {}
  const { row: currentRow, value } = cell || {}
  const { original } = currentRow || {}
  const { damage_required } = original || {}
  const { Header } = column || {}
  const getValues = value => {
    if (Header === 'Charge Type') {
      return value ? 'Additional Damage Charge' : 'Damage Charge'
    }
    return value
  }
  return (
    <div className="cell-item" title={value}>
      <span className={!damage_required ? 'disabled-row' : ''}>
        {getValues(value)}
      </span>
    </div>
  )
}

export default InspectionDamageCellComponent
