import Service from '../../service'
import { allUnitListAdapter, spaceAdapter } from './adapters'
import {
  COMPANY_TEMPLATES_LIST_REQUESTED,
  COMPANY_TEMPLATES_LIST_SUCCESS,
  COMPANY_TEMPLATES_LIST_FAIL,
  COMPANY_TEMPLATES_ELEMENTS_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_FAIL,
  COMPANY_TEMPLATES_ELEMENTS_RESET,
  COMPANY_TEMPLATES_DETAILS_REQUESTED,
  COMPANY_TEMPLATES_DETAILS_SUCCESS,
  COMPANY_TEMPLATES_DETAILS_FAIL,
  COMPANY_TEMPLATES_DETAILS_RESET,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL,
  COMPANY_TEMPLATE_DELETE_REQUESTED,
  COMPANY_TEMPLATE_DELETE_SUCCESS,
  COMPANY_TEMPLATE_DELETE_FAIL,
  COMPANY_TEMPLATE_DELETE_RESET,
  COMPANY_TEMPLATE_DUPLICATE_REQUESTED,
  COMPANY_TEMPLATE_DUPLICATE_SUCCESS,
  COMPANY_TEMPLATE_DUPLICATE_FAIL,
  COMPANY_TEMPLATE_DUPLICATE_RESET,
  COMPANY_TEMPLATE_SHARE_REQUESTED,
  COMPANY_TEMPLATE_SHARE_SUCCESS,
  COMPANY_TEMPLATE_SHARE_FAIL,
  COMPANY_TEMPLATE_SHARE_RESET,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS,
  COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS,
  CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL,
  COMPANY_TEMPLATES_ERRORS_RESET,
  ALL_UNIT_SPACES_REQUESTED,
  ALL_UNIT_SPACES_FAIL,
  ALL_UNIT_SPACES_SUCCESS
} from './types'

// TODO remove this mock

import { formatDateString } from '../../utils'
import moment from 'moment'
import { uuid } from 'short-uuid'
import { isEmpty } from 'lodash'

const _companyTemplateListFail = dispatch => {
  dispatch({
    type: COMPANY_TEMPLATES_LIST_FAIL
  })
}

const _companyTemplateDetailsFail = dispatch => {
  dispatch({
    type: COMPANY_TEMPLATES_DETAILS_FAIL
  })
}

/**
 * Gets company template list
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_LIST_REQUESTED, COMPANY_TEMPLATES_LIST_SUCCESS, COMPANY_TEMPLATES_LIST_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getCompanyTemplateList = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_LIST_REQUESTED
    })
    Service.getCompanyTemplateList(pdbid, context)
      .then(async res => {
        // TODO use the actual response
        if (res.ok) {
          const list = await res.json()

          return dispatch({
            type: COMPANY_TEMPLATES_LIST_SUCCESS,
            list: _listAdapter(list)
          })
        }

        return _companyTemplateListFail(dispatch)
      })
      .catch(() => {
        _companyTemplateListFail(dispatch)
      })
  }
}

/**
 * Gets company template elements
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_ELEMENTS_REQUESTED, COMPANY_TEMPLATES_ELEMENTS_SUCCESS, COMPANY_TEMPLATES_ELEMENTS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getTemplateElements = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_ELEMENTS_REQUESTED
    })
    Service.getTemplateElements(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const { elements } = await res.json()

          return dispatch({
            type: COMPANY_TEMPLATES_ELEMENTS_SUCCESS,
            elements: _elementsAdapter(elements, null)
          })
        }

        return dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_FAIL
        })
      })
  }
}

/**
 * Gets elements
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_ELEMENTS_REQUESTED, COMPANY_TEMPLATES_ELEMENTS_SUCCESS, COMPANY_TEMPLATES_ELEMENTS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const getElements = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_ELEMENTS_REQUESTED
    })
    Service.getTemplateElements(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const { elements } = await res.json()

          return dispatch({
            type: COMPANY_TEMPLATES_ELEMENTS_SUCCESS,
            elements: spaceAdapter(elements)
          })
        }

        return dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_FAIL
        })
      })
  }
}
/**
 * Save company template elements
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED, COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS, COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const updateTemplateElements = (
  pdbid,
  context,
  type,
  elementsToUpdate,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_ELEMENTS_UPDATE_REQUESTED
    })
    return Service.updateTemplateElements(
      pdbid,
      context,
      type,
      elementsToUpdate
    )
      .then(async res => {
        // TODO use the actual response
        if (res.ok) {
          dispatch({
            type: COMPANY_TEMPLATES_ELEMENTS_UPDATE_SUCCESS,
            payload: null
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL
        })

        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }

        dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_UPDATE_FAIL
        })
      })
  }
}

/**
 * Delete company template element
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED, COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS, COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const deleteTemplateElement = (
  pdbid,
  context,
  type,
  elementToDelete,
  idKey
) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_ELEMENTS_DELETE_REQUESTED
    })
    return Service.deleteTemplateElement(pdbid, context, type, elementToDelete)
      .then(async res => {
        if (res.ok) {
          return dispatch({
            type: COMPANY_TEMPLATES_ELEMENTS_DELETE_SUCCESS,
            payload: { elementToDelete, type, idKey }
          })
        }

        return dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: COMPANY_TEMPLATES_ELEMENTS_DELETE_FAIL
        })
      })
  }
}

/**
 * reset Templates elements
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_ELEMENTS_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const resetTemplateElements = () => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_ELEMENTS_RESET
    })
  }
}

/**
 * Gets company template details
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATES_DETAILS_REQUESTED, COMPANY_TEMPLATES_DETAILS_SUCCESS, COMPANY_TEMPLATES_DETAILS_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} templateId - template id to be requested
 */
export const getCompanyTemplateDetails = (pdbid, context, templateId) => {
  return async dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_DETAILS_REQUESTED
    })

    try {
      let [
        templateDetails,
        templateElements,
        templateProperties,
        templateList
      ] = await Promise.all([
        Service.getCompanyTemplateDetails(pdbid, context, templateId),
        Service.getTemplateElements(pdbid, context),
        Service.getCompanyTemplateProperties(pdbid, context, templateId),
        Service.getCompanyTemplateList(pdbid, context)
      ])

      if (
        templateDetails.ok &&
        templateElements.ok &&
        templateProperties.ok &&
        templateList.ok
      ) {
        const templateDetailsRes = await templateDetails.json()
        const templateElementsRes = await templateElements.json()
        const templatePropertiesRes = await templateProperties.json()
        const templateListRes = await templateList.json()

        const singleTemplate =
          Array.isArray(templateDetailsRes) && templateDetailsRes.length > 0
            ? templateDetailsRes[0]
            : {}

        const templateData = _detailsAdapter(
          singleTemplate,
          templatePropertiesRes,
          templateElementsRes?.elements
        )

        const elements = _elementsAdapter(
          templateElementsRes?.elements,
          templateData
        )

        return dispatch({
          type: COMPANY_TEMPLATES_DETAILS_SUCCESS,
          details: templateData,
          elements,
          list: _listAdapter(templateListRes)
        })
      }

      _companyTemplateDetailsFail(dispatch)
    } catch (err) {
      _companyTemplateDetailsFail(dispatch)
    }
  }
}

/**
 * reset Templates details
 * @redux
 * @reduxActionCreator COMPANY_DETAILS_ELEMENTS_RESET, COMPANY_TEMPLATE_DELETE_RESET
 */
export const resetCompanyTemplateDetails = () => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATES_DETAILS_RESET
    })
    dispatch({
      type: COMPANY_TEMPLATE_DELETE_RESET
    })
    dispatch({
      type: COMPANY_TEMPLATES_ERRORS_RESET
    })
  }
}
/**
 * Delete Company Template
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATE_DELETE_REQUESTED, COMPANY_TEMPLATE_DELETE_SUCCESS, COMPANY_TEMPLATE_DELETE_FAIL, COMPANY_TEMPLATE_DELETE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} body - template data that we need to submit
 * @param {string} templateId - template id
 */
export const createOrUpdateCompanyTemplate = (
  pdbid,
  context,
  body,
  templateId,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_REQUESTED
    })
    return Service.createOrUpdateOrDuplicateCompanyTemplate(
      pdbid,
      context,
      body,
      templateId
    )
      .then(async res => {
        // TODO use the actual response
        const templates = await res.json()

        if (res.ok) {
          dispatch({
            type: CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_SUCCESS
          })

          if (onSuccess) {
            onSuccess(templates[0])
          }

          return
        }

        if (onError) {
          onError()
        }

        dispatch({
          type: CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL
        })
      })
      .catch(() => {
        if (onError) {
          onError()
        }
        dispatch({
          type: CREATE_UPDATE_DUPLICATE_COMPANY_TEMPLATE_FAIL
        })
      })
  }
}

/**
 * Duplicate Company Template
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATE_DUPLICATE_REQUESTED, COMPANY_TEMPLATE_DUPLICATE_SUCCESS, COMPANY_TEMPLATE_DUPLICATE_FAIL, COMPANY_TEMPLATE_DUPLICATE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {Object} body - the payload object for the request
 * @param {string} templateId - template id to be requested
 * @param {string} onSuccess - success callback
 * @param {string} onError - error callback
 */
export const duplicateCompanyTemplate = (
  pdbid,
  context,
  body,
  templateId,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATE_DUPLICATE_REQUESTED
    })

    return Service.createOrUpdateOrDuplicateCompanyTemplate(
      pdbid,
      context,
      body,
      templateId
    )
      .then(async res => {
        // TODO use the actual response
        const templates = await res.json()

        if (res.ok) {
          dispatch({
            type: COMPANY_TEMPLATE_DUPLICATE_SUCCESS
          })

          if (onSuccess) {
            onSuccess(templates[0])
          }

          return
        }

        if (onError) {
          onError()
        }

        dispatch({
          type: COMPANY_TEMPLATE_DUPLICATE_FAIL
        })
      })
      .catch(() => {
        if (onError) {
          onError()
        }
        dispatch({
          type: COMPANY_TEMPLATE_DUPLICATE_FAIL
        })
      })
  }
}

/**
 * Delete Company Template
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATE_DELETE_REQUESTED, COMPANY_TEMPLATE_DELETE_SUCCESS, COMPANY_TEMPLATE_DELETE_FAIL, COMPANY_TEMPLATE_DELETE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} templateId - template id to be requested
 */
export const deleteCompanyTemplate = (pdbid, context, templateId) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATE_DELETE_REQUESTED
    })

    Service.deleteCompanyTemplate(pdbid, context, templateId)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: COMPANY_TEMPLATE_DELETE_SUCCESS,
            removedTemplateId: templateId
          })
          dispatch({
            type: COMPANY_TEMPLATE_DELETE_RESET
          })

          return
        }

        dispatch({
          type: COMPANY_TEMPLATE_DELETE_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: COMPANY_TEMPLATE_DELETE_FAIL
        })
      })
  }
}

/**
 * Share Company Template
 * @redux
 * @reduxActionCreator COMPANY_TEMPLATE_SHARE_REQUESTED, COMPANY_TEMPLATE_SHARE_SUCCESS, COMPANY_TEMPLATE_SHARE_FAIL, COMPANY_TEMPLATE_SHARE_RESET
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 * @param {string} templateId - template id to be requested
 */
export const shareCompanyTemplate = (pdbid, context, body, onSuccess) => {
  return dispatch => {
    dispatch({
      type: COMPANY_TEMPLATE_SHARE_REQUESTED
    })

    Service.shareCompanyTemplate(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          dispatch({
            type: COMPANY_TEMPLATE_SHARE_SUCCESS
          })
          dispatch({
            type: COMPANY_TEMPLATE_SHARE_RESET
          })

          if (onSuccess) {
            onSuccess()
          }

          return
        }

        dispatch({
          type: COMPANY_TEMPLATE_SHARE_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: COMPANY_TEMPLATE_SHARE_FAIL
        })
      })
  }
}

/**
 * Company template(s) adapters
 */

const sortTemplateElements = sortKey => {
  return (current, next) => {
    return current[sortKey] - next[sortKey]
  }
}

const _detailsAdapter = (template, properties, elements = {}) => {
  const spaces = (template?.spaces || [])
    .sort(sortTemplateElements('sorting_order'))
    .map((space, space_index) => ({
      ...space,
      type: 'space',
      collapsed: true,
      do_delete_space: !space.do_delete_space ? 'false' : space.do_delete_space,
      space_name: space?.name,
      space_index,
      status:
        elements['spaces']?.find(element => element.space_id === space.space_id)
          ?.status || 'active',
      space_uuid: uuid(),
      items: (space?.items || [])
        .sort(sortTemplateElements('sorting_order_item'))
        .map((item, item_index) => ({
          ...item,
          type: 'item',
          collapsed: true,
          do_delete_item: !item.do_delete_item ? 'false' : item.do_delete_item,
          item_name: item?.name,
          space_index,
          item_index,
          item_uuid: uuid(),
          observations: (item?.observations || [])
            .sort(sortTemplateElements('sorting_order_observation'))
            .map((observation, observation_index) => ({
              ...observation,
              type: 'observation',
              do_delete_observation: !observation.do_delete_observation
                ? 'false'
                : observation.do_delete_observation,
              observation_name: observation?.name,
              space_index,
              item_index,
              observation_index,
              observation_uuid: uuid(),
              solution_type: !observation.solution_type
                ? 'single'
                : observation.solution_type,
              solutions: (observation?.solutions || [])
                .sort(sortTemplateElements('sorting_order_solution'))
                .map((solution, solution_index) => ({
                  ...solution,
                  type: 'solution',
                  do_delete_solution: !solution.do_delete_solution
                    ? 'false'
                    : solution.do_delete_solution,
                  solution_name: solution?.name,
                  space_index,
                  item_index,
                  observation_index,
                  solution_index,
                  solution_uuid: uuid()
                }))
            }))
        }))
    }))

  const usedSpaces = (template?.spaces || []).map(space => space.space_id)
  const shared = (properties || []).filter(
    ({ template_id }) => template_id === template.template_id
  )
  return {
    ...template,
    name: template.name ? template.name : '',
    type: template.type ? template.type : '',
    spaces,
    usedSpaces,
    shared
  }
}

const sortElement = sortKey => {
  return (current, next) => {
    if (
      (current[sortKey] || '').toLowerCase() <
      (next[sortKey] || '').toLowerCase()
    )
      return -1
    if (
      (current[sortKey] || '').toLowerCase() >
      (next[sortKey] || '').toLowerCase()
    )
      return 1
    return 0
  }
}

/**
 * Elements adapater
 * @param {object} elements - elements data obtained from the endpoint
 * @param {object} context - elements being used, when getting the template detail
 */
const _elementsAdapter = (elements, detailsData) => {
  // if there is detailsData, the adapter would filter
  // the elements that are not already in use

  if (!isEmpty(elements)) {
    elements.spaces = (elements.spaces || [])
      .filter(space => space.status === 'active')
      .sort(sortElement('space_name'))
    elements.items = (elements.items || [])
      .filter(item => item.status === 'active')
      .sort(sortElement('item_name'))
    elements.observations = (elements.observations || [])
      .filter(observation => observation.status === 'active')
      .sort(sortElement('observation_name'))
    elements.solutions = (elements.solutions || [])
      .filter(solution => solution.status === 'active')
      .sort(sortElement('solution_name'))
  }

  if (detailsData) {
    const { usedSpaces } = detailsData
    const { spaces } = elements

    return {
      ...elements,
      spaces: (spaces || []).filter(
        space => !(usedSpaces || []).includes(space.space_id)
      )
    }
  }

  return elements
}

const _listAdapter = templates => {
  return (templates || []).map(template => ({
    ...template,
    created_at: moment(template.created_at).isValid()
      ? formatDateString(template.created_at, 'MM/DD/YYYY')
      : 'N/A',
    updated_at: moment(template.updated_at).isValid()
      ? formatDateString(template.updated_at, 'MM/DD/YYYY')
      : 'N/A',
    shared:
      template?.shared?.charAt(0)?.toUpperCase() +
      template?.shared?.toLowerCase()?.slice(1),
    state: template?.state === null ? 'Complete' : 'Incomplete'
  }))
}

/**
 * Gets elements
 * @redux
 * @reduxActionCreator ALL_UNIT_SPACES_REQUESTED, ALL_UNIT_SPACES_SUCCESS, ALL_UNIT_SPACES_FAIL
 */
export const getAllUnitSpace = pdbid => {
  return dispatch => {
    dispatch({
      type: ALL_UNIT_SPACES_REQUESTED
    })
    Service.getAllUnitSpace(pdbid)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const allUnitSpaces = await res.json()
          const filterData = allUnitListAdapter(
            allUnitSpaces,
            'unit_space',
            'unit_space'
          )

          return dispatch({
            type: ALL_UNIT_SPACES_SUCCESS,
            allUnitSpaces: filterData
          })
        }

        return dispatch({
          type: ALL_UNIT_SPACES_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: ALL_UNIT_SPACES_FAIL
        })
      })
  }
}

/**
 * Gets elements
 * @redux
 * @reduxActionCreator ALL_UNIT_SPACES_REQUESTED, ALL_UNIT_SPACES_SUCCESS, ALL_UNIT_SPACES_FAIL
 */
export const createElementSpace = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.createElementSpace(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}
