import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { arrowDown, question, questionFilled } from 'icons'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import {
  dropdownDefaultColorIndicator,
  getAdditionalActions,
  statusOptions
} from './config'
import AdditionalActions from 'components/additional-actions'
import './index.scss'
import { useAppContext, usePermissionGate } from '../../../../helpers/hooks'

const GeneralDetails = ({
  deleteTemplate,
  downloadTemplate,
  duplicateTemplate,
  shareTemplate,
  sharedElements,
  templateStatus,
  setTemplateData
}) => {
  const status = templateStatus === null ? 'active' : templateStatus
  const {
    user: { properties, projects }
  } = useAppContext()

  const { hasPermission } = usePermissionGate('create-or-modify-template')

  const [visibleChildren, setVisibleChildren] = useState(false)
  const [showToolTip, setShowToolTip] = useState(false)

  const additionalActions = getAdditionalActions(
    shareTemplate,
    duplicateTemplate,
    downloadTemplate,
    deleteTemplate
  )

  const toolTipIcon = useMemo(() => {
    return showToolTip ? questionFilled : question
  }, [showToolTip])

  const sharedProperties = useMemo(() => {
    const projectPropertyMappings = (sharedElements || []).map(
      element => element.property
    )

    const propertyIds = []

    for (let projectProperty of projectPropertyMappings || []) {
      for (let project of projects || []) {
        if (projectProperty?.includes(project.id)) {
          propertyIds.push(projectProperty?.replace(`${project.id}_`, ''))
        }
      }
    }

    return (properties || []).filter(property =>
      (propertyIds || []).includes(property.id)
    )
  }, [sharedElements, properties, projects])

  const getIndicatorColor = () =>
    statusOptions.find(option => option.value === status)?.color ||
    dropdownDefaultColorIndicator

  return (
    <div className="template__details__container">
      <div className="template__details__general m-t-md">
        <p className="has-text-weight-bold">General</p>
        <div
          className="is-flex"
          style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <p className="m-t-md template__details__general__text">
            Currently shared with:{' '}
            <strong>{sharedElements.length} properties</strong>
          </p>
          {sharedElements.length > 0 && (
            <button
              className="text-button m-t-md"
              onClick={() => setVisibleChildren(prevState => !prevState)}>
              <img
                src={arrowDown}
                alt="show/hide"
                className={`caret ${visibleChildren ? 'upside' : ''}`}
              />
              &nbsp;{!visibleChildren ? 'Show' : 'Hide'} list
            </button>
          )}
        </div>
        {visibleChildren && (
          <div className="template__details__general__text m-t-sm">
            <ul>
              {sharedProperties.map(item => (
                <li key={item.id} className="m-t-sm">
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="template__details__general m-t-md">
        <div className="has-text-weight-bold">
          <span style={{ verticalAlign: 'middle' }}>Status</span>
          <Tippy
            interactive
            appendTo={() => document.body}
            content={
              <div>
                <p>
                  <strong>Active</strong> means that the template can be shared
                  with additional properties.
                </p>
                <p className="m-t-md">
                  <strong>Inactive</strong> means that the template cannot be
                  shared with additional properties. If the template was shared
                  before it was marked "inactive", the property will still have
                  access to it.
                </p>
              </div>
            }
            placement="bottom-start"
            allowHTML
            onShow={() => setShowToolTip(true)}
            onHide={() => setShowToolTip(false)}
            theme="status">
            <img src={toolTipIcon} alt="help" className="m-l-md" />
          </Tippy>
        </div>
        <div className="select template__details__general__select">
          <div
            className="select__color-indicator"
            style={{
              backgroundColor: getIndicatorColor(),
              borderColor: getIndicatorColor()
            }}></div>
          <select
            className="input"
            disabled={!hasPermission}
            onChange={event => {
              const { value } = event.target

              if (hasPermission) {
                setTemplateData(prevData => {
                  return {
                    ...prevData,
                    status: value === 'active' ? null : value
                  }
                })
              }
            }}
            value={status}>
            {statusOptions.map(item => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <AdditionalActions actions={additionalActions} />
    </div>
  )
}

GeneralDetails.propTypes = {
  deleteTemplate: PropTypes.object,
  downloadTemplate: PropTypes.object,
  duplicateTemplate: PropTypes.object,
  shareTemplate: PropTypes.object,
  sharedElements: PropTypes.array,
  templateStatus: PropTypes.string,
  setTemplateData: PropTypes.func
}

export default GeneralDetails
