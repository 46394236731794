import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { useModals } from 'hooks'
import UnsavedChangesModal from '../../components/unsaved-changes-modal'

const RouterPrompt = ({ activePrompt }) => {
  const history = useHistory()
  const unblock = useRef()
  const [showModal, dismissModal] = useModals()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (activePrompt) {
      unblock.current = history.block((prompt) => {
        setCurrentPath(prompt.pathname)
        showModal(
          { width: '480px' },
          <UnsavedChangesModal
            onLeave={() => {
              dismissModal()
              unblock.current()
              history.push(currentPath)
            }}
            onStay={dismissModal}
          />
        )
        return false
      })
    }

    return () => {
      if (unblock.current) {
        unblock.current()
      }
    }
  }, [activePrompt, currentPath])

  return null
}

RouterPrompt.propTypes = {
  activePrompt: PropTypes.bool
}

export default RouterPrompt
