import React, { useEffect, useState, useMemo } from 'react'
import FieldTemplate from '../../components/forms-helper/FieldTemplate'
import GeneralField from '../../components/forms-helper/GeneralField'
import './index.scss'
import ReactTooltip from 'react-tooltip'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import BigLoading from '../../components/big-loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PermissionGate from '../../components/permission-gate'
import RestrictedElement from 'containers/restricted-element'
import { isEmpty } from 'lodash'
import '../../styles/common.scss'
import SwitchButton from '../../components/forms-helper/SwitchButton'

const VendorLoginUserEdit = props => {
  const {
    history,
    user,
    createOrEditUser,
    setAlert,
    newUserDetail,
    clearUser,
    allPropertiesList,
    context
  } = props || {}
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)

  const [UserName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [userTitle, setUserTitle] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [userAltPhone, setUserAltPhone] = useState('')
  const [loginAccess, setLoginAccess] = useState(false)
  const [userNote, setUserNote] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [permissionRules, setPermissionRules] = useState([])

  const { userDetail, propertiesList } = useSelector(state => state?.setupUsers)
  const { hasMadeInitialRequest, isRequesting, data: userDetailData } =
    userDetail || {}

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  const onSuccessUserDetail = () => {
    setIsLoading(false)
    setErrorMessage('')
  }

  const onErrorUserDetail = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  useEffect(() => {
    setIsLoading(true)
    allPropertiesList(pdbid)
    newUserDetail(pdbid, id, onSuccessUserDetail, onErrorUserDetail)
    return () => {
      clearUser()
    }
  }, [id, pdbid])

  const convertNullToString = data => {
    if (data === null) {
      return ''
    }
    return data
  }

  useEffect(() => {
    if (!isEmpty(userDetailData)) {
      const { name, email, title, phone, alt_phone, notes, permission_rules } =
        userDetailData || {}
      const { property, project } = context || {}

      const mappedPermissionRules = (permission_rules || []).map(rule => ({
        action: 'Update',
        internal_permission_rule_id: rule.internal_permission_rule_id,
        login_access: rule.login_access,
        permission_rule_id: rule.permission_rule_id,
        project_id: rule.project?.[0]?.project_id,
        property_id: rule.property_id || rule.project[0]?.property_id,
        role_id: rule.role_id,
        status: rule.status
      }))

      const propertyFound = (propertiesList || []).find(
        ({ property_code, project_code }) =>
          property_code === property && project_code === project
      )

      const foundRule = (mappedPermissionRules || []).find(
        ({ property_id, project_id }) =>
          propertyFound?.property_id === property_id &&
          propertyFound?.project_id === project_id
      )

      setUserName(convertNullToString(name))
      setEmail(convertNullToString(email))
      setUserTitle(convertNullToString(title))
      setUserPhone(convertNullToString(phone))
      setUserAltPhone(convertNullToString(alt_phone))
      setUserNote(convertNullToString(notes))
      setLoginAccess(!foundRule?.login_access ? true : false)
      setPermissionRules(mappedPermissionRules)
    }
  }, [userDetailData, propertiesList])

  const isSubmitButtonEnable = useMemo(() => {
    if (!(UserName || '').trim()) {
      return true
    }
    return false
  }, [UserName])

  const handleLoginAccessChange = value => {
    setLoginAccess(value)
  }

  const onSuccess = () => {
    setSubmitting(false)
    history.goBack()
    setAlert('Vendor User is successfully Updated', 'success')
  }

  const onError = () => {
    setSubmitting(false)
    setAlert('Something went wrong. Please try again later!!!', 'danger')
  }

  const onHandleEditVendor = () => {
    setSubmitting(true)
    const { property, project } = context || {}

    const propertyFound = (propertiesList || []).find(
      ({ property_code, project_code }) =>
        property_code === property && project_code === project
    )

    const updatedPermissionRules = (permissionRules || []).map(rule => {
      if (
        rule.property_id === propertyFound?.property_id &&
        rule.project_id === propertyFound?.project_id
      ) {
        return {
          ...rule,
          login_access: loginAccess ? null : 'false'
        }
      }
      return rule
    })

    const body = {
      ...userDetailData,
      name: UserName,
      title: userTitle || null,
      phone: userPhone || null,
      alt_phone: userAltPhone || null,
      notes: userNote || null,
      permission_rules: updatedPermissionRules
    }

    createOrEditUser(pdbid, body, onSuccess, onError)
  }

  if ((hasMadeInitialRequest && !isRequesting) || isLoading) {
    return <BigLoading />
  }

  if (errorMessage) {
    return (
      <div className="notification is-danger is-light is-flex">
        <div>
          <p>Something went wrong. Please try again later!!!</p>
        </div>
      </div>
    )
  }

  return (
    <div className="columns is-multiline add-vendor-login-user">
      <div className="column is-half animated fadeIn">
        <div className="box is-shadowless has-transition-opacity">
          <FieldTemplate>
            <div className="label-heading column">
              <strong className="is-size-5">Personal Information</strong>
            </div>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Name">
              <input
                id="name"
                name="name"
                value={UserName}
                autoFocus
                onChange={e => setUserName(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Name"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Email Address">
              <input
                id="email"
                name="email"
                value={email}
                type="text"
                autoComplete="off"
                disabled
                placeholder="Enter Email Address"
                className="input form-control"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Title" isRequired={false}>
              <input
                id="title"
                name="title"
                value={userTitle}
                onChange={e => setUserTitle(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Title"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Phone" isRequired={false}>
              <input
                id="phone"
                name="phone"
                value={userPhone}
                onChange={e => setUserPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Alt Phone" isRequired={false}>
              <input
                id="altphone"
                name="altphone"
                value={userAltPhone}
                onChange={e => setUserAltPhone(e?.target?.value)}
                type="text"
                className="input form-control"
                placeholder="Enter Alt Phone Number"></input>
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate isDisabledVcentered>
            <GeneralField fieldLabel="Notes" isRequired={false}>
              <textarea
                className="textarea  input form-control"
                rows={3}
                style={{ resize: 'none', marginTop: '2px' }}
                defaultValue={userNote}
                onChange={e => setUserNote(e.target.value)}
              />
            </GeneralField>
          </FieldTemplate>
          <FieldTemplate>
            <GeneralField fieldLabel="Enable Login" isRequired={false}>
              <SwitchButton
                isActive={loginAccess}
                onChange={handleLoginAccessChange}
              />
            </GeneralField>
          </FieldTemplate>
          <div>
            <PermissionGate name={'edit-vendor'}>
              <RestrictedElement>
                <div className="button-wrapper">
                  <button
                    className="button main-button is-secondary m-r-md"
                    onClick={() => history.goBack()}>
                    Cancel
                  </button>
                  <button
                    data-tip
                    data-for="saveuser"
                    onClick={() => {
                      if (!isSubmitButtonEnable) {
                        onHandleEditVendor()
                      }
                    }}
                    className={`button main-button is-primary ${
                      isSubmitButtonEnable ? 'button-disabled' : ''
                    }`}>
                    Save
                    {isSubmitting && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        className="m-l-sm"
                      />
                    )}
                  </button>
                  <ReactTooltip
                    className="customTooltipTheme"
                    id="saveuser"
                    place="top"
                    disable={!isSubmitButtonEnable}
                    effect="solid">
                    <div>
                      <p>
                        Please fill all the required (
                        <span className="required-star">*</span>) fields
                      </p>
                    </div>
                  </ReactTooltip>
                </div>
              </RestrictedElement>
            </PermissionGate>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VendorLoginUserEdit
