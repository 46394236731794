import React, { forwardRef } from 'react'

const TotalCost = ({ totalCost, minHeight }, ref) => {
  return (
    <div ref={ref}>
      <div
        className="box animated flipInX"
        style={{ height: '222px', minHeight }}>
        <p className="is-size-5">Total Cost</p>
        <p className="is-size-2 has-text-centered m-t-lg">{totalCost}</p>
      </div>
    </div>
  )
}

export default forwardRef(TotalCost)
