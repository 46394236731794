import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close } from 'ui/icons'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useAppContext, useAppUser } from '../../../helpers/hooks'
import { newForm } from '../formConfig'
import { useHistory } from 'react-router'
import { duplicatePropertyForm } from '../../../modules/property-forms'

const DuplicateFormModal = ({
  form = newForm,
  onSubmit,
  onConfirm = () => {},
  onDismiss = () => {}
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useAppUser()
  const {
    user: { pdbid },
    context
  } = useAppContext()

  const { duplicateIsRequesting, list } = useSelector(
    state => state.propertyForms
  )

  const isNameExist = useCallback(
    (formList, name) => (formList || []).some(form => form.name === name),
    []
  )

  const generatedFormName = useMemo(() => {
    let formName = `${form.name} - Copy`.replace(/  +/g, ' ').trim()

    if (isNameExist(list, formName)) {
      let randomNumber = 1

      while (isNameExist(list, `${formName} (${randomNumber})`)) {
        randomNumber++
      }

      formName = `${formName} (${randomNumber})`
    }

    return formName
  }, [form, isNameExist])

  const finalFormData = useMemo(() => {
    const formData = cloneDeep(form)

    formData.form_id = null
    formData.template_id = form.template_id || null
    formData.name = generatedFormName
    formData.type = formData.type.trim()
    formData.created_at = moment(new Date()).format('DD-MMM-YY')
    formData.created_by = formData.created_by || user.user_id

    formData.spaces = formData?.spaces?.length
      ? formData.spaces.map((space, spaceIndex) => ({
          space_id: space.space_id,
          sorting_order: `${spaceIndex + 1}`,
          form_space_id: null,
          do_delete_space: space.do_delete_space,
          required_on_property_forms: space.required_on_property_forms,
          template_space: space.template_space,
          items: space?.items?.length
            ? space.items.map((item, itemIndex) => ({
                item_id: item.item_id,
                sorting_order_item: `${itemIndex + 1}`,
                form_item_id: null,
                do_delete_item: item.do_delete_item,
                template_item: item.template_item,
                observations: item?.observations?.length
                  ? item.observations.map((observation, observationIndex) => ({
                      observation_id: observation.observation_id,
                      sorting_order_observation: `${observationIndex + 1}`,
                      form_observation_id: null,
                      do_delete_observation: observation.do_delete_observation,
                      solution_type: observation.solution_type,
                      media_required: observation.media_required,
                      media_minimum: observation.media_minimum,
                      template_observation: observation.template_observation,
                      solutions: observation?.solutions?.length
                        ? observation.solutions.map(
                            (solution, solutionIndex) => ({
                              solution_id: solution.solution_id,
                              sorting_order_solution: `${solutionIndex + 1}`,
                              form_solution_id: null,
                              do_delete_solution: solution.do_delete_solution,
                              template_solution: solution.template_solution
                            })
                          )
                        : [{ solution_id: null }]
                    }))
                  : [{ observation_id: null }]
              }))
            : [{ item_id: null }]
        }))
      : [{ space_id: null }]

    return formData
  }, [form, generatedFormName])

  const handleSubmit = () => {
    const formData = finalFormData
    onSubmit(true)

    const form_id = 0

    dispatch(
      duplicatePropertyForm(
        pdbid,
        context,
        formData,
        form_id,
        duplicateForm => {
          history.replace(
            `/inspections-setup/property-forms/manage?form=${duplicateForm.form_id}`
          )
          onSubmit(false)
          onConfirm()
        },
        () => {
          onSubmit(false)
          onDismiss()
        }
      )
    )
  }

  return (
    <div className="duplicate-template-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Are you sure?</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={onDismiss}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full">
          <p className="is-size-6">
            You're about to duplicate this form. Are you sure you want to
            duplicate?
          </p>
        </div>
      </div>

      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-secondary m-r-md"
            onClick={onDismiss}>
            No
          </button>
          <button
            className="button main-button is-primary"
            onClick={handleSubmit}>
            {duplicateIsRequesting && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default DuplicateFormModal
