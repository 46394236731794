import React from 'react'
import NoteCellComponent from './components/NoteCell'

export const headerData = () => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Vendor',
        accessor: 'vendor',
        textAlign: 'left',
        width: 160,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Service',
        accessor: 'servicetype',
        textAlign: 'left',
        width: 180,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'full_unit',
        textAlign: 'left',
        width: 80,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Amount ($)',
        accessor: 'amount',
        textAlign: 'left',
        width: 100,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      }
    ]
  }
]
