import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import BigLoading from '../../components/big-loading'
import { headerData } from './header'
import SetupStaffManageModalDelete from '../setup-staff-manage-modal-delete'
import { icons } from '../setup-staff-main/icons'
import RestrictedElement from '../../containers/restricted-element'
import PermissionGate from '../../components/permission-gate'
import { capitalize } from '../../utils/helperFunctions'
import SetupStaffTable from './setup-staff-table/setup-staff-table'
import { emptyPage, closeFilled, exportFile } from 'ui/icons'
import './index.scss'
import SetupStaffUserStepperModal from '../setup-staff-user-stepper-modal'
import SetupUserReactivateOrInactivateStepperModal from '../setup-user-reactivate-or-inactivate-stepper-modal'

const SetupStaffList = props => {
  const {
    history,
    user,
    context,
    setupStaff,
    openModal,
    closeModal,
    setModalContent,
    allStaffListGet,
    resetAllStaffListGet,
    resetCheckEmail
  } = props

  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [error, setError] = useState('')
  const tableRef = useRef()

  const { property, project } = context || {}
  const { pdbid } = user.user_metadata

  const handleShowDeleteStaffModal = useCallback(
    (user_id, permission_rule_id) => {
      setModalContent(() => (
        <SetupStaffManageModalDelete
          {...props}
          user={user}
          context={context}
          staffId={user_id}
          permissionRuleId={permission_rule_id}
          onCancel={closeModal}
          onConfirm={() => {
            allStaffListGet(pdbid, context)
            closeModal()
          }}
        />
      ))
      openModal({ width: '500px' })
    },
    [context]
  )

  const handleShowInactivateOrReactivateUserModal = useCallback(
    (permission_rule_id, status) => {
      setModalContent(() => (
        <SetupUserReactivateOrInactivateStepperModal
          {...props}
          user={user}
          context={context}
          permission_rule_id={permission_rule_id}
          status={!status}
          onCancel={closeModal}
          onConfirm={() => {
            allStaffListGet(pdbid, context)
            closeModal()
          }}
        />
      ))
      openModal({ width: '500px' })
    },
    [context]
  )

  const handleShowStaffUserStepperModal = () => {
    setModalContent(() => (
      <SetupStaffUserStepperModal
        onDismiss={() => {
          resetCheckEmail()
          closeModal()
        }}
        onCreateNewStaffUser={() => {
          closeModal()
          history.push('/setup/staff/user/new')
        }}
        onAfterAddStaffUserToProperty={() => {
          resetCheckEmail()
          allStaffListGet(pdbid, context)
          closeModal()
        }}
      />
    ))
    openModal({ width: '500px' })
  }

  const columns = useMemo(
    () =>
      headerData({
        onShowDeleteModal: handleShowDeleteStaffModal,
        onShowReactivateOrInactivateModal: handleShowInactivateOrReactivateUserModal
      }),
    [handleShowDeleteStaffModal, handleShowInactivateOrReactivateUserModal]
  )

  const { staffList, listError } = useMemo(() => {
    const { data, isError } = setupStaff.allStaff || {}
    return { staffList: data || [], listError: isError }
  }, [setupStaff])

  const checkIsFilterApplied = useMemo(() => {
    const checkSingleFilter = (isFilterApplied || []).filter(
      o => (o.value || []).length !== 0
    )
    return checkSingleFilter
  }, [isFilterApplied])

  const handleResetFilter = () => tableRef.current?.clearFilter([])

  useEffect(() => {
    allStaffListGet(pdbid, context)
  }, [location.pathname, property, project])

  useEffect(() => {
    if (listError) setError('Something went wrong, please try again later.')
  }, [listError])

  useEffect(() => {
    return () => {
      resetAllStaffListGet()
    }
  }, [property, project])

  const getEmptyState = () => (
    <div className="columns">
      <div className="column has-text-centered">
        <img src={icons.emptyPage} alt="Empty Page Icon" />
        <p className="is-size-5 m-t-md">
          Looks like you need to set up your staff!
        </p>
        <PermissionGate name={'add-staff-member'}>
          <RestrictedElement>
            <p className="has-text-grey is-size-7 m-t-sm">
              Ready when you are...
            </p>
            <button
              className="button is-primary m-t-md"
              onClick={handleShowStaffUserStepperModal}>
              Add Staff Member
            </button>
          </RestrictedElement>
        </PermissionGate>
      </div>
    </div>
  )

  const getLoadingState = () => <BigLoading />

  const exportCSV = () => {
    const { properties, projects } = user.user_metadata
    const property = properties.find(p => p.id === context.property)
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')

    const rows = tableRef.current?.getRows() || []
    const selectedRows = tableRef.current?.getSelectedRows() || []

    const filterData = (selectedRows.length ? selectedRows : rows || []).map(
      list => {
        const { name, email, status, role_name, login_access, account_status } =
          list || {}
        return {
          Name: capitalize(name || ''),
          'Email Address': capitalize(email || ''),
          Role: capitalize(role_name),
          'Account Status': capitalize(account_status),
          Status: capitalize(status || ''),
          'Enable Login': capitalize(login_access || '')
        }
      }
    )

    const data = filterData
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const encodedUri =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-all-staff-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const { allStaff } = setupStaff

  if (!allStaff.hasMadeInitialRequest || allStaff.isRequesting) {
    return getLoadingState()
  }

  if (allStaff.data.length < 1) {
    return getEmptyState()
  }

  return (
    <div className="setup-staff-list-container">
      <div className="data-table-wrapper">
        <div className="data-table-header">
          <div className="header">
            <h2 className="heading-5">Staff Members</h2>
          </div>
          <div className="action-button">
            <div className="staff-action-buttons">
              <PermissionGate name={'add-staff-member'}>
                <button
                  className="button is-success"
                  onClick={e => {
                    e.preventDefault()
                    handleShowStaffUserStepperModal()
                    // history.push('/setup/staff/user/new')
                  }}>
                  Add Staff Member
                </button>
              </PermissionGate>
              {(checkIsFilterApplied || []).length !== 0 && (
                <button
                  onClick={handleResetFilter}
                  className="button is-secondary">
                  Clear All Filter(s)
                </button>
              )}
            </div>
            <div>
              <button className="export" onClick={() => exportCSV()}>
                <img src={exportFile} alt="Export Inspections" />
                Export
              </button>
            </div>
          </div>

          {error !== '' && (
            <div>
              <br />
              <div className="notification is-danger is-light is-flex">
                <p>{error}</p>
                <button onClick={() => setError('')}>
                  <img src={closeFilled} alt="" />
                </button>
              </div>
            </div>
          )}
        </div>
        <SetupStaffTable
          data={staffList}
          ref={tableRef}
          tableColumns={columns}
          setIsFilterApplied={setIsFilterApplied}
        />
      </div>
    </div>
  )
}

SetupStaffList.propTypes = {
  user: PropTypes.object,
  setupStaff: PropTypes.object,
  setupAllStaffGet: PropTypes.func
}

export default SetupStaffList
