import React from 'react'
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { question, questionFilled } from 'ui/icons'

const GeneralField = ({
  children,
  fieldLabel,
  isTooltip = false,
  tooltipText = '',
  isRequired = true
}) => {
  return (
    <>
      <div className="label column is-one-third">
        <label>
          {fieldLabel}{' '}
          {isRequired ? <span className="required-star">*</span> : null}
          {isTooltip ? (
            <Tippy
              appendTo={() => document.body}
              content={
                <div className="content">
                  <p>{tooltipText}</p>
                </div>
              }
              placement="bottom-start"
              allowHTML
              theme="light">
              <img
                src={question}
                alt="help"
                onMouseOver={e => (e.currentTarget.src = questionFilled)}
                onMouseLeave={e => (e.currentTarget.src = question)}
              />
            </Tippy>
          ) : null}
        </label>
      </div>
      <div className=" column is-four-thirds input-field ">{children}</div>
    </>
  )
}

export default GeneralField
