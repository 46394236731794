import Service from '../service'

/**
 * QC module
 * @redux
 * @reduxActionScope qc
 * @module qc
 */

export const QC_GET_REQUESTED = 'QC_GET_REQUESTED'
export const QC_GET_FAIL = 'QC_GET_FAIL'

export const QC_GET_MYWORK_SUCCESS = 'QC_GET_MYWORK_SUCCESS'
export const QC_GET_QUEUE_SUCCESS = 'QC_GET_QUEUE_SUCCESS'
export const QC_GET_APPROVED_SUCCESS = 'QC_GET_APPROVED_SUCCESS'

export const QC_ASSIGN_SUCCESS = 'QC_ASSIGN_SUCCESS'
export const QC_ASSIGN_FAIL = 'QC_ASSIGN_FAIL'

export const QC_APPROVE_SUCCESS = 'QC_APPROVE_SUCCESS'
export const QC_APPROVE_FAIL = 'QC_APPROVE_FAIL'

const initialState = {
  isRequesting: false,
  isError: false,
  property: null,
  jobs: [],
  queue: [],
  approved: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case QC_GET_REQUESTED:
      return {
        ...state,
        isRequesting: true,
        isError: false,
      }
    case QC_GET_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true,
      }

    case QC_GET_MYWORK_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        jobs: action.payload.jobs,
        property: action.payload.property || state.property,
      }

    case QC_GET_QUEUE_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        queue: action.payload.queue,
        property: action.payload.property || state.property,
      }

    case QC_GET_APPROVED_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        isError: false,
        approved: action.payload.approved,
        property: action.payload.property || state.property,
      }

    case QC_ASSIGN_SUCCESS:
      return {
        ...state,
        isError: false,
      }

    case QC_ASSIGN_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true,
      }

    case QC_APPROVE_SUCCESS:
      return {
        ...state,
        isError: false
      }

    case QC_APPROVE_FAIL:
      return {
        ...state,
        isRequesting: false,
        isError: true
      }

    default:
      return state
  }
}

export const getMyWork = (pdbid, context, staffId) => {
  return dispatch => {
    dispatch({ type: QC_GET_REQUESTED })

    Service.qcMyWorkGet(pdbid, context, staffId).then(async res => {
      if (res.ok) {
        const data = await res.json()
        dispatch({
          type: QC_GET_MYWORK_SUCCESS,
          payload: {
            jobs: (data.mobile.jobs || []).slice(),
            property: data.mobile.property,
          }
        })
      } else {
        dispatch({ type: QC_GET_FAIL })
      }
    }).catch(() => {
      dispatch({ type: QC_GET_FAIL })
    })
  }
}

export const getQueue = (pdbid, context, staffId) => {
  return dispatch => {
    dispatch({ type: QC_GET_REQUESTED })

    Service.qcQueueGet(pdbid, context, staffId).then(async res => {
      if (res.ok) {
        const data = await res.json()

        // TODO: This is a fix, because backend return duplicated when have multiple teams;
        const queueMap = data.mobile.jobs?.reduce((result, order) => {
          const check = result.find(item => item.workorder === order.workorder)

          if (!check) {
            return [...result, {...order, qc_team_uri: [order.qc_team_uri]}]
          } else {
            const addTeam = {...check, qc_team_uri: [...check.qc_team_uri, order.qc_team_uri]}
            const newResult = result.filter(item => item.workorder !== order.workorder)
            return [...newResult, addTeam]
          }
        }, [])

        dispatch({
          type: QC_GET_QUEUE_SUCCESS,
          payload: {
            queue: queueMap || [],
            property: data.mobile.property,
          }
        })
      } else {
        dispatch({ type: QC_GET_FAIL })
      }
    }).catch(() => {
      dispatch({ type: QC_GET_FAIL })
    })
  }
}

export const getApproved = (pdbid, context, staffId) => {
  return dispatch => {
    dispatch({ type: QC_GET_REQUESTED })

    Service.qcApprovedGet(pdbid, context, staffId).then(async res => {
      if (res.ok) {
        const data = await res.json()
        dispatch({
          type: QC_GET_APPROVED_SUCCESS,
          payload: {
            approved: (data.mobile.jobs || []).slice(),
            property: data.mobile.property,
          }
        })
      } else {
        dispatch({ type: QC_GET_FAIL })
      }
    }).catch(() => {
      dispatch({ type: QC_GET_FAIL })
    })
  }
}

// TODO: Needs to implement assigning of multiple jobs in different service type (probably need to make sequence calls or ask backend to update)
// For now handling them as same service type as web admin only does support that way.
export const assignJobs = (pdbid, context, staffid, team, jobs) => {
  return dispatch => {
    if (jobs) {
      const service = jobs[0].service
      const operation_uri = jobs.map((job) => job.operation_uri).join(',')
      
      const json = {
        operation_uri,
        team_uri: team,
      }

      Service.qcAssignPut(pdbid, context, service, json)
        .then(async res => {
          if (res.ok) {
            dispatch({ type: QC_ASSIGN_SUCCESS })
            getQueue(pdbid, context, staffid)(dispatch)
          } else {
            dispatch({ type: QC_ASSIGN_FAIL })
          }
        })
        .catch(() => {
          dispatch({ type: QC_ASSIGN_FAIL })
        })
    }
  }
}

export const completeJob = (pdbid, context, staffid, job) => {
  return dispatch => {
    const gobacks = job.UnitSpaces.filter(o => o.qc_result === 'rejected')
    const accept = job.UnitSpaces.filter(o => o.qc_result === 'approved')

    // Approve API requires unit_id but /MyWork response doesn't return it, so derive it from workorder
    const [serviceId, unitId] = job.workorder.split('__')

    const json = {
      service: job.service,
      operation_uri: job.operation_uri,
      unit: job.unit,
      unit_id: unitId,
      unit_uri: unitId,
      goback_spaces: gobacks.map(us => us.unitspace).join(','),
      goback_notes: gobacks.map(us => `${us.unitspace}:${us.reason}:${us.notes}`).join(','),
      goback_task_uri: gobacks.map(us => us.task_uri).join(','),
      task_uri: accept.map(us => us.task_uri).join(','),
      workorder: job.workorder,
      qc_team_uri: job.team_uri,
      servicetype_id: serviceId
    }

    Service.qcCompletePut(pdbid, context, job, json)
      .then(async res => {
        if (res.ok) {
          const result = await res.json()

          dispatch({ type: QC_APPROVE_SUCCESS, data: job })
          
          dispatch({
            type: QC_GET_MYWORK_SUCCESS,
            payload: {
              jobs: result,
            }
          })
        } else {
          dispatch({ type: QC_APPROVE_FAIL })
        }
      })
      .catch(() => {
        dispatch({ type: QC_APPROVE_FAIL })
      })
  }
}
