import sortBy from 'lodash/sortBy'

export const newTemplate = {
  name: '',
  type: '',
  status: null,
  template_id: null,
  created_by: null,
  created_at: null,
  updated_at: null,
  spaces: [],
  shared: []
}

export const templateConfigSubmenu = [
  { label: 'Elements' },
  { label: 'Details' }
]

const sortElement = sortKey => {
  return (current, next) => {
    if (
      (current[sortKey] || '').toLowerCase() <
      (next[sortKey] || '').toLowerCase()
    )
      return -1
    if (
      (current[sortKey] || '').toLowerCase() >
      (next[sortKey] || '').toLowerCase()
    )
      return 1
    return 0
  }
}

export const sortAndMapTemplateElements = (data, type) => {
  return (data || []).sort(sortElement(`${type}_name`)).map(element => ({
    [`${type}_name`]: element[`${type}_name`],
    [`${type}_id`]: element[`${type}_id`],
    status: element.status || 'active'
  }))
  // return sortBy(
  //   data.map(item => ({
  //     [`${type}_name`]: item[`${type}_name`],
  //     [`${type}_id`]: item[`${type}_id`],
  //     status: item.status || 'active'
  //   })),
  //   [`${type}_name`]
  // )
}
