import React, { useState, useEffect, useMemo } from 'react'
import DataTable from '../DataTable'
import { addButton, trash } from 'ui/icons'

const InnerTabContainer = ({
  tabData,
  isEdit,
  onChangeTabData,
  headerData,
  onAddRow,
  onDeleteRow,
  setFilteredRows,
  clearAllFilter,
  onHandleDeleteRowModal,
  onRowSelectStateChange,
  selectedTableRows
}) => {
  const [tabs, setTabs] = useState([])
  const { content, id: currentTabId } = tabData || {}

  const onHandleAmountChange = (e, index, type, data) => {
    onChangeTabData(tabData?.id, index, e, type, data)
  }

  useEffect(() => {
    setTabs(tabData)
  }, [tabData])

  const memoizedColumns = useMemo(
    () =>
      headerData({
        isEdit,
        onHandleAmountChange,
        onDeleteRow,
        onHandleDeleteRowModal,
        tabId: currentTabId
      }),
    [headerData, isEdit, content?.length, currentTabId]
  )

  const memoizedData = useMemo(() => tabs || [], [tabs])

  return (
    <div>
      {tabs?.id === 5 && isEdit ? (
        <>
          <button
            className="button is-success btn-save"
            onClick={() => onAddRow(tabs?.id)}
            style={{ marginLeft: '10px', marginBottom: '10px' }}>
            <img
              src={addButton}
              style={{ marginLeft: '-7px', marginRight: '7px' }}
              className="is-inline m-t-0"
              alt="Add New Row"
            />
            Add Row
          </button>
          {selectedTableRows.length ? (
            <button
              className="button is-danger btn-save"
              onClick={() =>
                onHandleDeleteRowModal(selectedTableRows, currentTabId)
              }
              style={{ marginLeft: '10px', marginBottom: '10px' }}>
              {`Delete Selected Rows (${selectedTableRows.length})`}
            </button>
          ) : null}
        </>
      ) : null}
      {tabs?.id === 5 && isEdit && tabs?.error ? (
        <div className="notification is-danger is-light is-flex error-container">
          <strong>Error</strong>. There can be no two rows with the same Service
          Option, Unit and Space.
        </div>
      ) : null}
      <DataTable
        defaultcolumns={memoizedColumns}
        defaultdata={memoizedData?.content}
        setFilteredRows={setFilteredRows}
        clearAllFilter={clearAllFilter}
        isEdit={isEdit}
        onRowSelectStateChange={onRowSelectStateChange}
      />
    </div>
  )
}

export default InnerTabContainer
