import React, { useRef, useMemo, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import {
  create,
  color,
  useTheme,
  percent,
  ColorSet,
  registry
} from '@amcharts/amcharts4/core'
import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  Legend
} from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import Short from 'short-uuid'
import ComponentWithStatus from '../component-with-status'
import useWindowResize from '../../hooks/useWindowResize'

const AXIS_WIDTH = 75
const COLORS = ['#e5e9f2', '#122149', '#8bd3ed', '#0081c3']

/**
 * Goals summary bar charts
 *
 * @component
 * @example
 * const data = [
 *  {
 *    "category": "Walk",
 *    "goal": 0,
 *    "completed": 0,
 *    "goal_today": 0,
 *    "remaining_today": 0,
 *    "remaining": 0,
 *    "variance": 0,
 *    "variance_symbol": "+",
 *    "variance_color": "green"
 *  },
 *  {
 *    "category": "Paint",
 *    "goal": 414,
 *    "completed": 143,
 *    "goal_today": 415,
 *    "remaining_today": 272,
 *    "remaining": 271,
 *    "variance": -272,
 *    "variance_symbol": "-",
 *    "variance_color": "red"
 *  },
 *  {
 *    "category": "Punch",
 *    "goal": 414,
 *    "completed": 3,
 *    "goal_today": 414,
 *    "remaining_today": 411,
 *    "remaining": 411,
 *    "variance": -411,
 *    "variance_symbol": "-",
 *    "variance_color": "red"
 *  },
 *  {
 *    "category": "Clean",
 *    "goal": 400,
 *    "completed": 10,
 *    "goal_today": 400,
 *    "remaining_today": 390,
 *    "remaining": 390,
 *    "variance": -390,
 *    "variance_symbol": "-",
 *    "variance_color": "red"
 *  },
 *  {
 *    "category": "CP Clean",
 *    "goal": 0,
 *    "completed": 0,
 *    "goal_today": 0,
 *    "remaining_today": 0,
 *    "remaining": 0,
 *    "variance": 0,
 *    "variance_symbol": "+",
 *    "variance_color": "green"
 *  },
 *  {
 *    "category": "CP Rep",
 *    "goal": 0,
 *    "completed": 0,
 *    "goal_today": 0,
 *    "remaining_today": 0,
 *    "remaining": 0,
 *    "variance": 0,
 *    "variance_symbol": "+",
 *    "variance_color": "green"
 *  },
 *  {
 *    "category": "CR/V Rep",
 *    "goal": 0,
 *    "completed": 0,
 *    "goal_today": 0,
 *    "remaining_today": 0,
 *    "remaining": 0,
 *    "variance": 0,
 *    "variance_symbol": "+",
 *    "variance_color": "green"
 *  },
 *  {
 *    "category": "Sparkle",
 *    "goal": 0,
 *    "completed": 0,
 *    "goal_today": 0,
 *    "remaining_today": 0,
 *    "remaining": 0,
 *    "variance": 0,
 *    "variance_symbol": "+",
 *    "variance_color": "green"
 *  }
 * ]
 * return (
 *   <Goals
 *     loading={false}
 *     error={false}
 *     data={data}
 *   />
 * )
 */
const Goals = ({ loading, error, data, auto_goals }) => {
  const uuid = useMemo(() => Short.uuid(), [])
  const ref = useRef()
  const { width } = useWindowResize()

  const findChart = () => {
    return (
      registry.baseSprites.find(
        chartObj => chartObj.htmlContainer.id === `goals-chart-${uuid}`
      ) || null
    )
  }

  const getBarWidth = () =>
    ref.current && data && data.length
      ? (ref.current.offsetWidth - AXIS_WIDTH) / data.length
      : 0

  const createSeries = (
    target,
    field,
    name,
    isFirst,
    isInner,
    tooltip,
    gap
  ) => {
    const series = target.series.push(new ColumnSeries())
    series.dataFields.valueY = field
    series.dataFields.categoryX = 'category'
    series.name = name

    series.clustered = false
    series.columns.template.dx = (getBarWidth() / 4) * gap
    series.columns.template.width = isInner ? percent(29) : percent(35)
    //series.columns.template.stroke = color('rgba(0, 129, 195, 0.3)')
    //series.columns.template.strokeWidth = 1

    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = color('white')

    series.columns.template.tooltipHTML = tooltip
  }

  const getChart = () => {
    if (!ref.current || findChart()) return

    useTheme(am4themes_animated)

    const updated = create(`goals-chart-${uuid}`, XYChart)
    updated.responsive.enabled = true
    updated.height = 380
    updated.width = percent(100)
    updated.data = data
    const colorSet = new ColorSet()
    colorSet.list = COLORS.map(c => {
      return new color(c)
    })
    updated.colors = colorSet

    const categoryAxis = updated.xAxes.push(new CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 20
    categoryAxis.renderer.cellStartLocation = 0.1
    categoryAxis.renderer.cellEndLocation = 0.9

    const valueAxis = updated.yAxes.push(new ValueAxis())
    valueAxis.min = 0
    valueAxis.renderer.labels.template.fontSize = 12

    const totalTooltip = `
      <table class="table" style="background-color:rgba(255, 255, 255, 0.1)">
        <tbody>
          <tr>
            <td>
              <p style="color:black; text-align:left"><small>Turn Goal: {goal}</small></p>
              <p style="color:black; text-align:left"><small>Completed: {completed}</small></p>
            </td>
          </tr>
        </tbody>
      </table>
    `

    const todayTooltip = `
      <table class="table" style="background-color:rgba(255, 255, 255, 0.1)">
        <tbody>
          <tr>
            <td>
              <p style="color:black; text-align:left"><small>Today's Goal: {goal_today}</small></p>
              <p style="color:black; text-align:left"><small>Completed: {completed}</small></p>
              <p style="color:black; text-align:left"><small>Remaining: {remaining_today}</small></p>
            </td>
          </tr>
        </tbody>
      </table>
    `
    const unscheduledTooltip = `
      <table class="table" style="background-color:rgba(255, 255, 255, 0.1)">
        <tbody>
          <tr>
            <td>
              <p style="color:black; text-align:left"><small>Unscheduled Goals: {unschedule}</small></p>
            </td>
          </tr>
        </tbody>
      </table>
    `
    createSeries(updated, 'goal', 'Turn Goals', true, false, totalTooltip, -1.0)
    createSeries(
      updated,
      'completed',
      'Completed to Date',
      true,
      true,
      totalTooltip,
      -1.0
    )
    createSeries(
      updated,
      'goal_today',
      'Today\'s Goal',
      false,
      false,
      todayTooltip,
      0.2
    )
    if(!auto_goals) {
      createSeries(
        updated,
        'unschedule',
        'Unscheduled Goals',
        false,
        false,
        unscheduledTooltip,
        1.4
      )
    }
    // createSeries(
    //   updated,
    //   'completed_today',
    //   'Completed Today',
    //   false,
    //   true,
    //   todayTooltip
    // )

    updated.legend = new Legend()
    updated.legend.useDefaultMarker = true
    updated.legend.labels.template.fontSize = 12
    const marker = updated.legend.markers.template.children.getIndex(0)
    marker.dx = -10
    marker.width = 32
    marker.height = 23

    return updated
  }

  const disposeChart = () => {
    const foundChart = findChart()
    if (foundChart) {
      foundChart.dispose()
    }
  }

  useEffect(() => {
    return () => {
      disposeChart()
    }
  }, [])

  useEffect(() => {
    disposeChart()
    getChart()
  }, [ref.current, data, width])

  return (
    <div className="dashboard-card card-fill">
      <div className="inner-content" style={{ height: '455px' }}>
        <ComponentWithStatus loading={loading} error={error}>
          <>
            <div className="columns">
              <div className="column is-full heading-5">Goals</div>
            </div>
            <div className="columns">
              <div className="column is-full" style={{ height: '400px' }}>
                <div
                  id={`goals-chart-${uuid}`}
                  className={`goals-chart-${uuid} has-text-centered`}
                  ref={ref}
                  style={{ height: '380px' }}></div>
              </div>
            </div>
          </>
        </ComponentWithStatus>
      </div>
    </div>
  )
}

Goals.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Goals summary data
   */
  data: PropTypes.array,
}

export default memo(Goals)
