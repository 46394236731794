import React from 'react'
import PropTypes from 'prop-types'

const ProgressItem = ({ item, activeItem, submitted_at }) => {
  const { title, itemId } = item || {}
  const { active, completed } = activeItem || {}
  const checkCurrentTabCompletedStatus = completed.includes(itemId)
  return (
    <div className={`progress-item ${itemId === active && 'activeTab'} ${submitted_at && 'completedTab'}  ${checkCurrentTabCompletedStatus && 'completedTab'}`}>
      <span className="item">
        { title }
      </span>
    </div>
  )
}


ProgressItem.propTypes = {
  item: PropTypes.object,
  activeItem: PropTypes.object,
  submitted_at: PropTypes.string
}

export default ProgressItem

