import note from './icons/note.svg'
import noteAdd from './icons/note-add.svg'
import transfers from './icons/transfers.svg'
import transfersSmall from './icons/transfers-small.svg'
import transfersActiveBlue from './icons/transfers-active-blue.svg'
import as from './icons/as.svg'
import asFull from './icons/as-full.svg'
import asIs from './icons/asis.svg'
import asIsSmall from './icons/asis-small.svg'
import fullAsIs from './icons/full-asis.svg'
import fullAsIsSmall from './icons/full-asis-small.svg'
import carpet from './icons/carpet.svg'
import carpetSmall from './icons/carpet-small.svg'
import dark from './icons/dark@2x.svg'
import fullyVacant from './icons/fully-vacant.svg'
import partiallyOccupied from './icons/partially-occupied.svg'

export const icons = {
  note,
  noteAdd,
  transfers,
  transfersSmall,
  transfersActiveBlue,
  as,
  asFull,
  asIs,
  asIsSmall,
  fullAsIs,
  fullAsIsSmall,
  carpet,
  carpetSmall,
  dark,
  fullyVacant,
  partiallyOccupied,
}
