import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateContext, requestContextChange } from '../../modules/context'
import HomeMain from '../../parents/home-main'
import BigLoading from '../../components/big-loading'
import VendorDashboard from '../vendor-dashboard'
import { Redirect } from 'react-router-dom'

const Home = props => {
  const { user } = props
  const checkIsVendor = () => {
    const { roles } = user || {}
    if (Array.isArray(roles) && roles.length > 0) {
      const exist = (roles || []).find(
        role => role?.toLowerCase() === 'rfp vendor'
      )
      return (exist || []).length > 0
    }
    return false
  }

  if (user.hasMadeInitialRequest && !user.isRequesting) {
    const { user_metadata } = user || {}
    const { firstTimeLogin } = user_metadata || {}
    return (
      <>{checkIsVendor() ? <VendorDashboard /> : <HomeMain {...props} />}</>
    )
  }

  return null
}

Home.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = ({ user }) => ({
  user
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateContext,
      requestContextChange
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Home)
)
