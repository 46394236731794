export const mapActiveUnits = (units, unitsComparitionDate) => {
  return units.map(unit => {
    const unitDate = unit.earliest_date && new Date(unit.earliest_date)
    return {
      ...unit,
      active: unitDate ? unitDate.getTime() <= unitsComparitionDate : false,
    }
  })
}

export const getUnits = (scheduledItems, dateItem) => {
  scheduledItems.forEach(scheduledItem => {
    if (dateItem.date === new Date(scheduledItem.scheduled_date).getTime()) {
      dateItem.team_capacity.forEach(teamItem => {
        if (teamItem.team_id === scheduledItem.team_id) {
          teamItem.units.push({...scheduledItem, active: true, show: true })
          teamItem.availableSpaces = parseInt(teamItem.availableSpaces) - parseInt(scheduledItem.total_spaces)
        }
      })
    }
  })

  return dateItem
}
