import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Service from '../../service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'

/**
 * Component for showing the main app logo
 *
 * @component
 * @example
 * return (
 *   <Logo
 *      isOpen
 *      logo="http://example.com/img/logo.jpg"
 *      isLoading={false}
 *   />
 * )
 */
const Logo = ({ isOpen, logo, isLoading, closeSidebar }) => {
  const el = useRef()
  const [isInTransition, setIsInTransition] = useState(false)

  useEffect(() => {
    el.current.addEventListener(
      'transitionstart',
      e => onTransition('start', e),
      {},
      true
    )
    el.current.addEventListener(
      'transitionend',
      e => onTransition('end', e),
      {},
      true
    )
  }, [el])

  const onTransition = (which, e) => {
    const inTransition = which === 'start'
    if (e.propertyName !== 'width') return
    setIsInTransition(inTransition)
  }

  let baseStyles = {
    opacity: !isOpen || isInTransition ? 0 : 1,
    transition: 'opacity 350ms'
  }
  const customStyles = {
    height: '100%',
    width: '100%',
    padding: 0
  }
  const isCustom = logo !== Service.LOGO
  const styles = !isCustom ? baseStyles : { ...baseStyles, ...customStyles }
  return (
    <div
      className={`logo-container is-hidden-touch ${
        isLoading ? 'is-hidden' : 'animated fadeIn'
      }`}
      style={{
        width: isOpen ? '275px' : '0px',
        transition: 'width 500ms',
        position: 'fixed'
      }}
      ref={el}>
      <Link to="/">
        <img
          src={logo}
          className="turnable-logo"
          alt="Turnable Logo"
          style={styles}
        />
      </Link>
      {isOpen && !isInTransition && (
        <div
          style={{
            position: 'fixed',
            left: '240px',
            top: '18px'
          }}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            size="xs"
            className="has-text-primary m-l-md m-t-none m-b-none m-r-none is-hidden-mobile is-pointer"
            onClick={closeSidebar}
            style={baseStyles}
          />
        </div>
      )}
    </div>
  )
}

Logo.propTypes = {
  /**
   * Determines if the logo is currently visible
   */
  isOpen: PropTypes.bool,
  /**
   * URL for the logo image
   */
  logo: PropTypes.string,
  /**
   * Determines if the page is currently loading
   */
  isLoading: PropTypes.bool,
  /**
   * Event handler for close sidebar action
   */
  closeSidebar: PropTypes.func
}

export default Logo
