import React from 'react'
import { icons } from './icons'
import NoteCellComponent from './components/NoteCell'
import { GetToolTip } from './helper'
import PermissionGate from '../../components/permission-gate'

export const headerData = ({
  manageNote,
  setRemoveAllActiveFilter,
  setDeleteRequested,
  setDeleteNoteId,
  setDeleteUnitId,
  setErrorMessage,
  allNotesRawData = []
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Unit',
        accessor: 'unit',
        textAlign: 'center',
        width: 60,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Category',
        accessor: 'type',
        textAlign: 'left',
        width: 120,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Date',
        accessor: 'creation_date',
        textAlign: 'left',
        disableFilters: true,
        width: 80,
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: 'Message',
        accessor: 'note',
        disableSortBy: true,
        disableFilters: true,
        width: 280,
        textAlign: 'left',
        Cell: row => {
          return <NoteCellComponent row={row} />
        }
      },
      {
        Header: '',
        accessor: 'edit',
        disableSortBy: true,
        id: 'action-button',
        width: 50,
        disableFilters: true,
        textAlign: 'right',
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { id, unit_id, purchase_order_id } = original || {}
          return (
            <div className="cell-item note-icon s-flex">
              <PermissionGate name="manage-notes">
                <GetToolTip purchaseOrder={purchase_order_id} type="edit">
                  <img
                    className="is-pointer is-icon"
                    style={{ marginRight: '3px' }}
                    src={icons.pencil}
                    onClick={e => {
                      e.preventDefault()
                      if (!purchase_order_id) {
                        manageNote(
                          { ...original, allNotesRawData },
                          setRemoveAllActiveFilter
                        )
                      }
                    }}
                    alt="Edit Note"
                  />
                </GetToolTip>
                <GetToolTip purchaseOrder={purchase_order_id} type="delete">
                  <img
                    className="is-pointer is-icon"
                    onClick={e => {
                      e.preventDefault()
                      if (!purchase_order_id) {
                        setErrorMessage('')
                        setDeleteRequested(true)
                        setDeleteNoteId(id)
                        setDeleteUnitId(unit_id)
                      }
                    }}
                    src={icons.trash}
                    alt="delete-note"
                  />
                </GetToolTip>
              </PermissionGate>
            </div>
          )
        }
      }
    ]
  }
]
