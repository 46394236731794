import React, { useEffect, useState } from 'react'
import '../../styles/common.scss'

const SwitchButton = ({
  onChange,
  isActive,
  leftLabel = '',
  rightLabel = '',
  removeMargin = false,
  disabled = false
}) => {
  const [isChecked, setChecked] = useState(false)

  const styles = removeMargin ? { marginTop: '0' } : {}

  const handleChange = () => {
    setChecked(!isChecked)
    onChange(!isChecked)
  }

  useEffect(() => {
    setChecked(isActive)
  }, [isActive])

  return (
    <div className="toggle-container" style={styles}>
      {leftLabel && <span style={{ marginRight: '9px' }}>{leftLabel}</span>}
      <label className={`toggle-control ${disabled ? 'isDisabled' : ''}`}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          disabled={disabled}
        />
        <span className="control"></span>
      </label>
      {rightLabel && <span>{rightLabel}</span>}
    </div>
  )
}

export default SwitchButton
