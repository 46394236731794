/**
 * Property forms data module
 * @redux
 * @reduxActionScope Property forms
 * @module PropertyForms
 */

/**
 * Get property forms list
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_LIST_REQUESTED =
  'propertyforms/PROPERTY_FORMS_LIST_REQUESTED'

/**
 * Get property forms list has failed
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_LIST_FAIL = 'propertyforms/PROPERTY_FORMS_LIST_FAIL'

/**
 * Get property forms list has succeeded
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_LIST_SUCCESS =
  'propertyforms/PROPERTY_FORMS_LIST_SUCCESS'

/**
 * Reset property forms list
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_LIST_RESET =
  'propertyforms/PROPERTY_FORMS_LIST_RESET'

/**
 * Get property forms elements
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_ELEMENTS_REQUESTED =
  'propertyforms/PROPERTY_FORMS_ELEMENTS_REQUESTED'

/**
 * Get property forms elements has failed
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_ELEMENTS_FAIL =
  'propertyforms/PROPERTY_FORMS_ELEMENTS_FAIL'

/**
 * Get property forms elements has succeeded
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_ELEMENTS_SUCCESS =
  'propertyforms/PROPERTY_FORMS_ELEMENTS_SUCCESS'

/**
 * Reset property forms elements
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_ELEMENTS_RESET =
  'propertyforms/PROPERTY_FORMS_ELEMENTS_RESET'

/**
 * Get property forms details
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_DETAILS_REQUESTED =
  'propertyforms/PROPERTY_FORMS_DETAILS_REQUESTED'

/**
 * Get property forms details has failed
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_DETAILS_FAIL =
  'propertyforms/PROPERTY_FORMS_DETAILS_FAIL'

/**
 * Get property forms details has succeeded
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_DETAILS_SUCCESS =
  'propertyforms/PROPERTY_FORMS_DETAILS_SUCCESS'

/**
 * Reset property forms details
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORMS_DETAILS_RESET =
  'propertyforms/PROPERTY_FORMS_DETAILS_RESET'
/**
 * Reset property forms errors
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_ERROR_RESET =
  'propertyforms/PROPERTY_FORM_ERROR_RESET'

/**
 * Create or update property forms request
 * @type {Redux.ActionType}
 */
export const CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED =
  'propertyforms/CREATE_OR_UPDATE_PROPERTY_FORM_REQUESTED'

/**
 * Create or update property forms fail
 * @type {Redux.ActionType}
 */
export const CREATE_OR_UPDATE_PROPERTY_FORM_FAIL =
  'propertyforms/CREATE_OR_UPDATE_PROPERTY_FORM_FAIL'

/**
 * Create or update property forms success
 * @type {Redux.ActionType}
 */
export const CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS =
  'propertyforms/CREATE_OR_UPDATE_PROPERTY_FORM_SUCCESS'

/**
 * Reset create property forms
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_CREATE_RESET =
  'propertyforms/PROPERTY_FORM_CREATE_RESET'

/**
 * Delete a property form
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DELETE_REQUESTED =
  'propertyforms/PROPERTY_FORM_DELETE_REQUESTED'

/**
 * Delete a property form has failed
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DELETE_FAIL =
  'propertyforms/PROPERTY_FORM_DELETE_FAIL'

/**
 * Delete a property form was successful
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DELETE_SUCCESS =
  'propertyforms/PROPERTY_FORM_DELETE_SUCCESS'

/**
 * Reset delete a property form state
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DELETE_RESET =
  'propertyforms/PROPERTY_FORM_DELETE_RESET'

/**
 * Duplicate a property form
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DUPLICATE_REQUESTED =
  'propertyforms/PROPERTY_FORM_DUPLICATE_REQUESTED'

/**
 * Duplicate a property form has failed
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DUPLICATE_FAIL =
  'propertyforms/PROPERTY_FORM_DUPLICATE_FAIL'

/**
 * Duplicate a property form was successful
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DUPLICATE_SUCCESS =
  'propertyforms/PROPERTY_FORM_DUPLICATE_SUCCESS'

/**
 * Reset duplicate a property form state
 * @type {Redux.ActionType}
 */
export const PROPERTY_FORM_DUPLICATE_RESET =
  'propertyforms/PROPERTY_FORM_DUPLICATE_RESET'
