import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { close, closeFilled } from 'ui/icons'

const DeleteUserModal = props => {
  const {
    userId,
    closeModal,
    userAccountDelete,
    user,
    history,
    onHandleForceReload
  } = props || {}
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const { user_metadata } = user || {}
  const { pdbid } = user_metadata || {}

  const onUserDeleteAccountError = () => {
    setIsLoading(false)
    setErrorMessage('Something went wrong. Please try again later!!!')
  }

  const onUserDeleteAccountSuccess = () => {
    setIsLoading(false)
    setErrorMessage('')
    if (onHandleForceReload) {
      onHandleForceReload(true)
    } else {
      history.push('/setup/users')
    }
    closeModal()
  }

  return (
    <div className="confirmation-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Delete User</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={closeModal}>
            <img alt="Delete Note" src={close} />
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column is-full delete-user">
          <p className="is-size-6">
            This action cannot be undone and the user will need to be manually
            re-added if needed in the future.
          </p>
          <p className="is-size-6 sub-message">
            Are you sure you want to delete this user?
          </p>
        </div>
      </div>

      {errorMessage !== '' && (
        <div className="notification is-danger is-light is-flex">
          <div>
            <p>Something went wrong. Please try again later !!!</p>
          </div>
          <button className="error-button" onClick={() => setErrorMessage('')}>
            <img src={closeFilled} alt="" />
          </button>
        </div>
      )}
      <div className="columns is-vcentered m-b-sm m-t-md">
        <div className="center-container">
          <button
            className="button main-button is-primary m-r-md"
            onClick={closeModal}>
            Cancel
          </button>
          <button
            className="button main-button is-danger"
            onClick={() => {
              setIsLoading(true)
              setErrorMessage('')
              userAccountDelete(
                pdbid,
                userId,
                onUserDeleteAccountSuccess,
                onUserDeleteAccountError
              )
            }}>
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="m-r-sm" />
            )}
            Yes
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteUserModal
