import styled from 'styled-components'

export const VendorTableStyles = styled.div`
overflow: auto;
max-width: 100%;
position: relative;
display: flex;
justify-content: center;
input {
  margin-top:8px !important;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E9F2;
  margin: 0px auto;
  outline: none;
  font-size: 10px
}
select {
  width: 100%
  margin-top:8px !important;
}
  .vendor-company-table{
    display: inline-block;
    border-spacing: 0;
    position: relative;
    .tr {
      position: relative;
    }
    .th {
      text-align: center;
      padding: 0px;
    }

    .th,
    .td {
      margin: 0;
      border-bottom: 1px solid #e5e9f2;
      position: relative;
     
    }
  }
`
