import _ from 'lodash'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import Short from 'short-uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  MapChart,
  MapPolygonSeries,
  MapImageSeries,
  projections
} from '@amcharts/amcharts4/maps'
import {
  Container,
  Circle,
  color,
  percent,
  create
} from '@amcharts/amcharts4/core'
import { Legend } from '@amcharts/amcharts4/charts'
import usaLow from '@amcharts/amcharts4-geodata/usaLow'
import { getCorporateMap } from '../../modules/dashboard-map'

const CIRCLE_RADIUS = {
  SMALL: 3,
  MEDIUM: 5,
  BIG: 8
}

const COLOR_NAME = {
  GREEN: 'Good',
  YELLOW: 'Trouble',
  RED: 'Bad'
}

const HealthMap = ({
  user,
  context,
  property,
  map,
  getCorporateMap,
  onPropertySelected
}) => {
  const uuid = Short.uuid()

  useEffect(() => {
    const { pdbid } = user.user_metadata
    getCorporateMap(pdbid, context.project.id)
  }, [user, context, getCorporateMap])

  useEffect(() => {
    if (!map.isRequesting && map.hasMadeInitialRequest) makeMap(property)
  }, [map.isRequesting, map.hasMadeInitialRequest, property])

  const makeSeries = (mapChart, colorName, data) => {
    const locations = mapChart.series.push(new MapImageSeries())
    locations.mapImages.template.propertyFields.longitude = 'longitude'
    locations.mapImages.template.propertyFields.latitude = 'latitude'
    locations.mapImages.template.tooltipText = '{name}'
    locations.name = colorName
    locations.fill = color(colorName)

    const circle = locations.mapImages.template.createChild(Circle)
    circle.adapter.add('radius', (radius, target) => {
      return _.get(target, 'parent.dataItem.dataContext.size')
        ? CIRCLE_RADIUS[
          _.get(target, 'parent.dataItem.dataContext.size').toUpperCase()
        ]
        : radius
    })
    circle.adapter.add('fill', (fill, target) =>
      _.get(target, 'parent.dataItem.dataContext.fill', fill)
    )

    locations.data = data
    locations.events.on('hit', e => {
      onPropertySelected(e.target.data[0].id)
    })
  }

  const makeMap = property => {
    const container = create(`map-${uuid}`, Container)
    container.width = percent(100)
    container.height = percent(100)

    const mapChart = new MapChart()
    mapChart.parent = container
    mapChart.geodata = usaLow
    mapChart.projection = new projections.AlbersUsa()
    mapChart.height = percent(100)

    mapChart.legend = new Legend()
    mapChart.legend.useDefaultMarker = false
    mapChart.legend.background.fill = color('#000')
    mapChart.legend.background.fillOpacity = 0.05
    mapChart.legend.width = 150
    mapChart.legend.align = 'right'

    const marker = mapChart.legend.markers.template
    marker.width = 16
    marker.height = 16
    marker.adapter.add('fill', (fill, target) =>
      _.get(target, 'parent.dataItem.dataContext.fill', fill)
    )

    const label = mapChart.legend.labels.template
    label.disposeChildren()
    label.adapter.add('text', (text, target) => {
      if (_.get(target, 'parent.dataItem.name')) {
        return COLOR_NAME[_.get(target, 'parent.dataItem.name').toUpperCase()]
      }
      return text
    })

    const polygonSeries = mapChart.series.push(new MapPolygonSeries())
    polygonSeries.useGeodata = true
    polygonSeries.hiddenInLegend = true

    const polygonTemplate = polygonSeries.mapPolygons.template
    polygonTemplate.tooltipText = '{name}'
    polygonTemplate.fill = color('#dddddd')

    const hs = polygonTemplate.states.create('hover')
    hs.properties.fill = color('#eeeeee')
    const data = property
      ? _.filter(map.data, p => p.id === property)
      : map.data
    const groups = _.groupBy(data, l => l.color)
    _.forEach(Object.keys(groups), group => {
      makeSeries(mapChart, group, groups[group])
    })
  }

  if (map.isRequesting) {
    return (
      <div className="columns">
        <div className="column is-full">
          <div
            className="is-size-4 no-wrap"
            style={{
              width: '100%',
              textAlign: 'center',
              paddingTop: '220px',
              paddingBottom: '220px'
            }}>
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
          </div>
        </div>
      </div>
    )
  }

  return <div id={`map-${uuid}`} style={{ height: 500 }}></div>
}

HealthMap.propTypes = {
  user: PropTypes.object,
  map: PropTypes.object,
  getCorporateMap: PropTypes.func.isRequired
}

const mapStateToProps = ({ user, context, dashboard }) => ({
  user,
  context,
  map: dashboard.map
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCorporateMap
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthMap)
