import React, { useState, memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ProjectModel } from '@bryntum/gantt'
import GanttChart from '../../components/scheduleGanttChart'
import ScheduleCalendar from '../../components/scheduleCalendar'
import Breadcrumbs from '../breadcrumbs'
import TabOptions from '../../components/tab-options'
import './index.scss'

const ScheduleBoardsMain = ({ location, schedule }) => {
  const { boardsMainData } = schedule

  const tabs = [
    {
      label: 'Calendar View',
      id: 'calendar',
      overrideActiveBackground: '#3DB3E2',
      onClick: value => setActiveTab(value),
      active: true
    },
    {
      label: 'Gantt View',
      id: 'gantt',
      overrideActiveBackground: '#3DB3E2',
      onClick: value => setActiveTab(value),
      active: false
    },
    // { label: 'Grid View', id: 'grid', overrideActiveBackground: '#3DB3E2', onClick: value => setActiveTab(value), active: false },
  ]
  const [activeTab, setActiveTab] = useState(tabs[0])

  const getActiveBoard = useCallback(() => {
    switch (activeTab['id']) {
      case 'gantt':
        const projects = {
          servicesConfig: new ProjectModel({
            eventsData: boardsMainData.servicesGanttData
          }),
          teamsConfig: new ProjectModel({
            eventsData: boardsMainData.teamsGanttData
          }),
          groupsConfig: new ProjectModel({
            eventsData: boardsMainData.groupsGanttData
          })
        }
        return <GanttChart project={projects}/>

      case 'calendar':
        return <ScheduleCalendar data={boardsMainData} />

      case 'grid':
        return <p>this is the grid view</p>

      default:
        return null
    }
  }, [tabs])

  return (
    <div className="container animated fadeIn is-fullheight">
      <div className="section is-fullheight">
        <div className="columns is-marginless">
          <div className="column is-one-half is-paddingless">
            <Breadcrumbs location={location} />
          </div>
        </div>
        <div className="boards-content fullsize">
          <TabOptions tabs={tabs} style={{ top: -34 }} activeTab={activeTab} />
          <div className="boards-body">
            {getActiveBoard()}
          </div>
        </div>
      </div>
    </div>
  )
}

ScheduleBoardsMain.propTypes = {
  location: PropTypes.object,
  getScheduleMainData: PropTypes.func,
}

export default memo(ScheduleBoardsMain, (prevProps, nextProps) => {
  return prevProps.schedule === nextProps.schedule
})
