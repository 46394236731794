import { v4 as uuidv4 } from 'uuid'

/**
 * Timed alerts module
 * @redux
 * @reduxActionScope alerts
 * @module alerts
 */

/**
 * Sets a new alert
 * @type {Redux.ActionType}
 */
export const SET_ALERT = 'alerts/SET_ALERT'
/**
 * Removes an alert
 * @type {Redux.ActionType}
 */
export const REMOVE_ALERT = 'alerts/REMOVE_ALERT'

const initialState = []

/**
 * Alerts reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return [...state, action.alert]
    case REMOVE_ALERT:
      return state.filter(alert => alert.id !== action.id)
    default:
      return state
  }
}

/**
 * Sets a new timed alert
 * @redux
 * @reduxActionCreator SET_ALERT, REMOVE_ALERT
 * @param {string} msg - the message to be displayed
 * @param {string} type - the type of the alert (danger, warning, info, success)
 * @param {number} timeout - the duration of the alert in milliseconds
 */
export const setAlert = (msg, type, timeout = 3000) => dispatch => {
  const id = uuidv4()
  dispatch({
    type: SET_ALERT,
    alert: { msg, type, id }
  })

  setTimeout(() => dispatch({ type: REMOVE_ALERT, id }), timeout)
}
