import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { usePrevious } from '../../helpers/hooks'
import { getFormData, animate, hasDecimalPlace } from '../../helpers'

const SetupBudgetsServicesModalCreate = ({
  context,
  user,
  bl,
  setupBudgetLineCreate,
  setupBudgetLineUpdate,
  setupBudgets,
  setupVendors,
  closeModal,
  updateData
}) => {
  const [selectedService, setSelectedService] = useState(
    !bl ? 'none' : bl.servicetype_uri
  )
  const [budgetAmount, setBudgetAmount] = useState(!bl ? '0' : bl.budget)

  const prevBl = usePrevious(bl)

  useEffect(() => {
    if (
      (!prevBl && bl) ||
      (prevBl && bl && prevBl.budget_line_uri !== bl.budget_line_uri)
    ) {
      setSelectedService(bl.servicetype_uri)
      setBudgetAmount(bl.budget)
    }
  }, [bl])

  const submit = () => {
    const { budget_id } = setupBudgets.budget.data
    const { pdbid } = user.user_metadata
    const form = document.getElementById('setup-budget-services-modal-create')
    const body = getFormData(form)

    if (body.budget_amount >= 0 && selectedService !== 'none') {
      if (bl === null) setupBudgetLineCreate(pdbid, context, budget_id, body)
      else
        setupBudgetLineUpdate(pdbid, context, budget_id, {
          ...body,
          budget_line_uri: bl.budget_line_uri
        })
      closeModal()
      updateData()
    } else {
      const modalCard = document.getElementById('modal-card')
      animate(modalCard, 'shake')
      let toFocus = null
      let element = null
      if (selectedService === 'none') toFocus = 'vendor-services-modal-service'
      else if (body.budget_amount < 0) toFocus = 'vendor-services-modal-cost'
      if (toFocus !== null) element = document.getElementById(toFocus)
      if (element !== null) element.focus()
    }
  }

  const getServiceLabel = label =>
    label.toLowerCase() === 'cleaning' ? 'Clean' : label

  const getServices = () => {
    const { data } = setupVendors.allServices
    const { budget } = setupBudgets
    const lines = _.map(
      _.get(budget, 'data.budget_lines', []),
      l => l.servicetype
    )
    let template = []
    for (let i = 0; i < data.length; i += 1) {
      const d = data[i]
      if (bl !== null || lines.indexOf(d.servicetype) < 0) {
        template.push(
          <option
            value={d.servicetype_uri}
            key={`service-option-${d.servicetype_id}`}>
            {getServiceLabel(d.servicetype)}
          </option>
        )
      }
    }
    return template
  }

  const { budget_uri } = setupBudgets.budget.data
  const onSubmit = e => e.preventDefault()

  return (
    <form onSubmit={e => onSubmit(e)} id="setup-budget-services-modal-create">
      <input type="hidden" name="budget_uri" value={budget_uri} />
      <div className="columns is-multiline">
        <div className="column is-half">
          <p className="is-size-4 m-b-md">
            {bl ? 'Edit Service Budget' : 'New Service Budget'}
          </p>
          <div className="form-control">
            <label forhtml="service">Service</label>
            <div className="select is-fullwidth">
              <select
                className="input is-fullwidth"
                id="vendor-services-modal-service"
                onChange={e => setSelectedService(e.target.value)}
                value={selectedService}
                disabled={bl !== null}>
                <option value="none">Select Service</option>
                {getServices()}
              </select>
              <input
                type="hidden"
                name="servicetype_uri"
                value={selectedService}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-half">
          <div className="form-control">
            <label forhtml="option">Total Budget</label>
            <input
              type="text"
              value={budgetAmount || ''}
              name="budget_amount"
              id="vendor-services-modal-cost"
              onChange={e => {
                const { target } = e || {}
                const { value } = target || {}
                let filterValue = value || ''
                // Only allow Numbers and .
                filterValue = (value || '').replace(/[^0-9\.]/g, '')
                // Don't allow . as first character
                if ((filterValue || '')[0] === '.') {
                  filterValue = ''
                }
                // Check if . already exists
                if ((filterValue || '').toString().split('.').length > 2) {
                  filterValue = (filterValue || '').replace(/\.+$/, '')
                }
                // Dont allow more than 2 decimal points
                if (hasDecimalPlace(filterValue, 3)) {
                  return
                }
                setBudgetAmount(filterValue)
              }}
            />
            {/* <input
              type="number"
              className="input"
              min="1"
              step="1"
              name="budget_amount"
              id="vendor-services-modal-cost"
              value={budgetAmount}
              onChange={e => setBudgetAmount(e.target.value)}
            /> */}
          </div>
        </div>
      </div>
      <div className="columns is-desktop is-mobile">
        <div className="column is-one-quarter is-offset-one-quarter has-text-right">
          <div className="form-control">
            <button
              type="button"
              className="button is-secondary"
              onClick={() => {
                closeModal()
              }}>
              Cancel
            </button>
          </div>
        </div>
        <div className="column is-one-quarter has-text-left">
          <div className="form-control">
            <button
              type="button"
              className="button is-primary"
              onClick={() => submit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

SetupBudgetsServicesModalCreate.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  bl: PropTypes.object,
  setupVendors: PropTypes.object,
  setupBudgets: PropTypes.object,
  setupBudgetLineCreate: PropTypes.func,
  setupBudgetLineUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  updateData: PropTypes.func
}

export default SetupBudgetsServicesModalCreate
