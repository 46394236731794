import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { close, closeFilled } from 'ui/icons'
import PermissionRuleFieldTemplate from '../../components/forms-helper/PermissionRuleFieldTemplate'
import GeneralField from '../../components/forms-helper/GeneralField'
import { getAdhocUnitSpaces } from '../../modules/damages'
import BasicLoading from '../../components/basic-loading'
import './index.scss'

const AddAdHocDamageModal = props => {
  const {
    onClose,
    damage_cost,
    row_id,
    getCustomResidentDataFromModal,
    getAdhocUnitSpaces,
    damage,
    unit_id,
    user,
    context,
    splits,
    isView,
    ca_split_type
  } = props || {}

  const [customResidents, setCustomResident] = useState([])

  const { adhocUnitSpaces } = damage || {}
  const { data: allResidentsData, isRequesting: allResidentsRequesting } =
    adhocUnitSpaces || {}

  const { pdbid } = user?.user_metadata

  const getUniqueResidents = data => {
    let uniqueResidents = [
      ...new Map(data?.map(item => [item['value'], item]))?.values()
    ]
      ?.map(item => ({
        name: item.resident,
        value: item.resident,
        amount: 0,
        space_id: item.value,
        inspection_damage_split_id: null,
        is_non_renewed: item?.is_non_renewed,
        space: item?.name
      }))
      ?.sort((a, b) =>
        a?.space?.localeCompare(b?.space, undefined, {
          sensitivity: 'base'
        })
      )
      ?.filter(
        res =>
          res?.value !== undefined &&
          res?.value !== null &&
          res?.value !== '' &&
          res?.value !== ' '
      )
    if (ca_split_type == '1' || ca_split_type == '2') {
      if (ca_split_type == '2') {
        uniqueResidents = uniqueResidents?.filter(
          resident => resident.is_non_renewed
        )
      }
      const totalAmount = damage_cost
      const isDecimal = totalAmount % 1 !== 0
      const amountPerResident = totalAmount / uniqueResidents?.length
      uniqueResidents.forEach(resident => {
        resident.amount = isDecimal
          ? amountPerResident.toFixed(2)
          : amountPerResident
      })
    }
    return uniqueResidents
  }

  useEffect(() => {
    if (allResidentsData?.length) {
      let customResidents = getUniqueResidents(allResidentsData)
      if (splits?.length && ca_split_type == '3') {
        customResidents = customResidents?.map(data => {
          splits.forEach(split => {
            if (data?.space_id === split?.space_id) {
              data.amount = split?.charge
              data.inspection_damage_split_id =
                split?.inspection_damage_split_id
            }
          })
          return data
        })
      }
      setCustomResident(customResidents || null)
    }
  }, [allResidentsData, splits])

  useEffect(() => {
    if (unit_id) {
      getAdhocUnitSpaces(pdbid, context, unit_id)
    }
  }, [unit_id])

  const hasDecimalPlace = (value, x) => {
    let pointIndex = (value || '').toString().indexOf('.')
    return pointIndex >= 0 && pointIndex < (value || '').length - x
  }

  const onHandleDamageChargeChange = ({ target }, i) => {
    const { value } = target || {}
    let filterValue = value || ''
    // Only allow Numbers and .
    filterValue = (value || '')
      ?.replace(/^0+(\d)/, '$1')
      ?.replace(/[^\d.]/g, '')
    // Don't allow . as first character
    if ((filterValue || '')[0] === '.') {
      filterValue = ''
    }
    // Check if . already exists
    if ((filterValue || '').toString().split('.').length > 2) {
      filterValue = (filterValue || '').replace(/\.+$/, '')
    }
    // Dont allow more than 2 decimal points
    if (hasDecimalPlace(filterValue, 3)) {
      return
    }
    setCustomResident(prevState => {
      return prevState?.map((item, index) => {
        if (index === i) {
          return { ...item, amount: filterValue }
        } else {
          return item
        }
      })
    })
  }

  const isButtonDisable = () => {
    return (
      !damage_cost ||
      damage_cost == 0 ||
      damage_cost === '' ||
      checkIfCostExceed ||
      checkIfCostIsLess
    )
  }

  const checkIfCostExceed = useMemo(() => {
    const totalResidentCharge = customResidents.reduce(
      (accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount)
      },
      0.0
    )
    return totalResidentCharge > parseFloat(damage_cost) ? true : false
  }, [customResidents, damage_cost])

  const checkIfCostIsLess = useMemo(() => {
    const totalResidentCharge = customResidents.reduce(
      (accumulator, currentValue) => {
        return parseFloat(accumulator) + parseFloat(currentValue.amount)
      },
      0.0
    )
    return totalResidentCharge < parseFloat(damage_cost) ? true : false
  }, [customResidents, damage_cost])

  const onHandleSaveResidents = () => {
    const splits = customResidents?.map(res => ({
      inspection_damage_split_id: res?.inspection_damage_split_id || null,
      space_id: res?.space_id,
      charge: res?.amount
    }))
    getCustomResidentDataFromModal(splits, row_id, ca_split_type)
    onClose()
  }

  const displayErrorMessage = (message = 'Error') => {
    return (
      <div className="notification is-danger is-light is-flex">
        <p>{message}</p>
      </div>
    )
  }

  const onHandleDisplayErrors = () => {
    if (!customResidents || customResidents.length === 0) {
      return displayErrorMessage('No Resident found to proceed.')
    }

    if (
      (!damage_cost || damage_cost == 0 || damage_cost === '') &&
      customResidents.length !== 0
    ) {
      return displayErrorMessage(
        'To proceed with custom cost type, please insert a damage cost first.'
      )
    }

    if (
      (checkIfCostExceed || checkIfCostIsLess) &&
      customResidents.length !== 0
    ) {
      return displayErrorMessage(
        checkIfCostExceed
          ? 'The sum of all residents damage costs cannot exceed the total damage cost.'
          : 'The sum of all residents damage costs cannot be less than the total damage cost.'
      )
    }
  }

  return (
    <div className="add-charges-modal">
      <div className="columns is-desktop is-mobile">
        <div className="column is-full">
          <p className="is-pulled-left modal-sync-title">Split Charges</p>
          <p
            className="close is-pointer has-text-grey-light"
            onClick={e => {
              e.preventDefault()
              getCustomResidentDataFromModal(splits, row_id, ca_split_type)
              onClose()
            }}>
            <img alt="Close Modal" src={close} />
          </p>
        </div>
      </div>
      <div className="is-full">
        <div className="sub-heading-container ">
          <strong>{`Damage Cost: ${damage_cost ||
            'There is no input for the damage cost.'}`}</strong>
        </div>
        {allResidentsRequesting ? (
          <BasicLoading />
        ) : (
          <form>
            <div className="main-form">
              {onHandleDisplayErrors()}
              {customResidents?.map((resident, i) => {
                return (
                  <div className="resident-charge" key={i}>
                    <div className="columns is-vcentered mb-0" key={i}>
                      <div className="column is-full  " key={i}>
                        <div className="user-permission-container">
                          <div className="columns is-desktop is-mobile mb-0">
                            <div className="column permission-label">
                              <strong>{`Space ${resident?.space}:`}</strong>
                            </div>
                          </div>
                          <PermissionRuleFieldTemplate>
                            <GeneralField fieldLabel="Resident">
                              <input
                                value={resident.value}
                                type="text"
                                className="input form-control"
                                disabled={true}
                                placeholder="Enter Resident"></input>
                            </GeneralField>
                          </PermissionRuleFieldTemplate>
                          <PermissionRuleFieldTemplate>
                            <GeneralField fieldLabel="Amount">
                              <input
                                id="amount"
                                name="amount"
                                disabled={isView}
                                value={resident.amount}
                                onBlur={() =>
                                  setCustomResident(prevState => {
                                    return prevState?.map((item, index) => {
                                      if (index === i) {
                                        return {
                                          ...item,
                                          amount:
                                            resident.amount === ''
                                              ? 0
                                              : resident.amount
                                        }
                                      } else {
                                        return item
                                      }
                                    })
                                  })
                                }
                                onChange={e => onHandleDamageChargeChange(e, i)}
                                type="text"
                                className="input form-control"
                                placeholder="Enter Amount"></input>
                            </GeneralField>
                          </PermissionRuleFieldTemplate>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="columns is-vcentered m-b-sm m-t-sm m-b-space">
              <div className="center-container">
                <button
                  className={`button main-button m-r-md ${
                    isView ? 'is-primary' : 'is-secondary'
                  }`}
                  onClick={e => {
                    e.preventDefault()
                    getCustomResidentDataFromModal(
                      splits,
                      row_id,
                      ca_split_type
                    )
                    onClose()
                  }}>
                  Cancel
                </button>
                {!isView ? (
                  <button
                    disabled={isButtonDisable()}
                    onClick={e => {
                      e.preventDefault()
                      onHandleSaveResidents()
                    }}
                    className="button main-button is-primary">
                    Save
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

AddAdHocDamageModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  context: PropTypes.object
}

const mapStateToProps = ({ damage, user, context }) => ({
  user,
  context,
  damage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAdhocUnitSpaces
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdHocDamageModal)
