import Service from '../../service'
import * as TYPES from '../invoices/types'

const purchaseOrdersAdapter = purchaseOrders => {
  return (purchaseOrders || []).map(po => {
    const amount_paid = po.amount_paid?.toString()?.trim()
    const tax_amount = po.tax_amount?.toString()?.trim()
    const total_amount = po.total_amount?.toString()?.trim()

    return {
      ...po,
      status: po.status === 'Pending AP Approval' ? 'Approved' : po.status,
      amount_paid: !isNaN(amount_paid)
        ? parseFloat(amount_paid).toFixed(2)
        : amount_paid,
      tax_amount: !isNaN(tax_amount)
        ? parseFloat(tax_amount).toFixed(2)
        : tax_amount,
      total_amount: !isNaN(total_amount)
        ? parseFloat(total_amount).toFixed(2)
        : total_amount
    }
  })
}

const purchaseOrderDetailAdapter = purchaseOrder => ({
  ...purchaseOrder,
  po_lines: (purchaseOrder.po_lines || []).map(po_line => ({
    ...po_line,
    rate: !isNaN(po_line.rate)
      ? parseFloat(po_line.rate).toFixed(2)
      : po_line.rate
  }))
})

export const getPurchaseOrders = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: TYPES.INVOICE_LIST_REQUESTED
    })
    Service.getPurchaseOrders(pdbid, context)
      .then(async res => {
        if (res.ok) {
          try {
            const data = await res.json()
            const list = purchaseOrdersAdapter(data)

            dispatch({
              type: TYPES.INVOICE_LIST_SUCCESS,
              list: list || []
            })
          } catch (err) {
            dispatch({
              type: TYPES.INVOICE_LIST_FAIL
            })
          }
        }
        return dispatch({
          type: TYPES.INVOICE_LIST_FAIL
        })
      })
      .catch(() => {
        dispatch({
          type: TYPES.INVOICE_LIST_FAIL
        })
      })
  }
}

export const getPurchaseDetails = (pdbid, context, invoiceId, vendorId) => {
  return async dispatch => {
    dispatch({ type: TYPES.INVOICE_DETAILS_REQUESTED })

    try {
      let [purchaseOrdersData, tasksData] = await Promise.all([
        Service.getPurchaseDetails(pdbid, context, invoiceId),
        Service.getPurchaseTasks(pdbid, context, vendorId)
      ])

      if (purchaseOrdersData.ok && tasksData.ok) {
        const purchaseOrdersRes = await purchaseOrdersData.json()
        const tasksDataRes = await tasksData.json()
        const purchaseOrderDetails = purchaseOrderDetailAdapter(
          purchaseOrdersRes[0]
        )

        dispatch({
          type: TYPES.INVOICE_DETAILS_SUCCESS,
          details: purchaseOrderDetails,
          tasks: tasksDataRes
        })
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: TYPES.INVOICE_DETAILS_FAIL })
    }
  }
}

export const resetPurchaseOrderDetail = () => {
  return async dispatch => {
    dispatch({ type: TYPES.INVOICE_DETAILS_RESET })
  }
}
