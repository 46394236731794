import React from 'react'
import ProgressItem from './progress-item'
import PropTypes from 'prop-types'


const ProgressSection = ({ progressItems, activeItem, submitted_at }) => {
  return (
    <>
      <h2 className="progress-heading">RFP Progress:</h2>
      <div className="progress-section">
        {
          (progressItems || []).map((item, index) => {
            return (
              <ProgressItem activeItem={ activeItem } item={ item } key = { index } submitted_at={ submitted_at } />
            )
          })
        }
      </div>
    </>
  )
}

ProgressSection.propTypes = {
  progressItems: PropTypes.array,
  submitted_at: PropTypes.string,
  activeItem: PropTypes.object
}

export default ProgressSection
