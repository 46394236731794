import sortBy from 'lodash/sortBy'

export const newForm = {
  form_id: null,
  template_id: null,
  template_name: null,
  inspection_id: null,
  name: '',
  type: '',
  spaces: [],
  created_by: null,
  created_at: null,
  status: null
}

export const formConfigSubmenu = [{ label: 'Elements' }, { label: 'Details' }]

const sortElement = sortKey => {
  return (current, next) => {
    if (
      (current[sortKey] || '').toLowerCase() <
      (next[sortKey] || '').toLowerCase()
    )
      return -1
    if (
      (current[sortKey] || '').toLowerCase() >
      (next[sortKey] || '').toLowerCase()
    )
      return 1
    return 0
  }
}

export const sortAndMapTemplateElements = (data, type) => {
  return (data || []).sort(sortElement(`${type}_name`)).map(element => ({
    [`${type}_name`]: element[`${type}_name`],
    [`${type}_id`]: element[`${type}_id`],
    status: element.status || 'active'
  }))
  // sortBy(
  //   data.map(item => ({
  //     [`${type}_name`]: item[`${type}_name`],
  //     [`${type}_id`]: item[`${type}_id`],
  //     status: item.status || 'active'
  //   })),
  //   [`${type}_name`]
  // )
}
