import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const NoteField = (props) => {
  const { line_note='', current_Parent_id, current_Child_id, onChangeNoteValue } = props || {}
  useEffect(() => {
    setNote(line_note)
  }, [line_note])
  
  const [note, setNote] = useState('')
  return <>
    <h3>Anything else we need to know?</h3>
      <textarea name="notes" value={note || ''} onChange={(e) => {
        setNote(e.target.value)
      }} onBlur={() => onChangeNoteValue(note, current_Parent_id, current_Child_id)}>
      </textarea>
  </>
}

NoteField.propTypes = {
  line_note: PropTypes.string,
  current_Parent_id: PropTypes.number,
  current_Child_id: PropTypes.string,
  onChangeNoteValue: PropTypes.func,
}

export default NoteField
