import _ from 'lodash'
import moment from 'moment'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import SyncModal from './sync-modal'
import Breadcrumbs from '../../parents/breadcrumbs'
import ServicesSummary from '../../components/services-summary'
import InspectCard from '../../components/inspect-card'
import FilterModal from '../../components/data-table/filter-modal'
import SortModal from '../../components/data-table/sort-modal'
import TableTop from '../../components/data-table/table-top'
import PrintReportModal from './print-report-modal'
import { customFilters, getHeaderData } from './header'
import { icons } from './icons'
import './index.scss'
import PermissionGate from '../../components/permission-gate'

export const PRINT_OPTIONS = {
  FILTERS: 0,
  SPECIFIED: 1,
  ALL: 2
}

const formatDate = date =>
  moment(date).isValid()
    ? moment(date).format('MM/DD/YYYY [at] hh:mm A')
    : 'N/A'

const buildServicesMap = (services, ignoreCPClean) => {
  const map = {}
  _.forEach(services, service => {
    if (!ignoreCPClean || service.servicetype !== 'Carpet Clean') {
      map[service.servicetype] = service.label
    }
  })
  return map
}

const buildOptionsMap = services => {
  const map = []
  _.forEach(services, service => {
    _.forEach(service.options, option => {
      map.push({
        service: service.servicetype,
        key: option.item_option,
        label: option.item_option_short
      })
    })
  })
  return map
}

const InspectMain = ({
  modal,
  context,
  user,
  responsive,
  inspect,
  inspectActivity,
  getInspectActivityLog,
  onPrintReport,
  onSync,
  onRefresh,
  location,
  setModalContent,
  openModal,
  closeModal
}) => {
  const [textFilter, setTextFilter] = useState('')
  const [availableServices, setAvailableServices] = useState([])
  const [availableOptions, setAvailableOptions] = useState([])
  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [sortModalOpen, setSortModalOpen] = useState(false)
  const [printReportModalOpen, setPrintReportModalOpen] = useState(false)
  const [pendingRefresh, setPendingRefresh] = useState(false)
  const [activeSort, setActiveSort] = useState([])
  const [activeFilters, setActiveFilters] = useState({})

  const { properties } = user.user_metadata
  const property = properties.find(p => p.id === context.property)

  const labelMap = useMemo(
    () =>
      buildServicesMap(
        inspect.services,
        property &&
          property.cr_label !== undefined &&
          property.cr_label !== null
      ),
    [inspect.services, context]
  )

  useEffect(() => {
    if (!inspect.listIsRequesting && inspect.listHasRequested) {
      setAvailableServices(
        _.map(
          _.filter(
            inspect.services,
            s =>
              (property &&
                property.cr_label !== undefined &&
                property.cr_label !== null) ||
              s.servicetype
          ),
          service => ({
            key: service.servicetype,
            caption: service.label
          })
        )
      )
    }
  }, [inspect, activeFilters])

  const updateOptions = services => {
    if (services && services.length) {
      setAvailableOptions(
        _.sortBy(
          _.uniqBy(
            _.flatten(
              _.reduce(
                _.filter(
                  inspect.services,
                  service => services.indexOf(service.servicetype) >= 0
                ),
                (acc, service) =>
                  acc.concat(
                    _.map(service.options, o => ({
                      key: `${service.servicetype}::${o.item_option}`,
                      caption: `${service.label}: ${o.item_option}`
                    }))
                  ),
                []
              )
            ),
            o => o.key
          ),
          o => o.caption
        )
      )
    } else {
      setAvailableOptions([])
    }
  }

  useEffect(() => {
    updateOptions(activeFilters.services)
  }, [activeFilters])

  const optionsMap = useMemo(() => buildOptionsMap(inspect.services), [
    inspect.services
  ])

  const exportCSV = () => {
    const { projects } = user.user_metadata
    const project = projects.find(p => p.id === context.project)
    const names = {
      property: property.name.replace(/ /g, '_'),
      project: project.name.replace(/ /g, '_')
    }
    const datetime = moment().format('MM_DD_YYYY_h_mm_ss')
    // const { list } = inspect

    const data = _.flatten(
      _.map(filteredUnits, unit =>
        _.map(unit.unit_spaces, us =>
          _.omit({ name: `${unit.unit} | ${us.unitspace}`, ...us }, [
            'unitspace',
            'uri'
          ])
        )
      )
    )
    const keys = Object.keys(data[0])
    const csvData = [keys]
      .concat(data.map(d => Object.values(d)))
      .map(e => e.join(','))
      .join('\n')
    const csvContent = 'data:text/csv;charset=utf-8,' + csvData
    const encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute(
      'download',
      `${names.project}-${names.property}-inspect-${datetime}.csv`
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const reText = new RegExp(textFilter, 'i')
  const initialFilteredUnits = _.filter(
    inspect.list,
    u =>
      (reText.test(u.unit) ||
        _.some(u.unit_spaces, us => reText.test(us.unitspace))) &&
      (!activeFilters.services ||
        _.some(
          u.unit_spaces,
          us => activeFilters.services.filter(service => us[service]).length
        )) &&
      (!activeFilters.is_transfer || u.is_transfer) &&
      (!activeFilters.is_asis || u.is_asis) &&
      (!activeFilters.is_full_asis || u.is_full_asis) &&
      (!activeFilters.is_carpet || u.is_carpet) &&
      (!activeFilters.partially_occupied || u.partially_occupied) &&
      (!activeFilters.fully_vacant || u.fully_vacant) &&
      (!activeFilters.unit || activeFilters.unit.includes(u.unit))
  )

  let filteredUnits = []
  if (!activeFilters.options) {
    filteredUnits = [...initialFilteredUnits]
  } else {
    _.forEach(initialFilteredUnits, unit => {
      _.forEach(activeFilters.options, option => {
        const parts = option.split('::')
        if (_.some(unit.unit_spaces, us => us[parts[0]] === parts[1])) {
          filteredUnits.push({ ...unit })
        }
      })
    })
  }

  if (activeSort && activeSort.length) {
    const directions = _.map(activeSort, sort =>
      sort[0] === '-' ? 'desc' : 'asc'
    )
    filteredUnits = _.orderBy(
      filteredUnits,
      _.map(activeSort, sort => sort.replace(/^[^a-zA-Z]+/, '')),
      directions
    )
  }

  const getOptions = key => {
    const column = _.find(header, col => col.key === key)
    if (column && column.getValues) {
      return column.getValues(inspect.list)
    }
    return _.orderBy(
      _.uniq(_.filter(_.map(inspect.list, d => d[key]), d => d !== ''))
    )
  }

  const onFilterRemove = ({ key, value } = {}) => {
    const newFilters = { ...activeFilters }
    if (newFilters[key] && Array.isArray(newFilters[key])) {
      newFilters[key] = _.without(
        newFilters[key],
        value.indexOf(': ') > 1 ? value.split(': ')[1] : value
      )
      if (!newFilters[key].length) {
        delete newFilters[key]
      }
    } else {
      delete newFilters[key]
    }
    setActiveFilters(newFilters)
  }

  const onSyncHandler = useCallback(
    inspectionSource => {
      setModalContent(() => (
        <SyncModal
          inspectionSource={inspectionSource}
          onSave={() => {
            setPendingRefresh(true)
            onSync()
            closeModal()
          }}
          onClose={() => {
            closeModal()
          }}
        />
      ))
      openModal({ width: '480px', maxWidth: '94%' })
    },
    [setModalContent, openModal, user]
  )

  const getTagCaption = tag => {
    if (tag.indexOf('::') >= 0) {
      const parts = tag.split('::')
      const service = _.find(inspect.services, s => s.servicetype === parts[0])
      const option = _.find(
        _.get(service, 'options', []),
        o => o.item_option === parts[1]
      )
      return `${_.get(service, 'label', parts[0])}: ${_.get(
        option,
        'item_option_short',
        parts[1]
      )}`
    }
    return tag
  }

  const printReport = (option, specified, activityLog) => {
    let units = {}
    switch (option) {
      case PRINT_OPTIONS.ALL:
        units = _.map(inspect.list, item => item.unit_id)
        break
      case PRINT_OPTIONS.SPECIFIED:
        const ids = _.map(specified.split(','), item => item.trim())
        units = _.map(
          _.filter(inspect.list, item => ids.indexOf(item.unit) >= 0),
          item => item.unit_id
        )
        break
      case PRINT_OPTIONS.FILTERS:
        units = _.map(filteredUnits, item => item.unit_id)
      default:
        break
    }
    const body = {
      units,
      activityLog
    }
    onPrintReport(body)
  }

  const header = getHeaderData(availableServices, availableOptions)
  const fieldFilters = _.map(_.filter(header, h => h.filterable), h => ({
    ...h,
    options: getOptions(h.key)
  }))

  return (
    <div className="container animated fadeIn services-main">
      <div className="section">
        <div className="columns is-marginless">
          <div className="column is-one-half is-paddingless">
            <Breadcrumbs location={location} />
          </div>
        </div>
        <ServicesSummary
          isLoading={inspect.statsIsRequesting || !inspect.statsHasRequested}
          stats={inspect.stats}
          responsive={responsive}
          replaceLabels={
            property &&
            property.cr_label !== undefined &&
            property.cr_label !== null
          }
        />
        <div className="service-card m-b-lg">
          <TableTop
            noBorders
            isLoading={inspect.listIsRequesting || !inspect.listHasRequested}
            isUpdating={inspect.syncIsRequesting || !inspect.syncHasRequested}
            showCustomFilterOption={true}
            title="Inspections"
            header={header}
            allowFilter
            allowSort
            buttonsSection={
              <PermissionGate name="update-sync-inspect">
                {inspect.status !== 'inprogress' &&
                  !inspect.syncIsRequesting &&
                  pendingRefresh && (
                    <span className="refresh-caveat small is-hidden-tablet">
                      psst…Your page is out of sync. Click “Refresh Page” to get
                      newest data.
                    </span>
                  )}
                <div
                  className="columns is-vcentered is-mobile m-b-none"
                  style={{ position: 'relative' }}>
                  {inspect.status !== 'inprogress' &&
                    !inspect.syncIsRequesting &&
                    pendingRefresh && (
                      <span className="refresh-caveat is-hidden-mobile">
                        psst…Your page is out of sync. Click “Refresh Page” to
                        get newest data.
                      </span>
                    )}
                  <div className="column is-narrow p-r-xs is-half-mobile">
                    <button
                      className="button is-primary main-button"
                      disabled={
                        inspect.syncIsRequesting ||
                        inspect.status === 'inprogress'
                      }
                      type="button"
                      onClick={() => onSyncHandler(inspect.inspectionSource)}>
                      {inspect.syncIsRequesting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        <span>
                          {inspect.status === 'inprogress'
                            ? 'Fetching Data...'
                            : 'Sync'}
                        </span>
                      )}
                    </button>
                  </div>
                  <div className="column is-narrow is-half-mobile">
                    <button
                      className="button is-secondary m-r-none main-button"
                      onClick={() => {
                        setPendingRefresh(false)
                        onRefresh()
                      }}
                      disabled={
                        inspect.listIsRequesting || inspect.statsIsRequesting
                      }>
                      {inspect.listIsRequesting || inspect.statsIsRequesting ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        <span>Refresh Page</span>
                      )}
                    </button>
                  </div>
                </div>
              </PermissionGate>
            }
            additionalOptions={
              <>
                {/* <button
                  className="button tool-button"
                  type="button"
                  disabled={inspect.reportIsRequesting}
                  onClick={() => setPrintReportModalOpen(true)}>
                  <img
                    src={icons.print}
                    alt="Print Report"
                    className="m-r-xs"
                  />
                  Print Report
                </button> */}
                <button
                  className="button tool-button"
                  type="button"
                  onClick={() => {}}>
                  <img
                    src={icons.importFile}
                    alt="Import CSV"
                    className="m-r-xs"
                  />
                  Import CSV
                </button>
              </>
            }
            textFilter={textFilter}
            onSetTextFilter={setTextFilter}
            activeFilters={activeFilters}
            customFilters={customFilters}
            onSetFilters={setActiveFilters}
            onFilterRemove={onFilterRemove}
            onToggleFilterModal={setFilterModalOpen}
            onToggleSortModal={setSortModalOpen}
            onExport={exportCSV}
            getTagCaption={getTagCaption}
            additionalInfo={
              inspect.inspectionSource !== '' ? (
                <div
                  className="columns m-t-none m-b-none"
                  style={{ alignItems: 'center' }}>
                  <div className="column is-narrow-tablet p-t-none p-b-none">
                    Inspection Source: {inspect.inspectionSource}
                    <div className="is-hidden-tablet">
                      Last Sync: {formatDate(inspect.lastSync)}
                      {inspect.status !== 'inprogress' && (
                        <span
                          className={`sync-tooltip ${
                            inspect.status === 'error' ? 'break-text' : ''
                          }`}
                          data-custom-tooltip={
                            inspect.status === 'complete'
                              ? `Your last sync to ${inspect.inspectionSource} was successful!`
                              : `Oops!${'\n\n'}We weren’t able to sync the data from ${
                                  inspect.inspectionSource
                                } on last sync.${'\n\n'}Review the following error message:${
                                  inspect.errorMessage
                                    ? '\n\n' + inspect.errorMessage
                                    : ''
                                }`
                          }>
                          {inspect.status === 'complete' && (
                            <img src={icons.check} alt="Verified" />
                          )}
                          {inspect.status === 'error' && (
                            <img
                              src={icons.alert}
                              alt="Error"
                              style={{ width: 17, marginTop: 5, marginLeft: 5 }}
                            />
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="column divider is-narrow is-hidden-mobile" />
                  <div
                    className="column is-hidden-mobile p-t-none p-b-none"
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      minWidth: 262
                    }}>
                    Last Sync: {formatDate(inspect.lastSync)}
                    {inspect.status !== 'inprogress' && (
                      <span
                        className={`sync-tooltip ${
                          inspect.status === 'error' ? 'break-text' : ''
                        }`}
                        data-custom-tooltip={
                          inspect.status === 'complete'
                            ? `Your last sync to ${inspect.inspectionSource} was successful!`
                            : `Oops!${'\n\n'}We weren’t able to sync the data from ${
                                inspect.inspectionSource
                              } on last sync.${'\n\n'}Review the following error message:${
                                inspect.errorMessage
                                  ? '\n\n' + inspect.errorMessage
                                  : ''
                              }`
                        }>
                        {inspect.status === 'complete' && (
                          <img src={icons.check} alt="Verified" />
                        )}
                        {inspect.status === 'error' && (
                          <img
                            src={icons.alert}
                            alt="Error"
                            style={{ width: 17, marginTop: 5, marginLeft: 5 }}
                          />
                        )}
                      </span>
                    )}
                  </div>
                </div>
              ) : null
            }
          />
        </div>
        <div className="columns">
          <div className="column" style={{ maxWidth: '100%' }}>
            {_.map(filteredUnits, unit => (
              <InspectCard
                context={context}
                user={user}
                modal={modal}
                key={unit.unit_id}
                responsive={responsive}
                unit={unit}
                labelMap={labelMap}
                services={inspect.services.map(service => ({
                  ...service,
                  key: service.servicetype
                }))}
                optionsMap={optionsMap}
                inspectActivity={inspectActivity}
                getInspectActivityLog={getInspectActivityLog}
                setModalContent={setModalContent}
                openModal={openModal}
                closeModal={closeModal}
                rowOptions={[
                  {
                    available: unit => !unit.has_note,
                    icon: icons.noteAdd,
                    caption: 'Add Note'
                  },
                  {
                    available: row => row.has_note,
                    icon: icons.note,
                    caption: 'Edit Notes'
                  }
                ]}
              />
            ))}
          </div>
        </div>
      </div>
      {filterModalOpen && (
        <FilterModal
          open={filterModalOpen}
          startFilter={activeFilters}
          customFilters={customFilters}
          fieldFilters={fieldFilters}
          onChange={filter => updateOptions(filter.services)}
          onFilter={filter => setActiveFilters(filter)}
          onClose={() => setFilterModalOpen(false)}
          getTagCaption={getTagCaption}
        />
      )}
      <SortModal
        open={sortModalOpen}
        startSort={activeSort}
        allColumns={_.map(_.filter(header, h => !h.notSortable), column => ({
          key: column.key,
          caption: column.sortDisplay || column.display
        }))}
        onSort={values => {
          setActiveSort(values)
          setSortModalOpen(false)
        }}
        onClose={() => setSortModalOpen(false)}
      />
      <PrintReportModal
        open={printReportModalOpen}
        onClose={() => setPrintReportModalOpen(false)}
        onReport={(option, specified, activityLog) =>
          printReport(option, specified, activityLog)
        }
        activeFilters={activeFilters}
        customFilters={customFilters}
        fieldFilters={fieldFilters}
        getTagCaption={getTagCaption}
      />
    </div>
  )
}

InspectMain.propTypes = {
  modal: PropTypes.object,
  context: PropTypes.object,
  user: PropTypes.object,
  responsive: PropTypes.object,
  inspect: PropTypes.object,
  inspectActivity: PropTypes.object,
  getInspectActivityLog: PropTypes.func,
  location: PropTypes.object,
  setModalContent: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func
}

export default InspectMain
