import pencil from './icons/edit@2x.svg'
import trash from './icons/trash@2x.svg'
import close from './icons/close.svg'
import exportFile from './icons/export.svg'
import addButton from './icons/addButton.svg'
import closeFilled from './icons/close-filled.svg'
import caretUpOn from './icons/caret-up-full.svg'
import caretUpOff from './icons/caret-up-empty.svg'
import caretDownOn from './icons/caret-down-full.svg'
import caretDownOff from './icons/caret-down-empty.svg'

export const icons = {
  close,
  pencil,
  trash,
  exportFile,
  addButton,
  closeFilled,
  caretUpOn,
  caretUpOff,
  caretDownOn,
  caretDownOff
}
