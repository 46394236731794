import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { updateUser, updateUserAllowedSection } from '../../modules/user'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useRouteMatch, withRouter } from 'react-router-dom'
import { isMobile } from '../../utils/helperFunctions'
import useAppRoutes from '../../containers/app/hooks/useAppRoutes'

const ContextSelector = ({
  user,
  openModal,
  setModalContent,
  context,
  contextCookie,
  cancelContextChange,
  location,
  updateContext,
  closeModal,
  history,
  onlyProperties = false,
  showTenantOnly = false,
  updateUser,
  tenantCookie,
  updateUserAllowedSection
}) => {
  const { getPathAndCheckPrevPath } = useAppRoutes()
  const { path: currentPath } = useRouteMatch()
  const [selected, setSelected] = useState({
    project: onlyProperties ? 'empty' : 'select-one',
    property: 'select-one',
    tenant: 'Choose Tenant'
  })

  useEffect(() => {
    setModalContent(() => getModalTemplate())
    openModal({ width: '450px', maxWidth: '94%' })
  }, [user])

  useEffect(() => {
    let newSelected = { ...selected }
    if (context.project !== null || context.property !== null) {
      newSelected = {
        ...newSelected,
        project: context.project !== null ? context.project : 'select-one',
        property: context.property !== null ? context.property : 'select-one'
      }
    } else if (typeof contextCookie === 'string') {
      const split = contextCookie.split('|')
      newSelected = {
        ...newSelected,
        project: split[0] !== 'null' ? split[0] : 'select-one',
        property: split[1] !== 'null' ? split[1] : 'select-one'
      }
    } else if (typeof tenantCookie === 'string') {
      newSelected = {
        ...newSelected,
        tenant: tenantCookie
      }
    }
    setSelected(newSelected)
    setModalContent(() => getModalTemplate())
    openModal({ width: '450px', maxWidth: '94%' })
  }, [context])

  useEffect(() => {
    setModalContent(() => getModalTemplate())
  }, [selected])

  const getTenantsOptions = () => {
    const { user_metadata } = user || {}
    const { pdbids } = user_metadata || {}

    return (pdbids || []).map((id, index) => {
      return (
        <option key={index} className="is-capitalized" value={id}>
          {_.startCase(id.replace('_', ' '))}
        </option>
      )
    })
  }

  const handleChange = event => {
    const { target } = event || {}
    const { value, name } = target || {}
    setSelected({
      ...selected,
      [name]: value
    })
  }

  const getModalTemplate = () => {
    const propertyIsDisabled = onlyProperties
      ? false
      : selected.project === 'select-one'
    const hasContext = context.project !== null && context.property !== null
    const { pathname } = location
    return (
      <div>
        <div className="columns">
          <div className="column">
            <p
              className={`is-pulled-right is-unselectable is-pointer has-text-grey-light ${
                hasContext || tenantCookie || pathname === '/'
                  ? ''
                  : 'is-hidden'
              }`}
              onClick={() => {
                cancelContextChange()
                closeModal()
              }}>
              X
            </p>
            <p className="is-size-4">What are you working on today?</p>
          </div>
        </div>
        {showTenantOnly || tenantCookie ? (
          <div className="columns">
            <div className="column is-full">
              <label htmlFor="project" className="heading-4">
                Tenants
              </label>
              <div className="select is-fullwidth heading-4">
                {/* <select
                    className="is-fullwidth input" name="tenant"  onChange = {  handleChange }>
                    <option value="">Choose Tenant</option>
                    {
                      getTenantsOptions()
                    }
                  </select> */}
                {getTenantSelector('tenant')}
              </div>
            </div>
          </div>
        ) : (
          <>
            {!onlyProperties && (
              <div className="columns">
                <div className="column is-full">
                  <label htmlFor="project" className="heading-4">
                    Project
                  </label>
                  {getSelector('projects', e => userSelected('project', e))}
                </div>
              </div>
            )}
            <div className="columns">
              <div className="column is-full">
                <label htmlFor="property" className="heading-4">
                  Property
                </label>
                {getSelector(
                  'properties',
                  e => userSelected('property', e),
                  propertyIsDisabled
                )}
              </div>
            </div>
          </>
        )}
        <div className="columns">
          <div
            className={`column is-one-quarter is-offset-one-quarter has-text-right ${
              hasContext || tenantCookie || pathname === '/' ? '' : 'is-hidden'
            }`}>
            <button
              className={`button is-secondary is-fullwidth ${
                hasContext || tenantCookie || pathname === '/'
                  ? ''
                  : 'is-hidden'
              }`}
              onClick={() => {
                cancelContextChange()
                closeModal()
              }}>
              Cancel
            </button>
          </div>
          <div
            className={`column ${
              hasContext || tenantCookie || pathname === '/'
                ? 'is-one-quarter'
                : ''
            }`}>
            {showTenantOnly || tenantCookie ? (
              <button
                className="button is-primary is-fullwidth"
                disabled={
                  selected.tenant === 'Choose Tenant' || selected.tenant === ''
                    ? 'disabled'
                    : ''
                }
                onClick={() => saveTenant()}>
                Let's Go
              </button>
            ) : (
              <button
                className="button is-primary is-fullwidth"
                disabled={
                  selected.project === 'select-one' ||
                  selected.property === 'select-one'
                    ? 'disabled'
                    : ''
                }
                onClick={() => buttonClicked()}>
                Let's Go
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }

  const buttonClicked = async () => {
    const { pathname } = location

    if (
      selected.project === 'select-one' ||
      selected.property === 'select-one'
    ) {
      return
    }

    const { user_metadata } = user || {}
    const getProperty = (user_metadata?.properties || []).filter(
      d =>
        d?.id === selected?.property && d?.project[0].id === selected?.project
    )

    if ((getProperty || []).length) {
      const currentRole = [getProperty[0]?.role?.name]
      const roleName = currentRole?.[0]?.toLowerCase()
      const allowedSections = getProperty?.[0]?.allowedSections
      const { path, prevPathExists } = getPathAndCheckPrevPath(
        allowedSections,
        currentPath
      )

      updateUserAllowedSection(
        selected?.project,
        selected?.property,
        user,
        currentRole
      )
      // Important: we need to re-configure redux store for Vendor and QC routes. Reload whole page instead SPA routing
      if (roleName === 'vendor') {
        history.push('/vendor')
      } else if (isMobile() || roleName === 'staff') {
        history.push('/qc')
      } else {
        if (pathname === '/' || !prevPathExists) {
          history.push(path)
        }
      }
      await updateContext(
        onlyProperties ? 'empty' : selected.project,
        selected.property
      )
      await closeModal()
    }
  }

  const saveTenant = async () => {
    const { pathname } = location
    const { tenant: pdbid } = selected || {}
    await updateUser({
      pdbid,
      onSuccess: () => {
        console.log('done')
      }
    })
    if (pathname !== '/') {
      history.push('/')
    }
    await cancelContextChange()
    await closeModal()
  }

  const userSelected = (key, e) => {
    setSelected({
      ...selected,
      [key]: e.target.value
    })
  }
  const getTenantSelector = (key = 'tenant') => {
    const { user_metadata } = user || {}
    const { pdbids } = user_metadata || {}

    let options = pdbids || []
    console.log(options)
    const singular = {
      tenant: 'tenant'
    }

    let template = []
    for (let i = 0; i < options.length; i += 1) {
      const o = options[i]
      template.push(
        <option
          key={`${key}-${i}`}
          value={o}
          className="heading-4 is-capitalized">
          {(o || '').toString().replace('_', ' ')}
        </option>
      )
    }
    let value = selected[singular[key]]
    if (value === null) value = 'Choose Tenant'
    return (
      <>
        <div className="select is-fullwidth heading-4">
          <select
            className="is-fullwidth input is-capitalized"
            name="tenant"
            value={value}
            onChange={handleChange}>
            <option value="Choose Tenant">Choose Tenant</option>
            {template}
          </select>
        </div>
      </>
    )
  }
  const getSelector = (
    key = 'projects',
    changed = () => {},
    isDisabled = false
  ) => {
    let options = user.user_metadata[key] || []
    const singular = {
      projects: 'project',
      properties: 'property'
    }

    if (key === 'properties') {
      const { projects, properties } = user.user_metadata

      if (onlyProperties) {
        options = properties
      } else {
        const selectedProject = (projects || []).find(
          p => p.id === selected.project
        )
        if (typeof selectedProject !== 'undefined') {
          options = (options || []).filter((o, optionIndex, self) => {
            const propertyInProject = (selectedProject.properties || []).find(
              p => p === o.id
            )

            return (
              typeof propertyInProject !== 'undefined' &&
              optionIndex === self.findIndex(option => option.id === o.id)
            )
          })
        }
      }
    }
    let template = []
    for (let i = 0; i < options.length; i += 1) {
      const o = options[i]
      template.push(
        <option key={`${key}-${i}`} value={o.id} className="heading-4">
          {o.name}
        </option>
      )
    }
    let value = selected[singular[key]]
    if (value === null) value = 'select-one'
    return (
      <div className="select is-fullwidth heading-4">
        <select
          className="is-fullwidth input"
          id="project"
          disabled={isDisabled ? 'disabled' : ''}
          onChange={changed}
          value={value}>
          <option value="select-one" className="heading-4">
            Select one...
          </option>
          {template}
        </select>
      </div>
    )
  }

  return null
}

ContextSelector.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  contextCookie: PropTypes.string,
  updateContext: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  setModalContent: PropTypes.func,
  cancelContextChange: PropTypes.func,
  /**
   * Just to show the dropdown option with tenant user attached to
   */
  showTenantOnly: PropTypes.bool,
  /**
   * To update the user pdbid base on the current selected tenant
   */
  updateUser: PropTypes.func
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUser,
      updateUserAllowedSection
    },
    dispatch
  )

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(ContextSelector)
)
