import React from 'react'

export const getTabs = (
  setActiveTab,
  {
    setModalContent,
    openModal,
    closeModal,
    UnsavedChangesModal,
    setModifiedData
  }
) => {
  const handleTabClick = tabInfo => {
    if (tabInfo.modifiedData) {
      setModalContent(() => (
        <UnsavedChangesModal
          onLeave={() => {
            closeModal()
            setModifiedData(false)
            setActiveTab(tabInfo)
          }}
          onStay={closeModal}
        />
      ))

      openModal({ width: '480px' })
      return
    }

    setActiveTab(tabInfo)
  }

  return [
    {
      singularTabName: 'Space',
      label: 'Spaces',
      id: 'spaces',
      onClick: tabInfo => handleTabClick(tabInfo),
      overrideActiveBackground: '#3DB3E2',
      active: true
    },
    {
      singularTabName: 'Item',
      label: 'Items',
      id: 'items',
      onClick: tabInfo => handleTabClick(tabInfo),
      overrideActiveBackground: '#3DB3E2',
      active: false
    },
    {
      singularTabName: 'Observation',
      label: 'Observations',
      id: 'observations',
      onClick: tabInfo => handleTabClick(tabInfo),
      overrideActiveBackground: '#3DB3E2',
      active: false
    },
    {
      singularTabName: 'Solution',
      label: 'Solutions',
      id: 'solutions',
      onClick: tabInfo => handleTabClick(tabInfo),
      overrideActiveBackground: '#3DB3E2',
      active: false
    }
  ]
}

export const stateOptions = [
  {
    label: 'Active',
    value: 'active',
    color: '#3DB3E2'
  },
  {
    label: 'Inactive',
    value: 'inactive',
    color: '#E0E66B'
  }
]
