import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './index.scss'

const Notification = ({ onDismiss, color, message }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(message ? true : false)
  }, [message])

  const handleClose = () => {
    setOpen(false)
    onDismiss && onDismiss()
  }

  if (open) {
    return (
      <div className={`global-notification notification is-${color || 'info'}`}>
        <button className="delete" onClick={() => handleClose()}></button>
        {message}
      </div>
    )
  }
  return null
}

Notification.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger'
  ]),
  message: PropTypes.string,
  onDismiss: PropTypes.func
}

export default Notification
