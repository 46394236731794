import Service from '../service'
import LOGOUT from './auth'
import _ from 'lodash'

/**
 * Request Vendors data module
 * @redux
 * @reduxActionScope request-vendors
 * @module request-vendors
 */

/**
 * Request all Vendor list
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_GET_REQUESTED =
  'request-vendor/REQUEST_ALL_VENDOR_GET_REQUESTED'
/**
 * All Vendor list request failed
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_GET_FAIL = 'request-vendor/REQUEST_ALL_VENDOR_GET_FAIL'
/**
 * Update requested Vendor list with successful data from API
 * @type {Redux.ActionType}
 */
export const REQUEST_ALL_VENDOR_GET_SUCCESS =
  'request-vendor/REQUEST_ALL_VENDOR_GET_SUCCESS'

/**
 * Check existing vendor
 * @type {Redux.ActionType}
 */
export const CHECK_VENDOR_EXISTS_REQUESTED =
  'request-vendor/CHECK_VENDOR_EXISTS_REQUESTED'
/**
 * Check existing vendor failed
 * @type {Redux.ActionType}
 */
export const CHECK_VENDOR_EXISTS_FAIL = 'request-vendor/CHECK_VENDOR_EXISTS_FAIL'
/**
 * Update Check existing vendor with successful data from API
 * @type {Redux.ActionType}
 */
export const CHECK_VENDOR_EXISTS_SUCCESS =
  'request-vendor/CHECK_VENDOR_EXISTS_SUCCESS'
/**
 * Update vendor list on successfull deletion of vendor
 * @type {Redux.ActionType}
 */  
export const DELETE_VENDOR_LIST = 'DELETE_VENDOR_LIST'
  

export const UPDATE_VENDOR_LIST = 'UPDATE_VENDOR_LIST'

const initialState = {
  allVendor: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  vendorExists: {
    name:{
      hasMadeInitialRequest: false,
      isRequesting: false,
      isError: false,
      exist: '0'
    },
    email:{
      hasMadeInitialRequest: false,
      isRequesting: false,
      isError: false,
      exist: '0'
    },
  }
}

/**
 * Request Vendor reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ALL_VENDOR_GET_REQUESTED:
      return {
        ...state,
        allVendor: {
          ...state.allVendor,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false
        }
      }
    case REQUEST_ALL_VENDOR_GET_SUCCESS:
      return {
        ...state,
        allVendor: {
          ...state.allVendor,
          isRequesting: false,
          isError: false,
          data: action.data
        }
      }
    case DELETE_VENDOR_LIST: 
      const { allVendor } = state || {}
      const { data: exitingVendors } = allVendor || {}
      const { data } = action || {}
      const { id } = data || {}

      const newVendors = (exitingVendors || []).filter((vendor) => vendor.vender_id !== id)
      return {
        ...state,
        allVendor: {
          ...allVendor,
          data: newVendors || []
        }
      }

    case UPDATE_VENDOR_LIST: {
      const { data: newVendor } = action || {}
      const { allVendor } = state || {}
      const { data: allVendors } = allVendor || {}
      return {
        ...state,
        allVendor: {
          ...state.allVendor,
          data: [newVendor, ...allVendors ],
        }
      }
    }  
    case REQUEST_ALL_VENDOR_GET_FAIL:
      return {
        ...state,
        allVendor: {
          ...state.allVendor,
          isRequesting: false,
          isError: true
        }
      }
    case CHECK_VENDOR_EXISTS_REQUESTED:
      return {
        ...state,
        vendorExists: {
          [action.data.name]:{
            hasMadeInitialRequest: true,
            isRequesting: true,
            isError: false
          }
        }
      }
    case CHECK_VENDOR_EXISTS_SUCCESS:
      return {
        ...state,
        vendorExists: {
          [action.data.name]:{
            isRequesting: false,
            isError: false,
            exist: action.data.exist
          }
        }
      }
    case CHECK_VENDOR_EXISTS_FAIL:
      return {
        ...state,
        vendorExists: {
          [action.data.name]:{
            hasMadeInitialRequest: true,
            isRequesting: true,
            isError: false,
            exist: '0',
          }
          
        }
      }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

/**
 * Gets all Vendor members
 * @redux
 * @reduxActionCreator REQUEST_ALL_VENDOR_GET_REQUESTED, REQUEST_ALL_VENDOR_GET_SUCCESS, REQUEST_ALL_VENDOR_GET_FAIL
 * @param {string} pdbid - the pdbid for the request
 * @param {Object} context - the context object for the request
 */
export const allRequestVendorGet = (pdbid, context) => {
  return dispatch => {
    dispatch({
      type: REQUEST_ALL_VENDOR_GET_REQUESTED
    })
    Service.allRequestVendorGet(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          dispatch({
            type: REQUEST_ALL_VENDOR_GET_SUCCESS,
            data: _allRequestVendorAdapter(data)
          })
        } else {
          _allRequestVendorFail(dispatch)
        }
      })
      .catch(() => {
        _allRequestVendorFail(dispatch)
      })
  }
}

export const addVendor = ({ pdbid, context, body, onSuccess, onError }) => {
  return dispatch => {
    Service.addVendor(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          let updatedData = data || {}

          if('services' in updatedData) {
            const {services} = updatedData || {}
            updatedData['simplifiedServices'] =  getSimplifiedServices(services)
          } if('status' in updatedData) {
            const {status} = updatedData || {}
            updatedData.status= upatedStatusText(status)
          }
          dispatch({
            type: UPDATE_VENDOR_LIST,
            data: updatedData
          })
          if(onSuccess) {
            onSuccess()
          }
        } else {
          console.log({
            res
          })
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }
}

export const checkVendorRequest = ({pdbid, context, id, onSuccess, onError}) => {
  return dispatch => {
    Service.requestsForProposalGet(pdbid, context, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          const data = await res.json()
          if(onSuccess) {
            onSuccess(data)
          }
        } else {
          if(onError) {
            onError(res)
          }
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }
}

export const deleteVendor = ({ pdbid, context, body, id, onSuccess, onError}) => {
  return dispatch => {
    Service.deleteVendor(pdbid, context, body, id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          dispatch({
            type: DELETE_VENDOR_LIST,
            data: {
              id
            }
          })
          if(onSuccess) {
            onSuccess()
          }
        } else {
          if(onError) {
            onError(res)
          }
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }
}

export const checkVendorExists = ({pdbid, context, body, name, onSuccess, onError}) => {
  return dispatch => {
    dispatch({
      type: CHECK_VENDOR_EXISTS_REQUESTED,
      data: {
        name
      }
    })
    Service.checkVendorExists(pdbid, context, body)
      .then(async res => {
        if (res.ok) {
          const data = await res.json()
          if(onSuccess) {
            onSuccess(data)
          }
          return
        } 

        if(onError) {
          onError('Something went wrong.')
        }
      })
      .catch((error) => {
        if(onError) {
          onError(error)
        }
      })
  }
}

const _allRequestVendorFail = dispatch => {
  dispatch({
    type: REQUEST_ALL_VENDOR_GET_FAIL
  })
}

const upatedStatusText = (status) => {
  if(status !== '' && status === 'existing-vendor') {
    return 'Vendor'
  }
  return 'Prospect'
}

const getSimplifiedServices = (values) => {
  const simplifiedValue = (values || []).flatMap((service,i) => {
    let newValue=[]
    const { name } = service
    newValue.push(name)
    return newValue
  })
  return (simplifiedValue || []).join(', ')
}

const NS = ''
const _requestVendorAdapter = data => {
  return {
    vender_id: typeof data.vender_id === 'string' ? data.vender_id : NS,
    name: typeof data.name === 'string' ? data.name : NS,
    contact_name: typeof data.contact_name === 'string' ? data.contact_name : NS,
    email: typeof data.email === 'string' ? data.email : NS,
    status: typeof data.status === 'string' ? upatedStatusText(data.status.toLowerCase()) : 'Prospect',
    services: typeof data.services === 'object' ? data.services : [],
    simplifiedServices: typeof data.services === 'object' ? getSimplifiedServices(data.services) : [],
  }
}

const _allRequestVendorAdapter = data => {
  const arrayUniqueByKey = [...new Map(data.map(item =>
    [item['vender_id'], item])).values()]
  return arrayUniqueByKey.map(d => _requestVendorAdapter(d))
}
