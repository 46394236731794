import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Short from 'short-uuid'

/**
 * Component for showing pairs of display labels and values
 * with optional icons showing at the right
 *
 * @component
 * @example
 * return (
 *   <KeyValueDisplay
 *      data: '1000',
 *      display: 'Test value'
 *      icons: {
 *        <button type="button" onClick={alert('hello')} />
 *      }
 *   />
 * )
 */
const KeyValueDisplay = ({
  data,
  display,
  customDisplays = {},
  icons = null,
  ignore = []
}) => {
  const id = useMemo(() => Short.uuid(), [])
  let template = []
  for (let key in data) {
    if (!display[key] || ignore.indexOf(key) >= 0) continue
    let value = data[key]
    if (typeof customDisplays[key] === 'function') {
      value = customDisplays[key](value, key, id)
    }
    const hook = {
      key,
      value,
      display: display[key]
    }
    template.push(
      <div
        className="columns is-desktop is-mobile m-b-none"
        key={`vendor-manage-${key}`}>
        <div className="column is-one-third p-b-sm">
          <label forhtml={key}>{display[key]}:</label>
        </div>
        <div className="column has-text-left p-b-sm">{value}</div>
        {typeof icons === 'function' ? icons(hook) : icons}
      </div>
    )
  }
  return template
}

KeyValueDisplay.propTypes = {
  /**
   * Data value to show in the middle
   */
  data: PropTypes.object,
  /**
   * Label text to show on the left
   */
  display: PropTypes.object,
  ignore: PropTypes.array,
  /**
   * Optional icons element of generating function to show on the right
   */
  icons: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}

export default KeyValueDisplay
