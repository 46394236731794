import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getElements,
  updateTemplateElements,
  deleteTemplateElement,
  getAllUnitSpace,
  createElementSpace
} from '../../modules/company-templates'
import { setModalContent, openModal, closeModal } from '../../modules/modal'
import ElementsMain from '../../parents/elements-main'
import BigLoading from '../../components/big-loading'

const Elements = props => {
  const { user } = props
  if (user.hasMadeInitialRequest && !user.isRequesting) {
    return <ElementsMain {...props} />
  }

  return <BigLoading />
}

Elements.propTypes = {
  user: PropTypes.object,
  context: PropTypes.object,
  companyTemplates: PropTypes.object
}

const mapStateToProps = ({ user, context, companyTemplates, modal }) => ({
  user,
  context,
  companyTemplates,
  modal
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getElements,
      updateTemplateElements,
      deleteTemplateElement,
      openModal,
      closeModal,
      setModalContent,
      getAllUnitSpace,
      createElementSpace
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Elements)
)
