import _ from 'lodash'
import React, { useState, useEffect, memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  create,
  color,
  useTheme,
  percent,
  ColorSet,
  registry
} from '@amcharts/amcharts4/core'
import {
  RadarChart,
  CategoryAxis,
  ValueAxis,
  RadarColumnSeries,
  RadarCursor
} from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import Short from 'short-uuid'
import ComponentWithStatus from '../component-with-status'

const COLORS = ['#122048', '#0081c3', '#8bd3ed', '#55c2b8', '#8dc85c']
const BACKGROUND_COLOR = '#e5e9f2'

const getStatsRow = (data, highlighted) => (
  <div className="columns" key={Short.uuid()}>
    <div
      className={`column ${
        highlighted ? 'has-text-weight-bold' : ''
      } is-narrow clustered`}>
      {_.get(data, 'label')}:
    </div>
    <div
      className={`column ${
        highlighted ? 'has-text-weight-bold' : ''
      } has-text-right clustered`}>
      <a className="no-decoration" href={data.href || '#!'}>
        {_.get(data, 'value')}
      </a>
    </div>
  </div>
)

/**
 * Service summary gauge chart
 *
 * @component
 * @example
 * const data = [
 *   {
 *     "id": "Assign",
 *     "label": "Not Assigned",
 *     "value": 18,
 *     "href": "/services?s=Walk&p=Assign"
 *   },
 *   {
 *     "id": "Start",
 *     "label": "Assigned",
 *     "value": 5,
 *     "href": "/services?s=Walk&p=Start"
 *   },
 *   {
 *     "id": "InProgress",
 *     "label": "In Progress",
 *     "value": 1,
 *     "href": "/services?s=Walk&p=InProgress"
 *   },
 *   {
 *     "id": "AssignQC",
 *     "label": "Complete",
 *     "value": 0,
 *     "href": "/services?s=Walk&p=AssignQC"
 *   },
 *   {
 *     "id": "Approved",
 *     "label": "Approved",
 *     "value": 1,
 *     "href": "/services?s=Walk&p=Approved"
 *   }
 * ]
 * return (
 *   <GaugeChart
 *     loading={false}
 *     error={false}
 *     data={data}
 *     title="Walk"
 *   />
 * )
 */
const GaugeChart = ({ loading, error, title, data }) => {
  const [ref, setRef] = useState(null)
  const uuid = useMemo(() => Short.uuid(), [])

  const total = _.reduce(
    Object.values(data),
    (acc, current) => acc + _.get(current, 'value', 0),
    0
  )

  const findChart = () =>
    registry.baseSprites.find(
      chartObj => chartObj.htmlContainer.id === `gauge-chart-${uuid}`
    ) || null

  const getChart = () => {
    if (!ref || findChart()) return

    useTheme(am4themes_animated)
    const updated = create(`gauge-chart-${uuid}`, RadarChart)
    updated.height = 220
    updated.width = percent(100)
    updated.startAngle = -90
    updated.endAngle = 180
    updated.innerRadius = percent(20)
    updated.fontSize = 10

    updated.data = _.reverse(
      _.map(data, d => ({
        category: d.label,
        originalValue: d.value,
        value: parseFloat(((d.value / total) * 100).toFixed(2)),
        full: 100
      }))
    )

    const colorSet = new ColorSet()
    colorSet.list = _.reverse(
      COLORS.map(c => {
        return new color(c)
      })
    )
    updated.colors = colorSet

    const categoryAxis = updated.yAxes.push(new CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 100
    categoryAxis.renderer.minGridDistance = 10

    const valueAxis = updated.xAxes.push(new ValueAxis())
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.renderer.radius = percent(85)
    valueAxis.renderer.minGridDistance = 10
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.strictMinMax = true
    valueAxis.cursorTooltipEnabled = false

    const series1 = updated.series.push(new RadarColumnSeries())
    series1.dataFields.valueX = 'full'
    series1.dataFields.categoryY = 'category'
    series1.clustered = false
    series1.columns.template.fill = color(BACKGROUND_COLOR)
    series1.columns.template.cornerRadiusTopLeft = 20
    series1.columns.template.strokeWidth = 0
    series1.columns.template.radarColumn.cornerRadius = 20
    series1.columns.template.tooltipText =
      '{category}: [bold]{originalValue} ({value}%)[/]'

    var series2 = updated.series.push(new RadarColumnSeries())
    series2.dataFields.valueX = 'value'
    series2.dataFields.categoryY = 'category'
    series2.clustered = false
    series2.columns.template.strokeWidth = 0
    series2.columns.template.tooltipText =
      '{category}: [bold]{originalValue} ({value}%)[/]'
    series2.columns.template.radarColumn.cornerRadius = 20
    series2.columns.template.adapter.add('fill', (fill, target) =>
      updated.colors.getIndex(target.dataItem.index)
    )
    updated.cursor = new RadarCursor()

    return updated
  }

  const disposeChart = () => {
    const foundChart = findChart()
    if (foundChart) {
      foundChart.dispose()
    }
  }

  useEffect(() => {
    return () => {
      disposeChart()
    }
  }, [])

  useEffect(() => {
    getChart()
  }, [ref])

  return (
    <div className="dashboard-card">
      <div className="inner-content" style={{ minHeight: '297px' }}>
        <ComponentWithStatus loading={loading} error={error}>
          <div className="columns" style={{ marginBottom: '-1.5rem' }}>
            <div className="column with-ellipsis heading-5">
              Status: {title}
            </div>
          </div>
          <div
            id={`gauge-chart-${uuid}`}
            ref={r => setRef(r)}
            className={`gauge-chart-${uuid} has-text-centered`}
            style={{ height: '220px' }}
          />
          <div
            className="columns small-text"
            style={{
              paddingLeft: '1rem',
              paddingRight: '1.8rem',
              paddingTop: '1rem',
              paddingBottom: '1rem'
            }}>
            <div className="column is-half" style={{ marginRight: '0.25rem' }}>
              {getStatsRow({ label: 'Needed', value: total }, true)}
              {_.map(data.slice(0, Math.floor(data.length / 2)), d =>
                getStatsRow(d)
              )}
            </div>
            <div className="column is-half" style={{ marginLeft: '0.25rem' }}>
              {_.map(data.slice(Math.floor(data.length / 2)), d =>
                getStatsRow(d)
              )}
            </div>
          </div>
        </ComponentWithStatus>
      </div>
    </div>
  )
}

GaugeChart.propTypes = {
  /**
   * Determines if the data is currently being loaded
   */
  loading: PropTypes.bool,
  /**
   * Determines if there was an error reading the data
   */
  error: PropTypes.bool,
  /**
   * Gauge chart title string
   */
  title: PropTypes.string,
  /**
   * Array with all of the service's data
   */
  data: PropTypes.array
}

const dataEqual = (prevProps, nextProps) =>
  _.isEqual(prevProps.data, nextProps.data)

export default memo(GaugeChart, dataEqual)
