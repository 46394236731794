export const ALLPROPERTIES = [
  {
    project_id: 'proj_1',
    project_name: 'Demo',
    property_id: 'prop_1',
    property_name: 'Development Property'
  },
  {
    project_id: 'proj_10',
    project_name: 'Demo2',
    property_id: 'prop_1',
    property_name: 'Development Property'
  },
  {
    project_id: 'proj_1',
    project_name: 'Demo',
    property_id: 'prop_6',
    property_name: 'Sea View'
  },
  {
    project_id: 'proj_2',
    project_name: 'New Turn 2023',
    property_id: 'prop_2',
    property_name: 'Development Property 2'
  },
  {
    project_id: 'proj_3',
    project_name: 'Twin Towers',
    property_id: 'prop_3',
    property_name: 'Trump Tower'
  },
  {
    project_id: 'proj_4',
    project_name: 'Circuit',
    property_id: 'prop_4',
    property_name: 'Real State 2023'
  },
  {
    project_id: 'proj_5',
    project_name: 'Real Madrid',
    property_id: 'prop_5',
    property_name: 'Team Of Wonders'
  }
]

export const ALLROLES = [
  {
    role_id: 'rol_Odkqku2YkXmrbdpB',
    name: 'Accounting',
    role_type: null
  },
  {
    role_id: 'rol_DdMv0wYdixv6Ex9N',
    name: 'Administrator',
    role_type: null
  },
  {
    role_id: 'rol_Yrd560BfSx67FlHj',
    name: 'Property Manager',
    role_type: null
  },
  {
    role_id: 'rol_n2Up75TZYNkXJ0jA',
    name: 'Staff',
    role_type: null
  },
  {
    role_id: 'rol_Y8fh2aEa2wPBGkaK',
    name: 'Vendor',
    role_type: 'Vendor'
  },
  {
    role_id: 'rol_dlx6f8ffXMjmoZch',
    name: 'View Only',
    role_type: null
  }
]

export const ALLPROJECTS = [
  {
    id: 'backend',
    name: 'Demo',
    properties: ['dev']
  },
  {
    id: 'backend 2',
    name: 'Demo 2',
    properties: ['dev3', 'dev2']
  }
]

export const ALLALLOWEDSECTION = [
  {
    name: 'inspectionSetup'
  },
  {
    name: 'inspectSideBarLink'
  },
  {
    name: 'inspections'
  },
  {
    name: 'reports'
  },
  {
    name: 'payableSideBarLink'
  },
  {
    name: 'payableApproval'
  },
  {
    name: 'payableApprovalSideBarLink'
  },
  {
    name: 'rfp'
  },
  {
    name: 'isAutomaticScheduling'
  }
]

export const ALLUSERTYPE = [
  { name: 'Temporary' },
  { name: 'Part Time' },
  { name: 'Full Time' }
]

export const SAMPLEDATA = {
  name: 'Hammad',
  email: 'Hammad@gmail.com',
  user_id: '123456',
  auth_id: '123456',
  type: 'Temporary',
  title: 'Mr. Dev',
  phone: '03312121742',
  alt_phone: null,
  notes: 'N/A',
  hire_date: null,
  term_date: null,
  is_inactive: true,
  permission_rules: [
    {
      property_id: 'prop_1',
      role_id: 'rol_DdMv0wYdixv6Ex9N',
      internal_permission_rule_id: '106d33d2-7183-40d5-b4a4-f3d207d2b656',
      login_access: null,
      project_id: 'proj_1',
      action: 'Update',
      status: null,
      permission_rule_id: '9089e65b-7dc7-4f01-920c-20d06878yu4gh454'
    },
    {
      property_id: 'prop_1',
      role_id: 'rol_DdMv0wYdixv6Ex9N',
      internal_permission_rule_id: '106d33d2-7183-40d5-b4a4-f3d207d2b656',
      login_access: null,
      project_id: 'proj_10',
      action: 'Update',
      status: null,
      permission_rule_id: '1089e65b-7dc7-4f01-920c-20d06878yu4gh459'
    },
    {
      property_id: 'prop_2',
      role_id: 'rol_DdMv0wYdixv6Ex9N',
      internal_permission_rule_id: '106d33d2-7183-40d5-b4a4-f3d207d2b656',
      login_access: null,
      project_id: 'proj_2',
      action: 'Update',
      status: null,
      permission_rule_id: '9089e65b-7dc7-4f01-920c-20d06878yu4gh4544545'
    },
    {
      property_id: 'prop_3',
      role_id: 'rol_n2Up75TZYNkXJ0jA',
      internal_permission_rule_id: '72ff8a0d-f292-4679-b372-bec6c7e5d8e7',
      login_access: 'false',
      project_id: 'proj_3',
      action: 'Update',
      status: null,
      permission_rule_id: '9089e65b-7dc7-4f01-920c-20d06878yu4ghhfdfg4'
    }
  ]
}
