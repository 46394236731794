import React from 'react'
import PropTypes from 'prop-types'
import RFPList from '../rfp-list'
import './index.scss'

const RequestForProposalMain = (props) => {
  const { user } = props
  const { name } = user || {}
  return (
    <div className="container vendor-section setup-vendors-main animated fadeIn">
      <div className="section">
        <div className="columns">
          <div className="column">
            <div className="block">
              <p className="heading-4 is-capitalized">Welcome back, { name || 'Vendor Name'}.</p>
            </div>
            <div className="block">
              <p className="description">This table lists all of the requests for proposal that have been sent to you. To open a request, click the Request Title.</p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <RFPList {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

RequestForProposalMain.propTypes = {
  user: PropTypes.object,
}

export default RequestForProposalMain
