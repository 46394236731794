import _ from 'lodash'
import React, { Fragment, useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { getColumnWrapper } from './helpers'
import TableCell from './table-cell'
import { icons } from './icons'
import { getColor, getColumnStyle, intersectsContainer } from './helpers'
import { FIRST_STYLE } from './constants'
import { isDescendant } from '../../utils'
import { usePermissionGate } from '../../helpers/hooks'

const TableRow = ({
  d,
  isLast,
  index,
  trStyle,
  singleSelectionColumn,
  getTableHead,
  rowOptions,
  hasCheckboxes,
  rowKey,
  singleSelection,
  checkboxDisabled,
  checked,
  setChecked,
  emptyColor,
  pivotConfig,
  methods,
  data,
  header,
  emptyValue,
  iconClicked,
  tooltip,
  tooltipSizeAuto,
  openTooltip,
  setTooltipContent,
  closeTooltip,
  tooltipTemplate,
  headerRefs,
  getMenuDimensions,
  parentProps,
  currentRow,
  setCurrentRow,
  optionsOpen,
  setOptionsOpen,
  asyncActionOptionOnGoing,
  customCheckboxDisabledAllowed = () => {},
  customCheckboxDisabledKey = () => {}
}) => {
  const [expanded, setExpanded] = useState([])
  const [dropdownLeft, setDropdownLeft] = useState(0)
  const [dropdownTop, setDropdownTop] = useState(0)
  const ellipsisRef = useRef()

  useEffect(() => {
    const closeEllipsisMenu = e => {
      if (
        !isDescendant(e.target, 'ellipsis-container') &&
        !isDescendant(e.target, 'row-options') &&
        optionsOpen
      ) {
        setOptionsOpen(false)
        setCurrentRow(null)
      }
    }
    document.getElementById('root').addEventListener('click', closeEllipsisMenu)

    return () =>
      document
        .getElementById('root')
        .removeEventListener('click', closeEllipsisMenu)
  }, [optionsOpen])

  const getDisplay = (
    row,
    key,
    tooltipData = null,
    isLast = false,
    pivot = null,
    prefix = '',
    keyValue = ''
  ) => (
    <TableCell
      row={row}
      fieldKey={key}
      tooltipData={tooltipData}
      isLast={isLast}
      pivot={pivot}
      pivotConfig={pivotConfig}
      methods={methods}
      data={data}
      header={header}
      emptyValue={emptyValue}
      prefix={prefix}
      keyValue={keyValue}
      iconClicked={iconClicked}
      tooltip={tooltip}
      openTooltip={openTooltip}
      tooltipSizeAuto={tooltipSizeAuto}
      setTooltipContent={setTooltipContent}
      closeTooltip={closeTooltip}
      tooltipTemplate={tooltipTemplate}
    />
  )

  const getPivot = (d, pivot, trStyle, parentCols, prefix) => {
    const { collection, keyField, idField } = pivot
    const length = d[collection].length

    let parentExpanded = false
    if (prefix === '') parentExpanded = true
    else parentExpanded = expanded.indexOf(prefix) >= 0
    if (!parentExpanded) return null
    //TODO: HERE
    return (
      <tr className="pivot-wrapper">
        {/* <td /> */}
        <td
          colSpan={parentCols + (rowOptions && rowOptions.length ? 2 : 0)}
          className="pivot-container">
          <div className="pivot">
            {getTableHead(pivot.columns, pivot.pivotConfig, true)}
            {_.map(d[collection], (child, i) =>
              getCols(
                child,
                i < length,
                i,
                trStyle,
                pivot.columns || [],
                pivot,
                pivot.pivotConfig || null,
                d[idField],
                keyField,
                true
              )
            )}
            {pivot.bottomRow && (
              <div key={`footer-${prefix}`} className="row pivot-data-wrap">
                {pivot.bottomRow(d)}
              </div>
            )}
          </div>
        </td>
      </tr>
    )
  }

  const getCellTooltip = (value, row, menuTemplate) => {
    const [width, height, topOffset, rightOffset] = getMenuDimensions(row)
    const rect = row.getBoundingClientRect()
    const onTop = rect.top - height - topOffset
    if (!tooltip.isActive)
      openTooltip(
        tooltipSizeAuto ? '' : width,
        tooltipSizeAuto ? '' : height,
        onTop,
        rect.right - width - rightOffset,
        row,
        0,
        e => {
          const tooltipElement = document.getElementById('tooltip')
          if (
            !intersectsContainer(e.clientX, e.clientY, [row, tooltipElement])
          ) {
            row.classList.remove('focused')
            closeTooltip()
          }
        }
      )
    setTooltipContent(menuTemplate(value, parentProps), 'menu-tooltip')
  }

  const rowOptionsApplicable = row =>
    _.filter(rowOptions, option => option.available(row)).length

  const renderOptions = () => {
    const dataRow = _.find(data, d => d[rowKey] === currentRow)
    if (dataRow) {
      return (
        <ul>
          {_.map(
            _.filter(rowOptions, option => option.available(dataRow)),
            (option, index) => (
              <li key={`option-trigger-${index}`}>
                <button
                  className="options-button"
                  onClick={() => {
                    setOptionsOpen(false)
                    option.action(dataRow)
                    option.setCaption && option.setCaption(dataRow)
                  }}>
                  <img
                    src={option.icon}
                    alt={option.caption}
                    className="p-r-xs"
                  />
                  <span className="options-button-label">{option.caption}</span>
                </button>
              </li>
            )
          )}
        </ul>
      )
    }
    return null
  }

  const getCols = (
    d,
    isLast,
    index,
    trStyle,
    columns,
    parentPivot = null,
    pivot = null,
    prefix = '',
    idField,
    isChild
  ) => {
    if (typeof d === 'undefined') return
    let currentKey = ''
    if (pivot && prefix !== '') {
      currentKey = [prefix, d[pivot.idField]].join('||')
    } else if (pivot) {
      currentKey = d[pivot.idField]
    }
    const currentlyExpanded = pivot ? expanded.indexOf(currentKey) >= 0 : true
    let parentExpanded = false
    if (prefix === '') parentExpanded = true
    else parentExpanded = expanded.indexOf(prefix) >= 0
    let template = []

    if (pivot) {
      template.push(
        <td key={`pivot-${d[pivot.idField]}-${index}`} className="no-wrap">
          {!('cannotExpand' in d) && (
            <FontAwesomeIcon
              icon={faAngleDown}
              style={{
                ...FIRST_STYLE,
                transform: `rotate(${currentlyExpanded ? '180deg' : '0'})`,
                cursor: 'pointer'
              }}
              onClick={() => {
                setExpanded(
                  currentlyExpanded
                    ? _.without(expanded, d[pivot.idField])
                    : [...expanded, d[pivot.idField]]
                )
              }}
            />
          )}
        </td>
      )
    }
    if (hasCheckboxes) {
      template.push(
        <td key={`checkbox-${d[rowKey]}`} className="no-wrap">
          <input
            type="checkbox"
            onChange={e => {
              if (!singleSelection) {
                let newChecked = [...checked]
                const index = newChecked.indexOf(d[singleSelectionColumn])
                if (index !== -1) {
                  newChecked.splice(index, 1)
                } else {
                  newChecked = [...checked, d[singleSelectionColumn]]
                }
                setChecked(newChecked)
              } else {
                const clicked = e.target.checked ? [d[rowKey]] : []
                setChecked(clicked)
              }
            }}
            checked={typeof checked.find(c => c === d[rowKey]) !== 'undefined'}
            disabled={
              customCheckboxDisabledAllowed()
                ? checkboxDisabled || d[customCheckboxDisabledKey()]
                  ? 'disabled'
                  : ''
                : checkboxDisabled
                ? 'disabled'
                : ''
            }
            style={!pivot ? FIRST_STYLE : {}}
          />
        </td>
      )
    }

    const activeColumns = columns.filter(columnItem => !columnItem.hidden)

    for (let i = 0; i < activeColumns.length; i += 1) {
      let {
        key,
        tooltip: hTooltip,
        textAlign,
        menuTemplate,
        onHover,
        onMouseOut,
        ignoreEmpty,
        allowWrap
      } = activeColumns[i]
      const display = getColumnWrapper(
        i,
        getDisplay(
          d,
          key,
          hTooltip,
          isLast,
          parentPivot,
          _.get(d, idField, ''),
          prefix
        ),
        true
      )
      const backgroundColor =
        emptyColor && !ignoreEmpty && _.get(d, key) === ''
          ? {
              backgroundColor: getColor(emptyColor)
            }
          : {}

      const textDirection = textAlign || 'left'
      const wrap = allowWrap ? '' : 'no-wrap'
      template.push(
        !isChild ? (
          <td
            className={`table-cell here ${wrap} has-text-${textDirection}`}
            key={`${key}-data-${i}`}
            style={getColumnStyle(
              activeColumns,
              i,
              isChild,
              backgroundColor,
              {},
              headerRefs
            )}
            onMouseOver={e => {
              e.target.closest('tr').classList.add('focused')
              if (menuTemplate && !tooltip.isActive) {
                getCellTooltip(d, e.target.closest('td'), menuTemplate)
              }
              if (onHover) {
                onHover(d, e, methods)
              }
            }}
            onMouseOut={e => {
              e.target.closest('tr').classList.remove('focused')
              const tooltipElement = document.getElementById('tooltip')
              if (tooltipElement) {
                const rect = tooltipElement.getBoundingClientRect()
                if (
                  closeTooltip &&
                  (e.clientX < rect.x ||
                    e.clientX > rect.x + rect.width ||
                    e.clientY < rect.y ||
                    e.clientY > rect.y + rect.height)
                ) {
                  closeTooltip()
                }
              }
              if (onMouseOut) {
                onMouseOut(d, e, methods)
              }
            }}>
            {display}
          </td>
        ) : (
          <div
            key={`${key}-data-${i}`}
            className="div-column"
            style={getColumnStyle(
              activeColumns,
              i,
              isChild,
              backgroundColor,
              headerRefs
            )}>
            {display}
          </div>
        )
      )
    }
    if (
      rowOptions &&
      (rowOptions || []).length &&
      !isChild &&
      !asyncActionOptionOnGoing
    ) {
      template.push(
        <td
          className="table-cell action-item no-wrap has-text-right is-narrow p-t-xs p-b-none p-r-xs p-l-xs"
          key={`${d.unit}-options`}>
          {currentRow === d[rowKey] && rowOptionsApplicable(d) > 0 && (
            <div
              className={`dropdown ${optionsOpen ? 'is-active' : ''} ${
                isLast ? 'is-up' : ''
              }`}>
              <div className="dropdown-trigger" id="ellipsis-container">
                <button
                  ref={ellipsisRef}
                  className="button options-button"
                  type="button"
                  style={{ height: '32px' }}
                  aria-haspopup={true}
                  aria-controls="row-options"
                  onClick={e => {
                    const pos = ellipsisRef.current.getBoundingClientRect()
                    setDropdownLeft(pos.left - 170)
                    setDropdownTop(pos.top + pos.height)
                    setOptionsOpen(!optionsOpen)
                  }}>
                  <img src={icons.dark} alt="Options" />
                </button>
              </div>
              <div
                className="dropdown-menu"
                role="menu"
                id="row-options"
                style={{
                  position: 'fixed',
                  left: dropdownLeft,
                  top: dropdownTop,
                  minWidth: 0
                }}>
                <div
                  id="row-options"
                  className="dropdown-content has-text-left p-none m-none"
                  style={
                    isLast
                      ? {
                          top: rowOptionsApplicable(d) * -35,
                          position: 'relative'
                        }
                      : { position: 'relative' }
                  }>
                  {renderOptions()}
                </div>
              </div>
            </div>
          )}
        </td>
      )
    }

    return (
      <Fragment key={`data-table-${index}`}>
        {!isChild ? (
          <tr
            onMouseOver={() =>
              !asyncActionOptionOnGoing &&
              !optionsOpen &&
              setCurrentRow(d[rowKey])
            }
            onClick={() => {
              if (d[rowKey] !== currentRow) setOptionsOpen(false)
              setCurrentRow(d[rowKey])
            }}
            className={parentExpanded ? '' : 'is-hidden'}
            style={{
              ...trStyle,
              border: '1px solid #e5e9f2',
              borderTop: index > 0 ? '1px solid #e5e9f2' : 'none',
              borderBottom: !isLast ? 'none' : '1px solid #e5e9f2',
              backgroundColor:
                customCheckboxDisabledKey() === 'is_blocked' &&
                customCheckboxDisabledAllowed()
                  ? d[customCheckboxDisabledKey()] && '#e5e9f2'
                  : ''
            }}>
            {template}
          </tr>
        ) : (
          <div className="row">{template}</div>
        )}
        {pivot && getPivot(d, pivot, trStyle, columns.length, d[idField])}
      </Fragment>
    )
  }

  return getCols(
    d,
    isLast,
    index,
    trStyle,
    header,
    null,
    pivotConfig,
    '',
    _.get(pivotConfig, 'idField', '')
  )
}

export default TableRow
